import { useContext, useMemo } from "react";
import commodityCodeTypes from "../../../../../../../../constants/validation-pulldown-options/commodityCodeTypes";
import ThemeContext from "../../../../../../../../context/theme/ThemeContext";

const CommodityCodeDrawerItem = ({ item }) => {
	const { withTheme } = useContext(ThemeContext);

	const type = useMemo(
		() =>
			commodityCodeTypes.find(({ key }) => key === item.data.commodityCodeType)?.value ||
			item.data.commodityCodeType,
		[item]
	);

	return (
		<div>
			<div className={withTheme("search-page-drawer-item-title")}>{type}</div>

			<div style={{ display: "flex", alignItems: "center" }}>
				<div className={withTheme("item-property")}>ID:</div>
				<div style={{ fontSize: 12 }}>{item.data.commodityCodeId}</div>
			</div>
		</div>
	);
};

export default CommodityCodeDrawerItem;
