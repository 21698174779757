import { useContext, useMemo } from "react";
import Card from "../../../common/Card";
import ThemeContext from "../../../../context/theme/ThemeContext";
import DeclarationsPieChart from "./DeclarationsPiChart";
import { useTranslation } from "react-i18next";
import DeclarationsContext from "../../../../context/declarations/DeclarationsContext";

const ChartCard = () => {
	const { t } = useTranslation();
	const { state } = useContext(DeclarationsContext);

	const { withTheme } = useContext(ThemeContext);

	const data = useMemo(() => {
		let data = [
			{
				id: "imports",
				label: "Imports",
				value: 0,
				color: "#2D5E41",
			},
			{
				id: "exports",
				label: "Exports",
				value: 0,
				color: "#86C49F",
			},
		];

		state.declarations.forEach((declaration) => {
			const service = declaration.data.service

			if (service === "import") {
				data[0].value += 1;
			} else if (service === "export") {
				data[1].value += 1;
			}
		});

		return data;
	}, [state.declarations]);

	return (
		<Card style={{ height: 264, width: "50%" }}>
			<div className={withTheme("dashboard-card-label")}>{t("declarationsByType")}</div>
			<div style={{ display: "flex", alignItems: "center", gap: 24 }}>
				<div style={{ height: 160, width: 200 }}>
					<DeclarationsPieChart data={data} />
				</div>

				<div style={{ width: "60%", paddingRight: 12 }}>
					<div className={withTheme("chart-legend-entry")}>
						<div style={{ fontSize: 14, fontWeight: 500 }}>
							{t("totalSubmitted")}
						</div>

						<div className={withTheme("chart-legend-value")}>
							{state.declarations.length}
						</div>
					</div>

					{data.map((item, index) => (
						<ChartLegendEntry
							label={t(item.id)}
							value={item.value}
							color={item.color}
							key={index}
						/>
					))}
				</div>
			</div>
		</Card>
	);
};

const ChartLegendEntry = ({ label, value, color }) => {
	const { withTheme } = useContext(ThemeContext);

	return (
		<div className={withTheme("chart-legend-entry")}>
			<div className={withTheme("chart-legend-circle")} style={{ backgroundColor: color }} />
			<div style={{ fontSize: 14 }}>{label}</div>

			<div className={withTheme("chart-legend-value")}>{value || "-"}</div>
		</div>
	);
};

export default ChartCard;
