import { customSections, getCustomSectionElements } from "../components/common/InputsSection/CustomSections";
import headerGrouping from "../constants/grouping/import/header-grouping.json";
import itemsGrouping from "../constants/grouping/import/items-grouping.json";
import exportHeaderGrouping from "../constants/grouping/export/header-grouping.json";
import exportItemsGrouping from "../constants/grouping/export/items-grouping.json";
import validation from "../constants/validation";

export const bulkUpdateQueryParams = (updates) => {
	const url = new URL(window.location.href);

	updates.forEach((update) => {
		if (update.value) {
			url.searchParams.set(update.name, update.value);
		} else {
			url.searchParams.delete(update.name);
		}
	});

	window.history.replaceState(null, null, url);

	// popstate event is triggered in order for an 'onpopstate' window event listener to recognize it
	const event = new Event("popstate");
	window.dispatchEvent(event);
};

export const updateQueryParam = (name, value) => {
	const url = new URL(window.location.href);
	url.searchParams.set(name, value);
	window.history.replaceState(null, null, url);

	// popstate event is triggered in order for an 'onpopstate' window event listener to recognize it
	const event = new Event("popstate");
	window.dispatchEvent(event);
};

export const removeQueryParam = (name) => {
	const url = new URL(window.location.href);
	url.searchParams.delete(name);

	window.history.replaceState(null, "", url);

	// popstate event is triggered in order for an 'onpopstate' window event listener to recognize it
	const event = new Event("popstate");
	window.dispatchEvent(event);
};

// Retrieve the value of a query parameter using its name
export const getQueryParam = (name) => {
	const url = new URL(window.location.href);
	return url.searchParams.get(name);
};

/**
 * @param {*} group header or items
 * @param {*} elementName of the element we're searching for
 */
export const findElementPageNumber = (group, elementName, declaration) => {
	let result = null;
	let pages = headerGrouping;

	if (declaration.data.service === "export") {
		if (group === "items") {
			pages = exportItemsGrouping;
		} else {
			pages = exportHeaderGrouping;
		}
	} else if (group === "items") {
		pages = itemsGrouping;
	}

	pagesLoop: for (const [index, page] of pages.entries()) {
		for (const section of page.sections) {
			if (customSections.includes(section.section)) {
				const elements = getCustomSectionElements(section.section);
				for (const element of elements) {
					if (element === elementName) {
						result = index;
						break pagesLoop;
					}
				}
			} else {
				const findElementInSection = (elements) => {
					elements?.forEach((element) => {
						if (element.elementName === elementName) {
							result = index;
							// If element is a mutliline one, look for the elementName in its child components
						} else if (element.type === "multiline") {
							const validationElement = validation.find(
								(elem) => elem.elementName === element.elementName
							);

							if (validationElement) {
								const childElement = validationElement.components?.find(
									(elem) => elem.elementName === elementName
								);
								if (childElement) {
									result = index;
								}
							}

							return result;
						} else if (element.type === "row") {
							return findElementInSection(element.elements);
						}
					});
				};

				const isFound = findElementInSection(section.elements);

				if (isFound) {
					break pagesLoop;
				}
			}
		}
	}

	return result;
};

export const scrollToElement = (elementName) => {
	const htmlElement = document.getElementById(elementName);
	let position = htmlElement?.getBoundingClientRect();
	if (htmlElement) {
		window.scrollTo(position.left, position.top + window.scrollY - 150);
	}
};
