import moment from "moment";
import { useTranslation } from "react-i18next";

const Log = ({ log }) => {
	const { t } = useTranslation();

	return (
		<div style={{ display: "flex", alignItems: "center", marginTop: 20, marginBottom: 20 }}>
			<div style={{ width: "calc(100% - 70px)", textAlign: "left" }}>
				<div style={{ fontSize: 14 }}>{t(log.action + "Log")}</div>
				{Boolean(log.createdAt) && (
					<div style={{ fontSize: 12, marginTop: 4, color: "#494E57" }}>
						{moment(log.createdAt).format("DD MMM, hh:mm A")}
					</div>
				)}
			</div>
		</div>
	);
};

export default Log;
