import { useContext } from "react";
import { objectContainsNestedObjects, sortData } from "../../modals/DeclarationDetailsModal/utils";
import ThemeContext from "../../../context/theme/ThemeContext";
import { startCase } from "lodash";
import ReadOnlyElement from "./ReadOnlyElement";

const ObjectElement = ({ label, data, element }) => {
	const { withTheme } = useContext(ThemeContext);

	if (objectContainsNestedObjects(data)) {
		return (
			<div
				id={element?.elementName}
				className={`${withTheme("inputs-section")} ${withTheme("lemon")}`}
				style={{ width: "100%" }}
			>
				<div className={withTheme("inputs-section-title")} style={{ marginBottom: 16 }}>
					{startCase(label)}
				</div>

				<div
					style={{
						display: "flex",
						flexWrap: "wrap",
						flexGrow: 1,
						gap: 12,
					}}
				>
					{Object.keys(sortData(data)).map((key) => (
						<ReadOnlyElement key={key} label={key} data={data[key]} />
					))}
				</div>
			</div>
		);
	} else {
		return (
			<div
				id={element?.elementName}
				style={{ display: "flex", flexWrap: "wrap", width: "100%", gap: 12 }}
			>
				{Object.keys(sortData(data)).map((key) => (
					<ReadOnlyElement key={key} label={label + key} data={data[key]} />
				))}
			</div>
		);
	}
};

export default ObjectElement;
