import { useContext, useEffect, useState } from "react";
import SelectedFileHeader from "./Header";
import ThemeContext from "../../../../context/theme/ThemeContext";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../../../modals/ConfirmationModal";
import SelectedFileInputs from "./Inputs";
import SelectedFileData from "./Data";
import FilesContext from "../../../../context/files/FilesContext";

const SelectedFile = ({ file, onBackClick, isDrawerExpanded }) => {
	const { isFilesLoading, deleteFile } = useContext(FilesContext);
	const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);
	const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
	const { withTheme } = useContext(ThemeContext);
	const { t } = useTranslation();
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);

	const handleDelete = async () => {
		setIsDeleteLoading(true);

		await deleteFile(file);

		setIsDeleteLoading(false);
		setIsDeleteConfirmModalOpen(false);
	};

	useEffect(() => {
		setIsEditModeEnabled(false);
	}, [file?._id]);

	if (isFilesLoading) {
		return null;
	}

	return (
		<div
			style={{
				transition: "0.3s",
				width: `calc(100% - ${isDrawerExpanded ? 320 : 64}px)`,
				paddingLeft: 40,
				paddingRight: 40,
				borderTopRightRadius: 6,
				borderBottomRightRadius: 6,
				overflowY: "auto",
			}}
		>
			<ConfirmationModal
				isOpen={isDeleteConfirmModalOpen}
				title={t("areYouSure")}
				description={t("deleteFileConfirm")}
				primaryActionLabel={t("deleteFile")}
				isActionLoading={isDeleteLoading}
				onPrimaryActionClick={handleDelete}
				onClose={() => setIsDeleteConfirmModalOpen(false)}
			/>

			{!file ? (
				<div
					style={{ paddingTop: 92, textAlign: "center" }}
					className={withTheme("empty-page-text")}
				>
					{t("fileNotSelectedMessage")}
				</div>
			) : (
				<>
					<SelectedFileHeader
						file={file}
						onBackClick={onBackClick}
						isDeleteLoading={isDeleteLoading}
						isEditModeEnabled={isEditModeEnabled}
						onEditClick={() => setIsEditModeEnabled(true)}
						onDeleteClick={() => setIsDeleteConfirmModalOpen(true)}
					/>

					<div className={withTheme("search-page-item-title")} style={{ fontSize: 20 }}>
						{file.label}
					</div>

					{isEditModeEnabled ? (
						<SelectedFileInputs
							file={file}
							setIsEditModeEnabled={setIsEditModeEnabled}
						/>
					) : (
						<SelectedFileData file={file} />
					)}
				</>
			)}
		</div>
	);
};

export default SelectedFile;
