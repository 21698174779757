const goodsMeasureTypesOfPackages = [
    {
        "key": "DEFAULT",
        "value": "Please Select"
    },
    {
        "key": "43",
        "value": "Bag, super bulk"
    },
    {
        "key": "44",
        "value": "Bag, polybag"
    },
    {
        "key": "1A",
        "value": "Drum, steel"
    },
    {
        "key": "1B",
        "value": "Drum, aluminium"
    },
    {
        "key": "1D",
        "value": "Drum, plywood"
    },
    {
        "key": "1F",
        "value": "Container, flexible"
    },
    {
        "key": "1G",
        "value": "Drum, fibre"
    },
    {
        "key": "1W",
        "value": "Drum, wooden"
    },
    {
        "key": "2C",
        "value": "Barrel, wooden"
    },
    {
        "key": "3A",
        "value": "Jerrican, steel"
    },
    {
        "key": "3H",
        "value": "Jerrican, plastic"
    },
    {
        "key": "4A",
        "value": "Box, steel"
    },
    {
        "key": "4B",
        "value": "Box, aluminium"
    },
    {
        "key": "4C",
        "value": "Box, natural wood"
    },
    {
        "key": "4D",
        "value": "Box, plywood"
    },
    {
        "key": "4F",
        "value": "Box, reconstituted wood"
    },
    {
        "key": "4G",
        "value": "Box, fibreboard"
    },
    {
        "key": "4H",
        "value": "Box, plastic"
    },
    {
        "key": "5H",
        "value": "Bag, woven plastic"
    },
    {
        "key": "5L",
        "value": "Bag, textile"
    },
    {
        "key": "5M",
        "value": "Bag, paper"
    },
    {
        "key": "6H",
        "value": "Composite packaging, plastic receptacle"
    },
    {
        "key": "6P",
        "value": "Composite packaging, glass receptacle"
    },
    {
        "key": "7A",
        "value": "Case, car"
    },
    {
        "key": "7B",
        "value": "Case, wooden"
    },
    {
        "key": "8A",
        "value": "Pallet, wooden"
    },
    {
        "key": "8B",
        "value": "Crate, wooden"
    },
    {
        "key": "8C",
        "value": "Bundle, wooden"
    },
    {
        "key": "AA",
        "value": "Intermediate bulk container, rigid plastic"
    },
    {
        "key": "AB",
        "value": "Receptacle, fibre"
    },
    {
        "key": "AC",
        "value": "Receptacle, paper"
    },
    {
        "key": "AD",
        "value": "Receptacle, wooden"
    },
    {
        "key": "AE",
        "value": "Aerosol"
    },
    {
        "key": "AF",
        "value": "Pallet, modular, collars 80cms * 60cms"
    },
    {
        "key": "AG",
        "value": "Pallet, shrinkwrapped"
    },
    {
        "key": "AH",
        "value": "Pallet, 100cms * 110cms"
    },
    {
        "key": "AI",
        "value": "Clamshell"
    },
    {
        "key": "AJ",
        "value": "Cone"
    },
    {
        "key": "AL",
        "value": "Ball"
    },
    {
        "key": "AM",
        "value": "Ampoule, non-protected"
    },
    {
        "key": "AP",
        "value": "Ampoule, protected"
    },
    {
        "key": "AT",
        "value": "Atomizer"
    },
    {
        "key": "AV",
        "value": "Capsule"
    },
    {
        "key": "B4",
        "value": "Belt"
    },
    {
        "key": "BA",
        "value": "Barrel"
    },
    {
        "key": "BB",
        "value": "Bobbin"
    },
    {
        "key": "BC",
        "value": "Bottlecrate / bottlerack"
    },
    {
        "key": "BD",
        "value": "Board"
    },
    {
        "key": "BE",
        "value": "Bundle"
    },
    {
        "key": "BF",
        "value": "Balloon, non-protected"
    },
    {
        "key": "BG",
        "value": "Bag"
    },
    {
        "key": "BH",
        "value": "Bunch"
    },
    {
        "key": "BI",
        "value": "Bin"
    },
    {
        "key": "BJ",
        "value": "Bucket"
    },
    {
        "key": "BK",
        "value": "Basket"
    },
    {
        "key": "BL",
        "value": "Bale, compressed"
    },
    {
        "key": "BM",
        "value": "Basin"
    },
    {
        "key": "BN",
        "value": "Bale, non-compressed"
    },
    {
        "key": "BO",
        "value": "Bottle, non-protected, cylindrical"
    },
    {
        "key": "BP",
        "value": "Balloon, protected"
    },
    {
        "key": "BQ",
        "value": "Bottle, protected cylindrical"
    },
    {
        "key": "BR",
        "value": "Bar"
    },
    {
        "key": "BS",
        "value": "Bottle, non-protected, bulbous"
    },
    {
        "key": "BT",
        "value": "Bolt"
    },
    {
        "key": "BU",
        "value": "Butt"
    },
    {
        "key": "BV",
        "value": "Bottle, protected bulbous"
    },
    {
        "key": "BW",
        "value": "Box, for liquids"
    },
    {
        "key": "BX",
        "value": "Box"
    },
    {
        "key": "BY",
        "value": "Board, in bundle/bunch/truss"
    },
    {
        "key": "BZ",
        "value": "Bars, in bundle/bunch/truss"
    },
    {
        "key": "CA",
        "value": "Can, rectangular"
    },
    {
        "key": "CB",
        "value": "Crate, beer"
    },
    {
        "key": "CC",
        "value": "Churn"
    },
    {
        "key": "CD",
        "value": "Can, with handle and spout"
    },
    {
        "key": "CE",
        "value": "Creel"
    },
    {
        "key": "CF",
        "value": "Coffer"
    },
    {
        "key": "CG",
        "value": "Cage"
    },
    {
        "key": "CH",
        "value": "Chest"
    },
    {
        "key": "CI",
        "value": "Canister"
    },
    {
        "key": "CJ",
        "value": "Coffin"
    },
    {
        "key": "CK",
        "value": "Cask"
    },
    {
        "key": "CL",
        "value": "Coil"
    },
    {
        "key": "CM",
        "value": "Card"
    },
    {
        "key": "CN",
        "value": "Container, not otherwise specified as transport equipment"
    },
    {
        "key": "CO",
        "value": "Carboy, non-protected"
    },
    {
        "key": "CP",
        "value": "Carboy, protected"
    },
    {
        "key": "CQ",
        "value": "Cartridge"
    },
    {
        "key": "CR",
        "value": "Crate"
    },
    {
        "key": "CS",
        "value": "Case"
    },
    {
        "key": "CT",
        "value": "Carton"
    },
    {
        "key": "CU",
        "value": "Cup"
    },
    {
        "key": "CV",
        "value": "Cover"
    },
    {
        "key": "CW",
        "value": "Cage, roll"
    },
    {
        "key": "CX",
        "value": "Can, cylindrical"
    },
    {
        "key": "CY",
        "value": "Cylinder"
    },
    {
        "key": "CZ",
        "value": "Canvas"
    },
    {
        "key": "DA",
        "value": "Crate, multiple layer, plastic"
    },
    {
        "key": "DB",
        "value": "Crate, multiple layer, wooden"
    },
    {
        "key": "DC",
        "value": "Crate, multiple layer, cardboard"
    },
    {
        "key": "DG",
        "value": "Cage, Commonwealth Handling Equipment Pool (CHEP)"
    },
    {
        "key": "DH",
        "value": "Box, Commonwealth Handling Equipment Pool (CHEP), Eurobox"
    },
    {
        "key": "DI",
        "value": "Drum, iron"
    },
    {
        "key": "DJ",
        "value": "Demijohn, non-protected"
    },
    {
        "key": "DK",
        "value": "Crate, bulk, cardboard"
    },
    {
        "key": "DL",
        "value": "Crate, bulk, plastic"
    },
    {
        "key": "DM",
        "value": "Crate, bulk, wooden"
    },
    {
        "key": "DN",
        "value": "Dispenser"
    },
    {
        "key": "DP",
        "value": "Demijohn, protected"
    },
    {
        "key": "DR",
        "value": "Drum"
    },
    {
        "key": "DS",
        "value": "Tray, one layer no cover, plastic"
    },
    {
        "key": "DT",
        "value": "Tray, one layer no cover, wooden"
    },
    {
        "key": "DU",
        "value": "Tray, one layer no cover, polystyrene"
    },
    {
        "key": "DV",
        "value": "Tray, one layer no cover, cardboard"
    },
    {
        "key": "DW",
        "value": "Tray, two layers no cover, plastic tray"
    },
    {
        "key": "DX",
        "value": "Tray, two layers no cover, wooden"
    },
    {
        "key": "DY",
        "value": "Tray, two layers no cover, cardboard"
    },
    {
        "key": "EC",
        "value": "Bag, plastic"
    },
    {
        "key": "ED",
        "value": "Case, with pallet base"
    },
    {
        "key": "EE",
        "value": "Case, with pallet base, wooden"
    },
    {
        "key": "EF",
        "value": "Case, with pallet base, cardboard"
    },
    {
        "key": "EG",
        "value": "Case, with pallet base, plastic"
    },
    {
        "key": "EH",
        "value": "Case, with pallet base, metal"
    },
    {
        "key": "EI",
        "value": "Case, isothermic"
    },
    {
        "key": "EN",
        "value": "Envelope"
    },
    {
        "key": "FB",
        "value": "Flexibag"
    },
    {
        "key": "FC",
        "value": "Crate, fruit"
    },
    {
        "key": "FD",
        "value": "Crate, framed"
    },
    {
        "key": "FE",
        "value": "Flexitank"
    },
    {
        "key": "FI",
        "value": "Firkin"
    },
    {
        "key": "FL",
        "value": "Flask"
    },
    {
        "key": "FO",
        "value": "Footlocker"
    },
    {
        "key": "FP",
        "value": "Filmpack"
    },
    {
        "key": "FR",
        "value": "Frame"
    },
    {
        "key": "FT",
        "value": "Foodtainer"
    },
    {
        "key": "FW",
        "value": "Cart, flatbed"
    },
    {
        "key": "FX",
        "value": "Bag, flexible container"
    },
    {
        "key": "GB",
        "value": "Bottle, gas"
    },
    {
        "key": "GI",
        "value": "Girder"
    },
    {
        "key": "GL",
        "value": "Container, gallon"
    },
    {
        "key": "GR",
        "value": "Receptacle, glass"
    },
    {
        "key": "GU",
        "value": "Tray, containing horizontally stacked flat items"
    },
    {
        "key": "GY",
        "value": "Bag, gunny"
    },
    {
        "key": "GZ",
        "value": "Girders, in bundle/bunch/truss"
    },
    {
        "key": "HA",
        "value": "Basket, with handle, plastic"
    },
    {
        "key": "HB",
        "value": "Basket, with handle, wooden"
    },
    {
        "key": "HC",
        "value": "Basket, with handle, cardboard"
    },
    {
        "key": "HG",
        "value": "Hogshead"
    },
    {
        "key": "HN",
        "value": "Hanger"
    },
    {
        "key": "HR",
        "value": "Hamper"
    },
    {
        "key": "IA",
        "value": "Package, display, wooden"
    },
    {
        "key": "IB",
        "value": "Package, display, cardboard"
    },
    {
        "key": "IC",
        "value": "Package, display, plastic"
    },
    {
        "key": "ID",
        "value": "Package, display, metal"
    },
    {
        "key": "IE",
        "value": "Package, show"
    },
    {
        "key": "IF",
        "value": "Package, flow"
    },
    {
        "key": "IG",
        "value": "Package, paper wrapped"
    },
    {
        "key": "IH",
        "value": "Drum, plastic"
    },
    {
        "key": "IK",
        "value": "Package, cardboard, with bottle grip-holes"
    },
    {
        "key": "IL",
        "value": "Tray, rigid, lidded stackable (CEN TS 14482:2002)"
    },
    {
        "key": "IN",
        "value": "Ingot"
    },
    {
        "key": "IZ",
        "value": "Ingots, in bundle/bunch/truss"
    },
    {
        "key": "JB",
        "value": "Bag, jumbo"
    },
    {
        "key": "JC",
        "value": "Jerrican, rectangular"
    },
    {
        "key": "JG",
        "value": "Jug"
    },
    {
        "key": "JR",
        "value": "Jar"
    },
    {
        "key": "JT",
        "value": "Jutebag"
    },
    {
        "key": "JY",
        "value": "Jerrican, cylindrical"
    },
    {
        "key": "KG",
        "value": "Keg"
    },
    {
        "key": "KI",
        "value": "Kit"
    },
    {
        "key": "LE",
        "value": "Luggage"
    },
    {
        "key": "LG",
        "value": "Log"
    },
    {
        "key": "LT",
        "value": "Lot"
    },
    {
        "key": "LU",
        "value": "Lug"
    },
    {
        "key": "LV",
        "value": "Liftvan"
    },
    {
        "key": "LZ",
        "value": "Logs, in bundle/bunch/truss"
    },
    {
        "key": "MA",
        "value": "Crate, metal"
    },
    {
        "key": "MB",
        "value": "Bag, multiply"
    },
    {
        "key": "MC",
        "value": "Crate, milk"
    },
    {
        "key": "ME",
        "value": "Container, metal"
    },
    {
        "key": "MR",
        "value": "Receptacle, metal"
    },
    {
        "key": "MS",
        "value": "Sack, multi-wall"
    },
    {
        "key": "MT",
        "value": "Mat"
    },
    {
        "key": "MW",
        "value": "Receptacle, plastic wrapped"
    },
    {
        "key": "MX",
        "value": "Matchbox"
    },
    {
        "key": "NA",
        "value": "Not available"
    },
    {
        "key": "NE",
        "value": "Unpacked or unpackaged"
    },
    {
        "key": "NF",
        "value": "Unpacked or unpackaged, single unit"
    },
    {
        "key": "NG",
        "value": "Unpacked or unpackaged, multiple units"
    },
    {
        "key": "NS",
        "value": "Nest"
    },
    {
        "key": "NT",
        "value": "Net"
    },
    {
        "key": "NU",
        "value": "Net, tube, plastic"
    },
    {
        "key": "NV",
        "value": "Net, tube, textile"
    },
    {
        "key": "OA",
        "value": "Pallet, CHEP 40 cm x 60 cm"
    },
    {
        "key": "OB",
        "value": "Pallet, CHEP 80 cm x 120 cm"
    },
    {
        "key": "OC",
        "value": "Pallet, CHEP 100 cm x 120 cm"
    },
    {
        "key": "OD",
        "value": "Pallet, AS 4068-1993"
    },
    {
        "key": "OE",
        "value": "Pallet, ISO T11"
    },
    {
        "key": "OF",
        "value": "Platform, unspecified weight or dimension"
    },
    {
        "key": "OK",
        "value": "Block"
    },
    {
        "key": "OT",
        "value": "Octabin"
    },
    {
        "key": "OU",
        "value": "Container, outer"
    },
    {
        "key": "P2",
        "value": "Pan"
    },
    {
        "key": "PA",
        "value": "Packet"
    },
    {
        "key": "PB",
        "value": "Pallet, box Combined open-ended box and pallet"
    },
    {
        "key": "PC",
        "value": "Parcel"
    },
    {
        "key": "PD",
        "value": "Pallet, modular, collars 80cms * 100cms"
    },
    {
        "key": "PE",
        "value": "Pallet, modular, collars 80cms * 120cms"
    },
    {
        "key": "PF",
        "value": "Pen"
    },
    {
        "key": "PG",
        "value": "Plate"
    },
    {
        "key": "PH",
        "value": "Pitcher"
    },
    {
        "key": "PI",
        "value": "Pipe"
    },
    {
        "key": "PJ",
        "value": "Punnet"
    },
    {
        "key": "PK",
        "value": "Package"
    },
    {
        "key": "PL",
        "value": "Pail"
    },
    {
        "key": "PN",
        "value": "Plank"
    },
    {
        "key": "PO",
        "value": "Pouch"
    },
    {
        "key": "PP",
        "value": "Piece"
    },
    {
        "key": "PR",
        "value": "Receptacle, plastic"
    },
    {
        "key": "PT",
        "value": "Pot"
    },
    {
        "key": "PU",
        "value": "Tray"
    },
    {
        "key": "PV",
        "value": "Pipes, in bundle/bunch/truss"
    },
    {
        "key": "PX",
        "value": "Pallet"
    },
    {
        "key": "PY",
        "value": "Plates, in bundle/bunch/truss"
    },
    {
        "key": "PZ",
        "value": "Planks, in bundle/bunch/truss"
    },
    {
        "key": "QA",
        "value": "Drum, steel, non-removable head"
    },
    {
        "key": "QB",
        "value": "Drum, steel, removable head"
    },
    {
        "key": "QC",
        "value": "Drum, aluminium, non-removable head"
    },
    {
        "key": "QD",
        "value": "Drum, aluminium, removable head"
    },
    {
        "key": "QF",
        "value": "Drum, plastic, non-removable head"
    },
    {
        "key": "QG",
        "value": "Drum, plastic, removable head"
    },
    {
        "key": "QH",
        "value": "Barrel, wooden, bung type"
    },
    {
        "key": "QJ",
        "value": "Barrel, wooden, removable head"
    },
    {
        "key": "QK",
        "value": "Jerrican, steel, non-removable head"
    },
    {
        "key": "QL",
        "value": "Jerrican, steel, removable head"
    },
    {
        "key": "QM",
        "value": "Jerrican, plastic, non-removable head"
    },
    {
        "key": "QN",
        "value": "Jerrican, plastic, removable head"
    },
    {
        "key": "QP",
        "value": "Box, wooden, natural wood, ordinary"
    },
    {
        "key": "QQ",
        "value": "Box, wooden, natural wood, with sift proof walls"
    },
    {
        "key": "QR",
        "value": "Box, plastic, expanded"
    },
    {
        "key": "QS",
        "value": "Box, plastic, solid"
    },
    {
        "key": "RD",
        "value": "Rod"
    },
    {
        "key": "RG",
        "value": "Ring"
    },
    {
        "key": "RJ",
        "value": "Rack, clothing hanger"
    },
    {
        "key": "RK",
        "value": "Rack"
    },
    {
        "key": "RL",
        "value": "Reel"
    },
    {
        "key": "RO",
        "value": "Roll"
    },
    {
        "key": "RT",
        "value": "Rednet"
    },
    {
        "key": "RZ",
        "value": "Rods, in bundle/bunch/truss"
    },
    {
        "key": "SA",
        "value": "Sack"
    },
    {
        "key": "SB",
        "value": "Slab"
    },
    {
        "key": "SC",
        "value": "Crate, shallow"
    },
    {
        "key": "SD",
        "value": "Spindle"
    },
    {
        "key": "SE",
        "value": "Sea-chest"
    },
    {
        "key": "SH",
        "value": "Sachet"
    },
    {
        "key": "SI",
        "value": "Skid"
    },
    {
        "key": "SK",
        "value": "Case, skeleton"
    },
    {
        "key": "SL",
        "value": "Slipsheet"
    },
    {
        "key": "SM",
        "value": "Sheetmetal"
    },
    {
        "key": "SO",
        "value": "Spool"
    },
    {
        "key": "SP",
        "value": "Sheet, plastic wrapping"
    },
    {
        "key": "SS",
        "value": "Case, steel"
    },
    {
        "key": "ST",
        "value": "Sheet"
    },
    {
        "key": "SU",
        "value": "Suitcase"
    },
    {
        "key": "SV",
        "value": "Envelope, steel"
    },
    {
        "key": "SW",
        "value": "Shrinkwrapped"
    },
    {
        "key": "SX",
        "value": "Set"
    },
    {
        "key": "SY",
        "value": "Sleeve"
    },
    {
        "key": "SZ",
        "value": "Sheets, in bundle/bunch/truss"
    },
    {
        "key": "T1",
        "value": "Tablet"
    },
    {
        "key": "TB",
        "value": "Tub"
    },
    {
        "key": "TC",
        "value": "Tea-chest"
    },
    {
        "key": "TD",
        "value": "Tube, collapsible"
    },
    {
        "key": "TE",
        "value": "Tyre"
    },
    {
        "key": "TG",
        "value": "Tank container, generic"
    },
    {
        "key": "TI",
        "value": "Tierce"
    },
    {
        "key": "TK",
        "value": "Tank, rectangular"
    },
    {
        "key": "TL",
        "value": "Tub, with lid"
    },
    {
        "key": "TN",
        "value": "Tin"
    },
    {
        "key": "TO",
        "value": "Tun"
    },
    {
        "key": "TR",
        "value": "Trunk"
    },
    {
        "key": "TS",
        "value": "Truss"
    },
    {
        "key": "TT",
        "value": "Bag, tote"
    },
    {
        "key": "TU",
        "value": "Tube"
    },
    {
        "key": "TV",
        "value": "Tube, with nozzle"
    },
    {
        "key": "TW",
        "value": "Pallet, triwall"
    },
    {
        "key": "TY",
        "value": "Tank, cylindrical"
    },
    {
        "key": "TZ",
        "value": "Tubes, in bundle/bunch/truss"
    },
    {
        "key": "UC",
        "value": "Uncaged"
    },
    {
        "key": "UN",
        "value": "Unit"
    },
    {
        "key": "VA",
        "value": "Vat"
    },
    {
        "key": "VG",
        "value": "Bulk, gas (at 1031 mbar and 15 degrees celsius)"
    },
    {
        "key": "VI",
        "value": "Vial"
    },
    {
        "key": "VK",
        "value": "Vanpack"
    },
    {
        "key": "VL",
        "value": "Bulk, liquid"
    },
    {
        "key": "VN",
        "value": "Vehicle"
    },
    {
        "key": "VO",
        "value": "Bulk, solid, large particles ('nodules')"
    },
    {
        "key": "VP",
        "value": "Vacuum-packed"
    },
    {
        "key": "VQ",
        "value": "Bulk, liquefied gas (at abnormal temperature/pressure)"
    },
    {
        "key": "VR",
        "value": "Bulk, solid, granular particles ('grains')"
    },
    {
        "key": "VS",
        "value": "Bulk, scrap metal"
    },
    {
        "key": "VY",
        "value": "Bulk, solid, fine particles ('powders')"
    },
    {
        "key": "WA",
        "value": "Intermediate bulk container"
    },
    {
        "key": "WB",
        "value": "Wickerbottle"
    },
    {
        "key": "WC",
        "value": "Intermediate bulk container, steel"
    },
    {
        "key": "WD",
        "value": "Intermediate bulk container, aluminium"
    },
    {
        "key": "WF",
        "value": "Intermediate bulk container, metal"
    },
    {
        "key": "WG",
        "value": "Intermediate bulk container, steel, pressurised > 10 kpa"
    },
    {
        "key": "WH",
        "value": "Intermediate bulk container, aluminium, pressurised > 10 kpa"
    },
    {
        "key": "WJ",
        "value": "Intermediate bulk container, metal, pressure 10 kpa"
    },
    {
        "key": "WK",
        "value": "Intermediate bulk container, steel, liquid"
    },
    {
        "key": "WL",
        "value": "Intermediate bulk container, aluminium, liquid"
    },
    {
        "key": "WM",
        "value": "Intermediate bulk container, metal, liquid"
    },
    {
        "key": "WN",
        "value": "Intermediate bulk container, woven plastic, without coat/liner"
    },
    {
        "key": "WP",
        "value": "Intermediate bulk container, woven plastic, coated"
    },
    {
        "key": "WQ",
        "value": "Intermediate bulk container, woven plastic, with liner"
    },
    {
        "key": "WR",
        "value": "Intermediate bulk container, woven plastic, coated and liner"
    },
    {
        "key": "WS",
        "value": "Intermediate bulk container, plastic film"
    },
    {
        "key": "WT",
        "value": "Intermediate bulk container, textile without coat/liner"
    },
    {
        "key": "WU",
        "value": "Intermediate bulk container, natural wood, with inner liner"
    },
    {
        "key": "WV",
        "value": "Intermediate bulk container, textile, coated"
    },
    {
        "key": "WW",
        "value": "Intermediate bulk container, textile, with liner"
    },
    {
        "key": "WX",
        "value": "Intermediate bulk container, textile, coated and liner"
    },
    {
        "key": "WY",
        "value": "Intermediate bulk container, plywood, with inner liner"
    },
    {
        "key": "WZ",
        "value": "Intermediate bulk container, reconstituted wood, with inner liner"
    },
    {
        "key": "XA",
        "value": "Bag, woven plastic, without inner coat/liner"
    },
    {
        "key": "XB",
        "value": "Bag, woven plastic, sift proof"
    },
    {
        "key": "XC",
        "value": "Bag, woven plastic, water resistant"
    },
    {
        "key": "XD",
        "value": "Bag, plastics film"
    },
    {
        "key": "XF",
        "value": "Bag, textile, without inner coat/liner"
    },
    {
        "key": "XG",
        "value": "Bag, textile, sift proof"
    },
    {
        "key": "XH",
        "value": "Bag, textile, water resistant"
    },
    {
        "key": "XJ",
        "value": "Bag, paper, multi-wall"
    },
    {
        "key": "XK",
        "value": "Bag, paper, multi-wall, water resistant"
    },
    {
        "key": "YA",
        "value": "Composite packaging, plastic receptacle in steel drum"
    },
    {
        "key": "YB",
        "value": "Composite packaging, plastic receptacle in steel crate box"
    },
    {
        "key": "YC",
        "value": "Composite packaging, plastic receptacle in aluminium drum"
    },
    {
        "key": "YD",
        "value": "Composite packaging, plastic receptacle in aluminium crate"
    },
    {
        "key": "YF",
        "value": "Composite packaging, plastic receptacle in wooden box"
    },
    {
        "key": "YG",
        "value": "Composite packaging, plastic receptacle in plywood drum"
    },
    {
        "key": "YH",
        "value": "Composite packaging, plastic receptacle in plywood box"
    },
    {
        "key": "YJ",
        "value": "Composite packaging, plastic receptacle in fibre drum"
    },
    {
        "key": "YK",
        "value": "Composite packaging, plastic receptacle in fibreboard box"
    },
    {
        "key": "YL",
        "value": "Composite packaging, plastic receptacle in plastic drum"
    },
    {
        "key": "YM",
        "value": "Composite packaging, plastic receptacle in solid plastic box"
    },
    {
        "key": "YN",
        "value": "Composite packaging, glass receptacle in steel drum"
    },
    {
        "key": "YP",
        "value": "Composite packaging, glass receptacle in steel crate box"
    },
    {
        "key": "YQ",
        "value": "Composite packaging, glass receptacle in aluminium drum"
    },
    {
        "key": "YR",
        "value": "Composite packaging, glass receptacle in aluminium crate"
    },
    {
        "key": "YS",
        "value": "Composite packaging, glass receptacle in wooden box"
    },
    {
        "key": "YT",
        "value": "Composite packaging, glass receptacle in plywood drum"
    },
    {
        "key": "YV",
        "value": "Composite packaging, glass receptacle in wickerwork hamper"
    },
    {
        "key": "YW",
        "value": "Composite packaging, glass receptacle in fibre drum"
    },
    {
        "key": "YX",
        "value": "Composite packaging, glass receptacle in fibreboard box"
    },
    {
        "key": "YY",
        "value": "Composite packaging, glass receptacle in expandable plastic pack"
    },
    {
        "key": "YZ",
        "value": "Composite packaging, glass receptacle in solid plastic pack"
    },
    {
        "key": "ZA",
        "value": "Intermediate bulk container, paper, multi-wall"
    },
    {
        "key": "ZB",
        "value": "Bag, large"
    },
    {
        "key": "ZC",
        "value": "Intermediate bulk container, paper, multi-wall, water resistant"
    },
    {
        "key": "ZD",
        "value": "Intermediate bulk container, rigid plastic, with structural equipment, solids"
    },
    {
        "key": "ZF",
        "value": "Intermediate bulk container, rigid plastic, freestanding, solids"
    },
    {
        "key": "ZG",
        "value": "Intermediate bulk container, rigid plastic, with structural equipment, pressurised"
    },
    {
        "key": "ZH",
        "value": "Intermediate bulk container, rigid plastic, freestanding, pressurised"
    },
    {
        "key": "ZJ",
        "value": "Intermediate bulk container, rigid plastic, with structural equipment, liquids"
    },
    {
        "key": "ZK",
        "value": "Intermediate bulk container, rigid plastic, freestanding, liquids"
    },
    {
        "key": "ZL",
        "value": "Intermediate bulk container, composite, rigid plastic, solids"
    },
    {
        "key": "ZM",
        "value": "Intermediate bulk container, composite, flexible plastic, solids"
    },
    {
        "key": "ZN",
        "value": "Intermediate bulk container, composite, rigid plastic, pressurised"
    },
    {
        "key": "ZP",
        "value": "Intermediate bulk container, composite, flexible plastic, pressurised"
    },
    {
        "key": "ZQ",
        "value": "Intermediate bulk container, composite, rigid plastic, liquids"
    },
    {
        "key": "ZR",
        "value": "Intermediate bulk container, composite, flexible plastic, liquids"
    },
    {
        "key": "ZS",
        "value": "Intermediate bulk container, composite"
    },
    {
        "key": "ZT",
        "value": "Intermediate bulk container, fibreboard"
    },
    {
        "key": "ZU",
        "value": "Intermediate bulk container, flexible"
    },
    {
        "key": "ZV",
        "value": "Intermediate bulk container, metal, other than steel"
    },
    {
        "key": "ZW",
        "value": "Intermediate bulk container, natural wood"
    },
    {
        "key": "ZX",
        "value": "Intermediate bulk container, plywood"
    },
    {
        "key": "ZY",
        "value": "Intermediate bulk container, reconstituted wood"
    },
    {
        "key": "ZZ",
        "value": "Mutually defined"
    }
]

export default goodsMeasureTypesOfPackages;