import { useContext, useRef, useState } from "react";
import ThemeContext from "../../../context/theme/ThemeContext";
import { PiFunnel } from "react-icons/pi";
import { Overlay } from "react-bootstrap";
import FilterInput from "./Input";
import Badge from "../Badge";

const FilterButton = ({ value, filterInputs, style, onChange }) => {
	const overlayTarget = useRef(null);
	const [isOverlayOpen, setIsOverlayOpen] = useState(false);
	const { withTheme } = useContext(ThemeContext);

	const handleInputChange = (name, inputValue) => {
		if (!inputValue || (Array.isArray(inputValue) && !inputValue.length)) {
			delete value[name];
			onChange({ ...value });
		} else {
			onChange({ ...value, [name]: inputValue });
		}
	};

	return (
		<>
			<Overlay
				placement="bottom"
				rootClose={true}
				target={overlayTarget}
				onHide={() => setIsOverlayOpen(false)}
				show={isOverlayOpen}
			>
				{({ placement, arrowProps, show: _show, popper, ...props }) => (
					<div {...props} className={withTheme("overlay-body")}>
						{filterInputs.map((input) => (
							<FilterInput
								input={input}
								value={value[input.name]}
								onChange={handleInputChange}
							/>
						))}
					</div>
				)}
			</Overlay>

			<Badge count={Object.values(value).length}>
				<div
					ref={overlayTarget}
					style={style}
					className={withTheme("filter-button")}
					onClick={() => setIsOverlayOpen(true)}
				>
					<PiFunnel fontSize={20} />
				</div>
			</Badge>
		</>
	);
};

export default FilterButton;
