const obligations =
{
    rules: [
        {
            if: [{ "declarationCategory": ["H2","H3","H4","H5","C21I","C21IEIDR","FSD"]}],
            then: [
                "documentsProducedAWrittingOffIssuingAuth.isDisabled",
                "documentsProducedAWrittingOffValidityDate.isDisabled",
                "documentsProducedAWrittingOffQuantity.isDisabled",
                "writingOffMeasurmentUnitAndQualifier.isDisabled",
            ]
        },
        {
            if: [{ "declarationCategory": ["H1", "H2", "H3", "H4", "H5", "I1", "I1 (B&E)", "C21I", "C21I EIDR", "SRDS"] }],
            then: ["requestedAdditionalProceedureMultiline.isMandatory",
                "additionalInformation.isMandatory",
            ],
        },
        {
            if: [{ "declarationCategory": ["H1", "H2", "H3", "H4", "H5", "I1", "I1 (B&E)", "C21I", "C21I EIDR", "SRDS"] }],
            then: ["simplifiedDeclaration.isMandatory"],
        },
        {
            if: [{ "declarationCategory": ["H2", "H3", "H4", "I1", "C21I EIDR"] }],
            then: ["documentsProduced.isMandatory"],
        },
        {
            if: [{ "declarationCategory": ["FSD", "SRDS"] }],
            then: ["additionalInformation.isMandatory"],
        },
        {
            if: [{ "declarationCategory": ["FSD"] }],
            then: ["additionalFiscalReferences.isDisabled"],
        },
        {
            if: [{ "declarationCategory": ["C21I", "C21I EIDR", "FSD", "SRDS"] }],
            then: ["additionalSupplyChainActors.isDisabled"],
        },
        {
            if: [{ "declarationCategory": ["H1", "H3", "H4", "H5", "I1", "I1 (B&E)", "C21I", "C21I EIDR"] }],
            then: ["itemPriceAmount.isMandatory",
                "itemPriceCurrency.isMandatory"],
        },
        {
            if: [{ "declarationCategory": ["H1", "H3", "H4", "H5", "I1 (B&E)"] }],
            then: ["dutyRegimentCode.isMandatory"],
        },
        {
            if: [{ "declarationCategory": ["H3", "H5", "I1 (B&E)"] }],
            then: ["valuationMethodCode.isMandatory"],
        },
        {
            if: [{ "declarationCategory": ["H1", "H2", "H3", "H4", "H5", "I1", "I1 (B&E)"] }],
            then: ["countryOfOrigin.isMandatory"],
        },
        {
            if: [{ "declarationCategory": ["H1", "H4", "H5", "I1 (B&E)"] }],
            then: ["netWeightMeasure.isMandatory",],
        },
        {
            if: [{ "declarationCategory": ["H1", "H2", "H3", "H4", "H5", "I1", "C21I EIDR", "SRDS"] }],
            then: ["grossMassMeasure.isMandatory"],
        },
        {
            if: [{ "declarationCategory": ["H1", "H2", "H3", "H4", "H5", "I1", "I1 (B&E)", "SRDS"] }],
            then: ["goodsMeasureDescriptionOfGoods.isMandatory"],
        },
        {
            if: [{ "declarationCategory": ['SRDS'] }],
            then: [
                'declaredCustomsValueAmount.isMandatory',
                'declaredCustomsValueCurrency.isMandatory'
            ]
        },
        {
            if: [{ "declarationCategory": ["H1", "H2", "H3", "H4", "H5", "I1", "I1 (B&E)", "C21I EIDR"] }],
            then: ["descriptionOfPackaging.isMandatory"],
        },
        {
            if: [{ "declarationCategory": ["H1", "H2", "H3", "H4", "H5", "I1", "I1 (B&E)", "C21I EIDR"] }],
            then: ["commodityCodes.isMandatory"],
        },
        {
            if: [{ "declarationCategory": ["H4"] }],
            then: ["statisticalValues.isMandatory"]
        },
        {
            if: [{ "originTypeCode": ["1", null] }],
            then: ["countryOfPreferentialOrigin.isDisabled"]
        },
        {
            if: [{ "originTypeCode": ["2"] }],
            then: ["countryOfOrigin.isDisabled"]
        },
        {
            if: [{ "procedureCode": ["0100"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.ECONR,OSD01,PAL01,PAL02,PAL03",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C505,C506,1207,N703,N704,N705,N710,N714,N720,N722,N730,N740,N741,N750,N760,N785,N787",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.1BN,1CD,1CG,1CL,1ES,1SC,63P,E01,E02,F06,F15,F21,F22"
            ]
        },
        {
            if: [{ "procedureCode": ["0121"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,ECONR,GEN45,OVR01,OSD01",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C505,C506,1207,C019,9WKS,N703,N704,N705,N710,N714,N720,N722,N730,N740,N741,N750,N760,N785,N787",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.1CD,1CG,46P,48P,B02,B03,F06,F15"
            ]
        },
        {
            if: [{ "procedureCode": ["0151"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,10200,10300,ECONR,GEN45,GEN86,OVR01,OSD01",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C505,C506,1207,C601,9WKS,N703,N704,N705,N710,N714,N720,N722,N730,N740,N741,N750,N760,N785,N787",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.1CD,1CG,A04,F06,F15,F44"
            ]
        },
        {
            if: [{ "procedureCode": ["0153"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,10500,ECONR,GEN45,OVR01,OSD01",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C505,C506,1207,C516,N703,N704,N705,N710,N714,N720,N722,N730,N740,N741,N750,N760,N785,N787",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.1CD,1CG,F06,F15"
            ]
        },
        {
            if: [{ "procedureCode": ["0154"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.10200,10300,ECONR,GEN45,GEN86,OVR01,OSD01",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C505,C506,1207,C601,C603,N703,N704,N705,N710,N714,N720,N722,N730,N740,N741,N750,N760,N785,N787",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.A04,F06,F15,F44"
            ]
        },
        {
            if: [{ "procedureCode": ["0171"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.ECONR,OSD01,PREMS",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C505,C506,1207,C517,C518,C519,N703,N704,N705,N710,N714,N720,N722,N730,N740,N741,N750,N760,N785,N787",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.1CD,1CG,1VW,1XW,63P,E01,E02,F06,F15,F21,F22"
            ]
        },
        {
            if: [{ "procedureCode": ["0178"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.ECONR,OSD01,PREMS",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C505,C506,1207,C600,N703,N704,N705,N710,N714,N720,N722,N730,N740,N741,N750,N760,N785,N787",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.1CD,1CG,E01,E02,F06,F15,F21,F22"
            ]
        },
        {
            if: [{ "procedureCode": ["0700"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.ECONR,PAL01,PAL02,PAL03",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C505,C506,1207,C676",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.1CD,1CG,1EX,68P,95P,F06,F15"
            ]
        },
        {
            if: [{ "procedureCode": ["0721"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,ECONR,GEN45,OVR01",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C505,C506,1207,C019,9WKS,C604,C676",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.1CD,1CG,1EX,46P,48P,B02,B03,F06"
            ]
        },
        {
            if: [{ "procedureCode": ["0751"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,10200,10300,ECONR,GEN45,GEN86,OVR01",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C505,C506,1207,C601,C676,9WKS",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.1CD,1CG,1EX,F06,F15,F44"
            ]
        },
        {
            if: [{ "procedureCode": ["0753"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,10500,ECONR,GEN45,OVR01",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C505,C506,1207,C516,C676",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.1CD,1CG,F06,F15"
            ]
        },
        {
            if: [{ "procedureCode": ["0754"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.10200,10300,ECONR,GEN45,GEN86,OVR01",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C505,C506,1207,C676,C601,C603,9WKS",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.1EX,F06,F44"
            ]
        },
        {
            if: [{ "procedureCode": ["0771"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.ECONR,PREMS",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C505,C506,1207,C676,C517,C518,C519",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1CD,1CG,1EX,1XW,68P,95P,F06,F15"
            ]
        },
        {
            if: [{ "procedureCode": ["0778"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.ECONR,PREMS",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C676,C600,C505,C506,1207",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.1CD,1CG,1EX,95P,F06,F15"
            ]
        },
        {
            if: [{ "procedureCode": ["4000"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.PAL01,PAL02,PAL03",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C505,C506,1207",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,0GD,1BN,1CD,1CG,1CL,1DP,1ES,1IL,1LV,1MO,1NC,1NN,1NO,1NP,1NV,1PF,1PP,1RC,1RE,1RV,1SC,1TO,2DP,2LV,C01,C02,C03,C04,C06,C07,C08,C09,C10,C11,C12,C13,C14,C15,C16,C17,C18,C19,C20,C21,C22,C23,C24,C25,C26,C27,C28,C29,C30,C31,C32,C33,C34,C35,C36,C37,C38,C39,C40,C41,C42,C43,C44,C45,C46,C47,C48,C49,C50,C51,C52,C53,C54,C55,C56,C57,C58,C59,C60,C61,E01,E02,F15,F21,F22,F45"
            ]
        },
        {
            if: [{ "procedureCode": ["4051"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,10200,10300,ECONR,GEN45,GEN86,OVR01",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C505,C506,C601,1207,9WKS",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1CL,1MO,1RE,A04,C12,C13,C14,C15,C16,C17,C18,C19,C20,C21,C22,C23,C24,C25,C26,C29,C30,C31,C32,C33,C34,C35,C36,C40,C58,C59,F15,F44,F45"
            ]
        },
        {
            if: [{ "procedureCode": ["4053"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,10500,GEN45,OVR01,AUC01",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C505,C506,C516,1207",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1RE,1RV,1XT,C11,C12,C13,C14,C15,C16,C17,C18,C19,C20,C21,C23,C25,C26,C30,C31,C32,C33,C34,C35,C36,C40,C44,C56,C57,C58,C59,C60,C61,F15,F45"
            ]
        },
        {
            if: [{ "procedureCode": ["4054"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.10200,10300,GEN45,GEN86,OVR01",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C505,C506,1207,C601,C603",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1RE,A04,C11,C12,C13,C14,C15,C16,C17,C18,C19,C20,C21,C22,C23,C24,C25,C26,C29,C30,C31,C32,C33,C34,C35,C36,C40,C58,C59,F44,F45"
            ]
        },
        {
            if: [{ "procedureCode": ["4071"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.PREMS",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C505,C506,1207,C517,C518,C519",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,0GD,1CL,1DP,1ES,1MO,1NC,1NN,1NO,1NP,1NV,1PF,1RC,1RE,1RV,1TO,1VW,1XT,1XW,2DP,C01,C02,C03,C04,C06,C07,C08,C09,C10,C11,C12,C13,C14,C15,C16,C17,C18,C19,C20,C21,C22,C23,C24,C25,C26,C27,C28,C29,C30,C31,C32,C33,C34,C35,C36,C40,C41,C42,C43,C44,C45,C46,C47,C48,C49,C50,C51,C52,C53,C54,C55,C56,C57,C58,C59,C60,C61,E01,E02,F15,F21,F22,F45"
            ]
        },
        {
            if: [{ "procedureCode": ["4078"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.PREMS",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C505,C506,1207,C600",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,0GD,1CD,1CG,1CL,1DP,1ES,1MO,1NC,1NN,1NO,1NP,1NV,1PF,1RC,1RE,1RV,1TO,2DP,E01,C01,C02,C03,C04,C06,C07,C08,C09,C10,C11,C12,C13,C14,C15,C16,C17,C18,C19,C20,C21,C22,C23,C24,C25,C26,C27,C28,C29,C30,C31,C32,C33,C34,C35,C36,C40,C41,C42,C43,C44,C45,C46,C47,C48,C49,C50,C51,C52,C53,C54,C55,C56,C57,C58,C59,C60,C61,E02,F15,F21,F22,F45"
            ]
        },
        {
            if: [{ "procedureCode": ["4200"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.ECONR,OSR42,PAL01,PAL02,PAL03",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C505,C506,1207,N703,N704,N705,N710,N714,N720,N722,N730,N740,N741,N750,N760,N785,N787",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.E01,E02,F06,F15,F21,F22,000,1BN,1CD,1CG,1CL,1ES,1SC,63P"
            ]
        },
        {
            if: [{ "procedureCode": ["4221"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,ECONR,GEN45,OVR01,OSR42",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C505,C506,1207,C019,9WKS,C604,N703,N704,N705,N710,N714,N720,N722,N730,N740,N741,N750,N760,N785,N787",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1CD,1CG,46P,48P,B02,B03,B06,F06,F15"
            ]
        },
        {
            if: [{ "procedureCode": ["4251"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,10200,ECONR,GEN45,GEN86,OSR42,OVR01",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C601,C505,C506,1207,9WKS,N703,N704,N705,N710,N714,N720,N722,N730,N740,N741,N750,N760,N785,N787",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1CD,1CG,A04,F06,F15,F44"
            ]
        },
        {
            if: [{ "procedureCode": ["4253"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,10500,ECONR,GEN45,OVR01,OSR42",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C516,C505,C506,1207,N703,N704,N705,N710,N714,N720,N722,N730,N740,N741,N750,N760,N785,N787",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1CD,1CG,F06,F15"
            ]
        },
        {
            if: [{ "procedureCode": ["4254"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.10200,10300,ECONR,GEN45,GEN86,OVR01,OSR42",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C505,C506,1207,9WKS,C601,C603,N703,N704,N705,N710,N714,N720,N722,N730,N740,N741,N750,N760,N785,N787",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,A04,F06,F44"
            ]
        },
        {
            if: [{ "procedureCode": ["4271"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.ECONR,OSR42,PREMS",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C517,C518,C519,C505,C506,1207,N703,N704,N705,N710,N714,N720,N722,N730,N740,N741,N750,N760,N785,N787",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1CD,1CG,1VW,1XW,63P,E01,E02,F06,F15,F21,F22"
            ]
        },
        {
            if: [{ "procedureCode": ["4278"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.ECONR,OSR42,PREMS",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C600,C505,C506,1207,N703,N704,N705,N710,N714,N720,N722,N730,N740,N741,N750,N760,N785,N787",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1CD,1CG,E01,E02,F06,F15,F21,F22"
            ]
        },
        {
            if: [{ "procedureCode": ["4400"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C990,N990,C505,C506,1207",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1RL,1RV,1SW,2CD,2CG,2MO,E01,E02"
            ]
        },
        {
            if: [{ "procedureCode": ["4421"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,OVR01",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C990,C604,9WKS,C019,N990,C505,C506,1207",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1RV,1SW*,2CD,2CG,2MO*,46P"
            ]
        },
        {
            if: [{ "procedureCode": ["4422"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,OVR01",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C990,N990,C604,9WKS,C019,C505,C506,1207",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1RV,1SW,2CD,2CG,2MO,46P,48P,B02,B03"
            ]
        },
        {
            if: [{ "procedureCode": ["4451"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,10200,10300,GEN45,GEN86,OVR01",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C990,N990,C601,9WKS,C505,C506,1207",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1RV,1SW,2MO,F44"
            ]
        },
        {
            if: [{ "procedureCode": ["4453"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,10500,GEN45,OVR01",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C990,N990,C505,C506,1207,C516",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1RV,1SW,1XT"
            ]
        },
        {
            if: [{ "procedureCode": ["4454"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,10200,10300,GEN45,GEN86,OVR01",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C990,N990,C601,C603,C505,C506,1207,9WKS",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1RV,1SW,F44"
            ]
        },
        {
            if: [{ "procedureCode": ["4471"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,PREMS",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C990,N990,C517,C518,C519,C505,C506,1207",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1RL,1RV,1SW,1XT,1XW,2MO,E01,E02"
            ]
        },
        {
            if: [{ "procedureCode": ["4478"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,PREMS",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.,C600,C990,N990,C505,C506,1207",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1RL,1RV,1SW,2CD,2CG,2MO,E01,E02"
            ]
        },
        {
            if: [{ "procedureCode": ["5100"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,GEN86,ECO01,ECO02,ECO03,ECO04,ECO05,ECO06,ECO07,ECO08,ECO09,ECO10,ECO11,ECO12,ECO13,ECO14,ECO15,ECO16,ECO17,ECO18,ECO19,ECO20,ECO21,ECO22",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C601,C505,C506,1207",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1IE,1PO,2CD,2CG,3HG,A04,F15,F44"
            ]
        },
        {
            if: [{ "procedureCode": ["5111"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.GEN45,GEN86,ECO01,ECO02,ECO03,ECO04,ECO05,ECO06,ECO07,ECO08,ECO09,ECO10,ECO11,ECO12,ECO13,ECO14,ECO15,ECO16,ECO17,ECO18,ECO19,ECO20,ECO21,ECO22",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C601,C505,C606",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1MP,1PO,2CD,2CG,F44"
            ]
        },
        {
            if: [{ "procedureCode": ["5121"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN19,GEN45,GEN86,ECO01,ECO02,ECO03,ECO04,ECO05,ECO06,ECO07,ECO08,ECO09,ECO10,ECO11,ECO12,ECO13,ECO14,ECO15,ECO16,ECO17,ECO18,ECO19,ECO20,ECO21,ECO22",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C601,C505,C604,C019,C506,1207",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1IE,2CD,2CG,46P,48P,B02,B03,F44"
            ]
        },
        {
            if: [{ "procedureCode": ["5151"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,10200,GEN45,GEN86,ECO01,ECO02,ECO03,ECO04,ECO05,ECO06,ECO07,ECO08,ECO09,ECO10,ECO11,ECO12,ECO13,ECO14,ECO15,ECO16,ECO17,ECO18,ECO19,ECO20,ECO21,ECO22",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C601,C505,C506,1207",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1IE,1PO,A04,F15,F44"
            ]
        },
        {
            if: [{ "procedureCode": ["5153"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,10500,GEN45,GEN86,TAPAR,ECO01,ECO02,ECO03,ECO04,ECO05,ECO06,ECO07,ECO08,ECO09,ECO10,ECO11,ECO12,ECO13,ECO14,ECO15,ECO16,ECO17,ECO18,ECO19,ECO20,ECO21,ECO22",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C601,C505,C516,C506,1207",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1IE,A04,F15,F44"
            ]
        },
        {
            if: [{ "procedureCode": ["5154"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.10200,GEN45,GEN86,ECO01,ECO02,ECO03,ECO04,ECO05,ECO06,ECO07,ECO08,ECO09,ECO10,ECO11,ECO12,ECO13,ECO14,ECO15,ECO16,ECO17,ECO18,ECO19,ECO20,ECO21,ECO22",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C601,C505,C603",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1IE,A04,F44"
            ]
        },
        {
            if: [{ "procedureCode": ["5171"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,GEN86,PREMS,ECO01,ECO02,ECO03,ECO04,ECO05,ECO06,ECO07,ECO08,ECO09,ECO10,ECO11,ECO12,ECO13,ECO14,ECO15,ECO16,ECO17,ECO18,ECO19,ECO20,ECO21,ECO22",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C601,C505,C506,1207,C517,C518,C519",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1IE,1PO,1VW,1XW,A04,F15,F44"
            ]
        },
        {
            if: [{ "procedureCode": ["5178"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,GEN86,PREMS,ECO01,ECO02,ECO03,ECO04,ECO05,ECO06,ECO07,ECO08,ECO09,ECO10,ECO11,ECO12,ECO13,ECO14,ECO15,ECO16,ECO17,ECO18,ECO19,ECO20,ECO21,ECO22",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C601,C505,C600,C506,1207",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1IE,1PO,2CD,2CG,A04,F15,F44"
            ]
        },
        {
            if: [{ "procedureCode": ["5300"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,GEN21,GEN24,GEN27,GEN28,GEN29,GEN30,GEN31,GEN32,GEN33,GEN34,GEN35,GEN36,PAL01,PAL02,PAL03,OVR01",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C516,C505,C506,1207",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.D01,D02,D03,D04,D05,D06,D07,D08,D09,D10,D11,D12,D13,D15,D16,D17,D18,D19,D20,D21,D22,D23,D24,D25,D26,D27,D28,D29,D30,D51,F15"
            ]
        },
        {
            if: [{ "procedureCode": ["5351"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,10200,10300,GEN45,GEN21,GEN24,GEN27,GEN28,GEN29,GEN30,GEN31,GEN32,GEN33,GEN34,GEN35,GEN36,PAL01,PAL02,PAL03,GEN86,OVR01",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C516,C505,C506,1207,C601,9WKS",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.D01,D02,D03,D05,D06,D07,D09,D10,D11,D12,D13,D15,D16,D17,D18,D19,D20,D21,D22,D23,D24,D25,D26,D27,D28,D29,D30,D51,F15,F44"
            ]
        },
        {
            if: [{ "procedureCode": ["5353"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,10500,GEN45,GEN21,GEN24,GEN27,GEN28,GEN29,GEN30,GEN31,GEN32,GEN33,GEN34,GEN35,GEN36,PAL01,PAL02,PAL03,TAPAR,OVR01",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C516,C505,C506,1207",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.D01,D02,D03,D05,D06,D07,D08,D09,D10,D11,D12,D13,D15,D16,D17,D18,D19,D20,D21,D22,D23,D24,D25,D26,D27,D28,D29,D30,D51,F15"
            ]
        },
        {
            if: [{ "procedureCode": ["5354"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,10200,10300,GEN45,GEN21,GEN24,GEN27,GEN28,GEN29,GEN30,GEN31,GEN32,GEN33,GEN34,GEN35,GEN36,PAL01,PAL02,PAL03,GEN86,OVR01",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C516,C601,C603,C505,C506,1207,9WKS",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.D01,D02,D03,D05,D06,D07,D09,D10,D11,D12,D13,D15,D16,D17,D18,D19,D20,D21,D22,D23,D24,D25,D26,D27,D28,D29,D30,D51,F44"
            ]
        },
        {
            if: [{ "procedureCode": ["5371"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,GEN21,GEN24,GEN27,GEN28,GEN29,GEN30,GEN31,GEN32,GEN33,GEN34,GEN35,GEN36,PAL01,PAL02,PAL03,OVR01,PREMS",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C516,C517,C518,C519,C505,C506,1207",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.D01,D02,D03,D05,D06,D07,D08,D09,D10,D11,D12,D13,D15,D16,D17,D18,D19,D20,D21,D22,D23,D24,D25,D26,D27,D28,D29,D30,D51,F15"
            ]
        },
        {
            if: [{ "procedureCode": ["5378"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,GEN21,GEN24,GEN27,GEN28,GEN29,GEN30,GEN31,GEN32,GEN33,GEN34,GEN35,GEN36,PAL01,PAL02,PAL03,OVR01,PREMS",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C516,C600,C505,C506,1207",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.D01,D02,D03,D05,D06,D07,D08,D09,D10,D11,D12,D13,D15,D16,D17,D18,D19,D20,D21,D22,D23,D24,D25,D26,D27,D28,D29,D30,D51,F15"
            ]
        },
        {
            if: [{ "procedureCode": ["6110"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.GEN03,GEN3C,GEN45,OVR01,PAL05,PREMS",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C605,1314,1246,C517,C518,C519,C505,C506,1207",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,0GD,1CD,1CG,1RC,1RV,1VW,1XW,F01,F02,F03,F05,F15"
            ]
        },
        {
            if: [{ "procedureCode": ["6111"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.GEN03,GEN45,GEN86,OVR01,PAL05,PREMS",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C605,1314,1246,C601,C603,C606,9WKS,C517,C518,C519,C505,C506,1207",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1CD,1CG,1XW,F01,F05,F44"
            ]
        },
        {
            if: [{ "procedureCode": ["6121"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,OVR01,PREMS",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C604,C019,9WKS,C517,C518,C519,C505,C506,1207",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1CD,1CG,1MO,1XW,46P"
            ]
        },
        {
            if: [{ "procedureCode": ["6122"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,OVR01,PREMS",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C604,C019,9WKS,C517,C518,C519,C505,C506,1207",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1CD,1CG,1MO,1VW,1XW,46P,48P,B02,B03,B06,F15"
            ]
        },
        {
            if: [{ "procedureCode": ["6123"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.GEN03,GEN3C,GEN45,OVR01,PAL05,PREMS",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C605,1314,1246,C517,C518,C519,C505,C506,1207",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,0GD,1CD,1CG,1RC,1RV,1VW,1XW,F01,F02,F03,F05,F15"
            ]
        },
        {
            if: [{ "procedureCode": ["6131"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.GEN03,GEN45,GEN86,OVR01,PAL05,PREMS",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C605,1314,1246,C601,C603,9WKS,C517,C518,C519,C505,C506,1207",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,0GD,1CD,1CG,1RC,1RV,1VW,1XW,A04,F04,F07,F15"
            ]
        },
        {
            if: [{ "procedureCode": ["7100"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.PREMS,WHSRP",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C517,C518,C519",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1VW,2CD,2CG,F15"
            ]
        },
        {
            if: [{ "procedureCode": ["7110"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.PREMS,WHSRP",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C517,C518,C519",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1VW,2CD,2CG,F15"
            ]
        },
        {
            if: [{ "procedureCode": ["7121"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,PREMS,WHSRP",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C517,C518,C519,C019",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,2CD,2CG,46P"
            ]
        },
        {
            if: [{ "procedureCode": ["7122"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,PREMS,WHSRP",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C517,C518,C519,C019",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1VW,2CD,2CG,46P,48P,B02,B03,B06,F15"
            ]
        },
        {
            if: [{ "procedureCode": ["7123"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.GEN03,GEN3C,GEN45,GEN86,PREMS,WHSRP",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C517,C518,C519,1246",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1RV,1VW,2CD,2CG,F01,F02,F03,F04,F05,F07,F15"
            ]
        },
        {
            if: [{ "procedureCode": ["7151"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,10200,GEN45,GEN86,PREMS,WHSRP",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C517,C518,C519,C601",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1VW,A04,F15,F44"
            ]
        },
        {
            if: [{ "procedureCode": ["7153"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,10500,GEN45,PREMS,WHSRP",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C517,C518,C519,C516",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1VW,F15"
            ]
        },
        {
            if: [{ "procedureCode": ["7154"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.10200,GEN45,GEN86,PREMS,WHSRP",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C517,C518,C519,C601",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,A04,F44"
            ]
        },
        {
            if: [{ "procedureCode": ["7171"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.PREMS,WHSRP",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C517,C518,C519",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1VW,F15"
            ]
        },
        {
            if: [{ "procedureCode": ["7178"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.PREMS,WHSRP",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C517,C518,C519,C600",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,1VW,2CD,2CG,F15"
            ]
        },
        {
            if: [{ "procedureCode": ["7800", "7851", "7853", "7871", "7878"] }],
            then: [
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,F15,1VW,2CD,2CG",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C600",
            ]
        },
        {
            if: [{ "service": ["gbToNiAtRisk"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.NIDOM",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.U110,U111,U112",
                "valuatations.dutyRegimentCode.mustHave.300,310,315,318,320,323,325,340,350"
            ]
        },
        {
            if: [{ "service": ["gbToNiNotAtRisk"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.NIDOM,NIREM"
            ]
        },
        {
            if: [{ "service": ["rowToNiAtRisk"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.NIREM",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.U110,U111,U112",
                "valuatations.dutyRegimentCode.mustHave.300,310,315,318,320,323,325,340,350"
            ]
        },
        {
            if: [{ "service": ["finalSupplementary"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.FINSY,FINSZ,FINSL"
            ]
        },
        {
            if: [{ "procedureCode": ["1007"], "service": ["export"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.ECONR,ECONE,PAL06,PAL07,PAL08,PAL12",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C676,C651",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.F75,000,1CG"
            ]
        },
        {
            if: [{ "procedureCode": ["1040"], "service": ["export"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.PAL06,PAL07,PAL08,PAL12",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.C12,F75,000,0GD,0MG,1CD,1CG,1DP,1NO,1NP,2DP,2IE,3LV,3NS,3PE,3PM,3RI"
            ]
        },
        {
            if: [{ "procedureCode": ["1042"], "service": ["export"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.ECONR,ECONE,OSD01,OSR42,PAL06,PAL07,PAL08,PAL12",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C676,C651",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.F75,000,1CD,1CG,2ES"
            ]
        },
        {
            if: [{ "procedureCode": ["1044"], "service": ["export"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,30300,GEN39,GEN45,NSTAT",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C990,N990",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,0MG,1SW,2CD,2CG"
            ]
        },
        {
            if: [{ "procedureCode": ["1100"], "service": ["export"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.GEN45,GEN86,NSTAT",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C601,C606",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.F44,000,1MP"
            ]
        },
        {
            if: [{ "procedureCode": ["2100"], "service": ["export"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN39,GEN45,NSTAT",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C604,C019",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,0GD,46P"
            ]
        },
        {
            if: [{ "procedureCode": ["2144"], "service": ["export"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN39,GEN45,NSTAT",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C604,C019,C990,N990",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.000,0MG,1SW,46P"
            ]
        },
        {
            if: [{ "procedureCode": ["2151"], "service": ["export"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,ECONR,ECONE,GEN39,GEN45,GEN86,NSTAT",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C604,C019,C601,C651",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.B51,B52,F44,000,0GD,46P,48P"
            ]
        },
        {
            if: [{ "procedureCode": ["2154"], "service": ["export"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,ECONR,ECONE,GEN39,GEN45,GEN86,NSTAT",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C604,C019,C601,C603,C651",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.B51,B52,F44,000,46P,48P"
            ]
        },
        {
            if: [{ "procedureCode": ["2200"], "service": ["export"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN39,GEN45,NSTAT",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C604,C019",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.B51,B52,B53,B54,F75,000,0GD,46P,48P"
            ]
        },
        {
            if: [{ "procedureCode": ["2244"], "service": ["export"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN39,GEN45,NSTAT",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C604,C019,C990,N990",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.B51,B52,000,0MG,1SW,46P,48P"
            ]
        },
        {
            if: [{ "procedureCode": ["2300"], "service": ["export"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.GEN45,PAL12",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C605",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.F75,000,0GD,0MG,1CD,1CG,1DP,1NO,1NP,2DP"
            ]
        },
        {
            if: [{ "procedureCode": ["3151"], "service": ["export"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,10200,ECONR,ECONE,GEN45,GEN86,NSTAT",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C601,C651",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.A04,F44,F75,000,0GD,2CD,2CG"
            ]
        },
        {
            if: [{ "procedureCode": ["3153"], "service": ["export"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.00100,10500,AUC02,ECONR,ECONE,GEN45,GEN86,NSTAT,PAL06,PAL07,PAL08,PAL12,TAEXP",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C516,C651",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.D01,D02,D03,D04,D05,D05,D06,D07,D08,D09,D10,D11,D12,D13,D15,D16,D17,D18,D19,D20,D21,D22,D23,D24,D24,D25,D26,D27,D28,D29,D30,D51,F75"
            ]
        },
        {
            if: [{ "procedureCode": ["3154"], "service": ["export"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.10200,ECONR,ECONE,GEN45,GEN86,NSTAT",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C601,C603,C651",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.A04,F44,000"
            ]
        },
        {
            if: [{ "procedureCode": ["3171"], "service": ["export"] }],
            then: [
                "additionalInformation.additionalInformationCode.mustHave.ECONR,ECONE,NSTAT,PREMS",
                "documentsProduced.documentsProducedAdditionalCategoryCode.mustHave.C517,C518,C519,C651",
                "requestedAdditionalProceedureMultiline.requestedAdditionalProceedure.mustHave.F75,000,0GD,1DP,1NO,1NP,1VW,2CD,2CG,2DP"
            ]
        },
    ],
    groups: [
        ['documentsProducedAdditionalCategoryCode', 'documentsProducedAdditionalTypeCode', 'documentsProducedAdditionalDocId', 'documentsProducedAdditionalDocStatus', 'documentsProducedAdditionalDocStatusReason'],
        ['additionalSupplyChainIdentifier', 'additionalSupplyChainRoleCode'],
        ['additionalFiscalIdentifier', 'additionalFiscalTypeCode'],
        ['taxTypeCode', 'taxTypeMethodOfPayment'],
        ['taxBaseQuantity', 'taxBaseUnit', 'taxBaseQualifier'],
        ['payableTaxAmount', 'payableTaxCurrency'],
        ['totalTaxesAmount', 'totalPayableTaxCurrency'],
        ['valuationIndicators', 'valuationMethodCode'],
        ['itemPriceAmount', 'itemPriceCurrency', 'dutyRegimentCode'],
        ['countryOfOrigin', 'countryOfOriginCountryCode', 'countryOfOriginTypeCode', 'countryOfPreferentialOriginCountryCode', 'countryOfPreferentalOriginTypeCode'],
        ['netWeightMeasure', 'grossMassMeasure', 'goodsMeasureDescriptionOfGoods'],
        ['noOfPackages', 'shippingMarks'],
        ['quotaOrderId'],
        ['statisticalValueAmount', 'statisticalValueCurrency']
    ],

    services: {
        importParcel: {
            countryOfDestinationCode: 'GB'
        },

        postponedVatAccounting: {
            additionalFiscalReferences: [
                {
                    additionalFiscalId: "",
                    additionalFiscalTypeCode: ""
                }
            ]
        },

        finalSupplementary: {
            ucrReferenceNumber: "",
            requestedAdditionalProceedureMultiline: [{
                "requestedAdditionalProceedure": "97F"
            }],
        },

        gbToNiAtRisk: {
            additionalInformation: "",
            additionalInformation: [{
                "additionalInformationCode": "NIDOM",
                "additionalInformationDescription": "NIDOM"
            }],
        },

        gbToNiNotAtRisk: {
            additionalInformation: "",
            additionalInformation: [{
                "additionalInformationCode": "NIDOM",
                "additionalInformationDescription": "NIDOM"
            },
            {
                "additionalInformationCode": "NIREM",
                "additionalInformationDescription": "NIREM"
            }
        ],
        },
        rowToNiAtRisk: {
            countryOfDispatchExportCode: "",
            additionalInformation: "",
            additionalInformation: [{
                "additionalInformationCode": "NIIMP",
                "additionalInformationDescription": "NIIMP"
            },
            {
                "additionalInformationCode": "NIREM",
                "additionalInformationDescription": "NIREM"
            }
        ],
        },

        rowToNiNotAtRisk: {
            countryOfDispatchExportCode: "",
            additionalInformation: "",
            additionalInformation: [{
                "additionalInformationCode": "NIIMP",
                "additionalInformationDescription": "NIIMP"
            }],
        }
    },

};

export default obligations;
