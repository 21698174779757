import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ButtonGroup from "../../../components/common/ButtonGroup/ButtonGroup";
import { findElementPageNumber, getQueryParam, scrollToElement, updateQueryParam } from "../../../router/utils";
import DeclarationsContext from "../../../context/declarations/DeclarationsContext";
import FinalSupplementary from "./FinalSupplementary";
import { useTranslation } from "react-i18next";
import importGrouping from "../../../constants/grouping/import/header-grouping.json";
import exportGrouping from "../../../constants/grouping/export/header-grouping.json";
import { ELEMENT_QUERY_PARAM, PAGE_QUERY_PARAM } from "../../../constants/GlobalConstants";
import SectionsMasonry from "../SectionsMasonry";
import { debounce } from "lodash";

const Declaration = () => {
	const [selectedPageIndex, setSelectedPageIndex] = useState(0);
	const { state, findElementObligations } = useContext(DeclarationsContext);
	const { t } = useTranslation();

	const isFinalSupplementary = useMemo(
		() => state.declaration.data.importType === "finalSupplementary",
		[state.declaration]
	);

	const pages = useMemo(() => {
		if (isFinalSupplementary) {
			return [{ label: t("finalSupplementaryDec"), onClick: () => setSelectedPageIndex(0) }];
		} else if (state.declaration.data.service === "export") {
			return exportGrouping;
		} else {
			return importGrouping;
		}
	}, [isFinalSupplementary]);

	const pageButtons = useMemo(
		() =>
			pages.map((tab, index) => ({
				label: `Page ${index + 1}`,
				onClick: () => updatePageQuery(index),
			})),
		[pages]
	);

	const getStateFromQuery = useCallback(
		debounce(() => {
			const page = getQueryParam(PAGE_QUERY_PARAM);
			setSelectedPageIndex(parseInt(page) || 0);
			findElementAndScroll();
		}, 0),
		[]
	);

	const findElementAndScroll = () => {
		const element = getQueryParam(ELEMENT_QUERY_PARAM);
		const pageIndex = parseInt(getQueryParam(PAGE_QUERY_PARAM));

		if (element) {
			const elementPageIndex = findElementPageNumber("header", element, state.declaration);

			if (elementPageIndex !== pageIndex) {
				updateQueryParam(PAGE_QUERY_PARAM, elementPageIndex);
				setTimeout(() => {
					scrollToElement(element);
				}, 100);
			} else {
				scrollToElement(element);
			}
		}
	};

	const updatePageQuery = (index) => {
		updateQueryParam(ELEMENT_QUERY_PARAM, "");
		updateQueryParam(PAGE_QUERY_PARAM, index);
	};

	useEffect(() => {
		findElementAndScroll();
	}, [state.declaration._id]);

	useEffect(() => {
		getStateFromQuery();
		const eventHandler = () => {
			getStateFromQuery();
		};

		window.addEventListener("popstate", eventHandler);

		return function cleanup() {
			window.removeEventListener("popstate", eventHandler);
		};
	}, []);

	useEffect(() => {
		findElementObligations();
	}, [state.declaration]);

	return (
		<div>
			<div style={{ width: "fit-content", marginLeft: "auto" }}>
				<ButtonGroup selectedIndex={selectedPageIndex} buttons={pageButtons} />
			</div>

			<div style={{ marginTop: 12 }}>
				{!isFinalSupplementary ? (
					<SectionsMasonry sections={pages[selectedPageIndex]?.sections} />
				) : (
					<FinalSupplementary />
				)}
			</div>
			<div style={{ width: "fit-content", marginLeft: "auto", marginTop: 12 }}>
				<ButtonGroup selectedIndex={selectedPageIndex} buttons={pageButtons} />
			</div>

			{/* 
			<Button style={{ marginTop: 32 }} onClick={printState}>
				Print Declaration State
			</Button> */}
		</div>
	);
};

export default Declaration;
