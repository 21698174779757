const headerObligations = {
    rules: [
        {
            if: [{ "declarationCategory": ["H1", "H2", "H3", "H4", "H5", "I1", "I1 (B&E)", "C21I", "C21I EIDR", "SRDS"] }],
            then: ["procedureCode.isMandatory",
                "declarationType.isMandatory",
                "additionalDeclarationType.isMandatory",
                "locationOfGoodsType.isMandatory",
                "locationOfGoodsCountry.isMandatory",
                "qualifierOfIdentification.isMandatory",
                "declarant.isMandatory",
                "additionalInformation.isMandatory",
            ],
        },
        {
            if: [{ "declarationCategory": ["H1", "H2", "H3", "H4", "H5", "I1", "I1 (B&E)", "C21I EIDR", "SRDS"] }],
            then: [
                "importer.isMandatory",
            ],
        },
        {
            if: [{ "declarationCategory": ["H2", "H3", "H4", "I1", "C21I EIDR"] }],
            then: ["holderofAuthIdent.isMandatory",
            ],
        },
        {
            if: [{ "declarationCategory": ["H2"] }],
            then: ["warehouseType.isMandatory",
                "warehouseId.isMandatory"
            ],
        },
        {
            if: [{ "declarationCategory": ["C21I", "C21I EIDR", "FSD", "SRDS"] }],
            then: ["additionalSupplyChainActors.isDisabled"],
        },
        {
            if: [{ "declarationCategory": ["FSD"] }],
            then: ["additionalFiscalReferences.isDisabled"],
        },
        {
            if: [{ "declarationCategory": ["SRDS"] }],
            then: [
                "transportCostsToFinalDestAmount.isMandatory",
                "transportCostsToFinalDestCurrency.isMandatory",
            ],
        },
        {
            if: [{ "declarationCategory": ["H1", "H2", "H3", "H4", "H5", "I1 (B&E)", "C21I EIDR", "SRDS"] }],
            then: ["borderTransportMeans.isMandatory"],
        },
        {
            if: [{ "declarationCategory": ["H1", "H3", "H4", "H5", "I1", "I1 (B&E)", "C21I", "C21I EIDR"] }],
            then: ["identityOfMeansId.isMandatory",
                "identityOfMeansIdentificationType.isMandatory"],
        },

        {
            if: [{ "declarationCategory": ["H1", "H2", "H3", "H4", "H5", "I1", "I1 (B&E)", "C21I"] }],
            then: ["deferredPayment.isMandatory"],
        },
        {
            if: [{ "declarationCategory": ["H2"] }],
            then: ["supervisingCustomsOffice.isMandatory"],
        },
        {
            if: [{ "declarationCategory": ["H1", "H2", "H3", "H4", "H5", "I1", "I1 (B&E)", "C21I"] }],
            then: ["totalPackageQuantity.isMandatory"],
        },
        {
            if: [{ "declarationCategory": ["H1", "H2", "H3", "H4", "H5", "I1", "I1 (B&E)", "C21I EIDR"] }],
            then: ["consignmentContainerCode.isMandatory"]
        },
        {
            if: [{ "declarationCategory": ["H1", "H2", "H3", "H4", "H5", "I1", "C21I", "C21I EIDR", "SRDS"] }],
            then: ["grossMassMeasure.isMandatory"],
        },
        {
            if: [{ "declarationCategory": ["H1", "H2", "H3", "H4", "H5"] }],
            then: ["natureOfTransactionCode.isMandatory"],
        },
        {
            if: [{ "declarationCategory": ["H1", "H2", "H3", "H4", "H5", "I1 (B&E)"] }],
            then: ["countryOfDestinationCode.isMandatory"],
        },
        {
            if: [{ "declarationCategory": ["H1", "H2", "H3", "H4", "H5", "I1 (B&E)"] }],
            then: ["countryOfDispatchExportCode.isMandatory"],
        },
        {
            if: [{ "inlandModeOfTransport": ['5', '7'] }],
            then: ['identityOfMeansIdentificationType.isDisabled', 'identityOfMeansId.isDisabled']
        },
        {
            if: [{ "procedureCode": ["0100"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,SDE,DCR,DCS,355,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.ECONR,OSD01,PAL01,PAL02,PAL03",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO"
            ]
        },
        {
            if: [{ "procedureCode": ["0121"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,SDE,DCR,DCS,355,ZZZ,MRN",
                "additionalInformation.additionalInformationCode.mustHave.00100,ECONR,GEN45,OVR01,OSD01",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,OPO"
            ]
        },
        {
            if: [{ "procedureCode": ["0151"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.ZZZ,DCR,DCS",
                "additionalInformation.additionalInformationCode.mustHave.00100,10200,10300,ECONR,GEN45,GEN86,OVR01,OSD01",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,IPO"
            ]
        },
        {
            if: [{ "procedureCode": ["0153"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.MRN,DCR,DCS,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.00100,10500,ECONR,GEN45,OVR01,OSD01",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,TEA"
            ]
        },
        {
            if: [{ "procedureCode": ["0154"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.ZZZ,MRN,DCR,DCS",
                "additionalInformation.additionalInformationCode.mustHave.10200,10300,ECONR,GEN45,GEN86,OVR01,OSD01",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,IPO"
            ]
        },
        {
            if: [{ "procedureCode": ["0171"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.ZZZ,DCR,DCS",
                "additionalInformation.additionalInformationCode.mustHave.ECONR,OSD01,PREMS",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,CWP,CW1,CW2"
            ]
        },
        {
            if: [{ "procedureCode": ["0178"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,SDE,ZZZ,DCR,DCS",
                "additionalInformation.additionalInformationCode.mustHave.ECONR,OSD01,PREMS",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,FZ"
            ]
        },
        {
            if: [{ "procedureCode": ["0700"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,SDE,ZZZ,DCR,DCS,355",
                "additionalInformation.additionalInformationCode.mustHave.ECONR,PAL01,PAL02,PAL03",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,EXW,EXWH"
            ]
        },
        {
            if: [{ "procedureCode": ["0721"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,SDE,ZZZ,DCR,DCS,355,MRN",
                "additionalInformation.additionalInformationCode.mustHave.00100,ECONR,GEN45,OVR01",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,EXW,EXWH,OPO"
            ]
        },
        {
            if: [{ "procedureCode": ["0751"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.MRN,ZZZ,DCR,DCS",
                "additionalInformation.additionalInformationCode.mustHave.00100,10200,10300,ECONR,GEN45,GEN86,OVR01",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,EXW,EXWH,IPO"
            ]
        },
        {
            if: [{ "procedureCode": ["0753"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.MRN,ZZZ,DCR,DCS",
                "additionalInformation.additionalInformationCode.mustHave.00100,10500,ECONR,GEN45,OVR01",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,EXW,EXWH,TEA"
            ]
        },
        {
            if: [{ "procedureCode": ["0754"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.ZZZ,MRN,DCR,DCS",
                "additionalInformation.additionalInformationCode.mustHave.10200,10300,ECONR,GEN45,GEN86,OVR01",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,EXW,EXWH,IPO"
            ]
        },
        {
            if: [{ "procedureCode": ["0771"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.ZZZ,DCR,DCS",
                "additionalInformation.additionalInformationCode.mustHave.ECONR,PREMS",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,EXW,EXWH,CWP,CW1,CW2"
            ]
        },
        {
            if: [{ "procedureCode": ["0778"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,SDE,ZZZ,DCR,DCS",
                "additionalInformation.additionalInformationCode.mustHave.ECONR,PREMS",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,EXW,EXWH,FZ"
            ]
        },
        {
            if: [{ "procedureCode": ["4000"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,SDE,CLE,DCR,DCS,355,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.PAL01,PAL02,PAL03",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO"
            ]
        },
        {
            if: [{ "procedureCode": ["4051"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.SDE,DCR,DCS,MRN,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.00100,10200,10300,ECONR,GEN45,GEN86,OVR01",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,IPO"
            ]
        },
        {
            if: [{ "procedureCode": ["4053"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.SDE,DCR,DCS,MRN,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.00100,10500,GEN45,OVR01,AUC01",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,TEA"
            ]
        },
        {
            if: [{ "procedureCode": ["4054"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.MRN,ZZZ,DCR,DCS,",
                "additionalInformation.additionalInformationCode.mustHave.10200,10300,GEN45,GEN86,OVR01",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,IPO"
            ]
        },
        {
            if: [{ "procedureCode": ["4071"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.CLE,ZZZ,DCR,DCS,",
                "additionalInformation.additionalInformationCode.mustHave.PREMS",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,CWP,CW1,CW2"
            ]
        },
        {
            if: [{ "procedureCode": ["4078"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,SDE,CLE,ZZZ,DCR,DCS,",
                "additionalInformation.additionalInformationCode.mustHave.PREMS",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,FZ"
            ]
        },
        {
            if: [{ "procedureCode": ["4200"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,SDE,DCR,DCS,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.ECONR,OSR42,PAL01,PAL02,PAL03",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO"
            ]
        },
        {
            if: [{ "procedureCode": ["4221"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,SDE,DCR,DCS,355,MRN,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.00100,ECONR,GEN45,OVR01,OSR42",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,OPO"
            ]
        },
        {
            if: [{ "procedureCode": ["4251"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.MRN,DCR,DCS,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.00100,10200,ECONR,GEN45,GEN86,OSR42,OVR01",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,IPO"
            ]
        },
        {
            if: [{ "procedureCode": ["4253"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.MRN,DCR,DCS,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.00100,10500,ECONR,GEN45,OVR01,OSR42",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,TEA"
            ]
        },
        {
            if: [{ "procedureCode": ["4254"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.MRN,DCR,DCS,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.10200,10300,ECONR,GEN45,GEN86,OVR01,OSR42",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,IPO"
            ]
        },
        {
            if: [{ "procedureCode": ["4271"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.DCR,DCS,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.ECONR,OSR42,PREMS",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,CWP,CW1,CW2"
            ]
        },
        {
            if: [{ "procedureCode": ["4278"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,SDE,DCR,DCS,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.ECONR,OSR42,PREMS",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,FZ"
            ]
        },
        {
            if: [{ "procedureCode": ["4400"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,SDE,CLE,DCR,DCS,355,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,EUS"
            ]
        },
        {
            if: [{ "procedureCode": ["4421"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,SDE,DCR,DCS,355,ZZZ,MRN",
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,OVR01",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,EUS,OPO"
            ]
        },
        {
            if: [{ "procedureCode": ["4422"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,SDE,DCR,DCS,355,ZZZ,MRN",
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,OVR01",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,EUS,OPO"
            ]
        },
        {
            if: [{ "procedureCode": ["4451"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.CLE,DCR,DCS,MRN,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.00100,10200,10300,GEN45,GEN86,OVR01",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,EUS,IPO"
            ]
        },
        {
            if: [{ "procedureCode": ["4453"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.CLE,DCR,DCS,MRN,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.00100,10500,GEN45,OVR01",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,EUS,TEA"
            ]
        },
        {
            if: [{ "procedureCode": ["4454"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.DCR,DCS,MRN,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.00100,10200,10300,GEN45,GEN86,OVR01",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,EUS,IPO"
            ]
        },
        {
            if: [{ "procedureCode": ["4471"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.CLE,DCR,DCS,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,PREMS",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,EUS,CWP,CW1,CW2"
            ]
        },
        {
            if: [{ "procedureCode": ["4478"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,SDE,355,DCR,DCS,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,PREMS",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,EUS,FZ"
            ]
        },
        {
            if: [{ "procedureCode": ["5100"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,SDE,CLE,355,DCR,DCS,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,GEN86,ECO01,ECO02,ECO03,ECO04,ECO05,ECO06,ECO07,ECO08,ECO09,ECO10,ECO11,ECO12,ECO13,ECO14,ECO15,ECO16,ECO17,ECO18,ECO19,ECO20,ECO21,ECO22",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,IPO"
            ]
        },
        {
            if: [{ "procedureCode": ["5111"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,SDE,CLE,MRN,DCR,DCS,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.GEN45,GEN86,ECO01,ECO02,ECO03,ECO04,ECO05,ECO06,ECO07,ECO08,ECO09,ECO10,ECO11,ECO12,ECO13,ECO14,ECO15,ECO16,ECO17,ECO18,ECO19,ECO20,ECO21,ECO22",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,IPO"
            ]
        },
        {
            if: [{ "procedureCode": ["5121"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,SDE,355,MRN,DCR,DCS,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN19,GEN45,GEN86,ECO01,ECO02,ECO03,ECO04,ECO05,ECO06,ECO07,ECO08,ECO09,ECO10,ECO11,ECO12,ECO13,ECO14,ECO15,ECO16,ECO17,ECO18,ECO19,ECO20,ECO21,ECO22",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,IPO,OPO"
            ]
        },
        {
            if: [{ "procedureCode": ["5151"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.CLE,MRN,DCR,DCS,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.00100,10200,GEN45,GEN86,ECO01,ECO02,ECO03,ECO04,ECO05,ECO06,ECO07,ECO08,ECO09,ECO10,ECO11,ECO12,ECO13,ECO14,ECO15,ECO16,ECO17,ECO18,ECO19,ECO20,ECO21,ECO22",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,IPO,DPO"
            ]
        },
        {
            if: [{ "procedureCode": ["5153"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.CLE,MRN,DCR,DCS,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.00100,10500,GEN45,GEN86,TAPAR,ECO01,ECO02,ECO03,ECO04,ECO05,ECO06,ECO07,ECO08,ECO09,ECO10,ECO11,ECO12,ECO13,ECO14,ECO15,ECO16,ECO17,ECO18,ECO19,ECO20,ECO21,ECO22",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,IPO,TEA"
            ]
        },
        {
            if: [{ "procedureCode": ["5154"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.MRN,DCR,DCS,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.10200,GEN45,GEN86,ECO01,ECO02,ECO03,ECO04,ECO05,ECO06,ECO07,ECO08,ECO09,ECO10,ECO11,ECO12,ECO13,ECO14,ECO15,ECO16,ECO17,ECO18,ECO19,ECO20,ECO21,ECO22",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.IPO"
            ]
        },
        {
            if: [{ "procedureCode": ["5171"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.CLE,DCR,DCS,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,GEN86,PREMS,ECO01,ECO02,ECO03,ECO04,ECO05,ECO06,ECO07,ECO08,ECO09,ECO10,ECO11,ECO12,ECO13,ECO14,ECO15,ECO16,ECO17,ECO18,ECO19,ECO20,ECO21,ECO22",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,IPO,CWP,CW1,CW2"
            ]
        },
        {
            if: [{ "procedureCode": ["5178"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,SDE,DCR,DCS,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,GEN86,PREMS,ECO01,ECO02,ECO03,ECO04,ECO05,ECO06,ECO07,ECO08,ECO09,ECO10,ECO11,ECO12,ECO13,ECO14,ECO15,ECO16,ECO17,ECO18,ECO19,ECO20,ECO21,ECO22",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,IPO,FZ"
            ]
        },
        {
            if: [{ "procedureCode": ["5300"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,SDE,CLE,DCR,DCS,355,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,GEN21,GEN24,GEN27,GEN28,GEN29,GEN30,GEN31,GEN32,GEN33,GEN34,GEN35,GEN36,PAL01,PAL02,PAL03,OVR01",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,TEA,TEAH"
            ]
        },
        {
            if: [{ "procedureCode": ["5351"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.CLE,DCR,DCS,MRN,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.00100,10200,10300,GEN45,GEN21,GEN24,GEN27,GEN28,GEN29,GEN30,GEN31,GEN32,GEN33,GEN34,GEN35,GEN36,PAL01,PAL02,PAL03,GEN86,OVR01",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,TEA,TEAH,IPO"
            ]
        },
        {
            if: [{ "procedureCode": ["5353"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.CLE,DCR,DCS,MRN,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.00100,10500,GEN45,GEN21,GEN24,GEN27,GEN28,GEN29,GEN30,GEN31,GEN32,GEN33,GEN34,GEN35,GEN36,PAL01,PAL02,PAL03,TAPAR,OVR01",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,TEA,TEAH"
            ]
        },
        {
            if: [{ "procedureCode": ["5354"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.DCR,DCS,MRN,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.00100,10200,10300,GEN45,GEN21,GEN24,GEN27,GEN28,GEN29,GEN30,GEN31,GEN32,GEN33,GEN34,GEN35,GEN36,PAL01,PAL02,PAL03,GEN86,OVR01",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,TEA,TEAH,IPO"
            ]
        },
        {
            if: [{ "procedureCode": ["5371"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.CLE,DCR,DCS,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,GEN21,GEN24,GEN27,GEN28,GEN29,GEN30,GEN31,GEN32,GEN33,GEN34,GEN35,GEN36,PAL01,PAL02,PAL03,OVR01,PREMS",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,TEA,TEAH,CWP,CW1,CW2"
            ]
        },
        {
            if: [{ "procedureCode": ["5378"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,SDE,DCR,DCS,ZZZ",
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,GEN21,GEN24,GEN27,GEN28,GEN29,GEN30,GEN31,GEN32,GEN33,GEN34,GEN35,GEN36,PAL01,PAL02,PAL03,OVR01,PREMS",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,TEA,TEAH,FZ"
            ]
        },
        {
            if: [{ "procedureCode": ["6110"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,SDE,CLE,DCR,DCS,355,ZZZ,MRN",
                "additionalInformation.additionalInformationCode.mustHave.GEN03,GEN3C,GEN45,OVR01,PAL05,PREMS",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO"
            ]
        },
        {
            if: [{ "procedureCode": ["6111"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,SDE,CLE,DCR,DCS,355,ZZZ,MRN",
                "additionalInformation.additionalInformationCode.mustHave.GEN03,GEN45,GEN86,OVR01,PAL05,PREMS",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,IPO,CWP,CW1,CW2"
            ]
        },
        {
            if: [{ "procedureCode": ["6121"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,SDE,DCR,DCS,355,ZZZ,MRN",
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,OVR01,PREMS",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,OPO,CWP,CW1,CW2"
            ]
        },
        {
            if: [{ "procedureCode": ["6122"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,SDE,DCR,DCS,355,ZZZ,MRN",
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,OVR01,PREMS",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,OPO,CWP,CW1,CW2"
            ]
        },
        {
            if: [{ "procedureCode": ["6123"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,SDE,CLE,DCR,DCS,355,ZZZ,MRN",
                "additionalInformation.additionalInformationCode.mustHave.GEN03,GEN3C,GEN45,OVR01,PAL05,PREMS",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,CWP,CW1,CW2"
            ]
        },
        {
            if: [{ "procedureCode": ["6131"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,SDE,CLE,DCR,DCS,355,ZZZ,MRN",
                "additionalInformation.additionalInformationCode.mustHave.GEN03,GEN45,GEN86,OVR01,PAL05,PREMS",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CGU,DPO,IPO,CWP,CW1,CW2"
            ]
        },
        {
            if: [{ "procedureCode": ["7100"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,355,ZZZ,DCR,DCS",
                "additionalInformation.additionalInformationCode.mustHave.PREMS,WHSRP",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CWP,CW1,CW2"
            ]
        },
        {
            if: [{ "procedureCode": ["7110"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,355,ZZZ,MRN,DCR,DCS",
                "additionalInformation.additionalInformationCode.mustHave.PREMS,WHSRP",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CWP,CW1,CW2"
            ]
        },
        {
            if: [{ "procedureCode": ["7121"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,355,ZZZ,MRN,DCR,DCS",
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,PREMS,WHSRP",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CWP,CW1,CW2,OPO"
            ]
        },
        {
            if: [{ "procedureCode": ["7122"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,355,ZZZ,MRN,DCR,DCS",
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN45,PREMS,WHSRP",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CWP,CW1,CW2,OPO"
            ]
        },
        {
            if: [{ "procedureCode": ["7123"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,355,ZZZ,MRN,DCR,DCS",
                "additionalInformation.additionalInformationCode.mustHave.GEN03,GEN3C,GEN45,GEN86,PREMS,WHSRP",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CWP,CW1,CW2"
            ]
        },
        {
            if: [{ "procedureCode": ["7151"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.ZZZ,MRN,DCR,DCS",
                "additionalInformation.additionalInformationCode.mustHave.00100,10200,GEN45,GEN86,PREMS,WHSRP",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CWP,CW1,CW2,IPO"
            ]
        },
        {
            if: [{ "procedureCode": ["7153"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.ZZZ,MRN,DCR,DCS",
                "additionalInformation.additionalInformationCode.mustHave.00100,10500,GEN45,PREMS,WHSRP",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CWP,CW1,CW2,TEA"
            ]
        },
        {
            if: [{ "procedureCode": ["7154"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.ZZZ,MRN,DCR,DCS",
                "additionalInformation.additionalInformationCode.mustHave.10200,GEN45,GEN86,PREMS,WHSRP",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CWP,CW1,CW2,IPO"
            ]
        },
        {
            if: [{ "procedureCode": ["7171"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.ZZZ,DCR,DCS",
                "additionalInformation.additionalInformationCode.mustHave.PREMS,WHSRP",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CWP,CW1,CW2"
            ]
        },
        {
            if: [{ "procedureCode": ["7178"] }],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.337,ZZZ,DCR,DCS",
                "additionalInformation.additionalInformationCode.mustHave.PREMS,WHSRP",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CWP,CW1,CW2,FZ"
            ]
        },
        {
            if: [{ "procedureCode": ["7800","7851","7853","7871","7878"] }],
            then: [
                "warehouseIdentification.warehouseType.mustHave.Z",
                "simplifiedDeclaration.previousDocumentType.mustHave.ZZZ",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.FP",
                "locationOfGoods.qualifierOfIdentification.mustHave.U"
            ]
        },
        {
            if: [{ "procedureCode":["1007"], "service": ["export"]}],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.SDE,DCR,DCS,ZZZ,MCR",
                "additionalInformation.additionalInformationCode.mustHave.ECONR,ECONE,PAL06,PAL07,PAL08,PAL12", 
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.EXW,EXWH"
            ]
        },
        {
            if: [{ "procedureCode":["1040"], "service": ["export"]}],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.SDE,CLE,DCR,DCS,ZZZ,MCR",
                "additionalInformation.additionalInformationCode.mustHave.PAL06,PAL07,PAL08,PAL12", 
            ]
        },
        {
            if: [{ "procedureCode":["1042"], "service": ["export"]}],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.SDE,DCR,DCS,MRN,ZZZ,MCR",
                "additionalInformation.additionalInformationCode.mustHave.ECONR,ECONE,OSD01,OSR42,PAL06,PAL07,PAL08,PAL12",
                "additionalFiscalReferences.additionalFiscalTypeCode.mustHave.FR1"
            ]
        },
        {
            if: [{ "procedureCode":["1044"], "service": ["export"]}],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.SDE,CLE,DCR,DCS,MRN,ZZZ,MCR",
                "additionalInformation.additionalInformationCode.mustHave.00100,30300,GEN39,GEN45,NSTAT",
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.EUS"
            ]
        },
        {
            if: [{ "procedureCode":["1100"], "service": ["export"]}],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.SDE,DCR,DCS,ZZZ,MCR",
                "additionalInformation.additionalInformationCode.mustHave.GEN45,GEN86,NSTAT", 
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.IPO"
            ]
        },
        {
            if: [{ "procedureCode":["2100"], "service": ["export"]}],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.ZZZ,DCR,DCS,MCR",
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN39,GEN45,NSTAT", 
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.OPO"
            ]
        },
        {
            if: [{ "procedureCode":["2144"], "service": ["export"]}],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.ZZZ,DCR,DCS,MRN,MCR",
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN39,GEN45,NSTAT", 
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.OPO,EUS"
            ]
        },
        {
            if: [{ "procedureCode":["2151"], "service": ["export"]}],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.ZZZ,DCR,DCS,MRN,MCR",
                "additionalInformation.additionalInformationCode.mustHave.00100,ECONR,ECONE,GEN39,GEN45,GEN86,NSTAT", 
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.OPO,IPO"
            ]
        },
        {
            if: [{ "procedureCode":["2154"], "service": ["export"]}],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.ZZZ,DCR,DCS,MRN,MCR",
                "additionalInformation.additionalInformationCode.mustHave.00100,ECONR,ECONE,GEN39,GEN45,GEN86,NSTAT", 
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.OPO,IPO"
            ]
        },
        {
            if: [{ "procedureCode":["2200"], "service": ["export"]}],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.ZZZ,DCR,DCS,MCR",
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN39,GEN45,NSTAT", 
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.OPO"
            ]
        },
        {
            if: [{ "procedureCode":["2244"], "service": ["export"]}],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.ZZZ,DCR,DCS,MCR,MRN",
                "additionalInformation.additionalInformationCode.mustHave.00100,GEN39,GEN45,NSTAT", 
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.OPO,EUS"
            ]
        },
        {
            if: [{ "procedureCode":["2300"], "service": ["export"]}],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.SDE,CLE,DCR,DCS,ZZZ,MCR",
                "additionalInformation.additionalInformationCode.mustHave.GEN45,PAL12"
            ]
        },
        {
            if: [{ "procedureCode":["3151"], "service": ["export"]}],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.SDE,CLE,DCR,DCS,ZZZ,MRN,MCR",
                "additionalInformation.additionalInformationCode.mustHave.00100,10200,ECONR,ECONE,GEN45,GEN86,NSTAT", 
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.IPO"
            ]
        },
        {
            if: [{ "procedureCode":["3153"], "service": ["export"]}],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.SDE,CLE,DCR,DCS,ZZZ,MRN,MCR",
                "additionalInformation.additionalInformationCode.mustHave.00100,10500,AUC02,ECONR,ECONE,GEN45,GEN86,NSTAT,PAL06,PAL07,PAL08,PAL12,TAEXP", 
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.TEA,TEAH"
            ]
        },
        {
            if: [{ "procedureCode":["3154"], "service": ["export"]}],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.ZZZ,MRN,DCR,DCS,MCR",
                "additionalInformation.additionalInformationCode.mustHave.10200,ECONR,ECONE,GEN45,GEN86,NSTAT", 
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.IPO"
            ]
        },
        {
            if: [{ "procedureCode":["3171"], "service": ["export"]}],
            then: [
                "simplifiedDeclaration.previousDocumentType.mustHave.SDE,CLE,DCR,DCS,ZZZ,MCR",
                "additionalInformation.additionalInformationCode.mustHave.ECONR,ECONE,NSTAT,PREMS", 
                "holderofAuthIdent.holderOfAuthorisationIdentificationTypeCode.mustHave.CWP,CW1,CW2"
            ]
        },
    ],
    groups: [
        ['procedureCode', 'additionalDeclarationType', 'declarationType'],
        ['deferredPaymentId', 'deferredPaymentCategory', 'deferredPaymentType'],
        ['holderOfAuthorisationIdentificationId', 'holderOfAuthorisationIdentificationTypeCode'],
        ['warehouseType', 'warehouseId'],
        ['totalAmountInvoicedAmount', 'totalAmountInvoicedCurrency'],
        ['incoTermCode', 'unLocodeCode', 'deliveryTermsCountryCode', 'deliveryTermsLocationName'],
        ['supervisingCustomsOffice'],
        ['grossMassMeasure'],
        ['totalPackageQuantity', 'consignmentContainerCode'],
        ['identityOfMeansId', 'identityOfMeansIdentificationType'],
        ['guaranteeReferenceNumber', 'otherGuaranteeReference', 'accessCode', 'guaranteeReferenceAmount', 'guaranteeReferenceCurrency', 'customsOfficeToGuarantee']
    ],

    services: {
        gbToNiAtRisk: {
            additionalInformation: "",
            countryOfDestinationCode: "GB",
        },

        gbToNiNotAtRisk: {
            additionalInformation: "",
            countryOfDestinationCode: "GB",
        },

        rowToNiAtRisk: {
            additionalInformation: "",
            countryOfDestinationCode: "GB",
            //location of Goods mandatory
            locationOfGoodsId: "",
            locationOfGoodsType: "",
            locationOfGoodsCountry: "",
            qualifierOfIdentification: "",
        },
        rowToNiNotAtRisk: {
            additionalInformation: "",
            contactCountry: "",
            countryOfDestinationCode: "GB",
            //location of Goods mandatory
            locationOfGoodsId: "",
            locationOfGoodsType: "",
            locationOfGoodsCountry: "",
            qualifierOfIdentification: "",
        },


        importParcel: {
            declarationCategory: 'H1',
            declarationType: "0",
            additionalDeclarationType: "A",
            procedureCode: "4000",
            totalPackageQuantity: "1",
            totalNoOfItems: "1",
        },
        finalSupplementary: {
            totalNoOfItems: "1",
            procedureCode: "0090",
            additionalDeclarationType: '',
            declarantId: '',
            acceptanceDate: '',
            documentCategory: "Z",
            previousDocumentType: "ZZZ",
            previousDocumentReference: "FSD",
            locationOfGoodsType: "B",
            qualifierOfIdentification: "Y",
            locationOfGoodsCountry: "GB",
            locationOfGoodsId: "",
            functionCode: 9
        }
    }
}

export default headerObligations