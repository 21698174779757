import Button from "../Button";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import moment from "moment";
import NotificationBadge from "./NotificationBadge";
import { useMemo } from "react";
import { getDeclarationLabel } from "../../../helpers";

const NotificationItem = ({ notification, showDeclarationLabel, style, onClick }) => {
	const { t } = useTranslation();

	const label = useMemo(() => {
		if (notification.declaration && typeof notification.declaration === "object" && showDeclarationLabel) {
			const label = getDeclarationLabel(notification.declaration);

			return `**${label}** - `;
		} else {
			return "";
		}
	}, [showDeclarationLabel, notification]);

	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				marginTop: 20,
				marginBottom: 20,
				...(style || {}),
			}}
		>
			<div style={{ width: "calc(100% - 70px)", textAlign: "left" }}>
				<div style={{ display: "flex" }}>
					<NotificationBadge notification={notification} />

					<div>
						{Boolean(notification.processedTime) && (
							<div
								style={{
									fontSize: 12,
									marginBottom: 2,
									color: "#494E57",
								}}
							>
								{moment(notification.processedTime).format(
									"DD MMM, hh:mm A"
								)}
							</div>
						)}
						<div style={{ fontSize: 14 }}>
							<ReactMarkdown children={`${label}${notification.message}`} />
						</div>
					</div>
				</div>
			</div>

			{onClick && (
				<div style={{ width: "fit-content", marginLeft: "auto" }}>
					<Button type="ghost" onClick={() => onClick(notification)}>
						{t("view")}
					</Button>
				</div>
			)}
		</div>
	);
};

export default NotificationItem;
