import { useContext } from "react";
import { useTranslation } from "react-i18next";
import ThemeContext from "../../../../context/theme/ThemeContext";

const SubscriptionSummary = () => {
	const { t } = useTranslation();
	const { withTheme } = useContext(ThemeContext);

	return (
		<div className={withTheme("settings-section")}>
			<div className={withTheme("settings-section-label")}>{t("subscriptionSummary")}</div>

			<div style={{ display: "flex", alignItems: "center" }}>
				<div>
					<div style={{ fontSize: 20, fontWeight: 500 }}>Simple</div>
					<div className={withTheme("secondary-text")}>{t("subscriptionPlan")}</div>
				</div>
				<div style={{ width: "fit-content", marginLeft: "auto", textAlign: "left" }}>
					<div>
						<span className={withTheme("secondary-text")}>{t("status")}: </span>
						<span style={{ fontWeight: 700 }} className={withTheme("green-text")}>
							Active
						</span>
					</div>

					<div>
						<span className={withTheme("secondary-text")}>
							{t("nextPayment")}:{" "}
						</span>
						<span style={{ fontWeight: 700, fontSize: 14 }}>None</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SubscriptionSummary;
