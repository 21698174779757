import { PiDownloadSimple, PiUploadSimple } from "react-icons/pi";
import Button from "../Button";
import Tooltip from "../Tooltip";
import { useTranslation } from "react-i18next";
import { readFile } from "../MultiLineTableElement/utils";
import csv from "csvtojson";
import { useMemo, useRef } from "react";
import validationElements from "../../../constants/validation";

const ImportExportButtons = ({ value, elementName, onImport }) => {
	const { t } = useTranslation();
	const fileInputRef = useRef(null);

	const validationElement = useMemo(
		() => validationElements.find((tmpElement) => tmpElement.elementName === elementName),
		[elementName]
	);

	const handleFileInputClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.value = "";
		}
	};

	// TODO: Test CSV file import
	const handleImportFileChange = (e) => {
		Array.from(e.target.files).forEach((file) => {
			// Create temporary file path
			const filePath = URL.createObjectURL(file);
			const { type } = file;

			readFile(filePath, (fileValue) => {
				if (type === "application/json") {
					const parsedJson = JSON.parse(fileValue);

					if (Array.isArray(parsedJson)) {
						setItemsFromJson(parsedJson);
					}
				} else if (type.includes("text/")) {
					csv({ output: "json" })
						.fromString(fileValue)
						.then((jsonResult) => {
							setItemsFromJson(jsonResult);
						});
				}
			});
		});
	};

	const setItemsFromJson = (dataArray) => {
		let newTableItems = [];

		dataArray.forEach((item) => {
			let tableItem = {};
			// Grab the relevant element data from the JSON item
			validationElement.components.forEach((element) => {
				tableItem[element.elementName] = item[element.elementName];
			});

			// Add the table item only if it at least has 1 element value
			if (Object.keys(tableItem).length > 0) {
				newTableItems.push(tableItem);
			}
		});

		onImport([...(value || []), ...newTableItems]);
	};

	const handleExportClick = async () => {
		const json = JSON.stringify(value);
		const blob = new Blob([json], { type: "application/json" });
		const href = await URL.createObjectURL(blob);
		const link = document.createElement("a");

		link.href = href;
		link.download = elementName + ".json";

		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<>
			{Boolean(value?.length) && (
				<Tooltip openOnHover text={t("export")}>
					<Button type="icon" onClick={handleExportClick}>
						<PiDownloadSimple />
					</Button>
				</Tooltip>
			)}

			<Tooltip openOnHover text={t("import")}>
				<Button type="icon" onClick={() => fileInputRef.current.click()}>
					<PiUploadSimple />
				</Button>
			</Tooltip>

			<input
				ref={fileInputRef}
				type="file"
				multiple={false}
				style={{ display: "none" }}
				onChange={handleImportFileChange}
				onClick={handleFileInputClick}
			/>
		</>
	);
};

export default ImportExportButtons;
