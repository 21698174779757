import { axiosInstance } from "../index";
import config from "../config";
import { getHeaders } from "./helpers";

const { apiUrl } = config;

export const listCommodityCodes = async (workspaceId) => {
	const result = await axiosInstance.get(`${apiUrl}/commodity-codes`, { params: { workspaceId }, headers: getHeaders() });

	return result.data.codes;
};

export const createCommodityCode = async (data) => {
	const result = await axiosInstance.post(`${apiUrl}/commodity-codes`, data, { headers: getHeaders() });

	return result.data.code;
};

export const deleteCommodityCode = async (codeId) => {
	const result = await axiosInstance.delete(`${apiUrl}/commodity-codes/${codeId}`, {
		headers: getHeaders(),
	});
	return result.data;
};

export const updateCommodityCode = async (codeId, data) => {
	const result = await axiosInstance.put(`${apiUrl}/commodity-codes/${codeId}`, data, { headers: getHeaders() });

	return result.data.code;
};
