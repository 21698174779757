const documentsProducedAdditionalDocStatus = [
  {
    "key": "DEFAULT",
    "value": "Please Select"
  },
  {
    "key": "AC",
    "value": "Document attached for certification by customs"
  },
  {
    "key": "AE",
    "value": "Document attached — exhausted by (or only applies to) this entry (document returned to the trader)"
  },
  {
    "key": "AF",
    "value": "Document attached — exhausted by (or only applies to) this entry (document returned to the relevant department)"
  },
  {
    "key": "AG",
    "value": "Document attached — exhausted by (or only applies to) this entry (document retained)"
  },
  {
    "key": "AP",
    "value": "Document attached — part use (applies to this and other entries) (document returned to the trader)"
  },
  {
    "key": "AS",
    "value": "Document attached — surrendered (document returned to the trader)"
  },
  {
    "key": "AT",
    "value": "Document attached — surrendered (document returned to the relevant department)"
  },
  {
    "key": "CC",
    "value": "Authorisation issued under the Community Customs Code — CCG not required"
  },
  {
    "key": "EA",
    "value": "Electronic details on Customs Declaration Services (CDS) — already attributed on simplified declaration"
  },
  {
    "key": "EE",
    "value": "Electronic details on CDS — exhausted"
  },
  {
    "key": "EL",
    "value": "Electronic details on CDS — ‘Late’ declaration after licence completed"
  },
  {
    "key": "EP",
    "value": "Electronic details on CDS — part use (applies to this and other entries)"
  },
  {
    "key": "ES",
    "value": "Electronic details on CDS — surrendered"
  },
  {
    "key": "FP",
    "value": "Facsimile attached — part use (applies to this and other entries)"
  },
  {
    "key": "GE",
    "value": "Document with the goods — exhausted by (or only applies to) this entry"
  },
  {
    "key": "GP",
    "value": "Document with the goods — part use (applies to this and other entries)"
  },
  {
    "key": "HP",
    "value": "Facsimile with the goods — part use (applies to this and other entries)"
  },
  {
    "key": "IA",
    "value": "Details held by issuing authority — already attributed on simplified declaration"
  },
  {
    "key": "IE",
    "value": "Details held by issuing authority — exhausted by (or only applies to) this entry"
  },
  {
    "key": "IP",
    "value": "Details held by issuing authority — part use (applies to this and other entries)"
  },
  {
    "key": "IS",
    "value": "Details held by issuing authority — surrendered"
  },
  {
    "key": "JA",
    "value": "Document held by authorised trader — already attributed on simplified declaration"
  },
  {
    "key": "JE",
    "value": "Document held by authorised trader — exhausted by (or only applies to) this entry"
  },
  {
    "key": "JP",
    "value": "Evidence required is available from trader — part use (applies to this and other entries)"
  },
  {
    "key": "JS",
    "value": "Document held by authorised trader — surrendered"
  },
  {
    "key": "LE",
    "value": "Lodged previously — exhausted by (or only applies to) this entry"
  },
  {
    "key": "LP",
    "value": "Lodged previously — part use (applies to this and other entries)"
  },
  {
    "key": "RE",
    "value": "To be used when declaration of a REX number is required"
  },
  {
    "key": "SP",
    "value": "Special authorisation for attached facsimile (paper held by trader) — part use (applies to this and other entries)"
  },
  {
    "key": "TP",
    "value": "Special authorisation for facsimile with the goods (paper held by trader) — part use (applies to this and other entries)"
  },
  {
    "key": "UA",
    "value": "Paper (held by trader) unavailable — already attributed on simplified declaration"
  },
  {
    "key": "UE",
    "value": "Paper (held by trader) unavailable — exhausted by (or only applies to) this entry"
  },
  {
    "key": "UP",
    "value": "Evidence required is unavailable — part use (applies to this and other entries)"
  },
  {
    "key": "US",
    "value": "Paper (held by trader) unavailable — surrendered"
  },
  {
    "key": "XA",
    "value": "Electronic or paper document to be advised"
  },
  {
    "key": "XB",
    "value": "Document not required — quantity below ‘de minimus’"
  },
  {
    "key": "XF",
    "value": "Document not required — goods not going into free circulation"
  },
  {
    "key": "XO",
    "value": "To be used when declaration of a REX number is not required"
  },
  {
    "key": "XU",
    "value": "Unknown, specific document cannot be identified — security and/or undertaking may be required as detailed for the document or Procedure Code/Additional Procedure Code"
  },
  {
    "key": "XW",
    "value": "Waiver claimed"
  },
  {
    "key": "XX",
    "value": "To declare that goods are not subject to any licences other than those declared. Please see individual doc code completion rules where xx status code is allowed for details"
  }
]

export default documentsProducedAdditionalDocStatus;