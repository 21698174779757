export const validateElements = (data, elements) => {
   let errors = {};


   elements.forEach(element => {
      if(element.isRequired && !data[element.name]) {
         errors[element.name] = 'required';
      }
   })

   return errors
}