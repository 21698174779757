import { useTranslation } from "react-i18next";
import Button from "../../Button";
import { PiPencilSimple, PiPlus } from "react-icons/pi";

const InputsActions = (props) => {
	const { t } = useTranslation();
	const {
		items,
		components,
		limit,
		selectedRowIndex,
		setSelectedRowIndex,
		inputValues,
		setInputValues,
		updateItems,
		setSelectedPage,
		setErrors,
		disabled,
		onSaveAsNewClick,
		isSaveLoading,
		disallowDuplicates,
	} = props;

	const handleCancelClick = () => {
		setInputValues({});
		setSelectedRowIndex(null);
	};

	const handleSaveRowChangesClick = () => {
		items.forEach((item, index) => {
			if (index === parseInt(selectedRowIndex)) {
				items[index] = { ...item[index], ...inputValues };
			}
		});

		updateItems(items);
		setInputValues({});
		setSelectedRowIndex(null);
	};

	const handleResetClick = () => {
		setInputValues({});
	};

	const validateInputs = () => {
		let errors = {};

		components.forEach((component) => {
			if (component.isRequired && !inputValues[component.elementName]) {
				errors[component.elementName] = t("required");
			} else if (disallowDuplicates && items?.find((item) => item[component.elementName] === inputValues[component.elementName])) {
				errors[component.elementName] = t("valueAlreadyAdded");
			}
		});

		setErrors(errors);

		return errors;
	};

	const handleAddClick = () => {
		const errors = validateInputs();

		if (Object.keys(errors).length) {
			return;
		}

		updateItems([...items, inputValues]);
		setInputValues({});

		// Select the page to which this new item has been added
		setSelectedPage(Math.floor(items.length / 10));
	};

	const handleSaveAsNewClick = () => {
		const errors = validateInputs();

		if (Object.keys(errors).length) {
			return;
		}

		onSaveAsNewClick?.(inputValues);
	};
	return (
		<div
			style={{
				marginTop: 20,
				paddingBottom: 20,
				display: "flex",
				alignItems: "center",
				justifyContent: !onSaveAsNewClick ? "center" : null,
				borderBottom: "1px solid #D7DAE0",
			}}
		>
			{onSaveAsNewClick && (
				<Button isLoading={isSaveLoading} LeftIcon={PiPlus} onClick={handleSaveAsNewClick}>
					{t("saveAsNew")}
				</Button>
			)}
			<div
				style={{
					display: "flex",
					alignItems: "center",
					width: "fit-content",
					gap: 12,
					marginLeft: onSaveAsNewClick ? "auto" : null,
				}}
			>
				{selectedRowIndex !== null && (
					<>
						<Button
							type="secondary"
							disabled={disabled}
							onClick={handleCancelClick}
						>
							{t("cancel")}
						</Button>

						<Button LeftIcon={PiPencilSimple} onClick={handleSaveRowChangesClick}>
							{t("modify")}
						</Button>
					</>
				)}

				{/* Action Buttons */}
				{selectedRowIndex === null && (
					<>
						<Button type="secondary" disabled={disabled} onClick={handleResetClick}>
							{t("reset")}
						</Button>

						<Button
							disabled={items.length === limit || disabled}
							LeftIcon={PiPlus}
							onClick={handleAddClick}
						>
							{t("add")}
						</Button>
					</>
				)}
			</div>
		</div>
	);
};

export default InputsActions;
