import { useContext, useState } from "react";
import { ListGroup, Overlay } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import UserContext from "../../../context/user/UserContext";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../common/LoadingSpinner";

const UserOverlay = (props) => {
	const [isLogoutLoading, setIsLoadingLoading] = useState(false);
	const { target, open, handleClose } = props;
	const history = useHistory();
	const { t } = useTranslation();
	const userContext = useContext(UserContext);

	const handleLogoutClick = async () => {
		if (isLogoutLoading) {
			return;
		}

		setIsLoadingLoading(true);

		await userContext.signOut();
		history.push("/login");

		setIsLoadingLoading(false);
	};

	return (
		<Overlay placement="bottom" rootClose={true} target={target} onHide={handleClose} show={open}>
			{({ placement, arrowProps, show: _show, popper, ...props }) => (
				<div {...props}>
					<div style={{ marginTop: 16, marginLeft: 38 }}>
						<ListGroup style={{ width: 200 }}>
							<ListGroup.Item
								action
								onClick={() => history.push("/settings")}
							>
								{t("settings")}
							</ListGroup.Item>
							<ListGroup.Item
								style={{
									display: "flex",
									alignItems: "center",
									gap: 6,
								}}
								action
								onClick={handleLogoutClick}
							>
								{t("logout")}

								{isLogoutLoading && (
									<div
										style={{
											width: "fit-content",
											marginLeft: "auto",
										}}
									>
										<LoadingSpinner size="sm" />
									</div>
								)}
							</ListGroup.Item>
						</ListGroup>
					</div>
				</div>
			)}
		</Overlay>
	);
};

export default UserOverlay;
