import { useContext, useEffect, useMemo, useState } from "react";
import fsdGrouping from "../../constants/grouping/final-supplementary.json";
import importItemsGrouping from "../../constants/grouping/import/items-grouping.json";
import exportItemsGrouping from "../../constants/grouping/export/items-grouping.json";
import importHeaderGrouping from "../../constants/grouping/import/header-grouping.json";
import exportHeaderGrouping from "../../constants/grouping/export/header-grouping.json";
import validation from "../../constants/validation";
import { updateQueryParam } from "../../router/utils";
import { customSections, getCustomSectionElements, getCustomSectionLabel } from "./InputsSection/CustomSections";
import DeclarationsContext from "../../context/declarations/DeclarationsContext";
import { ELEMENT_QUERY_PARAM } from "../../constants/GlobalConstants";
import SelectInput from "./SelectInput";

const ElementSearchInput = (props) => {
	const [options, setOptions] = useState([]);
	const { state } = useContext(DeclarationsContext);
	const { selectedTabIndex } = props;

	const grouping = useMemo(() => (selectedTabIndex === 0 ? "header" : "items"), [selectedTabIndex]);

	const handleInputChange = (option) => {
		updateQueryParam(ELEMENT_QUERY_PARAM, option.value);
	};

	// TODO: Performance can be improved
	useEffect(() => {
		let options = [];
		let searchableElements = [];
		let groupingTabs = importHeaderGrouping;

		if (state.declaration.data.importType === "finalSupplementary" && grouping === "header") {
			groupingTabs = fsdGrouping;
		} else if (state.declaration.data.service === "export") {
			if (grouping === "items") {
				groupingTabs = exportItemsGrouping;
			} else {
				groupingTabs = exportHeaderGrouping;
			}
		} else {
			if (grouping === "items") {
				groupingTabs = importItemsGrouping;
			}
		}

		groupingTabs.forEach((tab) => {
			tab.sections?.forEach((section) => {
				// Recursively find and add elements that exist in grouping sections
				const findElementInSection = (elements) => {
					elements?.forEach((element) => {
						if (element.elementName) {
							searchableElements.push({
								section: section.title,
								elementName: element.elementName,
								multiline: element.type === "multiline",
							});
						} else if (element.type === "row") {
							findElementInSection(element.elements);
						}
					});
				};

				// Add elements that exist in custom sections (contacts, location of goods, commodity codes)
				if (customSections.includes(section.section)) {
					const elements = getCustomSectionElements(section.section);
					elements?.forEach((element) => {
						searchableElements.push({
							section: getCustomSectionLabel(element),
							elementName: element,
						});
					});
				} else {
					if (section.elements) {
						findElementInSection(section.elements);
					}
				}
			});
		});

		validation.forEach((validationElement) => {
			const element = searchableElements.find(
				(elem) => elem.elementName === validationElement.elementName
			);
			if (!element) {
				return;
			}

			// If element is a multiline, find its child elements from the validation json and make them searchable as well
			if (element.multiline) {
				validationElement.components.forEach((component) => {
					const childValidationElement = validation.find(
						(elem) => elem.elementName === component.elementName
					);
					if (childValidationElement) {
						options.push({
							value: childValidationElement.elementName,
							label: childValidationElement.label,
							section: element.section,
						});
					}
				});
			}

			options.push({
				value: element.elementName,
				label: `${validationElement.parenthesisCode || ""} ${
					validationElement.label
				}`,
				section: element.section,
			});
		});

		setOptions(options);
	}, [grouping, state.declaration]);

	return (
		<div style={{ width: 280 }}>
			<SelectInput
				placeholder="Search Elements"
				options={options}
				value={undefined}
				onChange={handleInputChange}
				formatOptionLabel={(data) => (
					<div style={{ textAlign: "left" }}>
						<div style={{ fontSize: 12 }}>{data.section}</div>
						<div style={{ fontSize: 14 }}>{data.label}</div>
					</div>
				)}
			/>
		</div>
	);
};

export default ElementSearchInput;
