const requestedAdditionalProceedure = [
  {
    "key": "000",
    "value": "No other Additional Procedure Code applies"
  },
  {
    "key": "0GD",
    "value": "Goods consigned to other Government Departments and agencies (where not otherwise covered under Data Element 1/11 National Additional Procedure Codes)"
  },
  {
    "key": "1BN",
    "value": "Fresh bananas without a weight certificate"
  },
  {
    "key": "1CD",
    "value": "Controlled drugs using Simplified Procedures"
  },
  {
    "key": "1CG",
    "value": "Controlled Goods using Simplified Procedures"
  },
  {
    "key": "1CL",
    "value": "Taxable commodities for climate change levy, with or without a claim to levy relief."
  },
  {
    "key": "1DP",
    "value": "Goods imported under diplomatic privilege (official use)"
  },
  {
    "key": "1ES",
    "value": "Goods imported for or on behalf of the European Space Agency (ESA) or by Astrium Ltd for use in INTELSAT project"
  },
  {
    "key": "1EX",
    "value": "Mineral (Hydrocarbon) oil imported on which Excise Duty is suspended on entry to an excise warehouse"
  },
  {
    "key": "1IE",
    "value": "IP prior import equivalence (IM/EX)"
  },
  {
    "key": "1IL",
    "value": "Single recipient low value consignments in excess of �15 but not exceeding �135"
  },
  {
    "key": "1LV",
    "value": "Low Value Bulking Imports (LVBI) in excess of �15 but not exceeding �135"
  },
  {
    "key": "1MO",
    "value": "Goods imported by the MoD (MoD VAT postponed accounting)"
  },
  {
    "key": "1MP",
    "value": "Prior export of compensating products obtained from milk and milk products (EX/IM)."
  },
  {
    "key": "1NC",
    "value": "Equipment imported for certain NATO contracts and projects"
  },
  {
    "key": "1NN",
    "value": "Goods imported for non-NATO authorised countries (official use)"
  },
  {
    "key": "1NO",
    "value": "Goods imported for US and other NATO visiting forces (official use)"
  },
  {
    "key": "1NP",
    "value": "Goods imported for US and other NATO visiting forces entitled personnel (personal use)"
  },
  {
    "key": "1NV",
    "value": "Tariff Chapters 8703 (motor cars) and 8711 (motor cycles) permanently imported"
  },
  {
    "key": "1PF",
    "value": "Goods of an aggregate value not exceeding �630 (700 euros) intended solely for personal or family use."
  },
  {
    "key": "1PO",
    "value": "Goods imported using the international mail procedure where a written customs declaration is required to enter the goods to IP."
  },
  {
    "key": "1RC",
    "value": "Relief from Customs Duty"
  },
  {
    "key": "1RE",
    "value": "Relief from Excise Duty"
  },
  {
    "key": "1RL",
    "value": "RGR with End Use (Duty and VAT Relief claimed)"
  },
  {
    "key": "1RV",
    "value": "Relief from VAT (VAT exemption is claimed)"
  },
  {
    "key": "1SC",
    "value": "Consignments entered under the provisions of the split consignment facility."
  },
  {
    "key": "1SW",
    "value": "Goods imported under the shipwork or platform end-use procedure"
  },
  {
    "key": "1TO",
    "value": "Hydrocarbon oils imported under the �Tied Oil� scheme for Excise Duty relief."
  },
  {
    "key": "1VW",
    "value": "Goods liable to VAT only, declared for customs warehousing, or removed from customs warehousing"
  },
  {
    "key": "1XT",
    "value": "More than 1 previous procedure involving Temporary Admission"
  },
  {
    "key": "1XW",
    "value": "More than 1 previous procedure involving Customs Warehousing"
  },
  {
    "key": "2CD",
    "value": "Controlled drugs released to a special procedure using Simplified Procedures"
  },
  {
    "key": "2CG",
    "value": "Controlled Goods released to a special procedure using Simplified Procedures"
  },
  {
    "key": "2DP",
    "value": "Goods imported under diplomatic privilege (personal use)"
  },
  {
    "key": "2LV",
    "value": "Low Value Bulking Imports (LVBI) not exceeding �15"
  },
  {
    "key": "2MO",
    "value": "Goods imported by the MoD to fulfil contracts"
  },
  {
    "key": "3HG",
    "value": "HG3 notice (Inward Processing)"
  },
  {
    "key": "46P",
    "value": "Prior Import Equivalence for Outward Processing"
  },
  {
    "key": "48P",
    "value": "Prior Import Equivalence for the replacement of defective goods under Outward Processing"
  },
  {
    "key": "63P",
    "value": "Release to RGR with Onward Supply"
  },
  {
    "key": "68P",
    "value": "Re-imported goods placed in a non-customs warehouse where both Excise Duty and VAT (where applicable) are suspended"
  },
  {
    "key": "95P",
    "value": "Goods from a Special Fiscal Territory or Territory with which the EU has formed a customs union, placed under a non-customs warehousing procedure (excise warehousing) where both VAT and Excise duty is suspended"
  },
  {
    "key": "A04",
    "value": "Goods which are being placed under or discharged from an Inward Processing procedure (VAT only)"
  },
  {
    "key": "B02",
    "value": "Processed products returning after repair under guarantee in accordance with Article 260 of the Code (goods repaired free of charge)."
  },
  {
    "key": "B03",
    "value": "Processed products returning after replacement under guarantee in accordance with Article 261 of the Code (Standard Exchange System)"
  },
  {
    "key": "B06",
    "value": "Processed products returning - VAT only"
  },
  {
    "key": "C01",
    "value": "Personal property belonging to natural persons transferring their normal place of residence to the Union"
  },
  {
    "key": "C02",
    "value": "Trousseaux and household effects imported on the occasion of a marriage"
  },
  {
    "key": "C03",
    "value": "Presents customarily given on the occasion of a marriage"
  },
  {
    "key": "C04",
    "value": "Personal property acquired by inheritance by a natural person having his normal place of residence in the customs territory of the Union"
  },
  {
    "key": "C06",
    "value": "School outfits, educational materials and related household effects"
  },
  {
    "key": "C07",
    "value": "Consignments of negligible value"
  },
  {
    "key": "C08",
    "value": "Consignments sent from one private individual to another"
  },
  {
    "key": "C09",
    "value": "Capital goods and other equipment imported on the transfer of activities from a third country into the Union"
  },
  {
    "key": "C10",
    "value": "Capital goods and other equipment belonging to persons engaged in a liberal profession and to legal persons engaged in a non-profit making activity"
  },
  {
    "key": "C11",
    "value": "Educational, scientific and cultural materials; scientific instruments and apparatus as listed in Annex 1 to Regulation (EC) No. 1186/2009"
  },
  {
    "key": "C12",
    "value": "Educational, scientific and cultural materials; scientific instruments and apparatus as listed in Annex II to Regulation (EC) No 1186/2009"
  },
  {
    "key": "C13",
    "value": "Educational, scientific and cultural materials; scientific instruments and apparatus imported exclusively for non-commercial purposes (including spare parts, components, accessories and tools)"
  },
  {
    "key": "C14",
    "value": "Equipment imported for non-commercial purposes by or on behalf of a scientific research establishment or organisation based outside the Union"
  },
  {
    "key": "C15",
    "value": "Laboratory animals and biological or chemical substances intended for research"
  },
  {
    "key": "C16",
    "value": "Therapeutic substances of human origin and blood-grouping and tissue-typing reagents"
  },
  {
    "key": "C17",
    "value": "Instruments and apparatus used in medical research, establishing medical diagnoses or carrying out medical treatment"
  },
  {
    "key": "C18",
    "value": "Reference substances for the quality control of medicinal products"
  },
  {
    "key": "C19",
    "value": "Pharmaceutical products used at international sports events"
  },
  {
    "key": "C20",
    "value": "Goods for charitable or philanthropic organisations - basic necessities imported by State organisations or other approved organisations"
  },
  {
    "key": "C21",
    "value": "Articles in Annex III to Regulation (EC) No 1186/2009, intended for the blind"
  },
  {
    "key": "C22",
    "value": "Articles in Annex IV to Regulation (EC) No 1186/2009, intended for the blind imported by blind persons themselves for their own use (including spare parts, components, accessories and tools)"
  },
  {
    "key": "C23",
    "value": "Articles in Annex IV to Regulation (EC) No 1186/2009, intended for the blind imported by certain institutions or organisations (including spare parts, components, accessories and tools)"
  },
  {
    "key": "C24",
    "value": "Articles intended for other handicapped persons (other than blind persons) imported by handicapped persons themselves for their own use (including spare parts, components, accessories and tools)"
  },
  {
    "key": "C25",
    "value": "Articles intended for other handicapped persons (other than blind persons) imported by certain institutions or organisations (including spare parts, components, accessories and tools)"
  },
  {
    "key": "C26",
    "value": "Goods imported for the benefit of disaster victims"
  },
  {
    "key": "C27",
    "value": "Decorations conferred by governments of third countries on persons whose normal place of residence is in the customs territory of the Union"
  },
  {
    "key": "C28",
    "value": "Goods imported into the customs territory of the Union by persons who have paid an official visit to a third country and who have received them on this occasion as gifts from the host authorities"
  },
  {
    "key": "C29",
    "value": "Goods to be used by monarchs or heads of state"
  },
  {
    "key": "C30",
    "value": "Samples of goods of negligible value imported for trade promotion purposes"
  },
  {
    "key": "C31",
    "value": "Printed advertising matter"
  },
  {
    "key": "C32",
    "value": "Small representative samples of goods manufactured outside the customs territory of the Union, intended for a trade fair or similar event"
  },
  {
    "key": "C33",
    "value": "Goods imported for examination, analysis or test purposes"
  },
  {
    "key": "C34",
    "value": "Consignments, sent to organisations protecting copyrights or industrial and commercial patent rights"
  },
  {
    "key": "C35",
    "value": "Tourist information literature"
  },
  {
    "key": "C36",
    "value": "Miscellaneous documents and articles"
  },
  {
    "key": "C37",
    "value": "Ancillary materials for the stowage and protection of goods during their transport"
  },
  {
    "key": "C38",
    "value": "Litter, Fodder and Feeding Stuffs for Animals During Their Transport"
  },
  {
    "key": "C39",
    "value": "Fuel and lubricants present in land motor vehicles and special containers"
  },
  {
    "key": "C40",
    "value": "Materials for cemeteries for, and memorials to, war victims"
  },
  {
    "key": "C41",
    "value": "Coffins, funerary urns and ornamental funerary articles"
  },
  {
    "key": "C42",
    "value": "Personal property entered for free circulation before the person concerned establishes his normal place of residence in the customs territory of the Union (duty relief subject to an undertaking)"
  },
  {
    "key": "C43",
    "value": "Personal property belonging to a natural person having intention to transfer his normal place of residence to the Union (duty free admission subject to an undertaking)"
  },
  {
    "key": "C44",
    "value": "Personal property acquired by inheritance by legal persons engaged in a non-profit making activity who are established in the customs territory of the Union"
  },
  {
    "key": "C45",
    "value": "Agricultural, stock-farming, bee-keeping, horticultural and forestry products from properties located in a third country adjoining the customs territory of the Union"
  },
  {
    "key": "C46",
    "value": "Products of fishing or fish-farming activities carried out in the lakes or waterways bordering a Member State and a third country by Union fishermen and products of hunting activities carried out on such lakes or waterways by Union sportsmen"
  },
  {
    "key": "C47",
    "value": "Seeds, fertilisers and products for treatment of soil and crops intended for use on property located in the customs territory of the Union adjoining a third country"
  },
  {
    "key": "C48",
    "value": "Goods contained in the personal luggage and exempted from VAT"
  },
  {
    "key": "C49",
    "value": "Goods for charitable or philanthropic organisations: goods of every description sent free of charge and to be used for fund-raising at occasional charity events for the benefit of needy persons"
  },
  {
    "key": "C50",
    "value": "Goods for charitable or philanthropic organisations: equipment and office materials sent free of charge"
  },
  {
    "key": "C51",
    "value": "Cups, medals and similar articles of an essentially symbolic nature which having been awarded in a third country to persons having their normal place of residence in the customs territory of the Union"
  },
  {
    "key": "C52",
    "value": "Cups, medals and similar articles of an essentially symbolic nature which are given free of charge by authorities or persons established in a third country, to be presented in the customs territory of the Union, to a person having their normal place of residence in the EU"
  },
  {
    "key": "C53",
    "value": "Awards, trophies and souvenirs of a symbolic nature and of limited value intended for distribution free of charge to persons normally resident in third countries at business conferences or similar international events"
  },
  {
    "key": "C54",
    "value": "Goods imported into the customs territory of the Union by persons coming to pay an official visit in the customs territory of the Union and who intend to offer them on that occasion as gifts to the host authorities"
  },
  {
    "key": "C55",
    "value": "Goods sent as gifts, in token of friendship or goodwill, by an official body, public authority or group, carrying on an activity in the public interest which is located in a third country, to an official body, public authority or group carrying on an activity in the public interest which is located in the customs territory of the Union and approved by the competent authorities to receive such articles free of duty"
  },
  {
    "key": "C56",
    "value": "Articles for advertising purposes, of no intrinsic commercial value, sent free of charge by suppliers to their customers, which apart from their advertising function, are not capable of being used otherwise"
  },
  {
    "key": "C57",
    "value": "Goods imported solely in order to be demonstrated or in order to demonstrate machines and apparatus, manufactured outside the customs territory of the Union and displayed at a trade fair or similar event"
  },
  {
    "key": "C58",
    "value": "Various materials of little value such as paints, varnishes, wallpaper etc., used in the building, fitting-out and decoration of temporary stands occupied by representatives of third countries at a trade fair or similar event, which are destroyed by being used"
  },
  {
    "key": "C59",
    "value": "Printed matter, catalogues, prospectuses, price lists, advertising posters, calendars, whether or not illustrated, unframed photographs and other articles supplied free of charge in order to advertise goods manufactured outside the customs territory of the Union and displayed at a trade fair or similar event"
  },
  {
    "key": "C60",
    "value": "Trousseaux and household effects imported on the occasion of a marriage entered for free circulation in the first two months before the wedding (duty relief subject to the lodging of appropriate security)"
  },
  {
    "key": "C61",
    "value": "Presents customarily given on the occasion of a marriage entered for free circulation in the last two months before the wedding (duty relief subject to the lodging of appropriate security)"
  },
  {
    "key": "D01",
    "value": "Pallets (including pallet accessories and equipment)"
  },
  {
    "key": "D02",
    "value": "Containers (including container accessories and equipment)"
  },
  {
    "key": "D03",
    "value": "Means of road, rail, air, sea and inland waterway transport"
  },
  {
    "key": "D04",
    "value": "Personal effects and goods for sports purposes imported by travellers"
  },
  {
    "key": "D05",
    "value": "Welfare material for seafarers"
  },
  {
    "key": "D06",
    "value": "Disaster relief material"
  },
  {
    "key": "D07",
    "value": "Medical, surgical and laboratory equipment"
  },
  {
    "key": "D08",
    "value": "Animals (twelve months or more)"
  },
  {
    "key": "D09",
    "value": "Goods for use in frontier zone"
  },
  {
    "key": "D10",
    "value": "Sound, image or data carrying media"
  },
  {
    "key": "D11",
    "value": "Publicity material"
  },
  {
    "key": "D12",
    "value": "Professional equipment"
  },
  {
    "key": "D13",
    "value": "Pedagogic material and scientific equipment"
  },
  {
    "key": "D15",
    "value": "Packings, empty"
  },
  {
    "key": "D16",
    "value": "Moulds, dies, blocks, drawings, sketches, measuring, checking and testing instruments and other similar articles"
  },
  {
    "key": "D17",
    "value": "Special tools and instruments"
  },
  {
    "key": "D18",
    "value": "Goods subject to tests, experiments or demonstrations (six months)"
  },
  {
    "key": "D19",
    "value": "Goods imported, subject to satisfactory acceptance tests, in connection with a sales contract"
  },
  {
    "key": "D20",
    "value": "Goods used to carry out tests, experiments or demonstrations without financial gain (six months)"
  },
  {
    "key": "D21",
    "value": "Samples"
  },
  {
    "key": "D22",
    "value": "Replacement means of production (six months)"
  },
  {
    "key": "D23",
    "value": "Goods for events or for sale"
  },
  {
    "key": "D24",
    "value": "Goods for approval (six months)"
  },
  {
    "key": "D25",
    "value": "Works of art, collectors� items and antiques"
  },
  {
    "key": "D26",
    "value": "Goods imported with a view to their sale by auction"
  },
  {
    "key": "D27",
    "value": "Spare parts, accessories and equipment"
  },
  {
    "key": "D28",
    "value": "Goods imported in particular situations having no economic effect"
  },
  {
    "key": "D29",
    "value": "Goods imported for a period not exceeding three months"
  },
  {
    "key": "D30",
    "value": "Means of Transport for persons established outside the customs territory of the Union or for persons preparing for the transfer of their normal place of residence outside that territory."
  },
  {
    "key": "D51",
    "value": "Temporary Admission with partial relief from duties"
  },
  {
    "key": "E01",
    "value": "Use of the Unit Price for the Determination of the Customs Value for Certain Perishable Goods (Article 74(2)(c) of the Code and Article 142(6) of Commission Implementing Regulation No. (EU) 2015/2447 )"
  },
  {
    "key": "E02",
    "value": "Standard Import Values (SIV) as published in the EU Official Journal (OJ)"
  },
  {
    "key": "F01",
    "value": "Relief from import duties for returned goods (Article 203 of the Code)"
  },
  {
    "key": "F02",
    "value": "Relief from import duties for returned goods (Special circumstances provided for in Article 159 of Delegated Regulation (EU) 2015/2446: agriculture goods)"
  },
  {
    "key": "F03",
    "value": "Relief from import duties for returned goods (Special circumstances provided for in Article 158 (2) of Delegated Regulation (EU) 215/2446 repair or restoration)"
  },
  {
    "key": "F04",
    "value": "Processed products which return to the European Union after having been previously re-exported subsequent to an inward processing procedure (Article 205 (1) of the Code)"
  },
  {
    "key": "F05",
    "value": "Relief from import duties and from VAT for returned goods (Art. 203 of the Code and Art. 143(1)(e) (Directive 2006/112/EC)"
  },
  {
    "key": "F06",
    "value": "A movement of excise goods under an excise duty suspension arrangement from the place of importation in accordance with Article 17(1)(b) of Directive 2008/118/EC"
  },
  {
    "key": "F07",
    "value": "Processed products which returned to the European Union after having been previously re-exported subsequent to an inward processing procedure where the import duty is determined in accordance with Article 86(3) of the Code (Article 205(2) of the Code)"
  },
  {
    "key": "F15",
    "value": "Goods introduced in the context of trade with Special Fiscal Territories or territories with which the EU has formed a Customs Union (Article 1(3) of the Code)"
  },
  {
    "key": "F21",
    "value": "Exemption from import duties of products of sea-fishing and other products taken from the territorial sea of a country or territory outside the customs territory of the Union by vessels solely registered or recorded in a Member State and flying the flag of that state"
  },
  {
    "key": "F22",
    "value": "Exemption from import duties of products obtained from products of sea fishing and other products taken from the territorial sea of a country or territory outside the customs territory of the Union on board factory-ships registered or recorded in a Member State and flying the flag of the state"
  },
  {
    "key": "F44",
    "value": "Inward Processing; customs debt under Article 86(3) of the Code"
  },
  {
    "key": "F45",
    "value": "Exemption from value added tax on the final importation of certain goods (Council Directive 2009/132/EC (*))"
  },
  {
    "key": "F47",
    "value": "Simplification of the drawing-up of customs declarations for goods falling under different tariff subheadings"
  },
  {
    "key": "F48",
    "value": "Claim under special scheme for distance sales of goods imported from third countries or third territories (Title XII Chapter Section 4 Directive 2006/112/EC) (Import One Stop Shop (IOSS))"
  },
  {
    "key": "1EB",
    "value": "Grouping excise goods falling under different tariff subheadings"
  },
  {
    "key": "1FV",
    "value": "Use of a provisional customs value pending sale of the goods after import or where no evidence is held to prove a declared value for equivalent goods and valuation method 4 is used (Taxation (Cross-Border Trade) Act 2018; Part 1; Section16(6b) and The Customs (Import Duty) (EU Exit) Regulations 2018; Part 12; Regulation 123)"
  },
  {
    "key": "1H7",
    "value": "Declaration Category H7 — Super Reduced Data Set (SRDS)"
  },
  {
    "key": "1PP",
    "value": "Northern Ireland Personal Property Relief (NIPPR) — Goods eligible for NIPPR"
  },
  {
    "key": "2CL",
    "value": "Taxable commodities claiming partial relief or no relief of the Climate Change Levy"
  }
];

export default requestedAdditionalProceedure;