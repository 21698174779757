import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import elements from "../../../constants/validation";
import ThemeContext from "../../../context/theme/ThemeContext";
import TablePagination from "./components/Pagination";
import TableBody from "./components/Body";
import InputsActions from "./components/InputsActions";
import TableInputs from "./components/Inputs";
import TableHead from "./components/Header";
import { useTranslation } from "react-i18next";
import DeclarationsContext from "../../../context/declarations/DeclarationsContext";
import { getQueryParam } from "../../../router/utils";
import { ITEM_QUERY_PARAM } from "../../../constants/GlobalConstants";
import SearchInput from "../SearchInput";

const MultiLineWithTableElement = (props) => {
	const { withTheme, theme } = useContext(ThemeContext);
	const [errors, setErrors] = useState({});
	const { t } = useTranslation();
	const { state, getElementObligation } = useContext(DeclarationsContext);
	const { onChange: onChangeProp, value: valueProp } = props;
	const { components, elementName, limit, validations } = props.element;
	const {
		inputValues: inputValuesProp,
		setInputValues: setInputValuesProp,
		onSaveAsNewClick,
		isSaveLoading,
	} = props.groupingElement;

	const readOnlyItems = useMemo(() => getElementObligation(elementName), [elementName]);

	const disabled = state.disabledElements?.find(
		(element) =>
			element.elementName === elementName &&
			element.itemIndex === parseInt(getQueryParam(ITEM_QUERY_PARAM))
	);

	const disallowDuplicates = useMemo(
		() => validations?.find((validation) => validation.type === "disallowDuplicates"),
		[validations]
	);

	// All values of element inputs
	const [inputValues, setInputValues] = useState([]);
	// Array of table items
	const [items, setItems] = useState([]);

	// filteredItems are items filtered with searchValue
	const [filteredItems, setFilteredItems] = useState([]);
	const [searchValue, setSearchValue] = useState("");

	const [selectedRowIndex, setSelectedRowIndex] = useState(null);
	const [selectedPage, setSelectedPage] = useState(0);

	const componentElements = useMemo(
		() =>
			components.map((component) =>
				elements.find(({ elementName }) => elementName === component.elementName)
			),
		[components]
	);

	const updateItems = (items) => {
		setInputValues({});

		/**
		 * Add a "sequenceNumber" property with the value of the array index + 1 to each one of the element items
		 */
		if (elementName === "containerIdentificationNumberMultiline") {
			items = items.map((item, index) => ({ ...item, sequenceNumber: index + 1 }));
		}

		onChangeProp(elementName, items);
	};

	useEffect(() => {
		if (!valueProp) return setItems([]);
		setItems([...valueProp]);
	}, [valueProp]);

	useEffect(() => {
		let filteredItems = [];

		if (searchValue) {
			const lowerCaseSearchValue = searchValue.toLowerCase();

			items.forEach((item) => {
				keys: for (const key of Object.keys(item)) {
					if (String(item[key])?.toLowerCase().includes(lowerCaseSearchValue)) {
						filteredItems.push(item);
						break;
					}

					const validationElement = componentElements.find(
						(elem) => elem.elementName === key
					);

					if (validationElement?.pullDownMenu) {
						for (const option of validationElement.pullDownMenu) {
							if (
								option.key === item[key] &&
								option.value
									.toLowerCase()
									.includes(lowerCaseSearchValue)
							) {
								filteredItems.push(item);
								break keys;
							}
						}
					}
				}
			});
		} else {
			filteredItems = [...items];
		}

		setFilteredItems([...filteredItems]);
	}, [items, searchValue]);

	useEffect(() => {
		setInputValues({
			...inputValuesProp,
		});
	}, [inputValuesProp]);

	return (
		<div id={elementName} style={{ paddingTop: 12 }} className={`${elementName}-${theme.name}`}>
			<div>
				<TableInputs
					componentElements={componentElements}
					inputValues={inputValues}
					disabled={disabled}
					readOnlyItems={readOnlyItems}
					errors={errors}
					setErrors={setErrors}
					setInputValues={setInputValuesProp || setInputValues}
				/>

				<InputsActions
					items={items}
					disallowDuplicates={disallowDuplicates}
					components={components}
					updateItems={updateItems}
					selectedRowIndex={selectedRowIndex}
					limit={limit}
					setSelectedRowIndex={setSelectedRowIndex}
					inputValues={inputValues}
					disabled={disabled}
					setInputValues={setInputValues}
					setSelectedPage={setSelectedPage}
					setErrors={setErrors}
					onSaveAsNewClick={onSaveAsNewClick}
					isSaveLoading={isSaveLoading}
				/>
			</div>

			<SearchInput
				containerStyle={{ width: 236, marginTop: 8, marginBottom: 8 }}
				value={searchValue}
				onChange={(e) => setSearchValue(e.target.value)}
			/>

			{Boolean(items.length) && (
				<div className={withTheme("table-container")}>
					<table className={withTheme("table")}>
						<TableHead components={components} />

						<TableBody
							items={filteredItems}
							selectedPage={selectedPage}
							components={components}
							disabled={disabled}
							readOnlyItems={readOnlyItems}
							updateItems={updateItems}
							setSelectedRowIndex={setSelectedRowIndex}
							setInputValues={setInputValuesProp || setInputValues}
						/>
					</table>

					{/* Pagination Elements */}
					<TablePagination
						items={filteredItems}
						selectedPage={selectedPage}
						setSelectedPage={setSelectedPage}
					/>
				</div>
			)}

			{items.length === 0 && (
				<div className={withTheme("empty-table-message")}>{t("noItemsAdded")}</div>
			)}
		</div>
	);
};

MultiLineWithTableElement.propTypes = {
	onChange: PropTypes.func.isRequired,
};

export default MultiLineWithTableElement;
