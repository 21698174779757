import { useContext, useState } from "react";
import ThemeContext from "../../context/theme/ThemeContext";
import { PiMagnifyingGlass } from "react-icons/pi";

const SearchInput = (props) => {
	const { withTheme } = useContext(ThemeContext);
	const [isFocused, setIsFocused] = useState(false);

	const { containerStyle, onFocus, onBlur, ...rest } = props;

	return (
		<div
			style={{ ...props.containerStyle }}
			className={`${withTheme("search-input-container")} ${
				isFocused ? withTheme("search-input-container-focused") : ""
			}`}
		>
			<PiMagnifyingGlass style={{ color: "#B0B5BD", fontSize: 16 }} />

			<input
				placeholder={props.placeholder || "Search"}
				className={withTheme("search-input")}
				onFocus={() => {
					onFocus?.();
					setIsFocused(true);
				}}
				onBlur={() => {
					onBlur?.();
					setIsFocused(false);
				}}
				{...rest}
			/>
		</div>
	);
};

export default SearchInput;
