import { useTranslation } from "react-i18next";
import Input from "../../../../components/common/Input";
import { useCallback, useContext, useEffect, useState } from "react";
import Button from "../../../../components/common/Button";
import { PiTrash } from "react-icons/pi";
import DeclarationsContext from "../../../../context/declarations/DeclarationsContext";
import { debounce } from "lodash";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal";
import { updateQueryParam } from "../../../../router/utils";
import { ITEM_QUERY_PARAM } from "../../../../constants/GlobalConstants";
import ThemeContext from "../../../../context/theme/ThemeContext";

const ItemHeader = ({ selectedItemIndex }) => {
	const { state, updateItem, deleteItem, isDeclarationReadOnly } = useContext(DeclarationsContext);
	const [itemName, setItemName] = useState("");
	const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
	const { withTheme } = useContext(ThemeContext);
	const { t } = useTranslation();

	const debouncedNameUpdate = useCallback(
		debounce((name) => {
			updateItem(selectedItemIndex, { name });
		}, 300),
		[selectedItemIndex]
	);

	const handleItemNameChange = (e) => {
		const { value } = e.target;

		setItemName(value);

		if (value) {
			debouncedNameUpdate(value);
		}
	};

	const handleItemDelete = () => {
		if (state.declaration.data.declarationItems.length === 1) {
			return;
		}

		updateQueryParam(ITEM_QUERY_PARAM, 0);
		deleteItem(selectedItemIndex);
		setIsDeleteConfirmOpen(false);
	};

	useEffect(() => {
		setItemName(state.declaration.data.declarationItems[selectedItemIndex]?.name);
	}, [selectedItemIndex]);

	return (
		<div style={{ display: "flex", alignItems: "flex-end", gap: 40, width: "100%" }}>
			<ConfirmationModal
				isOpen={isDeleteConfirmOpen}
				title={t("areYouSure")}
				description={t("deleteItemConfirm")}
				primaryActionLabel={t("removeItem")}
				onPrimaryActionClick={handleItemDelete}
				onClose={() => setIsDeleteConfirmOpen(false)}
			/>

			<div style={{ width: "60%", maxWidth: 380 }}>
				<Input
					value={itemName}
					readOnly={isDeclarationReadOnly()}
					label={t("itemName")}
					placeholder={t("itemName")}
					onChange={handleItemNameChange}
				/>

				{!itemName && <div className={withTheme("error-text")}>{t("required")}</div>}
			</div>

			<div style={{ width: "fit-content", marginLeft: "auto" }}>
				{state.declaration.data.declarationItems.length > 1 && (
					<Button
						onClick={() => setIsDeleteConfirmOpen(true)}
						type="secondary"
						LeftIcon={PiTrash}
					>
						{t("removeItem")}
					</Button>
				)}
			</div>
		</div>
	);
};

export default ItemHeader;
