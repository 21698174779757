import { SET_NOTIFICATIONS, SET_UNREAD_NOTIFICATIONS_COUNT } from "../types";

const notificationsReducer = (state, action) => {
	switch (action.type) {
		case SET_UNREAD_NOTIFICATIONS_COUNT: {
			return {
				...state,
				unreadCount: action.payload,
			};
		}
      
		case SET_NOTIFICATIONS:
			return {
				...state,
				notifications: action.payload,
			};

		default:
			return state;
	}
};

export default notificationsReducer;
