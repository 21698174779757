import { useContext, useMemo } from "react";
import Card from "../../common/Card";
import ThemeContext from "../../../context/theme/ThemeContext";
import Track from "../Track";
import { useTranslation } from "react-i18next";
import DeclarationsContext from "../../../context/declarations/DeclarationsContext";
import WorkspacesContext from "../../../context/workspaces/WorkspacesContext";

const SubscriptionUsageCard = () => {
	const { t } = useTranslation();
	const { workspaces, selectedWorkspaceId } = useContext(WorkspacesContext);
	const { declarations } = useContext(DeclarationsContext).state;
	const { withTheme } = useContext(ThemeContext);

	const currentWorkspace = useMemo(
		() => workspaces.find((workspace) => workspace._id === selectedWorkspaceId),
		[workspaces, selectedWorkspaceId]
	);

	return (
		<Card style={{ height: 264, width: "50%" }}>
			<div className={withTheme("dashboard-card-label")}>{t("subscriptionUsage")}</div>

			<div style={{ paddingLeft: 16, paddingRight: 16 }}>
				<Track
					label={t("totalDeclarationsSubmitted")}
					value={declarations.length}
					total={currentWorkspace?.subscription.limits.declarationsLimit}
				/>
			</div>
		</Card>
	);
};

export default SubscriptionUsageCard;
