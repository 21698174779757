const airports = [
	{
		key: "ABE",
		value: "Allentown, PA Lehigh Valley Intl United States",
	},
	{
		key: "ACY",
		value: "Atlantic City Atlantic City Intl United States",
	},
	{
		key: "ALB",
		value: "Albany Albany Intl United States",
	},
	{
		key: "ATL",
		value: "Atlanta Hartsfield-Jackson United States",
	},
	{
		key: "ATW",
		value: "Appleton Appleton International United States",
	},
	{
		key: "AVP",
		value: "Wilkes-Barre/Scranton Wilkes-Barre/Scranton International Airport United States",
	},
	{
		key: "AZO",
		value: "Kalamazoo/Battle Creek, MI Kalamazoo/Battle Creek Intl Airport United States",
	},
	{
		key: "BDA",
		value: "Bermuda L.F. Wade International Bermuda",
	},
	{
		key: "BDL",
		value: "Windsor Locks Bradley International United States",
	},
	{
		key: "BGR",
		value: "Bangor, Maine, ME Bangor Intl Airport United States",
	},
	{
		key: "BHM",
		value: "Birmingham, AL Birmingham Shuttleworth Intl United States",
	},
	{
		key: "BNA",
		value: "Nashville Nashville International United States",
	},
	{
		key: "BOS",
		value: "Boston Logan International United States",
	},
	{
		key: "BTV",
		value: "Burlington, VT Burlington Intl United States",
	},
	{
		key: "BUF",
		value: "Buffalo Buffalo Niagara Intl United States",
	},
	{
		key: "BWI",
		value: "Baltimore Washington Int United States",
	},
	{
		key: "CAK",
		value: "Akron/Canton Metropolitan Area United States",
	},
	{
		key: "CHI",
		value: "Chicago Chicago FSS United States",
	},
	{
		key: "CHS",
		value: "Charleston Charleston Intl United States",
	},
	{
		key: "CLE",
		value: "Cleveland Hopkins International United States",
	},
	{
		key: "CLT",
		value: "Charlotte Douglas United States",
	},
	{
		key: "CMH",
		value: "Columbus Port Columbus Intl United States",
	},
	{
		key: "CVG",
		value: "Cincinnati Northern Kentucky United States",
	},
	{
		key: "DAY",
		value: "Dayton, OH Dayton Intl United States",
	},
	{
		key: "DCA",
		value: "Washington Ronald Reagan National United States",
	},
	{
		key: "DET",
		value: "Detroit Colman A Young Intl United States",
	},
	{
		key: "DLH",
		value: "Duluth/Superior Duluth Intl United States",
	},
	{
		key: "DSM",
		value: "Des Moines Des Moine Intl United States",
	},
	{
		key: "DTW",
		value: "Detroit Metropolitan Area United States",
	},
	{
		key: "EAU",
		value: "Eau Claire Chippewa Valley United States",
	},
	{
		key: "ERI",
		value: "Erie Erie Intl United States",
	},
	{
		key: "EWR",
		value: "New York - Newark Newark Liberty Intl United States",
	},
	{
		key: "FCM",
		value: "Minneapolis Flying cloud United States",
	},
	{
		key: "FNT",
		value: "Flint, MI Bishop Intl United States",
	},
	{
		key: "FSP",
		value: "Saint-Pierre St. Pierre, NF Canada",
	},
	{
		key: "FWA",
		value: "Fort Wayne Fort Wayne Intl United States",
	},
	{
		key: "GRB",
		value: "Green Bay, WI Austin Straubel Intl United States",
	},
	{
		key: "GRR",
		value: "Grande Rapids Gerald R Ford Intl United States",
	},
	{
		key: "GSO",
		value: "Greensboro/Winston Salem, NC Piedmont Triad Intl United States",
	},
	{
		key: "GSP",
		value: "Greenville-Spartanburg Greenville/Spartanburg Intl United States",
	},
	{
		key: "HSV",
		value: "Huntsville Huntsville Intl United States",
	},
	{
		key: "HUF",
		value: "Terre Haute Terre Haute Intl United States",
	},
	{
		key: "IAD",
		value: "Washington Dulles Intl United States",
	},
	{
		key: "IAG",
		value: "Niagara Falls Niagara Falls Intl United States",
	},
	{
		key: "ILM",
		value: "Wilmington Wilmington Intl United States",
	},
	{
		key: "IND",
		value: "Indianapolis Indianapolis Intl United States",
	},
	{
		key: "JFK",
		value: "New York John F Kennedy Intl United States",
	},
	{
		key: "LAN",
		value: "Lansing Capital Region Intl United States",
	},
	{
		key: "LEX",
		value: "Lexington Blue Grass United States",
	},
	{
		key: "LGA",
		value: "New York La Guardia United States",
	},
	{
		key: "LOU",
		value: "Louisville Bowman field United States",
	},
	{
		key: "MCl",
		value: "Kansas City Kansas City Intl United States",
	},
	{
		key: "MDT",
		value: "Harrisburg Harrisburg Intl United States",
	},
	{
		key: "MDW",
		value: "Chicago Midway United States",
	},
	{
		key: "MEM",
		value: "Memphis Memphis Intl United States",
	},
	{
		key: "MIC",
		value: "Minneapolis Crystal United States",
	},
	{
		key: "MKC",
		value: "Kansas City Charles B Wheeler United States",
	},
	{
		key: "MKE",
		value: "Milwaukee General Mitchell United States",
	},
	{
		key: "MLI",
		value: "Moline Quad City Intl United States",
	},
	{
		key: "MSP",
		value: "Minneapolis St Paul Intl United States",
	},
	{
		key: "MSY",
		value: "New Orleans Louis Armstrong United States",
	},
	{
		key: "NEW",
		value: "New Orleans Lakefront United States",
	},
	{
		key: "NYC",
		value: "New York Metropolitan Area United States",
	},
	{
		key: "ORD",
		value: "Chicago O Hare International United States",
	},
	{
		key: "ORF",
		value: "Norfolk Norfolk Intl United States",
	},
	{
		key: "PHL",
		value: "Philadelphia Philadelphia Intl United States",
	},
	{
		key: "PIT",
		value: "Pittsburgh Pittsburgh Intl United States",
	},
	{
		key: "PNE",
		value: "Philadelphia North North East Philadelphia United States",
	},
	{
		key: "PVD",
		value: "Providence T. F. Intl United States",
	},
	{
		key: "PWM",
		value: "Portland Portland Intl United States",
	},
	{
		key: "RDU",
		value: "Durham Raleigh-Durham Intl United States",
	},
	{
		key: "RFD",
		value: "Rockford Chicago Rockford Intl United States",
	},
	{
		key: "RIC",
		value: "Richmond Richmond Intl United States",
	},
	{
		key: "ROC",
		value: "Rochester Greater Rochester Intl United States",
	},
	{
		key: "SAV",
		value: "Savannah Savannah/Hilton Head Intl United States",
	},
	{
		key: "SBN",
		value: "South South Bend Intl United States",
	},
	{
		key: "SBY",
		value: "Salisbury Salisbury Ocean City Wicomico County United States",
	},
	{
		key: "SDF",
		value: "Louisville Muhammad Ali International United States",
	},
	{
		key: "SFJ",
		value: "Soendre Soendre Stroemfjord Greenland",
	},
	{
		key: "SGF",
		value: "Springfield Springfield-Branson Rg United States",
	},
	{
		key: "STL",
		value: "St Louis Lambert-St Louis Intl United States",
	},
	{
		key: "SWF",
		value: "Newburgh Stewart Intl United States",
	},
	{
		key: "SYR",
		value: "Syracuse Syracuse Hancock Intl United States",
	},
	{
		key: "TLH",
		value: "Tallahassee Tallahassee Intl United States",
	},
	{
		key: "UAK",
		value: "Narsarsuaq Narsarsuaq Greenland",
	},
	{
		key: "WAS",
		value: "Washington Metropolitan Area United States",
	},
	{
		key: "YFC",
		value: "Fredericton Fredericton Intl Canada",
	},
	{
		key: "YHM",
		value: "Hamilton John C Munro Hamilton Intl Canada",
	},
	{
		key: "YHZ",
		value: "Halifax Halifax Stanfield Intl Canada",
	},
	{
		key: "YMX",
		value: "Montreal Mirabel Canada",
	},
	{
		key: "YOW",
		value: "Ottowa MacDonald-Cartier International Canada",
	},
	{
		key: "YQB",
		value: "Quebec Jean Lesage Intl Canada",
	},
	{
		key: "YQG",
		value: "Windsor Windsor Intl Canada",
	},
	{
		key: "YQM",
		value: "Moncton Greater Moncton Intl Canada",
	},
	{
		key: "YQT",
		value: "Thunder Thunder Bay Intl Canada",
	},
	{
		key: "YQX",
		value: "Gander Gander international Canada",
	},
	{
		key: "YTO",
		value: "Toronto Metropolitan Area Canada",
	},
	{
		key: "YTZ",
		value: "Toronto Toronto Island/Billy Bishop Canada",
	},
	{
		key: "YUL",
		value: "Montreal Pierre Elliott Intl Canada",
	},
	{
		key: "YXU",
		value: "London Metropolitan Area Canada",
	},
	{
		key: "YYC",
		value: "Calgary Calgary Intl Canada",
	},
	{
		key: "YYT",
		value: "St John's St. John's Intl Canada",
	},
	{
		key: "YYZ",
		value: "Toronto Pearson International Canada",
	},
	{
		key: "AAX",
		value: "Araxa Araxa Airport Brazil",
	},
	{
		key: "ABQ",
		value: "Albuquerque Albuquerque Intl United States",
	},
	{
		key: "ABR",
		value: "Aberdeen Aberdeen Regional United States",
	},
	{
		key: "ACA",
		value: "Acapulco General Juan N Alvarez Intl Mexico",
	},
	{
		key: "ADZ",
		value: "San Andres Island Gustavo Rojas Pinilla International Airport Colombia",
	},
	{
		key: "AEP",
		value: "Buenas Aires Airpt Jorge Newbery Argentina",
	},
	{
		key: "AGU",
		value: "Aguascalientes Lic. Jesús Terán Peredo International Mexico",
	},
	{
		key: "ALT",
		value: "Alenquer Alenquer Airport Brazil",
	},
	{
		key: "AMA",
		value: "Amarillo, Texas, TX Rick Husband Amarillo International Airport United States",
	},
	{
		key: "ANF",
		value: "Antofagasta Cerro Moreno Chile",
	},
	{
		key: "ANU",
		value: "Antigua V.C. Bird Intl Antigua and Barbuda",
	},
	{
		key: "ASI",
		value: "Ascension RAF Ascension Island Ascension",
	},
	{
		key: "ASU",
		value: "Asancion Silvio Petarossi Paraguay",
	},
	{
		key: "AUS",
		value: "Austin Austin-Bergstrom Intl United States",
	},
	{
		key: "AXA",
		value: "Anguilla Clayton J. Lloyd Intl Anguilla",
	},
	{
		key: "BAQ",
		value: "Barranquilla Ernesto Cortissoz Colombia",
	},
	{
		key: "BEL",
		value: "Belem Val de Cans Brazil",
	},
	{
		key: "BGA",
		value: "Bucaramanga Palonegro Intl Colombia",
	},
	{
		key: "BGI",
		value: "Bridgetown Granley Adams Int Barbados",
	},
	{
		key: "BHZ",
		value: "Belo Horizonte Metropolitan Area Brazil",
	},
	{
		key: "BIL",
		value: "Billings Billings Logan Intl United States",
	},
	{
		key: "BJX",
		value: "Leon Del Bajio Intl Mexico",
	},
	{
		key: "BLA",
		value: "Barcelona Gen Jose Antonio Anzoategui Venezuela",
	},
	{
		key: "BLI",
		value: "Bellingham, WA Bellingham Intl United States",
	},
	{
		key: "BNU",
		value: "Blumenau Blumenau Airport Brazil",
	},
	{
		key: "BOG",
		value: "Bogota El Dorado Intl Colombia",
	},
	{
		key: "BQN",
		value: "Auquadilla Rafael Hernandez Puerto Rico",
	},
	{
		key: "BRA",
		value: "Barreiras Barreiras Airport Brazil",
	},
	{
		key: "BRC",
		value: "San Carlos de Bariloche San Carlos de Bariloche Intl Argentina",
	},
	{
		key: "BSB",
		value: "Brasilia Pres. Juscelino Kubitschek Intl Brazil",
	},
	{
		key: "BUE",
		value: "Buenos Aires Metropolitan Area Argentina",
	},
	{
		key: "BVB",
		value: "Boa Vista Boa Vista International Brazil",
	},
	{
		key: "BZE",
		value: "Belize City Philip SW Goldson Int Belize",
	},
	{
		key: "BZN",
		value: "Bozeman, MT Yellowstone Intl United States",
	},
	{
		key: "CAP",
		value: "Cap Haitien Cap-Haitien Haiti",
	},
	{
		key: "CBB",
		value: "Cochabamba Jorge Wilsterman Intl Bolivia",
	},
	{
		key: "CCP",
		value: "Concepcion Carriel Sur Chile",
	},
	{
		key: "CCS",
		value: "Caracas Simon Bolivar Venzuela",
	},
	{
		key: "CCZ",
		value: "Chub Cay Chub Cay Intl Bahamas",
	},
	{
		key: "CEN",
		value: "Ciudad Obregon Ciudad Obregon Intl Mexico",
	},
	{
		key: "CFG",
		value: "Cienfuegos Jamie Gonzalez Cuba",
	},
	{
		key: "CGB",
		value: "Cuiaba Marechal Rondon Intl Brazil",
	},
	{
		key: "CGH",
		value: "Sao Paulo Congonhas Brazil",
	},
	{
		key: "CGR",
		value: "Campo Grande Campo Grande Intl Brazil",
	},
	{
		key: "CJS",
		value: "Ciudad Juarez Abraham Gonzalez Intl Mexico",
	},
	{
		key: "CKS",
		value: "Carajas Carajas Airport Brazil",
	},
	{
		key: "CLM",
		value: "Port Angeles William R Fairchild Int United States",
	},
	{
		key: "CLO",
		value: "Cali Alfonso B Aragon Colombia",
	},
	{
		key: "CME",
		value: "Cuidad del Carmen Cuidad del Carmen Intl Mexico",
	},
	{
		key: "CMM",
		value: "Carmelita Carmelita Airport Guatemala",
	},
	{
		key: "CNA",
		value: "Cananea Cananea National Mexico",
	},
	{
		key: "CNF",
		value: "Belo Horizonte Tancredo Neves Intl Brazil",
	},
	{
		key: "COC",
		value: "Concordia Comodoro Pierrestegui Airport Argentina",
	},
	{
		key: "COR",
		value: "Cordoba Ingemiero Aeronautico Ambrosio L.B Taravella Intl Argentina",
	},
	{
		key: "CRP",
		value: "Corpus Christi Corpus Christi Intl United States",
	},
	{
		key: "CTG",
		value: "Cartagena Rafeal Nunez Colombia",
	},
	{
		key: "CUL",
		value: "Culiacan Bachigualato Federal Intl Mexico",
	},
	{
		key: "CUN",
		value: "Cancun Cancun Intl Mexico",
	},
	{
		key: "CUR",
		value: "Curacao Curacao International Netherlands Antilles",
	},
	{
		key: "CUU",
		value: "Chinhuahua Gen Fierro Villalobos Mexico",
	},
	{
		key: "CVM",
		value: "Ciudad Victoria Gen Pedro J Mendez Intl Mexico",
	},
	{
		key: "CWB",
		value: "Curitiba Alfonso Pena Brazil",
	},
	{
		key: "CZA",
		value: "Chichen Itza Chichen Itza Intl Mexico",
	},
	{
		key: "CZM",
		value: "Cozumel Cozmel Intl Mexico",
	},
	{
		key: "DAB",
		value: "Daytona Beach, FL Daytona Beach Intl United States",
	},
	{
		key: "DAL",
		value: "Dallas Love Field United States",
	},
	{
		key: "DEN",
		value: "Denver Denver International United States",
	},
	{
		key: "DFW",
		value: "Dallas Fort Worth United States",
	},
	{
		key: "DOM",
		value: "Dominica Douglas-Charles Airport Dominica",
	},
	{
		key: "EFD",
		value: "Houston Ellington Field United States",
	},
	{
		key: "EIS",
		value: "Beef Island Terrance B Lettsome Virgin Islands (British)",
	},
	{
		key: "ELP",
		value: "El Paso El Paso Intl United States",
	},
	{
		key: "EYW",
		value: "Key West Key West Intl United States",
	},
	{
		key: "EZE",
		value: "Buenos Aires Ministro Pistarini Argentina",
	},
	{
		key: "FAT",
		value: "Fresno, CA Fresno Yosemite Intl United States",
	},
	{
		key: "FCA",
		value: "Kalispell, MT Glacier Park Intl United States",
	},
	{
		key: "FLL",
		value: "Fort Lauderdale/Hollywood Fort Lauderdale/Hollywood Intl United States",
	},
	{
		key: "FLN",
		value: "Florianopolis Hercilio Luz Brazil",
	},
	{
		key: "FOR",
		value: "Fortaleza Pinto Martins Brazil",
	},
	{
		key: "FPO",
		value: "Grand Bahama Grand Bahama Intl Bahamas",
	},
	{
		key: "GCM",
		value: "Grand Cayman Owen Roberts Intl Cayman Islands",
	},
	{
		key: "GDL",
		value: "Guadalajara Miguel Hidal Mexico",
	},
	{
		key: "GEG",
		value: "Spokane Spokane Intl United States",
	},
	{
		key: "GEO",
		value: "Georgetown Cheddi Jagan Intl Guyana",
	},
	{
		key: "GIG",
		value: "Rio de Janeiro Galeao Intl Brazil",
	},
	{
		key: "GND",
		value: "Grenada Maurice Bishop Intl Grenada",
	},
	{
		key: "GPT",
		value: "Gulfport/Biloxi Gulfport/Biloxi Intl United States",
	},
	{
		key: "GRU",
		value: "Sao Paulo Guarulhos Intl Brazil",
	},
	{
		key: "GTF",
		value: "Great Falls Great Falls Intl United States",
	},
	{
		key: "GUA",
		value: "Guatemala City La Aurora Guatemala",
	},
	{
		key: "GYE",
		value: "Guayaquil Jose Joaquin de Olmedo Intl Ecuador",
	},
	{
		key: "HAV",
		value: "Havana Jose Marti Intl Cuba",
	},
	{
		key: "HMO",
		value: "Hermosillo Gen. Pesqueira Garcia Intl Mexico",
	},
	{
		key: "HOG",
		value: "Holguin Frank Pais Cuba",
	},
	{
		key: "HOU",
		value: "Houston William P Hobby United States",
	},
	{
		key: "HRL",
		value: "Harlington/South Padre Island, TX Valley Intl United States",
	},
	{
		key: "HUG",
		value: "Huehuetenango Huehuetenango Guatemala",
	},
	{
		key: "HUX",
		value: "Huatulco Bahias de Huatulco Intl Mexico",
	},
	{
		key: "IAH",
		value: "Houston George Bush Intercontinental United States",
	},
	{
		key: "IGR",
		value: "Iguazu, Cataratas Cataratas del Iguazu Intl Argentina",
	},
	{
		key: "IGU",
		value: "Iguassu Falls Cataratas Brazil",
	},
	{
		key: "INB",
		value: "Independence Independence Belize",
	},
	{
		key: "IPC",
		value: "Easter Island Mataveri Intl Chile",
	},
	{
		key: "IQT",
		value: "Iquitos Francisco Secada Vignetta Intl Peru",
	},
	{
		key: "JAK",
		value: "Jacmel Jacmel Haiti",
	},
	{
		key: "JAN",
		value: "Jackson Jackson International United States",
	},
	{
		key: "JAX",
		value: "Jacksonville Jacksonville Intl United States",
	},
	{
		key: "JEE",
		value: "Jeremie Jeremie Airport Haiti",
	},
	{
		key: "JPA",
		value: "Joao Pessoa Castro Pinto Airport Brazil",
	},
	{
		key: "JUJ",
		value: "Jujuy Gobernador Horacio Guzman Intl Argentina",
	},
	{
		key: "JUL",
		value: "Juliaca Inca Manco Capac Intl Peru",
	},
	{
		key: "KIN",
		value: "Kingston Norman Manley Jamaica",
	},
	{
		key: "LAP",
		value: "La Paz Manuel Marquez de Leon Intl Mexico",
	},
	{
		key: "LAS",
		value: "Las Vegas Mccarran Intl United States",
	},
	{
		key: "LAX",
		value: "Los Angeles Los Angeles Intl United States",
	},
	{
		key: "LBB",
		value: "Lubbock, TX Preston Smith Intl United States",
	},
	{
		key: "LIM",
		value: "Lima J Chavez lntl Peru",
	},
	{
		key: "LMM",
		value: "Los Mochis Fort Valley Federal Intl Mexico",
	},
	{
		key: "LPB",
		value: "La Paz El Alto Bolivia",
	},
	{
		key: "LRM",
		value: "La Romana La Romana Intl Dominican Republic",
	},
	{
		key: "MAF",
		value: "Midland/Odessa, TX Midland Intl Air and Space Port United States",
	},
	{
		key: "MAO",
		value: "Manaus Edwardo Gomez Intl Brazil",
	},
	{
		key: "MAR",
		value: "Maracaibo La Chinita Intl Venzuela",
	},
	{
		key: "MBJ",
		value: "Montego Bay Sangster Intl Jamaica",
	},
	{
		key: "MBS",
		value: "Saginaw MBS Intl United States",
	},
	{
		key: "MCO",
		value: "Orlando Orlando International United States",
	},
	{
		key: "MCP",
		value: "Macapa Alberto Alcolumbre Intl Brazil",
	},
	{
		key: "MDE",
		value: "Medelin Jose Marie Cordova Colombia",
	},
	{
		key: "MDQ",
		value: "Mar del Plata Mar del Plata Argentina",
	},
	{
		key: "MDZ",
		value: "Mendoza EI Plumerilo Argentina",
	},
	{
		key: "MEX",
		value: "Mexico City Juarez International Mexico",
	},
	{
		key: "MGA",
		value: "Managua Augusto C Sandino Nicaragua",
	},
	{
		key: "MIA",
		value: "Miami Miami Intl United States",
	},
	{
		key: "MID",
		value: "Merida Manuel Crescencio Rejon Intl Mexico",
	},
	{
		key: "MTY",
		value: "Monterrey Gen Mariano Escobedo Mexico",
	},
	{
		key: "MVD",
		value: "Montevideo Carrasco Uruguay",
	},
	{
		key: "NAS",
		value: "Nassau Lynden Pindling Intl Bahamas",
	},
	{
		key: "NAT",
		value: "Natal Aluizio Alves Intl Brazil",
	},
	{
		key: "NOG",
		value: "Nogales Nogales Intl Mexico",
	},
	{
		key: "OAK",
		value: "Oakland Oakland Intl United States",
	},
	{
		key: "OKC",
		value: "Oklahoma City Will Rogers World United States",
	},
	{
		key: "ONT",
		value: "Ontario Ontario Intl United States",
	},
	{
		key: "OPF",
		value: "Miami Opa Locka United States",
	},
	{
		key: "ORG",
		value: "Paramaribo Zorg En Hoop Surinam",
	},
	{
		key: "PAP",
		value: "Port Au Prince Toussaint L'Ouverture Intl Haiti",
	},
	{
		key: "PBM",
		value: "Paramaribo Zanderij Intl Surinam",
	},
	{
		key: "PDX",
		value: "Portland Portland Intl United States",
	},
	{
		key: "PET",
		value: "Pelotas Joao Simoes Lopes Neto Intl Brazil",
	},
	{
		key: "PHX",
		value: "Phoenix Sky Harbour Intl United States",
	},
	{
		key: "PIU",
		value: "Piura Concha Iberico Intl Peru",
	},
	{
		key: "POA",
		value: "Porto Alegre Salgrado Filho Brazil",
	},
	{
		key: "POP",
		value: "Puerto Plata Gregorio Luperon Intl Dominican Republic",
	},
	{
		key: "POS",
		value: "Port of Spain Piarco Trinidad &Tobago",
	},
	{
		key: "PRA",
		value: "Parana Justo Jose de Urquiza Argentina",
	},
	{
		key: "PSE",
		value: "Ponce Mercedita Puerto Rico",
	},
	{
		key: "PTY",
		value: "Panama City Tocumen International Panama",
	},
	{
		key: "PUJ",
		value: "Punta Punta Cana Intl Dominican Republic",
	},
	{
		key: "PUQ",
		value: "Punta Arenas Carlos Ibanez del Campo Intl Chile",
	},
	{
		key: "PVR",
		value: "Puerto Vallarta Licenciado Gustavo Diaz Ordaz Intl Mexico",
	},
	{
		key: "PXM",
		value: "Puerto Puerto Escondido Intl Mexico",
	},
	{
		key: "REC",
		value: "Recife Guararapes Int Brazil",
	},
	{
		key: "RIO",
		value: "Rio de Janeiro Metropolitan Area Brazil",
	},
	{
		key: "RNO",
		value: "Reno Reno-Tahoe Intl United States",
	},
	{
		key: "ROS",
		value: "Rosario Rosario-Islas Malvinas Intl Argentina",
	},
	{
		key: "RSW",
		value: "Fort Southwest Florida Intl United States",
	},
	{
		key: "RTB",
		value: "Roatan Juan Manuel Galvez Intl Honduras",
	},
	{
		key: "SAL",
		value: "San Salvadore Oscar Arnulfo Romero Intl EI Salvador",
	},
	{
		key: "SAN",
		value: "San Diego Lindberg Field Intl United States",
	},
	{
		key: "SAO",
		value: "Sao Paulo Metropolitan Area Brazil",
	},
	{
		key: "SAP",
		value: "San Pedro Sula Ramon villeda Morales Honduras",
	},
	{
		key: "SAT",
		value: "San San Antonio Intl United States",
	},
	{
		key: "SCL",
		value: "Santiago Satiago International Chile",
	},
	{
		key: "SCU",
		value: "Santiago Antonio Maceo Airport Cuba",
	},
	{
		key: "SDQ",
		value: "Santo Domingo Las Americas Dominican Republic",
	},
	{
		key: "SEA",
		value: "Seattle Seattle Tacoma Intl United States",
	},
	{
		key: "SFD",
		value: "San Fernando De Apure Las Flecheras Venzuela",
	},
	{
		key: "SFN",
		value: "Sante Fe Sauce Viejo Airport Argentina",
	},
	{
		key: "SFO",
		value: "San Francisco San Francisco Intl United States",
	},
	{
		key: "SIG",
		value: "San Juan Isla Grande Puerto Rico",
	},
	{
		key: "SJC",
		value: "San Jose San Jose Intl United States",
	},
	{
		key: "SJD",
		value: "Los Los Cabos Intl Mexico",
	},
	{
		key: "SJO",
		value: "San Jose Juan Santamaria Intl Costa Rica",
	},
	{
		key: "SJS",
		value: "San Jose de Chiquitos San Jose de Chiquitos Bolivia",
	},
	{
		key: "SJU",
		value: "San Juan Luis Munoz Marin Intl Puerto Rico",
	},
	{
		key: "SKB",
		value: "St Kitts Robert L Bradshaw Intl St Kitts & Nevis",
	},
	{
		key: "SLA",
		value: "Salta Salta, Gen Belgrano Argentina",
	},
	{
		key: "SLC",
		value: "Salt Lake City Salt Lake City Intl United States",
	},
	{
		key: "SLP",
		value: "San Luis Potosi Ponciano Arriaga Intl Mexico",
	},
	{
		key: "SLU",
		value: "St Lucia George F L Charles St Lucia",
	},
	{
		key: "SLZ",
		value: "Sao Luiz Mal Cunha Machado Brazil",
	},
	{
		key: "SMF",
		value: "Sacramento Sacramento Intl United States",
	},
	{
		key: "SRZ",
		value: "Santa Cruz El Trompillo Bolivia",
	},
	{
		key: "SSA",
		value: "Salvador Luis Eduardo Magalhaes Brazil",
	},
	{
		key: "STT",
		value: "St Thomas Cyril E King Intl Virgin Islands (U.S.)",
	},
	{
		key: "SVD",
		value: "St Vincent E.T. Joshua Dominica",
	},
	{
		key: "TAB",
		value: "Scarborough A. N. R. Robinson Intl Trinidad and Tobago",
	},
	{
		key: "TAM",
		value: "Tampico Gen F Javier Mina Mexico",
	},
	{
		key: "TGU",
		value: "Tegucigalpa Toncontin Honduras",
	},
	{
		key: "THE",
		value: "Teresina Petronio Portella Brazil",
	},
	{
		key: "TIJ",
		value: "Tijuana Tijuana - Rodriguez Intl Mexico",
	},
	{
		key: "TPA",
		value: "Tampa Tampa Intl United States",
	},
	{
		key: "TUC",
		value: "Tucuman Benji Matienzo Argentina",
	},
	{
		key: "TUL",
		value: "Tulsa Tulsa Intl United States",
	},
	{
		key: "TUR",
		value: "Tucurui Tucurui Brazil",
	},
	{
		key: "TUS",
		value: "Tucson Tucson Intl United States",
	},
	{
		key: "UIO",
		value: "Quito Marscal Sucre Intl Ecuador",
	},
	{
		key: "USH",
		value: "Ushuaia Malvinas Argentinas Intl Argentina",
	},
	{
		key: "UVF",
		value: "St Lucia Hewannora St Lucia",
	},
	{
		key: "VCP",
		value: "Sao Paulo Viracopos/Campinas Brazil",
	},
	{
		key: "VER",
		value: "Veracruz Heriberto Jara Intl Mexico",
	},
	{
		key: "VNY",
		value: "Los Angeles Van Nuys United States",
	},
	{
		key: "VRA",
		value: "Varadero Juan Gualberto Gomez Cuba",
	},
	{
		key: "VSA",
		value: "Villahermosa Carlos Rovirosa Perez Intl Mexico",
	},
	{
		key: "YEG",
		value: "Edmonton Edmonton Intl Canada",
	},
	{
		key: "YLW",
		value: "Kelowna Kelowna Intl Canada",
	},
	{
		key: "YMM",
		value: "Fort McMurray Fort McMurray Intl Canada",
	},
	{
		key: "YQR",
		value: "Regina Regina Intl Canada",
	},
	{
		key: "YUM",
		value: "Yuma Yuma Intl United States",
	},
	{
		key: "YVR",
		value: "Vancouver Vancouver Intl Canada",
	},
	{
		key: "YWG",
		value: "Winnipeg James Armstrong Richardson Canada",
	},
	{
		key: "YXE",
		value: "Saskatoon John G Diefenbaker Intl Canada",
	},
	{
		key: "YXY",
		value: "Whitehorse Erik Nielsen Whitehorse Intl Canada",
	},
	{
		key: "YYJ",
		value: "Victoria Victoria Intl Canada",
	},
	{
		key: "ZCL",
		value: "Zacatecas Leobardo C Ruiz Intl Mexico",
	},
	{
		key: "ZIH",
		value: "Ixtapa Ixtapa-Zihuatanejo Intl Mexico",
	},
	{
		key: "ZLO",
		value: "Manzanillo Playa de Oro Intl Mexico",
	},
	{
		key: "ZSA",
		value: "San Salvador Island San Salvador Intl Bahamas",
	},
	{
		key: "ANC",
		value: "Anchorage Ted Stevens Achorage Intl United States",
	},
	{
		key: "ENT",
		value: "Enewetak Island Enewetak Auxiliary Airfield US minor islands",
	},
	{
		key: "FAI",
		value: "Fairbanks Fairbanks Intl United States",
	},
	{
		key: "HNL",
		value: "Honolulu Honolulu Intl United States",
	},
	{
		key: "ITO",
		value: "Hilo Hilo Intl United States",
	},
	{
		key: "JNU",
		value: "Juneau Juneau Intl United States",
	},
	{
		key: "KOA",
		value: "Kona, HI Kona Intl United States",
	},
	{
		key: "KTN",
		value: "Ketchikan, AK Ketchikan Intl United States",
	},
	{
		key: "AAC",
		value: "El Arish El Arish Intl Egypt",
	},
	{
		key: "AAE",
		value: "Annaba Rabah Bitat/Les Salines Algeria",
	},
	{
		key: "AGA",
		value: "Agadir Al Massira Airport Morocco",
	},
	{
		key: "AHU",
		value: "Al Hoceima Cherif Al Idrissi Airport Morocco",
	},
	{
		key: "ALG",
		value: "Algiers Houari Bourneadiene Algeria",
	},
	{
		key: "ALY",
		value: "Alexandria El Nouzha Egypt",
	},
	{
		key: "ASW",
		value: "Aswan Aswan International Egypt",
	},
	{
		key: "ATZ",
		value: "Assiut Assiut Airport Egypt",
	},
	{
		key: "BEN",
		value: "Benghazi Benina Int Libya",
	},
	{
		key: "CAI",
		value: "Cairo Cairo International Egypt",
	},
	{
		key: "CAS",
		value: "Casablanca Anfa Morocco",
	},
	{
		key: "CMN",
		value: "Casablanca Mohamed V Morocco",
	},
	{
		key: "CZL",
		value: "Constantine Mohamed Boudiaf Intl Algeria",
	},
	{
		key: "DJE",
		value: "Djerba Djerba-Zarzis Intl Tunisia",
	},
	{
		key: "FEZ",
		value: "Fes Fes-Saiss Morocco",
	},
	{
		key: "HRG",
		value: "Hurghada Hurghada International Egypt",
	},
	{
		key: "LXR",
		value: "Luxor Luxor Intl Egypt",
	},
	{
		key: "MIR",
		value: "Monastir Monastir Habib Bourguiba Intl Tunisia",
	},
	{
		key: "MUH",
		value: "Marsa Marsa Matruh Intl Egypt",
	},
	{
		key: "ORN",
		value: "Oran Oran (Ouahran) Algeria",
	},
	{
		key: "OUD",
		value: "Oujda Oujda Morocco",
	},
	{
		key: "OZZ",
		value: "Ouarzazate Ouarzazate Morocco",
	},
	{
		key: "RAK",
		value: "Marrakech Marrakech - Menara Morocco",
	},
	{
		key: "RBA",
		value: "Rabat Sale Morocco",
	},
	{
		key: "RMF",
		value: "Marsa Marsa Alam Intl Egypt",
	},
	{
		key: "SEB",
		value: "Sehba Sehba Libya",
	},
	{
		key: "SFA",
		value: "Sfax Sfax-Thyna Intl Tunisia",
	},
	{
		key: "SSH",
		value: "Sharm Sharm El Sheikh Egypt",
	},
	{
		key: "TIP",
		value: "Tripoli Tripoli Intl Libya",
	},
	{
		key: "TNG",
		value: "Tangier Tangier Ibn Battouta Morocco",
	},
	{
		key: "TUN",
		value: "Tunis Carthage Tunisia",
	},
	{
		key: "ABJ",
		value: "Abidjan Felix Houphouet Boigny Ivory Coast",
	},
	{
		key: "ABV",
		value: "Abuja Abuja - Nnamdi Azikiwe International Nigeria",
	},
	{
		key: "ACC",
		value: "Accra Kotoka Ghana",
	},
	{
		key: "ADD",
		value: "Addis Ababa Bole Ethiopia",
	},
	{
		key: "AJY",
		value: "Agadez Mano Dayak Intl Niger",
	},
	{
		key: "ASM",
		value: "Asmara Asmara International Ethiopia",
	},
	{
		key: "BEI",
		value: "Beica Beica Ethiopia",
	},
	{
		key: "BGF",
		value: "Bangui Bangui M'Poko International Airport Central African Republic",
	},
	{
		key: "BJL",
		value: "Banjul Yundum International Gambia",
	},
	{
		key: "BKO",
		value: "Bamako Bamako-Senou Intl Mali",
	},
	{
		key: "BSN",
		value: "Bossangoa Bossangoa Central African Rep",
	},
	{
		key: "CKY",
		value: "Conakry Conakry Intl Guinea",
	},
	{
		key: "COO",
		value: "Cotonou Cadjehoun Benin",
	},
	{
		key: "DKR",
		value: "Dakar Leopold Sedar Senghor Intl Senegal",
	},
	{
		key: "DLA",
		value: "Douala Douala Intl Cameroon",
	},
	{
		key: "FNA",
		value: "Freetown Lungi International Sierra leone",
	},
	{
		key: "GNN",
		value: "Ghinnir (Ginir) Ghinnir (Ginir) Intl Ethiopia",
	},
	{
		key: "GOU",
		value: "Garoua Garoua Intl Cameroon",
	},
	{
		key: "JIB",
		value: "Djibouti Ambouli Djibouti",
	},
	{
		key: "JIJ",
		value: "Jijiga Wilwal Intl Ethiopia",
	},
	{
		key: "JOS",
		value: "Jos Yakubu Gowon Airport Nigeria",
	},
	{
		key: "JUB",
		value: "Juba Juba Intl South Sudan",
	},
	{
		key: "KAN",
		value: "Kano Aminu Kano Int Apt Nigeria",
	},
	{
		key: "KMS",
		value: "Kumasi Kumasi Ghana",
	},
	{
		key: "KOL",
		value: "Koumala Koumala Central African Rep",
	},
	{
		key: "KRT",
		value: "Khartoum Khartoum Intl Sudan",
	},
	{
		key: "LEK",
		value: "Labe Labe Guinea",
	},
	{
		key: "LFW",
		value: "Lome Lome-Tokoin Togo",
	},
	{
		key: "LOS",
		value: "Lagos Murtala Muhammed Nigeria",
	},
	{
		key: "MCA",
		value: "Macenta Macenta Guinea",
	},
	{
		key: "MLW",
		value: "Monrovia Spriggs Payne Airport Liberia",
	},
	{
		key: "MVR",
		value: "Maroua Maroua Salak Cameroon",
	},
	{
		key: "NDB",
		value: "Nouadhibou Nouadhibou Intl Mauritania",
	},
	{
		key: "NDJ",
		value: "Ndjamena Ndjamena Intl Chad",
	},
	{
		key: "NIM",
		value: "Niamey Diori Hamani Intl Nigeria",
	},
	{
		key: "NKC",
		value: "Nouakchott Nouakchott-Oumtounsy Intl Mauritania",
	},
	{
		key: "OUA",
		value: "Ouagadougou Ouagadougou Burkina Faso",
	},
	{
		key: "PHC",
		value: "Port Harcourt Port Harcourt Intl Nigeria",
	},
	{
		key: "QRW",
		value: "Warri Warri Nigeria",
	},
	{
		key: "RAI",
		value: "Praia Nelson Mandela Intl Cape Verde",
	},
	{
		key: "ROB",
		value: "Monrovia Roberts Intl Liberia",
	},
	{
		key: "SID",
		value: "Sal Amilcar Cabral Intl Cape Verde",
	},
	{
		key: "VIL",
		value: "Dakhla Dakhla Mauritania",
	},
	{
		key: "YAO",
		value: "Yaounde Yaounde Cameroon",
	},
	{
		key: "ZAR",
		value: "Zaria Zaria Nigeria",
	},
	{
		key: "BAN",
		value: "Basongo Basongo Congo DR",
	},
	{
		key: "BGP",
		value: "Bongo Bongo Gabon",
	},
	{
		key: "BJM",
		value: "Bujumbura Bujumbura Int+l Burundi",
	},
	{
		key: "BZV",
		value: "Brazzaville Maya Maya Congo (Rep)",
	},
	{
		key: "DAR",
		value: "Dar Es Salaam Julius Nyerere Intl Tanzania",
	},
	{
		key: "EBB",
		value: "Entebbe Entebbe Intl Uganda",
	},
	{
		key: "FIH",
		value: "Kinshasa N'Djili Congo DR",
	},
	{
		key: "HCM",
		value: "Eyl Eyl Somalia",
	},
	{
		key: "HOA",
		value: "Hola Hola Kenya",
	},
	{
		key: "JRO",
		value: "Kilimanjaro Kilimanjaro Intl Tanzania",
	},
	{
		key: "KEE",
		value: "Kelle Kelle Congo (Rep)",
	},
	{
		key: "KGL",
		value: "Kigali Kigali Intl Rwanada",
	},
	{
		key: "KIS",
		value: "Kisumu Kisumu Kenya",
	},
	{
		key: "LBV",
		value: "Libreville Libreville Intl Gabon",
	},
	{
		key: "MBA",
		value: "Mombasa Moi Intl Kenya",
	},
	{
		key: "NBO",
		value: "Nairobi Jomo Kenyatta Kenya",
	},
	{
		key: "PNR",
		value: "Pointe Noire Pointe Noire Congo (Rep)",
	},
	{
		key: "POG",
		value: "Port Gentil Port Gentil Intl Gabon",
	},
	{
		key: "PRI",
		value: "Praslin Island Praslin Island Seychelles",
	},
	{
		key: "SEZ",
		value: "Mahe Island Seychelles Intl Seychelles",
	},
	{
		key: "SSG",
		value: "Malabo Santa Isabel Equitorial Guinea",
	},
	{
		key: "TMS",
		value: "Sao Tome Sao Tome Intl Sao Tome & Principe",
	},
	{
		key: "WIL",
		value: "Nairobi Wilson Aiport Kenya",
	},
	{
		key: "YAN",
		value: "Yangambi Yangambi Congo DR",
	},
	{
		key: "FBM",
		value: "Lumbumbashi Lumbumbashi Intl Congo (DRC)",
	},
	{
		key: "FKI",
		value: "Kisangani Banoka Intl Congo (DRC)",
	},
	{
		key: "MGQ",
		value: "Mogadishu Aden Adde International Somalia",
	},
	{
		key: "BFN",
		value: "Bloemfontein Bram Fischer Intl South Africa",
	},
	{
		key: "BLZ",
		value: "Blantyre Chileka Malawi",
	},
	{
		key: "BUQ",
		value: "Bulawayo Joshua Mqabuko Nkomo Intl Zimbabwe",
	},
	{
		key: "CAB",
		value: "Cabinda Cabinda Angola",
	},
	{
		key: "CPT",
		value: "Cape Town Cape Town Intl South Africa",
	},
	{
		key: "DUR",
		value: "Durban King Shaka Intl South Africa",
	},
	{
		key: "DZA",
		value: "Dzaoudzi Dzaoudzi-Pamandzi Intl Mayotte",
	},
	{
		key: "ELS",
		value: "East London East London South Africa",
	},
	{
		key: "GBE",
		value: "Gabarone Sir Seretse Khama Int Botswana",
	},
	{
		key: "HAH",
		value: "Moroni - Prince Said Ibrahim Prince Said Ibrahim Intl Comoros (Comores)",
	},
	{
		key: "HLA",
		value: "Lanseria Lanseria Intl South Africa",
	},
	{
		key: "HRE",
		value: "Harare Harare Intl Zimbabwe",
	},
	{
		key: "HVA",
		value: "Analalava Analalava Madagascar",
	},
	{
		key: "JNB",
		value: "Johannesburg O R Tambo Intl South Africa",
	},
	{
		key: "KIM",
		value: "Kimberly Kimberly South Africa",
	},
	{
		key: "KIW",
		value: "Kitwe Southdowns Zambia",
	},
	{
		key: "LAD",
		value: "Luanda Quatro de Fevereiro Angola",
	},
	{
		key: "LLW",
		value: "Lilongwe Lilongwe Intl Malawi",
	},
	{
		key: "LUN",
		value: "Lusaka Kenneth Kaunda Intl Zambia",
	},
	{
		key: "MAI",
		value: "Mangochi Mangochi Malawi",
	},
	{
		key: "MPM",
		value: "Maputo Maputo - Maputo International Mozambique",
	},
	{
		key: "MRU",
		value: "Mauritius S.Seewoosagur Ram lnt Mauritius",
	},
	{
		key: "MUB",
		value: "Maun Maun Botswana",
	},
	{
		key: "NLA",
		value: "Ndola Simon Mwansa Kapwepwe Intl Zambia",
	},
	{
		key: "PLZ",
		value: "Port Elizabeth Port Elizabeth South Africa",
	},
	{
		key: "POL",
		value: "Pemba Pemba Mozambique",
	},
	{
		key: "PRY",
		value: "Pretoria Wonderboom Apt South Africa",
	},
	{
		key: "PTG",
		value: "Polokwane Polokwane Intl South Africa",
	},
	{
		key: "TKO",
		value: "Tlokoeng Tlokoeng Lesotho",
	},
	{
		key: "TNR",
		value: "Antananarivo Ivato Intl Madagascar",
	},
	{
		key: "WDH",
		value: "Windhoek Hosea Kutako Intl Namibia",
	},
	{
		key: "WEL",
		value: "Welkom Welkom South Africa",
	},
	{
		key: "WVB",
		value: "Walvis Walvis Bay Intl South Africa",
	},
	{
		key: "ABD",
		value: "Abadan Abadan Intl Iran",
	},
	{
		key: "ADJ",
		value: "Amman Amman Civil Airport (Marka International) Jordan",
	},
	{
		key: "ALP",
		value: "Aleppo Aleppo International Syria",
	},
	{
		key: "AMM",
		value: "Amman Queen Alia Intl Jordan",
	},
	{
		key: "AQJ",
		value: "Aqaba King Hussein International Airport Jordan",
	},
	{
		key: "BAK",
		value: "Baku Heydar Aliyev Intl Azerbaijan",
	},
	{
		key: "BEY",
		value: "Beirut Beirut-Rafic Hariri Intl Lebanon",
	},
	{
		key: "BGW",
		value: "Baghdad Baghdad International Iraq",
	},
	{
		key: "BSR",
		value: "Basra, Basrah Basra Intl Iraq",
	},
	{
		key: "DAM",
		value: "Damascus Damascus Intl Syria",
	},
	{
		key: "EBL",
		value: "Erbil Erbil International Airport Iraq",
	},
	{
		key: "ETH",
		value: "Elat J. Hozman Israel",
	},
	{
		key: "EVN",
		value: "Eriwan (Yerevan, Jerevan) Zvartnots Intl Armenia",
	},
	{
		key: "GYD",
		value: "Baku Heydar Aliyev Intl Azerbaijan",
	},
	{
		key: "GZA",
		value: "Gaza City Yasser Arafat Intl Palestinian Territory",
	},
	{
		key: "HFA",
		value: "Haifa Haifa Israel",
	},
	{
		key: "JRS",
		value: "Jerusalem Atarot Israel",
	},
	{
		key: "KWI",
		value: "Kuwait Kuwait International Kuwait",
	},
	{
		key: "MHD",
		value: "Mashad Mashhad Intl Iran",
	},
	{
		key: "SDA",
		value: "Bagdad Bagdad - International Iraq",
	},
	{
		key: "SDV",
		value: "Tel Aviv Yafo Sde Dov Israel",
	},
	{
		key: "SYZ",
		value: "Shiraz Shiraz Intl Iran",
	},
	{
		key: "TBS",
		value: "Tbilisi Novo Alexeyevka Georgia",
	},
	{
		key: "THR",
		value: "Tehran Mehrabad Iran",
	},
	{
		key: "TLV",
		value: "Tel Aviv Yafo Ben Gurion intl Israel",
	},
	{
		key: "VDA",
		value: "Eilat Ovda Israel",
	},
	{
		key: "AAN",
		value: "Al Ain Intl Al Ain Intl United Arab Emirates",
	},
	{
		key: "ADE",
		value: "Aden Aden International Yemen",
	},
	{
		key: "AHB",
		value: "Abha Abha Regional Saudi Arabia",
	},
	{
		key: "AUA",
		value: "Aruba Reina Beatrix Aruba",
	},
	{
		key: "AUH",
		value: "Abu Dhabi Abu Dhabi Intl Ajnam/Fujairah etc.",
	},
	{
		key: "BAH",
		value: "Bahrain Bahrain International Bahrain",
	},
	{
		key: "DHA",
		value: "Dhahran King Abdulaziz Saudi Arabia",
	},
	{
		key: "DMM",
		value: "Dammam King Fahd Intl Saudi Arabia",
	},
	{
		key: "DOH",
		value: "Doha Hamad Intl Qatar",
	},
	{
		key: "DWC",
		value: "Jebel Ali Al Maktoum UAE",
	},
	{
		key: "DXB",
		value: "Dubai Dubai Intl Dubai",
	},
	{
		key: "FJR",
		value: "Fujairah Fujairah International United Arab Emirates",
	},
	{
		key: "JED",
		value: "Jeddah King Abdulaziz Int Saudi Arabia",
	},
	{
		key: "KAM",
		value: "Kamaran Kamaran Yemen",
	},
	{
		key: "MCT",
		value: "Muscat Seeb Oman",
	},
	{
		key: "MED",
		value: "Madinah, Mohammad Bin Abdulaziz Mohammad Bin Abdulaziz [Medina Airport] Saudi Arabia",
	},
	{
		key: "RIY",
		value: "Riyan Mukalla Riyan Yemen",
	},
	{
		key: "RKT",
		value: "Ras Al Khaimah Ras al Khaymah (Ras al Khaimah) Intl United Arab Emirates",
	},
	{
		key: "RUH",
		value: "Riyadh King Khalid Intl Saudi Arabia",
	},
	{
		key: "SAH",
		value: "Sana'a Sana'a International Yemen",
	},
	{
		key: "SHJ",
		value: "Sharjah Sharjah Intl Ajnam/Fujairah etc.",
	},
	{
		key: "SLL",
		value: "Salalah Salalah Intl Oman",
	},
	{
		key: "TAI",
		value: "Taiz Taiz Intl Yemen",
	},
	{
		key: "TIF",
		value: "Taif Taif Regional Saudi Arabia",
	},
	{
		key: "TUU",
		value: "Tabuk Tabuk Regional Saudi Arabia",
	},
	{
		key: "YNB",
		value: "Yanbu Prince Abdul Mohsin bin Abdulaziz Saudi Arabia",
	},
	{
		key: "AMD",
		value: "Ahmedabada Sardar Vallabhbhai Patel Intl India",
	},
	{
		key: "ARH",
		value: "Archangelisk Talagi Pakistan",
	},
	{
		key: "ATQ",
		value: "Amritsar, Punjab Sri Guru Ram Dass Jee International Airport India",
	},
	{
		key: "BBI",
		value: "Bhubaneswar, Odisha Biju Patnaik International Airport India",
	},
	{
		key: "BGL",
		value: "Baglung Balewa Nepal",
	},
	{
		key: "BLR",
		value: "Bangalore Kempegowda Intl India",
	},
	{
		key: "BOM",
		value: "Mumbai Chhatrapati Shivaji Maharaj Intl India",
	},
	{
		key: "BST",
		value: "Bost Bost Afghanistan",
	},
	{
		key: "CCJ",
		value: "Calicut Calicut Intl India",
	},
	{
		key: "CCU",
		value: "Calcutta Netaji Subhas Chandra India",
	},
	{
		key: "CGP",
		value: "Chittagong Shah Amanat Intl Bangladesh",
	},
	{
		key: "CHL",
		value: "Challis Challis Bangladesh",
	},
	{
		key: "CJB",
		value: "Coimbatore Coimbatore Intl India",
	},
	{
		key: "COK",
		value: "Cochin Cochin Intl India",
	},
	{
		key: "DAC",
		value: "Dhaka Hazrat Shahjalal Intl Bangladesh",
	},
	{
		key: "DEL",
		value: "Delhi Indira Gandhi Intl India",
	},
	{
		key: "GAU",
		value: "Guwahati Lokpriya Gopinath Bordoloi Intl India",
	},
	{
		key: "GOI",
		value: "Goa Goa Intl India",
	},
	{
		key: "GWD",
		value: "Gwadar Gwadar Intl Pakistan",
	},
	{
		key: "HYD",
		value: "Hyderabad Rajiv Gandhi Intl India",
	},
	{
		key: "ISB",
		value: "Islamabad Benazir Bhutto Intl Pakistan",
	},
	{
		key: "IXC",
		value: "Chandigarh Chandigarh Intl India",
	},
	{
		key: "JAA",
		value: "Jalalabad Jalalabad Afghanistan",
	},
	{
		key: "JAI",
		value: "Jaipur Jaipur Intl India",
	},
	{
		key: "KBL",
		value: "Kabul Khwaja Rawash Afghanistan",
	},
	{
		key: "KHI",
		value: "Karachi Jinnah Intl Pakistan",
	},
	{
		key: "KTM",
		value: "Kathmandu Tribhuvan Nepal",
	},
	{
		key: "LHE",
		value: "Lahore Allama Iqbal Intl Pakistan",
	},
	{
		key: "LKO",
		value: "Lucknow Chaudhary Charan Singh Intl India",
	},
	{
		key: "LYP",
		value: "Faisalabad Faisalabad Intl Pakistan",
	},
	{
		key: "MAA",
		value: "Chennai Chennai India",
	},
	{
		key: "MUX",
		value: "Multan Multan Intl Pakistan",
	},
	{
		key: "NAG",
		value: "Nagpour Dr Babasaheb Ambedkar Intl India",
	},
	{
		key: "PBH",
		value: "Paro Paro Bhutan",
	},
	{
		key: "PEW",
		value: "Peshawar Bacha Khan Intl Pakistan",
	},
	{
		key: "PKR",
		value: "Pokhara Pokhara Nepal",
	},
	{
		key: "RYK",
		value: "Rahim Shaikh Zayed Intl Pakistan",
	},
	{
		key: "SKT",
		value: "Sialkot Sialkot Pakistan",
	},
	{
		key: "SKZ",
		value: "Sukkur Sukkur Pakistan",
	},
	{
		key: "TRV",
		value: "Trivandrum Trivandrum Intl India",
	},
	{
		key: "TRZ",
		value: "Tiruchirapalli Tiruchirapalli Intl India",
	},
	{
		key: "VNS",
		value: "Varanasi Lal Bahadur Shastri India",
	},
	{
		key: "ZYL",
		value: "Sylhet Osmani Intl Bangladesh",
	},
	{
		key: "ALA",
		value: "Almaty Almaty Intl Kazakhstan",
	},
	{
		key: "ASB",
		value: "Ashgabat Ashkhabat - Saparmurat Turkmenbashy Intl Turkmenistan",
	},
	{
		key: "DYU",
		value: "Dushanbe (Duschanbe) Dushanbe Intl Tajikistan",
	},
	{
		key: "FRU",
		value: "Bishkek Manas Intl Kyrgyzstan",
	},
	{
		key: "OVB",
		value: "Novosibirsk Novosibirsk - Tolmachevo Russia",
	},
	{
		key: "SKD",
		value: "Samarkand Samarkand Intl Uzbekistan",
	},
	{
		key: "TAS",
		value: "Tashkent Tashkent Intl Uzbekistan",
	},
	{
		key: "TSE",
		value: "Astana Astana International Kazakhstan",
	},
	{
		key: "UGC",
		value: "Urgench Urgench Intl Uzbekistan",
	},
	{
		key: "ABU",
		value: "Atambua Haliwen Indonesia",
	},
	{
		key: "AKA",
		value: "Ankang Ankang Wulipu China",
	},
	{
		key: "BDO",
		value: "Bandung Husein International Indonesia",
	},
	{
		key: "BJS",
		value: "Beijing Metropolitan Area China",
	},
	{
		key: "BKI",
		value: "Kota Kinabalu Kota Kinabalu Intl Malaysia",
	},
	{
		key: "BKK",
		value: "Bangkok Suvarnabhumi Thailand",
	},
	{
		key: "BWN",
		value: "Bandar Seri Begawan Brunei International Brunei",
	},
	{
		key: "CAN",
		value: "Guangzhou Guangzhou Baiyun Intl China",
	},
	{
		key: "CEB",
		value: "Cebu Mactan-Cebu Intl Philippines",
	},
	{
		key: "CGK",
		value: "Jakarta Soekarno-Hatta Intl Indonesia",
	},
	{
		key: "CGO",
		value: "Zhengzhou Zhengzhou Xinzheng Intl China",
	},
	{
		key: "CGQ",
		value: "Changchun Changchun Longjia Intl China",
	},
	{
		key: "CKG",
		value: "Chongqing Chongqing Jiangbei Intl China",
	},
	{
		key: "CMB",
		value: "Colombo Bandaranaike Sri Lanka",
	},
	{
		key: "CNX",
		value: "Chaing Mai Chaing Mai Intl Thailand",
	},
	{
		key: "CRK",
		value: "Kapampangan Clark Intl Philippines",
	},
	{
		key: "CTU",
		value: "Chengdu Chengdu Shuangliu Intl China",
	},
	{
		key: "CZX",
		value: "Changzhou Changzhou Benniu China",
	},
	{
		key: "DAD",
		value: "Da Nang Da Nang Intl Viet-Nam",
	},
	{
		key: "DLC",
		value: "Dalian Dalian Zhoushuizi Intl China",
	},
	{
		key: "DPS",
		value: "Denpasar Bali Ngurah Rai Indonesia",
	},
	{
		key: "FOC",
		value: "Fuzhou Fuzhou Changle Intl China",
	},
	{
		key: "HAN",
		value: "Hanoi Noibai Viet-Nam",
	},
	{
		key: "HDY",
		value: "Hat Yai Hatyai (Hat Yai) Intl Thailand",
	},
	{
		key: "HGH",
		value: "Hangzhou Hangzhou Xiaoshan Intl China",
	},
	{
		key: "HKG",
		value: "Hong Kong Hong Kong Intl Hong Kong",
	},
	{
		key: "HKT",
		value: "Phuket Phuket Intl Thailand",
	},
	{
		key: "HLP",
		value: "Jakarta Halim Perdanakusuma Intl Indonesia",
	},
	{
		key: "HRB",
		value: "Harbin (Haerbin) Harbin Taiping Intl PR China",
	},
	{
		key: "HUI",
		value: "Hue - Phu Bai Phu Bai Intl Viet Nam",
	},
	{
		key: "IKT",
		value: "Irkutsk Intl Airport Irkutsk Russia",
	},
	{
		key: "INC",
		value: "Yinchuan Yinchuan Hedong Intl China",
	},
	{
		key: "JHB",
		value: "Johor Bahru Senai Intl Malaysia",
	},
	{
		key: "JHG",
		value: "Jinghong Jinghong - Gasa Airport China",
	},
	{
		key: "JJN",
		value: "Jinjiang Quanzhou Jinjiang Intl China",
	},
	{
		key: "JKT",
		value: "Jakarta Metropolitan Area Indonesia",
	},
	{
		key: "JMU",
		value: "Jiamusi - Jiamusi Airport Jiamusi Dongjiao Intl China",
	},
	{
		key: "KCH",
		value: "Kuching Kuching Intl Malaysia",
	},
	{
		key: "KHH",
		value: "Kaohsiung Kaohsiung Intl Taiwan",
	},
	{
		key: "KMG",
		value: "Kuming Kunming Changshui Intl China",
	},
	{
		key: "KUL",
		value: "Kuala Lumpur Kuala Lumpur Intl Malaysia",
	},
	{
		key: "KWL",
		value: "Guilin Guilin Liangjiang Intl China",
	},
	{
		key: "LBU",
		value: "Labuan Labuan Malaysia",
	},
	{
		key: "LGK",
		value: "Langkawi Langkawi Intl Malaysia",
	},
	{
		key: "MDC",
		value: "Manado Sam Ratulangi Intl Indonesia",
	},
	{
		key: "MDL",
		value: "Mandalay-Annisaton Mandalay - Annisaton Myanmar",
	},
	{
		key: "MFM",
		value: "Macau Macau International Macau",
	},
	{
		key: "MLE",
		value: "Male Velana Intl Maldives",
	},
	{
		key: "MNL",
		value: "Manila Nino Aquino Intl Philippines",
	},
	{
		key: "MUR",
		value: "Marudi Marudi Malaysia",
	},
	{
		key: "MYY",
		value: "Miri Miri Malaysia",
	},
	{
		key: "NGB",
		value: "Ningbo Ningbo Lishe International Airport China",
	},
	{
		key: "NKG",
		value: "Nanking/Nanjing Nanjing Lukou Intl China",
	},
	{
		key: "PDG",
		value: "Padang Minangkabau Intl Indonesia",
	},
	{
		key: "PEK",
		value: "Beijing Beijing Capital intl China",
	},
	{
		key: "PEN",
		value: "Penang Penang International Malaysia",
	},
	{
		key: "PNH",
		value: "Phnom Penh Phnom Penh Intl Cambodia",
	},
	{
		key: "PVG",
		value: "Shanghai Shanghai Pudong Intl China",
	},
	{
		key: "RGN",
		value: "Yangon Mingaladon Burma",
	},
	{
		key: "SGN",
		value: "Ho Chi Minh City Tan Son Nhat Intl Viet-Nam",
	},
	{
		key: "SHA",
		value: "Shanghai Honggiao China",
	},
	{
		key: "SHE",
		value: "Sheyang Shenyang Taoxian Intl Indonesia",
	},
	{
		key: "SIA",
		value: "Xi'an Xiguan China",
	},
	{
		key: "SIN",
		value: "Singapore Changi Singapore",
	},
	{
		key: "SOC",
		value: "Solo City Adi Surmarmo Indonesia",
	},
	{
		key: "SUB",
		value: "Surabaya Juanda Indonesia",
	},
	{
		key: "SZB",
		value: "Kuala Lumpur Sultan Abdul Aziz Shah Malaysia",
	},
	{
		key: "SZX",
		value: "Shenzhen Shenzhen Bao'an China",
	},
	{
		key: "TAO",
		value: "Quingdao Quingdao Liuting Intl China",
	},
	{
		key: "TEL",
		value: "Telupid Telupid Malaysia",
	},
	{
		key: "TNA",
		value: "Jinan Jinan Yaoqiang Intl China",
	},
	{
		key: "TPE",
		value: "Taipei Taiwan Taoyuan Intl Taiwan",
	},
	{
		key: "TSN",
		value: "Tianjin Tianjin Binhai Intl China",
	},
	{
		key: "TWU",
		value: "Tawau Tawau Malaysia",
	},
	{
		key: "TYN",
		value: "Taiyuan Taiyuan Wusu Intl China",
	},
	{
		key: "ULN",
		value: "Ulaangom Chinggis Khaan Intl Mongolia",
	},
	{
		key: "UPG",
		value: "Unjung Pandang Hasanudin Indonesia",
	},
	{
		key: "URC",
		value: "Urumqi Urumqi Diwopu Intl China",
	},
	{
		key: "UTP",
		value: "Utapao Utapao (Pattaya) Intl Thailand",
	},
	{
		key: "WAR",
		value: "Waris Waris Indonesia",
	},
	{
		key: "WUH",
		value: "Wuhan Wuhan Tianhe Intl China",
	},
	{
		key: "XIY",
		value: "Xi'an Xi'an - Xianyang Intl China",
	},
	{
		key: "XMN",
		value: "Xiamen Xiamen Gaoqi Intl China",
	},
	{
		key: "ZRM",
		value: "Sarmi Sarmi Orai Indonesia",
	},
	{
		key: "AOJ",
		value: "Aomori, Honshu Aomori Airport Japan",
	},
	{
		key: "CJU",
		value: "Jeju Jeju Intl Airport South Korea",
	},
	{
		key: "CTS",
		value: "Chitose New Chitose Japan",
	},
	{
		key: "FKS",
		value: "Fukushima Fukushima Airport Japan",
	},
	{
		key: "FNJ",
		value: "Pyongyang Sunan North Korea",
	},
	{
		key: "FUK",
		value: "Fukuoka Fukuoka Japan",
	},
	{
		key: "HIJ",
		value: "Hiroshima Hiroshima Japan",
	},
	{
		key: "HKD",
		value: "Hakodate Hakodate Japan",
	},
	{
		key: "HND",
		value: "Tokyo Haneda Japan",
	},
	{
		key: "ICN",
		value: "Seoul Incheon Intl South Korea",
	},
	{
		key: "ITM",
		value: "Osaka Itami Japan",
	},
	{
		key: "KHV",
		value: "Khabarovsk Novyy Russia",
	},
	{
		key: "KIJ",
		value: "Niigata Niigata Japan",
	},
	{
		key: "KIX",
		value: "Osaka Kansai Intl Japan",
	},
	{
		key: "KMI",
		value: "Miyazaki Miyazaki Japan",
	},
	{
		key: "KMJ",
		value: "Kumamoto Kumamoto Japan",
	},
	{
		key: "KMQ",
		value: "Komatsu Komatsu Japan",
	},
	{
		key: "KOJ",
		value: "Kagoshima Kagoshima Japan",
	},
	{
		key: "KUH",
		value: "Kushiro Kushiro Japan",
	},
	{
		key: "MUS",
		value: "Marcus Island Minami Torishima Japan",
	},
	{
		key: "NGO",
		value: "Nagoya Chubu Centrair Intl Japan",
	},
	{
		key: "NRT",
		value: "Tokyo Narita Japan",
	},
	{
		key: "OIT",
		value: "Oita Oita Japan",
	},
	{
		key: "OKA",
		value: "Okinawa Naha Japan",
	},
	{
		key: "OSA",
		value: "Osaka Metropolitan Area Japan",
	},
	{
		key: "PUS",
		value: "Pusan Gimhae Intl South Korea",
	},
	{
		key: "SDJ",
		value: "Sendai Sendai Japan",
	},
	{
		key: "SEL",
		value: "Seoul Kimpo Intl South Korea",
	},
	{
		key: "SPK",
		value: "Sapporo Metropolitan Area Japan",
	},
	{
		key: "TAK",
		value: "Takamatsu Takamatsu Japan",
	},
	{
		key: "TYO",
		value: "Tokyo Metropolitan Area Japan",
	},
	{
		key: "UKB",
		value: "Kobe Kobe Japan",
	},
	{
		key: "ADL",
		value: "Adelaide Adelaide Australia",
	},
	{
		key: "AKL",
		value: "Auckland Auckland Intl New Zealand",
	},
	{
		key: "APW",
		value: "Apia, Upolu Island Faleolo Intl Airport Samoa",
	},
	{
		key: "ASP",
		value: "Alice Springs, Northern Territory Alice Springs Airport Australia",
	},
	{
		key: "BLT",
		value: "Blackwater Blackwater Australia",
	},
	{
		key: "BME",
		value: "Broome Broome Intl Australia",
	},
	{
		key: "BNE",
		value: "Brisbane Brisbane Australia",
	},
	{
		key: "BON",
		value: "Bonaire Flamingo International Netherlands Antilles",
	},
	{
		key: "BUC",
		value: "Burketown Burketown Australia",
	},
	{
		key: "CBR",
		value: "Canberra Canberra Australia",
	},
	{
		key: "CHC",
		value: "Christchurch Christchurch Intl New Zealand",
	},
	{
		key: "CNS",
		value: "Cairns Cairns Australia",
	},
	{
		key: "CXI",
		value: "Christmas Line Cassidy Intl Kiribati",
	},
	{
		key: "DRW",
		value: "Darwin Darwin Intl Australia",
	},
	{
		key: "DUD",
		value: "Dunedin Dunedin Intl New Zealand",
	},
	{
		key: "GBP",
		value: "Gamboola Gamboola Australia",
	},
	{
		key: "GUM",
		value: "Guam AB Won Pat Intl US minor islands",
	},
	{
		key: "HBA",
		value: "Hobart Hobart Intl Australia",
	},
	{
		key: "HIR",
		value: "Honiara Honiara Solomon Islands",
	},
	{
		key: "HKK",
		value: "Hokitka Hokitka New Zealand",
	},
	{
		key: "HLZ",
		value: "Hamilton Hamilton New Zealand",
	},
	{
		key: "KGC",
		value: "Kingscote Kingscote Australia",
	},
	{
		key: "KIE",
		value: "Kieta Aropa Papua New Guinea",
	},
	{
		key: "KTA",
		value: "Karratha Karratha Australia",
	},
	{
		key: "LSA",
		value: "Losuia Losuia Papua New Guinea",
	},
	{
		key: "MEB",
		value: "Melbourne Essendon Australia",
	},
	{
		key: "MEL",
		value: "Melbourne Tullamarine Australia",
	},
	{
		key: "MON",
		value: "Mount Cook Mount Cook Airport New Zealand",
	},
	{
		key: "NAN",
		value: "Nadi Nadi Intl Fiji",
	},
	{
		key: "NOU",
		value: "Nourmea La Tontouta Intl New Caledonia",
	},
	{
		key: "NPL",
		value: "New Plymouth New Plymouth New Zealand",
	},
	{
		key: "OOL",
		value: "Gold Coast Gold Coast Australia",
	},
	{
		key: "PER",
		value: "Perth Perth Australia",
	},
	{
		key: "PHE",
		value: "Port Hedland Port Hedland intl Australia",
	},
	{
		key: "PLO",
		value: "Port Lincoln Port Lincoln Australia",
	},
	{
		key: "PMR",
		value: "Palmerston North Palmerston North New Zealand",
	},
	{
		key: "POM",
		value: "Port Moresby Jacksons Intl Papua New Guinea",
	},
	{
		key: "PPT",
		value: "Papeete Faaa French Polynesia",
	},
	{
		key: "RAR",
		value: "Rarotonga Rarotonga Intl Cook Island",
	},
	{
		key: "SCZ",
		value: "Santa Cruz Island Suceava Intl Solomon Islands",
	},
	{
		key: "SGP",
		value: "Shay Gap Shay Gap Australia",
	},
	{
		key: "SPN",
		value: "Saipan Saipan Intl US minor islands",
	},
	{
		key: "SXM",
		value: "St Maarten Princess Juliana Netherlands Antilles",
	},
	{
		key: "SYD",
		value: "Sydney Kingsford Smith Australia",
	},
	{
		key: "TKY",
		value: "Turkey Creek Turkey Creek Australia",
	},
	{
		key: "TRW",
		value: "Tarawa Bonriki Kiribati",
	},
	{
		key: "VLI",
		value: "Port Vila Bauerfield Vanuatu",
	},
	{
		key: "WLG",
		value: "Wellington Wellington intl New Zealand",
	},
	{
		key: "AER",
		value: "Sochi Sochi International Russia",
	},
	{
		key: "DME",
		value: "Moscow Domodedovo Russia",
	},
	{
		key: "EGS",
		value: "Egilsstadir Egilsstadir Iceland",
	},
	{
		key: "HRK",
		value: "Kharkiv Kharkov Intl Ukraine",
	},
	{
		key: "IEV",
		value: "Kiev Kyiv Intl (Zhuliany) Ukraine",
	},
	{
		key: "KBP",
		value: "Kiev Boryispol Ukraine",
	},
	{
		key: "KEF",
		value: "Reykjavik Keflavik Intl Iceland",
	},
	{
		key: "LED",
		value: "St Petersburg Pulkovo Russia",
	},
	{
		key: "LWO",
		value: "Lvov (Lwow, Lemberg) Lviv Danylo Halytskyi Intl Ukraine",
	},
	{
		key: "MOW",
		value: "Moscow Metropolitan Area Russia",
	},
	{
		key: "MRV",
		value: "Mineralnye Vody Mineralnye Vody Russia",
	},
	{
		key: "ODS",
		value: "Odessa Odessa Intl Ukraine",
	},
	{
		key: "REK",
		value: "Reykjavik Metropolitan Area Iceland",
	},
	{
		key: "SIP",
		value: "Simferopol Simferopol Intl Ukraine",
	},
	{
		key: "SVO",
		value: "Moscow Sheremetyevo Russia",
	},
	{
		key: "THO",
		value: "Thorshofn Thorshofn Iceland",
	},
	{
		key: "VKO",
		value: "Moscow Vnukovo International Russia",
	},
	{
		key: "ADA",
		value: "Adana Adana Sakirpasa Turkey",
	},
	{
		key: "ADB",
		value: "Ismir Adnan Menderes Turkey",
	},
	{
		key: "ADF",
		value: "Adiyaman Adiyaman Airport Turkey",
	},
	{
		key: "AES",
		value: "Aalesund Vigra Norway",
	},
	{
		key: "ALF",
		value: "Alta Alta Airport Norway",
	},
	{
		key: "ANK",
		value: "Ankara Etimesgut Turkey",
	},
	{
		key: "ASR",
		value: "Kayseri Erkilet International Turkey",
	},
	{
		key: "AYT",
		value: "Antalya Antalya Airport Turkey",
	},
	{
		key: "BDU",
		value: "Bardufoss Bardufoss Norway",
	},
	{
		key: "BEG",
		value: "Belgrade Belgrade Nikola Tesla Serbia",
	},
	{
		key: "BGO",
		value: "Bergen Flesland Norway",
	},
	{
		key: "BJV",
		value: "Milas Bodrum Bodrum - Milas Airport Turkey",
	},
	{
		key: "BNN",
		value: "Bronnoysund Broennoeysund Norway",
	},
	{
		key: "BOO",
		value: "Bodo Bodo Airport Norway",
	},
	{
		key: "BTZ",
		value: "Bursa Bursa Turkey",
	},
	{
		key: "BZY",
		value: "Beltsi (Balti) Balti Intl Moldova",
	},
	{
		key: "DLM",
		value: "Dalaman Dalaman Turkey",
	},
	{
		key: "DNZ",
		value: "Denizli Denizli Cardak Turkey",
	},
	{
		key: "ERC",
		value: "Erzincan Erzincan Turkey",
	},
	{
		key: "ERZ",
		value: "Erzurum Erzurum Turkey",
	},
	{
		key: "ESB",
		value: "Ankara Esenboga Turkey",
	},
	{
		key: "EVE",
		value: "Evenes Harstad/Narvik Norway",
	},
	{
		key: "FAE",
		value: "Sorvagur Vagar Faroe Islands",
	},
	{
		key: "FRO",
		value: "Flora Floro Norway",
	},
	{
		key: "GZT",
		value: "Gaziantep Gaziantep Oguzeli Intl Turkey",
	},
	{
		key: "HAU",
		value: "Haugesund Haugesund Norway",
	},
	{
		key: "HFT",
		value: "Hammerfest Hammerfest Norway",
	},
	{
		key: "INI",
		value: "Nis Constantine the Great Serbia",
	},
	{
		key: "IST",
		value: "Istanbul Ataturk Turkey",
	},
	{
		key: "IZM",
		value: "lsmir Metropolitan Area Turkey",
	},
	{
		key: "KCM",
		value: "Kahramanmaras Kahramanmaras Turkey",
	},
	{
		key: "KIV",
		value: "Chisinau Chisinau - Chisinau International Airport Moldova",
	},
	{
		key: "KKN",
		value: "Kirkenes Hoeybuktmoen Norway",
	},
	{
		key: "KRS",
		value: "Kristiansand Kjevik Norway",
	},
	{
		key: "KSU",
		value: "Kristansund Kverberget Norway",
	},
	{
		key: "LKL",
		value: "Lakselv Lakselv Norway",
	},
	{
		key: "LYR",
		value: "Longyearbyen - Svalbard Longyearbyen - Svalbard Norway",
	},
	{
		key: "MOL",
		value: "Molde Aro Norway",
	},
	{
		key: "MSQ",
		value: "Minsk Minsk Intl 2 Belarus",
	},
	{
		key: "OHD",
		value: 'Ohrid "St Paul the Apostle" Macedonia',
	},
	{
		key: "OMO",
		value: "Mostar Mostar Bosnia and Herzegovina",
	},
	{
		key: "OSL",
		value: "Oslo Oslo Gardermoen Norway",
	},
	{
		key: "PRN",
		value: "Pristina Pristina Intl Serbia",
	},
	{
		key: "SAW",
		value: "Istanbul Sabiha Gokcen Turkey",
	},
	{
		key: "SJJ",
		value: "Sarajevo Butmir Bosnia Herzogovina",
	},
	{
		key: "SKP",
		value: "Skopie Alexander the Great Macedonia",
	},
	{
		key: "SVG",
		value: "Stavanger Sola Norway",
	},
	{
		key: "SZF",
		value: "Samsun Samsun Turkey",
	},
	{
		key: "TGD",
		value: "Podgorica Golubovci Serbia",
	},
	{
		key: "TIA",
		value: "Tirana Nene Tereza/Rinas Intl Albania",
	},
	{
		key: "TIV",
		value: "Tivat Tivat Montenegro",
	},
	{
		key: "TOS",
		value: "Tromso Tromso/Langnes Norway",
	},
	{
		key: "TRD",
		value: "Trondheim Vaemes Norway",
	},
	{
		key: "TRF",
		value: "Torp Sandefjord Airport Norway",
	},
	{
		key: "TZX",
		value: "Trabzon Trabzon Turkey",
	},
	{
		key: "AAL",
		value: "Aalborg Aalborg Airport Denmark",
	},
	{
		key: "AAR",
		value: "Aarhus Aarhus Airport Denmark",
	},
	{
		key: "ACE",
		value: "Lanzarote Lanzarote Airport Spain",
	},
	{
		key: "ACH",
		value: "Altenrhein St. Gallen-Altenrhein Airport Switzerland",
	},
	{
		key: "AGP",
		value: "Málaga Málaga Airport Spain",
	},
	{
		key: "AHO",
		value: "Alghero Fertilia Airport Sardinia",
	},
	{
		key: "AJA",
		value: "Ajaccio Campo dell'Oro Airport France",
	},
	{
		key: "ALC",
		value: "Alicante Alicante Airport Spain",
	},
	{
		key: "AMS",
		value: "Amsterdam Amsterdam Airport Schiphol Netherlands",
	},
	{
		key: "ANR",
		value: "Antwerp Antwerp International Airport Belgium",
	},
	{
		key: "AOI",
		value: "Ancona Ancona Airport Italy",
	},
	{
		key: "AOK",
		value: "Karpathos Island Karpathos Island National Airport Greece",
	},
	{
		key: "ARN",
		value: "Stockholm Stockholm-Arlanda Airport Sweden",
	},
	{
		key: "ATH",
		value: "Athens Athens International Airport Greece",
	},
	{
		key: "BCN",
		value: "Barcelona Barcelona Airport Spain",
	},
	{
		key: "BDS",
		value: "Brindisi Brindisi Airport Italy",
	},
	{
		key: "BER",
		value: "Berlin Berlin Brandenburg Airport Germany",
	},
	{
		key: "BES",
		value: "Brest Brest Bretagne Airport France",
	},
	{
		key: "BGY",
		value: "Bergamo Orio al Serio Airport Italy",
	},
	{
		key: "BIA",
		value: "Bastia Poretta Airport France",
	},
	{
		key: "BIQ",
		value: "Biarritz Biarritz Airport France",
	},
	{
		key: "BLL",
		value: "Billund Billund Airport Denmark",
	},
	{
		key: "BLQ",
		value: "Bologna Bologna Airport Italy",
	},
	{
		key: "BMA",
		value: "Stockholm Stockholm-Bromma Airport Sweden",
	},
	{
		key: "BOD",
		value: "Bordeaux Mérignac Airport France",
	},
	{
		key: "BOJ",
		value: "Burgas Burgas Airport Bulgaria",
	},
	{
		key: "BRE",
		value: "Bremen Bremen Airport Germany",
	},
	{
		key: "BRI",
		value: "Bari Palese Airport Italy",
	},
	{
		key: "BRN",
		value: "Berne Belp Switzerland",
	},
	{
		key: "BRQ",
		value: "Brno Brno-Turany Airport Czech Republic",
	},
	{
		key: "BRU",
		value: "Brussels Brussels Airport Belgium",
	},
	{
		key: "BSL",
		value: "Basel/Mulhouse/Freiburg EuroAirport Swiss Switzerland",
	},
	{
		key: "BTS",
		value: "Bratislava M. R. Štefánik Airport Slovakia",
	},
	{
		key: "BUD",
		value: "Budapest Budapest Ferenc Liszt International Airport Hungary",
	},
	{
		key: "BVA",
		value: "Beauvais Beauvais-Tillé Airport France",
	},
	{
		key: "BYJ",
		value: "Beja Beja Airport Portugal",
	},
	{
		key: "BZG",
		value: "Bydgoszcz Bydgoszcz Ignacy Jan Paderewski Airport Poland",
	},
	{
		key: "BZR",
		value: "Béziers Béziers Cap d'Agde Airport France",
	},
	{
		key: "CAG",
		value: "Cagliari Cagliari Airport Italy",
	},
	{
		key: "CAY",
		value: "Cayenne Felix Eboue Airport French Guiana",
	},
	{
		key: "CCF",
		value: "Carcassonne Salvaza Airport France",
	},
	{
		key: "CDG",
		value: "Paris Charles de Gaulle Airport France",
	},
	{
		key: "CFU",
		value: "Corfu Ioannis Kapodistrias Airport Greece",
	},
	{
		key: "CGN",
		value: "Cologne/Bonn Cologne/Bonn Airport Germany",
	},
	{
		key: "CHQ",
		value: "Chania Chania International Airport Greece",
	},
	{
		key: "CIA",
		value: "Rome Rome Ciampino Airport Italy",
	},
	{
		key: "CLJ",
		value: "Cluj-Napoca Cluj-Napoca International Airport Romania",
	},
	{
		key: "CMF",
		value: "Chambéry Chambéry-Savoie Airport France",
	},
	{
		key: "CND",
		value: "Constanta Mihail Kogalniceanu International Airport Romania",
	},
	{
		key: "CPH",
		value: "Copenhagen Copenhagen Airport Denmark",
	},
	{
		key: "CRL",
		value: "Brussels/Charleroi Brussels South Charleroi Airport Belgium",
	},
	{
		key: "CTA",
		value: "Catania Fontanarossa Airport Italy",
	},
	{
		key: "CUF",
		value: "Cuneo Cuneo Levaldigi Airport Italy",
	},
	{
		key: "DBV",
		value: "Dubrovnik Dubrovnik Airport Croatia",
	},
	{
		key: "DEB",
		value: "Debrecen Debrecen International Airport Hungary",
	},
	{
		key: "DNR",
		value: "Dinard Pleurtuit Airport France",
	},
	{
		key: "DTM",
		value: "Dortmund Dortmund Airport Germany",
	},
	{
		key: "DUB",
		value: "Dublin Dublin Airport Republic of Ireland",
	},
	{
		key: "DUS",
		value: "Düsseldorf Düsseldorf Airport Germany",
	},
	{
		key: "EAP",
		value: "Basel Basel/Mulhouse Switzerland",
	},
	{
		key: "EBU",
		value: "Saint Etienne Bouthéon Airport France",
	},
	{
		key: "EFL",
		value: "Kefalonia Island Kefalonia Island International Airport Greece",
	},
	{
		key: "EGC",
		value: "Bergerac Bergerac-Roumanière Airport France",
	},
	{
		key: "EIN",
		value: "Eindhoven Eindhoven Airport Netherlands",
	},
	{
		key: "FAO",
		value: "Faro Faro Airport Portugal",
	},
	{
		key: "FCO",
		value: "Rome Leonardo da Vinci-Fiumicino Airport Italy",
	},
	{
		key: "FDF",
		value: "Fort-De-France Lamentin Intl Martinique",
	},
	{
		key: "FDH",
		value: "Friedrichshafen Friedrichshafen Airport Germany",
	},
	{
		key: "FKB",
		value: "Baden-Baden/Karlsruhe Karlsruhe/Baden-Baden Airport Germany",
	},
	{
		key: "FLR",
		value: "Florence Peretola Airport Italy",
	},
	{
		key: "FMM",
		value: "Memmingen Memmingen Airport Germany",
	},
	{
		key: "FNC",
		value: "Funchal Madeira Airport Portugal",
	},
	{
		key: "FNI",
		value: "Nîmes Garons Airport France",
	},
	{
		key: "FRA",
		value: "Frankfurt Frankfurt Airport Germany",
	},
	{
		key: "FSC",
		value: "Figari Figari Sud-Corse Airport France",
	},
	{
		key: "FUE",
		value: "Fuerteventura Fuerteventura Airport Spain",
	},
	{
		key: "GDN",
		value: "Gdansk Gdansk Lech Walesa Airport Poland",
	},
	{
		key: "GNB",
		value: "Grenoble Grenoble-Isère Airport France",
	},
	{
		key: "GOA",
		value: "Genoa Cristoforo Colombo Airport Italy",
	},
	{
		key: "GOT",
		value: "Gothenburg Göteborg Landvetter Airport Sweden",
	},
	{
		key: "GRO",
		value: "Girona Girona-Costa Brava Airport Spain",
	},
	{
		key: "GRQ",
		value: "Groningen Groningen Airport Eelde Netherlands",
	},
	{
		key: "GRX",
		value: "Granada Granada Airport Spain",
	},
	{
		key: "GRZ",
		value: "Graz Graz Airport Austria",
	},
	{
		key: "GSE",
		value: "Gothenburg Gothenburg City Airport Sweden",
	},
	{
		key: "GVA",
		value: "Geneva Geneve-Cointrin Switzerland",
	},
	{
		key: "HAJ",
		value: "Hanover Langenhagen Airport Germany",
	},
	{
		key: "HAM",
		value: "Hamburg Hamburg Airport Germany",
	},
	{
		key: "HEL",
		value: "Helsinki Helsinki-Vantaa Airport Finland",
	},
	{
		key: "HER",
		value: "Heraklion Heraklion International Airport Greece",
	},
	{
		key: "HHN",
		value: "Frankfurt Frankfurt-Hahn Airport Germany",
	},
	{
		key: "HSK",
		value: "Huesca Huesca-Pirineos Airport Spain",
	},
	{
		key: "IAS",
		value: "Iasi Iasi International Airport Romania",
	},
	{
		key: "IBZ",
		value: "Ibiza Ibiza Airport Spain",
	},
	{
		key: "ILD",
		value: "Lleida Alguaire Airport Spain",
	},
	{
		key: "ILZ",
		value: "Žilina Žilina Airport Slovakia",
	},
	{
		key: "INN",
		value: "Innsbruck Innsbruck Airport Austria",
	},
	{
		key: "JKH",
		value: "Chios Island Chios Island National Airport Greece",
	},
	{
		key: "JMK",
		value: "Mykonos Island Mykonos Island National Airport Greece",
	},
	{
		key: "JSI",
		value: "Skiathos Island Skiathos Island National Airport Greece",
	},
	{
		key: "JTR",
		value: "Santorini Santorini (Thira) National Airport Greece",
	},
	{
		key: "KAO",
		value: "Kuusamo Kuusamo Airport Finland",
	},
	{
		key: "KGS",
		value: "Kos Island Kos Island International Airport Greece",
	},
	{
		key: "KIR",
		value: "Kerry Kerry Airport Republic of Ireland",
	},
	{
		key: "KLU",
		value: "Klagenfurt Klagenfurt Airport Austria",
	},
	{
		key: "KLV",
		value: "Karlovy Vary Karlovy Vary Airport Czech Republic",
	},
	{
		key: "KLX",
		value: "Kalamata Kalamata International Airport Greece",
	},
	{
		key: "KRK",
		value: "Kraków John Paul II International Airport Kraków-Balice Poland",
	},
	{
		key: "KSC",
		value: "Košice Košice International Airport Slovakia",
	},
	{
		key: "KTT",
		value: "Kittilä Kittilä Airport Finland",
	},
	{
		key: "KTW",
		value: "Katowice Katowice International Airport Poland",
	},
	{
		key: "KUN",
		value: "Kaunas Kaunas Airport Lithuania",
	},
	{
		key: "KUO",
		value: "Kuopio Kuopio Airport Finland",
	},
	{
		key: "KVA",
		value: "Kavala Kavala International Airport Greece",
	},
	{
		key: "LBC",
		value: "Lübeck Lübeck Airport Germany",
	},
	{
		key: "LCA",
		value: "Larnaca Larnaca International Airport Cyrpus",
	},
	{
		key: "LCG",
		value: "A Coruña Alvedro Airport Spain",
	},
	{
		key: "LCJ",
		value: "Lódz Lódz Wladyslaw Reymont Airport Poland",
	},
	{
		key: "LEI",
		value: "Almeria Almería International Airport Spain",
	},
	{
		key: "LEJ",
		value: "Leipzig Leipzig/Halle Airport Germany",
	},
	{
		key: "LGG",
		value: "Liège Liège Airport Belgium",
	},
	{
		key: "LIG",
		value: "Limoges Bellegarde Airport France",
	},
	{
		key: "LIL",
		value: "Lille Lille Lesquin Airport France",
	},
	{
		key: "LIN",
		value: "Milan Linate Airport Italy",
	},
	{
		key: "LIS",
		value: "Lisbon Humberto Delgado Airport Portugal",
	},
	{
		key: "LJU",
		value: "Ljubljana Ljubljana Jože Pucnik Airport Slovenia",
	},
	{
		key: "LLA",
		value: "Luleå Luleå Airport Sweden",
	},
	{
		key: "LNZ",
		value: "Linz Linz Airport Austria",
	},
	{
		key: "LPA",
		value: "Las Palmas Gran Canaria Airport Spain",
	},
	{
		key: "LPP",
		value: "Lappeenranta Lappeenranta Airport Finland",
	},
	{
		key: "LRH",
		value: "Ile de Re Île de Ré Airport France",
	},
	{
		key: "LUX",
		value: "Luxembourg City Findel Airport Luxembourg",
	},
	{
		key: "LUZ",
		value: "Lublin Lublin Airport Poland",
	},
	{
		key: "LYS",
		value: "Lyon Saint-Exupéry Airport France",
	},
	{
		key: "MAD",
		value: "Madrid Madrid-Barajas Airport Spain",
	},
	{
		key: "MAH",
		value: "Menorca Menorca Airport Spain",
	},
	{
		key: "MBX",
		value: "Maribor Maribor Edvard Rusjan Airport Slovenia",
	},
	{
		key: "MHQ",
		value: "Åland Mariehamn Airport Finland",
	},
	{
		key: "MJT",
		value: "Mytilene Mytilene International Airport Greece",
	},
	{
		key: "MLA",
		value: "Valletta Malta International Airport Malta",
	},
	{
		key: "MLH",
		value: "Freiburg im Breisgau EuroAirport Basel–Mulhouse–Freiburg Germany",
	},
	{
		key: "MMX",
		value: "Malmö Malmö Airport Sweden",
	},
	{
		key: "MRS",
		value: "Marseille Provence Airport France",
	},
	{
		key: "MST",
		value: "Maastricht Maastricht-Aachen Airport Netherlands",
	},
	{
		key: "MUC",
		value: "Munich Munich Airport Germany",
	},
	{
		key: "MXP",
		value: "Milan Malpensa Airport Italy",
	},
	{
		key: "NAP",
		value: "Naples Capodichino Airport Italy",
	},
	{
		key: "NCE",
		value: "Nice Côte d'Azur Airport France",
	},
	{
		key: "NOC",
		value: "Knock Ireland West Airport Knock Republic of Ireland",
	},
	{
		key: "NRK",
		value: "Norrköping Norrköping Airport Sweden",
	},
	{
		key: "NRN",
		value: "Weeze Weeze Airport Germany",
	},
	{
		key: "NTE",
		value: "Nantes Nantes Atlantique Airport France",
	},
	{
		key: "NUE",
		value: "Nuremberg Albrecht Dürer Airport Germany",
	},
	{
		key: "NYO",
		value: "Stockholm Stockholm-Skavsta Airport Sweden",
	},
	{
		key: "OLB",
		value: "Olbia Costa Smeralda Airport Italy",
	},
	{
		key: "OMR",
		value: "Oradea Oradea International Airport Romania",
	},
	{
		key: "OPO",
		value: "Porto Francisco Sá Carneiro Airport Portugal",
	},
	{
		key: "ORK",
		value: "Cork Cork Airport Republic of Ireland",
	},
	{
		key: "ORY",
		value: "Paris Orly Airport France",
	},
	{
		key: "OSD",
		value: "Östersund Åre Östersund Airport Sweden",
	},
	{
		key: "OSI",
		value: "Osijek Osijek Airport Croatia",
	},
	{
		key: "OSR",
		value: "Ostrava Leoš Janácek Ostrava Airport Czech Republic",
	},
	{
		key: "OST",
		value: "Ostend/Bruges Ostend-Bruges International Airport Belgium",
	},
	{
		key: "OTP",
		value: "Bucharest Henri Coanda International Airport Romania",
	},
	{
		key: "OUL",
		value: "Oulu Oulu Airport Finland",
	},
	{
		key: "OVD",
		value: "Asturias Asturias Airport Spain",
	},
	{
		key: "PDL",
		value: "Ponta Delgada João Paulo II Airport Portugal",
	},
	{
		key: "PDV",
		value: "Plovdiv Plovdiv Airport Bulgaria",
	},
	{
		key: "PED",
		value: "Pardubice Pardubice Airport Czech Republic",
	},
	{
		key: "PEG",
		value: "Perugia San Egidio Airport Italy",
	},
	{
		key: "PFO",
		value: "Paphos Paphos International Airport Cyprus",
	},
	{
		key: "PGF",
		value: "Perpignan Llabanère Airport France",
	},
	{
		key: "PIS",
		value: "Poitiers Biard Airport France",
	},
	{
		key: "PLQ",
		value: "Palanga Palanga International Airport Lithuania",
	},
	{
		key: "PMI",
		value: "Palma de Mallorca Palma de Mallorca Airport Spain",
	},
	{
		key: "PMO",
		value: "Palermo Palermo Airport Italy",
	},
	{
		key: "POW",
		value: "Portorož Portorož Airport Slovenia",
	},
	{
		key: "POZ",
		value: "Poznan Poznan Lawica Airport Poland",
	},
	{
		key: "PRG",
		value: "Prague Prague Václav Havel Airport Czech Republic",
	},
	{
		key: "PSA",
		value: "Pisa Galileo Galilei Airport Italy",
	},
	{
		key: "PSR",
		value: "Pescara Abruzzo Airport Italy",
	},
	{
		key: "PTP",
		value: "Grande-Terre Pointe-a-Pitre Intl Guadeloupe",
	},
	{
		key: "PUF",
		value: "Pau Uzein Airport France",
	},
	{
		key: "PUY",
		value: "Pula Pula Airport Croatia",
	},
	{
		key: "PVK",
		value: "Preveza Aktion National Airport Greece",
	},
	{
		key: "PXO",
		value: "Porto Santo Island Porto Santo Airport Portugal",
	},
	{
		key: "PZY",
		value: "Pieštany Pieštany Airport Slovakia",
	},
	{
		key: "QGY",
		value: "Gyor Gyor-Pér International Airport Hungary",
	},
	{
		key: "QPJ",
		value: "Pécs Pécs-Pogány International Airport Hungary",
	},
	{
		key: "RDZ",
		value: "Rodez Marcillac Airport France",
	},
	{
		key: "REU",
		value: "Reus Reus Airport Spain",
	},
	{
		key: "RHO",
		value: "Rhodes Rhodes International Airport Greece",
	},
	{
		key: "RIX",
		value: "Riga Riga International Airport Latvia",
	},
	{
		key: "RJK",
		value: "Rijeka Rijeka Airport Croatia",
	},
	{
		key: "RMI",
		value: "Rimini Federico Fellini Airport Italy",
	},
	{
		key: "RMU",
		value: "Murcia Región de Murcia International Airport Spain",
	},
	{
		key: "RTM",
		value: "Rotterdam Rotterdam The Hague Airport Netherlands",
	},
	{
		key: "RUN",
		value: "Saint Denis/Gillot Rland Garros Airport Reunion",
	},
	{
		key: "RVN",
		value: "Rovaniemi Rovaniemi Airport Finland",
	},
	{
		key: "RZE",
		value: "Rzeszów Rzeszów-Jasionka Airport Poland",
	},
	{
		key: "SBZ",
		value: "Sibiu Sibiu International Airport Romania",
	},
	{
		key: "SCQ",
		value: "Santiago de Compostela Santiago de Compostela Airport Spain",
	},
	{
		key: "SDR",
		value: "Santander Santander Airport Spain",
	},
	{
		key: "SKG",
		value: "Thessaloniki Thessaloniki International Airport Greece",
	},
	{
		key: "SKU",
		value: "Skyros Island Skyros Island National Airport Greece",
	},
	{
		key: "SLD",
		value: "Sliac Sliac Airport Slovakia",
	},
	{
		key: "SMI",
		value: "Samos Samos International Airport Greece",
	},
	{
		key: "SNN",
		value: "Shannon Shannon Airport Republic of Ireland",
	},
	{
		key: "SOB",
		value: "Hévíz Hévíz-Balaton Airport Hungary",
	},
	{
		key: "SOF",
		value: "Sofia Sofia Airport Bulgaria",
	},
	{
		key: "SPC",
		value: "La Palma La Palma Airport Spain",
	},
	{
		key: "SPU",
		value: "Split Split Airport Croatia",
	},
	{
		key: "SQQ",
		value: "Šiauliai Šiauliai International Airport Lithuania",
	},
	{
		key: "STR",
		value: "Stuttgart Stuttgart Airport Germany",
	},
	{
		key: "SUF",
		value: "Lamezia Lamezia Terme Airport Italy",
	},
	{
		key: "SVQ",
		value: "Seville Seville Airport Spain",
	},
	{
		key: "SXB",
		value: "Strasbourg Strasbourg Airport France",
	},
	{
		key: "SXF",
		value: "Berlin Berlin Schönefeld Airport Germany",
	},
	{
		key: "SZG",
		value: "Salzburg Salzburg Airport Austria",
	},
	{
		key: "SZZ",
		value: "Szczecin Solidarity Szczecin-Goleniów Airport Poland",
	},
	{
		key: "TAT",
		value: "Poprad Poprad-Tatry Airport Slovakia",
	},
	{
		key: "TAY",
		value: "Tartu Tartu Airport Estonia",
	},
	{
		key: "TER",
		value: "Terceira Island Terceira Airport Portugal",
	},
	{
		key: "TFN",
		value: "Tenerife Tenerife North Airport Spain",
	},
	{
		key: "TFS",
		value: "Tenerife Tenerife South Airport Spain",
	},
	{
		key: "TKU",
		value: "Turku Turku Airport Finland",
	},
	{
		key: "TLL",
		value: "Tallinn Lennart Meri Tallinn Airport Estonia",
	},
	{
		key: "TLN",
		value: "Toulon Hyères Le Palyvestre Airport France",
	},
	{
		key: "TLS",
		value: "Toulouse Blagnac Airport France",
	},
	{
		key: "TMP",
		value: "Tampere Tampere-Pirkkala Airport Finland",
	},
	{
		key: "TPS",
		value: "Trapani Vincenzo Florio Airport Italy",
	},
	{
		key: "TRN",
		value: "Turin Caselle Airport Italy",
	},
	{
		key: "TRS",
		value: "Trieste Friuli Venezia Giulia Airport Italy",
	},
	{
		key: "TSR",
		value: "Timisoara Traian Vuia International Airport Romania",
	},
	{
		key: "TUF",
		value: "Tours Tours Loire Valley Airport France",
	},
	{
		key: "TXL",
		value: "Berlin Berlin Tegel Airport Germany",
	},
	{
		key: "UME",
		value: "Umeå Umeå Airport Sweden",
	},
	{
		key: "VAA",
		value: "Vaasa Vaasa Airport Finland",
	},
	{
		key: "VAR",
		value: "Varna Varna Airport Bulgaria",
	},
	{
		key: "VBS",
		value: "Brescia Brescia Airport Italy",
	},
	{
		key: "VBY",
		value: "Visby Visby Airport Sweden",
	},
	{
		key: "VCE",
		value: "Venice Venice Marco Polo Airport Italy",
	},
	{
		key: "VIE",
		value: "Vienna Vienna International Airport Austria",
	},
	{
		key: "VLC",
		value: "Valencia Valencia Airport Spain",
	},
	{
		key: "VLL",
		value: "Valladolid Valladolid Airport Spain",
	},
	{
		key: "VNO",
		value: "Vilnius Vilnius International Airport Lithuania",
	},
	{
		key: "VNT",
		value: "Ventspils Ventspils International Airport Latvia",
	},
	{
		key: "VOL",
		value: "Volos Nea Anchialos National Airport Greece",
	},
	{
		key: "VRN",
		value: "Verona Verona Airport Italy",
	},
	{
		key: "VST",
		value: "Stockholm Stockholm-Västerås Airport Sweden",
	},
	{
		key: "VXO",
		value: "Växjö Växjö Airport Sweden",
	},
	{
		key: "WAW",
		value: "Warsaw Frédéric Chopin Airport Poland",
	},
	{
		key: "WMI",
		value: "Warsaw Modlin Airport Poland",
	},
	{
		key: "WRO",
		value: "Wroclaw Copernicus Airport Wroclaw Poland",
	},
	{
		key: "XCR",
		value: "Châlons-en-Champagne Châlons Vatry Airport France",
	},
	{
		key: "XRY",
		value: "Jerez de la Frontera Jerez Airport Spain",
	},
	{
		key: "ZAG",
		value: "Zagreb Zagreb Franjo Tuđman Airport Croatia",
	},
	{
		key: "ZAZ",
		value: "Zaragoza Zaragoza Airport Spain",
	},
	{
		key: "ZRH",
		value: "Zurich Zurich Switzerland",
	},
	{
		key: "BQH",
		value: "Biggin Hill (London) Airport Biggin Hill (London) Airport United Kingdom",
	},
	{
		key: "BHX",
		value: "Birmingham Airport Birmingham Airport United Kingdom",
	},
	{
		key: "BLK",
		value: "Blackpool Airport Blackpool Airport United Kingdom",
	},
	{
		key: "BRS",
		value: "Bristol Airport Bristol Airport United Kingdom",
	},
	{
		key: "BZZ",
		value: "Brize Norton RAF Base Brize Norton RAF Base United Kingdom",
	},
	{
		key: "CBG",
		value: "Cambridge Airport Cambridge Airport United Kingdom",
	},
	{
		key: "CVT",
		value: "Coventry Airport Coventry Airport United Kingdom",
	},
	{
		key: "MME",
		value: "Durham Tees Valley Airport - Teesside Durham Tees Valley Airport - Teesside United Kingdom",
	},
	{
		key: "ABZ",
		value: "Aberdeen Airport - Dyce Aberdeen Airport - Dyce United Kingdom",
	},
	{
		key: "EMA",
		value: "East Midlands Apt East Midlands Apt United Kingdom",
	},
	{
		key: "SOU",
		value: "Southampton Airport - Eastleigh Southampton Airport - Eastleigh United Kingdom",
	},
	{
		key: "EDI",
		value: "Edinburgh Airport Edinburgh Airport United Kingdom",
	},
	{
		key: "EXT",
		value: "Exeter Airport Exeter Airport United Kingdom",
	},
	{
		key: "FAB",
		value: "Farnborough Airport Farnborough Airport United Kingdom",
	},
	{
		key: "LGW",
		value: "Gatwick Apt/London Gatwick Apt/London United Kingdom",
	},
	{
		key: "GLA",
		value: "Glasgow Airport Glasgow Airport United Kingdom",
	},
	{
		key: "LHR",
		value: "London Heathrow Airport London Heathrow Airport United Kingdom",
	},
	{
		key: "BOH",
		value: "Bournemouth International Airport - Hurn Bournemouth International Airport - Hurn United Kingdom",
	},
	{
		key: "HUY",
		value: "Humberside Airport - Kirmington Humberside Airport - Kirmington United Kingdom",
	},
	{
		key: "LCY",
		value: "London City Airport London City Airport United Kingdom",
	},
	{
		key: "LTN",
		value: "Luton Airport Luton Airport United Kingdom",
	},
	{
		key: "LYX",
		value: "London Ashford Airport - Lydd London Ashford Airport - Lydd United Kingdom",
	},
	{
		key: "MAN",
		value: "Manchester Airport Manchester Airport United Kingdom",
	},
	{
		key: "NQY",
		value: "Newquay Airport Newquay Airport United Kingdom",
	},
	{
		key: "NWI",
		value: "Norwich Airport Norwich Airport United Kingdom",
	},
	{
		key: "NHT",
		value: "Northolt RAF Base Northolt RAF Base United Kingdom",
	},
	{
		key: "NCL",
		value: "Newcastle International Airport - Ponteland Newcastle International Airport - Ponteland United Kingdom",
	},
	{
		key: "PIK",
		value: "Prestwick Airport Prestwick Airport United Kingdom",
	},
	{
		key: "CWL",
		value: "Cardiff International Airport - Rhoose Cardiff International Airport - Rhoose United Kingdom",
	},
	{
		key: "ESH",
		value: "Brighton City Airport - Shoreham-by-Sea Brighton City Airport - Shoreham-by-Sea United Kingdom",
	},
	{
		key: "SEN",
		value: "London Southend Airport London Southend Airport United Kingdom",
	},
	{
		key: "LPL",
		value: "Liverpool John Lennon Airport - Speke Liverpool John Lennon Airport - Speke United Kingdom",
	},
	{
		key: "STN",
		value: "London Stansted Airport London Stansted Airport United Kingdom",
	},
	{
		key: "IOM",
		value: "Ronaldsway Airport (IOM) Ronaldsway Airport (IOM) United Kingdom",
	},
	{
		key: "LSI",
		value: "Sumburgh Airport Sumburgh Airport United Kingdom",
	},
	{
		key: "LBA",
		value: "Leeds Bradford Airport - Yeadon Leeds Bradford Airport - Yeadon United Kingdom",
	},
	{
		key: "FFD",
		value: "Fairford RAF Base Fairford RAF Base United Kingdom",
	},
	{
		key: "LKZ",
		value: "Lakenheath RAF Base Lakenheath RAF Base United Kingdom",
	},
	{
		key: "LYE",
		value: "Lyneham RAF Base Lyneham RAF Base United Kingdom",
	},
	{
		key: "MHZ",
		value: "Mildenhall RAF Base Mildenhall RAF Base United Kingdom",
	},
	{
		key: "PSL",
		value: "Perth Airport Balbeggie Perth Airport Balbeggie United Kingdom",
	},
	{
		key: "BWF",
		value: "Walney WalneyAerodrome United Kingdom",
	},
	{
		key: "NQT",
		value: "Nottingham City Airport Bassingfield Nottingham City Airport Bassingfield United Kingdom",
	},
	{
		key: "BBP",
		value: "Bembridge Airfield IoW Bembridge Airfield IoW United Kingdom",
	},
	{
		key: "BBS",
		value: "Blackbushe Airport Blackbushe Airport Yateley United Kingdom",
	},
	{
		key: "CAX",
		value: "CarlisleAirport CarlisleAirport United Kingdom",
	},
	{
		key: "DSA",
		value: "Robin Hood Doncaster Sheffield Airpor tDoncaster Robin Hood Doncaster Sheffield Airport Doncaster United Kingdom",
	},
	{
		key: "DND",
		value: "Dundee Airport Dundee Airport United Kingdom",
	},
	{
		key: "GLO",
		value: "Gloucester Gloucester Staverton Airport United Kingdom",
	},
	{
		key: "QUG",
		value: "Goodwood Aerodrome Goodwood Aerodrome United Kingdom",
	},
	{
		key: "HAW",
		value: "Haverfordwest Airport HaverfordwestAirport United Kingdom",
	},
	{
		key: "CEG",
		value: "Chester Airport Hawarden Chester Airport Hawarden United Kingdom",
	},
	{
		key: "INV",
		value: "Inverness Airport Inverness Airport United Kingdom",
	},
	{
		key: "ISC",
		value: "St Mary's Airport - Isles of Scilly St Mary's Airport - Isles of Scilly United Kingdom",
	},
	{
		key: "GBA",
		value: "KembleAirfield KembleAirfield United Kingdom",
	},
	{
		key: "OXF",
		value: "Oxford Airport - Kidlington Oxford Airport - Kidlington United Kingdom",
	},
	{
		key: "KOI",
		value: "Kirkwall Airport Kirkwall Airport United Kingdom",
	},
	{
		key: "RCS",
		value: "Rochester Airport Rochester Airport United Kingdom",
	},
	{
		key: "LEQ",
		value: "LandsEnd Lands End Aerodrome SaintJust United Kingdom",
	},
	{
		key: "SCS",
		value: "Scatsta Heliport Scatsta Heliport United Kingdom",
	},
	{
		key: "SYY",
		value: "Stornoway Airport Stornoway Airport United Kingdom",
	},
	{
		key: "SWS",
		value: "Swansea Airport Swansea Airport United Kingdom",
	},
	{
		key: "ORM",
		value: "Sywell Northampton Sywell Northampton Aerodrome United Kingdom",
	},
	{
		key: "WRT",
		value: "Warton Aerodrome Warton Aerodrome United Kingdom",
	},
	{
		key: "WHL",
		value: "Welshpool Aerodrome Welshpool Aerodrome United Kingdom",
	},
	{
		key: "WIC",
		value: "Wick Airport Wick Airport United Kingdom",
	},
	{
		key: "JER",
		value: "Jersey Airport, Channel Islands Jersey Airport, Channel Islands UK Channel Islands",
	},
	{
		key: "GCI",
		value: "Guernsey Airport, Channel Islands Guernsey Airport, Channel Islands UK Channel Islands",
	},
];

export default airports;
