import { useContext } from "react";
import ThemeContext from "../../../../../../../../context/theme/ThemeContext";
import Button from "../../../../../../../common/Button";
import { PiFloppyDisk, PiPencilSimple, PiTrash } from "react-icons/pi";
import { useTranslation } from "react-i18next";
import BackButton from "../../../../../../../common/BackButton";
import { SEARCH_TYPES } from "../../../../../../../../constants/GlobalConstants";

const SelectedItemHeader = (props) => {
	const { t } = useTranslation();
	const { withTheme } = useContext(ThemeContext);
	const {
		item,
		isSaveLoading,
		isDeleteLoading,
		searchType,
		onDeleteClick,
		onEditClick,
		onSaveClick,
		isEditModeEnabled,
		onDismissClick,
		onBackClick,
	} = props;

	return (
		<>
			<div style={{ paddingTop: 20, display: "flex", alignItems: "center" }}>
				<BackButton onClick={onBackClick} />
				<div
					style={{
						width: "fit-content",
						marginLeft: "auto",
						display: "flex",
						alignItems: "center",
						gap: 8,
					}}
				>
					{isEditModeEnabled ? (
						<>
							<Button
								isDisabled={isSaveLoading}
								type="secondary"
								onClick={onDismissClick}
							>
								{t("dismiss")}
							</Button>

							<Button
								isLoading={isSaveLoading}
								onClick={onSaveClick}
								LeftIcon={PiFloppyDisk}
							>
								{t("saveChanges")}
							</Button>
						</>
					) : (
						<>
							<Button
								isLoading={isDeleteLoading}
								type="icon"
								onClick={() => onDeleteClick(item)}
							>
								<PiTrash />
							</Button>

							<Button
								type="secondary"
								onClick={onEditClick}
								LeftIcon={PiPencilSimple}
							>
								{t("edit")}
							</Button>
						</>
					)}
				</div>
			</div>

			<div className={withTheme("search-page-item-title")} style={{ fontSize: 20 }}>
				{searchType === SEARCH_TYPES.CONTACTS ? `${item.data?.contactName}` : ""}
			</div>
		</>
	);
};

export default SelectedItemHeader;
