import { useContext } from "react";
import ThemeContext from "../../../context/theme/ThemeContext";

const Tabs = ({ tabs, selectedTabIndex, onChange }) => {
	const { withTheme } = useContext(ThemeContext);

	return (
		<div className={withTheme("tabs-container")}>
			{tabs.map((tab, index) => (
				<div
					key={index}
					className={`${withTheme("tab")} ${
						selectedTabIndex === index ? withTheme("tab-selected") : ""
					}`}
					onClick={() => onChange(index)}
				>
					{selectedTabIndex === index && (
						<div className={withTheme("tab-selection-highlighter")}></div>
					)}
					{tab}
				</div>
			))}
		</div>
	);
};

export default Tabs;
