import { useContext, useMemo } from "react";
import DeclarationsContext from "../../../../../context/declarations/DeclarationsContext";
import ThemeContext from "../../../../../context/theme/ThemeContext";
import Item from "./ListItem";
import { updateQueryParam } from "../../../../../router/utils";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { ITEM_QUERY_PARAM, PAGE_QUERY_PARAM } from "../../../../../constants/GlobalConstants";
import Button from "../../../../common/Button";
import { PiPlusCircle } from "react-icons/pi";

const ItemsList = ({ selectedItemIndex, items, setItems }) => {
	const { state, createItem, isDeclarationReadOnly } = useContext(DeclarationsContext);
	const { withTheme } = useContext(ThemeContext);
	const { t } = useTranslation();

	const isFinalSupplementary = useMemo(
		() => state.declaration.data.importType === "finalSupplementary",
		[state.declaration]
	);

	const handleAddClick = () => {
		const generateNewItemName = (number) => {
			const name = `Item ${number}`;
			const isNameUsed = items.find((item) => item.name === name);

			if (isNameUsed) {
				return generateNewItemName(number + 1);
			}

			return name;
		};

		let newItem = { name: generateNewItemName(1) };

		setItems([...items, newItem]);

		createItem(newItem.name);
		updateQueryParam(ITEM_QUERY_PARAM, items?.length || 0);
	};

	const handleItemClick = (item, index) => {
		if (item.new) return;

		updateQueryParam(ITEM_QUERY_PARAM, index);
		updateQueryParam(PAGE_QUERY_PARAM, 0);
	};

	useEffect(() => {
		setItems([...(state.declaration.data.declarationItems || [])]);
		const eventHandler = () => {
			setItems([...(state.declaration.data.declarationItems || [])]);
		};

		window.addEventListener("popstate", eventHandler);

		return function cleanup() {
			window.removeEventListener("popstate", eventHandler);
		};
	}, [state.declaration]);

	return (
		<div className={withTheme("items-list")}>
			<div>
				{(!items || items?.length === 0) && (
					<div className="text-center">{t("noItemsFound")}</div>
				)}

				{items?.map((item, index) => (
					<Item
						item={item}
						index={index}
						handleItemClick={handleItemClick}
						selectedItemIndex={selectedItemIndex}
						items={items}
					/>
				))}

				{!isFinalSupplementary && !isDeclarationReadOnly() && (
					<div
						style={{
							marginTop: 12,
							width: "fit-content",
							marginLeft: "auto",
							marginRight: "auto",
						}}
					>
						<Button
							type="ghost"
							style={{ paddingLeft: 4, paddingRight: 4 }}
							LeftIcon={PiPlusCircle}
							onClick={handleAddClick}
							iconStyle={{ marginRight: 4 }}
						>
							{t("addItem")}
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};

export default ItemsList;
