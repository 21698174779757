import Badge from "../Badge";

const NotificationBadge = ({ notification }) => {

	return (
		<Badge
			style={{
				marginTop: 5,
				width: 8,
				height: 8,
				marginRight: 8,
				position: "static",
				backgroundColor: notification.isRead ? "#D7DAE0" : "",
			}}
			isCompact
		>
			<div></div>
		</Badge>
	);
};

export default NotificationBadge;
