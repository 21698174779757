import { useContext } from "react";
import ThemeContext from "../../context/theme/ThemeContext";
import { PiCaretLeft } from "react-icons/pi";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const BackButton = ({ text, onClick }) => {
	const { withTheme } = useContext(ThemeContext);
	const { t } = useTranslation();
	const history = useHistory();

	const handleClick = () => {
		if (onClick) {
			onClick();
		} else {
			history.push(-1);
		}
	};

	return (
		<div onClick={handleClick} className={withTheme("back-button")}>
			<PiCaretLeft fontSize="16px" />
			<div style={{ marginLeft: 4, fontSize: 14 }}>{text || t("back")}</div>
		</div>
	);
};

export default BackButton;
