import currencies from "./validation-pulldown-options/currencies";

const settings = [
	{
		label: "Display Language",
		elementName: "language",
		type: "pulldown",
        defaultValue: "en",
        hideResetButton: true,
		pullDownMenu: [
			{
				key: "en",
				value: "English",
			},
			{
				key: "fr",
				value: "French",
			},
		],
	},
	{
		elementName: "notifications",
		type: "pulldown",
		pullDownMenu: [
			{
				key: "App",
				value: "Navigation Bar",
			},
			{
				key: "Email",
				value: "Email",
			},
		],
		label: "Notification Options",
	},
	{
		elementName: "settingsTheme",
		type: "pulldown",
		pullDownMenu: [
			{
				key: "Theme1",
				value: "Theme 1",
			},
			{
				key: "Theme2",
				value: "Theme 2",
			},
		],
		label: "Theme options",
	},
	{
		elementName: "settingsSecureCallNo",
		validation: false,
		validationLogic: {},
		label: "Secure Call Number",
		readOnly: true,
		type: "text",
	},
	{
		elementName: "settingsUserEORI",
		validation: false,
		validationLogic: {},
		label: "Your EORI number",
		readOnly: true,
		type: "text",
	},
	{
		type: "text",
		elementName: "settingsUserEmail",
		validation: false,
		validationLogic: {},
		label: "Your Email address",
	},
	{
		type: "text",
		elementName: "settingsUserName",
		validation: false,
		validationLogic: {},
		label: "Your First Name",
	},
	{
		type: "text",
		elementName: "settingsUserSurname",
		validation: false,
		validationLogic: {},
		label: "Your Last Name",
	},
	{
		type: "text",
		elementName: "settingsUserAddress1",
		validation: false,
		validationLogic: {},
		label: "Address Line 1",
	},
	{
		type: "text",
		elementName: "settingsUserAddress2",
		validation: false,
		validationLogic: {},
		label: "Address Line 2",
	},
	{
		type: "text",
		elementName: "settingsUserAddress3",
		validation: false,
		validationLogic: {},
		label: "Address Line 3",
	},
	{
		type: "text",
		elementName: "settingsUserAddress4",
		validation: false,
		validationLogic: {},
		label: "Address Line 4",
	},
	{
		type: "text",
		elementName: "settingsUserCompany",
		validation: false,
		validationLogic: {},
		label: "Your company details",
	},
	{
		type: "text",
		elementName: "settingsUserCountry",
		validation: false,
		validationLogic: {},
		label: "Country",
	},
	{
		type: "text",
		elementName: "settingsUserPostcode",
		validation: false,
		validationLogic: {},
		label: "Postcode of your address",
	},
	{
		type: "phoneNumber",
		elementName: "settingsUserPhoneNo",
		validation: false,
		validationLogic: {},
		label: "Your Phone number",
	},
	{
		type: "phoneNumber",
		elementName: "settingsUserMobileNo",
		validation: false,
		validationLogic: {},
		label: "Your Mobile number",
	},
	{
		elementName: "settingsUserSubscription",
		type: "pulldown",
		pullDownMenu: [
			{
				key: "Sub1",
				value: "Subscription 1",
			},
			{
				key: "Sub2",
				value: "Subscription 2",
			},
		],
		label: "Subscription options",
	},
	{
		type: "text",
		elementName: "settingsUserDeferredPaymentAccount",
		validation: false,
		validationLogic: {},
		label: "Your Deferred Payment Account (if any)",
		isMandatory: false,
		readOnly: true,
	},
	{
		elementName: "settingsUserCurrency",
		type: "pulldown",
		pullDownMenu: currencies,
		validation: false,
		validationLogic: {},
		label: "Default Currency",
	},
];

export default settings;
