import { axiosInstance } from "../index";
import config from "../config";
import { getHeaders } from "./helpers";

const { apiUrl } = config;

export const getNotifications = async ({ workspaceId, markAsRead }) => {
	const result = await axiosInstance.get(`${apiUrl}/notifications`, {
		headers: getHeaders(),
		params: { workspaceId, markAsRead },
	});

	return result.data.notifications;
};

export const getUnreadCount = async (workspaceId) => {
	const result = await axiosInstance.get(`${apiUrl}/notifications`, {
		headers: getHeaders(),
		params: { workspaceId, unreadCount: true },
	});

	return result.data.count;
};

export const readNotifications = async (ids, workspaceId) => {
	const result = await axiosInstance.put(
		`${apiUrl}/notifications/read`,
		{ notificationIds: ids, workspace: workspaceId },
		{
			headers: getHeaders(),
		}
	);

	return result.data;
};
