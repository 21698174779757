import { useContext } from "react";
import ThemeContext from "../../context/theme/ThemeContext";
import ErrorText from "./ErrorText";

const Input = ({ label, error, containerStyle, ...rest }) => {
	const { withTheme } = useContext(ThemeContext);

	return (
		<div style={{ textAlign: "left", ...containerStyle }}>
			{label && (
				<div style={{ marginBottom: 4 }} className={withTheme("input-label")}>
					{label}
				</div>
			)}
			<input {...rest} className={`${withTheme("input")} ${error ? withTheme("input-error") : ""}`} />

			{error && <ErrorText>{error}</ErrorText>}
		</div>
	);
};

export default Input;
