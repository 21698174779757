import Masonry from "react-masonry-component";
import InputsSection from "../../components/common/InputsSection/InputsSection";

const SectionsMasonry = ({ selectedItemIndex, sections }) => {
	return (
		<Masonry
			enableResizableChildren
			className="grid"
			options={{
				transitionDuration: 0,
				columnWidth: ".grid-sizer",
				itemSelector: ".grid-item",
				percentPosition: true,
			}}
		>
			<div className="grid-sizer"></div>
			{sections.map((section, index) => (
				<div
					key={index}
					className={section.halfWidth ? "grid-item" : "grid-item grid-item--width2"}
				>
					<InputsSection
						key={section.section}
						itemIndex={selectedItemIndex}
						{...section}
					/>
				</div>
			))}
		</Masonry>
	);
};

export default SectionsMasonry;
