import { useState } from "react";
import { PiInfo, PiInfoFill } from "react-icons/pi";
import Tooltip from "./Tooltip";

const HelpButton = (props) => {
	const { style, iconSize, helpText } = props;
	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<Tooltip text={helpText} onOpen={() => setIsOpen(true)} onClose={() => setIsOpen(false)}>
				<div style={{ cursor: "pointer", display: "flex", alignItems: "center", ...style }}>
					{isOpen ? (
						<PiInfoFill color="#2D5E41" size={iconSize || 16} />
					) : (
						<PiInfo size={iconSize || 16} />
					)}
				</div>
			</Tooltip>
		</>
	);
};

export default HelpButton;
