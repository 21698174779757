import { useContext, useMemo } from "react";
import ThemeContext from "../../../../../../../../context/theme/ThemeContext";
import countries from "../../../../../../../../constants/validation-pulldown-options/countries";
import validation from "../../../../../../../../constants/validation";

const GoodsLocationDrawerItem = ({ item }) => {
	const { withTheme } = useContext(ThemeContext);

	const country = useMemo(
		() =>
			countries.find((country) => country.key === item.data.locationOfGoodsCountry)?.value ||
			item.data.locationOfGoodsCountry,
		[item]
	);

	const type = useMemo(
		() =>
			validation
				.find(({ elementName }) => elementName === "locationOfGoodsType")
				?.pullDownMenu.find(({ key }) => key === item.data.locationOfGoodsType)?.value ||
			item.data.locationOfGoodsType,
		[item]
	);

	return (
		<div>
			<div className={withTheme("search-page-drawer-item-title")}>{country}</div>

			<div style={{ display: "flex", alignItems: "center" }}>
				<div style={{ display: "flex", alignItems: "center" }}>
					<div className={withTheme("item-property")}>Type:</div>
					<div style={{ fontSize: 12 }}>{type}</div>
				</div>
				<div style={{ marginLeft: 24, display: "flex", alignItems: "center" }}>
					<div className={withTheme("item-property")}>ID:</div>
					<div style={{ fontSize: 12 }}>{item.data.locationOfGoodsId}</div>
				</div>
			</div>
		</div>
	);
};

export default GoodsLocationDrawerItem;
