import SubscriptionSummary from "./Summary";
import SubscriptionUsage from "./Usage";

const SubscriptionTab = () => {

	// TODO: Add subscription options section and add contacts, goods locations and commodity codes subscription limiting
	return (
		<div style={{ textAlign: "left" }}>
			<SubscriptionSummary />
			<SubscriptionUsage />
		</div>
	);
};

export default SubscriptionTab;
