const commodityCodeTypes = [
    {
        "key": "DEFAULT",
        "value": "Please Select"
    },
    {
        "key": "CUS",
        "value": "CUS Code"
    },
    {
        "key": "GN",
        "value": "National Additional code"
    },
    {
        "key": "TRA",
        "value": "TARIC Additional code"
    },
    {
        "key": "TRC",
        "value": "TARIC code"
    },
    {
        "key": "TSP",
        "value": "Combined Nomenclature Code"
    }
]
export default commodityCodeTypes;