import { useContext, useEffect, useMemo, useState } from "react";
import ThemeContext from "../../../../../../../context/theme/ThemeContext";
import SelectedItemHeader from "./components/Header";
import { deleteContact, updateContact } from "../../../../../../../api/contacts";
import { deleteLocation, updateLocation } from "../../../../../../../api/goodsLocations";
import { deleteCommodityCode, updateCommodityCode } from "../../../../../../../api/commodity-codes";
import { useTranslation } from "react-i18next";
import SearchItemElement from "./components/SearchItemElement";
import { SEARCH_TYPES } from "../../../../../../../constants/GlobalConstants";

const CustomSectionPageSelectedItem = (props) => {
	const { item, searchType, onDelete, onUpdate, elements, isInModal, onBackClick } = props;
	const [inputValues, setInputValues] = useState({});
	const [errors, setErrors] = useState({});
	const { withTheme } = useContext(ThemeContext);
	const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);
	const { t } = useTranslation();

	const emptyPageMessage = useMemo(() => {
		if (searchType === SEARCH_TYPES.CONTACTS) {
			return t("contactNotSelectedMessage");
		} else if (searchType === SEARCH_TYPES.GOODS_LOCATIONS) {
			return t("locationNotSelectedMessage");
		} else if (searchType === SEARCH_TYPES.COMMODITY_CODES) {
			return t("comodityCodeNotSelectedMessage");
		}
	}, [item, searchType]);

	const handleSaveClick = async () => {
		let errors = {};

		elements.forEach((element) => {
			if (element.isRequired && !inputValues[element.name]) {
				errors[element.name] = t("required");
			}
		});

		setErrors(errors);
		if (Object.keys(errors).length) {
			return;
		}

		setIsSaveLoading(true);

		let updatedItem = null;

		try {
			if (searchType === SEARCH_TYPES.CONTACTS) {
				updatedItem = await updateContact(item._id, inputValues);
			} else if (searchType === SEARCH_TYPES.GOODS_LOCATIONS) {
				updatedItem = await updateLocation(item._id, inputValues);
			} else if (searchType === SEARCH_TYPES.COMMODITY_CODES) {
				updatedItem = await updateCommodityCode(item._id, inputValues);
			}

			onUpdate(updatedItem);
		} catch (e) {
			console.log(e);
		}

		setIsSaveLoading(false);
		setIsEditModeEnabled(!isEditModeEnabled);
	};

	const handleDeleteClick = async () => {
		setIsDeleteLoading(true);

		if (searchType === SEARCH_TYPES.CONTACTS) {
			await deleteContact(item._id);
		} else if (searchType === SEARCH_TYPES.GOODS_LOCATIONS) {
			await deleteLocation(item._id);
		} else if (searchType === SEARCH_TYPES.COMMODITY_CODES) {
			await deleteCommodityCode(item._id);
		}

		setIsDeleteLoading(false);
		onDelete(item);
	};

	const handleInputChange = (name, value) => {
		if (isSaveLoading) {
			return;
		}

		if (errors[name]) {
			delete errors[name];
			setErrors({ ...errors });
		}

		setInputValues({
			...inputValues,
			[name]: value,
		});
	};

	const handleDismissClick = () => {
		setIsEditModeEnabled(false);

		setInputValues({ ...item.data });
	};

	useEffect(() => {
		if (item) {
			setInputValues({ ...item.data });
		}
	}, [item]);

	if (!item) {
		return (
			<div style={{ paddingTop: 92 }} className={withTheme("empty-page-text")}>
				{emptyPageMessage}
			</div>
		);
	}

	return (
		<div style={{ width: "100%" }}>
			{!isInModal && item && (
				<SelectedItemHeader
					searchType={searchType}
					item={item}
					isSaveLoading={isSaveLoading}
					isDeleteLoading={isDeleteLoading}
					onDeleteClick={handleDeleteClick}
					onEditClick={() => setIsEditModeEnabled(!isEditModeEnabled)}
					onSaveClick={handleSaveClick}
					onDismissClick={handleDismissClick}
					onBackClick={onBackClick}
					isEditModeEnabled={isEditModeEnabled}
				/>
			)}

			<div style={{ display: "flex", flexWrap: "wrap", columnGap: 20 }}>
				{elements.map(({ name }, index) => (
					<SearchItemElement
						key={index}
						isEditModeEnabled={isEditModeEnabled}
						elementName={name}
						value={inputValues[name]}
						error={errors[name]}
						onInputChange={handleInputChange}
					/>
				))}
			</div>
		</div>
	);
};

export default CustomSectionPageSelectedItem;
