import { useContext } from "react";
import ExpandButton from "./SideNavBar/ExpandButton";
import Button from "../common/Button";
import { PiCaretDown, PiCaretUp, PiPlus } from "react-icons/pi";
import { useTranslation } from "react-i18next";
import ThemeContext from "../../context/theme/ThemeContext";

const Drawer = ({
	children,
	isExpanded,
	setIsExpanded,
	CollapsedIcon,
	sort,
	onSortChange,
	onAddClick,
	hideExpandButton,
	style,
	hideHeader,
}) => {
	const { withTheme } = useContext(ThemeContext);
	const { t } = useTranslation();

	return (
		<div
			className={`${withTheme("drawer")} ${!isExpanded ? withTheme("drawer-collapsed") : ""}`}
			style={style}
		>
			{!hideExpandButton && (
				<ExpandButton
					type="secondary"
					style={{ top: 20 }}
					isExpanded={isExpanded}
					onClick={() => setIsExpanded(!isExpanded)}
				/>
			)}

			{isExpanded ? (
				<>
					<div
						style={{
							width: "100%",
							overflowY: "auto",
							overflowX: "hidden",
							height: "100%",
						}}
					>
						<div style={{ minWidth: 280, height: "100%", overflowX: "hidden" }}>
							{!hideHeader && (
								<div className={withTheme("drawer-header")}>
									<Button
										onClick={() =>
											onSortChange(
												sort === "oldestFirst"
													? "latestFirst"
													: "oldestFirst"
											)
										}
										type="text"
									>
										{t(sort)}

										{sort === "latestFirst" ? (
											<PiCaretDown
												style={{
													marginLeft: 4,
												}}
											/>
										) : (
											<PiCaretUp
												style={{
													marginLeft: 4,
												}}
											/>
										)}
									</Button>

									<div
										style={{
											width: "fit-content",
											marginLeft: "auto",
										}}
									>
										<Button
											onClick={onAddClick}
											className={withTheme(
												"drawer-add"
											)}
										>
											<PiPlus
												style={{
													marginRight: 8,
												}}
											/>
											{t("addNew")}
										</Button>
									</div>
								</div>
							)}
							{children}
						</div>
					</div>
				</>
			) : (
				<div style={{ marginTop: 23 }}>
					<CollapsedIcon color="#2D5E41" fontSize="24px" />
				</div>
			)}
		</div>
	);
};

export default Drawer;
