import { useContext, useEffect, useRef, useState } from "react";
import DeclarationsContext from "../../../../context/declarations/DeclarationsContext";
import { getDeclarationLogs } from "../../../../api/declarations";
import Log from "./Log";
import LoadingSpinner from "../../../../components/common/LoadingSpinner";

const AuditLogs = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [logs, setLogs] = useState([]);
	const { state } = useContext(DeclarationsContext);
	const loadedDeclarationId = useRef(null);

	const loadLogs = async () => {
		if (!state.declaration._id || state.declaration._id === loadedDeclarationId.current) {
			return;
		}

		loadedDeclarationId.current = state.declaration._id;

		setIsLoading(true);

		const logs = await getDeclarationLogs(state.declaration._id);

		setLogs(logs);
		setIsLoading(false);
	};

	useEffect(() => {
		loadLogs();
	}, [state.declaration?._id]);

	return <>{isLoading ? <LoadingSpinner /> : logs.map((log, index) => <Log log={log} key={index} />)}</>;
};

export default AuditLogs;
