import React, { useContext, useMemo } from "react";
import ThemeContext from "../../../../../context/theme/ThemeContext";
import DeclarationsContext from "../../../../../context/declarations/DeclarationsContext";
import moment from "moment";
import DeclarationStatusTag from "./DeclarationStatusTag";
import ListItem, { ListItemSubTitle, ListItemTitle } from "../../../../common/ListItem";
import { getDeclarationLabel } from "../../../../../helpers";

const DeclarationListItem = (props) => {
	const { withTheme } = useContext(ThemeContext);
	const { state } = useContext(DeclarationsContext);
	const { onClick, declaration } = props;

	const isSelected = useMemo(
		() =>
			state.loadingDeclarationId
				? state.loadingDeclarationId === declaration._id
				: state.declaration?._id === declaration._id,
		[state.declaration, state.loadingDeclarationId, declaration]
	);

	const handleClick = (e) => {
		e.stopPropagation();
		onClick(declaration);
	};

	return (
		<ListItem onClick={handleClick} isSelected={isSelected}>
			{isSelected && <div className={withTheme("list-item-selected-highlight")}></div>}
			<div style={{ display: "flex", alignItems: "center", gap: 8 }}>
				<ListItemTitle>{getDeclarationLabel(declaration)}</ListItemTitle>

				<div style={{ width: "fit-content", marginLeft: "auto" }}>
					<DeclarationStatusTag declaration={declaration} />
				</div>
			</div>

			<div style={{ fontSize: 12 }}>
				<ListItemSubTitle style={{ fontSize: 12 }}>
					<span>{declaration.description}</span>
					<br />
					{moment(declaration.createdAt || Number(declaration.creationTime)).format(
						"DD MMM, hh:mm A"
					)}
				</ListItemSubTitle>
			</div>
		</ListItem>
	);
};

export default DeclarationListItem;
