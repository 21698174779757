import { useContext, useMemo } from "react";
import ThemeContext from "../../../context/theme/ThemeContext";
import { isArray, isObject, startCase } from "lodash";
import {
	getElementValue,
	isCurrencyObject,
	isDateObject,
} from "../../modals/DeclarationDetailsModal/utils";
import ElementLabel from "../ElementLabel";
import ArrayElement from "./ArrayElement";
import ObjectElement from "./ObjectElement";

const ReadOnlyElement = ({ label, data, element, style }) => {
	const { withTheme } = useContext(ThemeContext);

	const value = useMemo(() => getElementValue(data), [data]);

	if (isObject(data)) {
		if (isArray(data) && data.length) {
			return <ArrayElement label={label} data={data} element={element} />;
		} else if (!isDateObject(data) && !isCurrencyObject(data)) {
			return <ObjectElement label={label} data={data} element={element} />;
		}
	}

	if (!value) {
		return null;
	}
	return (
		<div id={element?.elementName} className={withTheme("item-element-container")} style={style}>
			<ElementLabel label={label ? startCase(label) : null} element={element} />
			<div className={withTheme("item-element-value")}>{value}</div>
		</div>
	);
};



export default ReadOnlyElement;
