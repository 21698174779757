import { useContext } from "react";
import { Overlay } from "react-bootstrap";
import ThemeContext from "../../../context/theme/ThemeContext";

const Popover = ({ isOpen, targetRef, children, style, onClose }) => {
	const { withTheme } = useContext(ThemeContext);

	return (
		<Overlay placement="bottom" rootClose={true} target={targetRef} show={isOpen} onHide={onClose}>
			{({ placement, arrowProps, show: _show, popper, ...props }) => (
				<div
					{...props}
					style={{ ...props.style, ...(style || {}) }}
					className={withTheme("overlay-body")}
				>
					{children}
				</div>
			)}
		</Overlay>
	);
};

export default Popover;
