import { useEffect, useState } from "react";
import CustomSectionDataPage from "../components/layouts/declarations/CustomSections/CustomSectionDataPage/CustomSectionDataPage";
import PageContainer from "../components/layouts/PageContainer";
import { useTranslation } from "react-i18next";
import { SEARCH_TYPES, SEARCH_TYPE_ELEMENTS } from "../constants/GlobalConstants";
import { seo } from "../helpers";

const CommodityCodes = () => {
	const [searchValue, setSearchValue] = useState("");
	const { t } = useTranslation();

	useEffect(() => {
		seo({
			title: t("commodityCodes"),
		});

		return () => {
			seo({});
		};
	}, []);

	return (
		<PageContainer title={t("commodityCodes")}>
			<CustomSectionDataPage
				searchType={SEARCH_TYPES.COMMODITY_CODES}
				sectionElements={SEARCH_TYPE_ELEMENTS.COMMODITY_CODES}
				initialSearchValue={searchValue}
				onSearchInputChange={(e) => setSearchValue(e.target.value)}
			/>
		</PageContainer>
	);
};

export default CommodityCodes;
