import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import Button from "../../../common/Button";
import { handleValidationErrorsView } from "../../../../context/declarations/validations";

const NotificationError = ({ error, onViewClick }) => {
	const { t } = useTranslation();

	const handleViewClick = () => {
		console.log(error);
		handleValidationErrorsView(error);
		onViewClick();
	};

	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				marginTop: 20,
				marginBottom: 20,
			}}
		>
			<div style={{ fontSize: 14 }}>
				<ReactMarkdown children={error.message} />
			</div>

			<div style={{ width: "fit-content", marginLeft: "auto" }}>
				<Button type="ghost" onClick={handleViewClick}>
					{t("view")}
				</Button>
			</div>
		</div>
	);
};

export default NotificationError;
