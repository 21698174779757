import { useContext } from "react";
import ThemeContext from "../../../context/theme/ThemeContext";
import { PiUserCircle } from "react-icons/pi";
import { lowerCase, startCase } from "lodash";

const UserInfo = (props) => {
	const { user, onClick } = props;
	const { withTheme } = useContext(ThemeContext);

	return (
		<div onClick={onClick} className={withTheme("nav-user-info")}>
			<PiUserCircle fontSize={24} />
			<div className={withTheme("nav-username")}>{startCase(lowerCase(user.name))}</div>

			{/* <div className={withTheme("nav-user-photo")}></div> */}
		</div>
	);
};

export default UserInfo;
