import { useContext } from "react";
import ThemeContext from "../../../context/theme/ThemeContext";
import LoadingSpinner from "../LoadingSpinner";

const PopoverOption = ({ onClick, isLoading, isDisabled, children }) => {
	const { withTheme } = useContext(ThemeContext);

	return (
		<div
			onClick={onClick}
			className={`${withTheme("popover-option")} ${
				isLoading || isDisabled ? withTheme("popover-option-disabled") : ""
			}`}
		>
			{children}

			{isLoading && (
				<div style={{ marginLeft: 12 }}>
					<LoadingSpinner size="sm" />
				</div>
			)}
		</div>
	);
};

export default PopoverOption;
