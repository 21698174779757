import { useContext, useState } from "react";
import ThemeContext from "../../context/theme/ThemeContext";
import { useTranslation } from "react-i18next";
import { PiBell, PiBellSlash } from "react-icons/pi";
import Button from "./Button";
import { LS_DISABLE_REQUEST_PERMISSION_KEY } from "../../constants/GlobalConstants";

const NotificationRequestPopup = ({ isOpen, onClose }) => {
	const [isDenied, setIsDenied] = useState(false);
	const { withTheme } = useContext(ThemeContext);
	const { t } = useTranslation();

	const handleCloseClick = () => {
		localStorage.setItem(LS_DISABLE_REQUEST_PERMISSION_KEY, true);
		onClose();
	};

	const handleAllowClick = async () => {
		const result = await Notification.requestPermission();

		if (result === "denied") {
			setIsDenied(true);
		} else {
			onClose();
		}
	};

	if (!isOpen) {
		return null;
	}

	const Icon = isDenied ? PiBellSlash : PiBell;

	return (
		<div className={withTheme("notification-request-popup")}>
			<div style={{ display: "flex", alignItems: "flex-start", gap: 20 }}>
				<div>
					<Icon fontSize="40px" className={withTheme("accent")} />
				</div>
				<div>
					<div style={{ fontSize: 16, fontWeight: 700 }}>
						{t(
							isDenied
								? "notificationRequestDeniedPopupTitle"
								: "notificationRequestPopupTitle"
						)}
					</div>
					<div style={{ fontSize: 14, marginTop: 4 }}>
						{t(
							isDenied
								? "notificationRequestDeniedPopupContent"
								: "notificationRequestPopupContent"
						)}
					</div>
				</div>
			</div>

			<div
				style={{
					display: "flex",
					marginTop: 20,
					gap: 8,
					width: "fit-content",
					marginLeft: "auto",
				}}
			>
				{isDenied ? (
					<Button onClick={onClose} style={{ minWidth: 180 }}>
						{t("ok")}
					</Button>
				) : (
					<>
						<Button onClick={handleCloseClick} type="secondary">
							{t("maybeLater")}
						</Button>
						<Button onClick={handleAllowClick}>{t("allowNotifications")}</Button>
					</>
				)}
			</div>
		</div>
	);
};

export default NotificationRequestPopup;
