import { Form } from "react-bootstrap";

const RegistrationForm = (props) => {
    const { onInputChange, errors, inputValues } = props;

    return (
        <div>
            <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    name='name'
                    onChange={onInputChange}
                    value={inputValues.name}
                    type="name" placeholder="Name"
                />
                <Form.Text style={{ color: 'red' }}>
                    {errors.name}
                </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                    name='email'
                    onChange={onInputChange}
                    value={inputValues.email}
                    type="email" placeholder="Enter email"
                />
                <Form.Text style={{ color: 'red' }}>
                    {errors.email}
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                    name='password'
                    onChange={onInputChange}
                    type="password"
                    placeholder="Enter Password"
                    value={inputValues.password}
                />
                <Form.Text style={{ color: 'red' }}>
                    {errors.password}
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                    name='confirmPassword'
                    onChange={onInputChange}
                    type="password"
                    value={inputValues.confirmPassword}
                    placeholder="Confirm Password"
                />
                <Form.Text style={{ color: 'red' }}>
                    {errors.confirmPassword}
                </Form.Text>
            </Form.Group>
        </div>
    )
};

export default RegistrationForm;