import { useContext } from "react";
import ValidationInput from "../../components/common/InputsSection/ValidationInput";
import settingsElements from "../../constants/settings";
import ThemeContext from "../../context/theme/ThemeContext";

const SettingsElement = ({ elementName, onChange, inputValues, errors }) => {
	const { withTheme } = useContext(ThemeContext);
	const element = settingsElements.find((element) => element.elementName === elementName);

	const error = errors?.[elementName];

	if (!element) {
		return null;
	}

	return (
		<div style={{ width: "calc(50% - 20px)", flexGrow: 1 }}>
			<ValidationInput
				onChange={onChange}
				value={inputValues[element.elementName] || element.defaultValue}
				element={element}
			/>

			<div
				style={{
					minHeight: error ? 10 : 0,
					maxHeight: error ? 10 : 0,
				}}
				className={withTheme("error-text")}
			>
				{error}
			</div>
		</div>
	);
};

export default SettingsElement;
