import { ListItemSubTitle, ListItemTitle } from "../../../../../../../common/ListItem";

const ContactDrawerItem = ({ item }) => {
	return (
		<div>
			<ListItemTitle style={{ fontWeight: 600 }}>{item.data.contactName}</ListItemTitle>

			<ListItemSubTitle style={{ display: "flex", alignItems: "center", gap: 12, marginTop: 4 }}>
				{item.data.contactCountry ? (
					<div style={{ fontSize: 12 }}>{item.data.contactCountry}</div>
				) : null}
				{item.data.contactCity ? (
					<div style={{ fontSize: 12 }}>{item.data.contactCity || "None"}</div>
				) : null}
			</ListItemSubTitle>
		</div>
	);
};

export default ContactDrawerItem;
