const tariffOffices = [
    {
        "key": "DEFAULT",
        "value": "Please Select"
    },
    {
        "key": "AD000001",
        "value": "CUSTOMS OFFICE SANT JULIÀ DE LÒRIA"
    },
    {
        "key": "AD000002",
        "value": "DCNJ PORTA"
    },
    {
        "key": "AD000003",
        "value": "CENTRAL CUSTOMS OFFICE"
    },
    {
        "key": "AT001000",
        "value": "Steuer- u. Zollkoordination Fachbereich Zoll u.Verbrauchsteuern"
    },
    {
        "key": "AT100000",
        "value": "Zollamt Wien"
    },
    {
        "key": "AT100200",
        "value": "Zollstelle Hafen Wien"
    },
    {
        "key": "AT100400",
        "value": "Zollstelle Inzersdorf"
    },
    {
        "key": "AT100900",
        "value": "Zollstelle Wien/Post"
    },
    {
        "key": "AT230000",
        "value": "Zollamt St. Pölten Krems Wiener Neustadt"
    },
    {
        "key": "AT230200",
        "value": "Zollstelle Mistelbach"
    },
    {
        "key": "AT240000",
        "value": "Zollamt St. Pölten Krems Wiener Neustadt"
    },
    {
        "key": "AT240100",
        "value": "Zollstelle Hafen Krems"
    },
    {
        "key": "AT240200",
        "value": "Zollstelle Amstetten"
    },
    {
        "key": "AT240400",
        "value": "Zollstelle Gmünd-Nagelberg"
    },
    {
        "key": "AT240500",
        "value": "Zollstelle Hollabrunn"
    },
    {
        "key": "AT250000",
        "value": "Zollamt St. Pölten Krems Wiener Neustadt"
    },
    {
        "key": "AT250100",
        "value": "Zollstelle Wiener Neudorf"
    },
    {
        "key": "AT250101",
        "value": "Zollstelle Wiener Neudorf Aussenstelle Abfertigung"
    },
    {
        "key": "AT320000",
        "value": "Zollamt Eisenstadt Flughafen Wien"
    },
    {
        "key": "AT320200",
        "value": "Zollstelle Eisenstadt/Strasse"
    },
    {
        "key": "AT320300",
        "value": "Zollstelle Nickelsdorf"
    },
    {
        "key": "AT320400",
        "value": "Zollstelle Heiligenkreuz"
    },
    {
        "key": "AT320600",
        "value": "Zollstelle Schachendorf"
    },
    {
        "key": "AT330000",
        "value": "Zollamt Eisenstadt Flughafen Wien"
    },
    {
        "key": "AT330100",
        "value": "Zollstelle Wien Flughafen Güterabfertigung"
    },
    {
        "key": "AT330200",
        "value": "Zollstelle Flughafen Wien Reisendenabfertigung"
    },
    {
        "key": "AT330300",
        "value": "Zollstelle Maria Lanzendorf"
    },
    {
        "key": "AT330400",
        "value": "Zollstelle Flughafen Wien Cargo Center Nord"
    },
    {
        "key": "AT420000",
        "value": "Zollamt Klagenfurt Villach"
    },
    {
        "key": "AT420100",
        "value": "Zollstelle Klagenfurt Flughafen/Strasse"
    },
    {
        "key": "AT430000",
        "value": "Zollamt Klagenfurt Villach"
    },
    {
        "key": "AT430100",
        "value": "Zollstelle Bahnhof Villach Süd"
    },
    {
        "key": "AT430200",
        "value": "Zollstelle Arnoldstein/Thörl-Maglern"
    },
    {
        "key": "AT520000",
        "value": "Zollamt Linz Wels"
    },
    {
        "key": "AT520100",
        "value": "Zollstelle Flughafen Linz"
    },
    {
        "key": "AT520200",
        "value": "Zollstelle Stadthafen Linz"
    },
    {
        "key": "AT520300",
        "value": "Zollstelle Hafen Enns"
    },
    {
        "key": "AT530000",
        "value": "Zollamt Linz Wels"
    },
    {
        "key": "AT530001",
        "value": "Außenstelle Schärding"
    },
    {
        "key": "AT530200",
        "value": "Zollstelle Suben"
    },
    {
        "key": "AT600000",
        "value": "Zollamt Salzburg"
    },
    {
        "key": "AT600200",
        "value": "Zollstelle Flughafen Salzburg"
    },
    {
        "key": "AT600201",
        "value": "Zollstelle Flughafen Salzburg Reisendenabfertigung"
    },
    {
        "key": "AT600300",
        "value": "Zollstelle Zollstelle Güterverkehr Straße"
    },
    {
        "key": "AT600400",
        "value": "Zollstelle Liefering/Bahn"
    },
    {
        "key": "AT700000",
        "value": "Zollamt Graz"
    },
    {
        "key": "AT700100",
        "value": "Zollstelle Graz Flughafen"
    },
    {
        "key": "AT700200",
        "value": "Zollstelle Containerterminal Werndorf"
    },
    {
        "key": "AT700300",
        "value": "Zollstelle Leoben"
    },
    {
        "key": "AT700400",
        "value": "Zollstelle Spielfeld"
    },
    {
        "key": "AT800000",
        "value": "Zollamt Innsbruck"
    },
    {
        "key": "AT800200",
        "value": "Zollstelle Flughafen Innsbruck"
    },
    {
        "key": "AT800300",
        "value": "Zollstelle Freilager Hall"
    },
    {
        "key": "AT800400",
        "value": "Zollstelle Reutte"
    },
    {
        "key": "AT800600",
        "value": "Zollstelle Lienz"
    },
    {
        "key": "AT800700",
        "value": "Zollstelle Kufstein"
    },
    {
        "key": "AT800800",
        "value": "Zollstelle Landeck"
    },
    {
        "key": "AT800900",
        "value": "Zollstelle Martinsbruck"
    },
    {
        "key": "AT801000",
        "value": "Zollstelle Pfunds"
    },
    {
        "key": "AT801100",
        "value": "Zollstelle Spiss"
    },
    {
        "key": "AT920000",
        "value": "Zollamt Feldkirch Wolfurt"
    },
    {
        "key": "AT920100",
        "value": "Zollstelle Buchs/Bahnhof"
    },
    {
        "key": "AT920400",
        "value": "Zollstelle Tisis"
    },
    {
        "key": "AT920700",
        "value": "Zollstelle Meiningen"
    },
    {
        "key": "AT920900",
        "value": "Zollstelle Mäder"
    },
    {
        "key": "AT930000",
        "value": "Zollamt Feldkirch Wolfurt"
    },
    {
        "key": "AT930100",
        "value": "Zollstelle Wolfurt Post"
    },
    {
        "key": "AT930101",
        "value": "Zollstelle Wolfurt/Post Aussenstelle Selbstverzollung"
    },
    {
        "key": "AT930200",
        "value": "Zollstelle Hohenems"
    },
    {
        "key": "AT930300",
        "value": "Zollstelle Lustenau"
    },
    {
        "key": "AT930400",
        "value": "Zollstelle Schmitterbrücke"
    },
    {
        "key": "AT930600",
        "value": "Zollstelle Höchst"
    },
    {
        "key": "AT930700",
        "value": "Zollstelle Hard"
    },
    {
        "key": "AT930800",
        "value": "Zollstelle Seehafen Bregenz"
    },
    {
        "key": "AT930900",
        "value": "Zollstelle St. Margrethen"
    },
    {
        "key": "BE000001",
        "value": "HAUPTZOLLAMT BRUSSEL - ZENTRAL DIREKTION"
    },
    {
        "key": "BE000011",
        "value": "OPSCC"
    },
    {
        "key": "BE000100",
        "value": "Bureau Unique des Douanes & Accises"
    },
    {
        "key": "BE100000",
        "value": "hauptzollamt ANTWERPEN (REGIONALDIREKTION)"
    },
    {
        "key": "BE100011",
        "value": "Regionale Component KLAMA Antwerpen"
    },
    {
        "key": "BE101000",
        "value": "ZOLLAMT ANTWERPEN DAE"
    },
    {
        "key": "BE109000",
        "value": "zollamt MECHELEN DAE"
    },
    {
        "key": "BE200000",
        "value": "hauptzollamt BRUSSEL (REGIONALDIREKTION)"
    },
    {
        "key": "BE200011",
        "value": "Composante Rég. KLAMA Bruxelles"
    },
    {
        "key": "BE204000",
        "value": "ZOLLAMT BRUXELLES DE"
    },
    {
        "key": "BE210000",
        "value": "zollamt VILVOORDE DAE"
    },
    {
        "key": "BE211000",
        "value": "zollamt OTTIGNIES ZA"
    },
    {
        "key": "BE212000",
        "value": "zollamt ZAVENTEM D"
    },
    {
        "key": "BE300000",
        "value": "hauptzollamt GENT (REGIONALDIREKTION)"
    },
    {
        "key": "BE300011",
        "value": "Regionale Component KLAMA Gent"
    },
    {
        "key": "BE301000",
        "value": "zollamt AALST DAE - Industriezone Noord V"
    },
    {
        "key": "BE312000",
        "value": "zollamt GENT DAE"
    },
    {
        "key": "BE328000",
        "value": "ZOLLAMT OOSTENDE DAE"
    },
    {
        "key": "BE343000",
        "value": "zollamt ZEEBRUGGE D"
    },
    {
        "key": "BE349000",
        "value": "zollamt MENEN-LAR DAE"
    },
    {
        "key": "BE400000",
        "value": "hauptzollamt HASSELT (REGIONALDIREKTION)"
    },
    {
        "key": "BE400011",
        "value": "Regionale Component KLAMA Hasselt"
    },
    {
        "key": "BE408000",
        "value": "zollamt HASSELT DA"
    },
    {
        "key": "BE424000",
        "value": "ZOLLAMT TIENEN DAE"
    },
    {
        "key": "BE432000",
        "value": "zollamt GEEL DAE"
    },
    {
        "key": "BE500000",
        "value": "hauptzollamt LIEGE (REGIONALDIREKTION)"
    },
    {
        "key": "BE500011",
        "value": "KLAMA - BEWILLIGUNGEN - Lüttich"
    },
    {
        "key": "BE501000",
        "value": "zollamt ARLON DA"
    },
    {
        "key": "BE514000",
        "value": "zollamt EYNATTEN (RAEREN) Z"
    },
    {
        "key": "BE531000",
        "value": "ZOLLAMT LIEGE (MONSIN)"
    },
    {
        "key": "BE532000",
        "value": "zollamt BIERSET (GRACE-HOLLOGNE) DE"
    },
    {
        "key": "BE600000",
        "value": "hauptzollamt MONS (REGIONALDIREKTION)"
    },
    {
        "key": "BE600011",
        "value": "Composante Regionale KLAMA Mons"
    },
    {
        "key": "BE632000",
        "value": "zollamt LA LOUVIERE DAE"
    },
    {
        "key": "BE648000",
        "value": "zollamt NAMUR DA"
    },
    {
        "key": "BE662000",
        "value": "zollamt RISQUONS-TOUT (MOUSCRON) DAE"
    },
    {
        "key": "BE663000",
        "value": "zollamt SHAPE (CASTEAU)"
    },
    {
        "key": "BE680000",
        "value": "zollamt GOSSELIES (CHARLEROI) DAE"
    },
    {
        "key": "BE683000",
        "value": "zollamt CHIEVRES (C.A.B.)"
    },
    {
        "key": "BE700011",
        "value": "Regionale Component KLAMA Leuven"
    },
    {
        "key": "BE800000",
        "value": "Enquête et Recherche"
    },
    {
        "key": "BE800001",
        "value": "E&R Anvers"
    },
    {
        "key": "BE800002",
        "value": "E&R Bruxelles"
    },
    {
        "key": "BE800003",
        "value": "E&R Gand"
    },
    {
        "key": "BE800004",
        "value": "E&R Liège"
    },
    {
        "key": "BE800005",
        "value": "E&R Mons"
    },
    {
        "key": "BG001000",
        "value": "Териториално управление"
    },
    {
        "key": "BG001002",
        "value": "МП Летище Бургас"
    },
    {
        "key": "BG001003",
        "value": "МП М.Търново"
    },
    {
        "key": "BG001005",
        "value": "МБ Нефтохимически комбинат"
    },
    {
        "key": "BG001007",
        "value": "МП Пристанище Бургас център"
    },
    {
        "key": "BG001008",
        "value": "МБ Свободна Зона"
    },
    {
        "key": "BG001009",
        "value": "МБ Сливен"
    },
    {
        "key": "BG001010",
        "value": "МБ Ямбол"
    },
    {
        "key": "BG001011",
        "value": "МП Лесово"
    },
    {
        "key": "BG001012",
        "value": "МП Царево"
    },
    {
        "key": "BG001013",
        "value": "МП Нефтопристанище"
    },
    {
        "key": "BG001014",
        "value": "МП Ж.П. Гара Свиленград"
    },
    {
        "key": "BG001015",
        "value": "МП Капитан Андреево"
    },
    {
        "key": "BG002000",
        "value": "Териториално управление"
    },
    {
        "key": "BG002002",
        "value": "МП Пристанище Варна-Запад"
    },
    {
        "key": "BG002003",
        "value": "МП Летище Варна"
    },
    {
        "key": "BG002005",
        "value": "МП Пристанище Варна"
    },
    {
        "key": "BG002006",
        "value": "МП Пристанище Леспорт"
    },
    {
        "key": "BG002007",
        "value": "МП Варна-Ферибот"
    },
    {
        "key": "BG002008",
        "value": "МБ Шумен"
    },
    {
        "key": "BG002009",
        "value": "МБ Добрич"
    },
    {
        "key": "BG002010",
        "value": "МП Балчик"
    },
    {
        "key": "BG003000",
        "value": "Териториално управление"
    },
    {
        "key": "BG003001",
        "value": "МБ Казанлък"
    },
    {
        "key": "BG003002",
        "value": "МП Летище Пловдив"
    },
    {
        "key": "BG003004",
        "value": "МБ Пазарджик"
    },
    {
        "key": "BG003005",
        "value": "МБ Свободна зона Пловдив"
    },
    {
        "key": "BG003006",
        "value": "МБ Смолян"
    },
    {
        "key": "BG003007",
        "value": "МБ Карлово"
    },
    {
        "key": "BG003008",
        "value": "МБ Стара Загора"
    },
    {
        "key": "BG003009",
        "value": "МБ Радиново"
    },
    {
        "key": "BG003010",
        "value": "МБ Свиленград"
    },
    {
        "key": "BG003011",
        "value": "МБ Кърджали"
    },
    {
        "key": "BG003012",
        "value": "МБ Хасково"
    },
    {
        "key": "BG004000",
        "value": "Териториално управление"
    },
    {
        "key": "BG004002",
        "value": "МБ Разград"
    },
    {
        "key": "BG004006",
        "value": "МП Пристанище"
    },
    {
        "key": "BG004011",
        "value": "МБ Търговище"
    },
    {
        "key": "BG004014",
        "value": "МП силистра"
    },
    {
        "key": "BG004201",
        "value": "МБ Враца"
    },
    {
        "key": "BG004203",
        "value": "МП Оряхово"
    },
    {
        "key": "BG004205",
        "value": "МП Пристанище Лом"
    },
    {
        "key": "BG004210",
        "value": "МБ Монтана"
    },
    {
        "key": "BG004211",
        "value": "МБ Видин"
    },
    {
        "key": "BG004212",
        "value": "МП Брегово"
    },
    {
        "key": "BG004213",
        "value": "МП Връшка чука"
    },
    {
        "key": "BG004300",
        "value": "МП Пристанище Свищов"
    },
    {
        "key": "BG004301",
        "value": "МБ Габрово"
    },
    {
        "key": "BG004303",
        "value": "МП Летище Горна Оряховица"
    },
    {
        "key": "BG004304",
        "value": "МБ Ловеч"
    },
    {
        "key": "BG004306",
        "value": "МБ Плевен"
    },
    {
        "key": "BG004307",
        "value": "МП Сомовит"
    },
    {
        "key": "BG004309",
        "value": "МБ Севлиево"
    },
    {
        "key": "BG004311",
        "value": "МБ Троян"
    },
    {
        "key": "BG005100",
        "value": "МБ Летище София"
    },
    {
        "key": "BG005106",
        "value": "МП Летище София-пътници"
    },
    {
        "key": "BG005107",
        "value": "МП Аерогара София - товари"
    },
    {
        "key": "BG005700",
        "value": "МБ Благоевград"
    },
    {
        "key": "BG005701",
        "value": "МБ Гоце Делчев"
    },
    {
        "key": "BG005702",
        "value": "МП Гюешево"
    },
    {
        "key": "BG005703",
        "value": "МП Златарево"
    },
    {
        "key": "BG005704",
        "value": "МБ Кулата"
    },
    {
        "key": "BG005705",
        "value": "МБ Кюстендил"
    },
    {
        "key": "BG005706",
        "value": "МП Логодаж"
    },
    {
        "key": "BG005707",
        "value": "МП Олтоманци"
    },
    {
        "key": "BG005800",
        "value": "Териториално управление"
    },
    {
        "key": "BG005801",
        "value": "МБ Ботевград"
    },
    {
        "key": "BG005803",
        "value": "МБ Драгоман"
    },
    {
        "key": "BG005804",
        "value": "МП Калотина"
    },
    {
        "key": "BG005805",
        "value": "МБ Перник"
    },
    {
        "key": "BG005807",
        "value": "МБ София-Запад"
    },
    {
        "key": "BG005808",
        "value": "МБ София-Изток"
    },
    {
        "key": "BG005809",
        "value": "МП Стрезимировци"
    },
    {
        "key": "BG005810",
        "value": "МБ БРСЦ"
    },
    {
        "key": "BG005811",
        "value": "МБ Слатина"
    },
    {
        "key": "BG009999",
        "value": "Централно митническо управление"
    },
    {
        "key": "CH001001",
        "value": "Zollinspektorat Aarau Zentralstelle gVV"
    },
    {
        "key": "CH001004",
        "value": "Sektion Tarif und Veranlagung D I"
    },
    {
        "key": "CH001141",
        "value": "Inspectorat de douane Inspection de douane Boncourt-Delle"
    },
    {
        "key": "CH001251",
        "value": "Zollinspektorat Basel St. Jakob DA Wolf EVO"
    },
    {
        "key": "CH001252",
        "value": "Zollinspektorat Basel St. Jakob DA Wolf Konv."
    },
    {
        "key": "CH001253",
        "value": "Zollamt Basel St. Jakob DA Wolf Kurier"
    },
    {
        "key": "CH001454",
        "value": "Zollamt Basel St. Jakob Messe"
    },
    {
        "key": "CH001471",
        "value": "Zollamt Basel St. Jakob DA Rheinhäfen"
    },
    {
        "key": "CH001501",
        "value": "Zollamt RIEHEN"
    },
    {
        "key": "CH001551",
        "value": "Zollamt Riehen/Grenzacherstrasse"
    },
    {
        "key": "CH001571",
        "value": "Zollamt Basel St. Jakob UBF"
    },
    {
        "key": "CH001591",
        "value": "Zollamt Zollamt Stein/Bad Säckingen"
    },
    {
        "key": "CH001601",
        "value": "Zollamt Laufenburg"
    },
    {
        "key": "CH001611",
        "value": "Zollamt RHEINFELDEN-AB-DA MOEHLIN"
    },
    {
        "key": "CH001631",
        "value": "Zollamt AARAU"
    },
    {
        "key": "CH001651",
        "value": "Zollinspektorat AARAU DA LUZERN"
    },
    {
        "key": "CH001661",
        "value": "Zollinspektorat AARAU DA BERN"
    },
    {
        "key": "CH001671",
        "value": "Zollinspektorat AARAU DA BELP"
    },
    {
        "key": "CH001711",
        "value": "Zollamt BASEL-MÜLHAUSEN FLUGHAFEN FRACHT"
    },
    {
        "key": "CH001712",
        "value": "Zollamt Basel-Mülhausen Flughafen Kurier"
    },
    {
        "key": "CH001731",
        "value": "Zollamt Pratteln"
    },
    {
        "key": "CH001801",
        "value": "Zollamt BASEL/WEIL AM RHEIN-AUTOBAHN"
    },
    {
        "key": "CH001841",
        "value": "Zollamt BASEL/ST.LOUIS AUTOBAHN"
    },
    {
        "key": "CH001842",
        "value": "Zollamt Basel/St.Louis-Autobahn EVO"
    },
    {
        "key": "CH001921",
        "value": "Zollamt Zollamt Rheinfelden-Autobahn"
    },
    {
        "key": "CH002001",
        "value": "Suchzentrale D II"
    },
    {
        "key": "CH002003",
        "value": "Sektion Tarif und Veranlagung D II"
    },
    {
        "key": "CH002041",
        "value": "Zollamt ZI Rheinfelden Autobahn /DA Koblenz"
    },
    {
        "key": "CH002091",
        "value": "Zollamt SCHAFFHAUSEN NZA TRASADINGEN"
    },
    {
        "key": "CH002151",
        "value": "Zollamt Grenzübergang SCHLEITHEIM"
    },
    {
        "key": "CH002171",
        "value": "Zollamt SCHAFFHAUSEN DA SCHAFFHAUSEN"
    },
    {
        "key": "CH002261",
        "value": "Zollamt SCHAFFHAUSEN NZS RAFZ-SOLGEN"
    },
    {
        "key": "CH002291",
        "value": "Zollamt SCHAFFHAUSEN NZS NEUHAUSEN"
    },
    {
        "key": "CH002311",
        "value": "Zollamt SCHAFFHAUSEN DA BARGEN"
    },
    {
        "key": "CH002411",
        "value": "Zollamt SCHAFFHAUSEN DA RAMSEN"
    },
    {
        "key": "CH002471",
        "value": "Zollamt SCHAFFHAUSEN DA THAYNGEN"
    },
    {
        "key": "CH002621",
        "value": "Zollinspektorat KREUZLINGEN AUTOBAHN"
    },
    {
        "key": "CH002671",
        "value": "Zollamt ROMANSHORN"
    },
    {
        "key": "CH002711",
        "value": "Zollamt EMBRACH-FREILAGER"
    },
    {
        "key": "CH002751",
        "value": "Zollamt ZS Zürich Flughafen- Messeabteilung"
    },
    {
        "key": "CH002755",
        "value": "Zürich (Messezollamt)"
    },
    {
        "key": "CH002756",
        "value": "Zollamt Zürich DA Post"
    },
    {
        "key": "CH002771",
        "value": "Zollamt Zürich Flughafen"
    },
    {
        "key": "CH003001",
        "value": "Centre Recherches TC DIII Inspection de douane Genève-Routes"
    },
    {
        "key": "CH003011",
        "value": "Zollamt St. Gallen"
    },
    {
        "key": "CH003031",
        "value": "ST. MARGRETHEN DA WOLFURT"
    },
    {
        "key": "CH003041",
        "value": "ZOLLAMT ALTENRHEIN-AIRPORT"
    },
    {
        "key": "CH003071",
        "value": "Zollamt ST. MARGRETHEN DA STRASSE"
    },
    {
        "key": "CH003081",
        "value": "Zollamt ST. MARGRETHEN DA AU"
    },
    {
        "key": "CH003091",
        "value": "Zollamt ST. MARGRETHEN DA FREILAGER"
    },
    {
        "key": "CH003121",
        "value": "ZOLLAMT KRIESSERN"
    },
    {
        "key": "CH003140",
        "value": "Zollamt Buchs SG"
    },
    {
        "key": "CH003151",
        "value": "Zollamt OBERRIET"
    },
    {
        "key": "CH003201",
        "value": "Zollamt SCHAANWALD"
    },
    {
        "key": "CH003261",
        "value": "Zollamt MARTINA"
    },
    {
        "key": "CH003301",
        "value": "Zollamt MÜSTAIR"
    },
    {
        "key": "CH003331",
        "value": "Ufficio doganale LA DROSSA"
    },
    {
        "key": "CH003361",
        "value": "Ufficio doganale CAMPOCOLOGNO"
    },
    {
        "key": "CH003401",
        "value": "Ufficio doganale CASTASEGNA"
    },
    {
        "key": "CH003451",
        "value": "Zollamt DIEPOLDSAU"
    },
    {
        "key": "CH004001",
        "value": "Centrale PTC D IV Centrale PTC DIV"
    },
    {
        "key": "CH004002",
        "value": "Sezione Tariffa e regimi D IV"
    },
    {
        "key": "CH004011",
        "value": "Ufficio doganale VEDEGGIO"
    },
    {
        "key": "CH004031",
        "value": "Ufficio doganale Vedeggio SD Gandria"
    },
    {
        "key": "CH004101",
        "value": "Ufficio doganale Vedeggio SD Ponte Tresa"
    },
    {
        "key": "CH004131",
        "value": "Ufficio Doganale Vedeggio SD Agno Aeroporto"
    },
    {
        "key": "CH004162",
        "value": "Ufficio doganale Mendrisiotto"
    },
    {
        "key": "CH004163",
        "value": "Ufficio doganale Mendrisiotto SD Confine"
    },
    {
        "key": "CH004164",
        "value": "Ufficio doganale Mendrisiotto SD DFD-SM"
    },
    {
        "key": "CH004181",
        "value": "Ufficio doganale CHIASSO-STRADA"
    },
    {
        "key": "CH004182",
        "value": "Ufficio doganale Chiasso SD Brodega Autostrada"
    },
    {
        "key": "CH004183",
        "value": "Ufficio doganale Chiasso SD Chiasso Viaggiatori"
    },
    {
        "key": "CH004421",
        "value": "Ufficio doganale Vedeggio SD Madonna di Ponte"
    },
    {
        "key": "CH004471",
        "value": "Uffico doganale UDS Dirinella"
    },
    {
        "key": "CH004491",
        "value": "Ufficio doganale Vedeggio SD Luino (CH)"
    },
    {
        "key": "CH005031",
        "value": "Bureau de douane CHAVORNAY SUBD CHAVORNAY PORT FRANC"
    },
    {
        "key": "CH005040",
        "value": "Zollinspektorat Brig - DA Domo 2"
    },
    {
        "key": "CH005051",
        "value": "Zollamt BRIG"
    },
    {
        "key": "CH005081",
        "value": "Zollamt GONDO"
    },
    {
        "key": "CH005121",
        "value": "Bureau de douane ST. GINGOLPH"
    },
    {
        "key": "CH005211",
        "value": "Bureau de douane VEVEY PORT-FRANC"
    },
    {
        "key": "CH005441",
        "value": "Bureau de douane CHAVORNAY SUBD VALLORBE"
    },
    {
        "key": "CH005491",
        "value": "Bureau de douane CHAVORNAY SUBD LES VERRIERES"
    },
    {
        "key": "CH005551",
        "value": "Bureau de douane CHAVORNAY SUBD LE LOCLE"
    },
    {
        "key": "CH005561",
        "value": "Bureau de douane CHAVORNAY SUBD COL FRANCE"
    },
    {
        "key": "CH005691",
        "value": "Bureau de douane MARTIGNY"
    },
    {
        "key": "CH005701",
        "value": "Bureau de douane MARTIGNY-GRAND-ST. BERNARD-TUNNEL"
    },
    {
        "key": "CH006002",
        "value": "Section Tarif et Régimes D III"
    },
    {
        "key": "CH006021",
        "value": "Bureau de douane GENEVE ROUTES SD PORT FRANC"
    },
    {
        "key": "CH006221",
        "value": "Inspection de douane GENEVE-ROUTES SD THONEX-VALLARD"
    },
    {
        "key": "CH006251",
        "value": "Inspection de douane GENEVE-ROUTES SD BARDONNEX"
    },
    {
        "key": "CH006451",
        "value": "Inspection de douane GENEVE-ROUTES SD FERNEY-VOLTAIRE"
    },
    {
        "key": "CH006521",
        "value": "Bureau de douane GENEVE AEROPORT"
    },
    {
        "key": "CH009002",
        "value": "Oberzolldirektion OBERZOLLDIREKTION"
    },
    {
        "key": "CH009003",
        "value": "Oberzolldirektion OBERZOLLDIREKTION SEKTION FIRE"
    },
    {
        "key": "CH009007",
        "value": "OBERZOLLDIREKTION S Ursprung"
    },
    {
        "key": "CY000100",
        "value": "Αρχιτελωνείο"
    },
    {
        "key": "CY000130",
        "value": "NICOSIA CUSTOMS"
    },
    {
        "key": "CY000150",
        "value": "ΤΑΧΥΔΡΟΜΙΚΟ ΓΡΑΦΕΙΟ ΛΕΥΚΩΣΙΑΣ"
    },
    {
        "key": "CY000410",
        "value": "LARNACA PORT"
    },
    {
        "key": "CY000412",
        "value": "VASILICO-ZYGI"
    },
    {
        "key": "CY000431",
        "value": "LARNACA FREE ZONE"
    },
    {
        "key": "CY000440",
        "value": "LARNACA AIRPORT"
    },
    {
        "key": "CY000450",
        "value": "ΤΑΧΥΔΡΟΜΙΚΟ ΓΡΑΦΕΙΟ ΛΑΡΝΑΚΑΣ"
    },
    {
        "key": "CY000510",
        "value": "LIMASSOL PORT"
    },
    {
        "key": "CY000514",
        "value": "MONI"
    },
    {
        "key": "CY000550",
        "value": "ΤΑΧΥΔΡΟΜΙΚΟ ΓΡΑΦΕΙΟ ΔΕΜΑΤΩΝ ΛΕΜΕΣΟΣ"
    },
    {
        "key": "CY000610",
        "value": "PAPHOS CUSTOMS"
    },
    {
        "key": "CY000640",
        "value": "PAPHOS AIRPORT"
    },
    {
        "key": "CY000650",
        "value": "ΤΑΧΥΔΡΟΜΙΚΟ ΓΡΑΦΕΙΟ ΔΕΜΑΤΩΝ ΠΑΦΟΣ"
    },
    {
        "key": "CZ510000",
        "value": "Celní úřad pro hlavní město Prahu, Praha 1"
    },
    {
        "key": "CZ510100",
        "value": "Odbor CÚ Právní, Praha 1"
    },
    {
        "key": "CZ510101",
        "value": "Oddělení CÚ Právních a správních činností, Prah"
    },
    {
        "key": "CZ510200",
        "value": "Odbor CÚ Celní, Praha 10"
    },
    {
        "key": "CZ510201",
        "value": "Oddělení CÚ Celní, Praha 10"
    },
    {
        "key": "CZ510202",
        "value": "Oddělení CÚ Celní, Praha 10"
    },
    {
        "key": "CZ510300",
        "value": "Odbor CÚ Daňový, Praha 1"
    },
    {
        "key": "CZ510301",
        "value": "Oddělení CÚ Daňové, Praha 1"
    },
    {
        "key": "CZ510302",
        "value": "Oddělení CÚ Daňové, Praha 1"
    },
    {
        "key": "CZ510303",
        "value": "Oddělení CÚ Daňové, Praha 1"
    },
    {
        "key": "CZ520000",
        "value": "Celní úřad pro Jihočeský kraj, České Budějovic"
    },
    {
        "key": "CZ520009",
        "value": "Oddělení CÚ AEO, APEO a EORI, České Budějovice"
    },
    {
        "key": "CZ520100",
        "value": "Odbor CÚ Právní, České Budějovice"
    },
    {
        "key": "CZ520101",
        "value": "Oddělení CÚ Právních a správních činností, Česk"
    },
    {
        "key": "CZ520200",
        "value": "Odbor CÚ Celní, České Budějovice"
    },
    {
        "key": "CZ520201",
        "value": "Oddělení CÚ Celní, České Budějovice"
    },
    {
        "key": "CZ520202",
        "value": "Oddělení CÚ Celní, Strakonice"
    },
    {
        "key": "CZ520203",
        "value": "Oddělení CÚ Celní, Tábor"
    },
    {
        "key": "CZ520300",
        "value": "Odbor CÚ Daňový, České Budějovice"
    },
    {
        "key": "CZ520301",
        "value": "Oddělení CÚ Daňové, České Budějovice"
    },
    {
        "key": "CZ520302",
        "value": "Oddělení CÚ Daňové, Jindřichův Hradec"
    },
    {
        "key": "CZ520303",
        "value": "Oddělení CÚ Daňové, Strakonice"
    },
    {
        "key": "CZ520304",
        "value": "Oddělení CÚ Daňové, Tábor"
    },
    {
        "key": "CZ530000",
        "value": "Celní úřad pro Jihomoravský kraj, Brno"
    },
    {
        "key": "CZ530100",
        "value": "Odbor CÚ Právní, Brno-Slatina"
    },
    {
        "key": "CZ530101",
        "value": "Oddělení CÚ Právních a správních činností, Brno"
    },
    {
        "key": "CZ530200",
        "value": "Odbor CÚ Celní, Brno-Slatina"
    },
    {
        "key": "CZ530201",
        "value": "Oddělení CÚ Celní, Brno-Slatina"
    },
    {
        "key": "CZ530202",
        "value": "Oddělení CÚ Celní, Blansko"
    },
    {
        "key": "CZ530203",
        "value": "Oddělení CÚ Celní, Hodonín"
    },
    {
        "key": "CZ530204",
        "value": "Oddělení CÚ Celní, Lanžhot"
    },
    {
        "key": "CZ530220",
        "value": "Oddělení CÚ Podpory celního řízení, Brno - Slat"
    },
    {
        "key": "CZ530299",
        "value": "Oddělení CÚ Letiště Tuřany, Brno-Tuřany"
    },
    {
        "key": "CZ530300",
        "value": "Odbor CÚ Daňový, Brno-Slatina"
    },
    {
        "key": "CZ530301",
        "value": "Oddělení CÚ Daňové, Brno-Slatina"
    },
    {
        "key": "CZ530302",
        "value": "Oddělení CÚ Daňové, Blansko"
    },
    {
        "key": "CZ530303",
        "value": "Oddělení CÚ Daňové, Břeclav"
    },
    {
        "key": "CZ530304",
        "value": "Oddělení CÚ Daňové, Vyškov"
    },
    {
        "key": "CZ530305",
        "value": "Oddělení CÚ Daňové, Hodonín"
    },
    {
        "key": "CZ530306",
        "value": "Oddělení CÚ Daňové, Znojmo"
    },
    {
        "key": "CZ530307",
        "value": "Oddělení CÚ Daňové, Brno - Slatina"
    },
    {
        "key": "CZ540000",
        "value": "Celní úřad pro Karlovarský kraj, Karlovy Vary"
    },
    {
        "key": "CZ540100",
        "value": "Odbor CÚ Právní, Karlovy Vary"
    },
    {
        "key": "CZ540101",
        "value": "Oddělení CÚ Právních a správních činností, Karl"
    },
    {
        "key": "CZ540200",
        "value": "Odbor CÚ Celní, Karlovy Vary"
    },
    {
        "key": "CZ540201",
        "value": "Oddělení CÚ Celní, Karlovy Vary"
    },
    {
        "key": "CZ540202",
        "value": "Oddělení CÚ Celní, Cheb"
    },
    {
        "key": "CZ540299",
        "value": "Oddělení CÚ Letiště, Karlovy Vary"
    },
    {
        "key": "CZ540300",
        "value": "Odbor CÚ Daňový, Karlovy Vary"
    },
    {
        "key": "CZ540301",
        "value": "Oddělení CÚ Daňové, Karlovy Vary"
    },
    {
        "key": "CZ540302",
        "value": "Oddělení CÚ Daňové, Cheb"
    },
    {
        "key": "CZ550000",
        "value": "Celní úřad pro Královéhradecký kraj, Hradec Kr"
    },
    {
        "key": "CZ550100",
        "value": "Odbor CÚ Právní, Hradec Králové"
    },
    {
        "key": "CZ550101",
        "value": "Oddělení CÚ Právních a správních činností, Hrad"
    },
    {
        "key": "CZ550200",
        "value": "Odbor CÚ Celní, Hradec Králové"
    },
    {
        "key": "CZ550201",
        "value": "Oddělení CÚ Celní, Hradec Králové"
    },
    {
        "key": "CZ550202",
        "value": "Oddělení CÚ Celní, Jičín"
    },
    {
        "key": "CZ550203",
        "value": "Oddělení CÚ Celní, Náchod"
    },
    {
        "key": "CZ550300",
        "value": "Odbor CÚ Daňový, Hradec Králové"
    },
    {
        "key": "CZ550301",
        "value": "Oddělení CÚ Daňové, Hradec Králové"
    },
    {
        "key": "CZ550302",
        "value": "Oddělení CÚ Daňové, Jičín"
    },
    {
        "key": "CZ550303",
        "value": "Oddělení CÚ Daňové, Náchod"
    },
    {
        "key": "CZ550304",
        "value": "Oddělení CÚ Daňové, Trutnov"
    },
    {
        "key": "CZ560000",
        "value": "Celní úřad pro Liberecký kraj, Liberec 6"
    },
    {
        "key": "CZ560100",
        "value": "Odbor CÚ Právní, Liberec 6"
    },
    {
        "key": "CZ560101",
        "value": "Oddělení CÚ Právních a správních činností, Libe"
    },
    {
        "key": "CZ560201",
        "value": "Oddělení CÚ Celní, Liberec 6"
    },
    {
        "key": "CZ560301",
        "value": "Oddělení CÚ Daňové, Liberec 6"
    },
    {
        "key": "CZ570000",
        "value": "Celní úřad pro Moravskoslezský kraj, Ostrava"
    },
    {
        "key": "CZ570100",
        "value": "Odbor CÚ Právní, Ostrava"
    },
    {
        "key": "CZ570101",
        "value": "Oddělení CÚ Právních a správních činností, Ostr"
    },
    {
        "key": "CZ570200",
        "value": "Odbor CÚ Celní, Paskov"
    },
    {
        "key": "CZ570201",
        "value": "Oddělení CÚ Celní, Paskov"
    },
    {
        "key": "CZ570202",
        "value": "Oddělení CÚ Celní, Karviná - Nové Město"
    },
    {
        "key": "CZ570203",
        "value": "Oddělení CÚ Celní, Nošovice"
    },
    {
        "key": "CZ570204",
        "value": "Oddělení CÚ Celní, Opava"
    },
    {
        "key": "CZ570205",
        "value": "Oddělení CÚ Celní, Třinec"
    },
    {
        "key": "CZ570220",
        "value": "Oddělení CÚ Podpory celního řízení, Paskov"
    },
    {
        "key": "CZ570299",
        "value": "Oddělení CÚ Letiště, Mošnov"
    },
    {
        "key": "CZ570300",
        "value": "Odbor CÚ Daňový, Ostrava"
    },
    {
        "key": "CZ570301",
        "value": "Oddělení CÚ Daňové, Ostrava"
    },
    {
        "key": "CZ570302",
        "value": "Oddělení CÚ Daňové, Frýdek-Místek"
    },
    {
        "key": "CZ570303",
        "value": "Oddělení CÚ Daňové, Karviná - Nové Město"
    },
    {
        "key": "CZ570304",
        "value": "Oddělení CÚ Daňové, Mošnov"
    },
    {
        "key": "CZ570305",
        "value": "Oddělení CÚ Daňové, Opava"
    },
    {
        "key": "CZ580000",
        "value": "Celní úřad pro Olomoucký kraj"
    },
    {
        "key": "CZ580100",
        "value": "Odbor CÚ Právní, Olomouc"
    },
    {
        "key": "CZ580101",
        "value": "Oddělení CÚ Právních a správních činností, Olom"
    },
    {
        "key": "CZ580200",
        "value": "Odbor CÚ Celní, Olomouc"
    },
    {
        "key": "CZ580201",
        "value": "Oddělení CÚ Celní, Olomouc"
    },
    {
        "key": "CZ580202",
        "value": "Oddělení CÚ Celní, Přerov"
    },
    {
        "key": "CZ580203",
        "value": "Oddělení CÚ Celní, Šumperk"
    },
    {
        "key": "CZ580300",
        "value": "Odbor CÚ Daňový, Olomouc"
    },
    {
        "key": "CZ580301",
        "value": "Oddělení CÚ Daňové, Olomouc"
    },
    {
        "key": "CZ580302",
        "value": "Oddělení CÚ Daňové, Přerov"
    },
    {
        "key": "CZ580303",
        "value": "Oddělení CÚ Daňové, Šumperk"
    },
    {
        "key": "CZ590000",
        "value": "Celní úřad pro Pardubický kraj, Pardubice"
    },
    {
        "key": "CZ590100",
        "value": "Odbor CÚ Právní, Pardubice"
    },
    {
        "key": "CZ590101",
        "value": "Oddělení CÚ Právních a správních činností, Pard"
    },
    {
        "key": "CZ590200",
        "value": "Odbor CÚ Celní, Pardubice"
    },
    {
        "key": "CZ590201",
        "value": "Oddělení CÚ Celní, Pardubice"
    },
    {
        "key": "CZ590202",
        "value": "Oddělení CÚ Celní, Česká Třebová"
    },
    {
        "key": "CZ590299",
        "value": "Oddělení CÚ Letiště, Popkovice"
    },
    {
        "key": "CZ590300",
        "value": "Odbor CÚ Daňový, Pardubice"
    },
    {
        "key": "CZ590301",
        "value": "Oddělení CÚ Daňové, Pardubice"
    },
    {
        "key": "CZ590302",
        "value": "Oddělení CÚ Daňové, Česká Třebová"
    },
    {
        "key": "CZ600000",
        "value": "Celní úřad pro Plzeňský kraj, Plzeň 1"
    },
    {
        "key": "CZ600100",
        "value": "Odbor CÚ Právní, Plzeň 1"
    },
    {
        "key": "CZ600101",
        "value": "Oddělení CÚ Právních a správních činností, Plze"
    },
    {
        "key": "CZ600200",
        "value": "Odbor CÚ Celní, Plzeň"
    },
    {
        "key": "CZ600201",
        "value": "Oddělení CÚ Celní, Plzeň"
    },
    {
        "key": "CZ600202",
        "value": "Oddělení CÚ Celní, Draženov"
    },
    {
        "key": "CZ600203",
        "value": "Oddělení CÚ Celní, Tachov"
    },
    {
        "key": "CZ600300",
        "value": "Odbor CÚ Daňový, Plzeň"
    },
    {
        "key": "CZ600301",
        "value": "Oddělení CÚ Daňové, Plzeň"
    },
    {
        "key": "CZ600302",
        "value": "Oddělení CÚ Daňové, Draženov"
    },
    {
        "key": "CZ610000",
        "value": "Celní úřad pro Středočeský kraj, Praha 1"
    },
    {
        "key": "CZ610100",
        "value": "Odbor CÚ Právní, Praha 1"
    },
    {
        "key": "CZ610101",
        "value": "Oddělení CÚ Právních a správních činností, Prah"
    },
    {
        "key": "CZ610200",
        "value": "Odbor CÚ Celní, Zdiby"
    },
    {
        "key": "CZ610201",
        "value": "Oddělení CÚ Celní, Zdiby"
    },
    {
        "key": "CZ610202",
        "value": "Oddělení CÚ Celní, Benešov u Prahy"
    },
    {
        "key": "CZ610203",
        "value": "Oddělení CÚ Celní, Kladno"
    },
    {
        "key": "CZ610204",
        "value": "Oddělení CÚ Celní, Kolín 4"
    },
    {
        "key": "CZ610205",
        "value": "Oddělení CÚ Celní, Kosmonosy"
    },
    {
        "key": "CZ610206",
        "value": "Oddělení CÚ Celní, Mělník"
    },
    {
        "key": "CZ610207",
        "value": "Oddělení CÚ Celní, Říčany"
    },
    {
        "key": "CZ610208",
        "value": "Oddělení CÚ Celní, Rudná"
    },
    {
        "key": "CZ610220",
        "value": "Oddělení CÚ Podpory celního řízení, Zdiby"
    },
    {
        "key": "CZ610300",
        "value": "Odbor CÚ Daňový, Kladno"
    },
    {
        "key": "CZ610301",
        "value": "Oddělení CÚ Daňové, Kladno"
    },
    {
        "key": "CZ610302",
        "value": "Oddělení CÚ Daňové, Benešov u Prahy"
    },
    {
        "key": "CZ610303",
        "value": "Oddělení CÚ Daňové, Kolín 4"
    },
    {
        "key": "CZ610304",
        "value": "Oddělení CÚ Daňové, Kosmonosy"
    },
    {
        "key": "CZ610305",
        "value": "Oddělení CÚ Daňové, Kutná Hora"
    },
    {
        "key": "CZ610306",
        "value": "Oddělení CÚ Daňové, Mělník"
    },
    {
        "key": "CZ610399",
        "value": "Oddělení CÚ TNKP - Kutná Hora, Kutná Hora"
    },
    {
        "key": "CZ620000",
        "value": "Celní úřad pro Ústecký kraj, Ústí nad Labem 11"
    },
    {
        "key": "CZ620100",
        "value": "Odbor CÚ Právní, Ústí nad Labem 11"
    },
    {
        "key": "CZ620101",
        "value": "Oddělení CÚ Právních a správních činností, Ústí"
    },
    {
        "key": "CZ620200",
        "value": "Odbor CÚ Celní, Ústí nad Labem"
    },
    {
        "key": "CZ620201",
        "value": "Oddělení CÚ Celní, Ústí nad Labem"
    },
    {
        "key": "CZ620202",
        "value": "Oddělení CÚ Celní, Chomutov"
    },
    {
        "key": "CZ620203",
        "value": "Oddělení CÚ Celní, Most-Velebudice"
    },
    {
        "key": "CZ620300",
        "value": "Odbor CÚ Daňový, Ústí nad Labem"
    },
    {
        "key": "CZ620301",
        "value": "Oddělení CÚ Daňové, Ústí nad Labem"
    },
    {
        "key": "CZ620302",
        "value": "Oddělení CÚ Daňové, Chomutov"
    },
    {
        "key": "CZ620303",
        "value": "Oddělení CÚ Daňové, Most-Velebudice"
    },
    {
        "key": "CZ630000",
        "value": "Celní úřad pro Kraj Vysočina, Střítež u Jihlav"
    },
    {
        "key": "CZ630100",
        "value": "Odbor CÚ Právní, Střítež u Jihlavy"
    },
    {
        "key": "CZ630101",
        "value": "Oddělení CÚ Právních a správních činností, Stří"
    },
    {
        "key": "CZ630200",
        "value": "Odbor CÚ Celní, Střítež u Jihlavy"
    },
    {
        "key": "CZ630201",
        "value": "Oddělení CÚ Celní, Střítež u Jihlavy"
    },
    {
        "key": "CZ630202",
        "value": "Oddělení CÚ Celní, Pelhřimov"
    },
    {
        "key": "CZ630203",
        "value": "Oddělení CÚ Celní, Žďár nad Sázavou"
    },
    {
        "key": "CZ630300",
        "value": "Odbor CÚ Daňový, Střítež u Jihlavy"
    },
    {
        "key": "CZ630301",
        "value": "Oddělení CÚ Daňové, Střítež u Jihlavy"
    },
    {
        "key": "CZ630302",
        "value": "Oddělení CÚ Daňové, Pelhřimov"
    },
    {
        "key": "CZ630303",
        "value": "Oddělení CÚ Daňové, Třebíč"
    },
    {
        "key": "CZ630304",
        "value": "Oddělení CÚ Daňové, Žďár nad Sázavou"
    },
    {
        "key": "CZ640000",
        "value": "Celní úřad pro Zlínský kraj, Zlín"
    },
    {
        "key": "CZ640100",
        "value": "Odbor CÚ Právní, Zlín"
    },
    {
        "key": "CZ640101",
        "value": "Oddělení CÚ Právních a správních činností, Zlín"
    },
    {
        "key": "CZ640200",
        "value": "Odbor CÚ Celní, Zlín"
    },
    {
        "key": "CZ640201",
        "value": "Oddělení CÚ Celní, Lípa"
    },
    {
        "key": "CZ640202",
        "value": "Oddělení CÚ Celní, Napajedla"
    },
    {
        "key": "CZ640203",
        "value": "Oddělení CÚ Celní, Uherské Hradiště"
    },
    {
        "key": "CZ640204",
        "value": "Oddělení CÚ Celní, Valašské Meziříčí"
    },
    {
        "key": "CZ640300",
        "value": "Odbor CÚ Daňový, Zlín"
    },
    {
        "key": "CZ640301",
        "value": "Oddělení CÚ Daňové, Zlín"
    },
    {
        "key": "CZ640302",
        "value": "Oddělení CÚ Daňové, Uherské Hradiště"
    },
    {
        "key": "CZ640303",
        "value": "Oddělení CÚ Daňové, Valašské Meziříčí"
    },
    {
        "key": "CZ650000",
        "value": "Celní úřad Praha Ruzyně, Praha 6"
    },
    {
        "key": "CZ650100",
        "value": "Odbor CÚ Právní, Praha 6"
    },
    {
        "key": "CZ650101",
        "value": "Oddělení CÚ Právních a správních činností, Prah"
    },
    {
        "key": "CZ650200",
        "value": "Odbor CÚ Celní, Praha 6"
    },
    {
        "key": "CZ650201",
        "value": "Oddělení CÚ Celní, Praha 6"
    },
    {
        "key": "CZ650202",
        "value": "Oddělení CÚ Celní - Pošta Praha, Praha 5"
    },
    {
        "key": "CZ650299",
        "value": "Oddělení CÚ Letiště, Praha 6"
    },
    {
        "key": "CZ650301",
        "value": "Oddělení CÚ Daňové, Praha 6"
    },
    {
        "key": "CZ900000",
        "value": "GENERÁLNÍ ŘEDITELSTVÍ CEL, Praha 4"
    },
    {
        "key": "CZ921000",
        "value": "Odbor GŘC 21 - CELNÍ, Praha 4"
    },
    {
        "key": "DE001482",
        "value": "ITZBund Dienstsitz Hamburg"
    },
    {
        "key": "DE001488",
        "value": "HZA Stuttgart, Sachgebiet B Arbeitsgebiet EMCS"
    },
    {
        "key": "DE001500",
        "value": "Bundesstelle Ursprungsnachprüfung Hauptzollamt Münster"
    },
    {
        "key": "DE001501",
        "value": "Generalzolldirektion Zentralstelle Gewerblicher Rechtsschutz"
    },
    {
        "key": "DE001502",
        "value": "Zollfahndungsamt Berlin-Brandenburg"
    },
    {
        "key": "DE001503",
        "value": "Zollfahndungsamt Hannover Dienstsitz Bremen"
    },
    {
        "key": "DE001504",
        "value": "Zollfahndungsamt Hamburg Dienstsitz Bremerhaven"
    },
    {
        "key": "DE001505",
        "value": "Zollfahndungsamt Essen"
    },
    {
        "key": "DE001508",
        "value": "Zollfahndungsamt Frankfurt am Main"
    },
    {
        "key": "DE001509",
        "value": "Zollfahndungsamt Frankfurt am Main Dienstsitz Kassel"
    },
    {
        "key": "DE001510",
        "value": "Zollfahndungsamt Stuttgart Dienstsitz Freiburg i. Br."
    },
    {
        "key": "DE001512",
        "value": "Zollfahndungsamt Stuttgart Dienstsitz Radolfzell"
    },
    {
        "key": "DE001513",
        "value": "Zollfahndungsamt Hamburg"
    },
    {
        "key": "DE001514",
        "value": "Zollfahndungsamt Hannover"
    },
    {
        "key": "DE001516",
        "value": "Zollfahndungsamt Stuttgart Dienstsitz Karlsruhe"
    },
    {
        "key": "DE001518",
        "value": "Zollfahndungsamt Hamburg Dienstsitz Kiel"
    },
    {
        "key": "DE001522",
        "value": "Zollfahndungsamt Frankfurt am Main Dienstsitz Kaiserslautern"
    },
    {
        "key": "DE001524",
        "value": "Zollfahndungsamt Essen Dienstsitz Köln"
    },
    {
        "key": "DE001525",
        "value": "Zollfahndungsamt Essen Dienstsitz Aachen"
    },
    {
        "key": "DE001526",
        "value": "Zollfahndungsamt München"
    },
    {
        "key": "DE001527",
        "value": "Zollfahndungsamt München Dienstsitz Lindau"
    },
    {
        "key": "DE001528",
        "value": "Zollfahndungsamt Essen Dienstsitz Münster"
    },
    {
        "key": "DE001529",
        "value": "Zollfahndungsamt Hannover Dienstsitz Bielefeld"
    },
    {
        "key": "DE001531",
        "value": "Zollfahndungsamt München Dienstsitz Nürnberg"
    },
    {
        "key": "DE001534",
        "value": "Zollfahndungsamt Stuttgart"
    },
    {
        "key": "DE001535",
        "value": "Zollfahndungsamt Essen Dienstort Flughafen Düsseldorf"
    },
    {
        "key": "DE001536",
        "value": "Zollfahndungsamt Frankfurt am Main Dienstort Flughafengebäude"
    },
    {
        "key": "DE001537",
        "value": "Zollfahndungsamt Frankfurt am Main Dienstsitz Saarbrücken"
    },
    {
        "key": "DE001541",
        "value": "Zollfahndungsamt Essen Dienstsitz Nordhorn"
    },
    {
        "key": "DE001542",
        "value": "Zollfahndungsamt Essen Dienstsitz Kleve"
    },
    {
        "key": "DE001546",
        "value": "Zollfahndungsamt Essen Dienstort Flughafen Köln"
    },
    {
        "key": "DE001549",
        "value": "Zollfahndungsamt München Dienstort München-Flughafen"
    },
    {
        "key": "DE001555",
        "value": "Zollfahndungsamt Dresden"
    },
    {
        "key": "DE001556",
        "value": "Zollfahndungsamt Dresden Dienstsitz Leipzig"
    },
    {
        "key": "DE001558",
        "value": "Zollfahndungsamt Hannover Dienstsitz Magdeburg"
    },
    {
        "key": "DE001560",
        "value": "Zollfahndungsamt Berlin-Brandenburg Dienstsitz Teltow"
    },
    {
        "key": "DE001561",
        "value": "Zollfahndungsamt Berlin-Brandenburg Dienstsitz Frankfurt (Oder)"
    },
    {
        "key": "DE001562",
        "value": "Zollfahndungsamt Hamburg Dienstsitz Rostock"
    },
    {
        "key": "DE001565",
        "value": "Zollfahndungsamt Dresden Dienstsitz Görlitz"
    },
    {
        "key": "DE001566",
        "value": "Zollfahndungsamt Berlin-Brandenburg Dienstsitz Forst"
    },
    {
        "key": "DE001570",
        "value": "Zollfahndungsamt München Dienstsitz Weiden"
    },
    {
        "key": "DE001578",
        "value": "Zollfahndungsamt Berlin-Brandenburg Dienstsitz Pomellen"
    },
    {
        "key": "DE001710",
        "value": "DO Dresden - Stammdatenmanagement Generalzolldirektion"
    },
    {
        "key": "DE001800",
        "value": "Generalzolldirektion Bonn"
    },
    {
        "key": "DE002101",
        "value": "Zollamt Berlin-Marzahn"
    },
    {
        "key": "DE002102",
        "value": "Zollamt Berlin-Flughafen Schönefeld"
    },
    {
        "key": "DE002105",
        "value": "Zollamt Berlin-Flughafen-Tegel"
    },
    {
        "key": "DE002150",
        "value": "Hauptzollamt Berlin"
    },
    {
        "key": "DE002151",
        "value": "Zollamt Schöneberg"
    },
    {
        "key": "DE002152",
        "value": "Zollamt Berlin-Dreilinden"
    },
    {
        "key": "DE002181",
        "value": "Abfertigungsstelle Berlin-Messe"
    },
    {
        "key": "DE002300",
        "value": "Hauptzollamt Bremen"
    },
    {
        "key": "DE002301",
        "value": "Zollamt Bremen-Flughafen"
    },
    {
        "key": "DE002307",
        "value": "Hauptzollamt Bremen, Kontrolleinheit Flughafen Bremen"
    },
    {
        "key": "DE002325",
        "value": "Zollamt Bremen"
    },
    {
        "key": "DE002326",
        "value": "Zollamt Bremen Dienstort Senator-Harmssen-Straße"
    },
    {
        "key": "DE002452",
        "value": "Zollamt Bremerhaven"
    },
    {
        "key": "DE002457",
        "value": "Zollamt Bremerhaven Dienstort Senator-Borttscheller-Straße 15/15a"
    },
    {
        "key": "DE002458",
        "value": "Zollamt Bremerhaven DO Senator-Borttscheller-Straße 8"
    },
    {
        "key": "DE002600",
        "value": "Hauptzollamt Düsseldorf"
    },
    {
        "key": "DE002601",
        "value": "Zollamt Flughafen"
    },
    {
        "key": "DE002604",
        "value": "Zollamt Düsseldorf Nord"
    },
    {
        "key": "DE002605",
        "value": "Hauptzollamt Düsseldorf, KE Flughafen Düsseldorf"
    },
    {
        "key": "DE002607",
        "value": "Zollamt Düsseldorf Reisholz"
    },
    {
        "key": "DE002631",
        "value": "Abfertigungsstelle Düsseldorf Messe"
    },
    {
        "key": "DE002650",
        "value": "Hauptzollamt Duisburg"
    },
    {
        "key": "DE002651",
        "value": "Zollamt Essen"
    },
    {
        "key": "DE002656",
        "value": "Zollamt Duisburg-Ruhrort"
    },
    {
        "key": "DE002700",
        "value": "Hauptzollamt Duisburg DO Emmerich am Rhein Parkring"
    },
    {
        "key": "DE002701",
        "value": "Zollamt Emmerich"
    },
    {
        "key": "DE002702",
        "value": "Zollamt Straelen-Autobahn"
    },
    {
        "key": "DE002704",
        "value": "Hauptzollamt Duisburg, Kontrolleinheit Flughafen Weeze"
    },
    {
        "key": "DE002705",
        "value": "Abfertigungsstelle Weeze"
    },
    {
        "key": "DE002900",
        "value": "Hauptzollamt Krefeld"
    },
    {
        "key": "DE002901",
        "value": "Zollamt Schwanenhaus"
    },
    {
        "key": "DE002903",
        "value": "Zollamt Uerdingen"
    },
    {
        "key": "DE002904",
        "value": "Zollamt Mönchengladbach"
    },
    {
        "key": "DE002906",
        "value": "Zollamt Neuss"
    },
    {
        "key": "DE002956",
        "value": "Zollamt Wuppertal"
    },
    {
        "key": "DE003000",
        "value": "Hauptzollamt Erfurt"
    },
    {
        "key": "DE003002",
        "value": "Zollamt Eisenach"
    },
    {
        "key": "DE003003",
        "value": "Zollamt Erfurt-Am Flughafen"
    },
    {
        "key": "DE003004",
        "value": "Zollamt Jena"
    },
    {
        "key": "DE003009",
        "value": "Hauptzollamt Erfurt, Kontrolleinheit Flughafen Erfurt"
    },
    {
        "key": "DE003012",
        "value": "Zollamt Eisenach Zollservicepunkt Suhl"
    },
    {
        "key": "DE003013",
        "value": "Zollamt Am Flughafen Zollservicepunkt Nordhausen"
    },
    {
        "key": "DE003014",
        "value": "Zollamt Jena Zollservicepunkt Gera"
    },
    {
        "key": "DE003030",
        "value": "Abfertigungsstelle Erfurt-Flughafen Luftverkehr"
    },
    {
        "key": "DE003073",
        "value": "deleted_office"
    },
    {
        "key": "DE003074",
        "value": "Hauptzollamt Erfurt Dienstort Chemnitz"
    },
    {
        "key": "DE003075",
        "value": "Hauptzollamt Erfurt Dienstort Gera"
    },
    {
        "key": "DE003076",
        "value": "Hauptzollamt Erfurt Dienstort Suhl"
    },
    {
        "key": "DE003200",
        "value": "Hauptzollamt Darmstadt"
    },
    {
        "key": "DE003201",
        "value": "Zollamt Bensheim"
    },
    {
        "key": "DE003202",
        "value": "Zollamt Wiesbaden"
    },
    {
        "key": "DE003208",
        "value": "Hauptzollamt Darmstadt Dienstort Wiesbaden"
    },
    {
        "key": "DE003230",
        "value": "Zollamt Darmstadt"
    },
    {
        "key": "DE003300",
        "value": "Hauptzollamt Frankfurt am Main"
    },
    {
        "key": "DE003302",
        "value": "Zollamt Fracht"
    },
    {
        "key": "DE003305",
        "value": "Zollamt Fracht Abfertigungsstelle IPZ (Internationales Postzentrum)"
    },
    {
        "key": "DE003306",
        "value": "Hauptzollamt Frankfurt am Main KEFR Frankfurt am Main"
    },
    {
        "key": "DE003307",
        "value": "Hauptzollamt Frankfurt am Main KEF/Ü Frankfurt am Main"
    },
    {
        "key": "DE003310",
        "value": "Hauptzollamt Frankfurt am Main Dienstort Flughafen"
    },
    {
        "key": "DE003311",
        "value": "Zollamt Frankfurt am Main-Osthafen Abfertigungsstelle Messe"
    },
    {
        "key": "DE003352",
        "value": "Zollamt Hanau"
    },
    {
        "key": "DE003354",
        "value": "Zollamt Höchst"
    },
    {
        "key": "DE003356",
        "value": "Zollamt Oberursel"
    },
    {
        "key": "DE003358",
        "value": "Zollamt Frankfurt am Main-Osthafen"
    },
    {
        "key": "DE003400",
        "value": "Hauptzollamt Gießen Dienstort Fulda"
    },
    {
        "key": "DE003401",
        "value": "Zollamt Bad Hersfeld"
    },
    {
        "key": "DE003402",
        "value": "Abfertigungsstelle IPZ Niederaula"
    },
    {
        "key": "DE003430",
        "value": "Zollamt Fulda"
    },
    {
        "key": "DE003450",
        "value": "Hauptzollamt Gießen"
    },
    {
        "key": "DE003452",
        "value": "Zollamt Marburg"
    },
    {
        "key": "DE003453",
        "value": "Zollamt Wetzlar"
    },
    {
        "key": "DE003454",
        "value": "Zollamt Kassel"
    },
    {
        "key": "DE003456",
        "value": "Hauptzollamt Gießen Dienstort An der Kaserne"
    },
    {
        "key": "DE003460",
        "value": "Hauptzollamt Gießen Dienstort Kassel Hasselweg"
    },
    {
        "key": "DE003601",
        "value": "Zollamt Finsterwalde"
    },
    {
        "key": "DE003603",
        "value": "Zollamt Forst"
    },
    {
        "key": "DE003650",
        "value": "Hauptzollamt Frankfurt (Oder)"
    },
    {
        "key": "DE003652",
        "value": "Zollamt Frankfurt (Oder)-Autobahn"
    },
    {
        "key": "DE003656",
        "value": "Zollamt Fürstenwalde"
    },
    {
        "key": "DE003670",
        "value": "Hauptzollamt Frankfurt (Oder) DO Cottbus Karl-Liebknecht-Straße"
    },
    {
        "key": "DE003688",
        "value": "Zollamt Eberswalde Zollservicepunkt Schwedt/Oder"
    },
    {
        "key": "DE003700",
        "value": "Hauptzollamt Potsdam"
    },
    {
        "key": "DE003701",
        "value": "Zollamt Ludwigsfelde"
    },
    {
        "key": "DE003702",
        "value": "Zollamt Velten"
    },
    {
        "key": "DE003705",
        "value": "Hauptzollamt Potsdam, KE Flughafen Berlin-Tegel"
    },
    {
        "key": "DE003706",
        "value": "Hauptzollamt Potsdam, KE Flughafen Berlin-Schönefeld"
    },
    {
        "key": "DE003730",
        "value": "Abfertigungsstelle Neuruppin"
    },
    {
        "key": "DE003731",
        "value": "Abfertigungsstelle Perleberg"
    },
    {
        "key": "DE003732",
        "value": "Abfertigungsstelle Brandenburg"
    },
    {
        "key": "DE003752",
        "value": "Zollamt Eberswalde"
    },
    {
        "key": "DE003954",
        "value": "Zollamt Freiburg"
    },
    {
        "key": "DE003956",
        "value": "Zollamt Appenweier"
    },
    {
        "key": "DE003960",
        "value": "Hauptzollamt Lörrach DO Freiburg i. Br. Sautierstraße 32"
    },
    {
        "key": "DE004002",
        "value": "Zollamt Konstanz-Güterbahnhof"
    },
    {
        "key": "DE004005",
        "value": "Zollamt Konstanz-Autobahn (schweiz. Gebiet)"
    },
    {
        "key": "DE004020",
        "value": "Hauptzollamt Singen Dienstort Konstanz Kreuzlinger Tor"
    },
    {
        "key": "DE004050",
        "value": "Hauptzollamt Lörrach"
    },
    {
        "key": "DE004051",
        "value": "Zollamt Grenzacherhorn"
    },
    {
        "key": "DE004053",
        "value": "Zollamt Lörrach-Stetten"
    },
    {
        "key": "DE004055",
        "value": "Zollamt Weil am Rhein-Autobahn"
    },
    {
        "key": "DE004058",
        "value": "Deutsches Zollamt Basel"
    },
    {
        "key": "DE004062",
        "value": "Zollamt Rheinfelden-Autobahn"
    },
    {
        "key": "DE004073",
        "value": "Hauptzollamt Lörrach Dienstort Wiesentalstraße"
    },
    {
        "key": "DE004081",
        "value": "Abfertigungsstelle Weil am Rhein - Umschlagbahnhof"
    },
    {
        "key": "DE004083",
        "value": "Zollamt Weil am Rhein"
    },
    {
        "key": "DE004085",
        "value": "Deutsche Abfertigungsstelle Basler Häfen"
    },
    {
        "key": "DE004086",
        "value": "Abfertigungsstelle Weil Rheinfelden/Rheinhafen"
    },
    {
        "key": "DE004087",
        "value": "Abfertigungsstelle Rheinhafen"
    },
    {
        "key": "DE004100",
        "value": "Hauptzollamt Singen"
    },
    {
        "key": "DE004101",
        "value": "Zollamt Bietingen"
    },
    {
        "key": "DE004102",
        "value": "Zollamt Neuhaus"
    },
    {
        "key": "DE004103",
        "value": "Zollamt Rielasingen"
    },
    {
        "key": "DE004105",
        "value": "Zollamt Singen-Bahnhof"
    },
    {
        "key": "DE004106",
        "value": "Zollamt Deißlingen"
    },
    {
        "key": "DE004193",
        "value": "Hauptzollamt Singen Dienstort Gottmadingen Erwin-Dietrich-Straße"
    },
    {
        "key": "DE004201",
        "value": "Zollamt Erzingen"
    },
    {
        "key": "DE004203",
        "value": "Zollamt Jestetten (schweiz. Gebiet)"
    },
    {
        "key": "DE004204",
        "value": "Zollamt Laufenburg"
    },
    {
        "key": "DE004205",
        "value": "Zollamt Lottstetten"
    },
    {
        "key": "DE004206",
        "value": "Zollamt Stühlingen"
    },
    {
        "key": "DE004208",
        "value": "Zollamt Waldshut"
    },
    {
        "key": "DE004209",
        "value": "Zollamt Bad Säckingen"
    },
    {
        "key": "DE004501",
        "value": "Zollamt Cuxhaven"
    },
    {
        "key": "DE004506",
        "value": "Zollamt Helgoland"
    },
    {
        "key": "DE004600",
        "value": "Hauptzollamt Hamburg"
    },
    {
        "key": "DE004603",
        "value": "Zollamt Hamburg Dienstort Koreastraße"
    },
    {
        "key": "DE004605",
        "value": "Zollamt Hamburg Dienstort Pinkertweg"
    },
    {
        "key": "DE004633",
        "value": "Zollamt Hamburg Dienstort Plöner Straße"
    },
    {
        "key": "DE004701",
        "value": "Zollamt Hamburg-Flughafen"
    },
    {
        "key": "DE004850",
        "value": "Hauptzollamt Hamburg - Wendenstraße"
    },
    {
        "key": "DE004851",
        "value": "Zollamt Hamburg"
    },
    {
        "key": "DE004863",
        "value": "Zollamt Hamburg Dienstort Indiastraße"
    },
    {
        "key": "DE004900",
        "value": "Hauptzollamt Braunschweig"
    },
    {
        "key": "DE004906",
        "value": "Zollamt Wolfsburg"
    },
    {
        "key": "DE004909",
        "value": "Hauptzollamt Braunschweig Dienstort Industriestraße"
    },
    {
        "key": "DE004920",
        "value": "Hauptzollamt Braunschweig DO Helmstedt Schöninger Straße"
    },
    {
        "key": "DE004930",
        "value": "Zollamt Braunschweig- Broitzem"
    },
    {
        "key": "DE004961",
        "value": "Zollamt Helmstedt-Autobahn"
    },
    {
        "key": "DE005004",
        "value": "Zollamt Emden"
    },
    {
        "key": "DE005008",
        "value": "Zollamt Papenburg"
    },
    {
        "key": "DE005050",
        "value": "Hauptzollamt Braunschweig Dienstort Göttingen"
    },
    {
        "key": "DE005052",
        "value": "Zollamt Goslar"
    },
    {
        "key": "DE005053",
        "value": "Zollamt Hameln"
    },
    {
        "key": "DE005055",
        "value": "Zollamt Hildesheim"
    },
    {
        "key": "DE005056",
        "value": "Abfertigungsstelle Holzminden"
    },
    {
        "key": "DE005071",
        "value": "Hauptzollamt Braunschweig DO Hildesheim Eduard-Ahlborn-Straße"
    },
    {
        "key": "DE005080",
        "value": "Zollamt Göttingen"
    },
    {
        "key": "DE005100",
        "value": "Hauptzollamt Hannover"
    },
    {
        "key": "DE005102",
        "value": "Zollamt Hannover-Nord"
    },
    {
        "key": "DE005103",
        "value": "Zollamt Hannover-Flughafen"
    },
    {
        "key": "DE005104",
        "value": "Hauptzollamt Hannover, KE Flughafen Hannover Langenhagen"
    },
    {
        "key": "DE005110",
        "value": "Zollamt Hannover Messe"
    },
    {
        "key": "DE005111",
        "value": "Zollamt Celle"
    },
    {
        "key": "DE005202",
        "value": "Zollamt Soltau"
    },
    {
        "key": "DE005203",
        "value": "Zollamt Stade"
    },
    {
        "key": "DE005204",
        "value": "Zollamt Verden"
    },
    {
        "key": "DE005207",
        "value": "HZA Hannover, Verbindliche Zolltarif- und Ursprungsauskünfte"
    },
    {
        "key": "DE005230",
        "value": "Zollamt Lüneburg"
    },
    {
        "key": "DE005300",
        "value": "Hauptzollamt Oldenburg"
    },
    {
        "key": "DE005301",
        "value": "Zollamt Brake"
    },
    {
        "key": "DE005310",
        "value": "Zollamt Wilhelmshaven"
    },
    {
        "key": "DE005325",
        "value": "Hauptzollamt Oldenburg Dienstort Papenburg"
    },
    {
        "key": "DE005330",
        "value": "Zollamt Oldenburg-Kreyenbrück"
    },
    {
        "key": "DE005350",
        "value": "Hauptzollamt Osnabrück"
    },
    {
        "key": "DE005351",
        "value": "Zollamt Schüttorf"
    },
    {
        "key": "DE005355",
        "value": "Zollamt Lohne"
    },
    {
        "key": "DE005356",
        "value": "Zollamt Lingen"
    },
    {
        "key": "DE005371",
        "value": "Hauptzollamt Osnabrück Dienstort Nordhorn Stadtring"
    },
    {
        "key": "DE005380",
        "value": "Zollamt Fledder"
    },
    {
        "key": "DE005502",
        "value": "Zollamt Nossen"
    },
    {
        "key": "DE005530",
        "value": "Zollamt Chemnitz"
    },
    {
        "key": "DE005550",
        "value": "Hauptzollamt Dresden"
    },
    {
        "key": "DE005552",
        "value": "Zollamt Dresden-Flughafen"
    },
    {
        "key": "DE005555",
        "value": "Hauptzollamt Dresden, Kontrolleinheit Flughafen Dresden"
    },
    {
        "key": "DE005557",
        "value": "Hauptzollamt Dresden, Kontrolleinheit Flughafen Leipzig"
    },
    {
        "key": "DE005560",
        "value": "Hauptzollamt Dresden Dienstort Görlitz Schützenstraße"
    },
    {
        "key": "DE005565",
        "value": "Hauptzollamt Dresden Dienstort Hartmut-Dost-Straße"
    },
    {
        "key": "DE005572",
        "value": "Hauptzollamt Dresden Dienstort Leipzig"
    },
    {
        "key": "DE005574",
        "value": "Hauptzollamt Dresden Dienstort Pirna"
    },
    {
        "key": "DE005580",
        "value": "Zollamt Dresden"
    },
    {
        "key": "DE005603",
        "value": "Zollamt Taucha"
    },
    {
        "key": "DE005604",
        "value": "Zollamt Leipzig-Flughafen"
    },
    {
        "key": "DE005605",
        "value": "Zollamt Flughafen Leipzig Dienstort Industriestraße"
    },
    {
        "key": "DE005633",
        "value": "Abfertigungsstelle IFS Radefeld"
    },
    {
        "key": "DE005687",
        "value": "Zollamt Löbau"
    },
    {
        "key": "DE005756",
        "value": "Zollamt Hirschfeld"
    },
    {
        "key": "DE005850",
        "value": "Hauptzollamt Karlsruhe"
    },
    {
        "key": "DE005852",
        "value": "Zollamt Bruchsal"
    },
    {
        "key": "DE005853",
        "value": "Zollamt Baden-Baden"
    },
    {
        "key": "DE005855",
        "value": "Hauptzollamt Karlsruhe, Kontrolleinheit Flughafen Karlruhe"
    },
    {
        "key": "DE005857",
        "value": "Abfertigungsstelle Nagold"
    },
    {
        "key": "DE005858",
        "value": "Zollamt Pforzheim"
    },
    {
        "key": "DE005880",
        "value": "Zollamt Karlsruhe"
    },
    {
        "key": "DE005881",
        "value": "AbfSt Flugh. Karlsruhe/Baden-Baden Zollamt Baden-Baden"
    },
    {
        "key": "DE005900",
        "value": "Hauptzollamt Karlsruhe Dienstort Mannheim C 7"
    },
    {
        "key": "DE005901",
        "value": "Zollamt Heidelberg"
    },
    {
        "key": "DE005904",
        "value": "Zollamt Mannheim"
    },
    {
        "key": "DE005914",
        "value": "Zollamt Mannheim Zollservicepunkt Ludwigshafen a Rh."
    },
    {
        "key": "DE006133",
        "value": "Zollamt Flensburg"
    },
    {
        "key": "DE006150",
        "value": "Hauptzollamt Itzehoe"
    },
    {
        "key": "DE006151",
        "value": "Zollamt Brunsbüttel"
    },
    {
        "key": "DE006155",
        "value": "Zollamt Husum"
    },
    {
        "key": "DE006156",
        "value": "Zollamt Pinneberg"
    },
    {
        "key": "DE006179",
        "value": "Hauptzollamt Itzehoe Dienstort Hamburg"
    },
    {
        "key": "DE006183",
        "value": "Abfertigungsstelle Itzehoe (HZA)"
    },
    {
        "key": "DE006200",
        "value": "Hauptzollamt Kiel"
    },
    {
        "key": "DE006203",
        "value": "Zollamt Kiel-Wik"
    },
    {
        "key": "DE006206",
        "value": "Zollamt Rendsburg"
    },
    {
        "key": "DE006207",
        "value": "Zollamt Kiel"
    },
    {
        "key": "DE006231",
        "value": "Abfertigungsstelle Kiel-Norwegenkai"
    },
    {
        "key": "DE006302",
        "value": "Zollamt Heiligenhafen"
    },
    {
        "key": "DE006310",
        "value": "Zollamt Mölln"
    },
    {
        "key": "DE006332",
        "value": "Abfertigungsstelle Hafen Zollamt Lübeck"
    },
    {
        "key": "DE006333",
        "value": "Zollamt Lübeck"
    },
    {
        "key": "DE006334",
        "value": "Zollamt Lübeck Travemünde Skandinavienkai"
    },
    {
        "key": "DE006335",
        "value": "Hauptzollamt Kiel Puttgarden Fährhafen"
    },
    {
        "key": "DE006501",
        "value": "Zollamt Kaiserslautern"
    },
    {
        "key": "DE006502",
        "value": "Zollamt Pirmasens"
    },
    {
        "key": "DE006504",
        "value": "Zollamt Mainz"
    },
    {
        "key": "DE006550",
        "value": "Hauptzollamt Koblenz"
    },
    {
        "key": "DE006551",
        "value": "Zollamt Koblenz-Rheinhafen"
    },
    {
        "key": "DE006554",
        "value": "Hauptzollamt Koblenz, Kontrolleinheiten Flughafen Hahn"
    },
    {
        "key": "DE006560",
        "value": "Zollamt Rheinhafen Zollservicepunkt Hachenburg"
    },
    {
        "key": "DE006653",
        "value": "Zollamt Germersheim"
    },
    {
        "key": "DE006681",
        "value": "Zollamt Germersheim AbfSt IFS Speyer"
    },
    {
        "key": "DE006752",
        "value": "Zollamt Idar-Oberstein"
    },
    {
        "key": "DE006753",
        "value": "Zollamt Ehrang"
    },
    {
        "key": "DE006756",
        "value": "Zollamt Hahn-Flughafen"
    },
    {
        "key": "DE006757",
        "value": "Zollamt Trier-Ehrang Dienstort Wittlich"
    },
    {
        "key": "DE006758",
        "value": "Zollamt Mainz Dienstort Bingen"
    },
    {
        "key": "DE007050",
        "value": "Hauptzollamt Aachen"
    },
    {
        "key": "DE007053",
        "value": "Zollamt Heinsberg"
    },
    {
        "key": "DE007054",
        "value": "Zollamt Charlottenburger Allee"
    },
    {
        "key": "DE007064",
        "value": "Zollamt Düren"
    },
    {
        "key": "DE007150",
        "value": "Hauptzollamt Köln"
    },
    {
        "key": "DE007152",
        "value": "Zollamt Bonn"
    },
    {
        "key": "DE007153",
        "value": "Zollamt Gummersbach"
    },
    {
        "key": "DE007154",
        "value": "Zollamt Flughafen Köln/Bonn"
    },
    {
        "key": "DE007157",
        "value": "Zollamt Wahn"
    },
    {
        "key": "DE007159",
        "value": "Hauptzollamt Köln, KE Flughafen Köln/Bonn"
    },
    {
        "key": "DE007181",
        "value": "Abfertigungsstelle Köln-Messe"
    },
    {
        "key": "DE007200",
        "value": "Hauptzollamt Köln Dienstort Stolberger Straße 200"
    },
    {
        "key": "DE007202",
        "value": "Zollamt Köln-West"
    },
    {
        "key": "DE007350",
        "value": "Hauptzollamt Magdeburg"
    },
    {
        "key": "DE007352",
        "value": "Zollamt Aschersleben"
    },
    {
        "key": "DE007359",
        "value": "Zollamt Stendal"
    },
    {
        "key": "DE007361",
        "value": "Zollamt Dessau-Ost"
    },
    {
        "key": "DE007362",
        "value": "Zollamt Halle (Saale)"
    },
    {
        "key": "DE007370",
        "value": "Hauptzollamt Magdeburg Dienstort Halle"
    },
    {
        "key": "DE007380",
        "value": "Zollamt Magdeburg-Rothensee"
    },
    {
        "key": "DE007400",
        "value": "Hauptzollamt Augsburg"
    },
    {
        "key": "DE007401",
        "value": "Zollamt Göggingen"
    },
    {
        "key": "DE007402",
        "value": "Zollamt Donauwörth"
    },
    {
        "key": "DE007403",
        "value": "Zollamt Ingolstadt"
    },
    {
        "key": "DE007423",
        "value": "Hauptzollamt Augsburg Kontrolleinheit Flughafen Augsburg"
    },
    {
        "key": "DE007424",
        "value": "Hauptzollamt Augsburg, KE Flughafen Memmingerberg"
    },
    {
        "key": "DE007430",
        "value": "Zollamt Göggingen Abfertigungsstelle Flughafen"
    },
    {
        "key": "DE007455",
        "value": "Zollamt Altötting (Autobahn)"
    },
    {
        "key": "DE007458",
        "value": "Zollamt Bad Reichenhall-Autobahn"
    },
    {
        "key": "DE007477",
        "value": "Hauptzollamt Rosenheim Dienstort Traunstein"
    },
    {
        "key": "DE007478",
        "value": "Zollamt Hallbergmoos Dienstort Landshut"
    },
    {
        "key": "DE007500",
        "value": "Hauptzollamt Landshut"
    },
    {
        "key": "DE007501",
        "value": "Zollamt Hallbergmoos"
    },
    {
        "key": "DE007504",
        "value": "Zollamt Plattling"
    },
    {
        "key": "DE007522",
        "value": "Hauptzollamt Landshut Dienstort Passau Spitalhofstraße"
    },
    {
        "key": "DE007525",
        "value": "Hauptzollamt Landshut Dienstort Plattling"
    },
    {
        "key": "DE007533",
        "value": "Hauptzollamt Landshut Dienstort Siemensstraße"
    },
    {
        "key": "DE007551",
        "value": "Zollamt Hörbranz-Autobahn (österr. Gebiet)"
    },
    {
        "key": "DE007553",
        "value": "Zollamt Kempten"
    },
    {
        "key": "DE007554",
        "value": "Abfertigungsstelle Flughafen Memmingerberg"
    },
    {
        "key": "DE007561",
        "value": "Zollamt Memmingen"
    },
    {
        "key": "DE007600",
        "value": "Hauptzollamt München"
    },
    {
        "key": "DE007602",
        "value": "Zollamt Garching-Hochbrück"
    },
    {
        "key": "DE007611",
        "value": "Hauptzollamt München, Kontrolleinheiten Flughafen München"
    },
    {
        "key": "DE007622",
        "value": "Hauptzollamt München Dienstort Landsberger Straße 124"
    },
    {
        "key": "DE007650",
        "value": "Zollamt München - Flughafen"
    },
    {
        "key": "DE007679",
        "value": "Hauptzollamt München Dienstort München-Flughafen"
    },
    {
        "key": "DE007701",
        "value": "Zollamt Suben-Autobahn (österr. Gebiet)"
    },
    {
        "key": "DE007703",
        "value": "Zollamt Passau"
    },
    {
        "key": "DE007744",
        "value": "Hauptzollamt Landshut Dienstort Zwiesel"
    },
    {
        "key": "DE007750",
        "value": "Hauptzollamt Rosenheim"
    },
    {
        "key": "DE007751",
        "value": "Zollamt Weilheim"
    },
    {
        "key": "DE007755",
        "value": "Zollamt Reischenhart"
    },
    {
        "key": "DE007756",
        "value": "Hauptzollamt Rosenheim Dienstort München"
    },
    {
        "key": "DE008000",
        "value": "Hauptzollamt Bielefeld"
    },
    {
        "key": "DE008001",
        "value": "Zollamt Bielefeld-Eckendorfer Straße"
    },
    {
        "key": "DE008002",
        "value": "Hauptzollamt Bielefeld, KE Flughafen Paderborn/Lippstadt"
    },
    {
        "key": "DE008004",
        "value": "Zollamt Lemgo"
    },
    {
        "key": "DE008008",
        "value": "Zollamt Lübbecke"
    },
    {
        "key": "DE008027",
        "value": "Hauptzollamt Bielefeld Dienstort Anröchte"
    },
    {
        "key": "DE008051",
        "value": "Zollamt Bochum"
    },
    {
        "key": "DE008052",
        "value": "Zollamt Gelsenkirchen"
    },
    {
        "key": "DE008100",
        "value": "Hauptzollamt Dortmund"
    },
    {
        "key": "DE008101",
        "value": "Zollamt Hagen"
    },
    {
        "key": "DE008103",
        "value": "Zollamt Dortmund-Ost"
    },
    {
        "key": "DE008104",
        "value": "Zollamt Lüdenscheid"
    },
    {
        "key": "DE008106",
        "value": "Zollamt Siegen"
    },
    {
        "key": "DE008107",
        "value": "Hauptzollamt Dortmund, KE Flughafen Dortmund"
    },
    {
        "key": "DE008131",
        "value": "Zollamt Dortmund - Flughafen"
    },
    {
        "key": "DE008300",
        "value": "Hauptzollamt Münster"
    },
    {
        "key": "DE008301",
        "value": "Hauptzollamt Münster, Kontrolleinheit Flughafen Münster"
    },
    {
        "key": "DE008302",
        "value": "Zollamt Bocholt"
    },
    {
        "key": "DE008303",
        "value": "Zollamt Coesfeld"
    },
    {
        "key": "DE008304",
        "value": "Zollamt Münster-Eulerstraße"
    },
    {
        "key": "DE008305",
        "value": "Zollamt Rheine"
    },
    {
        "key": "DE008306",
        "value": "Zollamt Münster-Flughafen"
    },
    {
        "key": "DE008322",
        "value": "Hauptzollamt Münster Dienstort Coesfeld"
    },
    {
        "key": "DE008350",
        "value": "Hauptzollamt Bielefeld Dienstort Paderborn Am Hoppenhof"
    },
    {
        "key": "DE008351",
        "value": "Zollamt Anröchte"
    },
    {
        "key": "DE008352",
        "value": "Zollamt Arnsberg"
    },
    {
        "key": "DE008355",
        "value": "Zollamt Paderborn"
    },
    {
        "key": "DE008356",
        "value": "Zollamt Beckum"
    },
    {
        "key": "DE008380",
        "value": "Zollamt Paderborn-Flughafen"
    },
    {
        "key": "DE008601",
        "value": "Zollamt Bamberg"
    },
    {
        "key": "DE008602",
        "value": "Zollamt Bayreuth"
    },
    {
        "key": "DE008605",
        "value": "Zollamt Coburg"
    },
    {
        "key": "DE008711",
        "value": "Zollamt Hof-Marktredwitz Dienstsitz Marktredwitz"
    },
    {
        "key": "DE008715",
        "value": "Zollamt Hof-Marktredwitz"
    },
    {
        "key": "DE008750",
        "value": "Hauptzollamt Nürnberg"
    },
    {
        "key": "DE008751",
        "value": "Zollamt Ansbach-Weißenburg"
    },
    {
        "key": "DE008752",
        "value": "Zollamt Erlangen-Tennenlohe"
    },
    {
        "key": "DE008755",
        "value": "Zollamt Nürnberg Flughafen"
    },
    {
        "key": "DE008756",
        "value": "Zollamt Hafen"
    },
    {
        "key": "DE008757",
        "value": "Hauptzollamt Nürnberg, KE Flughafen Nürnberg"
    },
    {
        "key": "DE008761",
        "value": "Zollamt Ansbach-Weißenburg Dienstsitz Weißenburg"
    },
    {
        "key": "DE008784",
        "value": "Hauptzollamt Nürnberg (Kontaktstelle AEO)"
    },
    {
        "key": "DE008800",
        "value": "Hauptzollamt Regensburg"
    },
    {
        "key": "DE008801",
        "value": "Zollamt Amberg"
    },
    {
        "key": "DE008804",
        "value": "Zollamt Regensburg-Furth im Wald"
    },
    {
        "key": "DE008822",
        "value": "Hauptzollamt Regensburg Dienstort Hof Köditzer Straße"
    },
    {
        "key": "DE008833",
        "value": "Hauptzollamt Regensburg Dienstort Furth im Wald Böhmerstr."
    },
    {
        "key": "DE008835",
        "value": "Hauptzollamt Regensburg Dienstort Selb Lessingstraße"
    },
    {
        "key": "DE008840",
        "value": "Hauptzollamt Regensburg Dienstort Wernberg-Köblitz"
    },
    {
        "key": "DE008850",
        "value": "Hauptzollamt Schweinfurt"
    },
    {
        "key": "DE008851",
        "value": "Zollamt Aschaffenburg"
    },
    {
        "key": "DE008853",
        "value": "Zollamt Schweinfurt-Londonstraße"
    },
    {
        "key": "DE008857",
        "value": "Zollamt Dettelbach-Mainfrankenpark"
    },
    {
        "key": "DE008864",
        "value": "Hauptzollamt Schweinfurt Dienstort Londonstraße"
    },
    {
        "key": "DE008865",
        "value": "Hauptzollamt Schweinfurt DO Bamberg Gutenbergstraße"
    },
    {
        "key": "DE008904",
        "value": "Zollamt Weiden-Waidhaus"
    },
    {
        "key": "DE008913",
        "value": "Zollamt Weiden-Waidhaus Dienstsitz Waidhaus"
    },
    {
        "key": "DE008914",
        "value": "Zollamt Regensburg-Furth im Wald Dienstsitz Furth im Wald"
    },
    {
        "key": "DE008942",
        "value": "Hauptzollamt Regensburg Dienstort Waidhaus"
    },
    {
        "key": "DE009004",
        "value": "Zollamt Pomellen"
    },
    {
        "key": "DE009030",
        "value": "Zollamt Neubrandenburg"
    },
    {
        "key": "DE009101",
        "value": "Zollamt Ludwigslust"
    },
    {
        "key": "DE009102",
        "value": "Zollamt Laage"
    },
    {
        "key": "DE009103",
        "value": "Zollamt Wismar"
    },
    {
        "key": "DE009104",
        "value": "Zollamt Rostock"
    },
    {
        "key": "DE009106",
        "value": "Hauptzollamt Stralsund, Kontrolleinheit Flughafen Rostock"
    },
    {
        "key": "DE009150",
        "value": "Hauptzollamt Stralsund"
    },
    {
        "key": "DE009152",
        "value": "Zollamt Wolgast"
    },
    {
        "key": "DE009154",
        "value": "Zollamt Mukran"
    },
    {
        "key": "DE009178",
        "value": "Hauptzollamt Stralsund Dienstort Wolgast"
    },
    {
        "key": "DE009180",
        "value": "Zollamt Stralsund-Dänholm"
    },
    {
        "key": "DE009300",
        "value": "Hauptzollamt Saarbrücken"
    },
    {
        "key": "DE009303",
        "value": "Zollamt Saarbrücken"
    },
    {
        "key": "DE009304",
        "value": "Zollamt Saarbrücken Flughafen"
    },
    {
        "key": "DE009305",
        "value": "Hauptzollamt Saarbrücken, KE Flughafen Saarbrücken"
    },
    {
        "key": "DE009334",
        "value": "Zollamt Saarbrücken Zollservicepunkt Saarlouis"
    },
    {
        "key": "DE009344",
        "value": "Hauptzollamt Saarbrücken Dienstort Kaiserslautern"
    },
    {
        "key": "DE009402",
        "value": "Zollamt Friedrichshafen"
    },
    {
        "key": "DE009404",
        "value": "Zollamt Ravensburg"
    },
    {
        "key": "DE009412",
        "value": "Zollamt Ravensburg Zollservicepunkt Wangen im Allgäu"
    },
    {
        "key": "DE009420",
        "value": "Abfertigungsstelle Friedrichshafen-Fähre"
    },
    {
        "key": "DE009450",
        "value": "Hauptzollamt Heilbronn"
    },
    {
        "key": "DE009452",
        "value": "Zollamt Heilbronn"
    },
    {
        "key": "DE009453",
        "value": "Zollamt Ludwigsburg"
    },
    {
        "key": "DE009456",
        "value": "Zollamt Tauberbischofsheim"
    },
    {
        "key": "DE009459",
        "value": "Zollamt Untermünkheim"
    },
    {
        "key": "DE009500",
        "value": "Hauptzollamt Heilbronn Dienstort Reutlingen"
    },
    {
        "key": "DE009501",
        "value": "Zollamt Albstadt"
    },
    {
        "key": "DE009503",
        "value": "Zollamt Nürtingen"
    },
    {
        "key": "DE009520",
        "value": "Hauptzollamt Ulm Dienstort Pfullingen"
    },
    {
        "key": "DE009530",
        "value": "Zollamt Reutlingen"
    },
    {
        "key": "DE009550",
        "value": "Hauptzollamt Stuttgart"
    },
    {
        "key": "DE009551",
        "value": "Zollamt Böblingen"
    },
    {
        "key": "DE009552",
        "value": "Zollamt Stuttgart-Hafen"
    },
    {
        "key": "DE009554",
        "value": "Zollamt Stuttgart-Zuffenhausen"
    },
    {
        "key": "DE009555",
        "value": "Zollamt Stuttgart-Flughafen"
    },
    {
        "key": "DE009556",
        "value": "Zollamt Winnenden"
    },
    {
        "key": "DE009557",
        "value": "Hauptzollamt Stuttgart, KE Flughafen Stuttgart"
    },
    {
        "key": "DE009589",
        "value": "Abfertigungsstelle Stuttgart-Landesmesse"
    },
    {
        "key": "DE009650",
        "value": "Hauptzollamt Ulm"
    },
    {
        "key": "DE009651",
        "value": "Zollamt Aalen"
    },
    {
        "key": "DE009652",
        "value": "Zollamt Göppingen"
    },
    {
        "key": "DE009653",
        "value": "Hauptzollamt Ulm, KE Flughafen Friedrichshafen"
    },
    {
        "key": "DE009656",
        "value": "Zollamt Donautal"
    },
    {
        "key": "DE009657",
        "value": "Zollamt Biberach"
    },
    {
        "key": "DE012102",
        "value": "Zollamt Berlin-Flughafen Schönefeld"
    },
    {
        "key": "DE012105",
        "value": "Zollamt Berlin-Flughafen-Tegel"
    },
    {
        "key": "DE012325",
        "value": "Zollamt Bremen"
    },
    {
        "key": "DE012452",
        "value": "Zollamt Bremerhaven"
    },
    {
        "key": "DE013302",
        "value": "Zollamt Fracht"
    },
    {
        "key": "DE014002",
        "value": "Zollamt Konstanz-Güterbahnhof"
    },
    {
        "key": "DE014105",
        "value": "Zollamt Singen-Bahnhof"
    },
    {
        "key": "DE014201",
        "value": "Zollamt Erzingen"
    },
    {
        "key": "DE014203",
        "value": "Zollamt Jestetten (schweiz. Gebiet)"
    },
    {
        "key": "DE014204",
        "value": "Zollamt Laufenburg"
    },
    {
        "key": "DE014205",
        "value": "Zollamt Lottstetten"
    },
    {
        "key": "DE014206",
        "value": "Zollamt Stühlingen"
    },
    {
        "key": "DE014208",
        "value": "Zollamt Waldshut"
    },
    {
        "key": "DE014209",
        "value": "Zollamt Bad Säckingen"
    },
    {
        "key": "DE014501",
        "value": "Zollamt Cuxhaven"
    },
    {
        "key": "DE014701",
        "value": "Zollamt Hamburg-Flughafen"
    },
    {
        "key": "DE014851",
        "value": "Zollamt Hamburg"
    },
    {
        "key": "DE015004",
        "value": "Zollamt Emden"
    },
    {
        "key": "DE015008",
        "value": "Zollamt Papenburg"
    },
    {
        "key": "DE015203",
        "value": "Zollamt Stade"
    },
    {
        "key": "DE015301",
        "value": "Zollamt Brake"
    },
    {
        "key": "DE015310",
        "value": "Zollamt Wilhelmshaven"
    },
    {
        "key": "DE016133",
        "value": "Zollamt Flensburg"
    },
    {
        "key": "DE016151",
        "value": "Zollamt Brunsbüttel"
    },
    {
        "key": "DE016155",
        "value": "Zollamt Husum"
    },
    {
        "key": "DE016203",
        "value": "Zollamt Kiel-Wik"
    },
    {
        "key": "DE016206",
        "value": "Zollamt Rendsburg"
    },
    {
        "key": "DE016302",
        "value": "Zollamt Heiligenhafen"
    },
    {
        "key": "DE016332",
        "value": "Abfertigungsstelle Hafen Zollamt Lübeck"
    },
    {
        "key": "DE016756",
        "value": "Zollamt Hahn-Flughafen"
    },
    {
        "key": "DE017154",
        "value": "Zollamt Flughafen Köln/Bonn"
    },
    {
        "key": "DE017352",
        "value": "Zollamt Aschersleben"
    },
    {
        "key": "DE017650",
        "value": "Zollamt München - Flughafen"
    },
    {
        "key": "DE019004",
        "value": "Zollamt Pomellen"
    },
    {
        "key": "DE019030",
        "value": "Zollamt Neubrandenburg"
    },
    {
        "key": "DE019101",
        "value": "Zollamt Ludwigslust"
    },
    {
        "key": "DE019102",
        "value": "Zollamt Laage"
    },
    {
        "key": "DE019103",
        "value": "Zollamt Wismar"
    },
    {
        "key": "DE019104",
        "value": "Zollamt Rostock"
    },
    {
        "key": "DE019152",
        "value": "Zollamt Wolgast"
    },
    {
        "key": "DE019154",
        "value": "Zollamt Mukran"
    },
    {
        "key": "DE019180",
        "value": "Zollamt Stralsund-Dänholm"
    },
    {
        "key": "DE019589",
        "value": "Abfertigungsstelle Landesmesse"
    },
    {
        "key": "DK000010",
        "value": "Post"
    },
    {
        "key": "DK000020",
        "value": "Bane"
    },
    {
        "key": "DK000460",
        "value": "Toldstyrelsen"
    },
    {
        "key": "DK000461",
        "value": "Terminalen Toldekspedition"
    },
    {
        "key": "DK000462",
        "value": "Frihavnen Toldekspedition"
    },
    {
        "key": "DK000463",
        "value": "Københavns Lufthavn Toldekspedition"
    },
    {
        "key": "DK003100",
        "value": "Skattecenter Aalborg"
    },
    {
        "key": "DK003101",
        "value": "Frederikshavn Toldekspedition"
    },
    {
        "key": "DK003102",
        "value": "Hirtshals Toldekspedition"
    },
    {
        "key": "DK003103",
        "value": "Hanstholm Toldekspedition"
    },
    {
        "key": "DK003106",
        "value": "Post Danmark"
    },
    {
        "key": "DK003761",
        "value": "Kvistgaard Toldekspedition"
    },
    {
        "key": "DK003762",
        "value": "Bornholm Toldekspedition"
    },
    {
        "key": "DK003862",
        "value": "Billund Lufthavn Toldekspedition"
    },
    {
        "key": "DK003863",
        "value": "Esbjerg Toldekspedition"
    },
    {
        "key": "DK004700",
        "value": "Skattecenter Århus"
    },
    {
        "key": "DK004701",
        "value": "Århus Havn Toldekspedition"
    },
    {
        "key": "DK004702",
        "value": "Grenå Toldekspedition"
    },
    {
        "key": "DK004706",
        "value": "Herning Toldekspedition"
    },
    {
        "key": "DK005160",
        "value": "ToldSkat Sydsjælland"
    },
    {
        "key": "DK005162",
        "value": "Nørre Alslev Toldekspedition"
    },
    {
        "key": "DK005600",
        "value": "Skattecenter Middelfart"
    },
    {
        "key": "DK005601",
        "value": "Sydjyllands postcenter"
    },
    {
        "key": "DK005602",
        "value": "Fredericia Toldekspedition"
    },
    {
        "key": "DK005603",
        "value": "Kokholm Toldekspedition"
    },
    {
        "key": "DK005604",
        "value": "Padborg Toldekspedition"
    },
    {
        "key": "DK005606",
        "value": "Billund Toldekspedition"
    },
    {
        "key": "DK005607",
        "value": "Esbjerg Toldekspedition"
    },
    {
        "key": "DK005610",
        "value": "Odense Toldekspedition"
    },
    {
        "key": "DK006600",
        "value": "Skattecenter Køge"
    },
    {
        "key": "DK006602",
        "value": "Nr. Alslev Toldekspedition"
    },
    {
        "key": "DK007900",
        "value": "Skattecenter København"
    },
    {
        "key": "DK007901",
        "value": "Sønder Frihavn Toldekspedition"
    },
    {
        "key": "DK007902",
        "value": "Frihavnen Toldekspedition"
    },
    {
        "key": "DK007903",
        "value": "Københavns Lufthavn Toldekspedition"
    },
    {
        "key": "DK007904",
        "value": "Københavns Lufthavn Ankomsthallen"
    },
    {
        "key": "DK007906",
        "value": "København International Postcenter"
    },
    {
        "key": "DK007908",
        "value": "Kvistgaard Toldekspedition"
    },
    {
        "key": "DK007909",
        "value": "Bornholm Toldekspedition"
    },
    {
        "key": "DK008034",
        "value": "Skattestyrelsen Skive"
    },
    {
        "key": "DK008040",
        "value": "Skattestyrelsen Grenaa"
    },
    {
        "key": "DK008047",
        "value": "Skattestyrelsen Aarhus"
    },
    {
        "key": "DK008056",
        "value": "Skattestyrelsen Middelfart"
    },
    {
        "key": "DK008066",
        "value": "Skattecenter Køge (historisk)"
    },
    {
        "key": "DK008079",
        "value": "Skattestyrelsen København"
    },
    {
        "key": "DK008085",
        "value": "Skattestyrelsen Fredensborg"
    },
    {
        "key": "DK009262",
        "value": "Centerhavn Toldekspedition"
    },
    {
        "key": "DK009264",
        "value": "Padborg Toldekspedition"
    },
    {
        "key": "DK009760",
        "value": "Toldekspedition Aalborg"
    },
    {
        "key": "DK009761",
        "value": "Frederikshavn Toldekspedition"
    },
    {
        "key": "DK009762",
        "value": "Hirtshals Toldekspedition"
    },
    {
        "key": "DK009860",
        "value": "Toldekspedition Århus"
    },
    {
        "key": "EE0001EE",
        "value": "Maksu- ja tolliamet"
    },
    {
        "key": "EE1000EE",
        "value": "Tolliosakond"
    },
    {
        "key": "EE1110EE",
        "value": "Tollideklaratsiooni kontrollüksus"
    },
    {
        "key": "EE1111EE",
        "value": "Lennujaama reisiterminali kontroll"
    },
    {
        "key": "EE1112EE",
        "value": "Uurimisosakond"
    },
    {
        "key": "EE1113EE",
        "value": "Sadamate tollikontrolli üksus"
    },
    {
        "key": "EE1114EE",
        "value": "Posti tollikontrolli üksus"
    },
    {
        "key": "EE1160EE",
        "value": "Paldiski teeninduspunkt"
    },
    {
        "key": "EE1210EE",
        "value": "Muuga teeninduskoht"
    },
    {
        "key": "EE1241EE",
        "value": "Muuga vabatsoon"
    },
    {
        "key": "EE1310EE",
        "value": "Lennujaama teeninduskoht"
    },
    {
        "key": "EE3000EE",
        "value": "Teenuste osakond"
    },
    {
        "key": "EE3300EE",
        "value": "Lääne regioon"
    },
    {
        "key": "EE4000EE",
        "value": "Maksude osakond"
    },
    {
        "key": "EE4100EE",
        "value": "Lõuna regioon"
    },
    {
        "key": "EE4200EE",
        "value": "Tolliinfo"
    },
    {
        "key": "EE4700EE",
        "value": "Luhamaa piiripunkt"
    },
    {
        "key": "EE4800EE",
        "value": "Koidula maantee piiripunkt"
    },
    {
        "key": "EE4810EE",
        "value": "Koidula raudtee piiripunkt"
    },
    {
        "key": "EE5100EE",
        "value": "Ida regioon"
    },
    {
        "key": "EE5130EE",
        "value": "Sillamäe teeninduskoht"
    },
    {
        "key": "EE5131EE",
        "value": "Sillamäe vabatsoon"
    },
    {
        "key": "EE5500EE",
        "value": "Narva raudtee piiripunkt"
    },
    {
        "key": "EE5600EE",
        "value": "Narva maantee piiripunkt"
    },
    {
        "key": "ES0000DA",
        "value": "SERVICIO CENTRAL OEA"
    },
    {
        "key": "ES000101",
        "value": "ADUANA AEROPUERTO VITORIA-FORONDA"
    },
    {
        "key": "ES000141",
        "value": "ADUANA DE VITORIA - CARRETERA"
    },
    {
        "key": "ES000150",
        "value": "ADUANA DE VITORIA DOMICILIACIÓN"
    },
    {
        "key": "ES000241",
        "value": "ADUANA DE ALBACETE DOMICILIACIÓN"
    },
    {
        "key": "ES000301",
        "value": "ADUANA AEROPUERTO ALICANTE-ELCHE"
    },
    {
        "key": "ES000311",
        "value": "ADUANA DE ALICANTE - MARÍTIMA"
    },
    {
        "key": "ES000321",
        "value": "ADUANA DE TORREVIEJA"
    },
    {
        "key": "ES000401",
        "value": "ADUANA AEROPUERTO DE ALMERÍA"
    },
    {
        "key": "ES000411",
        "value": "ADUANA DE ALMERIA-MARÍTIMA"
    },
    {
        "key": "ES000415",
        "value": "ALMERÍA-MARÍTIMA CARBONERAS"
    },
    {
        "key": "ES000441",
        "value": "ADUANA DE ALMERIA-CARRETERA"
    },
    {
        "key": "ES000641",
        "value": "ADUANA DE BADAJOZ-CARRETERA."
    },
    {
        "key": "ES000671",
        "value": "ADUANA DE BADAJOZ-FERROCARRIL"
    },
    {
        "key": "ES000701",
        "value": "ADUANA AEROPUERTO PALMA MALLORCA"
    },
    {
        "key": "ES000707",
        "value": "ADUANA AEROPUERTO DE IBIZA"
    },
    {
        "key": "ES000708",
        "value": "ADUANA AEROPUERTO DE MAHÓN"
    },
    {
        "key": "ES000711",
        "value": "ADUANA DE PALMA DE MALLORCA"
    },
    {
        "key": "ES000717",
        "value": "ADUANA DE ALCUDIA"
    },
    {
        "key": "ES000721",
        "value": "ADUANA DE IBIZA MARITIMA"
    },
    {
        "key": "ES000725",
        "value": "ADUANA DE FORMENTERA-LA SAVINA"
    },
    {
        "key": "ES000731",
        "value": "ADUANA DE MAHON - MARITIMA"
    },
    {
        "key": "ES000801",
        "value": "ADUANA AEROPUERTO BARCELONA EL PRAT"
    },
    {
        "key": "ES000811",
        "value": "ADUANA DE BARCELONA - MARÍTIMA"
    },
    {
        "key": "ES000812",
        "value": "ADUANA DE BARCELONA-MARÍTIMA EXPORT"
    },
    {
        "key": "ES000821",
        "value": "ADUANA DE BARCELONA-ZONA FRANCA"
    },
    {
        "key": "ES000841",
        "value": "ADUANA DE BARCELONA - CARRETERA"
    },
    {
        "key": "ES000851",
        "value": "ADUANA DE BARCELONA FERIA MUESTRAS"
    },
    {
        "key": "ES000855",
        "value": "ADUANA DE BARCELONA CENTRALIZADORA"
    },
    {
        "key": "ES000860",
        "value": "ADUANA DE BARCELONA-DOMICILIACIÓN"
    },
    {
        "key": "ES000881",
        "value": "ADUANA DE BARCELONA-FFCC.- MORROT"
    },
    {
        "key": "ES000941",
        "value": "ADUANA DE BURGOS CARRETERA"
    },
    {
        "key": "ES001041",
        "value": "ADUANA DE CÁCERES-CARRETERA"
    },
    {
        "key": "ES001101",
        "value": "ADUANA AEROPUERTO JEREZ DE LA FRONT"
    },
    {
        "key": "ES001111",
        "value": "ADUANA DE CADIZ - PRINCIPAL"
    },
    {
        "key": "ES001115",
        "value": "ADUANA DE CADIZ - ZONA FRANCA"
    },
    {
        "key": "ES001130",
        "value": "ADUANA DE ALGECIRAS DOMICILIACIÓN"
    },
    {
        "key": "ES001131",
        "value": "ADUANA DE ALGECIRAS MARÍTIMA"
    },
    {
        "key": "ES001133",
        "value": "ADUANA DE ALGECIRAS- TARIFA"
    },
    {
        "key": "ES001136",
        "value": "ADUANA DE ALGECIRAS HELIPUERTO"
    },
    {
        "key": "ES001141",
        "value": "ADUANA DE CADIZ - CARRETERA"
    },
    {
        "key": "ES001161",
        "value": "ADUANA DE LÍNEA DE CONCEPCIÓN-MAR"
    },
    {
        "key": "ES001165",
        "value": "ADUANA DE LA LINEA DOMICILIACION"
    },
    {
        "key": "ES001167",
        "value": "ADUANA DE LA LINEA DE LA CONCEPCIÓN"
    },
    {
        "key": "ES001175",
        "value": "ADUANA DE ALGECIRAS CENTRALIZADORA"
    },
    {
        "key": "ES001211",
        "value": "ADUANA DE CASTELLON DE LA PLANA"
    },
    {
        "key": "ES001241",
        "value": "ADUANA DE CASTELLON - CARRETERA"
    },
    {
        "key": "ES001301",
        "value": "ADUANA DE CIUDAD REAL AEROPUERTO"
    },
    {
        "key": "ES001341",
        "value": "ADUANA DE CIUDAD REAL CARRETERA"
    },
    {
        "key": "ES001441",
        "value": "ADUANA DE CORDOBA CARRETERA"
    },
    {
        "key": "ES001501",
        "value": "ADUANA AEROPUERTO A CORUÑA-ALVEDRO"
    },
    {
        "key": "ES001507",
        "value": "ADUANA AEROPUERTO SANTIAGO-LABACOLL"
    },
    {
        "key": "ES001511",
        "value": "ADUANA DE A CORUÑA"
    },
    {
        "key": "ES001517",
        "value": "ADUANA DE A CORUÑA-DEPOSITO FRANCO"
    },
    {
        "key": "ES001521",
        "value": "ADUANA DE EL FERROL-MARITIMA"
    },
    {
        "key": "ES001541",
        "value": "ADUANA DE A CORUÑA CARRETERA"
    },
    {
        "key": "ES001551",
        "value": "ADUANA DE EL FERROL - CARRETERA"
    },
    {
        "key": "ES001641",
        "value": "ADUANA DE CUENCA CARRETERA"
    },
    {
        "key": "ES001701",
        "value": "ADUANA AEROPUERTO GIRONA-COSTA BRAV"
    },
    {
        "key": "ES001711",
        "value": "ADUANA DE PALAMOS-MARITIMA"
    },
    {
        "key": "ES001741",
        "value": "ADUANA DE LA JUNQUERA"
    },
    {
        "key": "ES001755",
        "value": "ADUANA DE LA JUNQUERA-CENTRALIZADOR"
    },
    {
        "key": "ES001761",
        "value": "ADUANA DE VILLAMALLA"
    },
    {
        "key": "ES001771",
        "value": "ADUANA DE PORT BOU - FERROCARRIL"
    },
    {
        "key": "ES001801",
        "value": "ADUANA DE GRANADA-AEROPUERTO"
    },
    {
        "key": "ES001811",
        "value": "ADUANA DE MOTRIL"
    },
    {
        "key": "ES001841",
        "value": "ADUANA DE MOTRIL-CARRETERA"
    },
    {
        "key": "ES001941",
        "value": "ADUANA DE GUADALAJARA CARRETERA"
    },
    {
        "key": "ES001950",
        "value": "ADUANA DE GUADALAJARA DOMICILIACIÓN"
    },
    {
        "key": "ES002001",
        "value": "ADUANA AEROPUERTO IRÚN"
    },
    {
        "key": "ES002011",
        "value": "ADUANA DE PASAJES - MARÍTIMA"
    },
    {
        "key": "ES002045",
        "value": "ADUANA DE IRUN-ZAISA"
    },
    {
        "key": "ES002050",
        "value": "ADUANA DE IRÚN DOMICILIACIÓN"
    },
    {
        "key": "ES002051",
        "value": "ADUANA DE PASAJES CARRETERA"
    },
    {
        "key": "ES002071",
        "value": "ADUANA DE IRÚN FERROCARRIL"
    },
    {
        "key": "ES002111",
        "value": "ADUANA DE HUELVA - PRINCIPAL"
    },
    {
        "key": "ES002141",
        "value": "ADUANA DE HUELVA - CARRETERA"
    },
    {
        "key": "ES002341",
        "value": "ADUANA DE JAÉN DOMICILIACIÓN"
    },
    {
        "key": "ES002441",
        "value": "ADUANA DE LEÓN CARRETERA"
    },
    {
        "key": "ES002541",
        "value": "ADUANA DE LA FARGA DE MOLES"
    },
    {
        "key": "ES002561",
        "value": "ADUANA DE LLEIDA CARRETERA"
    },
    {
        "key": "ES002641",
        "value": "ADUANA DE LOGROÑO"
    },
    {
        "key": "ES002711",
        "value": "ADUANA DE RIBADEO"
    },
    {
        "key": "ES002800",
        "value": "ADUANA DE MADRID-CENTRAL"
    },
    {
        "key": "ES002801",
        "value": "ADUANA AEROPUERTO MADRID-BARAJAS"
    },
    {
        "key": "ES002803",
        "value": "ADUANA POSTAL DE MADRID"
    },
    {
        "key": "ES002841",
        "value": "ADUANA DE MADRID-CARRETERA"
    },
    {
        "key": "ES002855",
        "value": "ADUANA MADRID CENTRALIZADORA"
    },
    {
        "key": "ES002861",
        "value": "ADUANA DE MADRID-DOMICILIACIÓN"
    },
    {
        "key": "ES002871",
        "value": "ADUANA DE MADRID-FERROCARRIL"
    },
    {
        "key": "ES002901",
        "value": "ADUANA AEROPUERTO MÁLAGA"
    },
    {
        "key": "ES002911",
        "value": "ADUANA DE MALAGA-PRINCIPAL"
    },
    {
        "key": "ES002915",
        "value": "ADUANA DE MALAGA- DOMICILIACIÓN"
    },
    {
        "key": "ES002941",
        "value": "ADUANA DE MALAGA-CARRETERA"
    },
    {
        "key": "ES003001",
        "value": "ADUANA DE MURCIA AEROPUERTO"
    },
    {
        "key": "ES003011",
        "value": "ADUANA DE CARTAGENA - PRINCIPAL"
    },
    {
        "key": "ES003041",
        "value": "ADUANA DE CARTAGENA - CARRETERA"
    },
    {
        "key": "ES003051",
        "value": "ADUANA DE MURCIA - CARRETERA"
    },
    {
        "key": "ES003161",
        "value": "ADUANA DE PAMPLONA - IMARCOAÍN"
    },
    {
        "key": "ES003171",
        "value": "ADUANA DE PAMPLONA - NOAÍN FERROC."
    },
    {
        "key": "ES003241",
        "value": "ADUANA DE OURENSE CARRETERA"
    },
    {
        "key": "ES003301",
        "value": "ADUANA AEROPUERTO DE ASTURIAS"
    },
    {
        "key": "ES003311",
        "value": "ADUANA DE GIJON -MARITIMA"
    },
    {
        "key": "ES003331",
        "value": "ADUANA DE AVILÉS MARÍTIMA"
    },
    {
        "key": "ES003341",
        "value": "ADUANA DE GIJÓN CARRETERA"
    },
    {
        "key": "ES003351",
        "value": "ADUANA DE AVILES CARRETERA"
    },
    {
        "key": "ES003371",
        "value": "ADUANA DE GIJON - FERROCARRIL"
    },
    {
        "key": "ES003441",
        "value": "ADUANA DE PALENCIA-CARRETERA"
    },
    {
        "key": "ES003541",
        "value": "ADUANA DE FUERTEVENTURA-MAR. (T1)"
    },
    {
        "key": "ES003551",
        "value": "ADUANA AEROPUERTO FUERTEVENTURA"
    },
    {
        "key": "ES003561",
        "value": "ADUANA DE LANZAROTE-MARITIMA (T1)"
    },
    {
        "key": "ES003571",
        "value": "ADUANA AEROPUERTO LANZAROTE"
    },
    {
        "key": "ES003581",
        "value": "ADUANA AEROPUERTO GRAN CANARIA"
    },
    {
        "key": "ES003591",
        "value": "ADUANA DE LAS PALMAS MARÍTIMA (T1)"
    },
    {
        "key": "ES003593",
        "value": "ADUANA DE GRAN CANARIA POSTAL"
    },
    {
        "key": "ES003601",
        "value": "ADUANA AEROPUERTO VIGO"
    },
    {
        "key": "ES003611",
        "value": "ADUANA DE VIGO-PRINCIPAL"
    },
    {
        "key": "ES003621",
        "value": "ADUANA DE MARIN"
    },
    {
        "key": "ES003631",
        "value": "ADUANA DE VILAGARCIA DE AROUSA-MAR."
    },
    {
        "key": "ES003641",
        "value": "ADUANA DE VIGO CARRETERA"
    },
    {
        "key": "ES003651",
        "value": "ADUANA DE VILAGARCIA DE AROUSA CAR."
    },
    {
        "key": "ES003681",
        "value": "ADUANA DE VIGO-ZONA FRANCA"
    },
    {
        "key": "ES003691",
        "value": "ADUANA DE VIGO DOMICILIACIÓN"
    },
    {
        "key": "ES003741",
        "value": "ADUANA DE SALAMANCA-CARRETERA"
    },
    {
        "key": "ES003861",
        "value": "ADUANA DE STA CRUZ DE PALMA-MAR.T1"
    },
    {
        "key": "ES003871",
        "value": "ADUANA AEROPUERTO LA PALMA"
    },
    {
        "key": "ES003881",
        "value": "ADUANA AEROPUERTO TENERIFE SUR"
    },
    {
        "key": "ES003883",
        "value": "ADUANA AEROPUERTO TENERIFE NORTE"
    },
    {
        "key": "ES003891",
        "value": "ADUANA DE TENERIFE-MARÍTIMA"
    },
    {
        "key": "ES003893",
        "value": "ADUANA DE TENERIFE POSTALES"
    },
    {
        "key": "ES003901",
        "value": "ADUANA AEROPUERTO SANTANDER"
    },
    {
        "key": "ES003911",
        "value": "ADUANA DE SANTANDER-MARITIMA"
    },
    {
        "key": "ES003921",
        "value": "ADUANA DE SANTANDER ZONA FRANCA"
    },
    {
        "key": "ES003941",
        "value": "ADUANA DE SANTANDER CARRETERA"
    },
    {
        "key": "ES003955",
        "value": "ADUANA DE SANTANDER-CENTRALIZADORA"
    },
    {
        "key": "ES003971",
        "value": "ADUANA DE SANTANDER-FERROCARRIL"
    },
    {
        "key": "ES004101",
        "value": "ADUANA AEROPUERTO SEVILLA"
    },
    {
        "key": "ES004111",
        "value": "ADUANA DE SEVILLA-PRINCIPAL"
    },
    {
        "key": "ES004115",
        "value": "SEVILLA-ZONA FRANCA"
    },
    {
        "key": "ES004141",
        "value": "ADUANA DE SEVILLA CARRETERA"
    },
    {
        "key": "ES004241",
        "value": "ADUANA DE SORIA-CARRETERA"
    },
    {
        "key": "ES004301",
        "value": "ADUANA TARRAGONA AEROPUERTO (REUS)"
    },
    {
        "key": "ES004311",
        "value": "ADUANA DE TARRAGONA-MARITIMA"
    },
    {
        "key": "ES004321",
        "value": "ADUANA DE SAN CARLOS DE LA RÁPITA"
    },
    {
        "key": "ES004341",
        "value": "ADUANA DE TARRAGONA-CARRETERA"
    },
    {
        "key": "ES004350",
        "value": "ADUANA DE TARRAGONA-DOMICIL. Y DEP."
    },
    {
        "key": "ES004371",
        "value": "ADUANA DE TARRAGONA-FEROCARRIL"
    },
    {
        "key": "ES004541",
        "value": "ADUANA DE TOLEDO DOMICILIACIÓN"
    },
    {
        "key": "ES004601",
        "value": "ADUANA AEROPUERTO VALENCIA"
    },
    {
        "key": "ES004611",
        "value": "ADUANA DE VALENCIA MARÍTIMA"
    },
    {
        "key": "ES004621",
        "value": "ADUANA DE SAGUNTO-MARITIMA"
    },
    {
        "key": "ES004631",
        "value": "ADUANA DE GANDIA-MARITIMA"
    },
    {
        "key": "ES004641",
        "value": "ADUANA DE VALENCIA CARRETERA"
    },
    {
        "key": "ES004647",
        "value": "ADUANA DE SILLA-CARRETERA"
    },
    {
        "key": "ES004650",
        "value": "ADUANA DE VALENCIA-DOMICILIACIÓN"
    },
    {
        "key": "ES004651",
        "value": "ADUANA DE SAGUNTO CARRETERA"
    },
    {
        "key": "ES004661",
        "value": "ADUANA DE GANDIA - CARRETERA"
    },
    {
        "key": "ES004741",
        "value": "ADUANA DE VALLADOLID"
    },
    {
        "key": "ES004801",
        "value": "ADUANA AEROPUERTO BILBAO"
    },
    {
        "key": "ES004811",
        "value": "ADUANA DE BILBAO-PRINCIPAL"
    },
    {
        "key": "ES004825",
        "value": "ADUANA DE BILBAO-ZAL"
    },
    {
        "key": "ES004842",
        "value": "ADUANA DE BILBAO - CARRETERA"
    },
    {
        "key": "ES004850",
        "value": "ADUANA DE BILBAO DOMICILIACIÓN"
    },
    {
        "key": "ES005001",
        "value": "ADUANA AEROPUERTO ZARAGOZA"
    },
    {
        "key": "ES005005",
        "value": "ADUANA DE ZARAGOZA - CENTRALIZADORA"
    },
    {
        "key": "ES005041",
        "value": "ADUANA DE ZARAGOZA CARRETERA/TIR"
    },
    {
        "key": "ES005060",
        "value": "ADUANA DE ZARAGOZA-DOMICILIACION"
    },
    {
        "key": "ESC17200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESC53200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD01200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD01600",
        "value": "DEP.ADUANAS E II.EE. ALAVA"
    },
    {
        "key": "ESD02200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD02600",
        "value": "DEP.ADUANAS E IIEE ALBACETE"
    },
    {
        "key": "ESD03200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD03600",
        "value": "DEP.ADUANAS E II.EE. ALICANTE"
    },
    {
        "key": "ESD04200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD04600",
        "value": "DEP.ADUANAS E II.EE. ALMERÍA"
    },
    {
        "key": "ESD05200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD05600",
        "value": "DEP.ADUANAS E II.EE. AVILA"
    },
    {
        "key": "ESD06200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD06600",
        "value": "DEP.REG.EXTREMADURA"
    },
    {
        "key": "ESD07200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD07600",
        "value": "DEP.REG.ISLAS BALEARES"
    },
    {
        "key": "ESD08200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD08600",
        "value": "DEP.REG.CATALUÑA"
    },
    {
        "key": "ESD09200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD09600",
        "value": "DEP.ADUANAS E II.EE. BURGOS"
    },
    {
        "key": "ESD10200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD10600",
        "value": "DEP. ADUANAS E II.EE.CÁCERES"
    },
    {
        "key": "ESD11200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD11600",
        "value": "DEP.ADUANAS E II.EE. CÁDIZ"
    },
    {
        "key": "ESD11601",
        "value": "DEP. ADUANAS E IIEE ALGECIRAS"
    },
    {
        "key": "ESD12200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD12600",
        "value": "DEP ADUANAS E II.EE. CASTELLÓN"
    },
    {
        "key": "ESD13200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD13600",
        "value": "DEP.ADUANAS E II EE. CIUDAD REAL"
    },
    {
        "key": "ESD14200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD14600",
        "value": "DEP.ADUANAS E II.EE. CORDOBA"
    },
    {
        "key": "ESD15200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD15600",
        "value": "DEP.REG.GALICIA"
    },
    {
        "key": "ESD16200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD16600",
        "value": "DEP ADUANAS E II EE CUENCA"
    },
    {
        "key": "ESD17200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD17600",
        "value": "DEP. ADUANAS E IIEE GERONA"
    },
    {
        "key": "ESD18200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD18600",
        "value": "DEP. ADUANAS E II.EE. GRANADA"
    },
    {
        "key": "ESD19200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD19600",
        "value": "DEP. ADUANAS E II .EE. GUADALAJARA"
    },
    {
        "key": "ESD20200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD20600",
        "value": "DEP.ADUANAS E II.EE. GIPUZKOA"
    },
    {
        "key": "ESD21200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD21600",
        "value": "DEP. ADUANAS E II.EE. HUELVA"
    },
    {
        "key": "ESD22200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD22600",
        "value": "DEP.ADUANAS E II.EE. HUESCA"
    },
    {
        "key": "ESD23200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD23600",
        "value": "DEP.ADUANAS E II.EE. JAEN"
    },
    {
        "key": "ESD24200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD24600",
        "value": "DEP.ADUANAS E II.EE. LEÓN"
    },
    {
        "key": "ESD25200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD25600",
        "value": "DEP. ADUANAS E IIEE LLEIDA"
    },
    {
        "key": "ESD26200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD26600",
        "value": "DEP.REG.LA RIOJA"
    },
    {
        "key": "ESD27200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD27600",
        "value": "DEP.ADUANAS E II.EE. LUGO"
    },
    {
        "key": "ESD28200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD28600",
        "value": "DEP.REG.MADRID"
    },
    {
        "key": "ESD28920",
        "value": "S.G. II.EE. - DPTO. ADUANAS E IIEE"
    },
    {
        "key": "ESD29200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD29600",
        "value": "DEP. ADUANAS E IIEE MALAGA"
    },
    {
        "key": "ESD30200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD30600",
        "value": "DEP.REG.MURCIA"
    },
    {
        "key": "ESD31200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD31600",
        "value": "DEP.REG.NAVARRA"
    },
    {
        "key": "ESD32200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD32600",
        "value": "DEP.ADUANAS E II.EE. OURENSE"
    },
    {
        "key": "ESD33200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD33600",
        "value": "DEP.REG.ASTURIAS"
    },
    {
        "key": "ESD34200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD34600",
        "value": "DEP.ADUANAS E II.EE. PALENCIA"
    },
    {
        "key": "ESD35600",
        "value": "DEP.REG.ISLAS CANARIAS"
    },
    {
        "key": "ESD36200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD36600",
        "value": "DEP.ADUANAS E II.EE. PONTEVEDRA"
    },
    {
        "key": "ESD37200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD37600",
        "value": "DEP.ADUANAS E II.EE. SALAMANCA"
    },
    {
        "key": "ESD38600",
        "value": "DEP ADUANAS E II.EE. TENERIFE"
    },
    {
        "key": "ESD39200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD39600",
        "value": "DEP.REG.CANTABRIA"
    },
    {
        "key": "ESD40200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD40600",
        "value": "DEP.ADUANAS E II.EE. SEGOVIA"
    },
    {
        "key": "ESD41200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD41600",
        "value": "DEP.REG.ANDALUCÍA,CEUTA Y MELILLA"
    },
    {
        "key": "ESD42200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD42600",
        "value": "DEP.ADUANAS E II.EE. SORIA"
    },
    {
        "key": "ESD43200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD43600",
        "value": "DEP ADUANAS E II.EE. TARRAGONA"
    },
    {
        "key": "ESD44200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD44600",
        "value": "DEP.ADUANAS E II.EE. TERUEL"
    },
    {
        "key": "ESD45200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD45600",
        "value": "DEP.REG.CASTILLA LA MANCHA"
    },
    {
        "key": "ESD46200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD46600",
        "value": "DEP.REG.VALENCIA"
    },
    {
        "key": "ESD47200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD47600",
        "value": "DEP.REG.CASTILLA LEÓN"
    },
    {
        "key": "ESD48200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD48600",
        "value": "DEP.REG.PAIS VASCO"
    },
    {
        "key": "ESD49200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD49600",
        "value": "DEP.ADUANAS E II.EE. ZAMORA"
    },
    {
        "key": "ESD50200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD50600",
        "value": "DEP.REG.ARAGON"
    },
    {
        "key": "ESD51200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD51600",
        "value": "DEP.ADUANAS E II.EE. CARTAGENA"
    },
    {
        "key": "ESD52200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD52600",
        "value": "DEP.ADUANAS E II.EE. GIJÓN"
    },
    {
        "key": "ESD53200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD53600",
        "value": "DEP. ADUANAS E IIEE JEREZ FRONTERA"
    },
    {
        "key": "ESD54200",
        "value": "OF. GESTORA DE IMPUESTOS ESPECIALES"
    },
    {
        "key": "ESD54600",
        "value": "DEP.ADUANAS E II.EE. VIGO"
    },
    {
        "key": "ESD57960",
        "value": "DEPARTAMENTO DE ADUANAS E II.EE."
    },
    {
        "key": "FI001000",
        "value": "Tulli, Valvontaosasto Rajoitukset"
    },
    {
        "key": "FI001200",
        "value": "Tulli Hallinto"
    },
    {
        "key": "FI001210",
        "value": "Ulkomaankauppa- ja verotus Tulli"
    },
    {
        "key": "FI001400",
        "value": "HELSINKI Eteläsatama Tulli"
    },
    {
        "key": "FI001602",
        "value": "HELSINKI Katajanokka K7 Tulli"
    },
    {
        "key": "FI001604",
        "value": "Helsinki Tulli KatajanokkaK9"
    },
    {
        "key": "FI001801",
        "value": "Helsinki Länsisatama L4 Tulli"
    },
    {
        "key": "FI001802",
        "value": "Helsinki Tulli Länsisatama L5"
    },
    {
        "key": "FI002000",
        "value": "Sähköinen Palvelukeskus"
    },
    {
        "key": "FI002001",
        "value": "Sähköinen Passituskeskus"
    },
    {
        "key": "FI002002",
        "value": "Tulli, Tullauskeskus Vienti"
    },
    {
        "key": "FI002006",
        "value": "Tulli, Sähköinen Palvelukeskus"
    },
    {
        "key": "FI005000",
        "value": "Helsinki Tulli"
    },
    {
        "key": "FI009800",
        "value": "Meritulli Vuosaari satama Gatehouse"
    },
    {
        "key": "FI009801",
        "value": "Meritullli Vuosaari satama V6"
    },
    {
        "key": "FI009802",
        "value": "Meritulli Vuosaari Hansaterminaali"
    },
    {
        "key": "FI015300",
        "value": "Helsinki-Vantaa Lentotulli"
    },
    {
        "key": "FI015301",
        "value": "Vantaa Postitulli"
    },
    {
        "key": "FI015302",
        "value": "Helsinki-Vantaa Lento MatkustajaT"
    },
    {
        "key": "FI077099",
        "value": "Verohallinto"
    },
    {
        "key": "FI079000",
        "value": "Tulli Loviisa Satama"
    },
    {
        "key": "FI108200",
        "value": "Lappohjan Satama"
    },
    {
        "key": "FI109000",
        "value": "Hanko Tulli"
    },
    {
        "key": "FI109001",
        "value": "Hanko Tulli Vapaasatama"
    },
    {
        "key": "FI151000",
        "value": "TULLI Lahti Tulli"
    },
    {
        "key": "FI201000",
        "value": "TULLI Turku Tulli"
    },
    {
        "key": "FI201003",
        "value": "Turku Tulli"
    },
    {
        "key": "FI203600",
        "value": "Turku Lentotulli"
    },
    {
        "key": "FI211000",
        "value": "TULLI Naantali"
    },
    {
        "key": "FI221001",
        "value": "Mariehamn Tull Hamnterminal"
    },
    {
        "key": "FI222700",
        "value": "TULL Eckerö Tulli"
    },
    {
        "key": "FI226300",
        "value": "Långnäs Tulli"
    },
    {
        "key": "FI235000",
        "value": "TULLI Uusikaupunki Tulli"
    },
    {
        "key": "FI261000",
        "value": "TULLI Rauma"
    },
    {
        "key": "FI288800",
        "value": "Pori Tulli"
    },
    {
        "key": "FI339000",
        "value": "Tampere Tulli"
    },
    {
        "key": "FI403200",
        "value": "TULLI Jyväskylä Tulli"
    },
    {
        "key": "FI482000",
        "value": "Kotka Hietanen Tulli"
    },
    {
        "key": "FI483100",
        "value": "TULLI Kotka Tulli"
    },
    {
        "key": "FI494600",
        "value": "TULLI Hamina"
    },
    {
        "key": "FI499300",
        "value": "TULLI VAALIMAA"
    },
    {
        "key": "FI542300",
        "value": "TULLI Nuijamaa Tulli"
    },
    {
        "key": "FI542700",
        "value": "TULLI Vainikkala Tulli"
    },
    {
        "key": "FI556100",
        "value": "TULLI Imatra Tulli"
    },
    {
        "key": "FI642600",
        "value": "TULLI Kaskinen Tulli"
    },
    {
        "key": "FI651700",
        "value": "TULLI Vaasa"
    },
    {
        "key": "FI651704",
        "value": "Vaasa Lupakeskus"
    },
    {
        "key": "FI671000",
        "value": "TULLI Kokkola Tulli"
    },
    {
        "key": "FI686000",
        "value": "Tulli Pietarsaari Satama"
    },
    {
        "key": "FI826750",
        "value": "TULLI NIIRALA"
    },
    {
        "key": "FI889300",
        "value": "TULLI Vartius Tulli"
    },
    {
        "key": "FI904000",
        "value": "TULLI OULU"
    },
    {
        "key": "FI904001",
        "value": "OULU LENTOTULLI"
    },
    {
        "key": "FI904006",
        "value": "Oulu, Lupakeskus"
    },
    {
        "key": "FI939990",
        "value": "TULLI Kuusamo Tulli"
    },
    {
        "key": "FI941000",
        "value": "Tulli Kemin Satama"
    },
    {
        "key": "FI954000",
        "value": "TULLI Tornio Tulli"
    },
    {
        "key": "FI954001",
        "value": "TULLI Tornio"
    },
    {
        "key": "FI989200",
        "value": "TULLI Salla Tulli"
    },
    {
        "key": "FI994400",
        "value": "TULLI Kivilompolo Tulli"
    },
    {
        "key": "FI994900",
        "value": "TULLI Kilpisjärvi Tulli"
    },
    {
        "key": "FI998001",
        "value": "TULLI Raja-Jooseppi Tulli"
    },
    {
        "key": "FI998002",
        "value": "TULLI Näätämö tulli"
    },
    {
        "key": "FI999500",
        "value": "TULLI Karigasniemi Tulli"
    },
    {
        "key": "FI999800",
        "value": "TULLI Utsjoki Tulli"
    },
    {
        "key": "FI999801",
        "value": "TULLI Polmak Tulli"
    },
    {
        "key": "FR000040",
        "value": "Ajaccio port"
    },
    {
        "key": "FR000100",
        "value": "Amiens bureau"
    },
    {
        "key": "FR000120",
        "value": "Angers bureau"
    },
    {
        "key": "FR000130",
        "value": "Angouleme bureau"
    },
    {
        "key": "FR000140",
        "value": "Annecy bureau"
    },
    {
        "key": "FR000170",
        "value": "Dunkerque energies bureau"
    },
    {
        "key": "FR000220",
        "value": "Arcachon port bureau"
    },
    {
        "key": "FR000270",
        "value": "Avignon bureau"
    },
    {
        "key": "FR000340",
        "value": "Bale mulhouse aero bureau"
    },
    {
        "key": "FR000380",
        "value": "Bastia port"
    },
    {
        "key": "FR000390",
        "value": "Bayonne bureau"
    },
    {
        "key": "FR000460",
        "value": "Besancon bureau"
    },
    {
        "key": "FR000530",
        "value": "Blois bureau"
    },
    {
        "key": "FR000600",
        "value": "Bordeaux merignac bureau"
    },
    {
        "key": "FR000610",
        "value": "Bordeaux bassens pt bureau"
    },
    {
        "key": "FR000630",
        "value": "Boulogne bureau"
    },
    {
        "key": "FR000640",
        "value": "Bourges bureau"
    },
    {
        "key": "FR000690",
        "value": "Brest bureau"
    },
    {
        "key": "FR000720",
        "value": "Caen bureau"
    },
    {
        "key": "FR000740",
        "value": "Calais bureau"
    },
    {
        "key": "FR000800",
        "value": "Cannes bureau"
    },
    {
        "key": "FR000860",
        "value": "Chalon s-saone bureau"
    },
    {
        "key": "FR000870",
        "value": "Chambery bureau"
    },
    {
        "key": "FR000900",
        "value": "Charleville bureau"
    },
    {
        "key": "FR000910",
        "value": "Chateauroux bureau"
    },
    {
        "key": "FR000950",
        "value": "Cherbourg bureau"
    },
    {
        "key": "FR000980",
        "value": "Clermont bureau"
    },
    {
        "key": "FR001010",
        "value": "Colmar bureau"
    },
    {
        "key": "FR001050",
        "value": "Divonne rte"
    },
    {
        "key": "FR001140",
        "value": "Delle bureau"
    },
    {
        "key": "FR001170",
        "value": "Dijon bureau"
    },
    {
        "key": "FR001200",
        "value": "Donges raffinerie"
    },
    {
        "key": "FR001260",
        "value": "Dunkerque port bureau"
    },
    {
        "key": "FR001280",
        "value": "Ennery bureau"
    },
    {
        "key": "FR001290",
        "value": "Epinal bureau"
    },
    {
        "key": "FR001370",
        "value": "Evreux bureau"
    },
    {
        "key": "FR001400",
        "value": "Ferney voltaire bureau"
    },
    {
        "key": "FR001560",
        "value": "Gonfreville raffinerie"
    },
    {
        "key": "FR001610",
        "value": "Grenoble bureau"
    },
    {
        "key": "FR002010",
        "value": "La ferriere rte"
    },
    {
        "key": "FR00201S",
        "value": "Ferriere sous jougne"
    },
    {
        "key": "FR002030",
        "value": "L'isle d'abeau bureau"
    },
    {
        "key": "FR002090",
        "value": "La rochelle pallice bureau"
    },
    {
        "key": "FR002270",
        "value": "Le havre antifer bureau"
    },
    {
        "key": "FR002300",
        "value": "Le Havre Port bureau"
    },
    {
        "key": "FR002310",
        "value": "St brieuc bureau"
    },
    {
        "key": "FR002340",
        "value": "Le mans bureau"
    },
    {
        "key": "FR002430",
        "value": "Les sables d'olonne bureau"
    },
    {
        "key": "FR002510",
        "value": "Porta bureau"
    },
    {
        "key": "FR002540",
        "value": "Limoges bureau"
    },
    {
        "key": "FR002600",
        "value": "Lorient bureau"
    },
    {
        "key": "FR002630",
        "value": "Lyon ville bureau"
    },
    {
        "key": "FR002650",
        "value": "Lyon St Exupery bureau"
    },
    {
        "key": "FR002710",
        "value": "Marignane aero bureau"
    },
    {
        "key": "FR002730",
        "value": "Marseille port bureau"
    },
    {
        "key": "FR002860",
        "value": "Monaco bureau"
    },
    {
        "key": "FR002970",
        "value": "Morteau bureau"
    },
    {
        "key": "FR00297S",
        "value": "COL FRANCE BC"
    },
    {
        "key": "FR002990",
        "value": "Mulhouse bureau"
    },
    {
        "key": "FR003000",
        "value": "Nancy bureau"
    },
    {
        "key": "FR003030",
        "value": "Nantes atlant. bureau"
    },
    {
        "key": "FR003040",
        "value": "Nantes viti ci"
    },
    {
        "key": "FR003080",
        "value": "Nice aero bureau"
    },
    {
        "key": "FR003100",
        "value": "Nice port bureau"
    },
    {
        "key": "FR003120",
        "value": "Nimes bureau"
    },
    {
        "key": "FR003160",
        "value": "Orleans bureau"
    },
    {
        "key": "FR003230",
        "value": "Paris C.I."
    },
    {
        "key": "FR003260",
        "value": "Paris principal bureau"
    },
    {
        "key": "FR003350",
        "value": "Le Bourget bureau"
    },
    {
        "key": "FR003370",
        "value": "Orly aero bureau"
    },
    {
        "key": "FR003410",
        "value": "Gennevilliers bureau"
    },
    {
        "key": "FR003440",
        "value": "Pau bureau"
    },
    {
        "key": "FR003480",
        "value": "Perpignan bureau"
    },
    {
        "key": "FR003500",
        "value": "Rouen Energies"
    },
    {
        "key": "FR003540",
        "value": "Poitiers bureau"
    },
    {
        "key": "FR003550",
        "value": "Pontarlier bureau"
    },
    {
        "key": "FR00355S",
        "value": "VERRIERES-DE-JOUX BC"
    },
    {
        "key": "FR003620",
        "value": "Port de Bouc Energies"
    },
    {
        "key": "FR003630",
        "value": "Port jerome raffinerie"
    },
    {
        "key": "FR003650",
        "value": "Port la nouvelle bureau"
    },
    {
        "key": "FR003680",
        "value": "Fos port st louis pt bureau"
    },
    {
        "key": "FR003690",
        "value": "Port Vendres port"
    },
    {
        "key": "FR003790",
        "value": "Reims bureau"
    },
    {
        "key": "FR003800",
        "value": "Rennes bureau"
    },
    {
        "key": "FR003920",
        "value": "Rouen port bureau"
    },
    {
        "key": "FR003930",
        "value": "Rouen fiscalités bureau"
    },
    {
        "key": "FR003940",
        "value": "Saint Avold bureau"
    },
    {
        "key": "FR00395S",
        "value": "LES ROUSSES BCS"
    },
    {
        "key": "FR003970",
        "value": "St Etienne bureau"
    },
    {
        "key": "FR004020",
        "value": "St julien bardon. bureau"
    },
    {
        "key": "FR004050",
        "value": "St louis autoroute"
    },
    {
        "key": "FR004060",
        "value": "St malo bureau"
    },
    {
        "key": "FR004080",
        "value": "St nazaire montoir bureau"
    },
    {
        "key": "FR004250",
        "value": "Sete bureau"
    },
    {
        "key": "FR004310",
        "value": "Strasbourg-Entzheim bureau"
    },
    {
        "key": "FR004320",
        "value": "Strasbourg energies grand est"
    },
    {
        "key": "FR004350",
        "value": "Tarbes bureau"
    },
    {
        "key": "FR00439A",
        "value": "SAINT-GINGOLPH BC"
    },
    {
        "key": "FR004430",
        "value": "Toulon la seyne bureau"
    },
    {
        "key": "FR004440",
        "value": "Toulouse blagnac aero bureau"
    },
    {
        "key": "FR004450",
        "value": "Toulouse portet bureau"
    },
    {
        "key": "FR004490",
        "value": "Tours bureau"
    },
    {
        "key": "FR004520",
        "value": "Troyes bureau"
    },
    {
        "key": "FR004550",
        "value": "Valence bureau"
    },
    {
        "key": "FR004560",
        "value": "Valenciennes bureau"
    },
    {
        "key": "FR004850",
        "value": "Lyon energies inter"
    },
    {
        "key": "FR004870",
        "value": "Lille bureau"
    },
    {
        "key": "FR004910",
        "value": "Perigueux bureau"
    },
    {
        "key": "FR004960",
        "value": "Brive bureau"
    },
    {
        "key": "FR004990",
        "value": "Compiegne bureau"
    },
    {
        "key": "FR005000",
        "value": "Laval bureau"
    },
    {
        "key": "FR005040",
        "value": "St quentin bureau"
    },
    {
        "key": "FR005070",
        "value": "Niort bureau"
    },
    {
        "key": "FR005090",
        "value": "Vesoul bureau"
    },
    {
        "key": "FR005130",
        "value": "Agen bureau"
    },
    {
        "key": "FR005160",
        "value": "Chartres bureau"
    },
    {
        "key": "FR005180",
        "value": "Nevers bureau"
    },
    {
        "key": "FR005190",
        "value": "Grandpuits raffinerie"
    },
    {
        "key": "FR005260",
        "value": "Lons le saunier bureau"
    },
    {
        "key": "FR005330",
        "value": "Melun bureau"
    },
    {
        "key": "FR005340",
        "value": "Aulnay-sous-Bois bureau"
    },
    {
        "key": "FR005350",
        "value": "Lesquin bureau"
    },
    {
        "key": "FR005360",
        "value": "Macon bureau"
    },
    {
        "key": "FR005440",
        "value": "Corbeil evry bureau"
    },
    {
        "key": "FR005470",
        "value": "Cahors bureau"
    },
    {
        "key": "FR005480",
        "value": "Rodez bureau"
    },
    {
        "key": "FR005500",
        "value": "Auxerre bureau"
    },
    {
        "key": "FR005580",
        "value": "Haguenau bureau"
    },
    {
        "key": "FR005590",
        "value": "Trappes bureau"
    },
    {
        "key": "FR005620",
        "value": "Arras bureau"
    },
    {
        "key": "FR005630",
        "value": "Le puy bureau"
    },
    {
        "key": "FR005660",
        "value": "Bar le duc bureau"
    },
    {
        "key": "FR005680",
        "value": "Montauban bureau"
    },
    {
        "key": "FR005750",
        "value": "Moulins bureau"
    },
    {
        "key": "FR005760",
        "value": "Cergy pontoise bureau"
    },
    {
        "key": "FR005800",
        "value": "Albi bureau"
    },
    {
        "key": "FR005810",
        "value": "Auch bureau"
    },
    {
        "key": "FR005830",
        "value": "Gap bureau"
    },
    {
        "key": "FR005850",
        "value": "Mont de marsan bureau"
    },
    {
        "key": "FR005880",
        "value": "Gueret bureau"
    },
    {
        "key": "FR005900",
        "value": "Aurillac bureau"
    },
    {
        "key": "FR005910",
        "value": "Chaumont bureau"
    },
    {
        "key": "FR006130",
        "value": "Rungis bureau"
    },
    {
        "key": "FR006150",
        "value": "Marseille transports bureau"
    },
    {
        "key": "FR00617C",
        "value": "Roissy France handling bureau"
    },
    {
        "key": "FR00619C",
        "value": "Roissy Fedex bureau"
    },
    {
        "key": "FR006240",
        "value": "La roche sur yon bureau"
    },
    {
        "key": "FR006270",
        "value": "Marne la vallee bureau"
    },
    {
        "key": "FR006320",
        "value": "Pointe a pitre port"
    },
    {
        "key": "FR006330",
        "value": "Le raizet bureau"
    },
    {
        "key": "FR006340",
        "value": "Fort de france port bureau"
    },
    {
        "key": "FR006370",
        "value": "Degrad d cannes port bureau"
    },
    {
        "key": "FR006380",
        "value": "Cayenne aero bureau"
    },
    {
        "key": "FR006390",
        "value": "St laurent maroni bureau"
    },
    {
        "key": "FR006420",
        "value": "Le port bureau"
    },
    {
        "key": "FR006430",
        "value": "Saint Pierre bureau"
    },
    {
        "key": "FR006440",
        "value": "Le lamentin aero bureau"
    },
    {
        "key": "FR006460",
        "value": "St denis aero"
    },
    {
        "key": "FR006480",
        "value": "Le lamentin raffinerie"
    },
    {
        "key": "FR006500",
        "value": "Fort de france mess. bureau"
    },
    {
        "key": "FR006530",
        "value": "Cayenne CDP"
    },
    {
        "key": "FR006600",
        "value": "Mende bureau"
    },
    {
        "key": "FR006610",
        "value": "La pointe-jarry port"
    },
    {
        "key": "FR006660",
        "value": "Foix bureau"
    },
    {
        "key": "FR006670",
        "value": "Vallard thonex bureau"
    },
    {
        "key": "FR006710",
        "value": "Montpellier Mediterranee aero bur"
    },
    {
        "key": "FR006750",
        "value": "St georges de l'oyapok bureau"
    },
    {
        "key": "FR00677A",
        "value": "Roissy prise en charge"
    },
    {
        "key": "FR006790",
        "value": "Chilly-mazarin BDP"
    },
    {
        "key": "FR006800",
        "value": "Longoni port bureau"
    },
    {
        "key": "FR006810",
        "value": "Pamandzi bureau"
    },
    {
        "key": "FR007730",
        "value": "Paris recette regionale"
    },
    {
        "key": "FR007740",
        "value": "Ile de France recette regionale"
    },
    {
        "key": "FR007760",
        "value": "Orly recette regionale"
    },
    {
        "key": "FR007780",
        "value": "Paris est recette regionale"
    },
    {
        "key": "FR007830",
        "value": "Roissy recette regionale"
    },
    {
        "key": "FR007880",
        "value": "Paris ouest recette regionale"
    },
    {
        "key": "FR007930",
        "value": "Centre recette regionale"
    },
    {
        "key": "FR008010",
        "value": "Dunkerque recette interregionale"
    },
    {
        "key": "FR008130",
        "value": "Valenciennes recette regionale"
    },
    {
        "key": "FR008180",
        "value": "Picardie recette regionale"
    },
    {
        "key": "FR008240",
        "value": "Reims recette regionale"
    },
    {
        "key": "FR008310",
        "value": "Nancy recette regionale"
    },
    {
        "key": "FR008380",
        "value": "Metz recette regionale"
    },
    {
        "key": "FR008440",
        "value": "Strasbourg recette regionale"
    },
    {
        "key": "FR008490",
        "value": "Mulhouse recette regionale"
    },
    {
        "key": "FR008540",
        "value": "Besancon recette regionale"
    },
    {
        "key": "FR008620",
        "value": "Dijon recette interregionale"
    },
    {
        "key": "FR008650",
        "value": "Lyon recette regionale"
    },
    {
        "key": "FR008700",
        "value": "Auvergne recette regionale"
    },
    {
        "key": "FR008730",
        "value": "Leman recette regionale"
    },
    {
        "key": "FR008750",
        "value": "Chambery recette regionale"
    },
    {
        "key": "FR008810",
        "value": "Nice recette regionale"
    },
    {
        "key": "FR008860",
        "value": "Corse recette regionale"
    },
    {
        "key": "FR008900",
        "value": "Provence recette regionale"
    },
    {
        "key": "FR008980",
        "value": "Marseille recette regionale"
    },
    {
        "key": "FR009030",
        "value": "Montpellier Recette regionale"
    },
    {
        "key": "FR009080",
        "value": "Recette regionale de perpignan"
    },
    {
        "key": "FR009130",
        "value": "Toulouse recette regionale"
    },
    {
        "key": "FR009200",
        "value": "Bayonne recette regionale"
    },
    {
        "key": "FR009270",
        "value": "Bordeaux recette regionale"
    },
    {
        "key": "FR009330",
        "value": "Poitiers recette regionale"
    },
    {
        "key": "FR009410",
        "value": "Nantes recette regionale"
    },
    {
        "key": "FR009460",
        "value": "Bretagne recette regionale"
    },
    {
        "key": "FR009510",
        "value": "Caen recette regionale"
    },
    {
        "key": "FR009570",
        "value": "Rouen recette regionale"
    },
    {
        "key": "FR009620",
        "value": "Le havre recette interregionale"
    },
    {
        "key": "FR009710",
        "value": "Guadeloupe recette regionale"
    },
    {
        "key": "FR009720",
        "value": "Martinique recette regionale"
    },
    {
        "key": "FR009730",
        "value": "Guyane recette regionale"
    },
    {
        "key": "FR009740",
        "value": "Reunion recette regionale"
    },
    {
        "key": "FR009760",
        "value": "Mayotte recette regionale"
    },
    {
        "key": "FR009860",
        "value": "Lille recette regionale"
    },
    {
        "key": "FR012721",
        "value": "Thouarce rl"
    },
    {
        "key": "FR013791",
        "value": "Rouillac rl"
    },
    {
        "key": "FR027721",
        "value": "Cairanne rl"
    },
    {
        "key": "FR038201",
        "value": "Bastia viti"
    },
    {
        "key": "FR038211",
        "value": "Aleria viti"
    },
    {
        "key": "FR060811",
        "value": "Cadillac rl"
    },
    {
        "key": "FR061781",
        "value": "Castillon rl"
    },
    {
        "key": "FR061861",
        "value": "St emilion rl"
    },
    {
        "key": "FR117721",
        "value": "Beaune rl"
    },
    {
        "key": "FR209871",
        "value": "Matha rl"
    },
    {
        "key": "FR304731",
        "value": "Vallet rl"
    },
    {
        "key": "FR340002",
        "value": "Montpellier mediterranee bur dedoua"
    },
    {
        "key": "FR379831",
        "value": "Epernay rl"
    },
    {
        "key": "FR455751",
        "value": "Tain l'hermitage rl"
    },
    {
        "key": "FR512751",
        "value": "olonzac rl"
    },
    {
        "key": "FR512771",
        "value": "St Chinian rl"
    },
    {
        "key": "FR512841",
        "value": "Gignac rl"
    },
    {
        "key": "FR537761",
        "value": "villie morgon rl"
    },
    {
        "key": "FR575311",
        "value": "Saint Pourcain viti"
    },
    {
        "key": "FR581811",
        "value": "Condom rl"
    },
    {
        "key": "FR590001",
        "value": "DI Hauts -de-France"
    },
    {
        "key": "FR750001",
        "value": "Service grands comptes (SGC)"
    },
    {
        "key": "FR750681",
        "value": "DGDDI E3 politique du dedouanement"
    },
    {
        "key": "FR750771",
        "value": "BUREAU F2"
    },
    {
        "key": "FR760001",
        "value": "DI Normandie"
    },
    {
        "key": "FR770511",
        "value": "SNRO"
    },
    {
        "key": "FR772001",
        "value": "DR Paris"
    },
    {
        "key": "FR776001",
        "value": "DR Orly"
    },
    {
        "key": "FR777001",
        "value": "DR Paris Est"
    },
    {
        "key": "FR782001",
        "value": "DR Roissy Fret"
    },
    {
        "key": "FR787001",
        "value": "DR Paris Ouest"
    },
    {
        "key": "FR792001",
        "value": "DR Centre"
    },
    {
        "key": "FR792201",
        "value": "Tours viti"
    },
    {
        "key": "FR792211",
        "value": "Blois viti"
    },
    {
        "key": "FR792221",
        "value": "Bourges viti"
    },
    {
        "key": "FR800001",
        "value": "DR Dunkerque"
    },
    {
        "key": "FR817001",
        "value": "DR Picardie"
    },
    {
        "key": "FR823001",
        "value": "DR Champagne Ardenne"
    },
    {
        "key": "FR823201",
        "value": "Epernay viti"
    },
    {
        "key": "FR823221",
        "value": "Bar sur Seine viti"
    },
    {
        "key": "FR830001",
        "value": "DR Lorraine"
    },
    {
        "key": "FR843001",
        "value": "DR Strasbourg"
    },
    {
        "key": "FR848001",
        "value": "DR Mulhouse"
    },
    {
        "key": "FR853001",
        "value": "DR Franche Comte"
    },
    {
        "key": "FR859001",
        "value": "DR Bourgogne"
    },
    {
        "key": "FR859201",
        "value": "Macon viti"
    },
    {
        "key": "FR859211",
        "value": "Chalon s-saone viti"
    },
    {
        "key": "FR859221",
        "value": "Dijon viti"
    },
    {
        "key": "FR859231",
        "value": "Auxerre viti"
    },
    {
        "key": "FR859241",
        "value": "Beaune viti"
    },
    {
        "key": "FR864001",
        "value": "DR Lyon"
    },
    {
        "key": "FR864201",
        "value": "Villefranche viti ci"
    },
    {
        "key": "FR864221",
        "value": "Privas viti ci"
    },
    {
        "key": "FR864241",
        "value": "Tain l'hermitage viti ci"
    },
    {
        "key": "FR864251",
        "value": "Valence viti ci"
    },
    {
        "key": "FR869001",
        "value": "DR Auvergne"
    },
    {
        "key": "FR869211",
        "value": "Clermont viti"
    },
    {
        "key": "FR873001",
        "value": "DR Leman"
    },
    {
        "key": "FR873201",
        "value": "Bourg en bresse viti"
    },
    {
        "key": "FR874001",
        "value": "DR Chambery"
    },
    {
        "key": "FR874201",
        "value": "Chambery viti"
    },
    {
        "key": "FR874211",
        "value": "Grenoble viti"
    },
    {
        "key": "FR880001",
        "value": "DR Nice"
    },
    {
        "key": "FR885001",
        "value": "DR Corse"
    },
    {
        "key": "FR885201",
        "value": "Ajaccio viti"
    },
    {
        "key": "FR889001",
        "value": "DR Provence"
    },
    {
        "key": "FR889201",
        "value": "Avignon viti"
    },
    {
        "key": "FR889211",
        "value": "Draguignan viti ci"
    },
    {
        "key": "FR889251",
        "value": "Aix en provence viti CI"
    },
    {
        "key": "FR897001",
        "value": "DR Marseille"
    },
    {
        "key": "FR902001",
        "value": "DR Montpellier"
    },
    {
        "key": "FR902201",
        "value": "Montpellier viti"
    },
    {
        "key": "FR902211",
        "value": "Beziers viti ci"
    },
    {
        "key": "FR902261",
        "value": "Bagnols s/ceze viti ci"
    },
    {
        "key": "FR907001",
        "value": "DR Perpignan"
    },
    {
        "key": "FR907201",
        "value": "Perpignan viti"
    },
    {
        "key": "FR907211",
        "value": "Narbonne viti"
    },
    {
        "key": "FR907221",
        "value": "Carcassonne viti ci"
    },
    {
        "key": "FR912001",
        "value": "DR Midi Pyrenees"
    },
    {
        "key": "FR912231",
        "value": "Cahors viti"
    },
    {
        "key": "FR912261",
        "value": "Gaillac viti"
    },
    {
        "key": "FR912701",
        "value": "Eauze c.v.a"
    },
    {
        "key": "FR919001",
        "value": "DR Bayonne"
    },
    {
        "key": "FR926001",
        "value": "DR Bordeaux"
    },
    {
        "key": "FR926211",
        "value": "Langon viti"
    },
    {
        "key": "FR926221",
        "value": "Libourne viti"
    },
    {
        "key": "FR926241",
        "value": "Bergerac viti"
    },
    {
        "key": "FR926251",
        "value": "Blaye viti"
    },
    {
        "key": "FR926261",
        "value": "Pauillac viti"
    },
    {
        "key": "FR930001",
        "value": "DGDDI Cellule contrefacon"
    },
    {
        "key": "FR930002",
        "value": "E-3 Perf. actif - PA aéro."
    },
    {
        "key": "FR930003",
        "value": "E-3 Perf. actif - PA prod agricoles"
    },
    {
        "key": "FR930004",
        "value": "E-3 Entrepôt douanier"
    },
    {
        "key": "FR930005",
        "value": "E-3 Destination part"
    },
    {
        "key": "FR930006",
        "value": "E-3 Projets informatiques"
    },
    {
        "key": "FR930007",
        "value": "E-1 POLITIQUE TARIFAIRE"
    },
    {
        "key": "FR930009",
        "value": "E-3 Transit - TIR"
    },
    {
        "key": "FR930010",
        "value": "D-2 Plan de contrôle ex post"
    },
    {
        "key": "FR930011",
        "value": "E-3 Procédures simplifiées"
    },
    {
        "key": "FR930012",
        "value": "B-4 Garantie/contributions indirect"
    },
    {
        "key": "FR930013",
        "value": "E-1 Nomenclature -rtc"
    },
    {
        "key": "FR930014",
        "value": "E-1 groupe rtc"
    },
    {
        "key": "FR930015",
        "value": "E-1 rtc bureau d'ordre"
    },
    {
        "key": "FR930016",
        "value": "E-1 chapitre 1 a 38"
    },
    {
        "key": "FR930017",
        "value": "E-1 chapitre 39 a 71"
    },
    {
        "key": "FR930018",
        "value": "E-1 chapitre 72 a 97"
    },
    {
        "key": "FR932001",
        "value": "DR Poitiers"
    },
    {
        "key": "FR932211",
        "value": "Angouleme viti"
    },
    {
        "key": "FR932221",
        "value": "Jonzac viti"
    },
    {
        "key": "FR932231",
        "value": "Saintes viti"
    },
    {
        "key": "FR936701",
        "value": "Cognac viti"
    },
    {
        "key": "FR940001",
        "value": "DR Pays de la Loire"
    },
    {
        "key": "FR940002",
        "value": "BCRE"
    },
    {
        "key": "FR940211",
        "value": "La roche s-yon centre viti"
    },
    {
        "key": "FR940221",
        "value": "Angers centre viti"
    },
    {
        "key": "FR940321",
        "value": "Saumur centre viti"
    },
    {
        "key": "FR945001",
        "value": "DR Bretagne"
    },
    {
        "key": "FR950001",
        "value": "DR Basse Normandie"
    },
    {
        "key": "FR950161",
        "value": "Caen alcools et cidriculture"
    },
    {
        "key": "FR950261",
        "value": "Granville alcools et cidriculture"
    },
    {
        "key": "FR956001",
        "value": "DR Rouen"
    },
    {
        "key": "FR961001",
        "value": "DR Le Havre"
    },
    {
        "key": "FR971011",
        "value": "DR Guadeloupe"
    },
    {
        "key": "FR972021",
        "value": "DR Martinique"
    },
    {
        "key": "FR973011",
        "value": "DR Guyane"
    },
    {
        "key": "FR974011",
        "value": "DR Reunion"
    },
    {
        "key": "FR985001",
        "value": "DR Mayotte"
    },
    {
        "key": "FR986001",
        "value": "DR Lille"
    },
    {
        "key": "FRA02300",
        "value": "Le Havre Port - Visite Ocean"
    },
    {
        "key": "FRA0617A",
        "value": "Roissy Panalpina bureau"
    },
    {
        "key": "FRA0617B",
        "value": "Roissy Sogaris bureau"
    },
    {
        "key": "FRA0617D",
        "value": "Roissy CDP bureau"
    },
    {
        "key": "FRA0619A",
        "value": "Roissy Air France bureau"
    },
    {
        "key": "FRA0619B",
        "value": "Roissy Cargo centre bureau"
    },
    {
        "key": "FRA0677B",
        "value": "Roissy Depot"
    },
    {
        "key": "FRB02300",
        "value": "Le Havre Port Visite Europe Atlan."
    },
    {
        "key": "FRB0617A",
        "value": "Roissy Sodexi bureau"
    },
    {
        "key": "FRB0617D",
        "value": "Roissy Chronopost bureau"
    },
    {
        "key": "FRB0619A",
        "value": "Roissy Banale bureau"
    },
    {
        "key": "FRB0677B",
        "value": "Roissy avitaillement"
    },
    {
        "key": "FRC02300",
        "value": "Le Havre Port Magasins Entrepots"
    },
    {
        "key": "FRC0619A",
        "value": "Roissy Nord Gestion des procedures"
    },
    {
        "key": "FRD02300",
        "value": "Le Havre Port - CREPS"
    },
    {
        "key": "FRD0619A",
        "value": "ROISSY P1 OPCO AEROGARE"
    },
    {
        "key": "FRGR0001",
        "value": "Bureau de depot ICS"
    },
    {
        "key": "GB000001",
        "value": "Central Community Transit Office"
    },
    {
        "key": "GB000002",
        "value": "PETER BENNETT HOUSE"
    },
    {
        "key": "GB000003",
        "value": "CASTLE MEADOW"
    },
    {
        "key": "GB000011",
        "value": "Birmingham Airport"
    },
    {
        "key": "GB000014",
        "value": "Belfast International Airport"
    },
    {
        "key": "GB000029",
        "value": "UKBF, Bristol International Airport"
    },
    {
        "key": "GB000033",
        "value": "AVONMOUTH 1"
    },
    {
        "key": "GB000040",
        "value": "Dover (OTS) Freight Clearance"
    },
    {
        "key": "GB000041",
        "value": "FOLKESTONE"
    },
    {
        "key": "GB000045",
        "value": "SHEERNESS"
    },
    {
        "key": "GB000047",
        "value": "Ramsgate"
    },
    {
        "key": "GB000049",
        "value": "NEWPORT"
    },
    {
        "key": "GB000051",
        "value": "Felixstowe"
    },
    {
        "key": "GB000052",
        "value": "Border Force, Harwich"
    },
    {
        "key": "GB000053",
        "value": "IPSWICH PORT"
    },
    {
        "key": "GB000054",
        "value": "GREAT YARMOUTH"
    },
    {
        "key": "GB000055",
        "value": "KING'S LYNN"
    },
    {
        "key": "GB000057",
        "value": "Rosyth"
    },
    {
        "key": "GB000058",
        "value": "NORWICH AIRPORT"
    },
    {
        "key": "GB000059",
        "value": "Grangemouth"
    },
    {
        "key": "GB000060",
        "value": "Dover/Folkestone Eurotunnel Freight"
    },
    {
        "key": "GB000062",
        "value": "Scrabster"
    },
    {
        "key": "GB000063",
        "value": "Scotland Inland Auth. Cee/Cor"
    },
    {
        "key": "GB000067",
        "value": "Scotland Frontier"
    },
    {
        "key": "GB000070",
        "value": "Prestwick Airport"
    },
    {
        "key": "GB000072",
        "value": "Hull"
    },
    {
        "key": "GB000073",
        "value": "GRIMSBY"
    },
    {
        "key": "GB000074",
        "value": "Immingham"
    },
    {
        "key": "GB000080",
        "value": "Liverpool, Seaforth S2 Berth"
    },
    {
        "key": "GB000081",
        "value": "UK North Auth Consignor/nees"
    },
    {
        "key": "GB000084",
        "value": "London Heathrow Cargo"
    },
    {
        "key": "GB000085",
        "value": "London Gatwick Airport Cargo Dist."
    },
    {
        "key": "GB000087",
        "value": "Leeds Bradford"
    },
    {
        "key": "GB000091",
        "value": "SOUTHEND AIRPORT"
    },
    {
        "key": "GB000093",
        "value": "Tilbury"
    },
    {
        "key": "GB000098",
        "value": "Newcastle Airport"
    },
    {
        "key": "GB000099",
        "value": "THAMES EUROPORT (DIFT)"
    },
    {
        "key": "GB000102",
        "value": "Luton Airport FCT"
    },
    {
        "key": "GB000108",
        "value": "FALMOUTH"
    },
    {
        "key": "GB000119",
        "value": "NORTHOLT AIRPORT"
    },
    {
        "key": "GB000120",
        "value": "London Heathrow Airport (Baggage)"
    },
    {
        "key": "GB000121",
        "value": "Stansted Airport FCT"
    },
    {
        "key": "GB000122",
        "value": "Cardiff International Airport"
    },
    {
        "key": "GB000123",
        "value": "SWANSEA"
    },
    {
        "key": "GB000124",
        "value": "HOLYHEAD"
    },
    {
        "key": "GB000125",
        "value": "Barry Dock"
    },
    {
        "key": "GB000126",
        "value": "PEMBROKE DOCK"
    },
    {
        "key": "GB000128",
        "value": "Edinburgh Airport"
    },
    {
        "key": "GB000130",
        "value": "London City Airport"
    },
    {
        "key": "GB000140",
        "value": "George Best (Belfast City)"
    },
    {
        "key": "GB000142",
        "value": "Belfast Docks,Unit 2 Block C"
    },
    {
        "key": "GB000149",
        "value": "Purfleet Thames Terminal"
    },
    {
        "key": "GB000150",
        "value": "Thamesport"
    },
    {
        "key": "GB000155",
        "value": "Reading"
    },
    {
        "key": "GB000170",
        "value": "London Gateway Port"
    },
    {
        "key": "GB000191",
        "value": "Manchester"
    },
    {
        "key": "GB000218",
        "value": "Newcastle-Upon-Tyne"
    },
    {
        "key": "GB000219",
        "value": "Teesport"
    },
    {
        "key": "GB000244",
        "value": "BOSTON"
    },
    {
        "key": "GB000245",
        "value": "UKBA East Midlands Airport"
    },
    {
        "key": "GB000246",
        "value": "UK South Auth Consignor/nees"
    },
    {
        "key": "GB000250",
        "value": "PLYMOUTH"
    },
    {
        "key": "GB000290",
        "value": "Southampton"
    },
    {
        "key": "GB000291",
        "value": "Poole Ferry Terminal"
    },
    {
        "key": "GB000292",
        "value": "Portsmouth"
    },
    {
        "key": "GB000297",
        "value": "EXETER AIRPORT"
    },
    {
        "key": "GB000328",
        "value": "Cardiff"
    },
    {
        "key": "GB000383",
        "value": "Salford National Clearance Hub"
    },
    {
        "key": "GB000411",
        "value": "Aberdeen Airport"
    },
    {
        "key": "GB000416",
        "value": "PETERHEAD"
    },
    {
        "key": "GB000434",
        "value": "Aberdeen"
    },
    {
        "key": "GB000450",
        "value": "Isle of Man Customs & Excise"
    },
    {
        "key": "GB000461",
        "value": "Guernsey Customs & Excise"
    },
    {
        "key": "GB000465",
        "value": "Jersey Customs & Immigration"
    },
    {
        "key": "GB000470",
        "value": "WSBA Customs and Immigration"
    },
    {
        "key": "GB000480",
        "value": "Chatham"
    },
    {
        "key": "GB000500",
        "value": "COVENTRY INTERNATIONAL HUB"
    },
    {
        "key": "GB000501",
        "value": "HWDC LANGLEY"
    },
    {
        "key": "GB000921",
        "value": "FORDS JETTY"
    },
    {
        "key": "GB001016",
        "value": "LERWICK"
    },
    {
        "key": "GB003010",
        "value": "Barrow in Furness"
    },
    {
        "key": "GB003020",
        "value": "Berwick upon Tweed"
    },
    {
        "key": "GB003030",
        "value": "Blackpool International Airport"
    },
    {
        "key": "GB003040",
        "value": "Glasgow Docks"
    },
    {
        "key": "GB003050",
        "value": "Plymouth Docks"
    },
    {
        "key": "GB003060",
        "value": "Bournemouth (Hurn) Airport"
    },
    {
        "key": "GB003070",
        "value": "Brize Norton"
    },
    {
        "key": "GB003080",
        "value": "Cambridge Airport"
    },
    {
        "key": "GB003090",
        "value": "Southampton (Eastleigh) Airport"
    },
    {
        "key": "GB003100",
        "value": "Dundee (Sea)"
    },
    {
        "key": "GB003110",
        "value": "Farnborough Airport"
    },
    {
        "key": "GB003120",
        "value": "Gloucester (Staverton) Airport"
    },
    {
        "key": "GB003130",
        "value": "Humberside International Airport"
    },
    {
        "key": "GB003140",
        "value": "Hartlepool"
    },
    {
        "key": "GB003150",
        "value": "Inverness"
    },
    {
        "key": "GB003160",
        "value": "Kemble Airport"
    },
    {
        "key": "GB003170",
        "value": "Kirkwall (Orkney Islands)"
    },
    {
        "key": "GB003180",
        "value": "Durham Tees Valley (Teeside Apt)"
    },
    {
        "key": "GB003190",
        "value": "Liverpool John Lennon Airport"
    },
    {
        "key": "GB003200",
        "value": "London Ashford (Lydd) Airport"
    },
    {
        "key": "GB003210",
        "value": "Londonderry"
    },
    {
        "key": "GB003220",
        "value": "Manchester Docks"
    },
    {
        "key": "GB003230",
        "value": "Oxford (Kidlington) Airport"
    },
    {
        "key": "GB003240",
        "value": "Portree (Isle of Skye)"
    },
    {
        "key": "GB003250",
        "value": "Newhaven"
    },
    {
        "key": "GB003260",
        "value": "Stornoway (Isle of Lewis)"
    },
    {
        "key": "GB003270",
        "value": "Stornoway Airport"
    },
    {
        "key": "GB003280",
        "value": "Workington"
    },
    {
        "key": "GB003290",
        "value": "BIGGIN HILL"
    },
    {
        "key": "GB004098",
        "value": "GLASGOW"
    },
    {
        "key": "GB005010",
        "value": "Appledore, c/o National Simps Team"
    },
    {
        "key": "GB005020",
        "value": "Bridgwater, c/o National Simps Team"
    },
    {
        "key": "GB005030",
        "value": "Dagenham c/o National Simps Team"
    },
    {
        "key": "GB005040",
        "value": "Dartford, c/o National Simps Team"
    },
    {
        "key": "GB005050",
        "value": "Greenock, c/o Simps Team"
    },
    {
        "key": "GB005060",
        "value": "Langstone, c/o Simps Team"
    },
    {
        "key": "GB005070",
        "value": "London, c/o Simps Team"
    },
    {
        "key": "GB005080",
        "value": "Middlesbrough, c/o Simps Team"
    },
    {
        "key": "GB005090",
        "value": "Neath, c/o Simps Team"
    },
    {
        "key": "GB005100",
        "value": "Penrhyn, c/o Simps Team"
    },
    {
        "key": "GB005110",
        "value": "Ridham, c/o Simps Team"
    },
    {
        "key": "GB005120",
        "value": "Rochester, c/o Simps Team"
    },
    {
        "key": "GB005130",
        "value": "Shoreham, c/o Simps Team"
    },
    {
        "key": "GB005140",
        "value": "South Shields, c/o Simps Team"
    },
    {
        "key": "GB005160",
        "value": "Warrenpoint"
    },
    {
        "key": "GB005170",
        "value": "Killingholme"
    },
    {
        "key": "GB005180",
        "value": "Montrose"
    },
    {
        "key": "GB005190",
        "value": "Portbury"
    },
    {
        "key": "GB005200",
        "value": "Fishguard"
    },
    {
        "key": "GB005210",
        "value": "Heysham"
    },
    {
        "key": "GB005211",
        "value": "Croydon Regional Centre"
    },
    {
        "key": "GBEORI01",
        "value": "EORI TEAM HMRC"
    },
    {
        "key": "GBREX001",
        "value": "Dorchester House"
    },
    {
        "key": "GBREX002",
        "value": "Peter Bennett House"
    },
    {
        "key": "GBSOU001",
        "value": "SOUTHEND DIRECT EXPORTS"
    },
    {
        "key": "GR000102",
        "value": "ΑΘΗΝΩΝ"
    },
    {
        "key": "GR000232",
        "value": "ΑΣΤΑΚΟΥ"
    },
    {
        "key": "GR000234",
        "value": "ΜΕΣΟΛΟΓΓΙΟΥ"
    },
    {
        "key": "GR000303",
        "value": "ΛΑΥΡΙΟΥ"
    },
    {
        "key": "GR000304",
        "value": "Δ.Α.Α. 'ΕΛΕΥΘΕΡΙΟΣ ΒΕΝΙΖΕΛΟΣ'"
    },
    {
        "key": "GR000331",
        "value": "ΡΑΦΗΝΑΣ"
    },
    {
        "key": "GR000402",
        "value": "ΝΑΥΠΛΙΟΥ"
    },
    {
        "key": "GR000631",
        "value": "ΑΡΤΑΣ"
    },
    {
        "key": "GR000700",
        "value": "ΤΕΛΩΝΕΙΑΚΗ ΠΕΡΙΦΕΡΕΙΑ ΑΧΑΪΑΣ"
    },
    {
        "key": "GR000701",
        "value": "ΠΑΤΡΩΝ"
    },
    {
        "key": "GR000731",
        "value": "ΑΙΓΙΟΥ"
    },
    {
        "key": "GR000831",
        "value": "ΑΝΤΙΚΥΡΑΣ"
    },
    {
        "key": "GR000832",
        "value": "ΟΙΝΟΗΣ"
    },
    {
        "key": "GR001002",
        "value": "ΔΡΑΜΑΣ"
    },
    {
        "key": "GR001102",
        "value": "ΕΛΕΥΣΙΝΑΣ"
    },
    {
        "key": "GR001202",
        "value": "ΡΟΔΟΥ"
    },
    {
        "key": "GR001232",
        "value": "ΚΑΛΥΜΝΟΥ"
    },
    {
        "key": "GR001233",
        "value": "ΚΑΡΠΑΘΟΥ"
    },
    {
        "key": "GR001234",
        "value": "ΛΕΡΟΥ"
    },
    {
        "key": "GR001235",
        "value": "ΚΩΣ"
    },
    {
        "key": "GR001236",
        "value": "ΠΑΤΜΟΥ"
    },
    {
        "key": "GR001237",
        "value": "ΣΥΜΗΣ"
    },
    {
        "key": "GR001272",
        "value": "ΜΕΓΙΣΤΗΣ"
    },
    {
        "key": "GR001302",
        "value": "ΑΛΕΞΑΝΔΡΟΥΠΟΛΗΣ"
    },
    {
        "key": "GR001331",
        "value": "ΝΕΑΣ ΟΡΕΣΤΙΑΔΑΣ"
    },
    {
        "key": "GR001332",
        "value": "ΚΗΠΩΝ ΕΒΡΟΥ"
    },
    {
        "key": "GR001336",
        "value": "ΚΑΣΤΑΝΕΩΝ"
    },
    {
        "key": "GR001402",
        "value": "ΧΑΛΚΙΔΑΣ"
    },
    {
        "key": "GR001431",
        "value": "ΩΡΕΩΝ"
    },
    {
        "key": "GR001631",
        "value": "ΖΑΚΥΝΘΟΥ"
    },
    {
        "key": "GR001732",
        "value": "ΚΥΛΛΗΝΗΣ"
    },
    {
        "key": "GR001802",
        "value": "ΒΕΡΟΙΑΣ"
    },
    {
        "key": "GR001902",
        "value": "ΗΡΑΚΛΕΙΟΥ"
    },
    {
        "key": "GR002001",
        "value": "Α' ΘΕΣΣΑΛΟΝΙΚΗΣ"
    },
    {
        "key": "GR002005",
        "value": "B ΘΕΣΣΑΛΟΝΙΚΗΣ"
    },
    {
        "key": "GR002006",
        "value": "Γ΄ ΘΕΣΣΑΛΟΝΙΚΗΣ"
    },
    {
        "key": "GR002015",
        "value": "ΤΕΛΩΝΕΙΑΚΗ ΠΕΡΙΦΕΡΕΙΑ ΘΕΣΣΑΛΟΝΙΚΗΣ"
    },
    {
        "key": "GR002051",
        "value": "ΣΤΑΥΡΟΥ"
    },
    {
        "key": "GR002102",
        "value": "ΗΓΟΥΜΕΝΙΤΣΑΣ"
    },
    {
        "key": "GR002132",
        "value": "ΜΑΥΡΟΜΑΤΙΟΥ"
    },
    {
        "key": "GR002202",
        "value": "ΙΩΑΝΝΙΝΩΝ"
    },
    {
        "key": "GR002203",
        "value": "ΚΑΚΑΒΙΑΣ"
    },
    {
        "key": "GR002233",
        "value": "ΜΕΡΤΖΑΝΗΣ"
    },
    {
        "key": "GR002302",
        "value": "ΚΑΒΑΛΑΣ"
    },
    {
        "key": "GR002501",
        "value": "ΚΑΣΤΟΡΙΑΣ"
    },
    {
        "key": "GR002602",
        "value": "ΚΕΡΚΥΡΑΣ"
    },
    {
        "key": "GR002731",
        "value": "ΑΡΓΟΣΤΟΛΙΟΥ"
    },
    {
        "key": "GR002802",
        "value": "ΕΥΖΩΝΩΝ"
    },
    {
        "key": "GR002803",
        "value": "ΔΟΙΡΑΝΗΣ"
    },
    {
        "key": "GR002805",
        "value": "ΚΙΛΚΙΣ"
    },
    {
        "key": "GR002931",
        "value": "ΠΤΟΛΕΜΑΪΔΑΣ"
    },
    {
        "key": "GR003002",
        "value": "ΚΟΡΙΝΘΟΥ"
    },
    {
        "key": "GR003102",
        "value": "ΣΥΡΟΥ"
    },
    {
        "key": "GR003131",
        "value": "ΜΗΛΟΥ"
    },
    {
        "key": "GR003132",
        "value": "ΠΑΡΟΥ"
    },
    {
        "key": "GR003133",
        "value": "ΜΥΚΟΝΟΥ"
    },
    {
        "key": "GR003134",
        "value": "ΝΑΞΟΥ"
    },
    {
        "key": "GR003135",
        "value": "ΘΗΡΑΣ"
    },
    {
        "key": "GR003201",
        "value": "ΓΥΘΕΙΟΥ"
    },
    {
        "key": "GR003232",
        "value": "ΝΕΑΠΟΛΗΣ ΒΟΙΩΝ"
    },
    {
        "key": "GR003302",
        "value": "ΛΑΡΙΣΣΑΣ"
    },
    {
        "key": "GR003431",
        "value": "ΑΓΙΟΥ ΝΙΚΟΛΑΟΥ"
    },
    {
        "key": "GR003433",
        "value": "ΣΗΤΕΙΑΣ"
    },
    {
        "key": "GR003502",
        "value": "ΜΥΤΙΛΗΝΗΣ"
    },
    {
        "key": "GR003532",
        "value": "ΜΥΡΙΝΑΣ"
    },
    {
        "key": "GR003575",
        "value": "ΠΛΩΜΑΡΙΟΥ"
    },
    {
        "key": "GR003631",
        "value": "ΛΕΥΚΑΔΑΣ"
    },
    {
        "key": "GR003702",
        "value": "ΒΟΛΟΥ"
    },
    {
        "key": "GR003751",
        "value": "ΣΚΙΑΘΟΥ"
    },
    {
        "key": "GR003802",
        "value": "ΚΑΛΑΜΑΤΑΣ"
    },
    {
        "key": "GR003851",
        "value": "ΚΥΠΑΡΙΣΣΙΑΣ"
    },
    {
        "key": "GR003931",
        "value": "ΞΑΝΘΗΣ"
    },
    {
        "key": "GR004001",
        "value": "Α' ΠΕΙΡΑΙΑ"
    },
    {
        "key": "GR004005",
        "value": "Γ΄ ΠΕΙΡΑΙΑ"
    },
    {
        "key": "GR004006",
        "value": "B΄ ΠΕΙΡΑΙΑ"
    },
    {
        "key": "GR004007",
        "value": "Δ' ΠΕΙΡΑΙΑ"
    },
    {
        "key": "GR004019",
        "value": "ΤΕΛΩΝΕΙΑΚΗ ΠΕΡΙΦΕΡΕΙΑ ΑΤΤΙΚΗΣ"
    },
    {
        "key": "GR004031",
        "value": "ΠΟΡΟΥ"
    },
    {
        "key": "GR004076",
        "value": "ΚΥΘΗΡΩΝ"
    },
    {
        "key": "GR004101",
        "value": "ΣΚΥΔΡΑΣ"
    },
    {
        "key": "GR004201",
        "value": "ΚΑΤΕΡΙΝΗΣ"
    },
    {
        "key": "GR004302",
        "value": "ΠΡΕΒΕΖΑΣ"
    },
    {
        "key": "GR004431",
        "value": "ΡΕΘΥΜΝΗΣ"
    },
    {
        "key": "GR004531",
        "value": "ΚΟΜΟΤΗΝΗΣ"
    },
    {
        "key": "GR004631",
        "value": "ΣΑΜΟΥ"
    },
    {
        "key": "GR004671",
        "value": "ΑΓΙΟΥ ΚΗΡΥΚΟΥ"
    },
    {
        "key": "GR004701",
        "value": "ΣΕΡΡΩΝ"
    },
    {
        "key": "GR004831",
        "value": "ΤΡΙΚΑΛΩΝ"
    },
    {
        "key": "GR004902",
        "value": "ΣΤΥΛΙΔΑΣ"
    },
    {
        "key": "GR005001",
        "value": "ΚΡΥΣΤΑΛΛΟΠΗΓΗΣ"
    },
    {
        "key": "GR005031",
        "value": "ΦΛΩΡΙΝΑΣ"
    },
    {
        "key": "GR005032",
        "value": "ΝΙΚΗΣ"
    },
    {
        "key": "GR005131",
        "value": "ΙΤΕΑΣ"
    },
    {
        "key": "GR005201",
        "value": "ΔΑΦΝΗΣ"
    },
    {
        "key": "GR005233",
        "value": "ΣΤΡΑΤΩΝΙΟΥ"
    },
    {
        "key": "GR005234",
        "value": "ΝΕΩΝ ΜΟΥΔΑΝΙΩΝ"
    },
    {
        "key": "GR005302",
        "value": "ΧΑΝΙΩΝ"
    },
    {
        "key": "GR005401",
        "value": "ΧΙΟΥ"
    },
    {
        "key": "GR009901",
        "value": "ΔΙΕΥΘΥΝΣΗ ΤΕΛΩΝΕΙΑΚΩΝ ΔΙΑΔΙΚΑΣΙΩΝ"
    },
    {
        "key": "GR009917",
        "value": "ΔΙΕΥΘΥΝΣΗ ΔΑΣΜΟΛΟΓΙΚΩΝ ΘΕΜΑΤΩΝ"
    },
    {
        "key": "GR009934",
        "value": "ΔΙΕΥΘΥΝΣΗ ΕΙΔΙΚΩΝ ΦΟΡΩΝ ΚΑΤΑΝΑΛΩΣΗΣ"
    },
    {
        "key": "GR009991",
        "value": "ΕΛ.Υ.Τ. Αττικής"
    },
    {
        "key": "HR011002",
        "value": "CARINSKA UPRAVA RH SREDIŠNJI URED"
    },
    {
        "key": "HR012351",
        "value": "ODJEL ZA POTRAGE I ZAKLJ. POSTUPAKA"
    },
    {
        "key": "HR017701",
        "value": "ELO SLUŽBA - TROŠARINSKI URED ZA VEZU"
    },
    {
        "key": "HR030007",
        "value": "PCU PODRUČNI CARINSKI URED ZAGREB"
    },
    {
        "key": "HR030147",
        "value": "CU CARINSKI URED ZAGREB I"
    },
    {
        "key": "HR030171",
        "value": "SLUŽBA ZA RGP SLUŽBA ZA RGP JANKOMIR I SL.ZONA"
    },
    {
        "key": "HR030198",
        "value": "CU CARINSKI URED ZAGREB II"
    },
    {
        "key": "HR030228",
        "value": "SLUŽBA ZA RGP SLUŽBA ZA RGP ŽITNJAK"
    },
    {
        "key": "HR030236",
        "value": "RGP RGP ZRAČNA LUKA ZAGREB"
    },
    {
        "key": "HR030244",
        "value": "RGP RGP POŠTA"
    },
    {
        "key": "HR030252",
        "value": "CU CARINSKI URED KRAPINA"
    },
    {
        "key": "HR030287",
        "value": "RGP SLUŽBA ZA RGP KRAPINA I SL.ZONA"
    },
    {
        "key": "HR030295",
        "value": "CU CARINSKI URED VARAŽDIN"
    },
    {
        "key": "HR030325",
        "value": "SLUŽBA ZA RGP SLUŽBA ZA RGP VARAŽDIN I SL.ZONA"
    },
    {
        "key": "HR030333",
        "value": "SLUŽBA ZA RGP SLUŽBA ZA RGP ČAKOVEC"
    },
    {
        "key": "HR030341",
        "value": "CU CARINSKI URED KOPRIVNICA"
    },
    {
        "key": "HR030376",
        "value": "SLUŽBA ZA RGP SLUŽBA ZA RGP KOPRIVNICA"
    },
    {
        "key": "HR030384",
        "value": "SLUŽBA ZA RGP SLUŽBA ZA RGP BJELOVAR"
    },
    {
        "key": "HR030392",
        "value": "CU CARINSKI URED SISAK"
    },
    {
        "key": "HR030422",
        "value": "SLUŽBA ZA RGP SLUŽBA ZA RGP SISAK"
    },
    {
        "key": "HR030430",
        "value": "KM KONTROLNO MJESTO KUTINA"
    },
    {
        "key": "HR030449",
        "value": "KM KONTROLNO MJESTO CRNAC"
    },
    {
        "key": "HR030457",
        "value": "CU CARINSKI URED KARLOVAC"
    },
    {
        "key": "HR030481",
        "value": "SLUŽBA ZA RGP SLUŽBA ZA RGP KARLOVAC"
    },
    {
        "key": "HR030503",
        "value": "RGP RGP JASENOVAC"
    },
    {
        "key": "HR030538",
        "value": "KM KONTROLNO MJESTO VOLINJA"
    },
    {
        "key": "HR030546",
        "value": "KM KONTROLNO MJESTO HRV. KOSTAJNICA"
    },
    {
        "key": "HR030562",
        "value": "SLUŽBA ZA RGP SLUŽBA ZA RGP ZAPADNI KOLODVOR"
    },
    {
        "key": "HR030597",
        "value": "GCU GCU JASENOVAC"
    },
    {
        "key": "HR030660",
        "value": "KM KONTROLNO MJESTO MACELJ"
    },
    {
        "key": "HR030686",
        "value": "GCU GCU ZRAČNA LUKA I POŠTA"
    },
    {
        "key": "HR030716",
        "value": "KONTROLNO MJESTO KONTROLNO MJESTO PODSUSED"
    },
    {
        "key": "HR030724",
        "value": "KONTROLNO MJESTO KONTROLNO MJESTO ZL I (Rhea)"
    },
    {
        "key": "HR030732",
        "value": "KONTROLNO MJESTO KONTROLNO MJESTO ZL II (InTime-TNT)"
    },
    {
        "key": "HR030740",
        "value": "KONTROLNO MJESTO KONTROLNO MJESTO ZL III (DHL)"
    },
    {
        "key": "HR030759",
        "value": "KONTROLNO MJESTO KONT. MJESTO ZL IV (Overseas-UPS)"
    },
    {
        "key": "HR030767",
        "value": "KONTROLNO MJESTO KONTROLNO MJESTO KOTORIBA"
    },
    {
        "key": "HR060003",
        "value": "PCU PODRUČNI CARINSKI URED RIJEKA"
    },
    {
        "key": "HR060143",
        "value": "CU CARINSKI URED RIJEKA"
    },
    {
        "key": "HR060178",
        "value": "SLUŽBA ZA RGP SLUŽBA ZA RGP LUKA RIJEKA"
    },
    {
        "key": "HR060186",
        "value": "KM KONTROLNO MJESTO ZRAČNA LUKA RIJEKA"
    },
    {
        "key": "HR060208",
        "value": "KM KONTROLNO MJESTO OMIŠALJ"
    },
    {
        "key": "HR060216",
        "value": "KM KONTROLNO MJESTO MALI LOŠINJ"
    },
    {
        "key": "HR060224",
        "value": "SLUŽBA ZA RGP SLUŽBA ZA RGP ŠKRLJEVO"
    },
    {
        "key": "HR060232",
        "value": "CU CARINSKI URED PULA - POLA"
    },
    {
        "key": "HR060267",
        "value": "SLUŽBA ZA RGP SLUŽBA ZA RGP LUKA PULA - POLA"
    },
    {
        "key": "HR060275",
        "value": "KM KONTROLNO MJESTO LABIN"
    },
    {
        "key": "HR060283",
        "value": "KM KONTOLNO MJESTO ZRAČNA LUKA PULA"
    },
    {
        "key": "HR060291",
        "value": "KM KONTROLNO MJESTO BUJE"
    },
    {
        "key": "HR060305",
        "value": "KM KONTROLNO MJESTO BRŠICA"
    },
    {
        "key": "HR060313",
        "value": "KM KONTROLNO MJESTO UMAG"
    },
    {
        "key": "HR060356",
        "value": "SLUŽBA ZA RGP SLUŽBA ZA RGP PAZIN"
    },
    {
        "key": "HR060364",
        "value": "CU CARINSKI URED GOSPIĆ"
    },
    {
        "key": "HR060399",
        "value": "SLUŽBA ZA RGP SLUŽBA ZA RGP GOSPIĆ"
    },
    {
        "key": "HR060402",
        "value": "GCU GCU LIČKO PETROVO SELO"
    },
    {
        "key": "HR060410",
        "value": "RGP RGP LIČKO PETROVO SELO"
    },
    {
        "key": "HR060461",
        "value": "RGP RGP MALJEVAC"
    },
    {
        "key": "HR070009",
        "value": "PCU PODRUČNI CARINSKI URED OSIJEK"
    },
    {
        "key": "HR070149",
        "value": "CU CARINSKI URED OSIJEK"
    },
    {
        "key": "HR070173",
        "value": "SLUŽBA ZA RGP SLUŽBA ZA RGP OSIJEK I SL. ZONA"
    },
    {
        "key": "HR070181",
        "value": "KM KONTROLNO MJESTO ZRAČNA LUKA OSIJEK"
    },
    {
        "key": "HR070203",
        "value": "SLUŽBA ZA RGP SLUŽBA ZA RGP VINKOVCI"
    },
    {
        "key": "HR070211",
        "value": "CU CARINSKI URED SLAVONSKI BROD"
    },
    {
        "key": "HR070246",
        "value": "SLUŽBA ZA RGP SLUŽBA ZA RGP SL. BROD I SL. ZONA"
    },
    {
        "key": "HR070254",
        "value": "KM SLUŽBA ZA RGP POŽEGA"
    },
    {
        "key": "HR070262",
        "value": "CU CARINSKI URED VIROVITICA"
    },
    {
        "key": "HR070297",
        "value": "SLUŽBA ZA RGP SLUŽBA ZA RGP VIROVITICA"
    },
    {
        "key": "HR070300",
        "value": "GCU GCU BAJAKOVO"
    },
    {
        "key": "HR070319",
        "value": "RGP RGP BAJAKOVO"
    },
    {
        "key": "HR070327",
        "value": "RGP RGP TOVARNIK I RGP Ž.KOL. TOVARNIK"
    },
    {
        "key": "HR070343",
        "value": "RGP SLUŽBA ZA RGP VUKOVAR I SL ZONA"
    },
    {
        "key": "HR070378",
        "value": "RGP RGP BATINA"
    },
    {
        "key": "HR070386",
        "value": "RGP RGP ILOK"
    },
    {
        "key": "HR070394",
        "value": "KM KONTROLNO MJESTO PRINCIPOVAC I"
    },
    {
        "key": "HR070408",
        "value": "KM KONTROLNO MJESTO PRINCIPOVAC II"
    },
    {
        "key": "HR070416",
        "value": "KM KONTROLNO MJESTO ILOK I"
    },
    {
        "key": "HR070432",
        "value": "GCU GCU ŽUPANJA"
    },
    {
        "key": "HR070440",
        "value": "RGP RGP ŽUPANJA MOST"
    },
    {
        "key": "HR070467",
        "value": "RGP RGP GUNJA"
    },
    {
        "key": "HR070483",
        "value": "KM KONTROLNO MJESTO Ž. KOL. DRENOVCI"
    },
    {
        "key": "HR070491",
        "value": "RGP RGP SL. ŠAMAC I ŽELJ.KOL. SL. ŠAMAC"
    },
    {
        "key": "HR070513",
        "value": "GCU GCU STARA GRADIŠKA"
    },
    {
        "key": "HR070521",
        "value": "RGP RGP STARA GRADIŠKA"
    },
    {
        "key": "HR070530",
        "value": "CU CARINSKI URED VUKOVAR"
    },
    {
        "key": "HR070548",
        "value": "RGP RGP SLAVONSKI BROD I LUKA SL.BROD"
    },
    {
        "key": "HR070572",
        "value": "KM KONTROLNO MJESTO LUKA DRAVA"
    },
    {
        "key": "HR070599",
        "value": "RGP RGP ERDUT I Ž.KOLODVOR"
    },
    {
        "key": "HR070661",
        "value": "SLUŽBA ZA RGP SLUŽBA ZA RGP ŽUPANJA"
    },
    {
        "key": "HR070688",
        "value": "GCU GCU ERDUT"
    },
    {
        "key": "HR070696",
        "value": "GCU GCU ILOK"
    },
    {
        "key": "HR070700",
        "value": "GCU GCU SLAVONSKI BROD"
    },
    {
        "key": "HR080004",
        "value": "PCU PODRUČNI CARINSKI URED SPLIT"
    },
    {
        "key": "HR080144",
        "value": "CU CARINSKI URED SPLIT"
    },
    {
        "key": "HR080179",
        "value": "SLUŽBA ZA RGP SLUŽBA ZA RGP SPLIT"
    },
    {
        "key": "HR080209",
        "value": "KONTROLNO MJESTO SLUŽBA ZA RGP ZRAČNA LUKA SPLIT"
    },
    {
        "key": "HR080225",
        "value": "CU CARINSKI URED ŠIBENIK"
    },
    {
        "key": "HR080284",
        "value": "CU CARINSKI URED ZADAR"
    },
    {
        "key": "HR080314",
        "value": "SLUŽBA ZA RGP SLUŽBA ZA RGP ZADAR"
    },
    {
        "key": "HR080322",
        "value": "KM KONTROLNO MJESTO ZRAČNA LUKA ZADAR"
    },
    {
        "key": "HR080357",
        "value": "CU CARINSKI URED PLOČE"
    },
    {
        "key": "HR080381",
        "value": "SLUŽBA ZA RGP SLUŽBA ZA RGP LUKA PLOČE"
    },
    {
        "key": "HR080438",
        "value": "KM KONTROLNO MJESTO LUKA DUBROVNIK"
    },
    {
        "key": "HR080446",
        "value": "SLUŽBA ZA RGP SLUŽBA ZA RGP KORČULA"
    },
    {
        "key": "HR080454",
        "value": "KM KONTROLNO MJESTO VELA LUKA"
    },
    {
        "key": "HR080470",
        "value": "KONTROLNO MJESTO KONT. MJESTO ZRAČNA LUKA DUBROVNIK"
    },
    {
        "key": "HR080497",
        "value": "GCU GCU KAMENSKO"
    },
    {
        "key": "HR080500",
        "value": "RGP RGP KAMENSKO"
    },
    {
        "key": "HR080551",
        "value": "RGP RGP STRMICA"
    },
    {
        "key": "HR080586",
        "value": "GCU GCU VINJANI DONJI"
    },
    {
        "key": "HR080594",
        "value": "RGP RGP VINJANI DONJI"
    },
    {
        "key": "HR080632",
        "value": "RGP RGP VINJANI GORNJI"
    },
    {
        "key": "HR080640",
        "value": "GCU GCU METKOVIĆ"
    },
    {
        "key": "HR080659",
        "value": "RGP RGP METKOVIĆ - NOVA SELA"
    },
    {
        "key": "HR080683",
        "value": "RGP RGP Ž. KOLODVOR I LUKA METKOVIĆ"
    },
    {
        "key": "HR080756",
        "value": "RGP RGP KLEK"
    },
    {
        "key": "HR080772",
        "value": "GCU GCU KARASOVIĆI"
    },
    {
        "key": "HR080780",
        "value": "RGP RGP KARASOVIĆI"
    },
    {
        "key": "HR080802",
        "value": "KM KM GORNJI BRGAT"
    },
    {
        "key": "HR080810",
        "value": "RGP RGP ZATON DOLI"
    },
    {
        "key": "HR080853",
        "value": "KONTROLNO MJESTO KONTROLNO MJESTO STARI GRAD"
    },
    {
        "key": "HR080896",
        "value": "SLUŽBA ZA RGP SLUŽBA ZA RGP ŠIBENIK"
    },
    {
        "key": "HR080918",
        "value": "SLUŽBA ZA RGP SLUŽBA ZA RGP DUBROVNIK"
    },
    {
        "key": "HR080950",
        "value": "CU CARINSKI URED DUBROVNIK"
    },
    {
        "key": "HU001100",
        "value": "NAV Központi Irányítás Vám Főo"
    },
    {
        "key": "HU001200",
        "value": "NAV Központi Irányítás Jövedéki Főo"
    },
    {
        "key": "HU013000",
        "value": "NAV KH Kock.kez. és Kock.elem. Főo."
    },
    {
        "key": "HU015000",
        "value": "NAV Informatikai Intézet"
    },
    {
        "key": "HU050000",
        "value": "NAV Fellebbviteli Igazgatósága"
    },
    {
        "key": "HU101000",
        "value": "NAV Dél-Budapesti AVI"
    },
    {
        "key": "HU101150",
        "value": "NAV Dél-budapesti AVI Metrans Sz.h."
    },
    {
        "key": "HU101220",
        "value": "NAV Dél-budapesti AVI Bilk Log. SZH"
    },
    {
        "key": "HU101240",
        "value": "NAV Pest Megyei AVI Pintér Vám SZH"
    },
    {
        "key": "HU101280",
        "value": "NAV Dél-budapesti AVI BSZL Zrt. SZH"
    },
    {
        "key": "HU102000",
        "value": "NAV KAVIG"
    },
    {
        "key": "HU106200",
        "value": "NAV Pest Megyei AVI Masped Rt. SZH"
    },
    {
        "key": "HU121000",
        "value": "NAV Repülőtéri Igazgatósága"
    },
    {
        "key": "HU160000",
        "value": "NAV Kelet-Budapesti AVI"
    },
    {
        "key": "HU170000",
        "value": "NAV Észak-Budapesti AVI"
    },
    {
        "key": "HU190000",
        "value": "NAV Szakértői Intézete"
    },
    {
        "key": "HU211000",
        "value": "NAV Baranya Megyei AVI"
    },
    {
        "key": "HU213000",
        "value": "NAV Baranya Megyei AVI Mohácsi Hk."
    },
    {
        "key": "HU221000",
        "value": "NAV Somogy Megyei AVI"
    },
    {
        "key": "HU231000",
        "value": "NAV Tolna Megyei AVI"
    },
    {
        "key": "HU311040",
        "value": "NAV BÁKM AVI Royal Sped Zrt.Sz.Hely"
    },
    {
        "key": "HU311500",
        "value": "NAV Bács-Kiskun Megyei AVI"
    },
    {
        "key": "HU315000",
        "value": "NAV BÁKM AVI Hercegszántói Hk."
    },
    {
        "key": "HU316000",
        "value": "NAV BÁKM AVI Szolg. Hely Kelebia"
    },
    {
        "key": "HU317000",
        "value": "NAV BÁKM AVI Tompai Hk."
    },
    {
        "key": "HU321000",
        "value": "NAV Jász-Nagykun-Szolnok Megyei AVI"
    },
    {
        "key": "HU321010",
        "value": "NAV JNSZM AVI MB 2001 Kft. Sz. Hely"
    },
    {
        "key": "HU331000",
        "value": "NAV BÁKM AVI Bajai Szolg. Hely I."
    },
    {
        "key": "HU331010",
        "value": "NAV BÁKM AVI Bajai Szolg. Hely II."
    },
    {
        "key": "HU332000",
        "value": "NAV BÁKM AVI Kiskőrösi Szolg. Hely"
    },
    {
        "key": "HU411000",
        "value": "NAV Borsod-Abaúj-Zemplén Megyei AVI"
    },
    {
        "key": "HU411020",
        "value": "NAV BAZM AVI ÁTI Depó Szolg. Hely"
    },
    {
        "key": "HU421000",
        "value": "NAV Heves Megyei AVI"
    },
    {
        "key": "HU422000",
        "value": "NAV Heves Megyei AVI Gyöngyös Sz.H"
    },
    {
        "key": "HU511000",
        "value": "NAV Csongrád Megyei AVI"
    },
    {
        "key": "HU511010",
        "value": "NAV CSOM AVI Zoll-Platz Kft. Sz. H."
    },
    {
        "key": "HU515000",
        "value": "NAV Csongrád Megyei AVI Röszkei Hk."
    },
    {
        "key": "HU517000",
        "value": "NAV CSOM AVI Tiszaszigeti Sz. Hely"
    },
    {
        "key": "HU521000",
        "value": "NAV Békés Megyei AVI"
    },
    {
        "key": "HU521050",
        "value": "NAV BÉM AVI Pintér Vám Szolg. Hely"
    },
    {
        "key": "HU611000",
        "value": "NAV Győr-Moson-Sopron Megyei AVI"
    },
    {
        "key": "HU611040",
        "value": "NAVGYMSAVI QuehenbergerLogisticsKft"
    },
    {
        "key": "HU618000",
        "value": "NAV GYMSM AVI Soproni Szolg. Hely"
    },
    {
        "key": "HU618010",
        "value": "NAV GYMSM AVI Gysev Cargo Szolg. H."
    },
    {
        "key": "HU621000",
        "value": "NAV Komárom-Esztergom Megyei AVI"
    },
    {
        "key": "HU621030",
        "value": "NAV KEM AVI ZEBRA NEO2014 SzolgHely"
    },
    {
        "key": "HU624000",
        "value": "NAV KEM AVI Esztergomi Szolg. Hely"
    },
    {
        "key": "HU624100",
        "value": "NAV KEM AVI Weskamp Bt. Szolg. Hely"
    },
    {
        "key": "HU631000",
        "value": "NAV Veszprém Megyei AVI"
    },
    {
        "key": "HU634000",
        "value": "NAV VEM AVI Pápai Sz. Hely"
    },
    {
        "key": "HU634100",
        "value": "NAV VEM AVI Pápa Repülőtér"
    },
    {
        "key": "HU711000",
        "value": "NAV Hajdú-Bihar Megyei AVI"
    },
    {
        "key": "HU721000",
        "value": "NAV Szabolcs-Szatmár-Bereg M. AVI"
    },
    {
        "key": "HU724000",
        "value": "NAV SZSZBM AVI Záhonyi Hk."
    },
    {
        "key": "HU724010",
        "value": "NAV SZSZBM AVI Eperjeskei Sz. Hely"
    },
    {
        "key": "HU726020",
        "value": "NAV SZSZBM AVI Barabás Sz. Hely"
    },
    {
        "key": "HU726030",
        "value": "NAV SZSZBM AVI Lónyai Sz. Hely"
    },
    {
        "key": "HU726040",
        "value": "NAV SZSZBM AVI Beregsurányi Hk."
    },
    {
        "key": "HU727000",
        "value": "NAV SZSZBM AVI Tiszabecsi Sz. Hely"
    },
    {
        "key": "HU727010",
        "value": "NAV SZSZBM AVI Nagyhódosi Szolg. H."
    },
    {
        "key": "HU811000",
        "value": "NAV PMAVI"
    },
    {
        "key": "HU811010",
        "value": "NAV PMAVI Depo-Zoll Kft. Sz. Hely"
    },
    {
        "key": "HU811020",
        "value": "NAV Pest Megyei AVI Schenker Kft."
    },
    {
        "key": "HU811190",
        "value": "NAV DBP AVI Trimex Kft. Sz. Hely"
    },
    {
        "key": "HU821000",
        "value": "NAV Fejér Megyei AVI"
    },
    {
        "key": "HU821060",
        "value": "NAV FEM AVI LOGALBA Kft. Sz. Hely"
    },
    {
        "key": "HU831000",
        "value": "NAV NÓM AVI"
    },
    {
        "key": "HU911000",
        "value": "NAV VAM AVI"
    },
    {
        "key": "HU911010",
        "value": "NAV VAM AVI VÁMCO Szolgálati Hely"
    },
    {
        "key": "HU911030",
        "value": "NAV VAM AVI Zoll-Sped Szolg. Hely"
    },
    {
        "key": "HU921000",
        "value": "NAV ZAM AVI"
    },
    {
        "key": "HU921060",
        "value": "NAV ZAM AVI Sármelléki Repülőtér"
    },
    {
        "key": "HU922000",
        "value": "NAV ZAMAVI Nagykanizsai Szolg. Hely"
    },
    {
        "key": "HU922040",
        "value": "NAV ZAM AVI GE Hungary Rt."
    },
    {
        "key": "HU922050",
        "value": "NAV ZAM AVI Nagykanizsa VSZ Zrt."
    },
    {
        "key": "IE003151",
        "value": "Central Transit Office"
    },
    {
        "key": "IEARK100",
        "value": "ARKLOW"
    },
    {
        "key": "IEASH900",
        "value": "Customs Drug Enforcement & IBD"
    },
    {
        "key": "IEATH200",
        "value": "Athlone Customs Office"
    },
    {
        "key": "IEATH800",
        "value": "Athlone"
    },
    {
        "key": "IEATH900",
        "value": "Athlone Mail Centre"
    },
    {
        "key": "IEATY800",
        "value": "Athy"
    },
    {
        "key": "IEAUU000",
        "value": "NAVAN"
    },
    {
        "key": "IEBYT100",
        "value": "BANTRY, CORK"
    },
    {
        "key": "IECAV800",
        "value": "Cavan"
    },
    {
        "key": "IECAW800",
        "value": "Carlow"
    },
    {
        "key": "IECKC900",
        "value": "Cork Cast"
    },
    {
        "key": "IECKM900",
        "value": "CORK MARITIME UNIT"
    },
    {
        "key": "IECKR900",
        "value": "CORK RAET"
    },
    {
        "key": "IECLB800",
        "value": "Castlebar"
    },
    {
        "key": "IECLL800",
        "value": "Clonmel"
    },
    {
        "key": "IEDDK100",
        "value": "DUNDALK"
    },
    {
        "key": "IEDDK800",
        "value": "Dundalk Excise"
    },
    {
        "key": "IEDDK899",
        "value": "CLO (EXCISE)"
    },
    {
        "key": "IEDLG100",
        "value": "DUN LAOGHAIRE QUAYSIDE(CO DUBLIN)"
    },
    {
        "key": "IEDON800",
        "value": "Donegal"
    },
    {
        "key": "IEDPE900",
        "value": "NEW CUSTOMS HOUSE ENFORCEMENT"
    },
    {
        "key": "IEDPL900",
        "value": "NEW CUSTOM HSE LANDING STATION"
    },
    {
        "key": "IEDRO100",
        "value": "DROGHEDA PORT"
    },
    {
        "key": "IEDRO800",
        "value": "Drogheda Excise"
    },
    {
        "key": "IEDUB100",
        "value": "DUBLIN PORT"
    },
    {
        "key": "IEDUB200",
        "value": "DUBLIN ACP CONTROL"
    },
    {
        "key": "IEDUB400",
        "value": "DUBLIN AIRPORT"
    },
    {
        "key": "IEDUB800",
        "value": "Oil Wharf Dublin"
    },
    {
        "key": "IEDUB801",
        "value": "Dublin South County"
    },
    {
        "key": "IEDUB802",
        "value": "Fingal"
    },
    {
        "key": "IEDUB803",
        "value": "Dublin South City"
    },
    {
        "key": "IEDUB804",
        "value": "Dun Laoghaire-Rathdown"
    },
    {
        "key": "IEDUB805",
        "value": "Dublin City Centre"
    },
    {
        "key": "IEDUB806",
        "value": "LCD (DTM) TALLAGHT"
    },
    {
        "key": "IEDUB807",
        "value": "LCD (DTM) SETANTA CENTRE"
    },
    {
        "key": "IEDUB808",
        "value": "LCD (HIM) SETANTA CENTRE,"
    },
    {
        "key": "IEDUB809",
        "value": "DUBLIN NORTH CITY DISTRICT"
    },
    {
        "key": "IEDUB900",
        "value": "Dublin Mail Centre"
    },
    {
        "key": "IEENI800",
        "value": "Clare"
    },
    {
        "key": "IEFCW100",
        "value": "Frank Cassin Wharf"
    },
    {
        "key": "IEFEN100",
        "value": "FENIT CUSTOMS AND EXCISE"
    },
    {
        "key": "IEFOV100",
        "value": "FOYNES"
    },
    {
        "key": "IEGRN100",
        "value": "GREENORE PORT, GOVT BLDGS,"
    },
    {
        "key": "IEGRN800",
        "value": "Greenore Excise"
    },
    {
        "key": "IEGWY100",
        "value": "GALWAY"
    },
    {
        "key": "IEGWY800",
        "value": "Galway County"
    },
    {
        "key": "IEGWY801",
        "value": "Galway Roscommon"
    },
    {
        "key": "IEKBS100",
        "value": "KILLYBEGS"
    },
    {
        "key": "IEKIL800",
        "value": "KILDARE DISTRICT-MOUNT ST"
    },
    {
        "key": "IEKIL801",
        "value": "KILDARE DISTRICT - NAAS"
    },
    {
        "key": "IEKLK100",
        "value": "KILKENNY"
    },
    {
        "key": "IEKLK800",
        "value": "LCD (DTM) Kilkenny"
    },
    {
        "key": "IEKMW900",
        "value": "KMW ENFORCEMENT"
    },
    {
        "key": "IELIF300",
        "value": "Lifford C & E & VRO,"
    },
    {
        "key": "IELMK100",
        "value": "Limerick Docks,"
    },
    {
        "key": "IELMK800",
        "value": "LCD (FOOD INDUSTRY) LIMERICK"
    },
    {
        "key": "IELMK801",
        "value": "LIMERICK EXCISE"
    },
    {
        "key": "IELTR300",
        "value": "LETTERKENNY"
    },
    {
        "key": "IEMON300",
        "value": "Monaghan Station"
    },
    {
        "key": "IENEN001",
        "value": "AEO"
    },
    {
        "key": "IENEN002",
        "value": "Origin Unit"
    },
    {
        "key": "IENEN003",
        "value": "Customs Decisions"
    },
    {
        "key": "IENEN004",
        "value": "Classification Unit (BTI)"
    },
    {
        "key": "IENEN005",
        "value": "eAEO"
    },
    {
        "key": "IENEN101",
        "value": "ECS/NCTS/ICS Project Team"
    },
    {
        "key": "IENEN400",
        "value": "ESE 2nd Tier District"
    },
    {
        "key": "IENRS100",
        "value": "New Ross"
    },
    {
        "key": "IEORK400",
        "value": "CORK AIRPORT"
    },
    {
        "key": "IEORK800",
        "value": "CORK NORTH WEST DISTRICT"
    },
    {
        "key": "IEORK801",
        "value": "LCD (DTMM) CORK"
    },
    {
        "key": "IEORK802",
        "value": "CORK SOUTH WEST DISTRICT"
    },
    {
        "key": "IEORK803",
        "value": "TRADE FACILITATION & CASH OFFICE"
    },
    {
        "key": "IEORK804",
        "value": "CORK EAST DISTRICT"
    },
    {
        "key": "IEPTL500",
        "value": "PORTLAOISE MAIL CENTRE"
    },
    {
        "key": "IERON800",
        "value": "ROSCOMMON"
    },
    {
        "key": "IEROS100",
        "value": "Rosslare Harbour"
    },
    {
        "key": "IEROS200",
        "value": "Rosslare Customs Office"
    },
    {
        "key": "IESLI100",
        "value": "Sligo"
    },
    {
        "key": "IESNN400",
        "value": "Shannon Airport"
    },
    {
        "key": "IETHR800",
        "value": "THURLES"
    },
    {
        "key": "IETIV100",
        "value": "TIVOLI"
    },
    {
        "key": "IETRA100",
        "value": "Tralee Government Buildings"
    },
    {
        "key": "IEWAT100",
        "value": "Waterford Government Buildings"
    },
    {
        "key": "IEWAT101",
        "value": "Belview, Waterford"
    },
    {
        "key": "IEWEX800",
        "value": "WEXFORD"
    },
    {
        "key": "IEWEX900",
        "value": "Wexford"
    },
    {
        "key": "IEWHI100",
        "value": "Whitegate"
    },
    {
        "key": "IEWIC100",
        "value": "Wicklow"
    },
    {
        "key": "IS000100",
        "value": "Reykjavik toll"
    },
    {
        "key": "IT014000",
        "value": "Direzione Ufficio delle Dogane di FOGGIA"
    },
    {
        "key": "IT014100",
        "value": "Ufficio delle Dogane di FOGGIA"
    },
    {
        "key": "IT014101",
        "value": "Sezione Operativa Territoriale di MANFREDONIA"
    },
    {
        "key": "IT014199",
        "value": "Area Gestione Tributi dell'UD di FOGGIA"
    },
    {
        "key": "IT015000",
        "value": "Direzione Ufficio delle Dogane di BRINDISI"
    },
    {
        "key": "IT015100",
        "value": "Ufficio delle Dogane di BRINDISI"
    },
    {
        "key": "IT015101",
        "value": "Sezione Operativa Territoriale di Aeroporto Casale"
    },
    {
        "key": "IT015102",
        "value": "Sezione Operativa Territoriale COSTA MORENA"
    },
    {
        "key": "IT015199",
        "value": "Area Gestione Tributi dell'UD di BRINDISI"
    },
    {
        "key": "IT016000",
        "value": "Direzione Ufficio delle Dogane di LECCE"
    },
    {
        "key": "IT016100",
        "value": "Ufficio delle Dogane di LECCE"
    },
    {
        "key": "IT016101",
        "value": "Sezione Operativa Territoriale di GALLIPOLI"
    },
    {
        "key": "IT016102",
        "value": "Sezione Operativa Territoriale di OTRANTO"
    },
    {
        "key": "IT016199",
        "value": "Area Gestione Tributi dell'UD di LECCE"
    },
    {
        "key": "IT017000",
        "value": "Direzione Ufficio delle Dogane di TARANTO"
    },
    {
        "key": "IT017100",
        "value": "Ufficio delle Dogane di TARANTO"
    },
    {
        "key": "IT017199",
        "value": "Area Gestione Tributi dell'UD di TARANTO"
    },
    {
        "key": "IT018000",
        "value": "Direzione Ufficio delle Dogane di BARI"
    },
    {
        "key": "IT018100",
        "value": "Ufficio delle Dogane di BARI"
    },
    {
        "key": "IT018101",
        "value": "Sezione Operativa Territoriale di Aeroporto Bari - Palese"
    },
    {
        "key": "IT018102",
        "value": "Sezione Operativa Territoriale di BAT - BARLETTA"
    },
    {
        "key": "IT018104",
        "value": "Sezione Operativa Territoriale di MOLFETTA"
    },
    {
        "key": "IT018105",
        "value": "Sezione Operativa Territoriale di MONOPOLI"
    },
    {
        "key": "IT018106",
        "value": "Sezione Operativa Territoriale Interporto Bari"
    },
    {
        "key": "IT018199",
        "value": "Area Gestione Tributi dell'UD di BARI"
    },
    {
        "key": "IT019000",
        "value": "Direzione Ufficio delle Dogane di POTENZA"
    },
    {
        "key": "IT019100",
        "value": "Ufficio delle Dogane di POTENZA"
    },
    {
        "key": "IT019101",
        "value": "Sezione Operativa Territoriale di FERRANDINA"
    },
    {
        "key": "IT019102",
        "value": "Sezione Operativa Territoriale di MATERA"
    },
    {
        "key": "IT019199",
        "value": "Area Gestione Tributi dell'UD di POTENZA"
    },
    {
        "key": "IT022300",
        "value": "Ufficio delle Dogane di REGGIO EMILIA"
    },
    {
        "key": "IT022399",
        "value": "Area Gestione Tributi dell'UD di REGGIO EMILIA"
    },
    {
        "key": "IT022999",
        "value": "Direzione Ufficio delle Dogane di REGGIO EMILIA"
    },
    {
        "key": "IT024000",
        "value": "Direzione Ufficio delle Dogane di FORLI'-CESENA"
    },
    {
        "key": "IT024100",
        "value": "Ufficio delle Dogane di FORLI'-CESENA"
    },
    {
        "key": "IT024101",
        "value": "Sezione Operativa Territoriale di CESENA"
    },
    {
        "key": "IT024102",
        "value": "Sezione Operativa Territoriale AEROPORTO RIDOLFI"
    },
    {
        "key": "IT024199",
        "value": "Area Gestione Tributi dell'UD di FORLI'-CESENA"
    },
    {
        "key": "IT025000",
        "value": "Direzione Ufficio delle Dogane di MODENA"
    },
    {
        "key": "IT025100",
        "value": "Ufficio delle Dogane di MODENA"
    },
    {
        "key": "IT025199",
        "value": "Area Gestione Tributi dell'UD di MODENA"
    },
    {
        "key": "IT026000",
        "value": "Direzione Ufficio delle Dogane di RIMINI"
    },
    {
        "key": "IT026100",
        "value": "Ufficio delle Dogane di RIMINI"
    },
    {
        "key": "IT026101",
        "value": "Sezione Operativa Territoriale AEROPORTO MIRAMARE 'F. FELLINI'"
    },
    {
        "key": "IT026199",
        "value": "Area Gestione Tributi dell'UD di RIMINI"
    },
    {
        "key": "IT027000",
        "value": "Direzione Ufficio delle Dogane di FERRARA"
    },
    {
        "key": "IT027100",
        "value": "Ufficio delle Dogane di FERRARA"
    },
    {
        "key": "IT027199",
        "value": "Area Gestione Tributi dell'UD di FERRARA"
    },
    {
        "key": "IT028000",
        "value": "Direzione Ufficio delle Dogane di PARMA"
    },
    {
        "key": "IT028100",
        "value": "Ufficio delle Dogane di PARMA"
    },
    {
        "key": "IT028101",
        "value": "AEROPORTO G. VERDI"
    },
    {
        "key": "IT028199",
        "value": "Area Gestione Tributi dell'UD di PARMA"
    },
    {
        "key": "IT029000",
        "value": "Direzione Ufficio delle Dogane di PIACENZA"
    },
    {
        "key": "IT029100",
        "value": "Ufficio delle Dogane di PIACENZA"
    },
    {
        "key": "IT029199",
        "value": "Area Gestione Tributi dell'UD di PIACENZA"
    },
    {
        "key": "IT034000",
        "value": "Direzione Ufficio delle Dogane di BOLZANO"
    },
    {
        "key": "IT034100",
        "value": "Ufficio delle Dogane di BOLZANO"
    },
    {
        "key": "IT034101",
        "value": "Sezione Operativa Territoriale di RESIA"
    },
    {
        "key": "IT034102",
        "value": "Sezione Operativa Territoriale AEROPORTO SAN GIACOMO"
    },
    {
        "key": "IT034103",
        "value": "Sezione Operativa Territoriale MM.GG. BOLZANO"
    },
    {
        "key": "IT034104",
        "value": "Sezione Operativa Territoriale di BRESSANONE"
    },
    {
        "key": "IT034105",
        "value": "Sezione Operativa Territoriale di BRUNICO"
    },
    {
        "key": "IT034106",
        "value": "Sezione Operativa Territoriale di CAMPO DI TRENS"
    },
    {
        "key": "IT034107",
        "value": "Sezione Operativa Territoriale di MERANO"
    },
    {
        "key": "IT034108",
        "value": "Sezione Operativa Territoriale di TUBRE"
    },
    {
        "key": "IT034199",
        "value": "Area Gestione Tributi dell'UD di BOLZANO"
    },
    {
        "key": "IT035000",
        "value": "Direzione Ufficio delle Dogane di TRENTO"
    },
    {
        "key": "IT035100",
        "value": "Ufficio delle Dogane di TRENTO"
    },
    {
        "key": "IT035101",
        "value": "Sezione Operativa Territoriale di RONCAFORT"
    },
    {
        "key": "IT035199",
        "value": "Area Gestione Tributi dell'UD di TRENTO"
    },
    {
        "key": "IT043000",
        "value": "Direzione Ufficio delle Dogane di SASSARI"
    },
    {
        "key": "IT043100",
        "value": "Ufficio delle Dogane di SASSARI"
    },
    {
        "key": "IT043101",
        "value": "Sezione Operativa Territoriale di PORTO TORRES"
    },
    {
        "key": "IT043102",
        "value": "Sezione Operativa Territoriale di ALGHERO"
    },
    {
        "key": "IT043103",
        "value": "Sezione Operativa Territoriale di LA MADDALENA - S. TERESA DI GALLURA"
    },
    {
        "key": "IT043104",
        "value": "Sezione Operativa Territoriale di OLBIA"
    },
    {
        "key": "IT043199",
        "value": "Area Gestione Tributi dell'UD di SASSARI"
    },
    {
        "key": "IT044000",
        "value": "Direzione Ufficio delle Dogane di CAGLIARI"
    },
    {
        "key": "IT044100",
        "value": "Ufficio delle Dogane di CAGLIARI"
    },
    {
        "key": "IT044101",
        "value": "Sezione Operativa Territoriale di AEROPORTO ELMAS"
    },
    {
        "key": "IT044103",
        "value": "Sezione Operativa Territoriale di ORISTANO"
    },
    {
        "key": "IT044104",
        "value": "Sezione Operativa Territoriale di PORTOVESME"
    },
    {
        "key": "IT044106",
        "value": "Sezione Operativa Territoriale di PORTO CANALE DI CAGLIARI"
    },
    {
        "key": "IT044199",
        "value": "Area Gestione Tributi dell'UD di CAGLIARI"
    },
    {
        "key": "IT051200",
        "value": "Ufficio delle Dogane di AREZZO"
    },
    {
        "key": "IT051201",
        "value": "Sezione Operativa Territoriale di SIENA"
    },
    {
        "key": "IT051299",
        "value": "Area Gestione Tributi dell'UD di AREZZO"
    },
    {
        "key": "IT051999",
        "value": "Direzione Ufficio delle Dogane di AREZZO"
    },
    {
        "key": "IT054000",
        "value": "Direzione Ufficio delle Dogane di PISA"
    },
    {
        "key": "IT054100",
        "value": "Ufficio delle Dogane di PISA"
    },
    {
        "key": "IT054101",
        "value": "Sezione Operativa Territoriale AEROPORTO DI PISA"
    },
    {
        "key": "IT054102",
        "value": "Sezione Operativa Territoriale di VIAREGGIO"
    },
    {
        "key": "IT054103",
        "value": "Sezione Operativa Territoriale di LUCCA"
    },
    {
        "key": "IT054104",
        "value": "Sezione Operativa Territoriale di MARINA DI CARRARA"
    },
    {
        "key": "IT054199",
        "value": "Area Gestione Tributi dell'UD di PISA"
    },
    {
        "key": "IT055000",
        "value": "Direzione Ufficio delle Dogane di LIVORNO"
    },
    {
        "key": "IT055100",
        "value": "Ufficio delle Dogane di LIVORNO"
    },
    {
        "key": "IT055101",
        "value": "Sezione Operativa Territoriale di PIOMBINO"
    },
    {
        "key": "IT055102",
        "value": "Sezione Operativa Territoriale di PORTOFERRAIO"
    },
    {
        "key": "IT055103",
        "value": "Sezione Operativa Territoriale di GROSSETO"
    },
    {
        "key": "IT055199",
        "value": "Area Gestione Tributi dell'UD di LIVORNO"
    },
    {
        "key": "IT056000",
        "value": "Direzione Ufficio dell'UD di PRATO E PISTOIA"
    },
    {
        "key": "IT056100",
        "value": "Ufficio delle Dogane di PRATO E PISTOIA"
    },
    {
        "key": "IT056199",
        "value": "Area Gestione Tributi dell'UD di PRATO E PISTOIA"
    },
    {
        "key": "IT057000",
        "value": "Direzione Ufficio delle Dogane di FIRENZE"
    },
    {
        "key": "IT057100",
        "value": "Ufficio delle Dogane di FIRENZE"
    },
    {
        "key": "IT057101",
        "value": "Sezione Operativa Territoriale AEROPORTO A. VESPUCCI"
    },
    {
        "key": "IT057199",
        "value": "Area Gestione Tributi dell'UD di FIRENZE"
    },
    {
        "key": "IT066000",
        "value": "Direzione Ufficio delle Dogane di SAVONA"
    },
    {
        "key": "IT066100",
        "value": "Ufficio delle Dogane di SAVONA"
    },
    {
        "key": "IT066101",
        "value": "Sezione Operativa Territoriale di VADO LIGURE"
    },
    {
        "key": "IT066199",
        "value": "Area Gestione Tributi dell'UD di SAVONA"
    },
    {
        "key": "IT067000",
        "value": "Direzione Ufficio delle Dogane di IMPERIA"
    },
    {
        "key": "IT067100",
        "value": "Ufficio delle Dogane di IMPERIA"
    },
    {
        "key": "IT067101",
        "value": "Sezione Operativa Territoriale di SANREMO"
    },
    {
        "key": "IT067199",
        "value": "Area Gestione Tributi dell'UD di IMPERIA"
    },
    {
        "key": "IT068000",
        "value": "Direzione Ufficio delle Dogane di LA SPEZIA"
    },
    {
        "key": "IT068100",
        "value": "Ufficio delle Dogane di LA SPEZIA"
    },
    {
        "key": "IT068199",
        "value": "Area Gestione Tributi dell'UD di LA SPEZIA"
    },
    {
        "key": "IT079000",
        "value": "Direzione Ufficio delle Dogane di MANTOVA"
    },
    {
        "key": "IT079100",
        "value": "Ufficio delle Dogane di MANTOVA"
    },
    {
        "key": "IT079199",
        "value": "Area Gestione Tributi dell'UD di MANTOVA"
    },
    {
        "key": "IT084000",
        "value": "Direzione Ufficio delle Dogane di SALERNO"
    },
    {
        "key": "IT084100",
        "value": "Ufficio delle Dogane di SALERNO"
    },
    {
        "key": "IT084199",
        "value": "Area Gestione Tributi dell'UD di SALERNO"
    },
    {
        "key": "IT085000",
        "value": "Direzione Ufficio delle Dogane di CATANZARO"
    },
    {
        "key": "IT085100",
        "value": "Ufficio delle Dogane di CATANZARO"
    },
    {
        "key": "IT085101",
        "value": "Sezione Operativa Territoriale di AEROPORTO DI LAMEZIA TERME"
    },
    {
        "key": "IT085102",
        "value": "Sezione Operativa Territoriale di COSENZA"
    },
    {
        "key": "IT085103",
        "value": "Sezione Operativa Territoriale di CROTONE"
    },
    {
        "key": "IT085104",
        "value": "Sezione Operativa Territoriale di CORIGLIANO CALABRO"
    },
    {
        "key": "IT085199",
        "value": "Area Gestione Tributi dell'UD di CATANZARO"
    },
    {
        "key": "IT086000",
        "value": "Direzione Ufficio delle Dogane di GIOIA TAURO"
    },
    {
        "key": "IT086100",
        "value": "Ufficio delle Dogane di GIOIA TAURO"
    },
    {
        "key": "IT086199",
        "value": "Area Gestione Tributi dell'UD di GIOIA TAURO"
    },
    {
        "key": "IT087000",
        "value": "Direzione Ufficio delle Dogane di REGGIO CALABRIA"
    },
    {
        "key": "IT087100",
        "value": "Ufficio delle Dogane di REGGIO CALABRIA"
    },
    {
        "key": "IT087101",
        "value": "Sezione Operativa Territoriale AEROPORTO DELLO STRETTO"
    },
    {
        "key": "IT087102",
        "value": "Sezione Operativa Territoriale di VIBO VALENTIA"
    },
    {
        "key": "IT087199",
        "value": "Area Gestione Tributi dell'UD di REGGIO CALABRIA"
    },
    {
        "key": "IT088000",
        "value": "Direzione Ufficio delle Dogane di CASERTA"
    },
    {
        "key": "IT088100",
        "value": "Ufficio delle Dogane di CASERTA"
    },
    {
        "key": "IT088199",
        "value": "Area Gestione Tributi dell'UD di CASERTA"
    },
    {
        "key": "IT089000",
        "value": "Direzione Ufficio delle Dogane di BENEVENTO"
    },
    {
        "key": "IT089100",
        "value": "Ufficio delle Dogane di BENEVENTO"
    },
    {
        "key": "IT089101",
        "value": "Sezione Operativa Territoriale di AVELLINO"
    },
    {
        "key": "IT089199",
        "value": "Area Gestione Tributi dell'UD di BENEVENTO"
    },
    {
        "key": "IT096000",
        "value": "Direzione Ufficio delle Dogane di PORTO EMPEDOCLE"
    },
    {
        "key": "IT096100",
        "value": "Ufficio delle Dogane di PORTO EMPEDOCLE"
    },
    {
        "key": "IT096101",
        "value": "Sezione Operativa Territoriale di GELA"
    },
    {
        "key": "IT096102",
        "value": "Sezione Operativa Territoriale di SCIACCA"
    },
    {
        "key": "IT096103",
        "value": "Sezione Operativa Territoriale di LICATA"
    },
    {
        "key": "IT096104",
        "value": "Sezione Operativa Territoriale di LAMPEDUSA"
    },
    {
        "key": "IT096199",
        "value": "Area Gestione Tributi dell'UD di PORTO EMPEDOCLE"
    },
    {
        "key": "IT097000",
        "value": "Direzione Ufficio delle Dogane di TRAPANI"
    },
    {
        "key": "IT097100",
        "value": "Ufficio delle Dogane di TRAPANI"
    },
    {
        "key": "IT097101",
        "value": "Sezione Operativa Territoriale di MARSALA"
    },
    {
        "key": "IT097102",
        "value": "Sezione Operativa Territoriale di MAZARA DEL VALLO"
    },
    {
        "key": "IT097103",
        "value": "Sezione Operativa Territoriale di CASTELLAMMARE DEL GOLFO"
    },
    {
        "key": "IT097104",
        "value": "Sezione Operativa Territoriale di PANTELLERIA"
    },
    {
        "key": "IT097199",
        "value": "Area Gestione Tributi dell'UD di TRAPANI"
    },
    {
        "key": "IT098000",
        "value": "Direzione Ufficio delle Dogane di MESSINA"
    },
    {
        "key": "IT098100",
        "value": "Ufficio delle Dogane di MESSINA"
    },
    {
        "key": "IT098101",
        "value": "Sezione Operativa Territoriale di MILAZZO"
    },
    {
        "key": "IT098102",
        "value": "Sezione Operativa Territoriale di GIARDINI NAXOS"
    },
    {
        "key": "IT098103",
        "value": "Sezione Operativa Territoriale di LIPARI"
    },
    {
        "key": "IT098104",
        "value": "Sezione Operativa Territoriale di SANT'AGATA DI MILITELLO"
    },
    {
        "key": "IT098199",
        "value": "Area Gestione Tributi dell'UD di MESSINA"
    },
    {
        "key": "IT099000",
        "value": "Direzione Ufficio delle Dogane di SIRACUSA"
    },
    {
        "key": "IT099100",
        "value": "Ufficio delle Dogane di SIRACUSA"
    },
    {
        "key": "IT099101",
        "value": "Sezione Operativa Territoriale di AUGUSTA"
    },
    {
        "key": "IT099102",
        "value": "Sezione Operativa Territoriale di POZZALLO"
    },
    {
        "key": "IT099103",
        "value": "Sezione Operativa Territoriale di RAGUSA"
    },
    {
        "key": "IT099199",
        "value": "Area Gestione Tributi dell'UD di SIRACUSA"
    },
    {
        "key": "IT105100",
        "value": "Ufficio delle Dogane di GAETA"
    },
    {
        "key": "IT105101",
        "value": "Sezione Operativa Territoriale di APRILIA"
    },
    {
        "key": "IT105104",
        "value": "Sezione Operativa Territoriale di LATINA"
    },
    {
        "key": "IT105199",
        "value": "Area Gestione Tributi dell'UD di GAETA"
    },
    {
        "key": "IT105999",
        "value": "Direzione Ufficio delle Dogane di GAETA"
    },
    {
        "key": "IT107000",
        "value": "Direzione Ufficio delle Dogane di PERUGIA"
    },
    {
        "key": "IT107100",
        "value": "Ufficio delle Dogane di PERUGIA"
    },
    {
        "key": "IT107103",
        "value": "Sezione Operativa Territoriale di TERNI"
    },
    {
        "key": "IT107199",
        "value": "Area Gestione Tributi dell'UD di PERUGIA"
    },
    {
        "key": "IT116000",
        "value": "Direzione Ufficio delle Dogane di BIELLA"
    },
    {
        "key": "IT116100",
        "value": "Ufficio delle Dogane di BIELLA"
    },
    {
        "key": "IT116101",
        "value": "Sezione Operativa Territoriale MM.GG. Saima Avandero S.p.A."
    },
    {
        "key": "IT116199",
        "value": "Area Gestione Tributi dell'UD di BIELLA"
    },
    {
        "key": "IT117000",
        "value": "Direzione Ufficio delle Dogane di VERCELLI"
    },
    {
        "key": "IT117100",
        "value": "Ufficio delle Dogane di VERCELLI"
    },
    {
        "key": "IT117102",
        "value": "Sezione Operativa Territoriale MM.GG. dei Trafori S.p.A."
    },
    {
        "key": "IT117199",
        "value": "Area Gestione Tributi dell'UD di VERCELLI"
    },
    {
        "key": "IT118000",
        "value": "Direzione Ufficio delle Dogane di AOSTA"
    },
    {
        "key": "IT118100",
        "value": "Ufficio delle Dogane di AOSTA"
    },
    {
        "key": "IT118101",
        "value": "Sezione Operativa Territoriale TRAFORO GRAN SAN BERNARDO"
    },
    {
        "key": "IT118199",
        "value": "Area Gestione Tributi dell'UD di AOSTA"
    },
    {
        "key": "IT119000",
        "value": "Direzione Ufficio delle Dogane di VERBANO-CUSIO-OSSOLA"
    },
    {
        "key": "IT119100",
        "value": "Ufficio delle Dogane di VERBANO-CUSIO-OSSOLA"
    },
    {
        "key": "IT119101",
        "value": "Sezione Operativa Territoriale di PIAGGIO VALMARA"
    },
    {
        "key": "IT119102",
        "value": "Sezione Operativa Territoriale di ISELLE TRASQUERA"
    },
    {
        "key": "IT119199",
        "value": "Area Gestione Tributi dell'UD di VERBANO-CUSIO-OSSOLA"
    },
    {
        "key": "IT126000",
        "value": "Direzione Ufficio delle Dogane di PORDENONE"
    },
    {
        "key": "IT126100",
        "value": "Ufficio delle Dogane di PORDENONE"
    },
    {
        "key": "IT126199",
        "value": "Area Gestione Tributi dell'UD di PORDENONE"
    },
    {
        "key": "IT127000",
        "value": "Direzione Ufficio delle Dogane di GORIZIA"
    },
    {
        "key": "IT127100",
        "value": "Ufficio delle Dogane di GORIZIA"
    },
    {
        "key": "IT127101",
        "value": "Sezione Operativa Territoriale di MONFALCONE"
    },
    {
        "key": "IT127102",
        "value": "Sezione Operativa Territoriale RONCHI D. LEGIONARI - AER. GIULIANO"
    },
    {
        "key": "IT127199",
        "value": "Area Gestione Tributi dell'UD di GORIZIA"
    },
    {
        "key": "IT128000",
        "value": "Direzione Ufficio delle Dogane di UDINE"
    },
    {
        "key": "IT128100",
        "value": "Ufficio delle Dogane di UDINE"
    },
    {
        "key": "IT128101",
        "value": "Sezione Operativa Territoriale di PONTEBBA"
    },
    {
        "key": "IT128102",
        "value": "Sezione Operativa Territoriale di PORTO NOGARO"
    },
    {
        "key": "IT128103",
        "value": "Sezione Operativa Territoriale Z.A.U. (Zona Annonaria Udinese)"
    },
    {
        "key": "IT128199",
        "value": "Area Gestione Tributi dell'UD di UDINE"
    },
    {
        "key": "IT129000",
        "value": "Direzione Ufficio delle Dogane di TRIESTE"
    },
    {
        "key": "IT129100",
        "value": "Ufficio delle Dogane di TRIESTE"
    },
    {
        "key": "IT129102",
        "value": "Sezione Operativa Territoriale PORTO INDUSTRIALE"
    },
    {
        "key": "IT129103",
        "value": "Sezione Operativa Territoriale PUNTO FRANCO NUOVO"
    },
    {
        "key": "IT129104",
        "value": "Sezione Operativa Territoriale PUNTO FRANCO VECCHIO"
    },
    {
        "key": "IT129199",
        "value": "Area Gestione Tributi dell'UD di TRIESTE"
    },
    {
        "key": "IT134000",
        "value": "Direzione Ufficio delle Dogane di TREVISO"
    },
    {
        "key": "IT134100",
        "value": "Ufficio delle Dogane di TREVISO"
    },
    {
        "key": "IT134101",
        "value": "Sezione Operativa Territoriale AEROPORTO S. GIUSEPPE"
    },
    {
        "key": "IT134102",
        "value": "Sezione Operativa Territoriale di SEDICO"
    },
    {
        "key": "IT134199",
        "value": "Area Gestione Tributi dell'UD di TREVISO"
    },
    {
        "key": "IT135000",
        "value": "Direzione Ufficio delle Dogane di VICENZA"
    },
    {
        "key": "IT135100",
        "value": "Ufficio delle Dogane di VICENZA"
    },
    {
        "key": "IT135199",
        "value": "Area Gestione Tributi dell'UD di VICENZA"
    },
    {
        "key": "IT136000",
        "value": "Direzione Ufficio delle Dogane di VERONA"
    },
    {
        "key": "IT136100",
        "value": "Ufficio delle Dogane di VERONA"
    },
    {
        "key": "IT136101",
        "value": "Sezione Operativa Territoriale AEROPORTO VALERIO CATULLO"
    },
    {
        "key": "IT136102",
        "value": "Sezione Operativa Territoriale MAGAZZINI GENERALI"
    },
    {
        "key": "IT136199",
        "value": "Area Gestione Tributi dell'UD di VERONA"
    },
    {
        "key": "IT137000",
        "value": "Direzione Ufficio delle Dogane di VENEZIA"
    },
    {
        "key": "IT137100",
        "value": "Ufficio delle Dogane di VENEZIA"
    },
    {
        "key": "IT137101",
        "value": "Sezione Operativa Territoriale AEROPORTO 'MARCO POLO'DI TESSERA"
    },
    {
        "key": "IT137102",
        "value": "Sezione Operativa Territoriale di CHIOGGIA"
    },
    {
        "key": "IT137103",
        "value": "Sezione Operativa Territoriale INTERPORTO"
    },
    {
        "key": "IT137104",
        "value": "Sezione Operativa Territoriale di PORTOGRUARO"
    },
    {
        "key": "IT137105",
        "value": "Sezione Operativa Territoriale MARITTIMA"
    },
    {
        "key": "IT137199",
        "value": "Area Gestione Tributi dell'UD di VENEZIA"
    },
    {
        "key": "IT138000",
        "value": "Direzione Ufficio delle Dogane di PADOVA"
    },
    {
        "key": "IT138100",
        "value": "Ufficio delle Dogane di PADOVA"
    },
    {
        "key": "IT138101",
        "value": "Sezione Operativa Territoriale di ROVIGO"
    },
    {
        "key": "IT138199",
        "value": "Area Gestione Tributi dell'UD di PADOVA"
    },
    {
        "key": "IT221000",
        "value": "Direzione Ufficio delle Dogane di RAVENNA"
    },
    {
        "key": "IT221100",
        "value": "Ufficio delle Dogane di RAVENNA"
    },
    {
        "key": "IT221199",
        "value": "Area Gestione Tributi dell'UD di RAVENNA"
    },
    {
        "key": "IT222000",
        "value": "Direzione Ufficio delle Dogane di BOLOGNA"
    },
    {
        "key": "IT222100",
        "value": "Ufficio delle Dogane di BOLOGNA"
    },
    {
        "key": "IT222101",
        "value": "Sezione Operativa Territoriale AEROPORTO 'G. MARCONI'"
    },
    {
        "key": "IT222102",
        "value": "Sezione Operativa Territoriale INTERPORTO"
    },
    {
        "key": "IT222199",
        "value": "Area Gestione Tributi dell'UD di BOLOGNA"
    },
    {
        "key": "IT261000",
        "value": "Direzione Ufficio delle Dogane di GENOVA 1"
    },
    {
        "key": "IT261101",
        "value": "Sezione Operativa Territoriale PASSO NUOVO"
    },
    {
        "key": "IT261199",
        "value": "Area Gestione Tributi dell'UD di GENOVA 1"
    },
    {
        "key": "IT262000",
        "value": "Direzione Ufficio delle Dogane di GENOVA 2"
    },
    {
        "key": "IT262101",
        "value": "Sezione Operativa Territoriale AEROPORTO"
    },
    {
        "key": "IT262102",
        "value": "Sezione Operativa Territoriale VOLTRI"
    },
    {
        "key": "IT262199",
        "value": "Area Gestione Tributi dell'UD di GENOVA 2"
    },
    {
        "key": "IT263000",
        "value": "Direzione Ufficio delle Dogane di RIVALTA SCRIVIA - RETROPORTO GENOVA"
    },
    {
        "key": "IT263100",
        "value": "Ufficio delle Dogane di RIVALTA SCRIVIA - RETROPORTO GENOVA"
    },
    {
        "key": "IT263101",
        "value": "Sezione Operativa Territoriale Pozzolo Formigaro"
    },
    {
        "key": "IT263199",
        "value": "Area Gestione Tributi dell'UD di RIVALTA SCRIVIA - RETROPORTO GENOVA"
    },
    {
        "key": "IT271000",
        "value": "Direzione Ufficio delle Dogane di PAVIA"
    },
    {
        "key": "IT271100",
        "value": "Ufficio delle Dogane di PAVIA"
    },
    {
        "key": "IT271101",
        "value": "Sezione Operativa Territoriale di SANNAZZARO DE' BURGONDI"
    },
    {
        "key": "IT271102",
        "value": "Sezione Operativa Territoriale di VIGEVANO"
    },
    {
        "key": "IT271103",
        "value": "Sezione Operativa Territoriale di VOGHERA"
    },
    {
        "key": "IT271199",
        "value": "Area Gestione Tributi dell'UD di PAVIA"
    },
    {
        "key": "IT272000",
        "value": "Direzione Ufficio delle Dogane di TIRANO"
    },
    {
        "key": "IT272100",
        "value": "Ufficio delle Dogane di TIRANO"
    },
    {
        "key": "IT272101",
        "value": "Sezione Operativa Territoriale di PASSO DEL FOSCAGNO"
    },
    {
        "key": "IT272103",
        "value": "Sezione Operativa Territoriale di VILLA DI CHIAVENNA"
    },
    {
        "key": "IT272199",
        "value": "Area Gestione Tributi dell'UD di TIRANO"
    },
    {
        "key": "IT273000",
        "value": "Direzione Ufficio delle Dogane di VARESE"
    },
    {
        "key": "IT273100",
        "value": "Ufficio delle Dogane di VARESE"
    },
    {
        "key": "IT273101",
        "value": "Sezione Operativa Territoriale di BUSTO ARSIZIO"
    },
    {
        "key": "IT273102",
        "value": "Sezione Operativa Territoriale di GAGGIOLO"
    },
    {
        "key": "IT273103",
        "value": "Sezione Operativa Territoriale di ISPRA"
    },
    {
        "key": "IT273104",
        "value": "Sezione Operativa Territoriale di LUINO"
    },
    {
        "key": "IT273105",
        "value": "Sezione Operativa Territoriale di PONTE TRESA"
    },
    {
        "key": "IT273199",
        "value": "Area Gestione Tributi dell'UD di VARESE"
    },
    {
        "key": "IT274000",
        "value": "Direzione Ufficio delle Dogane di BRESCIA"
    },
    {
        "key": "IT274100",
        "value": "Ufficio delle Dogane di BRESCIA"
    },
    {
        "key": "IT274101",
        "value": "Sezione Operativa Territoriale di CREMONA"
    },
    {
        "key": "IT274102",
        "value": "Sezione Operativa Territoriale di MONTICHIARI"
    },
    {
        "key": "IT274199",
        "value": "Area Gestione Tributi dell'UD di BRESCIA"
    },
    {
        "key": "IT275000",
        "value": "Direzione Ufficio delle Dogane di COMO"
    },
    {
        "key": "IT275100",
        "value": "Ufficio delle Dogane di COMO"
    },
    {
        "key": "IT275101",
        "value": "Sezione Operativa Territoriale di CHIASSO"
    },
    {
        "key": "IT275102",
        "value": "Sezione Operativa Territoriale di LECCO"
    },
    {
        "key": "IT275103",
        "value": "Sezione Operativa Territoriale di MONTANO LUCINO"
    },
    {
        "key": "IT275104",
        "value": "Sezione Operativa Territoriale di ORIA VALSOLDA"
    },
    {
        "key": "IT275105",
        "value": "Sezione Operativa Territoriale di PONTE CHIASSO"
    },
    {
        "key": "IT275199",
        "value": "Area Gestione Tributi dell'UD di COMO"
    },
    {
        "key": "IT276000",
        "value": "Direzione Ufficio delle Dogane di BERGAMO"
    },
    {
        "key": "IT276100",
        "value": "Ufficio delle Dogane di BERGAMO"
    },
    {
        "key": "IT276101",
        "value": "Sezione Operativa Territoriale di ORIO AL SERIO"
    },
    {
        "key": "IT276199",
        "value": "Area Gestione Tributi dell'UD di BERGAMO"
    },
    {
        "key": "IT277000",
        "value": "Direzione Ufficio delle Dogane di MILANO 1"
    },
    {
        "key": "IT277100",
        "value": "Ufficio delle Dogane di MILANO 1"
    },
    {
        "key": "IT277102",
        "value": "Sezione Operativa Territoriale di O.N.T."
    },
    {
        "key": "IT277199",
        "value": "Area Gestione Tributi dell'UD di MILANO 1"
    },
    {
        "key": "IT278000",
        "value": "Direzione Ufficio delle Dogane di MILANO 2"
    },
    {
        "key": "IT278100",
        "value": "Ufficio delle Dogane di MILANO 2"
    },
    {
        "key": "IT278101",
        "value": "Sezione Operativa Territoriale di CONCOREZZO"
    },
    {
        "key": "IT278105",
        "value": "Sezione Operativa Territoriale di RHO"
    },
    {
        "key": "IT278106",
        "value": "Sezione Operativa Territoriale di TURBIGO"
    },
    {
        "key": "IT278107",
        "value": "Sezione Operativa Territoriale di VITTUONE"
    },
    {
        "key": "IT278199",
        "value": "Area Gestione Tributi dell'UD di MILANO 2"
    },
    {
        "key": "IT279000",
        "value": "Direzione Ufficio delle Dogane di MALPENSA"
    },
    {
        "key": "IT279100",
        "value": "Ufficio delle Dogane di MALPENSA"
    },
    {
        "key": "IT279199",
        "value": "Area Gestione Tributi dell'UD di MALPENSA"
    },
    {
        "key": "IT281000",
        "value": "Direzione Ufficio delle Dogane di NAPOLI 1"
    },
    {
        "key": "IT281100",
        "value": "Ufficio delle Dogane di NAPOLI 1"
    },
    {
        "key": "IT281101",
        "value": "Sezione Operativa Territoriale PETROLI"
    },
    {
        "key": "IT281103",
        "value": "Sezione Operativa Territoriale AEROPORTO DI CAPODICHINO"
    },
    {
        "key": "IT281199",
        "value": "Area Gestione Tributi dell'UD di NAPOLI 1"
    },
    {
        "key": "IT282000",
        "value": "Direzione Ufficio delle Dogane di NAPOLI 2"
    },
    {
        "key": "IT282100",
        "value": "Ufficio delle Dogane di NAPOLI 2"
    },
    {
        "key": "IT282102",
        "value": "Sezione Operativa Territoriale di CAPRI"
    },
    {
        "key": "IT282103",
        "value": "Sezione Operativa Territoriale di NOLA"
    },
    {
        "key": "IT282104",
        "value": "Sezione Operativa Territoriale di POZZUOLI"
    },
    {
        "key": "IT282105",
        "value": "Sezione Operativa Territoriale di TORRE ANNUNZIATA-CASTELL. DI STABIA"
    },
    {
        "key": "IT282199",
        "value": "Area Gestione Tributi dell'UD di NAPOLI 2"
    },
    {
        "key": "IT291000",
        "value": "Direzione Ufficio delle Dogane di CATANIA"
    },
    {
        "key": "IT291100",
        "value": "Ufficio delle Dogane di CATANIA"
    },
    {
        "key": "IT291101",
        "value": "Sezione Operativa Territoriale AEROPORTO FONTANAROSSA"
    },
    {
        "key": "IT291102",
        "value": "Sezione Operativa Territoriale di RIPOSTO"
    },
    {
        "key": "IT291199",
        "value": "Area Gestione Tributi dell'UD di CATANIA"
    },
    {
        "key": "IT292000",
        "value": "Direzione Ufficio delle Dogane di PALERMO"
    },
    {
        "key": "IT292100",
        "value": "Ufficio delle Dogane di PALERMO"
    },
    {
        "key": "IT292101",
        "value": "Sezione Operativa Territoriale AEROPORTO DI PUNTA RAISI"
    },
    {
        "key": "IT292102",
        "value": "Sezione Operativa Territoriale di TERMINI IMERESE"
    },
    {
        "key": "IT292199",
        "value": "Area Gestione Tributi dell'UD di PALERMO"
    },
    {
        "key": "IT301000",
        "value": "Direzione Ufficio delle Dogane di CIVITAVECCHIA"
    },
    {
        "key": "IT301100",
        "value": "Ufficio delle Dogane di CIVITAVECCHIA"
    },
    {
        "key": "IT301101",
        "value": "Sezione Operativa Territoriale di VITERBO"
    },
    {
        "key": "IT301199",
        "value": "Area Gestione Tributi dell'UD di CIVITAVECCHIA"
    },
    {
        "key": "IT302000",
        "value": "Direzione Ufficio delle Dogane di FROSINONE"
    },
    {
        "key": "IT302100",
        "value": "Ufficio delle Dogane di FROSINONE"
    },
    {
        "key": "IT302199",
        "value": "Area Gestione Tributi dell'UD di FROSINONE"
    },
    {
        "key": "IT303000",
        "value": "Direzione Ufficio delle Dogane di CAMPOBASSO"
    },
    {
        "key": "IT303100",
        "value": "Ufficio delle Dogane di CAMPOBASSO"
    },
    {
        "key": "IT303101",
        "value": "Sezione Operativa Territoriale di TERMOLI"
    },
    {
        "key": "IT303199",
        "value": "Area Gestione Tributi dell'UD di CAMPOBASSO"
    },
    {
        "key": "IT304000",
        "value": "Direzione Ufficio delle Dogane di PESCARA"
    },
    {
        "key": "IT304100",
        "value": "Ufficio delle Dogane di PESCARA"
    },
    {
        "key": "IT304102",
        "value": "Sezione Operativa Territoriale di GIULIANOVA"
    },
    {
        "key": "IT304103",
        "value": "Sezione Operativa Territoriale di ORTONA"
    },
    {
        "key": "IT304104",
        "value": "Sezione Operativa Territoriale di VASTO"
    },
    {
        "key": "IT304199",
        "value": "Area Gestione Tributi dell'UD di PESCARA"
    },
    {
        "key": "IT305000",
        "value": "Direzione Ufficio delle Dogane di ANCONA"
    },
    {
        "key": "IT305100",
        "value": "Ufficio delle Dogane di ANCONA"
    },
    {
        "key": "IT305101",
        "value": "Sezione Operativa Territoriale di FALCONARA AEROPORTO"
    },
    {
        "key": "IT305103",
        "value": "Sezione Operativa Territoriale di FABRIANO"
    },
    {
        "key": "IT305104",
        "value": "Sezione Operativa Territoriale di FANO"
    },
    {
        "key": "IT305105",
        "value": "Sezione Operativa Territoriale di PESARO"
    },
    {
        "key": "IT305199",
        "value": "Area Gestione Tributi dell'UD di ANCONA"
    },
    {
        "key": "IT306000",
        "value": "Direzione Ufficio delle Dogane di ROMA 1"
    },
    {
        "key": "IT306100",
        "value": "Ufficio delle Dogane di ROMA 1"
    },
    {
        "key": "IT306101",
        "value": "Sezione Operativa Territoriale di CIAMPINO"
    },
    {
        "key": "IT306102",
        "value": "Sezione Operativa Territoriale di POMEZIA"
    },
    {
        "key": "IT306103",
        "value": "Sezione Operativa Territoriale di ROMA EST"
    },
    {
        "key": "IT306104",
        "value": "Sezione Operativa Territoriale di RIETI"
    },
    {
        "key": "IT306199",
        "value": "Area Gestione Tributi dell'UD di ROMA 1"
    },
    {
        "key": "IT307000",
        "value": "Direzione Ufficio delle Dogane di ROMA 2"
    },
    {
        "key": "IT307100",
        "value": "Ufficio delle Dogane di ROMA 2"
    },
    {
        "key": "IT307102",
        "value": "Sezione Operativa Territoriale di VIAGGIATORI"
    },
    {
        "key": "IT307199",
        "value": "Area Gestione Tributi dell'UD di ROMA 2"
    },
    {
        "key": "IT308000",
        "value": "Direzione Ufficio delle Dogane di CIVITANOVA MARCHE"
    },
    {
        "key": "IT308100",
        "value": "Ufficio delle Dogane di CIVITANOVA MARCHE"
    },
    {
        "key": "IT308101",
        "value": "Sezione Operativa Territoriale di SAN BENEDETTO DEL TRONTO"
    },
    {
        "key": "IT308199",
        "value": "Area Gestione Tributi dell'UD di CIVITANOVA MARCHE"
    },
    {
        "key": "IT309000",
        "value": "Direzione Ufficio delle Dogane L'AQUILA"
    },
    {
        "key": "IT309100",
        "value": "Ufficio delle Dogane L'AQUILA"
    },
    {
        "key": "IT309101",
        "value": "Sezione Operativa Territoriale di AVEZZANO"
    },
    {
        "key": "IT309199",
        "value": "Area Gestione Tributi dell'UD L'AQUILA"
    },
    {
        "key": "IT311000",
        "value": "Direzione Ufficio delle Dogane di CUNEO"
    },
    {
        "key": "IT311100",
        "value": "Ufficio delle Dogane di CUNEO"
    },
    {
        "key": "IT311101",
        "value": "Sezione Operativa Territoriale di ALBA"
    },
    {
        "key": "IT311102",
        "value": "Sezione Operativa Territoriale di MONDOVI'"
    },
    {
        "key": "IT311199",
        "value": "Area Gestione Tributi dell'UD di CUNEO"
    },
    {
        "key": "IT312000",
        "value": "Direzione Ufficio delle Dogane di NOVARA"
    },
    {
        "key": "IT312100",
        "value": "Ufficio delle Dogane di NOVARA"
    },
    {
        "key": "IT312101",
        "value": "Sezione Operativa Territoriale di ARONA"
    },
    {
        "key": "IT312102",
        "value": "Sezione Operativa Territoriale di NOVARA BOSCHETTO"
    },
    {
        "key": "IT312199",
        "value": "Area Gestione Tributi dell'UD di NOVARA"
    },
    {
        "key": "IT313000",
        "value": "Direzione Ufficio delle Dogane di ALESSANDRIA"
    },
    {
        "key": "IT313100",
        "value": "Ufficio delle Dogane di ALESSANDRIA"
    },
    {
        "key": "IT313101",
        "value": "Sezione Operativa Territoriale di ASTI"
    },
    {
        "key": "IT313199",
        "value": "Area Gestione Tributi dell'UD di ALESSANDRIA"
    },
    {
        "key": "IT314000",
        "value": "Direzione Ufficio delle Dogane di TORINO"
    },
    {
        "key": "IT314100",
        "value": "Ufficio delle Dogane di TORINO"
    },
    {
        "key": "IT314101",
        "value": "Sezione Operativa Territoriale di CASELLE TORINESE"
    },
    {
        "key": "IT314199",
        "value": "Area Gestione Tributi dell'UD di TORINO"
    },
    {
        "key": "IT321000",
        "value": "Direzione Ufficio delle Dogane di FERNETTI - RETROPORTO DI TRIESTE"
    },
    {
        "key": "IT321100",
        "value": "Ufficio delle Dogane di FERNETTI - RETROPORTO DI TRIESTE"
    },
    {
        "key": "IT321101",
        "value": "Sezione Operativa Territoriale di PROSECCO"
    },
    {
        "key": "IT321199",
        "value": "Area Gestione Tributi dell'UD di FERNETTI - RETROPORTO DI TRIESTE"
    },
    {
        "key": "IT371000",
        "value": "Direzione Ufficio delle Dogane di MILANO 3"
    },
    {
        "key": "IT371100",
        "value": "Ufficio delle Dogane di MILANO 3"
    },
    {
        "key": "IT371101",
        "value": "Sezione Operativa Territoriale di MELZO"
    },
    {
        "key": "IT371102",
        "value": "Sezione Operativa Territoriale di PIOLTELLO"
    },
    {
        "key": "IT371199",
        "value": "Area Gestione Tributi dell'UD di MILANO 3"
    },
    {
        "key": "IT444444",
        "value": "MONOPOLI DI STATO"
    },
    {
        "key": "IT922007",
        "value": "UFF. MUTUA ASS.ZA E COOP.NE AMM.VA DC ANTIFRODE E CONTROLLI"
    },
    {
        "key": "IT922008",
        "value": "UFFICIO ANALISI STATISTICA DC ANTIFRODE E CONTROLLI"
    },
    {
        "key": "IT922103",
        "value": "AEO COMPLIANCE E GRANDI IMPRESE DIREZIONE DOGANE"
    },
    {
        "key": "IT922104",
        "value": "TARIFFA E CLASSIFICAZIONE DIREZIONE DOGANE"
    },
    {
        "key": "IT922105",
        "value": "ORIGINE E VALORE DIREZIONE DOGANE"
    },
    {
        "key": "IT922106",
        "value": "REGIMI E PROCEDURE DOGANALI DIREZIONE DOGANE"
    },
    {
        "key": "IT922107",
        "value": "ACCERT. RISCOSS. E TUTELA ERARIALE DIREZIONE DOGANE"
    },
    {
        "key": "ITP00001",
        "value": "D.I. PUGLIA MOLISE E BASILICATA"
    },
    {
        "key": "ITP00002",
        "value": "D.I. EMILIA ROMAGNA E MARCHE"
    },
    {
        "key": "ITP00005",
        "value": "D.I. TOSCANA SARDEGNA E UMBRIA"
    },
    {
        "key": "ITP00006",
        "value": "D.I. PIEMONTE LIGURIA VALLE D'AOSTA"
    },
    {
        "key": "ITP00007",
        "value": "DIREZIONE REGIONALE LOMBARDIA"
    },
    {
        "key": "ITP00008",
        "value": "D.I. CAMPANIA E CALABRIA"
    },
    {
        "key": "ITP00009",
        "value": "DIREZIONE REGIONALE SICILIA"
    },
    {
        "key": "ITP00010",
        "value": "D.I. LAZIO E ABRUZZO"
    },
    {
        "key": "ITP00013",
        "value": "D.I. VENETO E FRIULI VENEZIA GIULIA"
    },
    {
        "key": "ITP00015",
        "value": "DIR. INTERPROV.LE BOLZANO TRENTO"
    },
    {
        "key": "LT00E001",
        "value": "VMI Akcizų administravimo skyrius"
    },
    {
        "key": "LT00E059",
        "value": "Vilniaus AVMI Akcizų admin. skyrius"
    },
    {
        "key": "LT00E060",
        "value": "Kauno AVMI Akcizų admin. skyrius"
    },
    {
        "key": "LT00E061",
        "value": "Klaipėdos AVMI Akcizų admin. skyr."
    },
    {
        "key": "LT00E062",
        "value": "Panevėžio AVMI Akcizų admin. sk."
    },
    {
        "key": "LT00E063",
        "value": "Šiaulių AVMI Akcizų admin. skyrius"
    },
    {
        "key": "LT00E064",
        "value": "Alytaus AVMI Akcizų admin. skyrius"
    },
    {
        "key": "LT00E065",
        "value": "Marijampolės AVMI Akcizų admin. sk."
    },
    {
        "key": "LT00E066",
        "value": "Mažeikių AVMI Akcizų admin. skyrius"
    },
    {
        "key": "LT00E067",
        "value": "Utenos AVMI Akcizų admin. skyrius"
    },
    {
        "key": "LT00E068",
        "value": "Tauragės AVMI Akcizų admin. skyrius"
    },
    {
        "key": "LTKA1000",
        "value": "Kauno oro uosto postas"
    },
    {
        "key": "LTKC0100",
        "value": "Kauno krovinių postas 'Centras'"
    },
    {
        "key": "LTKG3000",
        "value": "Kybartų geležinkelio postas"
    },
    {
        "key": "LTKK2000",
        "value": "Kybartų kelio postas"
    },
    {
        "key": "LTKK3000",
        "value": "Ramoniškių kelio postas"
    },
    {
        "key": "LTKM0000",
        "value": "Kauno teritorinė muitinė"
    },
    {
        "key": "LTKMB100",
        "value": "Mobiliųjų grupių postas"
    },
    {
        "key": "LTKR2000",
        "value": "Krovinių postas 'Tiekimas'"
    },
    {
        "key": "LTKR5000",
        "value": "Marijampolės krovinių postas"
    },
    {
        "key": "LTKR6000",
        "value": "Alytaus krovinių postas"
    },
    {
        "key": "LTLA1000",
        "value": "Palangos oro uosto postas"
    },
    {
        "key": "LTLC0100",
        "value": "Klaipėdos krovinių postas 'Centras'"
    },
    {
        "key": "LTLG3000",
        "value": "Pagėgių geležinkelio postas"
    },
    {
        "key": "LTLK1000",
        "value": "Nidos kelio postas"
    },
    {
        "key": "LTLK4000",
        "value": "Panemunės kelio postas"
    },
    {
        "key": "LTLM0000",
        "value": "Klaipėdos teritorinė muitinė"
    },
    {
        "key": "LTLMB100",
        "value": "Mobiliųjų grupių postas"
    },
    {
        "key": "LTLR1000",
        "value": "Klaipėdos krovinių postas"
    },
    {
        "key": "LTLU4000",
        "value": "Nidos upių uosto postas"
    },
    {
        "key": "LTLU9000",
        "value": "Malkų įlankos jūrų uosto postas"
    },
    {
        "key": "LTLUA000",
        "value": "Molo jūrų uosto postas"
    },
    {
        "key": "LTLUB000",
        "value": "Pilies jūrų uosto postas"
    },
    {
        "key": "LTLUC000",
        "value": "Nemuno upių uosto postas"
    },
    {
        "key": "LTLUD000",
        "value": "Rusnės upių uosto postas"
    },
    {
        "key": "LTMM0000",
        "value": "Muitinės departamentas"
    },
    {
        "key": "LTMM00G0",
        "value": "Garantijos įstaiga"
    },
    {
        "key": "LTPR2000",
        "value": "Panevėžio 1 kat. krovinių postas"
    },
    {
        "key": "LTPR4000",
        "value": "Utenos krovinių postas"
    },
    {
        "key": "LTSA1000",
        "value": "Šiaulių oro uosto postas"
    },
    {
        "key": "LTSG3000",
        "value": "Radviliškio geležinkelio postas"
    },
    {
        "key": "LTSG4000",
        "value": "Bugenių geležinkelio postas"
    },
    {
        "key": "LTSR1000",
        "value": "Šiaulių 1 kat. krovinių postas"
    },
    {
        "key": "LTVA1000",
        "value": "Vilniaus oro uosto postas"
    },
    {
        "key": "LTVC0100",
        "value": "Vilniaus krovinių postas 'Centras'"
    },
    {
        "key": "LTVG1000",
        "value": "Kenos geležinkelio postas"
    },
    {
        "key": "LTVG2000",
        "value": "Vaidotų geležinkelio postas"
    },
    {
        "key": "LTVG3000",
        "value": "Stasylų geležinkelio postas"
    },
    {
        "key": "LTVK1000",
        "value": "Lavoriškių kelio postas"
    },
    {
        "key": "LTVK2000",
        "value": "Medininkų kelio postas"
    },
    {
        "key": "LTVK3000",
        "value": "Šalčininkų kelio postas"
    },
    {
        "key": "LTVK8000",
        "value": "Raigardo kelio postas"
    },
    {
        "key": "LTVKB000",
        "value": "Šumsko kelio postas"
    },
    {
        "key": "LTVM0000",
        "value": "Vilniaus teritorinė muitinė"
    },
    {
        "key": "LTVMB100",
        "value": "Mobiliųjų grupių postas"
    },
    {
        "key": "LTVP1000",
        "value": "Vilniaus pašto postas"
    },
    {
        "key": "LTVR1000",
        "value": "Krovinių postas 'Savanoriai'"
    },
    {
        "key": "LTVR3000",
        "value": "Krovinių postas 'Kirtimai'"
    },
    {
        "key": "LTVR4000",
        "value": "Krovinių postas 'Žirmūnai'"
    },
    {
        "key": "LTVR5000",
        "value": "Krovinių postas 'Paneriai'"
    },
    {
        "key": "LTVU1000",
        "value": "Švendubrės upių uosto postas"
    },
    {
        "key": "LU700000",
        "value": "Direction des Douanes et Accises"
    },
    {
        "key": "LU701000",
        "value": "Bureau des Douanes et Accises Bettembourg"
    },
    {
        "key": "LU704000",
        "value": "Bureau des Douanes et Accises Centre Douanier"
    },
    {
        "key": "LU707000",
        "value": "Bureau des Douanes et Accises Esch-sur-Alzette"
    },
    {
        "key": "LU708000",
        "value": "Bureau des Douanes et Accises Centre douanier Nord"
    },
    {
        "key": "LU711000",
        "value": "des Douanes et Accises Caisse Centrale"
    },
    {
        "key": "LU715000",
        "value": "Bureau des Douanes et Accises Luxembourg-Aeroport"
    },
    {
        "key": "LU724000",
        "value": "Bureau des Douanes et Accises Centre Douanier Est Grevenmacher"
    },
    {
        "key": "LV000097",
        "value": "VID Akcīzes preču aprites daļa"
    },
    {
        "key": "LV000207",
        "value": "ŠĶIROTAVAS MKP"
    },
    {
        "key": "LV000210",
        "value": "RĪGAS BRĪVOSTAS MKP"
    },
    {
        "key": "LV000240",
        "value": "LIDOSTAS MKP"
    },
    {
        "key": "LV000311",
        "value": "VENTSPILS OSTAS MKP"
    },
    {
        "key": "LV000411",
        "value": "LIEPĀJAS OSTAS MKP"
    },
    {
        "key": "LV000512",
        "value": "JELGAVAS MKP"
    },
    {
        "key": "LV000626",
        "value": "VALMIERAS MKP"
    },
    {
        "key": "LV000712",
        "value": "VIENTUĻU MKP"
    },
    {
        "key": "LV000721",
        "value": "GREBŅEVAS MKP"
    },
    {
        "key": "LV000722",
        "value": "TEREHOVAS MKP"
    },
    {
        "key": "LV000723",
        "value": "ZILUPES MKP"
    },
    {
        "key": "LV000724",
        "value": "KĀRSAVAS MKP"
    },
    {
        "key": "LV000731",
        "value": "PĀTERNIEKU MKP"
    },
    {
        "key": "LV000742",
        "value": "RĒZEKNES 2 MKP"
    },
    {
        "key": "LV000810",
        "value": "DAUGAVPILS MKP"
    },
    {
        "key": "LV000814",
        "value": "SILENES MKP"
    },
    {
        "key": "LV000816",
        "value": "DAUGAVPILS PREČU STACIJAS MKP"
    },
    {
        "key": "LV000817",
        "value": "INDRAS MKP"
    },
    {
        "key": "LV001514",
        "value": "VID Nodokļu pārvaldes CIAD"
    },
    {
        "key": "LV002200",
        "value": "VID MP RĪGAS MKP DAĻA"
    },
    {
        "key": "LV002400",
        "value": "VID MP KURZEMES MKP DAĻA"
    },
    {
        "key": "LV002600",
        "value": "VID MP LATGALES MKP DAĻA"
    },
    {
        "key": "LV009999",
        "value": "VID MUITAS PĀRVALDE"
    },
    {
        "key": "MK001000",
        "value": "Carinarnica Skopje"
    },
    {
        "key": "MK001010",
        "value": "Skopje 1"
    },
    {
        "key": "MK001013",
        "value": "Skopje 3"
    },
    {
        "key": "MK001014",
        "value": "Skopje 4"
    },
    {
        "key": "MK001015",
        "value": "Skopje 2"
    },
    {
        "key": "MK001020",
        "value": "Slobodna zona Bunardzik"
    },
    {
        "key": "MK001025",
        "value": "Carinska ispostava Posta"
    },
    {
        "key": "MK001050",
        "value": "CI Aerodrom Skopje stokovo"
    },
    {
        "key": "MK001051",
        "value": "CI Aerodrom Skopje Patnicki"
    },
    {
        "key": "MK001071",
        "value": "CI Blace patnicki"
    },
    {
        "key": "MK001075",
        "value": "CI Blace stokovo"
    },
    {
        "key": "MK001080",
        "value": "CI Tetovo"
    },
    {
        "key": "MK001091",
        "value": "CI Jazince"
    },
    {
        "key": "MK002000",
        "value": "Carinarnica Kumanovo"
    },
    {
        "key": "MK002010",
        "value": "CI Kumanovo"
    },
    {
        "key": "MK002021",
        "value": "CI Zeleznicka stanicaTabanovci"
    },
    {
        "key": "MK002030",
        "value": "CI Tabanovce stokovo"
    },
    {
        "key": "MK002031",
        "value": "CI Tabanovce patnicki"
    },
    {
        "key": "MK002050",
        "value": "CI Deve Bair stokovo"
    },
    {
        "key": "MK002051",
        "value": "CI Deve Bair- Patnicki"
    },
    {
        "key": "MK003000",
        "value": "Carinarnica Stip"
    },
    {
        "key": "MK003010",
        "value": "CI Stip"
    },
    {
        "key": "MK003030",
        "value": "CI Delcevo stokovo"
    },
    {
        "key": "MK003031",
        "value": "CI Delcevo patnicki"
    },
    {
        "key": "MK003050",
        "value": "CI Veles"
    },
    {
        "key": "MK004000",
        "value": "Carinarnica Gevgelija"
    },
    {
        "key": "MK004010",
        "value": "CI Bogorodica patnicki"
    },
    {
        "key": "MK004015",
        "value": "CI Bogorodica stokovo"
    },
    {
        "key": "MK004020",
        "value": "CI Gevgelija"
    },
    {
        "key": "MK004021",
        "value": "CI Zeleznicka stanica Gevgelija"
    },
    {
        "key": "MK004031",
        "value": "CI Dojran"
    },
    {
        "key": "MK004040",
        "value": "CI Kavadarci"
    },
    {
        "key": "MK004050",
        "value": "CI Ctrumica"
    },
    {
        "key": "MK004060",
        "value": "CI Novo Selo stokovo"
    },
    {
        "key": "MK004061",
        "value": "CI Novo Selo patnicki"
    },
    {
        "key": "MK005000",
        "value": "Carinarnica Bitola"
    },
    {
        "key": "MK005010",
        "value": "CI Bitola"
    },
    {
        "key": "MK005020",
        "value": "CI Medzitlija stokovo"
    },
    {
        "key": "MK005021",
        "value": "CI Medzitlija patnicki"
    },
    {
        "key": "MK005030",
        "value": "CI Prilep"
    },
    {
        "key": "MK005040",
        "value": "CI Ohrid"
    },
    {
        "key": "MK005042",
        "value": "CI Aerodrom Ohrid"
    },
    {
        "key": "MK005051",
        "value": "CI Sveti Naum"
    },
    {
        "key": "MK005060",
        "value": "CI Struga"
    },
    {
        "key": "MK005070",
        "value": "CI Kafasan stokovo"
    },
    {
        "key": "MK005071",
        "value": "CI Kafasan patnicki"
    },
    {
        "key": "MK005081",
        "value": "CI Stenje"
    },
    {
        "key": "MK005091",
        "value": "CI Blato"
    },
    {
        "key": "MK009000",
        "value": "Centralna uprava"
    },
    {
        "key": "MT000100",
        "value": "Malta Freeport West Quay Gate"
    },
    {
        "key": "MT000102",
        "value": "Airfreight"
    },
    {
        "key": "MT000103",
        "value": "Grand Harbour Seaport"
    },
    {
        "key": "MT000104",
        "value": "MIA Arrivals"
    },
    {
        "key": "MT000107",
        "value": "Deep Water Quay"
    },
    {
        "key": "MT000108",
        "value": "Energy Products Control Unit"
    },
    {
        "key": "MT000109",
        "value": "Laboratory Wharf"
    },
    {
        "key": "MT000112",
        "value": "Msida Yacht Marina"
    },
    {
        "key": "MT000113",
        "value": "Malta Freeport Main Gate"
    },
    {
        "key": "MT000114",
        "value": "Parcel Post Office - Malta"
    },
    {
        "key": "MT000117",
        "value": "Customs Express Freight Office"
    },
    {
        "key": "MT000118",
        "value": "Economic Procedures Unit"
    },
    {
        "key": "MT000120",
        "value": "Excise Section"
    },
    {
        "key": "MT000122",
        "value": "Alcohol Products Unit"
    },
    {
        "key": "MT000123",
        "value": "MIA Departures"
    },
    {
        "key": "MT000400",
        "value": "Pre-Arrival Control Section"
    },
    {
        "key": "MT000600",
        "value": "Containers & Groupage Section"
    },
    {
        "key": "MT000702",
        "value": "Customs Warehousing Unit"
    },
    {
        "key": "MT000900",
        "value": "Customs Debt Unit"
    },
    {
        "key": "MT000910",
        "value": "Central Transit Office"
    },
    {
        "key": "MT000920",
        "value": "EOS and Databases Unit"
    },
    {
        "key": "MT000930",
        "value": "Binding Tariff Information Unit"
    },
    {
        "key": "MT000940",
        "value": "Taric & Quotas Office"
    },
    {
        "key": "NL000074",
        "value": "Douane/Landelijk kantoor"
    },
    {
        "key": "NL000100",
        "value": "Nationale Helpdesk Douane"
    },
    {
        "key": "NL000120",
        "value": "Douane Breda / Team BTI"
    },
    {
        "key": "NL000144",
        "value": "Eindhoven/Koeriers simplified proc."
    },
    {
        "key": "NL000155",
        "value": "Eindhoven Koeriers normal proc."
    },
    {
        "key": "NL000158",
        "value": "Eindhoven/Kantoor Eindhoven Airport"
    },
    {
        "key": "NL000184",
        "value": "Groningen/Kantoor Groningen (TIR)"
    },
    {
        "key": "NL000187",
        "value": "Eindhoven/JFC HQ Brunssum"
    },
    {
        "key": "NL000204",
        "value": "Eindhoven/Maastricht Aachen Airport"
    },
    {
        "key": "NL000205",
        "value": "Eindhoven/Kantoor Venlo"
    },
    {
        "key": "NL000210",
        "value": "Landelijk Oorsprong Team (LOT)"
    },
    {
        "key": "NL000282",
        "value": "Groningen/Kantoor Eemshaven"
    },
    {
        "key": "NL000396",
        "value": "Rotterdam Haven/Kantoor Maasvlakte"
    },
    {
        "key": "NL000432",
        "value": "Douane/Schiphol Cargo"
    },
    {
        "key": "NL000476",
        "value": "Groningen/Kantoor Groningen Airport"
    },
    {
        "key": "NL000510",
        "value": "Breda/Reeweg simplified proc."
    },
    {
        "key": "NL000511",
        "value": "Breda/Reeweg normal proc."
    },
    {
        "key": "NL000529",
        "value": "Douane/ GO Amsterdam Handel"
    },
    {
        "key": "NL000530",
        "value": "Douane/ GO West Olie en Gas"
    },
    {
        "key": "NL000563",
        "value": "Douane/Groningen"
    },
    {
        "key": "NL000566",
        "value": "Douane/Breda"
    },
    {
        "key": "NL000567",
        "value": "Douane/Schiphol Cargo"
    },
    {
        "key": "NL000568",
        "value": "Douane/Eindhoven"
    },
    {
        "key": "NL000702",
        "value": "Douane/Rotterdam Haven"
    },
    {
        "key": "NL000703",
        "value": "Douane/Amsterdam"
    },
    {
        "key": "NL000705",
        "value": "Schiphol passagiers"
    },
    {
        "key": "NL000707",
        "value": "Douane/Arnhem"
    },
    {
        "key": "NL000850",
        "value": "Arnhem/Kantoor Duiven"
    },
    {
        "key": "NL000851",
        "value": "Arnhem/Kantoor De Lutte"
    },
    {
        "key": "NL000853",
        "value": "Kantoor Rotterdam The Hague Airport"
    },
    {
        "key": "NL000854",
        "value": "Amsterdam/Kantoor Amsterdam"
    },
    {
        "key": "NL000855",
        "value": "Breda/Kantoor Moerdijk"
    },
    {
        "key": "NL000856",
        "value": "Breda/Kantoor Vlissingen"
    },
    {
        "key": "NL563415",
        "value": "Kantoor Groningen/Arnhem"
    },
    {
        "key": "NL566379",
        "value": "Breda/Rotterdam Haven"
    },
    {
        "key": "NL566383",
        "value": "Dutch Centre of AEO"
    },
    {
        "key": "NL567452",
        "value": "Kantoor Amsterdam/Schiphol Cargo"
    },
    {
        "key": "NL568067",
        "value": "DOUANE NEDERLAND"
    },
    {
        "key": "NL568355",
        "value": "Kantoor Eindhoven"
    },
    {
        "key": "NO01011A",
        "value": "Oslo toll - Tollbugata"
    },
    {
        "key": "NO01013C",
        "value": "Oslo toll - Alnabru"
    },
    {
        "key": "NO01016C",
        "value": "Oslo toll - Utstikker 2"
    },
    {
        "key": "NO01017C",
        "value": "Oslo toll - Hjortnes"
    },
    {
        "key": "NO01018C",
        "value": "Oslo toll - Gardermoen"
    },
    {
        "key": "NO02011A",
        "value": "Fredrikstad tollsted"
    },
    {
        "key": "NO02011B",
        "value": "Svinesund N tollsted"
    },
    {
        "key": "NO02061B",
        "value": "Ørje tollsted"
    },
    {
        "key": "NO02081D",
        "value": "Hån"
    },
    {
        "key": "NO02091D",
        "value": "Svinesund Tullverket"
    },
    {
        "key": "NO02101B",
        "value": "Kongsvinger tollsted"
    },
    {
        "key": "NO02121B",
        "value": "Østby tollsted"
    },
    {
        "key": "NO02131B",
        "value": "Åsnes tollsted"
    },
    {
        "key": "NO02141D",
        "value": "Eda tullexpedition"
    },
    {
        "key": "NO02151D",
        "value": "Idre tullexpedition"
    },
    {
        "key": "NO06011A",
        "value": "Kristiansand tollsted"
    },
    {
        "key": "NO06101B",
        "value": "Drammen tollsted"
    },
    {
        "key": "NO06111B",
        "value": "Grenland tollsted"
    },
    {
        "key": "NO06121B",
        "value": "Sandefjord tollsted"
    },
    {
        "key": "NO08011A",
        "value": "Bergen tollsted"
    },
    {
        "key": "NO08071B",
        "value": "Stavanger tollsted"
    },
    {
        "key": "NO08081B",
        "value": "Haugesund tollsted"
    },
    {
        "key": "NO08091B",
        "value": "Ålesund tollsted"
    },
    {
        "key": "NO09011A",
        "value": "Trondheim tollsted"
    },
    {
        "key": "NO09101B",
        "value": "Vauldalen tollsted"
    },
    {
        "key": "NO09161D",
        "value": "Storlien tullstation"
    },
    {
        "key": "NO09181B",
        "value": "Bodø tollsted"
    },
    {
        "key": "NO09191B",
        "value": "Junkerdal tollsted"
    },
    {
        "key": "NO09201B",
        "value": "Mo tollsted"
    },
    {
        "key": "NO09211B",
        "value": "Narvik tollsted"
    },
    {
        "key": "NO09211C",
        "value": "Bjørnfjell - Tollvesenet"
    },
    {
        "key": "NO09231D",
        "value": "Tärnaby tullstation"
    },
    {
        "key": "NO11011A",
        "value": "Tromsø tollsted"
    },
    {
        "key": "NO11031B",
        "value": "Hammerfest tollsted"
    },
    {
        "key": "NO11051B",
        "value": "Helligskogen tollsted"
    },
    {
        "key": "NO11071B",
        "value": "Kirkenes tollsted"
    },
    {
        "key": "NO11071C",
        "value": "Neiden eksp.enhet"
    },
    {
        "key": "NO11072C",
        "value": "Storskog ekspedisjonsenhet"
    },
    {
        "key": "NO11091B",
        "value": "Polmak tollsted"
    },
    {
        "key": "NO11111E",
        "value": "Karigasniemi tulli"
    },
    {
        "key": "NO11121E",
        "value": "Kivilompolo tulli"
    },
    {
        "key": "NO11141E",
        "value": "Utsjok tulli"
    },
    {
        "key": "NO950TOD",
        "value": "Tolldirektoratet"
    },
    {
        "key": "PL021045",
        "value": "US w Legnicy"
    },
    {
        "key": "PL022245",
        "value": "US w Wałbrzychu"
    },
    {
        "key": "PL022845",
        "value": "US Wrocław-Śródmieście"
    },
    {
        "key": "PL040643",
        "value": "Trzeci US w Bydgoszczy"
    },
    {
        "key": "PL041743",
        "value": "Drugi US w Toruniu"
    },
    {
        "key": "PL060230",
        "value": "US w Białej Podlaskiej"
    },
    {
        "key": "PL061030",
        "value": "Pierwszy US w Lublinie"
    },
    {
        "key": "PL062030",
        "value": "US w Zamościu"
    },
    {
        "key": "PL080241",
        "value": "US w Gorzowie Wielkopolskim"
    },
    {
        "key": "PL080841",
        "value": "Pierwszy US w Zielonej Górze"
    },
    {
        "key": "PL100936",
        "value": "Drugi US Łódź-Bałuty"
    },
    {
        "key": "PL101036",
        "value": "Pierwszy US Łódź-Górna"
    },
    {
        "key": "PL101736",
        "value": "US w Piotrkowie Trybunalskim"
    },
    {
        "key": "PL120935",
        "value": "US Kraków-Nowa Huta"
    },
    {
        "key": "PL121735",
        "value": "US w Nowym Sączu"
    },
    {
        "key": "PL140344",
        "value": "US w Ciechanowie"
    },
    {
        "key": "PL142144",
        "value": "US w Pruszkowie"
    },
    {
        "key": "PL142444",
        "value": "Pierwszy US w Radomiu"
    },
    {
        "key": "PL142644",
        "value": "US w Siedlcach"
    },
    {
        "key": "PL143444",
        "value": "US Warszawa-Praga"
    },
    {
        "key": "PL143844",
        "value": "US Warszawa-Ursynów"
    },
    {
        "key": "PL161038",
        "value": "Drugi US w Opolu"
    },
    {
        "key": "PL180740",
        "value": "US w Krośnie"
    },
    {
        "key": "PL181340",
        "value": "US w Przemyślu"
    },
    {
        "key": "PL181640",
        "value": "Pierwszy US w Rzeszowie"
    },
    {
        "key": "PL200331",
        "value": "Pierwszy US w Białymstoku"
    },
    {
        "key": "PL200831",
        "value": "US w Łomży"
    },
    {
        "key": "PL201231",
        "value": "US w Suwałkach"
    },
    {
        "key": "PL220532",
        "value": "Pierwszy US w Gdańsku"
    },
    {
        "key": "PL220932",
        "value": "Drugi US w Gdyni"
    },
    {
        "key": "PL221632",
        "value": "US w Słupsku"
    },
    {
        "key": "PL240433",
        "value": "Drugi US w Bielsku-Białej"
    },
    {
        "key": "PL240933",
        "value": "Pierwszy US w Częstochowie"
    },
    {
        "key": "PL241733",
        "value": "Drugi US w Katowicach"
    },
    {
        "key": "PL242733",
        "value": "US w Rybniku"
    },
    {
        "key": "PL260534",
        "value": "Drugi US w Kielcach"
    },
    {
        "key": "PL280537",
        "value": "US w Elblągu"
    },
    {
        "key": "PL281337",
        "value": "US w Olsztynie"
    },
    {
        "key": "PL300000",
        "value": "IAS w Lublinie"
    },
    {
        "key": "PL300839",
        "value": "Drugi US w Kaliszu"
    },
    {
        "key": "PL301000",
        "value": "Delegatura UCS w Białej Podlaskiej"
    },
    {
        "key": "PL301010",
        "value": "BIAŁA PODLASKA OC"
    },
    {
        "key": "PL301020",
        "value": "MALASZEWICZE OC"
    },
    {
        "key": "PL301040",
        "value": "KOROSZCZYN OC"
    },
    {
        "key": "PL301050",
        "value": "TERESPOL OC"
    },
    {
        "key": "PL301060",
        "value": "TERESPOL OC DROGOWY"
    },
    {
        "key": "PL301070",
        "value": "SŁAWATYCZE OC"
    },
    {
        "key": "PL301439",
        "value": "US w Lesznie"
    },
    {
        "key": "PL301939",
        "value": "US w Pile"
    },
    {
        "key": "PL302000",
        "value": "Delegatura UCS w Lublinie"
    },
    {
        "key": "PL302010",
        "value": "LUBLIN OC"
    },
    {
        "key": "PL302020",
        "value": "PUŁAWY OC"
    },
    {
        "key": "PL302040",
        "value": "CHEŁM OC"
    },
    {
        "key": "PL302050",
        "value": "DOROHUSK OC"
    },
    {
        "key": "PL302060",
        "value": "DOROHUSK OC DROGOWY"
    },
    {
        "key": "PL302070",
        "value": "OC PORT LOTNICZY LUBLIN-ŚWIDNIK"
    },
    {
        "key": "PL302080",
        "value": "Lublin OC Pocztowy"
    },
    {
        "key": "PL302239",
        "value": "US Poznań-Nowe Miasto"
    },
    {
        "key": "PL303000",
        "value": "Delegatura UCS w Zamościu"
    },
    {
        "key": "PL303010",
        "value": "ZAMOŚĆ OC"
    },
    {
        "key": "PL303020",
        "value": "HREBENNE OC"
    },
    {
        "key": "PL303030",
        "value": "HRUBIESZÓW OC"
    },
    {
        "key": "PL303040",
        "value": "ZOSIN OC"
    },
    {
        "key": "PL303080",
        "value": "TOMASZÓW LUBELSKI OC"
    },
    {
        "key": "PL303091",
        "value": "DOŁHOBYCZÓW OC"
    },
    {
        "key": "PL308000",
        "value": "Lubelski UCS w Białej Podlaskiej"
    },
    {
        "key": "PL310000",
        "value": "IAS w Białymstoku"
    },
    {
        "key": "PL311000",
        "value": "Delegatura UCS w Białymstoku"
    },
    {
        "key": "PL311010",
        "value": "BIAŁYSTOK OC"
    },
    {
        "key": "PL311020",
        "value": "KUŹNICA OC KOLEJOWY"
    },
    {
        "key": "PL311030",
        "value": "KUŹNICA OC DROGOWY"
    },
    {
        "key": "PL311050",
        "value": "SIEMIANÓWKA OC"
    },
    {
        "key": "PL311060",
        "value": "POŁOWCE OC"
    },
    {
        "key": "PL311070",
        "value": "BOBROWNIKI OC"
    },
    {
        "key": "PL312000",
        "value": "Delegatura UCS w Łomży"
    },
    {
        "key": "PL312010",
        "value": "ŁOMŻA OC"
    },
    {
        "key": "PL313000",
        "value": "Delegatura UCS w Suwałkach"
    },
    {
        "key": "PL313010",
        "value": "SUWAŁKI OC"
    },
    {
        "key": "PL313020",
        "value": "AUGUSTÓW OC"
    },
    {
        "key": "PL318000",
        "value": "Podlaski UCS w Białymstoku"
    },
    {
        "key": "PL320000",
        "value": "IAS w Gdańsku"
    },
    {
        "key": "PL321000",
        "value": "Delegatura UCS w Gdyni"
    },
    {
        "key": "PL321010",
        "value": "GDYNIA OC 'BASEN V'"
    },
    {
        "key": "PL321030",
        "value": "GDYNIA OC 'BAZA KONTENEROWA'"
    },
    {
        "key": "PL321050",
        "value": "GDYNIA OC 'BASEN IV'"
    },
    {
        "key": "PL321070",
        "value": "GDYNIA OC 'NABRZEŻE BUŁGARSKIE'"
    },
    {
        "key": "PL321142",
        "value": "Drugi US w Koszalinie"
    },
    {
        "key": "PL321542",
        "value": "Pierwszy US w Szczecinie"
    },
    {
        "key": "PL322000",
        "value": "Delegatura UCS w Gdańsku"
    },
    {
        "key": "PL322010",
        "value": "GDAŃSK OC 'OPŁOTKI'"
    },
    {
        "key": "PL322020",
        "value": "GDAŃSK OC 'NABRZEŻE WIŚLANE'"
    },
    {
        "key": "PL322030",
        "value": "GDAŃSK OC 'BASEN WŁADYSŁAWA IV'"
    },
    {
        "key": "PL322050",
        "value": "GDAŃSK-RĘBIECHOWO OC PORT LOTNICZY"
    },
    {
        "key": "PL322060",
        "value": "TCZEW OC"
    },
    {
        "key": "PL322070",
        "value": "KWIDZYN OC"
    },
    {
        "key": "PL322080",
        "value": "GDAŃSK OC 'TERMINAL KONTENEROWY'"
    },
    {
        "key": "PL322090",
        "value": "PRUSZCZ GDAŃSKI OC POCZTOWY"
    },
    {
        "key": "PL323000",
        "value": "Delegatura UCS w Słupsku"
    },
    {
        "key": "PL323010",
        "value": "SŁUPSK OC"
    },
    {
        "key": "PL323020",
        "value": "CHOJNICE OC"
    },
    {
        "key": "PL328000",
        "value": "Pomorski UCS w Gdyni"
    },
    {
        "key": "PL330000",
        "value": "IAS w Katowicach"
    },
    {
        "key": "PL331000",
        "value": "Delegatura UCS w Katowicach"
    },
    {
        "key": "PL331010",
        "value": "CHORZÓW OC"
    },
    {
        "key": "PL331020",
        "value": "TYCHY OC"
    },
    {
        "key": "PL331030",
        "value": "SŁAWKÓW OC"
    },
    {
        "key": "PL331040",
        "value": "OC TOWAROWY PORT LOTNICZY PYRZOWICE"
    },
    {
        "key": "PL331050",
        "value": "OC OSOBOWY PORT LOTNICZY PYRZOWICE"
    },
    {
        "key": "PL332000",
        "value": "Delegatura UCS w Rybniku"
    },
    {
        "key": "PL332010",
        "value": "GLIWICE OC"
    },
    {
        "key": "PL332030",
        "value": "RACIBÓRZ OC"
    },
    {
        "key": "PL332040",
        "value": "ZABRZE OC POCZTOWY"
    },
    {
        "key": "PL333000",
        "value": "Delegatura UCS w Częstochowie"
    },
    {
        "key": "PL333010",
        "value": "CZĘSTOCHOWA OC"
    },
    {
        "key": "PL335000",
        "value": "Delegatura UCS w Bielsku-Białej"
    },
    {
        "key": "PL335010",
        "value": "CZECHOWICE-DZIEDZICE OC"
    },
    {
        "key": "PL335020",
        "value": "ŻYWIEC OC"
    },
    {
        "key": "PL338000",
        "value": "Śląski UCS w Katowicach"
    },
    {
        "key": "PL340000",
        "value": "IAS w Kielcach"
    },
    {
        "key": "PL341000",
        "value": "Delegatura UCS w Kielcach"
    },
    {
        "key": "PL341010",
        "value": "KIELCE OC"
    },
    {
        "key": "PL341020",
        "value": "STARACHOWICE OC"
    },
    {
        "key": "PL341030",
        "value": "SANDOMIERZ OC"
    },
    {
        "key": "PL348000",
        "value": "Świętokrzyski UCS w Kielcach"
    },
    {
        "key": "PL350000",
        "value": "IAS w Krakowie"
    },
    {
        "key": "PL351000",
        "value": "Delegatura UCS w Krakowie"
    },
    {
        "key": "PL351020",
        "value": "KRAKÓW OC II"
    },
    {
        "key": "PL351030",
        "value": "KRAKÓW-BALICE OC PORT LOTNICZY"
    },
    {
        "key": "PL351050",
        "value": "CHYŻNE OC"
    },
    {
        "key": "PL351060",
        "value": "ANDRYCHÓW OC"
    },
    {
        "key": "PL353000",
        "value": "Delegatura UCS w Nowym Sączu"
    },
    {
        "key": "PL353010",
        "value": "NOWY SĄCZ OC"
    },
    {
        "key": "PL353030",
        "value": "TARNÓW OC"
    },
    {
        "key": "PL358000",
        "value": "Małopolski UCS w Krakowie"
    },
    {
        "key": "PL360000",
        "value": "IAS w Łodzi"
    },
    {
        "key": "PL360001",
        "value": "CENTRALNE BIURO TRANZYTU"
    },
    {
        "key": "PL361000",
        "value": "Delegatura UCS I w Łodzi"
    },
    {
        "key": "PL361010",
        "value": "ŁÓDŹ OC I"
    },
    {
        "key": "PL361030",
        "value": "SIERADZ OC"
    },
    {
        "key": "PL362000",
        "value": "Delegatura UCS II w Łodzi"
    },
    {
        "key": "PL362010",
        "value": "ŁÓDŹ OC II"
    },
    {
        "key": "PL362030",
        "value": "KUTNO OC"
    },
    {
        "key": "PL362040",
        "value": "LUBLINEK - OC PORT LOTNICZY"
    },
    {
        "key": "PL363000",
        "value": "Delegatura UCS w Piotrkowie Trybun."
    },
    {
        "key": "PL363010",
        "value": "PIOTRKÓW TRYBUNALSKI OC"
    },
    {
        "key": "PL368000",
        "value": "Łódzki UCS w Łodzi"
    },
    {
        "key": "PL370000",
        "value": "IAS w Olsztynie"
    },
    {
        "key": "PL371000",
        "value": "Delegatura UCS w Olsztynie"
    },
    {
        "key": "PL371010",
        "value": "OLSZTYN OC"
    },
    {
        "key": "PL371020",
        "value": "KORSZE OC"
    },
    {
        "key": "PL371030",
        "value": "BEZLEDY OC"
    },
    {
        "key": "PL371040",
        "value": "BARTOSZYCE OC"
    },
    {
        "key": "PL371050",
        "value": "EŁK OC"
    },
    {
        "key": "PL371060",
        "value": "GOŁDAP OC"
    },
    {
        "key": "PL372000",
        "value": "Delegatura UCS w Elblągu"
    },
    {
        "key": "PL372010",
        "value": "ELBLĄG OC"
    },
    {
        "key": "PL372020",
        "value": "BRANIEWO OC"
    },
    {
        "key": "PL372030",
        "value": "GRONOWO OC"
    },
    {
        "key": "PL372040",
        "value": "IŁAWA OC"
    },
    {
        "key": "PL372050",
        "value": "GRZECHOTKI OC"
    },
    {
        "key": "PL378000",
        "value": "Warmińsko-Mazurski UCS w Olsztynie"
    },
    {
        "key": "PL380000",
        "value": "IAS w Opolu"
    },
    {
        "key": "PL381000",
        "value": "Delegatura UCS w Opolu"
    },
    {
        "key": "PL381010",
        "value": "OPOLE OC"
    },
    {
        "key": "PL381030",
        "value": "KĘDZIERZYN-KOŹLE OC"
    },
    {
        "key": "PL381040",
        "value": "NYSA OC"
    },
    {
        "key": "PL388000",
        "value": "Opolski UCS w Opolu"
    },
    {
        "key": "PL390000",
        "value": "IAS w Poznaniu"
    },
    {
        "key": "PL391000",
        "value": "Delegatura UCS w Poznaniu"
    },
    {
        "key": "PL391010",
        "value": "POZNAŃ OC"
    },
    {
        "key": "PL391020",
        "value": "POZNAŃ OC MTP"
    },
    {
        "key": "PL391030",
        "value": "POZNAŃ-ŁAWICA OC PORT LOTNICZY"
    },
    {
        "key": "PL391040",
        "value": "GĄDKI OC"
    },
    {
        "key": "PL392000",
        "value": "Delegatura UCS w Pile"
    },
    {
        "key": "PL392010",
        "value": "PIŁA OC"
    },
    {
        "key": "PL393000",
        "value": "Delegatura UCS w Lesznie"
    },
    {
        "key": "PL393010",
        "value": "LESZNO OC"
    },
    {
        "key": "PL393020",
        "value": "NOWY TOMYŚL OC"
    },
    {
        "key": "PL394000",
        "value": "Delegatura UCS w Kaliszu"
    },
    {
        "key": "PL394010",
        "value": "KALISZ OC"
    },
    {
        "key": "PL394020",
        "value": "KONIN OC"
    },
    {
        "key": "PL398000",
        "value": "Wielkopolski UCS w Poznaniu"
    },
    {
        "key": "PL400000",
        "value": "IAS w Rzeszowie"
    },
    {
        "key": "PL401000",
        "value": "Delegatura UCS w Przemyślu"
    },
    {
        "key": "PL401010",
        "value": "PRZEMYŚL OC"
    },
    {
        "key": "PL401030",
        "value": "MEDYKA OC"
    },
    {
        "key": "PL401040",
        "value": "PRZEMYŚL-MEDYKA OC KOLEJOWY"
    },
    {
        "key": "PL401050",
        "value": "JAROSŁAW OC"
    },
    {
        "key": "PL401060",
        "value": "KORCZOWA OC"
    },
    {
        "key": "PL401080",
        "value": "BUDOMIERZ OC"
    },
    {
        "key": "PL402000",
        "value": "Delegatura UCS w Rzeszowie"
    },
    {
        "key": "PL402010",
        "value": "RZESZÓW OC"
    },
    {
        "key": "PL402020",
        "value": "RZESZÓW-JASIONKA OC PORT LOTNICZY"
    },
    {
        "key": "PL402040",
        "value": "DĘBICA OC"
    },
    {
        "key": "PL402050",
        "value": "STALOWA WOLA OC"
    },
    {
        "key": "PL402060",
        "value": "MIELEC OC"
    },
    {
        "key": "PL404000",
        "value": "Delegatura UCS w Krośnie"
    },
    {
        "key": "PL404010",
        "value": "KROSNO OC"
    },
    {
        "key": "PL404030",
        "value": "KROŚCIENKO OC"
    },
    {
        "key": "PL408000",
        "value": "Podkarpacki UCS w Przemyślu"
    },
    {
        "key": "PL410000",
        "value": "IAS w Zielonej Górze"
    },
    {
        "key": "PL411000",
        "value": "Delegatura UCS w Zielonej Górze"
    },
    {
        "key": "PL411010",
        "value": "ZIELONA GÓRA OC"
    },
    {
        "key": "PL411020",
        "value": "OLSZYNA OC"
    },
    {
        "key": "PL412000",
        "value": "Delegatura UCS w Rzepinie"
    },
    {
        "key": "PL412010",
        "value": "GORZÓW WIELKOPOLSKI OC"
    },
    {
        "key": "PL412020",
        "value": "ŚWIECKO OC"
    },
    {
        "key": "PL418000",
        "value": "Lubuski UCS w Gorzowie Wielkop."
    },
    {
        "key": "PL420000",
        "value": "IAS w Szczecinie"
    },
    {
        "key": "PL421000",
        "value": "Delegatura UCS w Szczecinie"
    },
    {
        "key": "PL421010",
        "value": "SZCZECIN OC"
    },
    {
        "key": "PL421030",
        "value": "SZCZECIN OC 'NABRZEŻE ŁASZTOWNIA'"
    },
    {
        "key": "PL421050",
        "value": "SZCZECIN-GOLENIÓW OC PORT LOTNICZY"
    },
    {
        "key": "PL421060",
        "value": "STARGARD OC"
    },
    {
        "key": "PL421080",
        "value": "SWINOUJŚCIE OC"
    },
    {
        "key": "PL422000",
        "value": "Delegatura UCS w Koszalinie"
    },
    {
        "key": "PL422010",
        "value": "KOSZALIN OC"
    },
    {
        "key": "PL422020",
        "value": "KOŁOBRZEG OC"
    },
    {
        "key": "PL422030",
        "value": "SZCZECINEK OC"
    },
    {
        "key": "PL428000",
        "value": "Zachodniopomorski UCS w Szczecinie"
    },
    {
        "key": "PL430000",
        "value": "IAS w Bydgoszczy"
    },
    {
        "key": "PL431000",
        "value": "Delegatura UCS w Bydgoszczy"
    },
    {
        "key": "PL431020",
        "value": "BYDGOSZCZ OC II"
    },
    {
        "key": "PL431030",
        "value": "INOWROCŁAW OC"
    },
    {
        "key": "PL432000",
        "value": "Delegatura UCS w Toruniu"
    },
    {
        "key": "PL432010",
        "value": "TORUŃ OC"
    },
    {
        "key": "PL432030",
        "value": "WŁOCŁAWEK OC"
    },
    {
        "key": "PL432040",
        "value": "GRUDZIĄDZ OC"
    },
    {
        "key": "PL438000",
        "value": "Kujawsko-Pomorski UCS w Toruniu"
    },
    {
        "key": "PL440000",
        "value": "IAS w Warszawie"
    },
    {
        "key": "PL441000",
        "value": "Delegatura UCS I w Warszawie"
    },
    {
        "key": "PL441010",
        "value": "WARSZAWA OC I POCZTOWY"
    },
    {
        "key": "PL441040",
        "value": "WARSZAWA OC IV"
    },
    {
        "key": "PL442000",
        "value": "Delegatura UCS II w Warszawie"
    },
    {
        "key": "PL442010",
        "value": "WARSZAWA OC V"
    },
    {
        "key": "PL442020",
        "value": "WARSZAWA OC VI"
    },
    {
        "key": "PL443000",
        "value": "deleted_office"
    },
    {
        "key": "PL443010",
        "value": "WARSZAWA OC OSOBOWY"
    },
    {
        "key": "PL443020",
        "value": "WARSZAWA OC TOWAROWY I"
    },
    {
        "key": "PL443050",
        "value": "WARSZAWA OC PORT LOTNICZY MODLIN"
    },
    {
        "key": "PL444000",
        "value": "Delegatura UCS w Radomiu"
    },
    {
        "key": "PL444010",
        "value": "RADOM OC"
    },
    {
        "key": "PL444020",
        "value": "GRÓJEC OC"
    },
    {
        "key": "PL445000",
        "value": "Delegatura UCS w Pruszkowie"
    },
    {
        "key": "PL445010",
        "value": "PRUSZKÓW OC I"
    },
    {
        "key": "PL445050",
        "value": "PŁOCK OC"
    },
    {
        "key": "PL446000",
        "value": "Delegatura UCS w Siedlcach"
    },
    {
        "key": "PL446010",
        "value": "SIEDLCE OC"
    },
    {
        "key": "PL446020",
        "value": "GARWOLIN OC"
    },
    {
        "key": "PL447000",
        "value": "Delegatura UCS w Ciechanowie"
    },
    {
        "key": "PL447010",
        "value": "CIECHANÓW OC"
    },
    {
        "key": "PL447020",
        "value": "OSTROŁĘKA OC"
    },
    {
        "key": "PL448000",
        "value": "Mazowiecki UCS w Warszawie"
    },
    {
        "key": "PL450000",
        "value": "IAS we Wrocławiu"
    },
    {
        "key": "PL451000",
        "value": "Delegatura UCS we Wrocławiu"
    },
    {
        "key": "PL451010",
        "value": "WROCŁAW OC I"
    },
    {
        "key": "PL451020",
        "value": "WROCŁAW OC II"
    },
    {
        "key": "PL451030",
        "value": "WROCŁAW-STRACHOWICE OC TOWAROWY"
    },
    {
        "key": "PL451040",
        "value": "WROCŁAW-STRACHOWICE OC OSOBOWY"
    },
    {
        "key": "PL452000",
        "value": "Delegatura UCS w Legnicy"
    },
    {
        "key": "PL452010",
        "value": "LEGNICA OC"
    },
    {
        "key": "PL452020",
        "value": "POLKOWICE OC"
    },
    {
        "key": "PL452030",
        "value": "ŻARSKA WIEŚ OC"
    },
    {
        "key": "PL454000",
        "value": "Delegatura UCS w Wałbrzychu"
    },
    {
        "key": "PL454010",
        "value": "WAŁBRZYCH OC"
    },
    {
        "key": "PL458000",
        "value": "Dolnośląski UCS we Wrocławiu"
    },
    {
        "key": "PT000005",
        "value": "FARO Delegação Aduaneira Aeroporto Faro"
    },
    {
        "key": "PT000006",
        "value": "Beja Posto Aduaneiro Aeroporto de Beja"
    },
    {
        "key": "PT000010",
        "value": "FUNCHAL Delegação Adu. Aeroporto da Madeira"
    },
    {
        "key": "PT000015",
        "value": "LISBOA Alfândega do Aeroporto de Lisboa"
    },
    {
        "key": "PT000020",
        "value": "PORTO Alfândega do Aeroporto do Porto"
    },
    {
        "key": "PT000025",
        "value": "MADEIRA Delegação Aduaneira de Porto Santo"
    },
    {
        "key": "PT000029",
        "value": "Ponta Delgada Posto Aduaneiro Aeroporto P.Delgada"
    },
    {
        "key": "PT000030",
        "value": "AÇORES Delegação Ad. Aeroporto Santa Maria"
    },
    {
        "key": "PT000040",
        "value": "LISBOA Alfândega Marítima de Lisboa"
    },
    {
        "key": "PT000070",
        "value": "MADEIRA Alfândega do Funchal"
    },
    {
        "key": "PT000080",
        "value": "AÇORES Alfândega de Ponta Delgada"
    },
    {
        "key": "PT000088",
        "value": "LISBOA Alfândega de Alverca"
    },
    {
        "key": "PT000095",
        "value": "AÇORES Delegação Adua. Angra do Heroísmo"
    },
    {
        "key": "PT000098",
        "value": "LISBOA Posto Aduaneiro da Bobadela"
    },
    {
        "key": "PT000115",
        "value": "AVEIRO Alfândega de Aveiro"
    },
    {
        "key": "PT000164",
        "value": "BRAGA Alfândega de Braga"
    },
    {
        "key": "PT000167",
        "value": "Delegação Aduaneira de Bragança"
    },
    {
        "key": "PT000170",
        "value": "ELVAS Delegação Aduaneira de Elvas"
    },
    {
        "key": "PT000227",
        "value": "COVILHÃ Delegação Aduaneira da Covilhã"
    },
    {
        "key": "PT000236",
        "value": "Delegação Aduan. Encomendas Postais"
    },
    {
        "key": "PT000265",
        "value": "FARO Alfândega de Faro"
    },
    {
        "key": "PT000275",
        "value": "Delegação Aduaneira Figueira da Foz"
    },
    {
        "key": "PT000284",
        "value": "PORTO Alfândega do Freixieiro"
    },
    {
        "key": "PT000295",
        "value": "AÇORES Delegação Aduaneira da Horta"
    },
    {
        "key": "PT000305",
        "value": "LISBOA Alfândega do Jardim do Tabaco"
    },
    {
        "key": "PT000340",
        "value": "PORTO Alfândega de Leixões"
    },
    {
        "key": "PT000455",
        "value": "PENICHE Alfândega de Peniche"
    },
    {
        "key": "PT000459",
        "value": "Delegação Aduaneira Peso da Régua"
    },
    {
        "key": "PT000461",
        "value": "PENICHE Posto Aduaneiro de Riachos"
    },
    {
        "key": "PT000500",
        "value": "Delegação Aduaneira de Portimão"
    },
    {
        "key": "PT000510",
        "value": "AÇORES Posto Aduaneiro Praia da Graciosa"
    },
    {
        "key": "PT000560",
        "value": "LISBOA Xabregas"
    },
    {
        "key": "PT000574",
        "value": "AÇORES Posto Aduaneiro Lajes das Flores"
    },
    {
        "key": "PT000650",
        "value": "AÇORES Posto Aduaneiro S. Roque do Pico"
    },
    {
        "key": "PT000665",
        "value": "Alfândega de Setúbal"
    },
    {
        "key": "PT000670",
        "value": "Delegação Aduaneira de Sines"
    },
    {
        "key": "PT000745",
        "value": "AÇORES Posto Aduaneiro Velas de S. Jorge"
    },
    {
        "key": "PT000750",
        "value": "Alfândega de Viana do Castelo"
    },
    {
        "key": "PT000830",
        "value": "Delegação Aduaneira Vilar Formoso"
    },
    {
        "key": "PT000855",
        "value": "MADEIRA Delegação Ad. Zona Franca Madeira"
    },
    {
        "key": "PT000900",
        "value": "Autoridade Tributária e Aduaneira"
    },
    {
        "key": "PT000908",
        "value": "DS. de Tributação Aduaneira (AT)"
    },
    {
        "key": "PT000909",
        "value": "DS de Regulação Aduaneira (AT)"
    },
    {
        "key": "PT000910",
        "value": "DS Antifraude Aduaneira"
    },
    {
        "key": "PT000930",
        "value": "DS Antifraude Adua- Div Informações"
    },
    {
        "key": "RO010001",
        "value": "Excise Liaison Office"
    },
    {
        "key": "ROANV001",
        "value": "DIRECTIA GENERALA A VAMILOR"
    },
    {
        "key": "ROANV002",
        "value": "CENTRAL TRANSIT OFFICE"
    },
    {
        "key": "ROANV003",
        "value": "BIROUL GARANTII TRANZIT"
    },
    {
        "key": "ROANV004",
        "value": "AUTORITATEA COMPETENTA AEO"
    },
    {
        "key": "ROANV005",
        "value": "Biroul Garantii"
    },
    {
        "key": "ROBU1030",
        "value": "OTOPENI CALATORI"
    },
    {
        "key": "ROBU1040",
        "value": "BANEASA"
    },
    {
        "key": "ROBU1200",
        "value": "ANTREPOZITE/DJAOV ILFOV"
    },
    {
        "key": "ROBU1380",
        "value": "BUCURESTI POSTA"
    },
    {
        "key": "ROBU1400",
        "value": "TARGURI SI EXPOZITII"
    },
    {
        "key": "ROBU3910",
        "value": "GIURGIU"
    },
    {
        "key": "ROBU3980",
        "value": "ZONA LIBERA GIURGIU"
    },
    {
        "key": "ROBU7100",
        "value": "PLOIESTI"
    },
    {
        "key": "ROBU8600",
        "value": "TARGOVISTE"
    },
    {
        "key": "ROBV0300",
        "value": "ALBA IULIA"
    },
    {
        "key": "ROBV0900",
        "value": "BRASOV"
    },
    {
        "key": "ROBV5600",
        "value": "MIERCUREA CIUC"
    },
    {
        "key": "ROBV7820",
        "value": "SFANTU GHEORGHE"
    },
    {
        "key": "ROBV7900",
        "value": "SIBIU"
    },
    {
        "key": "ROBV7910",
        "value": "SIBIU AEROPORT"
    },
    {
        "key": "ROBV8800",
        "value": "TARGU MURES"
    },
    {
        "key": "ROBV8820",
        "value": "TARGU-MURES AEROPORT"
    },
    {
        "key": "ROCJ0400",
        "value": "BISTRITA"
    },
    {
        "key": "ROCJ0500",
        "value": "BAIA MARE"
    },
    {
        "key": "ROCJ0510",
        "value": "AEROPORT BAIA MARE"
    },
    {
        "key": "ROCJ1800",
        "value": "CLUJ NAPOCA"
    },
    {
        "key": "ROCJ1810",
        "value": "CLUJ NAPOCA AEROPORT"
    },
    {
        "key": "ROCJ4310",
        "value": "HALMEU"
    },
    {
        "key": "ROCJ6570",
        "value": "ORADEA"
    },
    {
        "key": "ROCJ6580",
        "value": "ORADEA AEROPORT"
    },
    {
        "key": "ROCJ7810",
        "value": "SATU MARE"
    },
    {
        "key": "ROCJ7830",
        "value": "AEROPORT SATU MARE"
    },
    {
        "key": "ROCJ8000",
        "value": "SIGHET"
    },
    {
        "key": "ROCJ9700",
        "value": "ZALAU"
    },
    {
        "key": "ROCR0310",
        "value": "ALEXANDRIA"
    },
    {
        "key": "ROCR1700",
        "value": "CALAFAT"
    },
    {
        "key": "ROCR1720",
        "value": "BECHET"
    },
    {
        "key": "ROCR2000",
        "value": "CORABIA"
    },
    {
        "key": "ROCR2100",
        "value": "CRAIOVA"
    },
    {
        "key": "ROCR2110",
        "value": "CRAIOVA AEROPORT"
    },
    {
        "key": "ROCR5800",
        "value": "ZIMNICEA"
    },
    {
        "key": "ROCR7000",
        "value": "PITESTI"
    },
    {
        "key": "ROCR7200",
        "value": "PORTILE DE FIER II"
    },
    {
        "key": "ROCR7270",
        "value": "PORTILE DE FIER I"
    },
    {
        "key": "ROCR7280",
        "value": "ORSOVA"
    },
    {
        "key": "ROCR7700",
        "value": "RAMNICU VALCEA"
    },
    {
        "key": "ROCR8210",
        "value": "SLATINA"
    },
    {
        "key": "ROCR8810",
        "value": "TARGU JIU"
    },
    {
        "key": "ROCR9000",
        "value": "DROBETA TURNU SEVERIN"
    },
    {
        "key": "ROCR9100",
        "value": "TURNU MAGURELE"
    },
    {
        "key": "ROCT1710",
        "value": "CALARASI"
    },
    {
        "key": "ROCT1900",
        "value": "CONSTANTA SUD AGIGEA"
    },
    {
        "key": "ROCT1970",
        "value": "CONSTANTA PORT"
    },
    {
        "key": "ROCT5100",
        "value": "MIHAIL KOGALNICEANU AEROPORT"
    },
    {
        "key": "ROCT5400",
        "value": "MANGALIA"
    },
    {
        "key": "ROCT8220",
        "value": "SLOBOZIA"
    },
    {
        "key": "ROCT8300",
        "value": "SULINA"
    },
    {
        "key": "RODRVBU0",
        "value": "D.R.A.O.V. BUCURESTI"
    },
    {
        "key": "RODRVBV0",
        "value": "D.R.A.O.V. BRASOV"
    },
    {
        "key": "RODRVCJ0",
        "value": "D.R.A.O.V. CLUJ"
    },
    {
        "key": "RODRVCR0",
        "value": "D.R.A.O.V. CRAIOVA"
    },
    {
        "key": "RODRVGL0",
        "value": "D.R.A.O.V. GALATI"
    },
    {
        "key": "RODRVIS0",
        "value": "D.R.A.O.V. IASI"
    },
    {
        "key": "RODRVPL0",
        "value": "DIRECTIA REGIONALA VAMALA PLOIESTI"
    },
    {
        "key": "RODRVTM0",
        "value": "D.R.A.O.V. TIMISOARA"
    },
    {
        "key": "ROGL0700",
        "value": "BRAILA"
    },
    {
        "key": "ROGL0710",
        "value": "ZONA LIBERA BRAILA"
    },
    {
        "key": "ROGL1500",
        "value": "BUZAU"
    },
    {
        "key": "ROGL3600",
        "value": "FOCSANI"
    },
    {
        "key": "ROGL3610",
        "value": "OANCEA"
    },
    {
        "key": "ROGL3800",
        "value": "GALATI"
    },
    {
        "key": "ROGL3810",
        "value": "GALATI ZONA LIBERA"
    },
    {
        "key": "ROGL3850",
        "value": "GIURGIULESTI"
    },
    {
        "key": "ROGL8900",
        "value": "TULCEA"
    },
    {
        "key": "ROGL8910",
        "value": "Aeroport Delta Dunarii Tulcea"
    },
    {
        "key": "ROIS0100",
        "value": "ALBITA"
    },
    {
        "key": "ROIS0600",
        "value": "BACAU"
    },
    {
        "key": "ROIS0620",
        "value": "BACAU AEROPORT"
    },
    {
        "key": "ROIS1600",
        "value": "BOTOSANI"
    },
    {
        "key": "ROIS1610",
        "value": "STANCA COSTESTI"
    },
    {
        "key": "ROIS1620",
        "value": "RADAUTI PRUT"
    },
    {
        "key": "ROIS2700",
        "value": "DORNESTI"
    },
    {
        "key": "ROIS4650",
        "value": "IASI"
    },
    {
        "key": "ROIS4660",
        "value": "IASI AEROPORT"
    },
    {
        "key": "ROIS4990",
        "value": "SCULENI"
    },
    {
        "key": "ROIS7400",
        "value": "PIATRA NEAMT"
    },
    {
        "key": "ROIS8200",
        "value": "SIRET"
    },
    {
        "key": "ROIS8230",
        "value": "SUCEAVA"
    },
    {
        "key": "ROIS8250",
        "value": "SUCEAVA AEROPORT"
    },
    {
        "key": "ROIS9610",
        "value": "VASLUI"
    },
    {
        "key": "ROIS9620",
        "value": "VICOVU DE SUS"
    },
    {
        "key": "ROTM0200",
        "value": "ARAD"
    },
    {
        "key": "ROTM0230",
        "value": "ARAD AEROPORT"
    },
    {
        "key": "ROTM2300",
        "value": "ZONA LIBERA CURTICI"
    },
    {
        "key": "ROTM5010",
        "value": "JIMBOLIA"
    },
    {
        "key": "ROTM5510",
        "value": "MORAVITA"
    },
    {
        "key": "ROTM6100",
        "value": "NAIDAS"
    },
    {
        "key": "ROTM7600",
        "value": "RESITA"
    },
    {
        "key": "ROTM8100",
        "value": "DEVA"
    },
    {
        "key": "ROTM8720",
        "value": "TIMISOARA BAZA"
    },
    {
        "key": "ROTM8730",
        "value": "TIMISOARA AEROPORT"
    },
    {
        "key": "RS011002",
        "value": "CARINARNICA BEOGRAD"
    },
    {
        "key": "RS011029",
        "value": "CI LUKA BEOGRAD"
    },
    {
        "key": "RS011037",
        "value": "CI PANČEVO"
    },
    {
        "key": "RS011045",
        "value": "CI POŠTA BEOGRAD"
    },
    {
        "key": "RS011096",
        "value": "CI RANŽIRNA ŽEL.STANICA MAKIŠ-BEOG"
    },
    {
        "key": "RS011118",
        "value": "CI OSTRUŽNICA"
    },
    {
        "key": "RS011126",
        "value": "CI BEOGRADSKI SAJAM"
    },
    {
        "key": "RS011134",
        "value": "CI SKLADIŠTA"
    },
    {
        "key": "RS011207",
        "value": "CI ZA POSLOVE CAR. NADZORA-BEOGRAD"
    },
    {
        "key": "RS011231",
        "value": "CR LUKA I REČNO PRISTANIŠTE PANČEVO"
    },
    {
        "key": "RS011240",
        "value": "CR SAVSKO PRISTANIŠTE"
    },
    {
        "key": "RS011274",
        "value": "CI AERODROM NIKOLA TESLA"
    },
    {
        "key": "RS011282",
        "value": "CI ZA KURIRSKE POŠILJKE-DHL"
    },
    {
        "key": "RS011347",
        "value": "CI BEOGRAD"
    },
    {
        "key": "RS011410",
        "value": "CI AERODROM BEOGRAD"
    },
    {
        "key": "RS011568",
        "value": "CI TERMINAL BEOGRAD"
    },
    {
        "key": "RS012009",
        "value": "CARINARNICA KLADOVO"
    },
    {
        "key": "RS012033",
        "value": "CI MOKRANJE"
    },
    {
        "key": "RS012041",
        "value": "CI PRAHOVO"
    },
    {
        "key": "RS012068",
        "value": "CI VELIKO GRADIŠTE"
    },
    {
        "key": "RS012084",
        "value": "CR ĐERDAP 2"
    },
    {
        "key": "RS012092",
        "value": "CR MAJDANPEK"
    },
    {
        "key": "RS012289",
        "value": "CI ĐERDAP"
    },
    {
        "key": "RS012297",
        "value": "CR BOR"
    },
    {
        "key": "RS012319",
        "value": "CI VRŠKA ČUKA"
    },
    {
        "key": "RS012327",
        "value": "CR ZAJEČAR"
    },
    {
        "key": "RS013005",
        "value": "CARINARNICA DIMITROVGRAD"
    },
    {
        "key": "RS013013",
        "value": "CI TERMINAL GRADINA"
    },
    {
        "key": "RS013021",
        "value": "CI ŽELEZNIČKA STANICA DIMITROVGRAD"
    },
    {
        "key": "RS013030",
        "value": "CI SLOBODNA ZONA PIROT"
    },
    {
        "key": "RS013048",
        "value": "CR PIROT"
    },
    {
        "key": "RS013277",
        "value": "CI GRADINA"
    },
    {
        "key": "RS014001",
        "value": "CARINARNICA KRALJEVO"
    },
    {
        "key": "RS014010",
        "value": "CR RAŠKA"
    },
    {
        "key": "RS014052",
        "value": "CI KRALJEVO"
    },
    {
        "key": "RS014060",
        "value": "CI ČAČAK"
    },
    {
        "key": "RS014109",
        "value": "CI NOVI PAZAR"
    },
    {
        "key": "RS014176",
        "value": "CI GORNJI MILANOVAC"
    },
    {
        "key": "RS014320",
        "value": "CP RUDNICA"
    },
    {
        "key": "RS014338",
        "value": "CI ŠPILJANI"
    },
    {
        "key": "RS014346",
        "value": "CP BRNJAČKI MOST"
    },
    {
        "key": "RS015008",
        "value": "CARINARNICA NIŠ"
    },
    {
        "key": "RS015024",
        "value": "CI LESKOVAC"
    },
    {
        "key": "RS015032",
        "value": "CR RIBARCI"
    },
    {
        "key": "RS015040",
        "value": "CR STREZIMIROVCI"
    },
    {
        "key": "RS015075",
        "value": "CR ŽELEZNIČKA STANICA NIŠ"
    },
    {
        "key": "RS015083",
        "value": "CR POŠTA NIŠ"
    },
    {
        "key": "RS015105",
        "value": "CI TERMINAL PREŠEVO"
    },
    {
        "key": "RS015113",
        "value": "CR KNJAŽEVAC"
    },
    {
        "key": "RS015121",
        "value": "CI VRANJE"
    },
    {
        "key": "RS015156",
        "value": "CI TERMINAL NIŠ"
    },
    {
        "key": "RS015172",
        "value": "CI PREŠEVO"
    },
    {
        "key": "RS015288",
        "value": "CR ŽELEZNIČKA STANICA RISTOVAC"
    },
    {
        "key": "RS015407",
        "value": "CI PROKUPLJE"
    },
    {
        "key": "RS015423",
        "value": "CR DUVANSKA NIŠ"
    },
    {
        "key": "RS016128",
        "value": "UPRAVA CARINA"
    },
    {
        "key": "RS017000",
        "value": "CARINARNICA KRAGUJEVAC"
    },
    {
        "key": "RS017019",
        "value": "CI KRAGUJEVAC"
    },
    {
        "key": "RS017027",
        "value": "CR JAGODINA"
    },
    {
        "key": "RS017035",
        "value": "CR SMEDEREVSKA PALANKA"
    },
    {
        "key": "RS017043",
        "value": "CR ŽELEZNIČKA STANICA KRAGUJEVAC"
    },
    {
        "key": "RS017078",
        "value": "CR LAPOVO"
    },
    {
        "key": "RS017094",
        "value": "CARINSKI REFERAT VELIKA PLANA"
    },
    {
        "key": "RS017256",
        "value": "CR ARANĐELOVAC"
    },
    {
        "key": "RS017329",
        "value": "CR MLADENOVAC"
    },
    {
        "key": "RS017337",
        "value": "CR POŽAREVAC"
    },
    {
        "key": "RS017345",
        "value": "CI SMEDEREVO"
    },
    {
        "key": "RS017353",
        "value": "CR ŽELEZARA SMEDEREVO"
    },
    {
        "key": "RS017370",
        "value": "CR SLOBODNA ZONA KRAGUJEVAC"
    },
    {
        "key": "RS017388",
        "value": "CR SLOBODNA ZONA SMEDEREVO"
    },
    {
        "key": "RS021008",
        "value": "CARINARNICA NOVI SAD"
    },
    {
        "key": "RS021016",
        "value": "CI LUKA I SKLADIŠTA NOVI SAD"
    },
    {
        "key": "RS021024",
        "value": "CI BAČKA PALANKA"
    },
    {
        "key": "RS021040",
        "value": "CR VRBAS"
    },
    {
        "key": "RS021059",
        "value": "CR POŠTA NOVI SAD"
    },
    {
        "key": "RS021067",
        "value": "CI RANŽIRNA ŽELEZ. STANICA NOVI SAD"
    },
    {
        "key": "RS021083",
        "value": "CI ŽELEZNIČKA STANICA ŠID"
    },
    {
        "key": "RS021091",
        "value": "CI BATROVCI"
    },
    {
        "key": "RS021105",
        "value": "CI MOST BAČKA PALANKA"
    },
    {
        "key": "RS021156",
        "value": "CR NEŠTIN"
    },
    {
        "key": "RS021202",
        "value": "CR ZA POSLOVE CAR. NADZORA NOVI SAD"
    },
    {
        "key": "RS021270",
        "value": "CR SOT"
    },
    {
        "key": "RS021300",
        "value": "CR LJUBA"
    },
    {
        "key": "RS021342",
        "value": "CI ŠID"
    },
    {
        "key": "RS021369",
        "value": "CR BEČEJ"
    },
    {
        "key": "RS021377",
        "value": "CR SLOBODNA ZONA NOVI SAD"
    },
    {
        "key": "RS021440",
        "value": "CR ZA REČNI SAOBRAĆAJ NOVI SAD"
    },
    {
        "key": "RS021458",
        "value": "CR NOVI SAD"
    },
    {
        "key": "RS022004",
        "value": "CARINARNICA SOMBOR"
    },
    {
        "key": "RS022012",
        "value": "CR APATIN"
    },
    {
        "key": "RS022020",
        "value": "CI BAČKI BREG"
    },
    {
        "key": "RS022039",
        "value": "CI BEZDAN-MOHAČ"
    },
    {
        "key": "RS022047",
        "value": "CR SOMBOR"
    },
    {
        "key": "RS022128",
        "value": "CI BOGOJEVO"
    },
    {
        "key": "RS022144",
        "value": "CR BEZDAN"
    },
    {
        "key": "RS022292",
        "value": "CR ŽELEZNIČKA STANICA BOGOJEVO"
    },
    {
        "key": "RS023000",
        "value": "CARINARNICA VRŠAC"
    },
    {
        "key": "RS023019",
        "value": "CI KALUĐEROVO"
    },
    {
        "key": "RS023027",
        "value": "CI VATIN"
    },
    {
        "key": "RS023035",
        "value": "CI ŽELEZNIČKA STANICA VRŠAC"
    },
    {
        "key": "RS023043",
        "value": "CI HEMOFARM"
    },
    {
        "key": "RS024007",
        "value": "CARINARNICA ZRENJANIN"
    },
    {
        "key": "RS024015",
        "value": "CI KIKINDA"
    },
    {
        "key": "RS024023",
        "value": "CI ZRENJANIN"
    },
    {
        "key": "RS024031",
        "value": "CI SRPSKA CRNJA"
    },
    {
        "key": "RS024287",
        "value": "CR SLOBODNA ZONA ZRENJANIN"
    },
    {
        "key": "RS025003",
        "value": "CARINARNICA SUBOTICA"
    },
    {
        "key": "RS025011",
        "value": "CI TERMINAL HORGOŠ"
    },
    {
        "key": "RS025038",
        "value": "JAVNA SKLADIŠTA SUBOTICA"
    },
    {
        "key": "RS025046",
        "value": "CI ŽELEZNIČKA STANICA SUBOTICA"
    },
    {
        "key": "RS025054",
        "value": "CI TERMINAL KELEBIJA"
    },
    {
        "key": "RS025062",
        "value": "CI SENTA"
    },
    {
        "key": "RS025151",
        "value": "CI HORGOŠ"
    },
    {
        "key": "RS025160",
        "value": "CI SLOBODNA ZONA SUBOTICA"
    },
    {
        "key": "RS025267",
        "value": "CI KELEBIJA"
    },
    {
        "key": "RS031003",
        "value": "CARINARNICA PRIŠTINA"
    },
    {
        "key": "RS031305",
        "value": "CP MERDARE"
    },
    {
        "key": "RS031321",
        "value": "CP DEPCE"
    },
    {
        "key": "RS031330",
        "value": "CP KONČULJ"
    },
    {
        "key": "RS042005",
        "value": "CARINARNICA ŠABAC"
    },
    {
        "key": "RS042056",
        "value": "CI ŠABAC"
    },
    {
        "key": "RS042072",
        "value": "CI SREMSKA MITROVICA"
    },
    {
        "key": "RS042102",
        "value": "CI SREMSKA RAČA"
    },
    {
        "key": "RS042145",
        "value": "CI VALJEVO"
    },
    {
        "key": "RS042153",
        "value": "CI MALI ZVORNIK"
    },
    {
        "key": "RS042188",
        "value": "CI BADOVINCI"
    },
    {
        "key": "RS042196",
        "value": "CI TRBUŠNICA"
    },
    {
        "key": "RS042200",
        "value": "CI LJUBOVIJA"
    },
    {
        "key": "RS042277",
        "value": "CR SLOBODNA ZONA ŠABAC"
    },
    {
        "key": "RS045004",
        "value": "CARINARNICA KRUŠEVAC"
    },
    {
        "key": "RS045047",
        "value": "CI KRUŠEVAC"
    },
    {
        "key": "RS045055",
        "value": "CR PARAĆIN"
    },
    {
        "key": "RS045063",
        "value": "CR TRSTENIK"
    },
    {
        "key": "RS045110",
        "value": "CR SLOBODNA ZONA KRUŠEVAC"
    },
    {
        "key": "RS046000",
        "value": "CARINARNICA UŽICE"
    },
    {
        "key": "RS046027",
        "value": "CI UŽICE"
    },
    {
        "key": "RS046035",
        "value": "CR POŽEGA"
    },
    {
        "key": "RS046043",
        "value": "CI PRIJEPOLJE"
    },
    {
        "key": "RS046051",
        "value": "CR JABUKA"
    },
    {
        "key": "RS046060",
        "value": "CR ŽELEZ. STAN. PRIJEPOLJE KOLOVRAT"
    },
    {
        "key": "RS046078",
        "value": "CI GOSTUN"
    },
    {
        "key": "RS046086",
        "value": "CR BAJINA BAŠTA"
    },
    {
        "key": "RS046094",
        "value": "CI KOTROMAN"
    },
    {
        "key": "RS046108",
        "value": "CI UVAC"
    },
    {
        "key": "RS046124",
        "value": "CR SLOBODNA ZONA UŽICE"
    },
    {
        "key": "RS046159",
        "value": "CR SLOBODNA ZONA PRIBOJ"
    },
    {
        "key": "SE000050",
        "value": "MALMÖ TULLVERKET"
    },
    {
        "key": "SE003033",
        "value": "ARLANDA TULLVERKET"
    },
    {
        "key": "GBABD001",
        "value": "Aberdeen, Ruby House"
    },
    {
        "key": "GBAVO001",
        "value": "Avonmouth, Customs House"
    },
    {
        "key": "GBBTH001",
        "value": "Bathgate, Pyramids Business Centre"
    },
    {
        "key": "GBBEL001",
        "value": "Belfast, Beaufort House"
    },
    {
        "key": "GBBEL002",
        "value": "Belfast, Carne House"
    },
    {
        "key": "GBBEL003",
        "value": "Belfast, Custom House"
    },
    {
        "key": "GBBEL004",
        "value": "Belfast, Dorchester House (NIRU)"
    },
    {
        "key": "GBBHM001",
        "value": "Birmingham, 2 Broadway"
    },
    {
        "key": "GBBHM002",
        "value": "Birmingham, City Centre House"
    },
    {
        "key": "GBBLB001",
        "value": "Blackburn, Chaucers Walk"
    },
    {
        "key": "GBBLT001",
        "value": "Bolton, Stonecross House"
    },
    {
        "key": "GBBOE001",
        "value": "Bootle, Litherland House"
    },
    {
        "key": "GBBOE002",
        "value": "Bootle, St. John’s House"
    },
    {
        "key": "GBBOE003",
        "value": "Bootle, St. John’s House"
    },
    {
        "key": "GBBOH001",
        "value": "Bournemouth, Holland House"
    },
    {
        "key": "GBBRD001",
        "value": "Bradford, Centenary Court"
    },
    {
        "key": "GBBRD002",
        "value": "Bradford, No.1 The Interchange"
    },
    {
        "key": "GBRIH001",
        "value": "Brierley Hill, Merry Hill Contact Centre"
    },
    {
        "key": "GBBSH001",
        "value": "Brighton, Crown House"
    },
    {
        "key": "GBBRS001",
        "value": "Bristol, 101 Victoria Street"
    },
    {
        "key": "GBBRS002",
        "value": "Bristol, Temple Quay House"
    },
    {
        "key": "GBBRS003",
        "value": "Bristol, The Crescent centre"
    },
    {
        "key": "GBCMG001",
        "value": "Cambridge, Eastbrook"
    },
    {
        "key": "GBCNB001",
        "value": "Canterbury, Charter House (re Sun Alliance House)"
    },
    {
        "key": "GBCDF001",
        "value": "Cardiff, Brunel House"
    },
    {
        "key": "GBCDF002",
        "value": "Cardiff, Ty Glas"
    },
    {
        "key": "GBCVT001",
        "value": "Coventry, Sherbourne House"
    },
    {
        "key": "GBCRG001",
        "value": "Craigavon, Marlborough House"
    },
    {
        "key": "GBCWY001",
        "value": "Crawley, Origin One"
    },
    {
        "key": "GBCRO001",
        "value": "Croydon, 1 Ruskin Square"
    },
    {
        "key": "GBCRO002",
        "value": "Croydon, Southern House"
    },
    {
        "key": "GBCLD001",
        "value": "Cumbernauld, Accounts Office"
    },
    {
        "key": "GBDXY001",
        "value": "Derby, Northgate House"
    },
    {
        "key": "GBDVR001",
        "value": "Dover, Priory Court"
    },
    {
        "key": "GBDUN001",
        "value": "Dundee, Caledonian House"
    },
    {
        "key": "GBDUN002",
        "value": "Dundee, Sidlaw House"
    },
    {
        "key": "GBEKB001",
        "value": "East Kilbride, Plaza Tower"
    },
    {
        "key": "GBEKB002",
        "value": "East Kilbride, Queensway House"
    },
    {
        "key": "GBEDI001",
        "value": "Edinburgh, Elgin House"
    },
    {
        "key": "GBEDI002",
        "value": "Edinburgh, Grayfield House"
    },
    {
        "key": "GBEDI003",
        "value": "Edinburgh, Meldrum House"
    },
    {
        "key": "GBENK001",
        "value": "Enniskillen, Abbey House"
    },
    {
        "key": "GBERI001",
        "value": "Erith, Chaucer House"
    },
    {
        "key": "GBEXE001",
        "value": "Exeter, Longbrook House"
    },
    {
        "key": "GBGTC001",
        "value": "Gartcosh, Scottish Crime Campus"
    },
    {
        "key": "GBGLW001",
        "value": "Glasgow, Cotton House"
    },
    {
        "key": "GBGLW002",
        "value": "Glasgow, Portcullis House"
    },
    {
        "key": "GBGLO001",
        "value": "Gloucester, Twyver House"
    },
    {
        "key": "GBGVS001",
        "value": "Gravesend, Custom House"
    },
    {
        "key": "GBGSY001",
        "value": "Grimsby, Imperial House"
    },
    {
        "key": "GBHRW001",
        "value": "Harwich, Custom House"
    },
    {
        "key": "GBHUL001",
        "value": "Hull, Cherry Court"
    },
    {
        "key": "GBHUL002",
        "value": "Hull, Custom House"
    },
    {
        "key": "GBINV001",
        "value": "Inverness, River House"
    },
    {
        "key": "GBIPS001",
        "value": "Ipswich, Haven House"
    },
    {
        "key": "GBIPS002",
        "value": "Ipswich, St Clare House"
    },
    {
        "key": "GBLBA001",
        "value": "Leeds, Peter Bennett House (Customs Authorisations &amp; Reviews Team)"
    },
    {
        "key": "GBLBA002",
        "value": "Leeds, 1 Munroe Court"
    },
    {
        "key": "GBLBA003",
        "value": "Leeds, Castle House"
    },
    {
        "key": "GBLBA004",
        "value": "Leeds, Windsor House"
    },
    {
        "key": "GBLCS001",
        "value": "Leicester, Saxon House"
    },
    {
        "key": "GBLCN001",
        "value": "Lincoln, Lawress Hall"
    },
    {
        "key": "GBLIS001",
        "value": "Lisburn, Moira House"
    },
    {
        "key": "GBLIV001",
        "value": "Liverpool, Graeme House (AEO Central Site)"
    },
    {
        "key": "GBLIV002",
        "value": "Liverpool, Imperial Court"
    },
    {
        "key": "GBLIV003",
        "value": "Liverpool, Regian House"
    },
    {
        "key": "GBTON001",
        "value": "Livingston, Barbara Ritchie House"
    },
    {
        "key": "GBLON001",
        "value": "London, 10 South Colonnade"
    },
    {
        "key": "GBLON002",
        "value": "London, 100 Parliament Street"
    },
    {
        "key": "GBLON003",
        "value": "London, Bush House"
    },
    {
        "key": "GBLON004",
        "value": "London, Custom House"
    },
    {
        "key": "GBLON005",
        "value": "London, Custom House Annexe"
    },
    {
        "key": "GBLON006",
        "value": "London, Dorset House"
    },
    {
        "key": "GBLON007",
        "value": "London, Euston Tower"
    },
    {
        "key": "GBLON008",
        "value": "London, International House"
    },
    {
        "key": "GBLON009",
        "value": "London, Jubilee House"
    },
    {
        "key": "GBLDY001",
        "value": "Londonderry, Foyle House"
    },
    {
        "key": "GBLUT001",
        "value": "Luton, King House"
    },
    {
        "key": "GBMDT001",
        "value": "Maidstone, Medvale House"
    },
    {
        "key": "GBMNC001",
        "value": "Manchester, Albert Bridge House"
    },
    {
        "key": "GBMNC002",
        "value": "Manchester, Manchester Airport"
    },
    {
        "key": "GBMID001",
        "value": "Middlesbrough, 100 Russell Street"
    },
    {
        "key": "GBMID002",
        "value": "Middlesbrough, Eustace House"
    },
    {
        "key": "GBNTE001",
        "value": "Netherton, Comben House"
    },
    {
        "key": "GBNCL001",
        "value": "Newcastle, Benton Park View"
    },
    {
        "key": "GBNCL002",
        "value": "Newcastle, Block D, Tyneview Park"
    },
    {
        "key": "GBNHP001",
        "value": "Northampton, Princess House"
    },
    {
        "key": "GBNTG001",
        "value": "Nottingham, Barkley House"
    },
    {
        "key": "GBNTG002",
        "value": "Nottingham, Castle Meadow Campus"
    },
    {
        "key": "GBNTG003",
        "value": "Nottingham, Ferrers House"
    },
    {
        "key": "GBNTG004",
        "value": "Nottingham, FitzRoy House"
    },
    {
        "key": "GBNTG005",
        "value": "Nottingham, Howard House"
    },
    {
        "key": "GBNTG006",
        "value": "Nottingham, Yorke House"
    },
    {
        "key": "GBOXF001",
        "value": "Oxford, Trinity House"
    },
    {
        "key": "GBPET001",
        "value": "Peterborough, Churchgate"
    },
    {
        "key": "GBPET002",
        "value": "Peterborough, Peterborough (Woodston) Storage Centre"
    },
    {
        "key": "GBPTE001",
        "value": "Peterlee, Emerald Court"
    },
    {
        "key": "GBPTD001",
        "value": "Porthmadog, Ty Moelwyn"
    },
    {
        "key": "GBPME001",
        "value": "Portsmouth, Lynx House"
    },
    {
        "key": "GBPME002",
        "value": "Portsmouth, Wingfield House"
    },
    {
        "key": "GBPRE001",
        "value": "Preston, Charles House"
    },
    {
        "key": "GBPRE002",
        "value": "Preston, Diadem House"
    },
    {
        "key": "GBPRE003",
        "value": "Preston, St Mark’s House"
    },
    {
        "key": "GBPRE004",
        "value": "Preston, St Mary’s House"
    },
    {
        "key": "GBPRE005",
        "value": "Preston, The Guild Centre"
    },
    {
        "key": "GBPRE006",
        "value": "Preston, Unicentre"
    },
    {
        "key": "GBRDN001",
        "value": "Reading, Sapphire Plaza"
    },
    {
        "key": "GBREH001",
        "value": "Redhill, Warwick House"
    },
    {
        "key": "GBRRU001",
        "value": "Redruth, Piran House"
    },
    {
        "key": "GBSFY001",
        "value": "Salford, Ralli Quays (NTAS) (EORI Team)"
    },
    {
        "key": "GBSFY002",
        "value": "Salford, Trinity Bridge House"
    },
    {
        "key": "GBSHE001",
        "value": "Sheffield, Concept House"
    },
    {
        "key": "GBSHI001",
        "value": "Shipley, Accounts Office"
    },
    {
        "key": "GBSOL001",
        "value": "Solihull, Regent House"
    },
    {
        "key": "GBSOL002",
        "value": "Solihull, Royal House"
    },
    {
        "key": "GBSOL003",
        "value": "Solihull, Sapphire East"
    },
    {
        "key": "GBSOU001",
        "value": "Southampton, Compass House"
    },
    {
        "key": "GBSAA001",
        "value": "Southend on Sea, Alexander House"
    },
    {
        "key": "GBSHY001",
        "value": "St Helens, College Street"
    },
    {
        "key": "GBSTS001",
        "value": "Staines, Forum House"
    },
    {
        "key": "GBSTS002",
        "value": "Staines, Heliting House"
    },
    {
        "key": "GBSTT001",
        "value": "Stockton-on-Tees, George Stephenson House"
    },
    {
        "key": "GBSOT001",
        "value": "Stoke-on-Trent, Blackburn House"
    },
    {
        "key": "GBSUN001",
        "value": "Sunderland, Waterside House"
    },
    {
        "key": "GBSUR001",
        "value": "Surbiton, Tolworth Tower"
    },
    {
        "key": "GBSFY001",
        "value": "Salford, Ralli Quays (NTAS)"
    },
    {
        "key": "GBSFY002",
        "value": "Salford, Trinity Bridge House"
    },
    {
        "key": "GBSWA001",
        "value": "Swansea, Ty Nant"
    },
    {
        "key": "GBSNN001",
        "value": "Swindon, Wiltshire Court"
    },
    {
        "key": "GBTEF001",
        "value": "Telford, Abbey House"
    },
    {
        "key": "GBTEF002",
        "value": "Telford, Parkside Court"
    },
    {
        "key": "GBTEF003",
        "value": "Telford, Plaza 2 - Floor 2"
    },
    {
        "key": "GBUXB001",
        "value": "Uxbridge, Valiant House"
    },
    {
        "key": "GBWRN001",
        "value": "Warrington, Mersey Bank House"
    },
    {
        "key": "GBWNN001",
        "value": "Washington, Waterview Park"
    },
    {
        "key": "GBWAF001",
        "value": "Watford, Cambridge House"
    },
    {
        "key": "GBWBY001",
        "value": "Wembley, Olympic House"
    },
    {
        "key": "GBWKG001",
        "value": "Woking, Dukes Court"
    },
    {
        "key": "GBWOV001",
        "value": "Wolverhampton, Crown House"
    },
    {
        "key": "GBWTR001",
        "value": "Worcester, County Hall"
    },
    {
        "key": "GBWOR001",
        "value": "Workington, West Cumbria House"
    },
    {
        "key": "GBWOT001",
        "value": "Worthing, Durrington Bridge House"
    },
    {
        "key": "GBWOT002",
        "value": "Worthing, HMRC Barrington Road"
    },
    {
        "key": "GBWXH001",
        "value": "Wrexham, Plas Gororau"
    },
    {
        "key": "GBYRK001",
        "value": "York, Swinson House"
    },
    {
        "key": "AT230100",
        "value": "ZOLLSTELLE TULLN"
    },
    {
        "key": "BE305000",
        "value": "BRUGGE DAE DOUANEKANTOOR"
    },
    {
        "key": "BE324000",
        "value": "RONSE DE DOUANEKANTOOR"
    },
    {
        "key": "BE553000",
        "value": "STERPENICH"
    },
    {
        "key": "DE005656",
        "value": "ABFERTIGUNGSSTELLE LUDWIGSDORF"
    },
    {
        "key": "FR620001",
        "value": "Calais port tunnel bureau"
    },
    {
        "key": "HR030600",
        "value": "RGP DVOR"
    },
    {
        "key": "IEDON200",
        "value": "RevenueCommissioners"
    },
    {
        "key": "IEKRY400",
        "value": "KERRY AIRPORT"
    },
    {
        "key": "IEMTH200",
        "value": "C& E Section"
    },
    {
        "key": "LV000714",
        "value": "GULBENES MKP"
    },
    {
        "key": "PL321060",
        "value": "WLADYSLAWOWO OC"
    },
    {
        "key": "SE004134",
        "value": "NORRKOPING TULLVERKET"
    },
    {
        "key": "SE010332",
        "value": "TARNABY TULLVERKET"
    },
    {
        "key": "SE010333",
        "value": "BJORNFJELL TOLLSTED"
    },
    {
        "key": "SE010334",
        "value": "JUNKERDAL TOLLSTED"
    },
    {
        "key": "SE020342",
        "value": "STORLIEN TULLVERKET"
    },
    {
        "key": "SE020344",
        "value": "VAULDALEN TULLVERKET"
    },
    {
        "key": "SE060335",
        "value": "HAN TULLVERKET"
    },
    {
        "key": "SE060340",
        "value": "SVINESUND TOLLSTED"
    },
    {
        "key": "SE060341",
        "value": "ORJE TOLLSTED"
    },
    {
        "key": "SE303400",
        "value": "STOCKHOLM TULLVERKET"
    },
    {
        "key": "SE603303",
        "value": "GOTEBORG TULLVERKET"
    },
    {
        "key": "SE603340",
        "value": "SVINESUND TULLVERKET"
    },
    {
        "key": "SE603342",
        "value": "STROMSTAD TULLVERKET"
    },
    {
        "key": "SE603360",
        "value": "EDA TULLVERKET"
    },
    {
        "key": "SE603362",
        "value": "OSTBY TOLLSTED"
    },
    {
        "key": "SE603363",
        "value": "ASNES TOLLSTED"
    },
    {
        "key": "SI001026",
        "value": "IZPOSTAVA KRANJ"
    },
    {
        "key": "SI001034",
        "value": "LETALISCE BRNIK"
    },
    {
        "key": "SI001050",
        "value": "IZPOSTAVA NOVO MESTO"
    },
    {
        "key": "SI001115",
        "value": "A"
    },
    {
        "key": "SI001123",
        "value": "IZPOSTAVA OBREZJE"
    },
    {
        "key": "SI001913",
        "value": "TERMINAL LJUBLJANA"
    },
    {
        "key": "SI002022",
        "value": "IZPOSTAVA CELJE"
    },
    {
        "key": "SI003053",
        "value": "IZPOSTAVA DRAVOGRAD"
    },
    {
        "key": "SI004017",
        "value": "MURSKA SOBOTA"
    },
    {
        "key": "SI005080",
        "value": "PLAVSKI TRAVNIK"
    },
    {
        "key": "SI006036",
        "value": "IZPOSTAVA KOPER"
    },
    {
        "key": "SI006044",
        "value": "IZPOSTAVA LUK KOPER"
    },
    {
        "key": "SI006090",
        "value": "IZPOSTAVA POTNISKA KA LUKA KOPER"
    },
    {
        "key": "SI006170",
        "value": "IZPOSTAVA DRAGONJA"
    },
    {
        "key": "SI007040",
        "value": "LETALISCE MARIBOR"
    },
    {
        "key": "SI007067",
        "value": "IZPOSTAVA MARIBOR"
    },
    {
        "key": "SI007075",
        "value": "A"
    },
    {
        "key": "SI008071",
        "value": "A"
    },
    {
        "key": "SI008128",
        "value": "IZPOSTAVA VRTOJBA"
    },
    {
        "key": "SI009043",
        "value": "TERMINAL SEZANA"
    },
    {
        "key": "SK515200",
        "value": "POB. COLN. UR. BAUSKA BYSTR. POSTA"
    },
    {
        "key": "SK517200",
        "value": "LUCENEC NS"
    },
    {
        "key": "SK517700",
        "value": "A"
    },
    {
        "key": "SK524100",
        "value": "A"
    },
    {
        "key": "SK524200",
        "value": "BRATISLAVA - LETISKO"
    },
    {
        "key": "SK525100",
        "value": "BRATISLAVA - POSTA"
    },
    {
        "key": "SK526400",
        "value": "BRATISLAVA - PRISTAV"
    },
    {
        "key": "SK526500",
        "value": "POBOCKA COLNEHO URADU BRATISLAVA-STAVITELSKA"
    },
    {
        "key": "SK526700",
        "value": "BRATISLAVA"
    },
    {
        "key": "SK531100",
        "value": "CIERNA NAD TISOU -ZELEZNICA 'A'"
    },
    {
        "key": "SK531102",
        "value": "CIERNA NAD TISOU-SIROKY ROZCHOD C"
    },
    {
        "key": "SK531400",
        "value": "MATOVCE - ZELEZNICA"
    },
    {
        "key": "SK532100",
        "value": "VYSNE NEMECKE"
    },
    {
        "key": "SK532300",
        "value": "UBL`A"
    },
    {
        "key": "SK536100",
        "value": "MICHALOVCE"
    },
    {
        "key": "SK536200",
        "value": "DOBRA"
    },
    {
        "key": "SK536400",
        "value": "TREBISOV"
    },
    {
        "key": "SK564100",
        "value": "KOSICE - LETISKO"
    },
    {
        "key": "SK565100",
        "value": "KOSICE"
    },
    {
        "key": "SK565300",
        "value": "KOSICE POSTA"
    },
    {
        "key": "SK565301",
        "value": "PCU KOSISE SACA - PRACOVISKO SRT"
    },
    {
        "key": "SK565302",
        "value": "A"
    },
    {
        "key": "SK566500",
        "value": "ROZNAVA"
    },
    {
        "key": "SK567100",
        "value": "SPISSKA NOVA VES"
    },
    {
        "key": "SK586100",
        "value": "DUNAJSKA STREDA"
    },
    {
        "key": "SK586101",
        "value": "POB. COLN. UR. DUNASJ. STRE. METRANS"
    },
    {
        "key": "SK586102",
        "value": "POB. COLN. UR. VELKY MEDER"
    },
    {
        "key": "SK586200",
        "value": "PIESTANY"
    },
    {
        "key": "SK586400",
        "value": "TRNAVA"
    },
    {
        "key": "SK586500",
        "value": "SENICA"
    },
    {
        "key": "SK587100",
        "value": "GALANTA"
    },
    {
        "key": "SK587200",
        "value": "BRODSKE"
    },
    {
        "key": "SK587201",
        "value": "A"
    },
    {
        "key": "SK605200",
        "value": "POB. COLN. UR. ZILINA POSTA"
    },
    {
        "key": "SK606300",
        "value": "MARTIN"
    },
    {
        "key": "SK606900",
        "value": "ZILINA - NACESTNE STEDISKO"
    },
    {
        "key": "SK607600",
        "value": "TRSTENA"
    },
    {
        "key": "SK607700",
        "value": "RUZOMBEROK"
    },
    {
        "key": "SK615500",
        "value": "KOMARNO"
    },
    {
        "key": "SK615700",
        "value": "LEVICE"
    },
    {
        "key": "SK615800",
        "value": "NOVE ZAMKY - NS"
    },
    {
        "key": "SK615900",
        "value": "STUROVO"
    },
    {
        "key": "SK616300",
        "value": "TOPOLCANY"
    },
    {
        "key": "SK616500",
        "value": "NITRA"
    },
    {
        "key": "SK625200",
        "value": "A"
    },
    {
        "key": "SK626300",
        "value": "PRESOV NS"
    },
    {
        "key": "SK627200",
        "value": "STARA L UBOVNA"
    },
    {
        "key": "SK627300",
        "value": "POPRAD"
    },
    {
        "key": "SK666100",
        "value": "TRENCIN"
    },
    {
        "key": "SK666300",
        "value": "NOVE MESTO NAD VAHOM"
    },
    {
        "key": "SK666500",
        "value": "PUCHOV"
    },
    {
        "key": "SK666700",
        "value": "PRIEVIDZA"
    },
    {
        "key": "SK667100",
        "value": "ILAVA"
    },
    {
        "key": "XI000014",
        "value": "Belfast International Airport"
    },
    {
        "key": "XI000081",
        "value": "NI Auth Consignor/nees"
    },
    {
        "key": "XI000140",
        "value": "George Best (Belfast City)"
    },
    {
        "key": "XI000142",
        "value": "Belfast Docks"
    },
    {
        "key": "XI003210",
        "value": "Londonderry"
    }
];

export default tariffOffices;