import React, { useContext, useEffect, useState } from "react";
import Button from "../components/common/Button";
import { useHistory } from "react-router-dom";
import NewDeclarationModal from "../components/layouts/declarations/NewDeclarationModal/NewDeclarationModal";
import PageContainer from "../components/layouts/PageContainer";
import { useTranslation } from "react-i18next";
import { PiPlus } from "react-icons/pi";
import SubscriptionUsageCard from "../components/layouts/dashboard/SubscriptionUsageCard";
import ChartCard from "../components/layouts/dashboard/Chart/ChartCard";
import DeclarationsContext from "../context/declarations/DeclarationsContext";
import StatsCards from "../components/layouts/dashboard/StatsCards";
import { Spinner } from "react-bootstrap";
import { DECLARATION_ID_QUERY_PARAM } from "../constants/GlobalConstants";
import { seo } from "../helpers";

const DashboardLayout = () => {
	const [isNewDeclarationModalOpen, setIsNewDeclarationModalOpen] = useState(false);
	const { state, listDeclarations } = useContext(DeclarationsContext);
	const history = useHistory();
	const { t } = useTranslation();

	useEffect(() => {
		if (!state.declarationsLoading && !state.declarations.length) {
			listDeclarations();
		}
	}, []);

	useEffect(() => {
		seo({
			title: t("dashboard"),
		});

		return () => {
			seo({});
		};
	}, []);

	return (
		<>
			<NewDeclarationModal
				isOpen={isNewDeclarationModalOpen}
				onSubmit={({ _id }) =>
					history.push(`/declarations?${DECLARATION_ID_QUERY_PARAM}=${_id}`)
				}
				onClose={() => setIsNewDeclarationModalOpen(false)}
			/>

			<PageContainer title={t("dashboard")} innerStyle={{ backgroundColor: "transparent" }}>
				{state.declarationsLoading ? (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							padding: 62,
						}}
					>
						<Spinner animation="border" role="status" />
					</div>
				) : (
					<>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ fontSize: 14, fontWeight: 500 }}>
								{t("declarationsSubmittedThisMonth")}
							</div>

							<div style={{ width: "fit-content", marginLeft: "auto" }}>
								<Button
									onClick={() =>
										setIsNewDeclarationModalOpen(true)
									}
									LeftIcon={PiPlus}
								>
									{t("addNewDeclaration")}
								</Button>
							</div>
						</div>

						<StatsCards />

						<div
							style={{
								display: "flex",
								alignItems: "center",
								marginTop: 20,
								gap: 20,
							}}
						>
							<ChartCard />
							<SubscriptionUsageCard />
						</div>
					</>
				)}
			</PageContainer>
		</>
	);
};

export default DashboardLayout;
