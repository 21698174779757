import { useContext, useEffect, useState } from "react";
import Button from "../../../components/common/Button";
import UserContext from "../../../context/user/UserContext";
import { LS_LANGUAGE_KEY } from "../../../constants/GlobalConstants";
import { PiFloppyDisk } from "react-icons/pi";
import { useTranslation } from "react-i18next";
import SettingsElement from "../SettingsElement";

const ApplicationSettingsTab = () => {
	const { i18n, t } = useTranslation();

	const [inputValues, setInputValues] = useState({});
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const { updateSettings, userSettings } = useContext(UserContext);

	const handleChange = (name, value) => {
		setInputValues({
			...inputValues,
			[name]: value,
		});
	};

	const handleResetClick = () => {
		setInputValues({ ...userSettings });
	};

	const handleSaveClick = async () => {
		setIsSaveLoading(true);

		try {
			await updateSettings({
				...inputValues,
			});
		} catch (e) {
			console.log(e.response.data);
		}

		if (i18n.language !== inputValues.language) {
			i18n.changeLanguage(inputValues.language);
			localStorage.setItem(LS_LANGUAGE_KEY, inputValues.language);
		}

		setIsSaveLoading(false);
	};

	useEffect(() => {
		if (userSettings) {
			setInputValues({ ...userSettings.settings });
		} else {
			setInputValues({});
		}
	}, [userSettings]);

	return (
		<div>
			<SettingsElement elementName="language" onChange={handleChange} inputValues={inputValues} />

			<div
				style={{
					width: "fit-content",
					marginLeft: "auto",
					display: "flex",
					alignItems: "center",
					gap: 12,
					marginTop: 52,
				}}
			>
				<Button type="secondary" onClick={handleResetClick} disabled={isSaveLoading}>
					{t("reset")}
				</Button>
				<Button isLoading={isSaveLoading} onClick={handleSaveClick} LeftIcon={PiFloppyDisk}>
					{t("saveChanges")}
				</Button>
			</div>
		</div>
	);
};

export default ApplicationSettingsTab;
