import { PiWarningCircleFill } from "react-icons/pi";
import Button from "../../../common/Button";
import Tooltip from "../../../common/Tooltip";
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import ThemeContext from "../../../../context/theme/ThemeContext";
import FilesContext from "../../../../context/files/FilesContext";
import DeclarationsContext from "../../../../context/declarations/DeclarationsContext";

const SubmissionErrorIcon = ({ file }) => {
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);
	const { t } = useTranslation();
	const { submitFileToHmrc } = useContext(FilesContext);
	const { state } = useContext(DeclarationsContext);
	const [isLoading, setIsLoading] = useState(false);
	const { withTheme } = useContext(ThemeContext);

	const handleRetryClick = async () => {
		setIsLoading(true);

		await submitFileToHmrc(file, state.declaration);

		setIsLoading(false);
		setIsTooltipOpen(false);
	};

	return (
		<Tooltip
			isOpen={isTooltipOpen}
			onOpen={() => setIsTooltipOpen(true)}
			onClose={() => setIsTooltipOpen(false)}
			text={
				<div>
					<span>{t("fileNotSubmittedToHmrc")}</span>

					<div
						style={{
							width: "fit-content",
							marginLeft: "auto",
						}}
					>
						<Button isLoading={isLoading} onClick={handleRetryClick} type="text">
							{t("retry")}
						</Button>
					</div>
				</div>
			}
		>
			<PiWarningCircleFill cursor="pointer" className={withTheme("error-text-icon")} />
		</Tooltip>
	);
};

export default SubmissionErrorIcon;
