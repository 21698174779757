const qualifiers = [
    {
        "key": "DEFAULT",
        "value": "Please Select"
    },
    {
        "key": "A",
        "value": "Litre Total alcohol"
    },
    {
        "key": "C",
        "value": "Cubic metre 1000"
    },
    {
        "key": "B",
        "value": "Number of items per flask"
    },
    {
        "key": "E",
        "value": "Tonne net of drained weight"
    },
    {
        "key": "I",
        "value": "Tonne of biodiesel content"
    },
    {
        "key": "J",
        "value": "Tonne of fuel content (usually in form of tallow, stearin, paraffin wax or other waxes, including the wick)"
    },
    {
        "key": "K",
        "value": "Tonne of bioethanol content"
    },
    {
        "key": "M",
        "value": "Tonne net of dry matter"
    },
    {
        "key": "R",
        "value": "Tonne of the standard quality"
    },
    {
        "key": "Z",
        "value": "Tonne per 1% by weight of sucrose"
    }
];

export default qualifiers;