import React, { useContext } from "react";
import ThemeContext from "../../../../../context/theme/ThemeContext";

const ListItem = (props) => {
	const { withTheme } = useContext(ThemeContext);
	const { handleItemClick, item, index, selectedItemIndex } = props;

	const selected = selectedItemIndex === index && !item.new;

	return (
		<div
			className={`${withTheme("item")} ${selected ? withTheme("item-selected") : ""}`}
			onClick={() => handleItemClick(item, index)}
		>
			{selected && <div className={withTheme("item-selection-highlighter")}></div>}

			<div className={withTheme("item-title")}>{item.name}</div>
		</div>
	);
};

export default ListItem;
