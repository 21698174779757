import { useContext } from "react";
import ThemeContext from "../../context/theme/ThemeContext";

const ListItem = ({ isSelected, onClick, children }) => {
	const { withTheme } = useContext(ThemeContext);

	return (
		<div
			onClick={onClick}
			className={`${withTheme("list-item")} ${isSelected ? withTheme("list-item-selected") : ""}`}
		>
			{isSelected && <div className={withTheme("list-item-selected-highlight")}></div>}

			{children}
		</div>
	);
};

export const ListItemTitle = ({ children, style }) => {
	const { withTheme } = useContext(ThemeContext);

	return (
		<div style={style} className={withTheme("list-item-title")}>
			{children}
		</div>
	);
};

export const ListItemSubTitle = ({ children, style }) => {
	const { withTheme } = useContext(ThemeContext);
   
	return (
		<div style={style} className={withTheme("list-item-subtitle")}>
			{children}
		</div>
	);
};

export default ListItem;
