import { useTranslation } from "react-i18next";
import Modal from "../../../modals/Modal";
import NotificationError from "./NotificationError";
import { useMemo, useState } from "react";
import Tabs from "../../../common/Tabs/Tabs";
import ReadOnlyElement from "../../../common/ReadOnlyElement/ReadOnlyElement";

const NotificationDetailsModal = (props) => {
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const { open, notification, onClose } = props;
	const { t } = useTranslation();

	const tabs = useMemo(() => {
		let tabs = [];

		if (notification?.errors?.length) {
			tabs.push(t("errors"));
		}

		if (notification?.data.declaration && Object.keys(notification.data.declaration).length) {
			tabs.push(t("data"));
		}

		return tabs;
	}, [notification]);

	if (!notification) {
		return null;
	}

	return (
		<Modal isOpen={open} onClose={onClose} label={t("notificationDetails")} style={{ width: 650 }}>
			<Tabs
				tabs={tabs}
				selectedTabIndex={selectedTabIndex}
				onChange={(index) => {
					setSelectedTabIndex(index);
				}}
			/>

			{selectedTabIndex === 0 &&
				Boolean(notification.errors?.length) &&
				notification.errors.map((error, index) => (
					<NotificationError error={error} onViewClick={onClose} key={index} />
				))}

			{(selectedTabIndex === 1 || !Boolean(notification.errors?.length)) &&
				notification.data &&
				Object.keys(notification.data).map((key) => (
					<ReadOnlyElement key={key} label={key} data={notification.data[key]} />
				))}
		</Modal>
	);
};

export default NotificationDetailsModal;
