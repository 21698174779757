import { PiDownloadSimple, PiPencilSimple, PiTrash } from "react-icons/pi";
import BackButton from "../../../common/BackButton";
import Button from "../../../common/Button";
import { useTranslation } from "react-i18next";

const SelectedFileHeader = ({ file, isDeleteLoading, isEditModeEnabled, onDeleteClick, onBackClick, onEditClick }) => {
	const { t } = useTranslation();

	const handleDownloadClick = () => {
		window.open(file.signedUrl, "_blank").focus();
	};

	return (
		<div style={{ paddingTop: 20, display: "flex", alignItems: "center" }}>
			<BackButton onClick={onBackClick} />

			<div
				style={{
					width: "fit-content",
					marginLeft: "auto",
					display: "flex",
					alignItems: "center",
					gap: 8,
				}}
			>
				<Button type="icon" onClick={handleDownloadClick}>
					<PiDownloadSimple />
				</Button>
				<Button isLoading={isDeleteLoading} type="icon" onClick={onDeleteClick}>
					<PiTrash />
				</Button>

				<Button
					isDisabled={isEditModeEnabled}
					onClick={onEditClick}
					LeftIcon={PiPencilSimple}
					type="secondary"
				>
					{t("editAttributes")}
				</Button>
			</div>
		</div>
	);
};

export default SelectedFileHeader;
