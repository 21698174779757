import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import ElementLabel from './ElementLabel';
import { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash.debounce';

const PhoneNumberInput = (props) => {
    const [inputValue, setInputValue] = useState('');
    const { onChange, inputStyle, element, value } = props;
    const { elementName, readOnly, placeholder } = element;

    const debouncedUpdate = useCallback(
        debounce((value) => {
            onChange && onChange({
                target: {
                    name: elementName,
                    value
                }
            });
        }, 300),
        [elementName, onChange]
    );

    const handleInputChange = (value) => {
        setInputValue(value);
        debouncedUpdate(value)
    };

    useEffect(() => {
        setInputValue(value);
    }, [value]);


    return (
        <div>
            <div className='d-flex align-items-center'>
                <ElementLabel
                    element={element}
                />
            </div>
            <PhoneInput
                style={{ ...inputStyle }}
                placeholder={placeholder}
                value={inputValue}
                disabled={readOnly}
                onChange={handleInputChange}
                limitMaxLength={true}
            />
        </div>
    )
};

export default PhoneNumberInput;