export const readFile = (file, cb) => {
	const rawFile = new XMLHttpRequest();
	rawFile.open("GET", file, false);
	rawFile.onreadystatechange = function () {
		if (rawFile.readyState === 4) {
			if (rawFile.status === 200 || rawFile.status === 0) {
				const allText = rawFile.responseText;
				cb(allText);
			}
		}
	};
	rawFile.send(null);
};

export const parseElementConditions = (conditions, inputValues) => {
	let result = {};

	for (const condition of conditions) {
		for (const comparison of condition.if) {
			if (Object.keys(comparison).every((key) => comparison[key] === inputValues[key])) {
				result = { ...result, ...condition.then };
				break;
			}
		}
	}

	return result;
};
