import { useContext } from "react";
import ThemeContext from "../../context/theme/ThemeContext";

const Badge = ({ count, isCompact, isHidden, style, children }) => {
	const { withTheme } = useContext(ThemeContext);

	return (
		<div style={{ position: "relative" }}>
			<div
				style={{
					display: (!count && !isCompact) || isHidden ? "none" : "",
					...style,
				}}
				className={`${withTheme("custom-badge")} ${
					isCompact ? withTheme("custom-badge-compact") : ""
				}`}
			>
				{count}
			</div>

			{children}
		</div>
	);
};

export default Badge;
