import { useContext, useMemo, useRef, useState } from "react";
import { PiDotsThreeVertical } from "react-icons/pi";
import Popover from "../../../common/Popover/Popover";
import Button from "../../../common/Button";
import PopoverOption from "../../../common/Popover/PopoverOption";
import { useTranslation } from "react-i18next";
import DeclarationsContext from "../../../../context/declarations/DeclarationsContext";
import FilesContext from "../../../../context/files/FilesContext";
import ConfirmationModal from "../../../modals/ConfirmationModal";
import { DECLARATION_STATUSES } from "../../../../constants/GlobalConstants";

const FileOptionsPopover = ({ file, isSubmittedToHmrc, isAssociatedWithDeclaration, onRenameClick }) => {
	const { t } = useTranslation();
	const { state } = useContext(DeclarationsContext);
	const { updateFile, deleteFile, submitFileToHmrc } = useContext(FilesContext);
	const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [loadingAction, setLoadingAction] = useState(false);
	const targetRef = useRef(null);

	const isSubmitToHmrcAllowed = useMemo(
		() =>
			state.declaration.mrn &&
			state.declaration.status === DECLARATION_STATUSES.ACCEPTED &&
			!file.associatedMrns?.includes(state.declaration.mrn),
		[state.declaration, file]
	);

	const handleDownloadClick = () => {
		window.open(file.signedUrl, "_blank").focus();
		setIsOpen(false);
	}

	const handleDetachClick = async () => {
		if (isSubmittedToHmrc) {
			return;
		}

		setLoadingAction("detach");

		let updatedDeclarations = [...file.declarations];
		const declarationIndex = updatedDeclarations.findIndex((dec) => dec._id === state.declaration._id);

		updatedDeclarations.splice(declarationIndex, 1);

		await updateFile(file, { declarations: updatedDeclarations });

		setLoadingAction(null);
		setIsOpen(false);
	};

	const handleDeleteClick = async () => {
		setLoadingAction("delete");

		await deleteFile(file);

		setLoadingAction(null);
	};

	const handleSubmitToHmrcClick = async () => {
		setLoadingAction("submitToHmrc");

		await submitFileToHmrc(file, state.declaration);

		setLoadingAction(null);
		setIsOpen(false);
	};



	return (
		<>
			<ConfirmationModal
				title={t("areYouSure")}
				description={t("deleteFileConfirmation")}
				isOpen={isDeleteConfirmModalOpen}
				isActionLoading={loadingAction === "submit"}
				primaryActionLabel={t("submit")}
				onPrimaryActionClick={() => handleDeleteClick(true)}
				onClose={() => {
					if (!loadingAction) {
						setIsDeleteConfirmModalOpen(false);
					}
				}}
			/>

			<Popover
				isOpen={isOpen}
				targetRef={targetRef}
				onClose={() => setIsOpen(false)}
				style={{ paddingRight: 0, paddingLeft: 0, paddingTop: 8, paddingBottom: 8 }}
			>
				{onRenameClick && <PopoverOption onClick={onRenameClick}>{t("rename")}</PopoverOption>}
				<PopoverOption onClick={handleDownloadClick}>{t('download')}</PopoverOption>

				{isAssociatedWithDeclaration && state.declaration && !isSubmittedToHmrc && (
					<>
						<PopoverOption
							onClick={handleDetachClick}
							isLoading={loadingAction === "detach"}
						>
							{t("detachFromDeclaration")}
						</PopoverOption>

						{isSubmitToHmrcAllowed && (
							<PopoverOption
								onClick={handleSubmitToHmrcClick}
								isLoading={loadingAction === "submitToHmrc"}
							>
								{t("submitToHmrc")}
							</PopoverOption>
						)}
					</>
				)}

				{!file.associatedMrns?.length && (
					<PopoverOption
						onClick={() => {
							setIsDeleteConfirmModalOpen(true);
							setIsOpen(false);
						}}
					>
						{t("deleteFile")}
					</PopoverOption>
				)}
			</Popover>

			<div ref={targetRef} style={{ width: "fit-content", marginLeft: "auto" }}>
				<Button type="secondary-icon" onClick={() => setIsOpen(true)}>
					<PiDotsThreeVertical />
				</Button>
			</div>
		</>
	);
};

export default FileOptionsPopover;
