import { useContext } from "react";
import ThemeContext from "../../context/theme/ThemeContext";

const Track = ({ value, total, label, style }) => {
	const { withTheme } = useContext(ThemeContext);

	return (
		<div style={{ width: "100%", ...style }}>
			<div style={{ display: "flex", alignItems: "center", marginBottom: 6 }}>
				<div className={withTheme("track-label")}>{label}</div>

				<div
					style={{
						display: "flex",
						alignItems: "center",
						width: "fit-content",
						marginLeft: "auto",
						textAlign: "right",
					}}
				>
					<div className={withTheme("track-value")}>{value}</div>
					<div className={withTheme("track-left")}>{total - value} left</div>
				</div>
			</div>
			<div className={withTheme("track-outter")}>
				<div
					className={withTheme("track-inner")}
					style={{ width: `${(value / total) * 100}%` }}
				/>
			</div>
		</div>
	);
};

export default Track;
