import questionSymbol from "../../assets/images/question-symbol.png";
import { useContext } from "react";
import ThemeContext from "../../context/theme/ThemeContext";
import Button from "../common/Button";
import { useTranslation } from "react-i18next";
import Modal from "./Modal";

const ConfirmationModal = ({
	isOpen,
	title,
	description,
	isActionLoading,
	onPrimaryActionClick,
	primaryActionLabel,
	primaryActionStyle,
	image,
	hideBackButton,
	style,
	onClose,
}) => {
	const { t } = useTranslation();
	const { withTheme } = useContext(ThemeContext);

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					...style,
				}}
			>
				<img src={image || questionSymbol} alt="question" width="65px" height="65px" />

				<div className={withTheme("confirm-modal-title")}>{title}</div>
				<div className={withTheme("confirm-modal-description")}>{description}</div>

				<div
					style={{
						width: "100%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						gap: 12,
						marginTop: 120,
					}}
				>
					{!hideBackButton && (
						<Button style={{ minWidth: 120 }} type="secondary" onClick={onClose}>
							{t("back")}
						</Button>
					)}
					<Button
						style={{
							minWidth: 160,
							width: hideBackButton ? "100%" : "",
							...(primaryActionStyle || {}),
						}}
						isLoading={isActionLoading}
						onClick={onPrimaryActionClick}
					>
						{primaryActionLabel}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default ConfirmationModal;
