import { useContext, useState } from "react";
import Button from "../common/Button";
import NotificationToast from "../common/NotificationToast";
import ThemeContext from "../../context/theme/ThemeContext";
import { useTranslation } from "react-i18next";
import DeclarationsContext from "../../context/declarations/DeclarationsContext";
import Modal from "./Modal";
import Input from "../common/Input";

const CancelDeclarationModal = ({ isOpen, onClose }) => {
	const { withTheme } = useContext(ThemeContext);
	const { state, cancelDeclaration } = useContext(DeclarationsContext);
	const [cancelReason, setCancelReason] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [toast, setToast] = useState({ text: "", variant: "" });
	const { t } = useTranslation();

	const handleCancel = async () => {
		try {
			setIsLoading(true);

			await cancelDeclaration(state.declaration._id, cancelReason);

			setToast({ text: t("declarationCancelSuccess"), variant: "success" });
		} catch (e) {
			console.log(e.response.data);

			setToast({ text: t("declarationCancelFailure"), variant: "error" });
		}

		setIsLoading(false);
		setCancelReason("");
		onClose();
	};

	return (
		<>
			<NotificationToast isOpen={Boolean(toast.text)} onClose={() => setToast("")} text={toast} />
			<Modal isOpen={isOpen} label={t("cancelDeclaration")} style={{ width: 450 }} onClose={onClose}>
				<Input
					label={t("cancelReason")}
					value={cancelReason}
					className={withTheme("input")}
					onChange={(e) => setCancelReason(e.target.value)}
				/>

				<div
					style={{
						display: "flex",
						alignItems: "center",
						width: "fit-content",
						marginLeft: "auto",
						gap: 12,
						marginTop: 32,
					}}
				>
					<Button isDisabled={isLoading} type="secondary" onClick={onClose}>
						{t("back")}
					</Button>
					<Button isLoading={isLoading} onClick={handleCancel}>
						{t("cancelDeclaration")}
					</Button>
				</div>
			</Modal>
		</>
	);
};

export default CancelDeclarationModal;
