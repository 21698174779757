const currencies = [
    {
        "key": "DEFAULT",
        "value": "Please Select"
    },
    {
        "key": "AED",
        "value": "AED"
    },
    {
        "key": "AFN",
        "value": "AFN"
    },
    {
        "key": "ALL",
        "value": "ALL"
    },
    {
        "key": "AMD",
        "value": "AMD"
    },
    {
        "key": "ANG",
        "value": "ANG"
    },
    {
        "key": "AOA",
        "value": "AOA"
    },
    {
        "key": "ARS",
        "value": "ARS"
    },
    {
        "key": "AUD",
        "value": "AUD"
    },
    {
        "key": "AWG",
        "value": "AWG"
    },
    {
        "key": "AZN",
        "value": "AZN"
    },
    {
        "key": "BAM",
        "value": "BAM"
    },
    {
        "key": "BBD",
        "value": "BBD"
    },
    {
        "key": "BDT",
        "value": "BDT"
    },
    {
        "key": "BGN",
        "value": "BGN"
    },
    {
        "key": "BHD",
        "value": "BHD"
    },
    {
        "key": "BIF",
        "value": "BIF"
    },
    {
        "key": "BMD",
        "value": "BMD"
    },
    {
        "key": "BND",
        "value": "BND"
    },
    {
        "key": "BOB",
        "value": "BOB"
    },
    {
        "key": "BRL",
        "value": "BRL"
    },
    {
        "key": "BSD",
        "value": "BSD"
    },
    {
        "key": "BWP",
        "value": "BWP"
    },
    {
        "key": "BYN",
        "value": "BYN"
    },
    {
        "key": "BZD",
        "value": "BZD"
    },
    {
        "key": "CAD",
        "value": "CAD"
    },
    {
        "key": "CDF",
        "value": "CDF"
    },
    {
        "key": "CHF",
        "value": "CHF"
    },
    {
        "key": "CLP",
        "value": "CLP"
    },
    {
        "key": "CNY",
        "value": "CNY"
    },
    {
        "key": "COP",
        "value": "COP"
    },
    {
        "key": "CRC",
        "value": "CRC"
    },
    {
        "key": "CUP",
        "value": "CUP"
    },
    {
        "key": "CVE",
        "value": "CVE"
    },
    {
        "key": "CZK",
        "value": "CZK"
    },
    {
        "key": "DJF",
        "value": "DJF"
    },
    {
        "key": "DKK",
        "value": "DKK"
    },
    {
        "key": "DOP",
        "value": "DOP"
    },
    {
        "key": "DZD",
        "value": "DZD"
    },
    {
        "key": "EGP",
        "value": "EGP"
    },
    {
        "key": "ERN",
        "value": "ERN"
    },
    {
        "key": "ETB",
        "value": "ETB"
    },
    {
        "key": "EUR",
        "value": "EUR"
    },
    {
        "key": "FJD",
        "value": "FJD"
    },
    {
        "key": "FKP",
        "value": "FKP"
    },
    {
        "key": "GBP",
        "value": "GBP"
    },
    {
        "key": "GEL",
        "value": "GEL"
    },
    {
        "key": "GHS",
        "value": "GHS"
    },
    {
        "key": "GIP",
        "value": "GIP"
    },
    {
        "key": "GMD",
        "value": "GMD"
    },
    {
        "key": "GNF",
        "value": "GNF"
    },
    {
        "key": "GTQ",
        "value": "GTQ"
    },
    {
        "key": "GYD",
        "value": "GYD"
    },
    {
        "key": "HKD",
        "value": "HKD"
    },
    {
        "key": "HNL",
        "value": "HNL"
    },
    {
        "key": "HRK",
        "value": "HRK"
    },
    {
        "key": "HTG",
        "value": "HTG"
    },
    {
        "key": "HUF",
        "value": "HUF"
    },
    {
        "key": "IDR",
        "value": "IDR"
    },
    {
        "key": "ILS",
        "value": "ILS"
    },
    {
        "key": "INR",
        "value": "INR"
    },
    {
        "key": "IQD",
        "value": "IQD"
    },
    {
        "key": "IRR",
        "value": "IRR"
    },
    {
        "key": "ISK",
        "value": "ISK"
    },
    {
        "key": "JMD",
        "value": "JMD"
    },
    {
        "key": "JOD",
        "value": "JOD"
    },
    {
        "key": "JPY",
        "value": "JPY"
    },
    {
        "key": "KES",
        "value": "KES"
    },
    {
        "key": "KGS",
        "value": "KGS"
    },
    {
        "key": "KHR",
        "value": "KHR"
    },
    {
        "key": "KMF",
        "value": "KMF"
    },
    {
        "key": "KPW",
        "value": "KPW"
    },
    {
        "key": "KRW",
        "value": "KRW"
    },
    {
        "key": "KWD",
        "value": "KWD"
    },
    {
        "key": "KYD",
        "value": "KYD"
    },
    {
        "key": "KZT",
        "value": "KZT"
    },
    {
        "key": "LAK",
        "value": "LAK"
    },
    {
        "key": "LBP",
        "value": "LBP"
    },
    {
        "key": "LKR",
        "value": "LKR"
    },
    {
        "key": "LRD",
        "value": "LRD"
    },
    {
        "key": "LYD",
        "value": "LYD"
    },
    {
        "key": "MAD",
        "value": "MAD"
    },
    {
        "key": "MDL",
        "value": "MDL"
    },
    {
        "key": "MGA",
        "value": "MGA"
    },
    {
        "key": "MKD",
        "value": "MKD"
    },
    {
        "key": "MMK",
        "value": "MMK"
    },
    {
        "key": "MNT",
        "value": "MNT"
    },
    {
        "key": "MOP",
        "value": "MOP"
    },
    {
        "key": "MRO",
        "value": "MRO"
    },
    {
        "key": "MUR",
        "value": "MUR"
    },
    {
        "key": "MVR",
        "value": "MVR"
    },
    {
        "key": "MWK",
        "value": "MWK"
    },
    {
        "key": "MXN",
        "value": "MXN"
    },
    {
        "key": "MYR",
        "value": "MYR"
    },
    {
        "key": "MZN",
        "value": "MZN"
    },
    {
        "key": "NAD",
        "value": "NAD"
    },
    {
        "key": "NGN",
        "value": "NGN"
    },
    {
        "key": "NIO",
        "value": "NIO"
    },
    {
        "key": "NOK",
        "value": "NOK"
    },
    {
        "key": "NPR",
        "value": "NPR"
    },
    {
        "key": "NZD",
        "value": "NZD"
    },
    {
        "key": "OMR",
        "value": "OMR"
    },
    {
        "key": "PAB",
        "value": "PAB"
    },
    {
        "key": "PEN",
        "value": "PEN"
    },
    {
        "key": "PGK",
        "value": "PGK"
    },
    {
        "key": "PHP",
        "value": "PHP"
    },
    {
        "key": "PKR",
        "value": "PKR"
    },
    {
        "key": "PLN",
        "value": "PLN"
    },
    {
        "key": "PYG",
        "value": "PYG"
    },
    {
        "key": "QAR",
        "value": "QAR"
    },
    {
        "key": "RON",
        "value": "RON"
    },
    {
        "key": "RSD",
        "value": "RSD"
    },
    {
        "key": "RUB",
        "value": "RUB"
    },
    {
        "key": "RWF",
        "value": "RWF"
    },
    {
        "key": "SAR",
        "value": "SAR"
    },
    {
        "key": "SBD",
        "value": "SBD"
    },
    {
        "key": "SCR",
        "value": "SCR"
    },
    {
        "key": "SDG",
        "value": "SDG"
    },
    {
        "key": "SEK",
        "value": "SEK"
    },
    {
        "key": "SGD",
        "value": "SGD"
    },
    {
        "key": "SHP",
        "value": "SHP"
    },
    {
        "key": "SLL",
        "value": "SLL"
    },
    {
        "key": "SOS",
        "value": "SOS"
    },
    {
        "key": "SRD",
        "value": "SRD"
    },
    {
        "key": "SSP",
        "value": "SSP"
    },
    {
        "key": "STD",
        "value": "STD"
    },
    {
        "key": "SVC",
        "value": "SVC"
    },
    {
        "key": "SYP",
        "value": "SYP"
    },
    {
        "key": "SZL",
        "value": "SZL"
    },
    {
        "key": "THB",
        "value": "THB"
    },
    {
        "key": "TJS",
        "value": "TJS"
    },
    {
        "key": "TMT",
        "value": "TMT"
    },
    {
        "key": "TND",
        "value": "TND"
    },
    {
        "key": "TOP",
        "value": "TOP"
    },
    {
        "key": "TRY",
        "value": "TRY"
    },
    {
        "key": "TTD",
        "value": "TTD"
    },
    {
        "key": "TWD",
        "value": "TWD"
    },
    {
        "key": "TZS",
        "value": "TZS"
    },
    {
        "key": "UAH",
        "value": "UAH"
    },
    {
        "key": "USD",
        "value": "USD"
    },
    {
        "key": "UYU",
        "value": "UYU"
    },
    {
        "key": "UZS",
        "value": "UZS"
    },
    {
        "key": "VEF",
        "value": "VEF"
    },
    {
        "key": "VND",
        "value": "VND"
    },
    {
        "key": "VUV",
        "value": "VUV"
    },
    {
        "key": "WST",
        "value": "WST"
    },
    {
        "key": "XAF",
        "value": "XAF"
    },
    {
        "key": "XCD",
        "value": "XCD"
    },
    {
        "key": "XOF",
        "value": "XOF"
    },
    {
        "key": "XPF",
        "value": "XPF"
    },
    {
        "key": "YER",
        "value": "YER"
    },
    {
        "key": "YUM",
        "value": "YUM"
    },
    {
        "key": "ZAR",
        "value": "ZAR"
    },
    {
        "key": "ZMW",
        "value": "ZMW"
    },
    {
        "key": "ZWL",
        "value": "ZWL"
    },
    {
        "key": "UGX",
        "value": "UGX"
    }
];

export default currencies;