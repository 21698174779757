import ConstantsBasedPullDownMenu from "../../ConstantsBasedPulDownMenu/ConstantsBasedPullDownMenu";
import ConstantsBasedTextInput from "../../ConstantsBasedTextInput/ConstantsBasedTextInput";
import { useContext, useMemo } from "react";
import ThemeContext from "../../../../context/theme/ThemeContext";
import { parseElementConditions } from "../utils";

const TableInputs = (props) => {
	const { withTheme } = useContext(ThemeContext);
	const {
		errors,
		setErrors,
		componentElements,
		inputValues,
		setInputValues,
		disabled,
		readOnlyItems,
	} = props;

	const elements = useMemo(() => {
		let elements = [];

		componentElements.forEach((element) => {
			if (element.conditions) {
				elements.push({
					...element,
					...parseElementConditions(element.conditions, inputValues),
				});
			} else {
				elements.push(element);
			}
		});
		return elements;
	}, [componentElements, inputValues]);

	const handleChange = (name, value) => {
		if (errors[name]) {
			delete errors[name];
			setErrors({ ...errors });
		}

		setInputValues({
			...inputValues,
			[name]: value,
		});
	};

	return (
		<div className={withTheme("table-inputs-container")}>
			{elements.map((element, index) => (
				<Input
					key={index}
					element={element}
					value={inputValues[element.elementName]}
					error={errors[element.elementName]}
					readOnlyItems={readOnlyItems}
					disabled={disabled}
					onChange={handleChange}
				/>
			))}
		</div>
	);
};

const Input = ({ element, value, readOnlyItems, disabled, error, onChange }) => {
	return (
		<div style={{ width: "calc(50% - 20px)" }}>
			{!element.pullDownMenu ? (
				<ConstantsBasedTextInput
					inputStyle={{ width: "100%" }}
					element={element}
					value={value}
					onChange={onChange}
					error={error}
					disabled={disabled}
				/>
			) : (
				<ConstantsBasedPullDownMenu
					inputStyle={{ width: "100%" }}
					element={element}
					value={value}
					error={error}
					onChange={onChange}
					excludedOptions={
						readOnlyItems?.map(
							(readOnlyItem) => readOnlyItem[element.elementName]
						) || []
					}
					disabled={disabled}
				/>
			)}
		</div>
	);
};

export default TableInputs;
