const commodityCodeNationalAdditionalCode = [
	{ key: "X301", value: "Low Alcohol - not exc 1.2%" },
	{ key: "X311", value: "Beer less than 3.5% abv" },
	{ key: "X312", value: "Cider less than 3.5% abv" },
	{ key: "X313", value: "Wine less than 3.5% abv" },
	{ key: "X314", value: "Other fermented products less than 3.5% abv" },
	{ key: "X315", value: "Spirits less than 3.5% abv" },
	{ key: "X321", value: "Beer at least 3.5% abv but less than 8.5% abv" },
	{
		key: "X322",
		value: "Cider at least 3.5% abv but less than 8.5% abv & sparkling cider at least 3.5% abv not exceeding 5.5% abv",
	},
	{ key: "X323", value: "Wine at least 3.5% abv but less than 8.5% abv" },
	{
		key: "X324",
		value: "Other fermented products at least 3.5% abv but less than 8.5% abv & sparkling cider exceeding 5.5% abv but less than 8.5% abv",
	},
	{ key: "X325", value: "Spirits at least 3.5% abv but less than 8.5% abv" },
	{ key: "X331", value: "Beer at least 8.5% abv but not exceeding 22% abv" },
	{ key: "X333", value: "Wine at least 8.5% abv but not exceeding 22% abv" },
	{ key: "X334", value: "Other fermented products at least 8.5% abv but not exceeding 22% abv" },
	{ key: "X335", value: "Spirits at least 8.5% abv but not exceeding 22% abv" },
	{ key: "X341", value: "Beer exceeding 22% abv" },
	{ key: "X343", value: "Wine exceeding 22% abv" },
	{ key: "X344", value: "Other fermented products exceeding 22% abv" },
	{ key: "X345", value: "Spirits exceeding 22% abv" },
	{ key: "X351", value: "Beer less than 3.5% abv & eligible for Draught Relief" },
	{ key: "X352", value: "Cider less than 3.5% abv & eligible for Draught Relief" },
	{ key: "X353", value: "Wine less than 3.5% abv & eligible for Draught Relief" },
	{ key: "X354", value: "Other fermented products less than 3.5% abv & eligible for Draught Relief" },
	{ key: "X355", value: "Spirits less than 3.5% abv & eligible for Draught Relief" },
	{ key: "X356", value: "Beer at least 3.5% abv but less than 8.5% abv & eligible for Draught Relief" },
	{
		key: "X357",
		value: "Cider at least 3.5% abv but less than 8.5% abv & Sparkling cider at least 3.5% abv but less than not exceeding 5.5% abv & eligible for Draught Relief",
	},
	{ key: "X358", value: "Wine at least 3.5% abv but less than 8.5% abv & eligible for Draught Relief" },
	{
		key: "X359",
		value: "Other fermented products at least 3.5% abv but less than 8.5% abv & Sparkling cider exceeding 5.5% abv but less than 8.5% abv & eligible for Draught Relief",
	},
	{ key: "X360", value: "Spirits at least 3.5% abv but less than 8.5% abv & eligible for Draught Relief" },
	{ key: "X361", value: "Beer less than 3.5% abv & eligible for Small Producer Relief" },
	{ key: "X362", value: "Cider less than 3.5% abv & eligible for Small Producer Relief" },
	{ key: "X363", value: "Wine less than 3.5% abv & eligible for Small Producer Relief" },
	{ key: "X364", value: "Other fermented products less than 3.5% abv & eligible for Small Producer Relief" },
	{ key: "X365", value: "Spirits less than 3.5% abv & eligible for Small Producer Relief" },
	{ key: "X366", value: "Beer at least 3.5% abv but less than 8.5% abv & eligible for Small Producer Relief" },
	{
		key: "X367",
		value: "Cider at least 3.5% abv but less than 8.5% abv & Sparkling cider at least 3.5% abv but less than not exceeding 5.5% abv & eligible for Small Producer Relief",
	},
	{ key: "X368", value: "Wine at least 3.5% abv but less than 8.5% abv & eligible for Small Producer Relief" },
	{
		key: "X369",
		value: "Other fermented products at least 3.5% abv but less than 8.5% abv & Sparkling cider exceeding 5.5% abv but less than 8.5% abv & eligible for Small Producer Relief",
	},
	{ key: "X370", value: "Spirits at least 3.5% abv but less than 8.5% abv & eligible for Small Producer Relief" },
	{ key: "X371", value: "Beer less than 3.5% abv & eligible for Small Producer Relief and Draught Relief" },
	{ key: "X372", value: "Cider less than 3.5% abv & eligible for Small Producer Relief and Draught Relief" },
	{ key: "X373", value: "Wine less than 3.5% abv & eligible for Small Producer Relief and Draught Relief" },
	{
		key: "X374",
		value: "Other fermented products less than 3.5% abv & eligible for Small Producer Relief and Draught Relief",
	},
	{ key: "X375", value: "Spirits less than 3.5% abv & eligible for Small Producer Relief and Draught Relief" },
	{
		key: "X376",
		value: "Beer at least 3.5% abv but less than 8.5% abv & eligible for Small Producer Relief and Draught Relief",
	},
	{
		key: "X377",
		value: "Cider at least 3.5% abv but less than 8.5% abv & Sparkling cider at least 3.5% abv but less than not exceeding 5.5% abv & eligible for Small Producer Relief and Draught Relief",
	},
	{
		key: "X378",
		value: "Wine at least 3.5% abv but less than 8.5% abv & eligible for Small Producer Relief and Draught Relief",
	},
	{
		key: "X379",
		value: "Other fermented products at least 3.5% abv but less than 8.5% abv & Sparkling cider exceeding 5.5% abv but less than 8.5% abv & eligible for Small Producer Relief and Draught Relief",
	},
	{
		key: "X380",
		value: "Spirits at least 3.5% abv but less than 8.5% abv & eligible for Small Producer Relief and Draught Relief",
	},
	{ key: "X639", value: "UK tax type 639, Snuff" },
];

export default commodityCodeNationalAdditionalCode;
