import ConfirmationModal from "./ConfirmationModal";
import warningSymbol from "../../assets/images/warning-symbol.png";
import { useTranslation } from "react-i18next";

const ErrorModal = ({ isOpen, onClose }) => {
	const { t } = useTranslation();

	return (
		<ConfirmationModal
			hideBackButton
			isOpen={isOpen}
			image={warningSymbol}
			title={t("errorOccured")}
			description={t("somethingWentWrong")}
			primaryActionLabel={t("ok")}
			onPrimaryActionClick={onClose}
			onClose={onClose}
		/>
	);
};

export default ErrorModal;
