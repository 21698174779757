import { useContext } from "react";
import ThemeContext from "../../../../context/theme/ThemeContext";
import { ListItemSubTitle, ListItemTitle } from "../../../common/ListItem";
import { getDeclarationLabel } from "../../../../helpers";
import DeclarationStatusTag from "../../declarations/DeclarationsList/ListItem/DeclarationStatusTag";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { DECLARATION_ID_QUERY_PARAM } from "../../../../constants/GlobalConstants";

const FileAssociatedDeclaration = ({ declaration }) => {
	const { withTheme } = useContext(ThemeContext);
	const history = useHistory();

	const handleClick = () => {
		history.push(`/declarations?${DECLARATION_ID_QUERY_PARAM}=${declaration._id}`);
	};

	return (
		<div className={withTheme("file-associated-dec")} onClick={handleClick}>
			<div style={{ display: "flex", alignItems: "center" }}>
				<ListItemTitle>{getDeclarationLabel(declaration)}</ListItemTitle>

				<div style={{ width: "fit-content", marginLeft: "auto" }}>
					<DeclarationStatusTag declaration={declaration} />
				</div>
			</div>

			<ListItemSubTitle style={{ fontSize: 12, marginTop: 4 }}>
				{declaration.description && (
					<>
						<span>{declaration.description}</span>
						<br />
					</>
				)}
				{moment(declaration.createdAt || Number(declaration.creationTime)).format(
					"DD MMM, hh:mm A"
				)}
			</ListItemSubTitle>
		</div>
	);
};

export default FileAssociatedDeclaration;
