import React, { useContext, useEffect, useMemo, useState } from "react";
import DeclarationsContext from "../../../context/declarations/DeclarationsContext";
import validationElements from "../../../constants/validation";
import ThemeContext from "../../../context/theme/ThemeContext";
import CustomSections from "./CustomSections";
import ValidationInput from "./ValidationInput";
import { getQueryParam } from "../../../router/utils";
import Highlighter from "react-highlight-words";
import ImportExportButtons from "./ImportExportButtons";

const InputsSection = (props) => {
	const { setElementValue, setItemElementValue, state, setHasUnsavedChanges, isDeclarationReadOnly } =
		useContext(DeclarationsContext);
	const [highlighted, setHighlighted] = useState(false);
	const { withTheme } = useContext(ThemeContext);
	const { declaration } = state || {};
	const { elements, section, title, styleName, actions, childSections, itemIndex, columns, SectionActions } =
		props;
	const [sectionState, setSectionState] = useState({});

	const readOnly = useMemo(() => isDeclarationReadOnly(), [state.declaration]);

	const hasImportExportButtons = useMemo(
		() => Boolean(elements?.find((element) => element.type === "multiline")),
		[elements]
	);

	const handleChange = (name, value) => {
		let updatedState = {
			...sectionState,
			[name]: value,
		};

		setHasUnsavedChanges(true);

		if (typeof itemIndex === "number") {
			setItemElementValue(itemIndex, name, value);
		} else {
			setElementValue(name, value);
		}

		setSectionState({ ...updatedState });
	};

	/**
	 * Since this component can be used in either a regular declaration or a declaration item,
	 * if an item index is provided, get the section state from this specific item,
	 * otherwise just get the state from the provided declaration page/section.
	 */
	const getSectionValues = () => {
		let elementsData = {};
		if (typeof itemIndex === "number") {
			elementsData = declaration.data.declarationItems[itemIndex] || {};
		} else {
			elementsData = { ...declaration.data };
		}

		let sectionState = {};

		elements?.forEach((element) => {
			if (element.elementName && elementsData[element.elementName]) {
				sectionState[element.elementName] = elementsData[element.elementName];
			} else if (element.type === "row") {
				element.elements.forEach((element) => {
					if (element.elementName && elementsData[element.elementName]) {
						sectionState[element.elementName] = elementsData[element.elementName];
					}
				});
			}
		});

		return sectionState;
	};

	useEffect(() => {
		setSectionState({ ...getSectionValues() });
	}, [declaration?._id, itemIndex]);

	// useEffect(() => {
	// 	// Elements data will either get its values from the root declaration object if section is in header,
	// 	// or from the declaration item object if the section is in an item
	// 	setSectionState({ ...getSectionValues() });
	// }, [itemIndex, declaration]);

	useEffect(() => {
		const highlight = () => {
			setHighlighted(elements?.find((elem) => elem.elementName === getQueryParam("element")));
		};

		highlight();
		const eventHandler = () => {
			highlight();
		};

		window.addEventListener("popstate", eventHandler);

		return function cleanup() {
			window.removeEventListener("popstate", eventHandler);
		};
	}, []);

	if (!elements) {
		return <CustomSections section={section} itemIndex={itemIndex} />;
	}

	return (
		<div className={`${withTheme(styleName)} ${withTheme("inputs-section")}`}>
			<div style={{ display: "flex", marginBottom: 20, alignItems: "center" }}>
				<Highlighter
					highlightStyle={{ backgroundColor: "yellow" }}
					className={withTheme("inputs-section-title")}
					searchWords={highlighted ? [title] : []}
					autoEscape={true}
					textToHighlight={title}
				/>

				{(hasImportExportButtons || SectionActions) && (
					<div
						style={{
							width: "fit-content",
							marginLeft: "auto",
							display: "flex",
							alignItems: "center",
							gap: 10,
						}}
					>
						{SectionActions}

						{hasImportExportButtons && (
							<ImportExportButtons
								value={sectionState[elements[0].elementName]}
								elementName={elements[0].elementName}
								onImport={(value) =>
									handleChange(elements[0].elementName, value)
								}
							/>
						)}
					</div>
				)}
			</div>

			<div style={{ display: "flex", flexWrap: "wrap", gap: 20 }}>
				{elements.map((element, index) => {
					return (
						<SectionElement
							element={element}
							sectionState={sectionState}
							onChange={handleChange}
							key={index}
							columns={columns}
							readOnly={readOnly}
						/>
					);
				})}
			</div>

			<div style={{ marginTop: 16, marginRight: "auto", width: "fit-content" }}>
				{actions?.map((action, index) => (
					<button key={index} type="button" className="btn btn-sm btn-primary">
						{action.label}
					</button>
				))}
			</div>

			{childSections?.map((section) => (
				<InputsSection {...section} />
			))}
		</div>
	);
};

const SectionElement = ({ element, sectionState, onChange, columns, readOnly }) => {
	const validationElement = useMemo(
		() => validationElements.find((tmpElement) => tmpElement.elementName === element.elementName),
		[]
	);

	if (element.type === "row") {
		return (
			<div className="d-flex flex-wrap" style={{ gap: 40, width: "100%" }}>
				{element.elements.map((nestedElement, index) => (
					<SectionElement
						element={nestedElement}
						sectionState={sectionState}
						onChange={onChange}
						key={index}
						columns={element.elements.length}
						readOnly={readOnly}
					/>
				))}
			</div>
		);
	}

	return (
		<div
			style={{
				...(element.style || {}),
				width: element.style?.width || (columns ? `calc(${100 / columns}% - 20px)` : "100%"),
			}}
		>
			<ValidationInput
				onChange={onChange}
				groupingElement={element}
				value={sectionState?.[element.elementName]}
				element={validationElement}
				readOnly={readOnly}
			/>
		</div>
	);
};

export default InputsSection;
