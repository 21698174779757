import { useTranslation } from "react-i18next";

const TablePagination = (props) => {
    const { t } = useTranslation();
    const {
        items, setSelectedPage,
        selectedPage
    } = props;

    if (items.length <= 10)
        return <></>;

    return (
        <div style={{ width: 'fit-content', margin: '12px auto 0px auto' }}>
            <nav>
                <ul className="pagination">
                    {/* Decrement the selected page by 1 until 0 is reached */}
                    <li
                        onClick={() => setSelectedPage(selectedPage > 0 ? selectedPage - 1 : 0)}
                        className="page-item">
                        <div className="page-link">{t('previous')}</div>
                    </li>
                    <li
                        onClick={() => setSelectedPage(0)}
                        className="page-item">
                        <div
                            className="page-link"
                            style={{ backgroundColor: selectedPage === 0 ? '#e9ecef' : '' }}
                        >{1}</div>
                    </li>

                    {/* Get the number of pages by dividing the items array length by 10 */}
                    {/* Should appear only when the table has more than 10 elements */}
                    {[...Array(Math.floor(items.length / 10)).keys()].map((page) => (
                        <li
                            onClick={() => setSelectedPage(page + 1)}
                            className="page-item">
                            <div
                                style={{ backgroundColor: selectedPage === page + 1 ? '#e9ecef' : '' }}
                                className="page-link">{page + 2}
                            </div>
                        </li>
                    ))}
                    {/* Increment selected page by 1 until the highest page is recahed */}
                    <li
                        onClick={() => setSelectedPage(
                            selectedPage < Math.floor(items.length / 10) ?
                                selectedPage + 1 : Math.floor(items.length / 10)
                        )}
                        className="page-item">
                        <div className="page-link">{t('next')}</div>
                    </li>
                </ul>
            </nav>
        </div>
    )
};

export default TablePagination