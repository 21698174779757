import { useTranslation } from "react-i18next";
import Button from "../../../common/Button";
import { Form } from "react-bootstrap";

const inputLabelStyles = { fontSize: 14, marginBottom: 8, marginTop: 16, fontWeight: 500 };
const disabledStyles = { pointerEvents: "none", opacity: 0.5 };

const NewDeclarationModalInputs = (props) => {
	const { inputValues, onInputChange, isLoading, onSubmitClick, onCancelClick } = props;
	const { t } = useTranslation();
	const { declarationType, importType } = inputValues;

	const getRadioButtonProps = (inputName, radioButtonId) => {
		return {
			name: inputName,
			type: "radio",
			label: t(radioButtonId),
			style: { fontSize: 12 },
			checked: inputValues[inputName] === radioButtonId,
			onChange: () => onInputChange(inputName, radioButtonId),
		};
	};

	return (
		<div>
			<div style={{ ...inputLabelStyles, marginTop: 0 }}>{t("declarationTypeQuestion")}</div>

			<Form.Check {...getRadioButtonProps("declarationType", "import")} />
			<Form.Check {...getRadioButtonProps("declarationType", "export")} />
			<div style={declarationType !== "import" ? disabledStyles : {}}>
				<div style={inputLabelStyles}>{t("importTypeQuestion")}</div>

				<Form.Check {...getRadioButtonProps("importType", "no")} />
				<Form.Check {...getRadioButtonProps("importType", "postponedVatAccounting")} />
				<Form.Check {...getRadioButtonProps("importType", "importParcel")} />
				<Form.Check {...getRadioButtonProps("importType", "finalSupplementary")} />
			</div>

			<div style={declarationType !== "import" || importType !== "no" ? disabledStyles : {}}>
				<div style={inputLabelStyles}>{t("protocolQuestion")}</div>

				<Form.Check {...getRadioButtonProps("protocol", "no")} />

				<Form.Check {...getRadioButtonProps("protocol", "rowToNi")} />

				<Form.Check {...getRadioButtonProps("protocol", "rowToGb")} />

				<Form.Check {...getRadioButtonProps("protocol", "gbToNi")} />
			</div>

			<div style={declarationType !== "import" ? disabledStyles : {}}>
				<div style={inputLabelStyles}>{t("goodsAtRiskQuestion")}</div>

				<Form.Check {...getRadioButtonProps("risk", "no")} />

				<Form.Check {...getRadioButtonProps("risk", "yes")} />

				{/* <Select
                    name='risk'
                    onChange={({ value }) => onInputChange('risk', value)}
                    value={{ label: t(inputValues.risk), value: inputValues.risk }}
                    options={risks.map(risk => ({ label: t(risk), value: risk }))}
                /> */}
			</div>

			<div
				style={{
					width: "fit-content",
					marginLeft: "auto",
					marginTop: 16,
					display: "flex",
					gap: 8,
				}}
			>
				<Button type="secondary" onClick={onCancelClick}>
					{t("cancel")}
				</Button>
				<Button isLoading={isLoading} onClick={onSubmitClick}>
					{t("createNewDeclaration")}
				</Button>
			</div>
		</div>
	);
};

export default NewDeclarationModalInputs;
