import React, { useReducer } from "react";
import { SET_THEME } from "../types";
import ThemeContext from './ThemeContext';
import ThemeReducer from "./ThemeReducer";
import { LS_THEME_KEY } from "../../constants/GlobalConstants";


export let themeInitialState = {
    name: localStorage.getItem(LS_THEME_KEY) || 'default'
};

// Load the initial state from localstorage if it exists
if (localStorage.getItem(LS_THEME_KEY)) {
    themeInitialState = {
        ...JSON.parse(localStorage.getItem(LS_THEME_KEY))
    };
};

export const themeOptions = [
    { name: 'Default', value: 'default' },
    // { name: 'Dark', value: 'dark' },
    // { name: 'MoveOn', value: 'moveOn' },
    // { name: 'PastelOrange', value: 'pastelOrange' },
    // { name: 'PastelBlue', value: 'pastelBlue' },
    // { name: 'PastelGreen', value: 'pastelGreen' }
]

const ThemeState = (props) => {
    const [state, dispatch] = useReducer(ThemeReducer, themeInitialState);

    // Add the currently selected them to the end of a className string
    const withTheme = (className) => {
        if (state.name === 'default') {
            return `${className}`;
        };

        return `${className} ${className}-${state.name}`;
    };

    const setTheme = (themeName) => {
        localStorage.setItem(LS_THEME_KEY, themeName);

        dispatch({
            type: SET_THEME,
            payload: themeName
        });
    };

    return (
        <ThemeContext.Provider value={{
            theme: state,
            setTheme,
            withTheme
        }}>
            {props.children}
        </ThemeContext.Provider>
    )
};

export default ThemeState