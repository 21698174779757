import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const FilterOption = ({ option, isSelected, onFilterClick }) => {
	const { t } = useTranslation();

	return (
		<Form.Check
			checked={Boolean(isSelected)}
			type="radio"
			name={option}
			label={t(option)}
			onClick={() => onFilterClick(option)}
		/>
	);
};

export default FilterOption;
