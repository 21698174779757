import { useState } from "react";
import { changeUserPassword } from "../../../api/settings";
import Input from "../../../components/common/Input";
import { useTranslation } from "react-i18next";
import Button from "../../../components/common/Button";
import { PiFloppyDisk } from "react-icons/pi";
import ErrorText from "../../../components/common/ErrorText";
import NotificationToast from "../../../components/common/NotificationToast";

const DEFAULT_PAYLOAD = { oldPassword: "", newPassword: "", newPasswordConfirm: "" };

const MyAccountTab = () => {
	const [payload, setPayload] = useState(DEFAULT_PAYLOAD);
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const [isSuccessToastOpen, setIsSuccessToastOpen] = useState(false);
	const [error, setError] = useState();
	const { t } = useTranslation();

	const handleInputChange = (name, value) => {
		setError("");

		setPayload({
			...payload,
			[name]: value,
		});
	};

	const handleSaveClick = async () => {
		if (!payload.oldPassword || !payload.newPassword) {
			return;
		}

		const { oldPassword, newPassword, newPasswordConfirm } = payload;

		if (newPassword !== newPasswordConfirm) {
			return setError(t("passwordConfirmDoesNotMatch"));
		}

		setIsSaveLoading(true);
      setError('');

		try {
			await changeUserPassword(oldPassword, newPassword);
			setPayload(DEFAULT_PAYLOAD);

			setIsSuccessToastOpen(true);
		} catch (e) {
         console.log(e)
			setError(e.response.data.message);
		}

		setIsSaveLoading(false);
	};

	const handleResetClick = () => {
		setPayload(DEFAULT_PAYLOAD);
		setError("");
	};

	return (
		<div>
			<NotificationToast
				isOpen={isSuccessToastOpen}
				text={t("changePasswordSuccess")}
				variant="success"
				onClose={() => setIsSuccessToastOpen(false)}
			/>

			<div style={{ display: "flex", flexDirection: "column", gap: 16, maxWidth: 500 }}>
				<Input
					label={t("oldPassword")}
					type="password"
					value={payload.oldPassword}
					onChange={(e) => handleInputChange("oldPassword", e.target.value)}
				/>

				<Input
					type="password"
					label={t("newPassword")}
					value={payload.newPassword}
					onChange={(e) => handleInputChange("newPassword", e.target.value)}
				/>

				<Input
					label={t("confirmNewPassword")}
					type="password"
					value={payload.newPasswordConfirm}
					onChange={(e) => handleInputChange("newPasswordConfirm", e.target.value)}
				/>

				{error && <ErrorText>{error}</ErrorText>}
			</div>

			<div
				style={{
					width: "fit-content",
					marginLeft: "auto",
					display: "flex",
					alignItems: "center",
					gap: 12,
					marginTop: 52,
				}}
			>
				<Button type="secondary" onClick={handleResetClick} disabled={isSaveLoading}>
					{t("reset")}
				</Button>

				<Button
					isDisabled={!payload.oldPassword || !payload.newPassword}
					isLoading={isSaveLoading}
					onClick={handleSaveClick}
					LeftIcon={PiFloppyDisk}
				>
					{t("saveNewPassword")}
				</Button>
			</div>
		</div>
	);
};

export default MyAccountTab;
