import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ThemeContext from "../../../../context/theme/ThemeContext";
import ElementSearchInput from "../../../common/ElementSearchInput";
import ValidationErrors from "./ValidationErrors";
import BackButton from "../../../common/BackButton";
import DeclarationActions from "../DeclarationActions/DeclarationActions";
import DeclarationsContext from "../../../../context/declarations/DeclarationsContext";
import Input from "../../../common/Input";
import { debounce } from "lodash";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getDeclarationLabel } from "../../../../helpers";
import DeclarationStatusTag from "../DeclarationsList/ListItem/DeclarationStatusTag";

const DeclarationPageHeader = (props) => {
	const { withTheme } = useContext(ThemeContext);
	const { state, updateDeclaration } = useContext(DeclarationsContext);
	const [description, setDescription] = useState("");
	const { t } = useTranslation();
	const history = useHistory();
	const { loadingAction, setLoadingAction, selectedTabIndex } = props;

	const debouncedDescUpdate = useCallback(
		debounce((description) => {
			updateDeclaration(state.declaration._id, {
				description,
			});
		}, 300),
		[state.declaration?._id]
	);

	const handleDescChange = (e) => {
		const { value } = e.target;

		setDescription(value);
		debouncedDescUpdate(value);
	};

	useEffect(() => {
		setDescription(state.declaration?.description || "");
	}, [state.declaration?._id]);

	if (!state.declaration?.isFullDataLoaded || state.loadingDeclarationId) {
		return null;
	}

	return (
		<div>
			<div className="d-flex align-items-center" style={{ marginTop: 24, width: "100%" }}>
				<BackButton onClick={() => history.push("/declarations")} />

				<div style={{ width: "fit-content", marginLeft: "auto" }}>
					<ElementSearchInput selectedTabIndex={selectedTabIndex} />
				</div>
			</div>
			<div style={{ marginTop: 24 }}>
				<DeclarationStatusTag declaration={state.declaration} />
			</div>
			<div className="d-flex align-items-center">
				<div>
					<div className={withTheme("declarations-title")}>
						{getDeclarationLabel(state.declaration)}
					</div>
				</div>

				<DeclarationActions setLoadingAction={setLoadingAction} loadingAction={loadingAction} />
			</div>

			<Input
				placeholder={t("declarationDesc")}
				style={{ marginTop: 12 }}
				value={description}
				onChange={handleDescChange}
			/>

			{/* <NotificationsPanel /> */}
			<ValidationErrors />
		</div>
	);
};

export default DeclarationPageHeader;
