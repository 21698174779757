import { useContext, useEffect, useState } from "react";
import Input from "../../../common/Input";
import { useTranslation } from "react-i18next";
import Textarea from "../../../common/Textarea";
import Button from "../../../common/Button";
import { PiFloppyDisk } from "react-icons/pi";
import FilesContext from "../../../../context/files/FilesContext";

const SelectedFileInputs = ({ file, setIsEditModeEnabled }) => {
	const { t } = useTranslation();
	const { updateFile } = useContext(FilesContext);
	const [inputValues, setInputValues] = useState({});
	const [errors, setErrors] = useState({});
	const [isSaveLoading, setIsSaveLoading] = useState(false);

	const handleInputChange = (name, value) => {
		setInputValues({ ...inputValues, [name]: value });

		if (errors[name]) {
			delete errors[name];

			setErrors({ ...errors });
		}
	};

	const handleSaveClick = async () => {
		let errors = {};

		if (!inputValues.description) {
			errors.description = t("required");
		}

		if (!inputValues.label) {
			errors.label = t("required");
		}

		setErrors({ ...errors });

		if (Object.keys(errors).length) {
			return;
		}

		setIsSaveLoading(true);

		await updateFile(file, inputValues);

		setIsSaveLoading(false);

		setIsEditModeEnabled(false);
	};

	useEffect(() => {
		setInputValues({ label: file.label, description: file.description });
	}, [file._id]);

	return (
		<div>
			<Input
				label={t("fileName")}
				placeholder={t("name")}
				error={errors.label}
				value={inputValues.label || ""}
				onChange={(e) => handleInputChange("label", e.target.value)}
			/>

			<div style={{ marginTop: 16 }}>
				<Textarea
					label={t("description")}
					placeholder={t("description")}
					error={errors.description}
					value={inputValues.description || ""}
					style={{ resize: "none" }}
					onChange={(e) => handleInputChange("description", e.target.value)}
				/>
			</div>

			<div
				style={{
					display: "flex",
					width: "fit-content",
					marginLeft: "auto",
					gap: 12,
					marginTop: 24,
				}}
			>
				<Button type="secondary" onClick={() => setIsEditModeEnabled(false)}>
					{t("dismiss")}
				</Button>

				<Button isLoading={isSaveLoading} LeftIcon={PiFloppyDisk} onClick={handleSaveClick}>
					{t("saveChanges")}
				</Button>
			</div>
		</div>
	);
};

export default SelectedFileInputs;
