import { PiTrash } from "react-icons/pi";
import Textarea from "../../../common/Textarea";
import Button from "../../../common/Button";
import Input from "../../../common/Input";
import { useTranslation } from "react-i18next";
import { FILE_DESC_MAX_LENGTH, FILE_NAME_MAX_LENGTH } from "../../../../constants/GlobalConstants";

const FileMetadataInputs = ({ selectedFile, inputValues, errors, onInputChange, onRemoveClick }) => {
	const { t } = useTranslation();

	return (
		<>
			<div style={{ display: "flex", gap: 16 }}>
				<div style={{ marginTop: 16, width: "calc(100% - 40px)" }}>
					<Input
						label={t("fileName")}
						placeholder={t("name")}
						error={errors?.file || errors?.label}
						value={inputValues.label || ""}
						maxLength={FILE_NAME_MAX_LENGTH}
						onChange={(e) => onInputChange("label", e.target.value)}
					/>
				</div>

				<Button
					isDisabled={!selectedFile}
					tooltip={t("removeFile")}
					type="secondary-icon"
					onClick={onRemoveClick}
					style={{ marginTop: 42.5 }}
				>
					<PiTrash />
				</Button>
			</div>

			<div style={{ marginTop: 16 }}>
				<Textarea
					label={t("fileDescription")}
					placeholder={t("description")}
					value={inputValues.description || ""}
					style={{ resize: "none" }}
					error={errors?.description}
					maxLength={FILE_DESC_MAX_LENGTH}
					onChange={(e) => onInputChange("description", e.target.value)}
				/>
			</div>
		</>
	);
};

export default FileMetadataInputs;
