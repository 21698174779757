export const FSD_DEFAULT_DATA = {
	additionalDeclarationType: "Q",
	declarationCategory: "FSD",
	declarationType: "IM",
	functionCode: 9,
	importType: "finalSupplementary",
	previousDocumentType: "ZZZ",
	procedureCode: "0090",
	service: "import",
};
