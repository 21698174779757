import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import PageContainer from "../../components/layouts/PageContainer";
import Tabs from "../../components/common/Tabs/Tabs";
import UserContext from "../../context/user/UserContext";
import { useTranslation } from "react-i18next";
import { seo } from "../../helpers";
import ApplicationSettingsTab from "./Tabs/ApplicationSettings";
import MyAccountTab from "./Tabs/MyAccount";
import SubscriptionTab from "./Tabs/Subscription/Subscription";

const tabs = ["myAccount", "applicationSettings", "billingAndSubscription"];

const SettingsLayout = () => {
	const { getSettings } = useContext(UserContext);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const { t } = useTranslation();

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			await getSettings();
			setIsLoading(false);
		})();

		seo({
			title: t("settings"),
		});

		return () => {
			seo({});
		};
	}, []);

	return (
		<PageContainer title={t("settings")}>
			<div style={{ padding: 40 }}>
				<Tabs
					tabs={tabs.map((tab) => t(tab))}
					selectedTabIndex={selectedTabIndex}
					onChange={setSelectedTabIndex}
				/>

				{isLoading ? (
					<Spinner animation="border" role="status" />
				) : (
					<div style={{ marginTop: 32 }}>
						{selectedTabIndex === 0 && <MyAccountTab />}
						{selectedTabIndex === 1 && <ApplicationSettingsTab />}
						{selectedTabIndex === 2 && <SubscriptionTab />}
					</div>
				)}
			</div>
		</PageContainer>
	);
};

export default SettingsLayout;
