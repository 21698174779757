import { useTranslation } from "react-i18next";
import StatsCard from "../../../components/layouts/dashboard/StatsCard";
import { useContext, useMemo } from "react";
import DeclarationsContext from "../../../context/declarations/DeclarationsContext";

const StatsCards = () => {
	const { state } = useContext(DeclarationsContext);
	const { t } = useTranslation();

	const { cleared, accepted, rejected, inQueue } = useMemo(() => {
		let result = { cleared: 0, accepted: 0, rejected: 0, inQueue: 0 };

		state.declarations.forEach((declaration) => {
			const { status } = declaration;

			result[status] += 1;
		});

		return result;
	}, [state.declarations]);

	return (
		<div style={{ display: "flex", gap: 20, marginTop: 20 }}>
			<StatsCard value={cleared} label={t("declarationsCleared")} />
			<StatsCard value={accepted} label={t("declarationsAccepted")} />
			<StatsCard value={rejected} label={t("declarationsRejected")} />
			<StatsCard value={inQueue} label={t("declarationsInQueue")} />
		</div>
	);
};

export default StatsCards;
