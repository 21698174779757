import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from "axios";
import { isExpired } from "react-jwt";
import config from "./config";
import { refreshTokensUtil } from './context/user/UserState';
import { LS_ACCESS_TOKEN_KEY } from './constants/GlobalConstants';

const { cognitoAuthUrl } = config

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(async (config) => {
  const accessToken = localStorage.getItem(LS_ACCESS_TOKEN_KEY);
  const isTokenExpired = isExpired(accessToken)

  if ((isTokenExpired || !config.headers.Authorization) && !config.url.includes(cognitoAuthUrl)) {
    const newAccessToken = await refreshTokensUtil();
    config.headers.Authorization = `Bearer ${newAccessToken}`;
  }

  return config
}, async (error) => {
  const originalRequest = error.config;
  if (error.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    await refreshTokensUtil();
    return axiosInstance(originalRequest)
  }

  return Promise.reject(error);
});



ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// Test 2
