import { ResponsivePie } from "@nivo/pie";
import { useMemo } from "react";

const DeclarationsPieChart = ({ data }) => {
	const isEmpty = useMemo(() => data.every(item => !item.value),[data])

	return (
		<ResponsivePie
			data={isEmpty ? emptyData : data}
			cornerRadius={3}
			isInteractive={false}
			innerRadius={0.7}
			colors={(item) => item.data.color}
			arcLinkLabel={(arc) => null}
			enableArcLinkLabels={false}
			enableArcLabels={false}
		/>
	);
};

const emptyData = [
	{
		id: "",
		value: 1,
		color: '#D7DAE0'
	}
]

export default DeclarationsPieChart;
