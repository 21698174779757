// Declarations
export const GET_CONTACTS = "GET_CONTACTS";
export const UPDATE_STATE = "UPDATE_STATE";
export const SET_MANDATORY_DECLARATION_ELEMENTS = "SET_MANDATORY_DECLARATION_ELEMENTS";
export const SET_DISABLED_DECLARATION_ELEMENTS = "SET_DISABLED_DECLARATION_ELEMENTS";
export const SET_ELEMENT_VALUE = "SET_ELEMENT_VALUE";
export const SET_ELEMENT_VALUE_IN_SETCION = "SET_ELEMENT_VALUE_IN_SETCION";
export const SET_ITEM_ELEMENT_VALUE = "SET_ITEM_ELEMENT_VALUE";
export const SET_ITEM_ELEMENT_VALUE_IN_SECTION = "SET_ITEM_ELEMENT_VALUE_IN_SECTION";
export const CREATE_ITEM = "CREATE_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";
export const UPDATE_ITEM = "UPDATE_ITEM";
export const SET_VALIDATION_ERRORS = "SET_VALIDATION_ERRORS";
export const SET_UNSAVED_CHANGES = "SET_UNSAVED_CHANGES";
export const SET_REQUIRED_PULLDOWN_OPTIONS = "SET_REQUIRED_PULLDOWN_OPTIONS";
export const LIST_DECLARATIONS = "LIST_DECLARATIONS";
export const LIST_DECLARATIONS_LOADING = "LIST_DECLARATIONS_LOADING";
export const CLEAR_SELECTED_DECLARATION_STATE = "CLEAR_SELECTED_DECLARATION_STATE";
export const SET_LOADING_DECLARATION_ID = "SET_LOADING_DECLARATION_ID";

// Theme
export const SET_THEME = "SET_THEME";

// Files
export const SET_FILES = "SET_FILES";
export const SET_FILES_LOADING = "SET_FILES_LOADING";

// User
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_USER_SETTINGS = "SET_USER_SETTINGS";

// Workspaces
export const SET_WORKSPACES = "SET_WORKSPACES";
export const SET_SELECTED_WORKSPACE = "SET_SELECTED_WORKSPACE";

// Notifications
export const SET_UNREAD_NOTIFICATIONS_COUNT = "SET_UNREAD_NOTIFICATIONS_COUNT";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
