import React, { useState } from "react";
import { useContext, useEffect } from "react";
import { Route } from "react-router-dom";
import UserContext from "../../context/user/UserContext";
import LoadingSpinner from "./LoadingSpinner";
import { useTranslation } from "react-i18next";
import moment from "moment";
import NotificationRequestPopup from "./NotificationRequestPopup";
import { LS_DISABLE_REQUEST_PERMISSION_KEY } from "../../constants/GlobalConstants";

const ProtectedRoute = ({ component: Component, exact, path }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [isNotificationRequestPopupOpen, setIsNotificationRequestPopupOpen] = useState(false);
	const { getUserInfo, getSettings, user, userSettings, subscribeToPushNotifs } = useContext(UserContext);
	const { i18n } = useTranslation();

	const loadUserData = async () => {
		setIsLoading(true);
		let loadedUserDataResult = null;

		if (!user) {
			loadedUserDataResult = await getUserInfo();
		}

		if (!userSettings && (loadedUserDataResult || user)) {
			await getSettings();
		}

		setIsLoading(false);

		if (Notification.permission === "default" && !localStorage.getItem(LS_DISABLE_REQUEST_PERMISSION_KEY)) {
			setIsNotificationRequestPopupOpen(true);
		}
	};

	useEffect(() => {
		loadUserData();
	}, []);

	useEffect(() => {
		if (userSettings?.language && userSettings?.language !== i18n.language) {
			i18n.changeLanguage(userSettings.language);
			moment.locale(userSettings.language);
		}
	}, [userSettings?.language]);

	useEffect(() => {
		if (userSettings) {
			subscribeToPushNotifs();
		}
	}, [userSettings]);

	if (isLoading) {
		return (
			<div style={{ width: "fit-content", padding: 62, marginLeft: "auto", marginRight: "auto" }}>
				<LoadingSpinner />
			</div>
		);
	}

	return (
		<>
			<NotificationRequestPopup
				isOpen={isNotificationRequestPopupOpen}
				onClose={() => setIsNotificationRequestPopupOpen(false)}
			/>

			<Route exact={exact} path={path} component={Component} />
		</>
	);
};

export default ProtectedRoute;
