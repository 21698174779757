import { useContext, useEffect, useMemo, useState } from "react";
import { createCommodityCode } from "../../../../../../api/commodity-codes";
import { createContact } from "../../../../../../api/contacts";
import { createLocation } from "../../../../../../api/goodsLocations";
import Button from "../../../../../common/Button";
import Modal from "../../../../../modals/Modal";
import { useTranslation } from "react-i18next";
import SearchItemElement from "./SelectedItem/components/SearchItemElement";
import WorkspacesContext from "../../../../../../context/workspaces/WorkspacesContext";

const NewSectionDataEntryModal = (props) => {
	const { isOpen, sectionType, sectionElements, onClose, onSubmit } = props;
	const [inputValues, setInputValues] = useState({});
	const [errors, setErrors] = useState({});
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const { selectedWorkspaceId } = useContext(WorkspacesContext);
	const { t } = useTranslation();

	const label = useMemo(() => {
		if (sectionType === "contacts") {
			return t("addContact");
		} else if (sectionType === "locationOfGoods") {
			return t("addLocation");
		} else if (sectionType === "commodityCodes") {
			return t("addCode");
		}
	}, [sectionType]);

	const handleInputChange = (name, value) => {
		if (errors[name]) {
			delete errors[name];
			setErrors({ ...errors });
		}

		setInputValues({
			...inputValues,
			[name]: value,
		});
	};

	const handleSubmit = async () => {
		let errors = {};

		sectionElements.forEach((element) => {
			if (element.isRequired && !inputValues[element.name]) {
				errors[element.name] = t("required");
			}
		});

		setErrors(errors);
		if (Object.keys(errors).length) {
			return;
		}

		setIsSaveLoading(true);

		let result = null;

		inputValues.workspace = selectedWorkspaceId;

		if (sectionType === "contacts") {
			result = await createContact(inputValues);
		} else if (sectionType === "locationOfGoods") {
			result = await createLocation(inputValues);
		} else if (sectionType === "commodityCodes") {
			result = await createCommodityCode(inputValues);
		}

		onSubmit(result);
		setIsSaveLoading(false);
		onClose();
	};

	useEffect(() => {
		if (!isOpen) {
			setInputValues({});
			setErrors({});
		}
	}, [isOpen]);

	return (
		<Modal style={{ width: 800 }} isOpen={isOpen} onClose={onClose} label={label}>
			<div style={{ display: "flex", flexWrap: "wrap", columnGap: 20 }}>
				{sectionElements?.map(({ name }, index) => (
					<SearchItemElement
						isEditModeEnabled
						key={index}
						elementName={name}
						value={inputValues[name]}
						error={errors[name]}
						onInputChange={handleInputChange}
					/>
				))}
			</div>

			<div
				style={{
					marginTop: 32,
					width: "fit-content",
					marginLeft: "auto",
					display: "flex",
					gap: 12,
				}}
			>
				<Button
					type="secondary"
					style={{ minWidth: 100 }}
					isDisabled={isSaveLoading}
					onClick={onClose}
				>
					{t("cancel")}
				</Button>
				<Button isLoading={isSaveLoading} onClick={handleSubmit}>
					{label}
				</Button>
			</div>
		</Modal>
	);
};

export default NewSectionDataEntryModal;
