import { useEffect, useState } from "react";
import CustomSectionDataPage from "../components/layouts/declarations/CustomSections/CustomSectionDataPage/CustomSectionDataPage";
import PageContainer from "../components/layouts/PageContainer";
import { useTranslation } from "react-i18next";
import { SEARCH_TYPES, SEARCH_TYPE_ELEMENTS } from "../constants/GlobalConstants";
import { seo } from "../helpers";

const GoodsLocations = () => {
	const [searchValue, setSearchValue] = useState("");
	const { t } = useTranslation();

	useEffect(() => {
		seo({
			title: t("goodsLocations"),
		});

		return () => {
			seo({});
		};
	}, []);

	return (
		<PageContainer title={t("goodsLocations")}>
			<CustomSectionDataPage
				searchType={SEARCH_TYPES.GOODS_LOCATIONS}
				sectionElements={SEARCH_TYPE_ELEMENTS.GOODS_LOCATIONS}
				initialSearchValue={searchValue}
				onSearchInputChange={(e) => setSearchValue(e.target.value)}
			/>
		</PageContainer>
	);
};

export default GoodsLocations;
