import { useContext } from "react";
import ThemeContext from "../../context/theme/ThemeContext";

const Card = ({ style, children }) => {
	const { withTheme } = useContext(ThemeContext);

	return (
		<div style={style} className={withTheme("card")}>
			{children}
		</div>
	);
};

export default Card;
