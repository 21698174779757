import { PiCaretLeftBold, PiCaretRightBold } from "react-icons/pi";
import ThemeContext from "../../../context/theme/ThemeContext";
import { useContext } from "react";

const ExpandButton = ({ type, isExpanded, style, onClick }) => {
	const { withTheme } = useContext(ThemeContext);

	return (
		<div className={withTheme("expand-button-outter")} style={style} onClick={onClick}>
			<div
				className={`${
					type === "secondary" ? withTheme("secondary-expand-button-inner") : ""
				} ${withTheme("expand-button-inner")}`}
			>
				{isExpanded ? <PiCaretLeftBold /> : <PiCaretRightBold />}
			</div>
		</div>
	);
};

export default ExpandButton;
