import { LS_ACCESS_TOKEN_KEY } from "../constants/GlobalConstants";

export const getHeaders = () => {
	const lsToken = localStorage.getItem(LS_ACCESS_TOKEN_KEY);

	if (lsToken) {
		return {
			Authorization: lsToken,
		};
	} else return {};
};

