import { useContext } from "react";
import { useQuery } from "../../../App";
import ThemeContext from "../../../context/theme/ThemeContext";

import NavItem from "./NavItem";
import Logo from "../../../assets/images/logo.png";
import LogoSmall from "../../../assets/images/logo-small.png";
import {
	PiChartDonut,
	PiChartDonutFill,
	PiMapPinLine,
	PiNote,
	PiNoteFill,
	PiUserSquare,
	PiUserSquareFill,
	PiMapPinLineFill,
	PiBarcode,
	PiBarcodeFill,
	PiUploadSimple,
	PiUploadSimpleFill,
	PiGear,
	PiGearFill,
} from "react-icons/pi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ExpandButton from "./ExpandButton";
import { DECLARATION_ID_QUERY_PARAM } from "../../../constants/GlobalConstants";

const SideNavBar = ({ isExpanded, setIsExpanded }) => {
	const { withTheme } = useContext(ThemeContext);
	const history = useHistory();
	let query = useQuery();

	const navItems = [
		{
			label: "dashboard",
			routes: ["/"],
			Icon: PiChartDonut,
			ActiveIcon: PiChartDonutFill,
		},
		{
			label: "declarations",
			Icon: PiNote,
			ActiveIcon: PiNoteFill,
			routes: ["/declarations", "/declarations/preview"],
			onClick: () => {
				const declarationId = query.get(DECLARATION_ID_QUERY_PARAM);
				let route = "/declarations";

				if (declarationId) {
					route += `?${DECLARATION_ID_QUERY_PARAM}=${declarationId}`;
				}

				history.push(route);
			},
		},
		{
			label: "contacts",
			routes: ["/contacts"],
			Icon: PiUserSquare,
			ActiveIcon: PiUserSquareFill,
		},
		{
			label: "goodsLocations",
			routes: ["/goodsLocations"],
			Icon: PiMapPinLine,
			ActiveIcon: PiMapPinLineFill,
		},
		{
			label: "commodityCodes",
			routes: ["/commodity-codes"],
			Icon: PiBarcode,
			ActiveIcon: PiBarcodeFill,
		},
		{
			label: "uploadFiles",
			routes: ["/files"],
			Icon: PiUploadSimple,
			ActiveIcon: PiUploadSimpleFill,
		},
	];

	return (
		<>
			<div
				className={`${withTheme("side-nav")} ${
					!isExpanded ? withTheme("side-nav-collapsed") : ""
				}`}
			>
				<ExpandButton isExpanded={isExpanded} onClick={() => setIsExpanded(!isExpanded)} />

				<img
					src={isExpanded ? Logo : LogoSmall}
					width={isExpanded ? 180 : 23}
					alt="logo"
					style={{
						marginBottom: 26,
						marginTop: !isExpanded ? 12 : 0,
					}}
				/>
				<div
					style={{
						minWidth: isExpanded ? "200px" : "fit-content",
						overflowX: "hidden",
					}}
				>
					{navItems.map((item, index) => (
						<NavItem item={item} key={index} isExpanded={isExpanded} />
					))}
				</div>

				<div style={{ marginTop: "auto" }}>
					<NavItem
						isExpanded={isExpanded}
						item={{
							label: "settings",
							routes: ["/settings"],
							Icon: PiGear,
							ActiveIcon: PiGearFill,
						}}
					/>
				</div>
			</div>
		</>
	);
};

export default SideNavBar;
