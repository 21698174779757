
/** Valdiates an element's value according to its "regex" OR the "format" property.
 * Examples formats:
 * - "n7": accepts only number with a length of 7
 * - "a7": accepts only characters with a length of 7
 * - "an7": accepts characters AND numbers with a length of 7
 * - "an..7": accepts characters and numbers with a maximum length of 7, any less than that is also allowed
 */
export const validateElementValue = (element, value, setValidationWarning) => {
    const { format, regex } = element;

    // If a certain regex is specified in the validation element, it is used instead of the "format"
    if (regex && !regex.test(value)) {
        setValidationWarning('Invalid value')
    } else setValidationWarning('')
    if (!format || typeof value !== 'string') return true;

    const mainCharactersLimit = parseInt(format.split(',')[0].replace(/^\D+/g, ''));
    const decimalCharactersLimit = (format.split(',')[1]?.length || 0);

    let characterLimit = mainCharactersLimit + decimalCharactersLimit;

    let numbersLength = String(parseFloat(value))?.length;
    if (value.includes('.') && !value.startsWith('.') && value.endsWith('.')) {
        numbersLength = numbersLength + 1;
    };

    // Show a validation warning if the amount of characters is less that the required length
    if (value && value.length < characterLimit && !format.includes('..'))
        setValidationWarning(`Input requires at least ${characterLimit} characters`)
    else setValidationWarning('');

    // Return false if "a" is not included and value has regular characters (not numbers)
    if (!format.includes('a') && ((value && numbersLength !== value.length) || value.split('.').length > 2)) {
        return false
        // Character limit check
    } else if (value.length > characterLimit)
        return false
    // Has decimal check
    else if (!format.includes(',') && value.includes('.') && !format.includes('a'))
        return false
    // Decimals number limit
    else if (format.includes(',') && value.split('.')[1]?.length > parseInt(format.split(',')[1])) {
        return false
    } else return true;
};