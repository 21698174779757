import { useTranslation } from "react-i18next";
import Modal from "../Modal";
import { useContext, useEffect, useMemo, useState } from "react";
import DeclarationsContext from "../../../context/declarations/DeclarationsContext";
import { getDeclarationHmrcDetails } from "../../../api/declarations";
import LoadingSpinner from "../../common/LoadingSpinner";
import { get } from "lodash";
import { sortData } from "./utils";
import Tabs from "../../common/Tabs/Tabs";
import ReadOnlyElement from "../../common/ReadOnlyElement/ReadOnlyElement";
import NotificationToast from "../../common/NotificationToast";

const DeclarationDetailsModal = ({ isOpen, onClose }) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState();
	const [isErrorToastOpen, setIsErrorToastOpen] = useState(false);
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const { state } = useContext(DeclarationsContext);

	const selectedTabData = useMemo(() => {
		if (!data) {
			return {};
		}

		return sortData(get(data, TABS[selectedTabIndex].path));
	}, [data, selectedTabIndex]);

	const loadData = async () => {
		setIsLoading(true);

		try {
			const data = await getDeclarationHmrcDetails(state.declaration._id);

			setData(data);
		} catch (e) {
			setIsErrorToastOpen(true);
		}

		setIsLoading(false);
	};

	useEffect(() => {
		if (isOpen && state.declaration?.mrn && data?._id !== state.declaration?._id) {
			loadData();
		}
	}, [isOpen]);

	return (
		<>
			<NotificationToast
				isOpen={isErrorToastOpen}
				text={t("errorLoadingData")}
				variant="error"
				onClose={() => setIsErrorToastOpen(false)}
			/>

			<Modal
				isOpen={isOpen}
				label={t("declarationDetails")}
				style={{ width: 1200, height: "90vh" }}
				onClose={onClose}
			>
				{isLoading ? (
					<div style={{ width: "fit-content", marginLeft: "auto", marginRight: "auto" }}>
						<LoadingSpinner />
					</div>
				) : (
					data && (
						<>
							<Tabs
								tabs={TABS.map((tab) => t(tab.label))}
								selectedTabIndex={selectedTabIndex}
								onChange={setSelectedTabIndex}
							/>

							<div
								style={{
									display: "flex",
									flexWrap: "wrap",
									flexGrow: 1,
									gap: 8,
								}}
							>
								{Object.keys(selectedTabData).map((key) => (
									<ReadOnlyElement
										key={key}
										label={key}
										data={selectedTabData[key]}
									/>
								))}
							</div>
						</>
					)
				)}
			</Modal>
		</>
	);
};

const TABS = [
	{
		label: "fullDeclaration",
		path: "FullDeclarationObject.Declaration",
	},
	{
		label: "generatedConsignment",
		path: "GeneratedConsignmentDetails",
	},
	{
		label: "generatedItemLevelConsignment",
		path: "GeneratedItemLevelConsignmentDetails",
	},
	{
		label: "highLevelSummary",
		path: "HighLevelSummaryDetails",
	},
	{
		label: "account",
		path: "AccountDetails",
	},
];

export default DeclarationDetailsModal;
