import { useContext, useEffect, useRef, useState } from "react";
import { Overlay } from "react-bootstrap";
import ThemeContext from "../../context/theme/ThemeContext";
import { isBoolean } from "lodash";

const Tooltip = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const { withTheme } = useContext(ThemeContext);
	const overlayTarget = useRef(null);
	const { isCompact, isDisabled, openOnHover, children, text, placement, onOpen, onClose } = props;

	const handleOpen = () => {
		if (isDisabled) {
			return null;
		}

		onOpen?.();

		setIsOpen(true);
	};

	const handleClose = () => {
		onClose?.();

		setIsOpen(false);
	};

	useEffect(() => {
		if (isBoolean(props.isOpen)) {
			setIsOpen(props.isOpen);
		}
	}, [props.isOpen]);

	if (!text) {
		return null;
	}

	return (
		<>
			<Overlay
				rootClose
				rootCloseEvent="mousedown"
				target={overlayTarget.current}
				show={isOpen}
				placement={placement}
				onHide={handleClose}
			>
				{({ ...props }) => (
					<div
						{...props}
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
							e.nativeEvent.stopImmediatePropagation();
						}}
						className={`${withTheme("tooltip-container")} ${
							isCompact ? withTheme("tooltip-container-compact") : ""
						}`}
						style={{
							...props.style,
							zIndex: 15
						}}
					>
						{text}
					</div>
				)}
			</Overlay>

			<div
				onMouseLeave={() => openOnHover && handleClose()}
				onMouseEnter={() => openOnHover && handleOpen()}
				onClick={() => !openOnHover && handleOpen()}
				ref={overlayTarget}
			>
				{children}
			</div>
		</>
	);
};

export default Tooltip;
