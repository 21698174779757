import { useContext } from "react";
import ThemeContext from "../../context/theme/ThemeContext";
import { PiWarningCircleFill } from "react-icons/pi";

const ErrorText = ({ children, style }) => {
	const { withTheme } = useContext(ThemeContext);

	return (
		<div style={style} className={withTheme("error-text")}>
			<PiWarningCircleFill className={withTheme("error-text-icon")} />
			<div>{children}</div>
		</div>
	);
};

export default ErrorText;
