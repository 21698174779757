import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useContext, useMemo } from "react";
import ThemeContext from "../../../context/theme/ThemeContext";

const NavItem = ({ item, isExpanded }) => {
	const { withTheme } = useContext(ThemeContext);
	const history = useHistory();
	const { t } = useTranslation();

	const isSelected = useMemo(() => item.routes?.includes(history.location.pathname), [history.location, item]);

	const handleClick = () => {
		if (item.onClick) {
			item.onClick();
		} else {
			history.push(item.routes[0]);
		}
	};

	const Icon = isSelected ? item.ActiveIcon : item.Icon;

	return (
		<div onClick={handleClick} className={`${withTheme("side-nav-item")} ${isSelected ? "side-nav-item-active" : ""}`}>
			<Icon className={withTheme("side-nav-item-icon")} />
			{isExpanded && <div className={withTheme("side-nav-item-text")}>{t(item.label)}</div>}
		</div>
	);
};

export default NavItem;
