import { axiosInstance } from "../index";
import config from "../config";
import { getHeaders } from "./helpers";

const { apiUrl } = config;

export const listContacts = async (workspaceId) => {
	const result = await axiosInstance.get(`${apiUrl}/contacts`, { params: { workspaceId }, headers: getHeaders() });

	return result.data.contacts;
};

export const createContact = async (data) => {
	const result = await axiosInstance.post(`${apiUrl}/contacts`, data, { headers: getHeaders() });

	return result.data;
};

export const updateContact = async (contactId, data) => {
	const result = await axiosInstance.put(`${apiUrl}/contacts/${contactId}`, data, { headers: getHeaders() });

	return result.data.contact;
};

export const deleteContact = async (contactId) => {
	const result = await axiosInstance.delete(`${apiUrl}/contacts/${contactId}`, {
		headers: getHeaders(),
	});

	return result.data;
};
