import { useContext } from "react";
import ThemeContext from "../../../../../context/theme/ThemeContext";
import { useTranslation } from "react-i18next";

const DeclarationStatusTag = ({ declaration }) => {
	const { status } = declaration;
	const { withTheme } = useContext(ThemeContext);
	const { t } = useTranslation();

	return (
		<div
			className={`${withTheme("declaration-status-tag")} ${
				status ? withTheme(`declaration-status-tag-${status.toLowerCase()}`) : ""
			}`}
		>
			{t(status)}
		</div>
	);
};

export default DeclarationStatusTag;
