import ListItem from "../../../../../../../common/ListItem";
import { SEARCH_TYPES } from "../../../../../../../../constants/GlobalConstants";
import ContactDrawerItem from "./ContactDrawerItem";
import GoodsLocationDrawerItem from "./GoodsLocationItem";
import CommodityCodeDrawerItem from "./CommodityCodeDrawerItem";

const DrawerItem = (props) => {
	const { item, searchType, onClick, isSelected } = props;

	return (
		<ListItem isSelected={isSelected} onClick={() => onClick(item)}>
			{searchType === SEARCH_TYPES.CONTACTS && <ContactDrawerItem item={item} />}
			{searchType === SEARCH_TYPES.GOODS_LOCATIONS && <GoodsLocationDrawerItem item={item} />}
			{searchType === SEARCH_TYPES.COMMODITY_CODES && <CommodityCodeDrawerItem item={item} />}
		</ListItem>
	);
};

export default DrawerItem;
