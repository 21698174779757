import { useContext, useMemo, useState } from "react";
import ThemeContext from "../../../../context/theme/ThemeContext";
import ImageErrorIllust from "../../../../assets/images/image-error-illust.png";
import moment from "moment";
import { PiCheckCircleFill } from "react-icons/pi";
import FileOptionsPopover from "./OptionsPopover";
import FileListItemEditMode from "./EditMode";
import DeclarationsContext from "../../../../context/declarations/DeclarationsContext";
import SubmissionErrorIcon from "./SubmissionErrorIcon";

const FileListItem = ({
	file,
	isSelected,
	isDisabled,
	style,
	isAssociatedWithDeclaration,
	showOptionsButton,
	onClick,
}) => {
	const { state } = useContext(DeclarationsContext);
	const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);
	const { withTheme } = useContext(ThemeContext);

	const isSubmittedToHmrc = useMemo(
		() => state.declaration?.mrn && file.associatedMrns?.includes(state.declaration?.mrn),
		[isAssociatedWithDeclaration, state.declaration, file]
	);

	const hasSubmissionError = useMemo(
		() => isAssociatedWithDeclaration && !isSubmittedToHmrc,
		[isAssociatedWithDeclaration, isSubmittedToHmrc]
	);

	return (
		<div
			className={`${withTheme("file-list-item")} ${
				isSelected ? withTheme("file-list-item-selected") : ""
			} ${isDisabled ? withTheme("file-list-item-disabled") : ""}`}
			style={{ ...style, cursor: onClick ? "pointer" : "default", userSelect: onClick && "none" }}
			onClick={() => onClick?.(file)}
		>
			<img className={withTheme("file-list-item-image")} alt="File Preview" src={ImageErrorIllust} />

			{isEditModeEnabled ? (
				<FileListItemEditMode
					file={file}
					onSave={() => setIsEditModeEnabled(false)}
					onCancel={() => setIsEditModeEnabled(false)}
				/>
			) : (
				<div className={withTheme("file-list-item-text")}>
					<div style={{ display: "flex", alignItems: "center", gap: 6 }}>
						<div
							title={file.label}
							className={`${withTheme("file-list-item-title")} ${
								hasSubmissionError ? withTheme("error-red") : ""
							}`}
						>
							{file.label}
						</div>

						{hasSubmissionError && <SubmissionErrorIcon file={file} />}
					</div>
					<div
						title={file.description}
						className={withTheme("file-list-item-description")}
					>
						{file.description}
					</div>
					<div>{moment(file.createdAt).format("DD MMM YYYY")}</div>
				</div>
			)}

			{showOptionsButton && !isEditModeEnabled && (
				<FileOptionsPopover
					file={file}
					isAssociatedWithDeclaration={isAssociatedWithDeclaration}
					isSubmittedToHmrc={isSubmittedToHmrc}
					onRenameClick={() => setIsEditModeEnabled(true)}
				/>
			)}

			{isSelected && (
				<div className={withTheme("file-list-item-check-circle")}>
					<PiCheckCircleFill fontSize="32px" />
				</div>
			)}
		</div>
	);
};

export default FileListItem;
