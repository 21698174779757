const LoadingSpinner = ({ style, size }) => {
	return (
		<div style={{ ...style }}>
			<div
				className={`spinner-border ${size === "sm" ? "spinner-border-sm" : ""}`}
				role="status"
			></div>
		</div>
	);
};

export default LoadingSpinner;
