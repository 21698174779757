import { generateFileKey, generateObjectId } from "../helpers";
import { axiosInstance } from "../index";
import config from "../config";
import { getHeaders } from "./helpers";
import axios from "axios";

const { apiUrl } = config;

export const fetchFiles = async (workspaceId) => {
	const result = await axiosInstance.get(`${apiUrl}/files`, { params: { workspaceId }, headers: getHeaders() });

	return result.data.files;
};

export const uploadFile = async (file, { onUploadFail, onUploadProgress, cancelToken } = {}) => {
	let fileDocument = {
		_id: generateObjectId(),
		workspace: file.workspace,
		file: file.file,
		size: file.file.size,
		label: file.label || file.file.name,
		type: file.file.type,
		description: file.description,
		declarations: file.declarations,
		key: file.key || generateFileKey(file.file),
		status: "pending",
	};

	try {
		const uploadUrl = await initializeUpload(fileDocument, {
			cancelToken,
		});

		await axios.put(uploadUrl, file.file, {
			cancelToken,
			headers: {
				"Content-Type": file.file.type,
			},
			onUploadProgress: (progressEvent) =>
				onUploadProgress?.(Math.floor((progressEvent.loaded / progressEvent.total) * 100)),
		});

		return await updateFile(fileDocument._id, { status: "uploaded" });
	} catch (e) {
		console.log(e);
		onUploadFail?.(file.file);
		throw e;
	}
};

const initializeUpload = async (file, { cancelToken } = {}) => {
	const result = await axiosInstance.post(`${apiUrl}/files`, file, {
		cancelToken,
		headers: getHeaders(),
	});

	return result.data.uploadUrl;
};

export const updateFile = async (id, updates) => {
	const result = await axiosInstance.put(`${apiUrl}/files/${id}`, updates, { headers: getHeaders() });

	return result.data;
};

export const bulkUpdateFiles = async (payload) => {
	const result = await axiosInstance.put(`${apiUrl}/files`, payload, { headers: getHeaders() });

	return result.data;
};

export const deleteFile = async (id) => {
	const result = await axiosInstance.delete(`${apiUrl}/files/${id}`, { headers: getHeaders() });

	return result.data;
};

export const submitFileToHmrc = async (id, declarationId) => {
	const result = await axiosInstance.post(`${apiUrl}/files/${id}/submit`, { declarationId }, { headers: getHeaders() });

	return result.data;
};
