import { useContext, useRef, useState } from "react";
import Button from "../../../common/Button";
import { PiDotsThreeVertical } from "react-icons/pi";
import Popover from "../../../common/Popover/Popover";
import { useTranslation } from "react-i18next";
import PopoverOption from "../../../common/Popover/PopoverOption";
import DeclarationsContext from "../../../../context/declarations/DeclarationsContext";
import DeclarationDetailsModal from "../../../modals/DeclarationDetailsModal/DeclarationDetailsModal";
import { removeQueryParam, updateQueryParam } from "../../../../router/utils";
import { DECLARATION_ID_QUERY_PARAM } from "../../../../constants/GlobalConstants";
import ConfirmationModal from "../../../modals/ConfirmationModal";
import NotificationToast from "../../../common/NotificationToast";
import Badge from "../../../common/Badge";
import { getDeclarationLabel } from "../../../../helpers";
import UnsavedChangesModal from "../../../modals/UnsavedChangesModal";
import { getDeclarationReport } from "../../../../api/declarations";

const DeclarationActionsPopover = () => {
	const [loadingAction, setLoadingAction] = useState();
	const [isOpen, setIsOpen] = useState(false);
	const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] = useState(false);
	const { t } = useTranslation();
	const targetRef = useRef(null);
	const [isDecDetailsModalOpen, setIsDecDetailsModalOpen] = useState(false);
	const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
	const [toast, setToast] = useState({ text: "", variant: "success" });

	const {
		state,
		createDeclaration,
		// getValidationErrors,
		deleteDeclaration,
		updateDeclaration,
		isDeclarationReadOnly,
		clearSelectedDeclaration,
		setHasUnsavedChanges,
		getDeclarationById,
	} = useContext(DeclarationsContext);

	const handleSaveClick = async () => {
		console.log(loadingAction)
		if (loadingAction) {
			return;
		}
		console.log('saving')

		setLoadingAction("save");

		await updateDeclaration(state.declaration._id, { data: state.declaration.data });

		setLoadingAction(null);
		setToast({ text: t("declarationSaveSuccess"), variant: "success" });
		setIsOpen(false);

		setHasUnsavedChanges(false);
	};

	const handleDuplicateClick = async () => {
		if (loadingAction) {
			return;
		}

		setLoadingAction("duplicate");

		const createdDeclaration = await createDeclaration({
			data: state.declaration.data,
			description: `Duplicate of ${getDeclarationLabel(state.declaration)}`,
		});

		setLoadingAction(null);
		setIsOpen(false);

		updateQueryParam(DECLARATION_ID_QUERY_PARAM, createdDeclaration._id);
	};

	const handleReloadClick = async (isConfirmed) => {
		setIsOpen(false);

		if (!isConfirmed && state.hasUnsavedChanges) {
			setIsUnsavedChangesModalOpen(true);
		} else {
			await getDeclarationById(state.declaration._id, { forceReload: true, selectDeclaration: true });

			setIsUnsavedChangesModalOpen(false);
			setHasUnsavedChanges(false);
		}
	};

	const handleDeleteClick = async () => {
		if (loadingAction) {
			return;
		}

		setLoadingAction("delete");

		await deleteDeclaration(state.declaration._id);

		setLoadingAction(null);
		setIsDeleteConfirmModalOpen(false);

		removeQueryParam(DECLARATION_ID_QUERY_PARAM);
		clearSelectedDeclaration();
		setIsOpen(false);
	};

	const handleGenerareReportClick = async () => {
		setLoadingAction("generateReport");

		try {
			const data = await getDeclarationReport(state.declaration._id);

			console.log(data.url);

			window.open(data.url, "_blank");
		} catch (e) {
			console.log(e);
			setToast({ text: t("generateReportError"), variant: "error" });
		}

		setLoadingAction(null);
		setIsOpen(false)
	};

	return (
		<>
			<UnsavedChangesModal
				isOpen={isUnsavedChangesModalOpen}
				description={t("reloadConfirmWarningDesc")}
				actionLabel={t("reload")}
				onClose={() => setIsUnsavedChangesModalOpen(false)}
				onConfirm={() => handleReloadClick(true)}
			/>

			<NotificationToast
				isOpen={Boolean(toast.text)}
				onClose={() => setToast({ ...toast, text: "" })}
				text={toast.text}
				variant={toast.variant}
			/>

			<DeclarationDetailsModal
				isOpen={isDecDetailsModalOpen}
				onClose={() => setIsDecDetailsModalOpen(false)}
			/>

			<ConfirmationModal
				isOpen={isDeleteConfirmModalOpen}
				title={t("areYouSure")}
				description={t("deleteDeclarationConfirm")}
				primaryActionLabel={t("deleteDeclaration")}
				isActionLoading={loadingAction === "delete"}
				onPrimaryActionClick={handleDeleteClick}
				onClose={() => setIsDeleteConfirmModalOpen(false)}
			/>

			<Popover
				style={{ paddingRight: 0, paddingLeft: 0, paddingTop: 8, paddingBottom: 8 }}
				isOpen={isOpen}
				targetRef={targetRef}
				onClose={() => setIsOpen(false)}
			>
				<PopoverOption onClick={() => handleReloadClick()}>{t("reload")}</PopoverOption>
				<PopoverOption
					isDisabled={isDeclarationReadOnly()}
					isLoading={loadingAction === "save"}
					onClick={handleSaveClick}
				>
					<Badge
						style={{ position: "static", marginRight: 8 }}
						isCompact
						isHidden={!state.hasUnsavedChanges}
					>
						<div></div>
					</Badge>

					{t("saveChanges")}
				</PopoverOption>
				<PopoverOption onClick={handleDuplicateClick} isLoading={loadingAction === "duplicate"}>
					{t("duplicateDeclaration")}
				</PopoverOption>
				<PopoverOption
					isDisabled={!state.declaration.mrn || state.declaration.status !== "cleared"}
					onClick={() => {
						setIsDecDetailsModalOpen(true);
						setIsOpen(false);
					}}
				>
					{t("viewDeclarationDetails")}
				</PopoverOption>
				<PopoverOption
					isLoading={loadingAction === "generateReport"}
					onClick={handleGenerareReportClick}
					isDisabled={!state.declaration.mrn || state.declaration.status !== "cleared"}
				>
					{t("generateDeclarationReport")}
				</PopoverOption>
				<PopoverOption
					onClick={() => {
						setIsDeleteConfirmModalOpen(true);
						setIsOpen(false);
					}}
				>
					{t("deleteDeclaration")}
				</PopoverOption>
			</Popover>

			<Badge
				style={{ top: 4, right: 4, width: 10, height: 10 }}
				isCompact
				isHidden={!state.hasUnsavedChanges}
			>
				<div ref={targetRef}>
					<Button type="icon" onClick={() => setIsOpen(true)}>
						<PiDotsThreeVertical />
					</Button>
				</div>
			</Badge>
		</>
	);
};

export default DeclarationActionsPopover;
