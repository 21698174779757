import grouping from "../../../constants/grouping/final-supplementary.json";
import InputsSection from "../../../components/common/InputsSection/InputsSection";

const FinalSupplementary = () => {
	return (
		<div>
			<div className="d-flex flex-row flex-wrap">
				{grouping[0].sections.map((section, index) => (
					<div
						style={{
							width: section.fullWidth ? "100%" : "50%",
						}}
					>
						<div style={{ marginLeft: 8, marginRight: 8 }}>
							<InputsSection key={index} page="main" {...section} />
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default FinalSupplementary;
