import { useContext } from "react";
import ThemeContext from "../../../context/theme/ThemeContext";
import Card from "../../common/Card";

const StatsCard = ({ value, label }) => {
	const { withTheme } = useContext(ThemeContext);

	return (
		<Card>
			<h1 className={withTheme("stats-box-value")}>{value}</h1>
			<div className={withTheme("stats-box-label")}>{label}</div>
		</Card>
	);
};

export default StatsCard;
