import { axiosInstance } from "../index";
import config from "../config";
import { getHeaders } from "./helpers";

const { apiUrl } = config;

export const listLocations = async (workspaceId) => {
	const result = await axiosInstance.get(`${apiUrl}/goods-locations`, { params: { workspaceId }, headers: getHeaders() });

	return result.data.locations;
};

export const createLocation = async (data) => {
	const result = await axiosInstance.post(`${apiUrl}/goods-locations`, data, { headers: getHeaders() });

	return result.data;
};

export const updateLocation = async (locationId, data) => {
	const result = await axiosInstance.put(`${apiUrl}/goods-locations/${locationId}`, data, {
		headers: getHeaders(),
	});

	return result.data.location;
};

export const deleteLocation = async (locationId) => {
	const result = await axiosInstance.delete(`${apiUrl}/goods-locations/${locationId}`, {
		headers: getHeaders(),
	});

	return result.data;
};
