import ContactForm from "../../layouts/declarations/Sections/ContactForm";
import CommodityCodes from "../../layouts/declarations/Sections/CommodityCodes";
import LocationOfGoods from "../../layouts/declarations/Sections/LocationOfGoods";
import { capitalize } from "lodash";
import { SEARCH_TYPE_ELEMENTS } from "../../../constants/GlobalConstants";

export const headerContactSections = ["exporter", "importer", "declarant", "representative"];
export const itemContactSections = ["buyer", "seller"];
export const contactSections = [...itemContactSections, ...headerContactSections];
export const customSections = [...contactSections, "locationOfGoods", "commodityCodes"];

// Retrieve all element names of the passed name of a custom section
export const getCustomSectionElements = (sectionName) => {
	if (contactSections.includes(sectionName)) {
		return SEARCH_TYPE_ELEMENTS.CONTACTS.map((element) => element.name);
	} else if (sectionName === "locationOfGoods") {
		return SEARCH_TYPE_ELEMENTS.GOODS_LOCATIONS.map((element) => element.name);
	} else if (sectionName === "commodityCodes") {
		return SEARCH_TYPE_ELEMENTS.COMMODITY_CODES.map((element) => element.name);
	}
};

export const getCustomSectionLabel = (sectionName) => {
	if (contactSections.includes(sectionName)) {
		return `${capitalize(sectionName)} Contact`;
	} else if (sectionName === "locationOfGoods") {
		return "Location of Goods";
	} else if (sectionName === "commodityCodes") {
		return "Commodity Codes";
	}
};

const CustomSections = (props) => {
	const { section, itemIndex } = props;

	if (contactSections.includes(section))
		return (
			<ContactForm
				formName={capitalize(section)}
				formRole={section}
				itemSection={`contact${capitalize(section)}`}
				itemIndex={itemIndex}
			/>
		);

	if (section === "locationOfGoods") {
		return <LocationOfGoods />;
	}

	if (section === "commodityCodes") {
		return <CommodityCodes itemIndex={itemIndex} />;
	}
};

export default CustomSections;
