import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import config from "../../config";
import UserContext from "../../context/user/UserContext";
import { useQuery } from "../../App";
import MainNavBar from "../../components/navigation/MainNavBar/MainNavBar";
import Button from "../../components/common/Button";
import { LS_SELECTED_WORKSPACE_KEY } from "../../constants/GlobalConstants";

const { cognitoAuthUrl, cognitoClientId, redirect_uri } = config;

const Login = () => {
	const userContext = useContext(UserContext);
	const history = useHistory();
	const query = useQuery();

	const handleLoginClick = () => {
		window.location.replace(
			`${cognitoAuthUrl}/login?client_id=${cognitoClientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${redirect_uri}`
		);
	};

	// Get accessToken from callback URL
	useEffect(() => {
		const code = query.get("code");

		localStorage.removeItem(LS_SELECTED_WORKSPACE_KEY);

		if (code) {
			userContext.getCredentialsFromCode(code);
		}
	}, [history.location]);

	useEffect(() => {
		(async () => {
			const userInfo = await userContext.getUserInfo();

			if (userInfo) {
				history.push("/");
			}
		})();
	}, []);

	return (
		<div>
			<MainNavBar />
			<div style={{ marginTop: 82 }}>
				<h2
					style={{
						padding: "10px",
						textAlign: "center",
						fontWeight: "bold",
					}}
				>
					Welcome to Tax2Cargo!
				</h2>
				<div className="d-flex justify-content-center">
					<Button onClick={handleLoginClick}>Login</Button>
					<Button style={{ marginLeft: 16 }} onClick={() => history.push("/register")}>
						Register
					</Button>
				</div>
			</div>
		</div>
	);
};

export default Login;
