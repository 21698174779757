const identificationOfLocation = [
    {
        "key": "DEFAULT",
        "value": "Please Select"
    },
    {
        "key": "BELBFSBEL",
        "value": "Belfast Airport"
    },
    {
        "key": "BQHBQHBQH",
        "value": "Biggin Hill (London) Airport"
    },
    {
        "key": "BHMBHXBHM",
        "value": "Birmingham Airport"
    },
    {
        "key": "BLKBLKBLK",
        "value": "Blackpool Airport"
    },
    {
        "key": "BRSBRSBRS",
        "value": "Bristol Airport"
    },
    {
        "key": "BZZBZNRAF",
        "value": "Brize Norton RAF Base"
    },
    {
        "key": "CMGCBGCMG",
        "value": "Cambridge Airport"
    },
    {
        "key": "CVTCVTCVT",
        "value": "Coventry Airport"
    },
    {
        "key": "DRLMMEDRL",
        "value": "Durham Tees Valley Airport - Teesside"
    },
    {
        "key": "DYCABZDYC",
        "value": "Aberdeen Airport - Dyce"
    },
    {
        "key": "DYCABZBOND",
        "value": "Aberdeen Airport - Dyce [Bond Offshore Helicopters]"
    },
    {
        "key": "DYCABZBSTW",
        "value": "Aberdeen Airport - Dyce [Bristow Helicopters]"
    },
    {
        "key": "DYCABZCHC",
        "value": "Aberdeen Airport - Dyce [CHC Scotia]"
    },
    {
        "key": "EMAEMAEMA",
        "value": "East Midlands Apt"
    },
    {
        "key": "EATSOUEAT",
        "value": "Southampton Airport - Eastleigh"
    },
    {
        "key": "EDIEDIEDI",
        "value": "Edinburgh Airport"
    },
    {
        "key": "EXEEXTEXE",
        "value": "Exeter Airport"
    },
    {
        "key": "FBOFABFBO",
        "value": "Farnborough Airport"
    },
    {
        "key": "LGWLGWLGW",
        "value": "Gatwick Apt/London"
    },
    {
        "key": "GLWGLAGLW",
        "value": "Glasgow Airport"
    },
    {
        "key": "LHRLHRLHR",
        "value": "London Heathrow Airport"
    },
    {
        "key": "UURBOHUUR",
        "value": "Bournemouth International Airport - Hurn"
    },
    {
        "key": "KMGHUYKMG",
        "value": "Humberside Airport - Kirmington"
    },
    {
        "key": "LCTLCYLCT",
        "value": "London City Airport"
    },
    {
        "key": "LUTLTNLUT",
        "value": "Luton Airport"
    },
    {
        "key": "LYXLYDLYX",
        "value": "London Ashford Airport - Lydd"
    },
    {
        "key": "MNCMANMNC",
        "value": "Manchester Airport"
    },
    {
        "key": "NQYNQYNQY",
        "value": "Newquay Airport"
    },
    {
        "key": "NRWNWINRW",
        "value": "Norwich Airport"
    },
    {
        "key": "NHTLHRRAF",
        "value": "Northolt RAF Base"
    },
    {
        "key": "PNLNCLPNL",
        "value": "Newcastle International Airport - Ponteland"
    },
    {
        "key": "PIKPIKPIK",
        "value": "Prestwick Airport"
    },
    {
        "key": "RS8CWLRS8",
        "value": "Cardiff International Airport - Rhoose"
    },
    {
        "key": "ESHESHESH",
        "value": "Brighton City Airport - Shoreham-by-Sea"
    },
    {
        "key": "SENSENSEN",
        "value": "London Southend Airport"
    },
    {
        "key": "SPKLPLSPK",
        "value": "Liverpool John Lennon Airport - Speke"
    },
    {
        "key": "STNLSASTN",
        "value": "London Stansted Airport"
    },
    {
        "key": "IOMRWYIOM",
        "value": "Ronaldsway Airport (IOM)"
    },
    {
        "key": "LSILSILSI",
        "value": "Sumburgh Airport"
    },
    {
        "key": "YDNLBAYDN",
        "value": "Leeds Bradford Airport - Yeadon"
    },
    {
        "key": "YDNLBAMFL",
        "value": "Leeds Bradford Airport - Yeadon [Multiflight Ltd]"
    },
    {
        "key": "FFOFFDFFO",
        "value": "Fairford RAF Base"
    },
    {
        "key": "FELFLTFEL",
        "value": "Feltwell RAF Base"
    },
    {
        "key": "LAKLKHLAK",
        "value": "Lakenheath RAF Base"
    },
    {
        "key": "LYELYHLYE",
        "value": "Lyneham RAF Base"
    },
    {
        "key": "MLLMDHMLL",
        "value": "Mildenhall RAF Base"
    },
    {
        "key": "HRTMWHHRT",
        "value": "Menwith Hill RAF Base"
    },
    {
        "key": "AGNLHRRRL",
        "value": "Abingdon. Robinsons Relocation Ltd "
    },
    {
        "key": "ADGBHXMCL",
        "value": "Aldridge. Move Corp Ltd"
    },
    {
        "key": "ASFLHRIMI",
        "value": "Ashford. I Move International"
    },
    {
        "key": "BGKLHRRRL",
        "value": "Basingstoke. Robinsons Relocation Ltd"
    },
    {
        "key": "BOYSTNWAC",
        "value": "Botley. White & Company"
    },
    {
        "key": "BMYLBAGBL",
        "value": "Bramley. GB Liners Ltd"
    },
    {
        "key": "BRAFXTAMS",
        "value": "Brandon. Abels Moving Services Ltd "
    },
    {
        "key": "BRAFXTLOX",
        "value": "Brandon. Arrowpak Military Services Ltd"
    },
    {
        "key": "BRAFXTSHW",
        "value": "Brandon. Arrowpak Transport & Warehousing Ltd"
    },
    {
        "key": "BSHNHVGBL",
        "value": "Brighton. GB Liners Ltd"
    },
    {
        "key": "CLTAVOGBL",
        "value": "Cheltenham. GB Liners Ltd"
    },
    {
        "key": "CWMCDFFOX",
        "value": "Cwmbran. Fox Moving & Storage Ltd"
    },
    {
        "key": "DFDTHPBON",
        "value": "Dartford. Kelerbay Ltd t/a Doree Bonner"
    },
    {
        "key": "ENFLSACWG",
        "value": "Enfield. Crown Worldwide Group"
    },
    {
        "key": "ENFLSADOL",
        "value": "Enfield. Dolphin Movers"
    },
    {
        "key": "FBOLHRWAC",
        "value": "Farnborough. White & Company"
    },
    {
        "key": "GTMIMMFNW",
        "value": "Grantham. F&N Worldwide Logistics Ltd"
    },
    {
        "key": "GFRLHRSMS",
        "value": "Greenford. Stephen Morris Shipping Plc"
    },
    {
        "key": "HHELTNJMI",
        "value": "Hemel Hempstead. John Mason International Ltd"
    },
    {
        "key": "HAFBHXGBL",
        "value": "Hereford. GB Liners Ltd"
    },
    {
        "key": "HGRLTNBON",
        "value": "Houghton Regis. Kelerbay Ltd t/a Doree Bonner"
    },
    {
        "key": "HYNLIVJMI",
        "value": "Huyton. John Mason International Ltd"
    },
    {
        "key": "LANBARWAC",
        "value": "Lancaster. White & Company"
    },
    {
        "key": "LBALBACWG",
        "value": "Leeds. Crown Worldwide Group"
    },
    {
        "key": "TONEDICWG",
        "value": "Livingston. Crown Worldwide Group"
    },
    {
        "key": "LYNDOGSSW",
        "value": "Lympne. Seven Seas Worldwide Ltd"
    },
    {
        "key": "ME5LGWBMI",
        "value": "Merstham. Britannia Movers International Plc"
    },
    {
        "key": "MLLFXTEG",
        "value": "Mildenhall. Euro Group International Movers Ltd"
    },
    {
        "key": "MLLFXTPFS",
        "value": "Mildenhall. P&F Safepac"
    },
    {
        "key": "PYALHRCTL",
        "value": "Park Royal. Cadogan Tate Ltd"
    },
    {
        "key": "PYALHRSFR",
        "value": "Park Royal. Santa Fe Relocation (UK) Ltd"
    },
    {
        "key": "PCUEDIBIS",
        "value": "Penicuik. Bishops Move"
    },
    {
        "key": "PESNHVRWL",
        "value": "Pevensey. Relo-Worldwide Ltd"
    },
    {
        "key": "PMEPTMWAC",
        "value": "Portsmouth. White & Company"
    },
    {
        "key": "RDDBHXRRL",
        "value": "Redditch. Robinsons Relocation Ltd"
    },
    {
        "key": "RMFLONGCC",
        "value": "Romford. Geo Copsey & Co Ltd"
    },
    {
        "key": "RUILHRCWG",
        "value": "Ruislip. Crown Worldwide Group"
    },
    {
        "key": "SLLLHRGBL",
        "value": "Southall. GB Liners Ltd"
    },
    {
        "key": "STWFXTGRS",
        "value": "Stowmarket. Greens Removals & Storage Ltd"
    },
    {
        "key": "SFDMANAPI",
        "value": "Stretford. Anglo Pacific International Ltd"
    },
    {
        "key": "SURLHRBIS",
        "value": "Surbiton. Bishops Move"
    },
    {
        "key": "SWBTHPSRS",
        "value": "Swanscombe. Simpsons Removals & Storage Ltd"
    },
    {
        "key": "TEFBHXWAC",
        "value": "Telford. White & Company"
    },
    {
        "key": "TFDFXTARR",
        "value": "Thetford. Arrowdene"
    },
    {
        "key": "TLGSTNFOX",
        "value": "Totton. Fox Moving & Storage Ltd"
    },
    {
        "key": "UDDGLWAPI",
        "value": "Uddingston. Anglo Pacific International Ltd"
    },
    {
        "key": "UXBLHRAGS",
        "value": "Uxbridge. AGS Ltd"
    },
    {
        "key": "WACLONWAC",
        "value": "Waltham Cross. White & Company"
    },
    {
        "key": "WAFLTNERL",
        "value": "Watford. Eagle Relocations Ltd"
    },
    {
        "key": "WBYLHRAPI",
        "value": "Wembley. Anglo Pacific International Ltd"
    },
    {
        "key": "WBYLHREIM",
        "value": "Wembley. Excess International Movers Ltd"
    },
    {
        "key": "WBYLHRPIC",
        "value": "Wembley. Pickfords Move Management Ltd"
    },
    {
        "key": "WNESTNWAC",
        "value": "Winchester. White & Company"
    },
    {
        "key": "WIHLONGRS",
        "value": "Witham. Greens Removals & Storage Ltd"
    },
    {
        "key": "WOHCVTEGI",
        "value": "Woodford Halse. Euro Group International Movers Ltd"
    },
    {
        "key": "EMAEMABIP",
        "value": "East Midlands Airport. North West Leicestershire District Council"
    },
    {
        "key": "LHRLHRBIP",
        "value": "London Heathrow Airport. Corporation of London"
    },
    {
        "key": "STNLSABIP",
        "value": "London Stansted Airport. Stansted Airport Border Inspection Post Limited"
    },
    {
        "key": "ABPCWLABP ",
        "value": "West Wales Airport - Aberporth "
    },
    {
        "key": "ALRLHRSTO ",
        "value": "Brimpton Airfield - Aldermaston  "
    },
    {
        "key": "VLYHLDVLY ",
        "value": "Mona Aerodrome - Anglesey "
    },
    {
        "key": "ASDDOVASD ",
        "value": "Lashenden / Headcorn Aerodrome - Ashford "
    },
    {
        "key": "AWLFXTBAC ",
        "value": "Old Buckenham Airfield - Attleborough "
    },
    {
        "key": "AWLFXTTAC ",
        "value": "Old Buckenham Airfield - Attleborough "
    },
    {
        "key": "LPREDILPR",
        "value": "Perth Airport - Balbeggie"
    },
    {
        "key": "BIFLIVBWF",
        "value": "Walney Aerodrome - Barrow in Furness"
    },
    {
        "key": "BNOBHXBNO ",
        "value": "Tatenhill Airfield - Barton-under-Needwood"
    },
    {
        "key": "BSFEMABSF",
        "value": "Nottingham City Airport - Bassingfield"
    },
    {
        "key": "BTTLHRBTT",
        "value": "London Heliport - Battersea"
    },
    {
        "key": "BCCFXTBCC",
        "value": "Beccles Airfield"
    },
    {
        "key": "BEFLTNBEF",
        "value": "Thurleigh Airfield - Bedford"
    },
    {
        "key": "BBPPTMBBP",
        "value": "Bembridge Airfield [IoW]"
    },
    {
        "key": "BIDPLHBID",
        "value": "Bideford - Portledge Helipad"
    },
    {
        "key": "BBSLHRBBS",
        "value": "Blackbushe Airport"
    },
    {
        "key": "BLKLIVBLK",
        "value": "Blackpool Airport"
    },
    {
        "key": "BB2BHXBB2",
        "value": "Wolverhampton Halfpenny Green Airport - Bobbington"
    },
    {
        "key": "BDMPLYBDM",
        "value": "Bodmin Airfield"
    },
    {
        "key": "LTDPLYLTD",
        "value": "Bolt Head Airfield"
    },
    {
        "key": "BCKLTNBCK",
        "value": "Turweston Aerodrome - Brackley "
    },
    {
        "key": "CNVHLDCNV",
        "value": "Caernarfon Airport"
    },
    {
        "key": "CNBDOVCNB",
        "value": "Maypole Airfield - Canterbury"
    },
    {
        "key": "CAXLIVCAX",
        "value": "Carlisle Airport"
    },
    {
        "key": "CGVLHRCGV",
        "value": "Chalgrove Airfield"
    },
    {
        "key": "CPNBRSCPN",
        "value": "Garstom Farm Airfield - Chippenham"
    },
    {
        "key": "CHBLHRCHB",
        "value": "Fairoaks Airport - Chobham"
    },
    {
        "key": "CLSHRHCLS",
        "value": "Clacton-on-Sea Airfield"
    },
    {
        "key": "CRFLTNCRF",
        "value": "Cranfield Airport"
    },
    {
        "key": "CLDGLACLD",
        "value": "Cumbernauld Airport"
    },
    {
        "key": "DHMLHRDHM",
        "value": "Denham Aerodrome"
    },
    {
        "key": "DIDLHRDID",
        "value": "North Moreton Airstrip - Didcot"
    },
    {
        "key": "DONDSADON",
        "value": "Robin Hood Doncaster Sheffield Airport - Doncaster"
    },
    {
        "key": "DUNDUNDUN",
        "value": "Dundee Airport"
    },
    {
        "key": "DKWPLHDKW",
        "value": "Dunkeswell Airfield [Honiton]"
    },
    {
        "key": "DXFLSADXF",
        "value": "Duxford Airfield"
    },
    {
        "key": "ECOHRHECO",
        "value": "Earls Colne Airfield"
    },
    {
        "key": "ECSMANECS",
        "value": "Manchester Barton City Airport - Eccles"
    },
    {
        "key": "ETRLHRETR",
        "value": "London Elstree Aerodrome - Elstree"
    },
    {
        "key": "ENKBFSENK",
        "value": "St Angelo Airport - Enniskillen"
    },
    {
        "key": "FGNTHPFGN",
        "value": "Brands Hatch Helipads - Fawkham Green"
    },
    {
        "key": "FODLHRFOD",
        "value": "Old Sarum Airfield - Ford"
    },
    {
        "key": "FRELSAFRE",
        "value": "Fowlmere Aerodrome"
    },
    {
        "key": "FSTLBAFST",
        "value": "Full Sutton Airfield"
    },
    {
        "key": "FR4LHRFR4",
        "value": "Frensham – Wishanger Park"
    },
    {
        "key": "GAIDSAGAI",
        "value": "Sturgate Aerodrome - Gainsborough"
    },
    {
        "key": "GMTDSAGMT",
        "value": "Retford (Gamston) Airport - Gamston"
    },
    {
        "key": "BHDBHDBHD",
        "value": "George Best Belfast City Apt / Belfast"
    },
    {
        "key": "GLOGLOGLO",
        "value": "Gloucester (Staverton) Airport"
    },
    {
        "key": "QUGPTMQUG",
        "value": "Goodwood Aerodrome"
    },
    {
        "key": "GSLLSAGSL",
        "value": "Saling Airfield - Great Saling"
    },
    {
        "key": "HDLFXTHDL",
        "value": "Elmsett Airfield - Hadleigh"
    },
    {
        "key": "HVRCWLHVR",
        "value": "Haverfordwest Airport"
    },
    {
        "key": "HWNHLDHWN",
        "value": "Chester Airport - Hawarden"
    },
    {
        "key": "RRSBRSRRS",
        "value": "Henstridge Airfield"
    },
    {
        "key": "HMELSAHME",
        "value": "Peterborough (Conington) Business Airfield - Holme "
    },
    {
        "key": "INVINVINV",
        "value": "Inverness Airport"
    },
    {
        "key": "ISCPLYISC",
        "value": "St Mary's Airport - Isles of Scilly"
    },
    {
        "key": "KEMKEMKEM",
        "value": "Kemble Airfield"
    },
    {
        "key": "KZOLHRKZO",
        "value": "Oxford Airport - Kidlington"
    },
    {
        "key": "KMTLHRKMT",
        "value": "Thruxton Aerodrome - Kimpton"
    },
    {
        "key": "KWLKWLKWL",
        "value": "Kirkwall Airport"
    },
    {
        "key": "LATBRSLAT",
        "value": "Curry Rivel Airstrip - Langport"
    },
    {
        "key": "LORDSALOR",
        "value": "Wickenby Aerodome - Langworth"
    },
    {
        "key": "QLALASATC",
        "value": "Lasham Airfield"
    },
    {
        "key": "QLALASLGS",
        "value": "Lasham Airfield"
    },
    {
        "key": "LEDBRSLED",
        "value": "Berrow Airfield - Ledbury"
    },
    {
        "key": "LESPTMLES",
        "value": "Daedalus [Solent] Airfield - Lee-on-Solent"
    },
    {
        "key": "LCSEMALCS",
        "value": "Leicester Airport"
    },
    {
        "key": "LWENHVLWE",
        "value": "Deanland Airfield - Lewes"
    },
    {
        "key": "LDYLDYLDY",
        "value": "City of Derry Airport - Londonderry"
    },
    {
        "key": "MMBLHRMMB",
        "value": "Manor Farm - Malmesbury"
    },
    {
        "key": "MLWLHRMLW",
        "value": "Wycombe Air Park - Marlow"
    },
    {
        "key": "MDSLHRMDS",
        "value": "Popham Airfield - Micheldever"
    },
    {
        "key": "NPTCWLNPT",
        "value": "Newport City Aerodrome"
    },
    {
        "key": "NWLLSANWL",
        "value": "North Weald [Bassett] Airfield"
    },
    {
        "key": "PMYCWLPMY",
        "value": "Wales West Airport - Pembrey"
    },
    {
        "key": "PNPPLYPNP",
        "value": "Perranporth Airfield"
    },
    {
        "key": "REHLGWREH",
        "value": "Redhill Aerodrome"
    },
    {
        "key": "RCSTHPRCS",
        "value": "Rochester Airport"
    },
    {
        "key": "SAWLSASAW",
        "value": "Audley End Airfield – Saffron Walden"
    },
    {
        "key": "SIHLHRSIH",
        "value": "Saint Athan - RAF MOD Base"
    },
    {
        "key": "SJUPLHSJU",
        "value": "Lands End Aerodrome - Saint Just "
    },
    {
        "key": "SD5DSASD5",
        "value": "Sandtoft Airfield"
    },
    {
        "key": "SDOPTMSDO",
        "value": "Sandown – IoW Airport"
    },
    {
        "key": "SCSABZSCS",
        "value": "Scatsta Heliport"
    },
    {
        "key": "STGFXTSTG",
        "value": "Seething Airfield"
    },
    {
        "key": "SHBSOUSHB",
        "value": "Compton Abbas Airfield - Shaftesbury"
    },
    {
        "key": "SBLLBASBL",
        "value": "Sherburn Airfield - Sherburn in Elmet"
    },
    {
        "key": "SHHFXTSHH",
        "value": "Shipdham Airfield"
    },
    {
        "key": "SIYBHXSIY",
        "value": "Woods Farm, Shirley"
    },
    {
        "key": "EENBHXEEN",
        "value": "Shobdon Airfield"
    },
    {
        "key": "SPKLPLSPK",
        "value": "Speke -  Liverpool John Lennon Airport"
    },
    {
        "key": "SBALONSBA",
        "value": "Stapleford Airfield - Stapleford Abbots "
    },
    {
        "key": "OKEDSAOKE",
        "value": "Gamston Airfield - Stokeham"
    },
    {
        "key": "STOSTOSTO",
        "value": "Stornoway Airport"
    },
    {
        "key": "SJYPOOSJY",
        "value": "Knoll House - Studland"
    },
    {
        "key": "SSJFXTSSJ",
        "value": "Fenland Airfield - Sutton Saint James"
    },
    {
        "key": "SWACWLSWA",
        "value": "Swansea Airport"
    },
    {
        "key": "SYWLTNSYW",
        "value": "Sywell [Northampton] Aerodrome"
    },
    {
        "key": "UMBPLHUMB",
        "value": "Eaglescott Airfield - Umberleigh"
    },
    {
        "key": "WRTWARBAE",
        "value": "Warton Aerodrome"
    },
    {
        "key": "WSUBHXWSU",
        "value": "Wellesbourne Mountford Airfield"
    },
    {
        "key": "WPSHLDWPS",
        "value": "Welshpool Aerodrome"
    },
    {
        "key": "WGCLTNWGC",
        "value": "Panshanger Aerodrome - Welwyn Garden City"
    },
    {
        "key": "WNRBHXWNR",
        "value": "Sleap Airfield - Wem [Shrewsbury]"
    },
    {
        "key": "WWMLHRWWM",
        "value": "White Waltham Airfield"
    },
    {
        "key": "WICWIKWIC",
        "value": "Wick Airport"
    },
    {
        "key": "WRKDSAWRK",
        "value": "Netherthorpe Airfield - Worksop"
    },
    {
        "key": "YEOYVLYEO",
        "value": "Yeovil Aerodrome"
    },
    {
        "key": "BELLCACSE",
        "value": "Belfast, Carne House, 20 Corry Place, Belfast, BT3 9HY"
    },
    {
        "key": "NTGLDACSE",
        "value": "Nottingham, Bowman House, Talbot Street, Nottingham, NG1 5NF"
    },
    {
        "key": "BHMLDJCSE",
        "value": "Birmingham"
    },
    {
        "key": "ingham",
        "value": "Wolverhampton"
    },
    {
        "key": "DXYLDNCSE",
        "value": "Derby"
    },
    {
        "key": "ABDLEACSE",
        "value": "Aberdeen, 28 Guild Street, Aberdeen, AB11 6GY"
    },
    {
        "key": "FEMLHRCSE",
        "value": "Heathrow, Wayfarer House, Great South West Road, Feltham, Middlesex, TW14 8NP"
    },
    {
        "key": "STFLHBCSE",
        "value": "East London Business (E & IT, Jubilee House, Stratford)"
    },
    {
        "key": "SWRLHCCSE",
        "value": "Inner Excise (LBG, Dorset House)"
    },
    {
        "key": "SWRLHECSE",
        "value": "EICS District B (E & IT, Dorset House)"
    },
    {
        "key": "UXBLHFCSE",
        "value": "Uxbridge (E & IT)"
    },
    {
        "key": "ESDLHHCSE",
        "value": "Willesden"
    },
    {
        "key": "SWRLHGCSE",
        "value": "Waterloo"
    },
    {
        "key": "LBALAACSE",
        "value": "Leeds, Peter Bennett House, Redvers Close, Leeds, LS16 6RQ"
    },
    {
        "key": "YRKLDCCSE",
        "value": "York"
    },
    {
        "key": "CAXLAECSE",
        "value": "Carlisle"
    },
    {
        "key": "MIDLAFCSE",
        "value": "Middlesbrough"
    },
    {
        "key": "IMMLAGCSE",
        "value": "Immingham"
    },
    {
        "key": "GSYLAICSE",
        "value": "Grimsby"
    },
    {
        "key": "SHELAJCSE",
        "value": "Sheffield"
    },
    {
        "key": "NCLLAOCSE",
        "value": "Newcastle"
    },
    {
        "key": "LIVLANCSE",
        "value": "Liverpool"
    },
    {
        "key": "BLBLAQ1CSE",
        "value": "Blackburn Lancashire"
    },
    {
        "key": "ELGLEBCSE",
        "value": "Elgin Excise"
    },
    {
        "key": "AYRLECCSE",
        "value": "Ayr"
    },
    {
        "key": "GRGLEDCSE",
        "value": "Grangemouth"
    },
    {
        "key": "COTLEECSE",
        "value": "Coatbridge"
    },
    {
        "key": "GLWLEFCSE",
        "value": "Glasgow Excise"
    },
    {
        "key": "DUNLEGCSE",
        "value": "Dundee"
    },
    {
        "key": "EDILEHCSE",
        "value": "LBG Scotland East (Edinburgh)"
    },
    {
        "key": "GLWLEJCSE",
        "value": "LBG Scotland West (Glasgow)"
    },
    {
        "key": "SOULFGCSE",
        "value": "Southampton, Compass House, Romsey Road, Maybush, Southampton, SO14 4HP"
    },
    {
        "key": "BRSLFECSE",
        "value": "Bristol"
    },
    {
        "key": "GLOLFFCSE",
        "value": "Gloucester"
    },
    {
        "key": "OXFLFHCSE",
        "value": "Oxford"
    },
    {
        "key": "PLYLFJCSE",
        "value": "Plymouth EIC"
    },
    {
        "key": "TAULFICSE",
        "value": "Taunton"
    },
    {
        "key": "RDNLGCCSE",
        "value": "Reading, Eldon Court, 75 London Road, Berkshire, RG1 5BS"
    },
    {
        "key": "REHLGBCSE",
        "value": "Redhill"
    },
    {
        "key": "SPPLGDCSE",
        "value": "Shepperton (LBG)"
    },
    {
        "key": "STSLGECSE",
        "value": "Staines"
    },
    {
        "key": "RDNLGFCSE",
        "value": "Reading LBG"
    },
    {
        "key": "SNNLGGCSE",
        "value": "Swindon"
    },
    {
        "key": "MAHLGKCSE",
        "value": "Maidenhead"
    },
    {
        "key": "CNBLGMCSE",
        "value": "Canterbury"
    },
    {
        "key": "WNNLAB1CSE",
        "value": "Washington Tyne & Wear"
    },
    {
        "key": "HULLADCSE",
        "value": "Hull"
    },
    {
        "key": "SFYLAHCSE",
        "value": "Salford"
    },
    {
        "key": "HFXLALCSE",
        "value": "Halifax"
    },
    {
        "key": "CHALAM1CSE",
        "value": "Cheadle Stockport"
    },
    {
        "key": "WGNLARCSE",
        "value": "Wigan"
    },
    {
        "key": "FXTFXTCSE",
        "value": "Felixstowe, Custom House, Viewpoint Road, Felixstowe, Suffolk, IP11 3RF"
    },
    {
        "key": "IPSLDBCSE",
        "value": "Ipswich"
    },
    {
        "key": "NRWLDDCSE",
        "value": "Norwich"
    },
    {
        "key": "CMGLDECSE",
        "value": "Cambridge"
    },
    {
        "key": "PETLDHCSE",
        "value": "Peterborough"
    },
    {
        "key": "LUTLDICSE",
        "value": "Luton"
    },
    {
        "key": "LCSLDLCSE",
        "value": "Leicester"
    },
    {
        "key": "KTRLDMCSE",
        "value": "Kettering"
    },
    {
        "key": "NHPLDOCSE",
        "value": "Northampton"
    },
    {
        "key": "TILTILCSE",
        "value": "Tilbury, Custom House, Tilbury Dock, Tilbury, Essex, RM18 7EJ"
    },
    {
        "key": "BASLDFCSE",
        "value": "Basildon Central"
    },
    {
        "key": "HRLLDGCSE",
        "value": "Harlow Central"
    },
    {
        "key": "CDFLAPCSE",
        "value": "Cardiff, Portcullis House, Cowbridge Road East, Cardiff, CF11 9SS"
    },
    {
        "key": "CEGLBB2CSE",
        "value": "Chester Cheshire West & Chester"
    },
    {
        "key": "SWALBCCSE",
        "value": "Swansea"
    },
    {
        "key": "BA5ABDCGD",
        "value": "Aberdeen 410. Grampian Continental Ltd, Birchwood Works, Kinellar, Aberdeen, AB21 0SH"
    },
    {
        "key": "ZAHABZXCQ",
        "value": "Aberdeen 410. Panalpina World Transport Ltd, Mugiemoss Road, Bucksburn, Aberdeen, AB21 9NP"
    },
    {
        "key": "BELBFSXXX",
        "value": "Belfast 516. DHL International (UK) Ltd, Unit 3B, Edgewater Road, Belfast, BT3 9JQ"
    },
    {
        "key": "CLLBHXXAD",
        "value": "Birmingham 011. Davies Turner & Co Ltd, West Midlands Freight Terminal, Station Road, Birmingham, B46 1DT"
    },
    {
        "key": "SOLBHXXBW",
        "value": "Birmingham 011. DHL Global Forwarding (UK) Ltd, Unit 1, Starley Way, Bickenhill Road, Elmdon, Birmingham, B37 7BH"
    },
    {
        "key": "LHFBHXXCR",
        "value": "Birmingham 011. Hellman Worldwide Logistics Ltd, Kuhlmann House, Lancaster Way, Fradley Park, Lichfield, WS13 8SX"
    },
    {
        "key": "AVOBRSXAD",
        "value": "Bristol 033. Davies Turner & Co Ltd, Western Freight Terminal, Fifth Way, Avonmouth, BS11 8DT"
    },
    {
        "key": "ANFDOGWSP",
        "value": "Dover 060. Channel Ports Ltd, Stop 24, Folkestone Services, Junction 11, M20, Stanford Intersection, Stanford, CT21 4BL"
    },
    {
        "key": "DVRDOGWDG",
        "value": "Dover 060. Motis Ireland Ltd, Freight Clearance Centre, Western Docks, Dover, Kent, CT17 9DN"
    },
    {
        "key": "DVRDOGWNL",
        "value": "Dover 060. Nagel Langdons Limited, 1 Palmerston Road, Port Zone, Dover, Kent, CT16 3NF"
    },
    {
        "key": "LY9DOGWSE",
        "value": "Dover 060. Sealane Freight Ltd, The Freight Terminal, Lydden Hill, Dover, Kent, CT15 7JW"
    },
    {
        "key": "NWBEDIXXX",
        "value": "Edinburgh 436. DHL International (UK) Ltd, Unit 2 Chariot Drive, Newbridge, Edinburgh, EH28 8PA"
    },
    {
        "key": "CWYLGWXBB",
        "value": "Gatwick 120. Allport Cargo Services Ltd, Unit 2, The Faraday Centre, Faraday Road, Crawley, West Sussex, RH10 2PX"
    },
    {
        "key": "RENGLAXBS",
        "value": "Glasgow 444. Airsea Scotland, Eagle House, 159 Wright Street, Renfrew, PA4 8AN"
    },
    {
        "key": "BTIGRKBXD",
        "value": "Glasgow 444. Bullet Express Ltd, 99 Baillieston Road, Glasgow. G32 0TF"
    },
    {
        "key": "BWLGRKBLD",
        "value": "Glasgow 444. Bullet Express Ltd, Bullet House, 5 Ashley Drive, Bothwell. G71 8BS"
    },
    {
        "key": "CLDGLAXAD",
        "value": "Glasgow 444. Davies Turner & Co Ltd, 7 Grayshill Road, Westfield North, Cumbernauld, Glasgow, G68 9HQ"
    },
    {
        "key": "PASGLAXCN",
        "value": "Glasgow 444. DHL Global Forwarding (UK) Ltd, 2 Campsie Drive, Glasgow Airport, Paisley, PA3 2SE"
    },
    {
        "key": "PASGLAXXX",
        "value": "Glasgow 444. DHL International (UK) Ltd, Phoenix Industrial Estate, West Avenue, Paisley, PA1 2FB"
    },
    {
        "key": "PIKPIKGPS",
        "value": "Glasgow 444. Glasgow Prestwick Bond Ltd, Glasgow Prestwick Freight Centre, Prestwick Airport, Ayrshire, KA9 2PL"
    },
    {
        "key": "LHRLHRXBN",
        "value": "Heathrow 120. Air Menzies International Ltd, Unit 2B, Polar Park, Bath Road, Heathrow, UB7 0DA"
    },
    {
        "key": "SLLLHRXBB",
        "value": "Heathrow 120. Allport Ltd, Hayes Road, Southall, Middlesex, UB2 5NJ"
    },
    {
        "key": "FEMLHRXCY",
        "value": "Heathrow 120. Azerbaijan Airlines UK Ltd, T/A ASC Cargo Handling, Unit 8 Radius Park, St Theresa's Road, Hatton Cross, Feltham, Middlesex, TW14 0NG"
    },
    {
        "key": "FEMLHRXCB",
        "value": "Heathrow 120. Bollore' Logistics UK Ltd, 673 Spur Road, North Feltham Trading Estate, Feltham, Middlesex, TW14 0SL"
    },
    {
        "key": "FEMLHRXBF",
        "value": "Heathrow 120. Brinks Ltd, Unit 1, Radius Park, Faggs Road, Hatton Cross, Feltham, TW14 0NG"
    },
    {
        "key": "HOULHRXCU",
        "value": "Heathrow 120. Ceva Freight Ltd, Heathrow Gateway, Godfrey Way, Hounslow, Middlesex, TW4 5WY"
    },
    {
        "key": "FEMLHRXBK",
        "value": "Heathrow 120. Cosmotrans International Ltd, 9 Felthambrook Industrial Estate, Felthambrook Way, Feltham, Middlesex, TW13 7DU"
    },
    {
        "key": "CBRLHRXAD",
        "value": "Heathrow 120. Davies Turner Air Cargo Ltd, Unit A1, Calder Way, Horton Road, Colnbrook, Slough, SL3 0BQ"
    },
    {
        "key": "HMILHRXBW",
        "value": "Heathrow 120. DHL Global Forwarding (UK) Ltd, Danzas House, Kestrel Way, Dawley Road, Hayes, UB3 1HJ"
    },
    {
        "key": "CBRLHRXXD",
        "value": "Heathrow 120. DHL International (UK) Ltd, Southern Hub Facility, Horton Road, Colnbrook, Slough, SL3 0BB"
    },
    {
        "key": "RDNLHRXXX",
        "value": "Heathrow 120. DHL International (UK) Ltd, Unit 14, Island Road, Reading, RG2 0RP"
    },
    {
        "key": "HOULHRXBQ",
        "value": "Heathrow 120. Dunwoody Airline Services, Building 875 & 1081, Scylla Road, Heathrow Airport, Hounslow, Middlesex, TW4 6BG"
    },
    {
        "key": "SLOLHRXBA",
        "value": "Heathrow 120. ECMS Express (UK) Ltd, 241 Gresham Road, Slough Trading Estate, Slough, SL1 4RN"
    },
    {
        "key": "ASFLHRXCZ",
        "value": "Heathrow 120. Geodis FF United Kingdom Ltd, Action Court, Ashford Road, Ashford, Middlesex, TW15 1XS"
    },
    {
        "key": "FEMLHRXDA",
        "value": "Heathrow 120. Geodis FF United Kingdom Ltd, LHR1, 145 Faggs Road, Feltham, Middlesex, TW14 0LZ"
    },
    {
        "key": "CBRLHRXBU",
        "value": "Heathrow 120. Heathrow Cargo Handling Ltd, HCH House, Horton Road, Colnbrook, Slough, SL3 0AT"
    },
    {
        "key": "CBRLHRXCR",
        "value": "Heathrow 120. Hellman Worldwide Logistics Ltd, Unit 6, Hurricane Way, Axis Park, Langley, Slough, SL3 8AG"
    },
    {
        "key": "FEMLHRXCK",
        "value": "Heathrow 120. Heavyweight Air Express Ltd, Unit 1, Pier Road Industrial Estate, North Feltham Trading Estate, Feltham, TW14 0TW"
    },
    {
        "key": "CBRLHRXCA",
        "value": "Heathrow 120. Hemisphere Freight Services Ltd, Unit 4, Trident Industrial Estate, Blackthorne Road, Colnbrook, Slough, Berkshire, SL3 0AX"
    },
    {
        "key": "SWLLHRXAG",
        "value": "Heathrow 120. JAS Forwarding (UK) Ltd, Unit 1 Heathrow Logistics Park, Bedfont Road, Heathrow, TW14 8EE"
    },
    {
        "key": "HOULHRXBV",
        "value": "Heathrow 120. Jalsa (UK) Ltd, Building 875, Scylla Road, Hounslow, TW6 3YH"
    },
    {
        "key": "CBRLHRXAF",
        "value": "Heathrow 120. James Cargo Services Ltd, 9 Galleymad Road, Colnbrook, Slough, Berks, SL3 0EN"
    },
    {
        "key": "HMILHRXDB",
        "value": "Heathrow 120. Martinspeed Ltd, 163 Clayton Road, Middlesex, UB3 1AX"
    },
    {
        "key": "HOULHRXAA",
        "value": "Heathrow 120. Nippon Express, Unit A1/A2 Parkway West, Cranford Lane, Heston, Hounslow, Middlesex, TW5 9NE"
    },
    {
        "key": "CBRLHROND",
        "value": "Heathrow 120. Ontour Logistic Service UK Ltd, Unit 3 McKay, Blackthorne Road, Colnbrook, SL3 0AH"
    },
    {
        "key": "CWYLGWXCS",
        "value": "Heathrow 120. PBS International Ltd, 9 Meadow Brook Industrial Centre, Maxwell Way, Crawley, RH10 9SA"
    },
    {
        "key": "FEMLHRXBC",
        "value": "Heathrow 120. Plane Handling Limited, Unit 8, Radius Park, Faggs Road, Feltham, Middlesex, TW14 0NG"
    },
    {
        "key": "CBRLHRXBD",
        "value": "Heathrow 120. PRS Distribution Ltd, Valerie House, Unit 6, Horton Road, Colnbrook, Slough, SL3 0BB"
    },
    {
        "key": "CBRLHRXBR",
        "value": "Heathrow 120. Regency Shipping Ltd, Unit 15, Trident Industrial Estate, Blackthorne Road, Colnbrook, SL3 0AX"
    },
    {
        "key": "FEMLHRXCQ",
        "value": "Heathrow 120. Panalpina World Transport Ltd, Panalpina House, Great South West Road, Feltham, TW14 8NU"
    },
    {
        "key": "FEMLHRXAL",
        "value": "Heathrow 120. Schenker Limited, Unit 7, Radius Park, Faggs Road, Feltham, Middlesex TW14, 0NG"
    },
    {
        "key": "CBRLHRXAI",
        "value": "Heathrow 120. Sovereign Speed (UK) Ltd, Unit B2X, Skyway 14, Calder Way, Colnbrook, SL3 0BQ"
    },
    {
        "key": "SLLLHRXZY",
        "value": "Heathrow 120. Shuttlebridge Logistics Ltd, Unit 6C, Beaver Ind Park, Southall, Middlesex, UB2 5FB"
    },
    {
        "key": "CBRLHRXAJ",
        "value": "Heathrow 120. Strategic Shipping Co Ltd, Unit 6, McKay Trading Estate, Blackthorne Road, Colnbrook, Slough SL3 0AH"
    },
    {
        "key": "FEMLHRXAC",
        "value": "Heathrow 120. Transglobal Freight Management, International House, Girling Way, Great South West Road, Feltham, Middlesex, TW14 0PH"
    },
    {
        "key": "HESLHRXAH",
        "value": "Heathrow 120. UPS SCS (UK) Ltd, Unit 19, Airlinks Ind Est, Spitfire Way, Heston, Middlesex, TW5 9NR"
    },
    {
        "key": "PLELHRXDC",
        "value": "Heathrow 120. UPS SCS (UK) Ltd, Unit 1, Blackthorne Road, Poyle, Berkshire, SL3 0DA"
    },
    {
        "key": "HOULHRXAH",
        "value": "Heathrow 120. UPS SCS (UK) Ltd, (Freightnet Handling Ltd), X2, Unit 5 & 6, Hatton Cross, Heathrow Airport, TW6 2GE"
    },
    {
        "key": "HOULHRXBT",
        "value": "Heathrow 120. Westgate Handling Services Ltd, Unit 7, X2 Centre, Hatton Cross, Hounslow, TW6 2GE"
    },
    {
        "key": "ASFLHROWD",
        "value": "Heathrow. X One Wholesale Ltd, Unit 9 Ashford Industrial Estate, Shield Road, Ashford, TW15 1AU"
    },
    {
        "key": "LUTLTNXBG",
        "value": "Luton 223. Aircargo & Container Service, Unit H, Airport Executive Park, President Way, Luton Airport, Luton, LU2 9NL"
    },
    {
        "key": "MANMANXBM",
        "value": "Manchester 191. Air Menzies International Ltd, Building 314, World Freight Terminal, Manchester Airport, M90 5EX"
    },
    {
        "key": "MANMANXBB",
        "value": "Manchester 191. Allport Cargo Services Ltd, Unit 704, Kingspark, Kingsmead Place, Trafford Park, Manchester, M17 1PH"
    },
    {
        "key": "SFDMANXDH",
        "value": "Manchester 191. Charles Kendall Freight Ltd, Units 10 & 11 The Furrows, Off Barton Dock Road, Stretford, Manchester, M32 0SZ"
    },
    {
        "key": "MANMANXAD",
        "value": "Manchester 191. Davies Turner Air Cargo Ltd, Unit 10, Building 301, World Freight Terminal, Manchester Airport, Manchester, M90 5UX"
    },
    {
        "key": "TFKMANXCL",
        "value": "Manchester 191. Davies Turner Air Cargo Ltd, Manchester International Freight Terminal, Westing House Road, Trafford Park, Manchester, MZ17 1DP"
    },
    {
        "key": "ACMMANXXX",
        "value": "Manchester 191. DHL International (UK) Ltd, 2 Sunbank Lane, Airport City, Manchester, Altrincham, WA15 0AF"
    },
    {
        "key": "BRFMANXCJ",
        "value": "Manchester 191. Redhead Freight Ltd, Dealburn Road, Low Moor, Bradford, BD12 0RG"
    },
    {
        "key": "MANMANXBJ",
        "value": "Manchester 191. Sandalwest Ltd TA/A TQ Express, Unit 7, Building 301, World Freight Terminal, Manchester Airport, M90 5TQ"
    },
    {
        "key": "RCSRCSXDI",
        "value": "Medway 065. Charles Kendall Freight Ltd, Liberty House, London Thamesport, Isle of Grain, Rochester, Kent, ME3 0EP"
    },
    {
        "key": "DFDLGPXAD",
        "value": "Medway 065. Davies Turner & Co Ltd, Dartford Freight Terminal, Edison’s Park, Crossways, Dartford, Kent DA2 6QJ"
    },
    {
        "key": "DFDTHPEUD",
        "value": "Medway 065. Europa Road Ltd, Littlebrook DCI, Shield Road, Kent, DA1 5RU"
    },
    {
        "key": "LRKMEDWSG",
        "value": "Medway 065. TTS Logistics Limited, Unit 2 Invicta Riverside, New Hythe Lane, Larkfield, Kent, ME20 7FG"
    },
    {
        "key": "BSTLSAXXX",
        "value": "Stansted 121. DHL International UK Ltd, Unit 5 Vision Stansted Ind Est, Mead Way, off Dunmow Road, Great Hallingbury, Bishops Stortford, CM22 7FD"
    },
    {
        "key": "CLPTILCTD",
        "value": "Tilbury 150. Crown Fine Art Ltd, Union Court, 20-22 Union Road, London, SW4 6JP"
    },
    {
        "key": "LAILONXAM",
        "value": "Tilbury 150. Redhead Freight Limited, Unit 3, Juniper Park, Fenton Way, Southfields Industrial Estate, Laindon, Essex, SS15 6RZ"
    },
    {
        "key": "POEABZEBX",
        "value": "Aberdeen. Crane Worldwide UK Ltd"
    },
    {
        "key": "ABDABDLUE",
        "value": "Aberdeen. Lubbers (UK) Limited"
    },
    {
        "key": "ADGBHXEJX",
        "value": "Aldridge. NTG EBREX UK Limited"
    },
    {
        "key": "AVOBRSGEX",
        "value": "Almondsbury. Agility Logistics Limited"
    },
    {
        "key": "ANTBFSIFX",
        "value": "Antrim. IFS Global Logistics Limited"
    },
    {
        "key": "AVOAVOBAC",
        "value": "Avonmouth. Dunwoody Airline Services Limited T/A Worldwide Flight Services"
    },
    {
        "key": "AVOBRSAEX",
        "value": "Avonmouth. DHL Global Forwarding (UK) Limited"
    },
    {
        "key": "AVOBRSCFX",
        "value": "Avonmouth. Concordia International Forwarding Limited"
    },
    {
        "key": "AVOBRSGWX",
        "value": "Avonmouth. GEFCO Forwarding UK Limited"
    },
    {
        "key": "AVOBRSFSX",
        "value": "Avonmouth. DSV Air and Sea Limited"
    },
    {
        "key": "AVOBRSKNX",
        "value": "Avonmouth. Kuehne + Nagel Limited"
    },
    {
        "key": "AVOBRSEXX",
        "value": "Avonmouth. Expeditors International (UK) Limited"
    },
    {
        "key": "AVOAVODTA",
        "value": "Avonmouth. Davies Turner & Co. Limited"
    },
    {
        "key": "AYSTHPHZN1",
        "value": "Aylesford. Horizon International Cargo Limited"
    },
    {
        "key": "BKGLONPZL1",
        "value": "Barking [London]. K.W.S. (Distribution) Limited"
    },
    {
        "key": "BKGLONKWS1",
        "value": "Barking [London]. K.W.S. (Distribution) Limited"
    },
    {
        "key": "BKGLONPZX1",
        "value": "Barking [London]. Uneek Forwarding Limited"
    },
    {
        "key": "BKGLONPYP1",
        "value": "Barking [London]. W.E.Deane Limited"
    },
    {
        "key": "BASLONPPF1",
        "value": "Basildon. Phoenix Freight International Limited"
    },
    {
        "key": "BASLONPXN1",
        "value": "Basildon. W.N.D. Limited"
    },
    {
        "key": "BASLONGEO1",
        "value": "Basildon. Geodis Wilson UK Limited"
    },
    {
        "key": "BASLONPXX1",
        "value": "Basildon. Bronel Group limited"
    },
    {
        "key": "BELBFSCMX",
        "value": "Belfast. Campbell McCleave & Company Limited"
    },
    {
        "key": "BELBFSCRX",
        "value": "Belfast. Cargo Air Freight Limited"
    },
    {
        "key": "BELBELDHX",
        "value": "Belfast. DHL International (UK) Limited"
    },
    {
        "key": "BELBFSCUX",
        "value": "Belfast. DSV Air & Sea Limited"
    },
    {
        "key": "LISBFSEOX",
        "value": "Belfast. Eos Warehousing Ltd"
    },
    {
        "key": "BFSBFSPBE",
        "value": "Belfast. Royal Mail Group Limited T/A Parcelforce Worldwide"
    },
    {
        "key": "BELBELHLX",
        "value": "Belfast. Campbell McCleave & Company Limited"
    },
    {
        "key": "BELBELCAM1",
        "value": "Belfast. Campbell McCleave & Company Limited"
    },
    {
        "key": "BELBELHYX",
        "value": "Belfast. PING Handling Limited"
    },
    {
        "key": "BELBELVKT2",
        "value": "Belfast. All Route Shipping (NI) Limited"
    },
    {
        "key": "BELBELUPS1",
        "value": "Belfast. UPS Limited"
    },
    {
        "key": "BELBELVRB1",
        "value": "Belfast. Hamilton Shipping (Container Services) Limited"
    },
    {
        "key": "BELBELCAF1",
        "value": "Belfast. Cargo Forwarding Limited"
    },
    {
        "key": "BELBFSRXX",
        "value": "Belfast. Schenker "
    },
    {
        "key": "CCVBELDPB",
        "value": "Belfast. Interlink Ireland Limited t/a DPD Ireland"
    },
    {
        "key": "ANTBELDPK",
        "value": "Belfast. Interlink Ireland Limited t/a DPD Ireland"
    },
    {
        "key": "MLKBELDPM",
        "value": "Belfast. Interlink Ireland Limited t/a DPD Ireland"
    },
    {
        "key": "XLUBELDPL",
        "value": "Belfast. Interlink Ireland Limited t/a DPD Ireland"
    },
    {
        "key": "CRGBELDPC",
        "value": "Belfast. Interlink Ireland Limited t/a DPD Ireland"
    },
    {
        "key": "TOOBELDPT",
        "value": "Belfast. Interlink Ireland Limited t/a DPD Ireland"
    },
    {
        "key": "URLBELHAN",
        "value": "Belfast. Hannon Transport Ltd"
    },
    {
        "key": "BBGBELEOS",
        "value": "Belfast. EOS Warehousing Limited"
    },
    {
        "key": "BHWMIDCOI",
        "value": "Billingham. Containerships (UK) Limited"
    },
    {
        "key": "BHMBHXAEX",
        "value": "Birmingham. DHL Global Forwarding (UK) Limited"
    },
    {
        "key": "BHMBHXLPX",
        "value": "Birmingham. Agility Logistics Limited"
    },
    {
        "key": "BHMBHXBLE1",
        "value": "Birmingham. Agility Logistics Limited"
    },
    {
        "key": "BHMBHXLKX",
        "value": "Birmingham. Atlas Logistics UK Limited"
    },
    {
        "key": "BHMBHXKEX",
        "value": "Birmingham. Kingscote Rojay Limited"
    },
    {
        "key": "ATHBHXRMX",
        "value": "Birmingham. Parcelforce Worldwide"
    },
    {
        "key": "CNKBHXRUX",
        "value": "Birmingham. Rhenus Logistics Limited"
    },
    {
        "key": "BHMBHXTFX",
        "value": "Birmingham. Trilogy Freight"
    },
    {
        "key": "BITLBAFUT1",
        "value": "Birstall. Future Forwarding Company Ltd"
    },
    {
        "key": "BDNNCLNPX",
        "value": "Boldon Colliery. Nippon Express (UK) Limited"
    },
    {
        "key": "BRFLBASUR1",
        "value": "Bradford. Surefreight International Limited"
    },
    {
        "key": "BTAEMAUPI1",
        "value": "Broughton Astley. Pinnacle International Freight Limited"
    },
    {
        "key": "ZAHABZGMX",
        "value": "Bucksburn. Panalpina World Transport Limited"
    },
    {
        "key": "BOCFXTRBF",
        "value": "Burnham on Crouch. Rupert Brown Freight Limited"
    },
    {
        "key": "CFGBFSFDC2",
        "value": "Carrickfergus. Fedex Express UK Transportation Limited"
    },
    {
        "key": "CFGBFSIQX",
        "value": "Carrickfergus. IFS Global Logistics Limited"
    },
    {
        "key": "CADEMAPZX",
        "value": "Castle Donington. Dnata Limited"
    },
    {
        "key": "CADEMAEXX",
        "value": "Castle Donington. Expeditors International (UK) Limited"
    },
    {
        "key": "CADEMANPX",
        "value": "Castle Donington. Nippon Express (UK) Limited"
    },
    {
        "key": "CTFLBAVIE",
        "value": "Castleford. Viamaster International Limited"
    },
    {
        "key": "CEHMANPPX",
        "value": "Cheadle Heath. Panalpina World Transport Limited"
    },
    {
        "key": "CEHMANMPW1",
        "value": "Cheadle Heath. Panalpina World Transport Limited"
    },
    {
        "key": "CFDFXTWIE",
        "value": "Chelmsford. Woodland Global Limited"
    },
    {
        "key": "HEYLHRHOX",
        "value": "Chertsey. Haulystic Ltd"
    },
    {
        "key": "BHIEMAKZX",
        "value": "Coalville Bardon. Amazon UK Services Ltd"
    },
    {
        "key": "COAEMAOIE",
        "value": "Coalville. Oakland International ltd"
    },
    {
        "key": "COTGLAPFL",
        "value": "Coatbridge. Fraser Limited"
    },
    {
        "key": "CLRBFSDDE",
        "value": "Coleraine. WD MEATS"
    },
    {
        "key": "CLLBHXEXX",
        "value": "Coleshill. Expeditors International (UK) Limited"
    },
    {
        "key": "CLLBHXBDT1",
        "value": "Coleshill. Davies Turner & Co. Limited"
    },
    {
        "key": "HTOLHREBX ",
        "value": "Colnbrook. Crane Worldwide"
    },
    {
        "key": "CBRLHRKJX",
        "value": "Colnbrook. F S Mackenzie Limited"
    },
    {
        "key": "CBRLHRHGX",
        "value": "Colnbrook. Global Cargo Services London Limited"
    },
    {
        "key": "CBRLHRFKX",
        "value": "Colnbrook. Global Forwarding Ltd"
    },
    {
        "key": "CBRLHRHSX",
        "value": "Colnbrook. Goldstar Heathrow Ltd"
    },
    {
        "key": "CBRLHRHEX",
        "value": "Colnbrook. Hellman Worldwide Logistics Limited"
    },
    {
        "key": "CBRLHRGGX",
        "value": "Colnbrook. GTS Enterprises Limited T/A Worldnet Shipping"
    },
    {
        "key": "CBRLHRSJX",
        "value": "Colnbrook. Strategic Shipping Company Limited"
    },
    {
        "key": "CBRLHRMJX",
        "value": "Colnbrook. Morgan Air Cargo Limited"
    },
    {
        "key": "CBRLHRUWX",
        "value": "Colnbrook. MIQ Logistics Limited"
    },
    {
        "key": "CBRLHRDTX",
        "value": "Colnbrook. Davies Turner Air Cargo Limited"
    },
    {
        "key": "CBRLHROTX",
        "value": "Colnbrook. Ontour Logistic Service UK LTD"
    },
    {
        "key": "CBRLHRRYX",
        "value": "Colnbrook. Regency Shipping Limited"
    },
    {
        "key": "CBRLHRRNX",
        "value": "Colnbrook. Regency Shipping Limited"
    },
    {
        "key": "CBRLHRHFX",
        "value": "Colnbrook. Hemisphere Freight Services Limited"
    },
    {
        "key": "CBRLHRTFX",
        "value": "Colnbrook. Trilogy Freight Limited"
    },
    {
        "key": "CBRLHROCS2",
        "value": "Colnbrook. Overseas Courier Service (London) Limited"
    },
    {
        "key": "CBRLHRDHX",
        "value": "Colnbrook. DHL International (UK) Limited"
    },
    {
        "key": "CBRLHRQUX",
        "value": "Colnbrook. Oceanteam Logistics Limited"
    },
    {
        "key": "CBRLHRQYX",
        "value": "Colnbrook. Dynamic International Freight Services Limited"
    },
    {
        "key": "CBRLHRLNX",
        "value": "Colnbrook. Linehaul Express Limited"
    },
    {
        "key": "CBRLHRCOX",
        "value": "Colnbrook. Connexion World Cargo Limited"
    },
    {
        "key": "CBRLHRFRX",
        "value": "Colnbrook. Heathrow Cargo Handling Limited (Illegal Immigration Issues)"
    },
    {
        "key": "CBRLHRKWX",
        "value": "Colnbrook. Kintetsu World Express (UK) Limited"
    },
    {
        "key": "CBRLHRAGX",
        "value": "Colnbrook. Air Sea Logistics Limited"
    },
    {
        "key": "CBRLHRJMX",
        "value": "Colnbrook. James Cargo Services Limited"
    },
    {
        "key": "CBRLHRRBX",
        "value": "Colnbrook. Roy Bowles Transport Ltd"
    },
    {
        "key": "CBRLHRWFX",
        "value": "Colnbrook. C H Robinson Worldwide (UK) Limited"
    },
    {
        "key": "CBRLHRSNX",
        "value": "Colnbrook. Horizon International Cargo Ltd"
    },
    {
        "key": "CBRLHRCNO2",
        "value": "Colnbrook. Norsk European Wholesale Ltd"
    },
    {
        "key": "CBRLHRCPR2",
        "value": "Colnbrook. Aramex (UK) Limited"
    },
    {
        "key": "CBRLHRPYX",
        "value": "Colnbrook. Aramex (UK) Limited"
    },
    {
        "key": "CBRLHRTOX",
        "value": "Colnbrook. Turnstone Worldwide Logistics Ltd"
    },
    {
        "key": "CBRLHRKBX",
        "value": "Colnbrook. Mark 3 International Ltd"
    },
    {
        "key": "CBRLHRWVX ",
        "value": "Colnbrook. Worldnet International Ltd"
    },
    {
        "key": "CVTCVTPRX",
        "value": "Coventry. Royal Mail Group t/a Parceforce Worldwide"
    },
    {
        "key": "CVTCVTRMG",
        "value": "Coventry. Royal Mail Group Limited"
    },
    {
        "key": "CVTBHXFZX",
        "value": "Coventry. UPS SCS (UK) Limited"
    },
    {
        "key": "CG9LHRGTX",
        "value": "Cranford. Asendia UK Limited"
    },
    {
        "key": "CG9LHRKPX",
        "value": "Cranford. Kuehne + Nagel Limited"
    },
    {
        "key": "CWYLGWPIX",
        "value": "Crawley. PBS International Freight Limited"
    },
    {
        "key": "8TNBFSFBD2",
        "value": "Crumlin. Fedex Express UK Transportation Limited"
    },
    {
        "key": "CLDGLAGDT",
        "value": "Cumbernauld. Davies Turner Air Cargo Ltd"
    },
    {
        "key": "DAGLONPXP",
        "value": "Dagenham. Kuehne + Nagel Limited"
    },
    {
        "key": "DAGLONPZO1",
        "value": "Dagenham. Companie De Transports (Cotrafi) T/A Gondrand"
    },
    {
        "key": "DFDTHPJWP1",
        "value": "Dartford. Davies Turner & Co. Limited"
    },
    {
        "key": "DFDTHPEUS",
        "value": "Dartford. Europa Worldwide Logistics Limited"
    },
    {
        "key": "DFDDOVEPX",
        "value": "Dartford. Pentagon Freight Services PLC"
    },
    {
        "key": "DFDTHPPZH1",
        "value": "Dartford. Veerstyle Limited T/A CKR Services"
    },
    {
        "key": "DFDTHPTNT3",
        "value": "Dartford. TNT UK Limited"
    },
    {
        "key": "DENMANNNX",
        "value": "Denton. NNR Global Logistics UK Limited"
    },
    {
        "key": "DWYLBAUZX",
        "value": "Dewsbury. UPS SCS (UK) Limited"
    },
    {
        "key": "DRDBHXEGX",
        "value": "Dordon. CEVA Freight (UK) Limited"
    },
    {
        "key": "DYCABZAEX",
        "value": "Dyce. DHL Global Forwarding (UK) Limited"
    },
    {
        "key": "DYCABZBWE",
        "value": "Dyce. Blue Water Shipping UK Limited"
    },
    {
        "key": "DYCABZDAX",
        "value": "Dyce. Dunwoody Airline Services Limited T/A Worldwide Flight Services"
    },
    {
        "key": "DYCABZEGX",
        "value": "Dyce. CEVA Freight (UK) Ltd"
    },
    {
        "key": "DYCABZEPX",
        "value": "Dyce. Pentagon Freight Services PLC"
    },
    {
        "key": "DYCABZEXX",
        "value": "Dyce. Expeditors International (UK) Limited"
    },
    {
        "key": "DYCABZJEX",
        "value": "Dyce. Bollore Logistics UK Limited"
    },
    {
        "key": "DYCABZKNX",
        "value": "Dyce. Kuehne + Nagel Limited"
    },
    {
        "key": "DYCABZLVQ",
        "value": "Dyce. LV Shipping Limited"
    },
    {
        "key": "DYCABZPSA",
        "value": "Dyce. Petrasco Services Limited T/A Petrasco"
    },
    {
        "key": "ECSMANRUX",
        "value": "Eccles. Rhenus Logistics Ltd"
    },
    {
        "key": "EATSTNSAB1",
        "value": "Eastleigh. Harris Transport Limited"
    },
    {
        "key": "EATSTNSCS1",
        "value": "Eastleigh. Roberts Distribution Limited"
    },
    {
        "key": "EATSTNUTI1",
        "value": "Eastleigh. UTI Worldwide (UK) Limited"
    },
    {
        "key": "EATSTNSFM1",
        "value": "Eastleigh. JPI Warehousing (Soton) Limited"
    },
    {
        "key": "EATSTNOAS1",
        "value": "Eastleigh. Oceanair Express Logistics Limited"
    },
    {
        "key": "EATSTNPBN1",
        "value": "Eastleigh. Peters and May Limited"
    },
    {
        "key": "EDIEDITRX",
        "value": "Edinburgh. Trans Global Freight Management Limited"
    },
    {
        "key": "EGHLHRLLX",
        "value": "Egham. Seko Logistics London Limited"
    },
    {
        "key": "EGHLHRYUX",
        "value": "Egham. Yusen Logistics (UK) Limited"
    },
    {
        "key": "EWLFXTHHE",
        "value": "Elmswell. S.J. Hudson, M.C. Hudson & K Hudson t/a Hudson Haulage"
    },
    {
        "key": "DYLBFSAGE",
        "value": "Enniskillen. A1 Global Logistics"
    },
    {
        "key": "EKIGLAEGX",
        "value": "Erskine. CEVA Freight (UK) Ltd"
    },
    {
        "key": "FHMSTNSFL1",
        "value": "Fareham. DSV Road Limited"
    },
    {
        "key": "FNWMANTSX",
        "value": "Farnworth. Stone Logistics Limited"
    },
    {
        "key": "FXTFXTIRE",
        "value": "Felixstowe. Imorex Shipping Services Limited"
    },
    {
        "key": "FXTFXTLDF",
        "value": "Felixstowe. Felixstowe Warehousing Co Limited"
    },
    {
        "key": "FXTFXTPTV",
        "value": "Felixstowe. Pentalver Transport Limited"
    },
    {
        "key": "FXTFXTMSP",
        "value": "Felixstowe. AP Moller-Maersk AS T/A Maersk Line"
    },
    {
        "key": "FXTFXTDFE",
        "value": "Felixstowe. Denholm Global Logistics Limited"
    },
    {
        "key": "FXTFXTGMF",
        "value": "Felixstowe. GMA Warehousing and Transport Limited"
    },
    {
        "key": "FXTFXTSCE",
        "value": "Felixstowe. Schallenberg International Ltd"
    },
    {
        "key": "FXTFXTGBE",
        "value": "Felixstowe. George Baker (Shipping) Ltd"
    },
    {
        "key": "FEMLHRBRX",
        "value": "Feltham. Brinks Limited"
    },
    {
        "key": "FEMLHRBTX",
        "value": "Feltham. Brinks Limited "
    },
    {
        "key": "FEMLHRCFX",
        "value": "Feltham. Concordia International Forwarding Ltd"
    },
    {
        "key": "FEMLHRWTX",
        "value": "Feltham. Toll Global Forwarding Group (UK) Limited"
    },
    {
        "key": "FEMLHRFOX",
        "value": "Feltham. Denholm Global Logistics Limited"
    },
    {
        "key": "FEMLHRFDX",
        "value": "Feltham. Federal Express Europe Inc."
    },
    {
        "key": "FEMLHRFLX",
        "value": "Feltham. FedEx Express UK Ltd"
    },
    {
        "key": "FEMLHRPPX",
        "value": "Feltham. Panalpina World Transport Limited"
    },
    {
        "key": "FEMLHRDAX",
        "value": "Feltham. Dunwoody Airline Services Limited T/A Worldwide Flight Services"
    },
    {
        "key": "FEMLHRERX",
        "value": "Feltham. Daily Freight Services Limited t/a DFS Worldwide"
    },
    {
        "key": "FEMLHREXX",
        "value": "Feltham. Expeditors International (UK) Limited"
    },
    {
        "key": "FEMLHRNNX",
        "value": "Feltham. NNR Global Logistics UK Limited"
    },
    {
        "key": "FEMLHRWLX",
        "value": "Feltham. Geodis Wilson UK Limited"
    },
    {
        "key": "FEMLHRCYX",
        "value": "Feltham. Customs Clearance Limited"
    },
    {
        "key": "FEMLHRKNX",
        "value": "Feltham. Kuehne + Nagel Limited"
    },
    {
        "key": "FEMLHRJZX",
        "value": "Feltham. Aviation Servicing Company Limited T/A ASC Cargo Handling"
    },
    {
        "key": "FEMLHRSKX",
        "value": "Feltham. Schenker Limited"
    },
    {
        "key": "FEMLHRMQX",
        "value": "Feltham. Damco UK Limited"
    },
    {
        "key": "FEMLHRJEX",
        "value": "Feltham. Bollore Logistics UK Limited"
    },
    {
        "key": "FEMLHRADX",
        "value": "Feltham. Air and Cargo Services Limited"
    },
    {
        "key": "FEMLHRKRX",
        "value": "Feltham. Kerry Logistics (UK) Ltd"
    },
    {
        "key": "FEMLHRFTX",
        "value": "Feltham. Fedex Trade Networks Transport & Brokerage (UK) Ltd"
    },
    {
        "key": "FEMLHRLPX",
        "value": "Feltham. Agility Logistics Limited"
    },
    {
        "key": "FEMLHRNSX",
        "value": "Feltham. Norman Global Logistics Limited"
    },
    {
        "key": "FEMLHRCTX",
        "value": "Feltham. Cosmotrans International Ltd"
    },
    {
        "key": "FEMLHRCKX",
        "value": "Feltham. Charles Kendall Freight Limited"
    },
    {
        "key": "FEMLHRHMX",
        "value": "Feltham. H&M Freight Services Limited"
    },
    {
        "key": "FEMLHRFWX",
        "value": "Feltham. Frontier Forwarding Services Ltd"
    },
    {
        "key": "FEMLHRPDX",
        "value": "Feltham. Perishable Movements Limited"
    },
    {
        "key": "FEMLHRJGX",
        "value": "Feltham. JAG-UFS (International) Limited"
    },
    {
        "key": "FEMLHRCIX",
        "value": "Feltham. CEVA Freight (UK) Ltd"
    },
    {
        "key": "FEMLHRGAX",
        "value": "Feltham. Tiga Aero Services Limited"
    },
    {
        "key": "FEMLHRHWX",
        "value": "Feltham. Heavyweight Air Express Limited"
    },
    {
        "key": "FEMLHRFQX",
        "value": "Feltham. Mainfreight UK Ltd"
    },
    {
        "key": "FEMLHRTKX",
        "value": "Feltham. Martintrux Group Ltd"
    },
    {
        "key": "FEMLHRYSX",
        "value": "Feltham. YSD Logistics Limited"
    },
    {
        "key": "FDYBHXHEX",
        "value": "Fradley. Hellman Worldwide Logistics Limited"
    },
    {
        "key": "GATNCLFZX",
        "value": "Gateshead. UPS SCS (UK) Limited"
    },
    {
        "key": "GLWGLADTX",
        "value": "Glasgow. Davies Turner Air Cargo Limited"
    },
    {
        "key": "GLWGLAKNX",
        "value": "Glasgow. Kuehne + Nagel Limited"
    },
    {
        "key": "GLWGLANPX",
        "value": "Glasgow. Nippon Express (UK) Limited"
    },
    {
        "key": "GLWGLAEXX",
        "value": "Glasgow. Expeditors International (UK) Limited"
    },
    {
        "key": "GLWGLAAEX",
        "value": "Glasgow. DHL Global Forwarding (UK) Limited"
    },
    {
        "key": "QDLBRSPTE",
        "value": "Gloucester. P L Transport Ltd T/A We Move Bikes (WMB Logistics)"
    },
    {
        "key": "GVSTHPCCK",
        "value": "Gravesend. CCT Worldwide Limited"
    },
    {
        "key": "GYSLONPXW1",
        "value": "Grays. L.A.R. Warehousing Limited"
    },
    {
        "key": "LWTLONTLE",
        "value": "Grays. Twinwheel Logistics Ltd"
    },
    {
        "key": "GFRLHRWYX",
        "value": "Greenford. Wealmoor Ltd"
    },
    {
        "key": "HMWLHRBJX",
        "value": "Harmondsworth. B & H Worldwide Limited"
    },
    {
        "key": "HMWLHRETX",
        "value": "Harmondsworth. Circle Express Limited"
    },
    {
        "key": "HMWLHRSIX",
        "value": "Harmondsworth. Senator International Freight Forwarding Limited"
    },
    {
        "key": "HMWLHRGJX",
        "value": "Harmondsworth. Global Freight Systems Limited"
    },
    {
        "key": "HMWLHRGYX",
        "value": "Harmondsworth. Globexair Limited"
    },
    {
        "key": "HMWLHRWRX",
        "value": "Harmondsworth. Air Menzies International Limited"
    },
    {
        "key": "HROLHREHX",
        "value": "Harrow. Eastlines Limited"
    },
    {
        "key": "HTOLHRGBX",
        "value": "Hatton Cross. Global Freight Systems Limited"
    },
    {
        "key": "HTOLHRWEX",
        "value": "Hatton Cross. Westgate Handling Services Limited"
    },
    {
        "key": "HMILHRAEX",
        "value": "Hayes. DHL Global Forwarding (UK) Limited"
    },
    {
        "key": "HMILHRMBX",
        "value": "Hayes. Martinspeed Limited"
    },
    {
        "key": "HMILHRNPX",
        "value": "Hayes. Nippon Express (UK) Limited"
    },
    {
        "key": "HMILHRWIX",
        "value": "Hayes. Woodland Global Limited"
    },
    {
        "key": "HMILHRHIX",
        "value": "Hayes. Heritage International Freight Limited"
    },
    {
        "key": "HESLHRIWX",
        "value": "Heston. IT Way Trans Group Clearance LLP"
    },
    {
        "key": "HESLHRBPX",
        "value": "Heston. Landmark Global (UK) Limited"
    },
    {
        "key": "HESLHRTQX",
        "value": "Heston. TNT UK Limited"
    },
    {
        "key": "HESLHRTNX",
        "value": "Heston. TNT UK Limited"
    },
    {
        "key": "HESLHRLJX",
        "value": "Heston. Ligentia UK Limited"
    },
    {
        "key": "HESLHRELX",
        "value": "Heston. Torque Logistics Limited"
    },
    {
        "key": "HESLHRMHX",
        "value": "Heston. Mentfield (UK) Limited"
    },
    {
        "key": "HOULHRLRX",
        "value": "Hounslow. LP Customs Clearance Ltd"
    },
    {
        "key": "HOULHRYTX",
        "value": "Hounslow. Yamato Transport Europe BV"
    },
    {
        "key": "HOULHRYRX",
        "value": "Hounslow. Yourway Transport Limited"
    },
    {
        "key": "HOULHRUNX",
        "value": "Hounslow. Uneek Freight Services Limited"
    },
    {
        "key": "HYNLIVLSF",
        "value": "Huyton. Lombard Shipping and Forwarding Limited"
    },
    {
        "key": "HYNLIVLLF",
        "value": "Huyton. Locker Freight Ltd"
    },
    {
        "key": "HTHDOGWSP",
        "value": "Hythe - STOP 24 Exports. Channel Ports Limited"
    },
    {
        "key": "HTHDOGSPE",
        "value": "Hythe - STOP 24 Imports. Channel Ports Limited"
    },
    {
        "key": "IMMIMMESI",
        "value": "Immingham. Eimskip UK Limited"
    },
    {
        "key": "IPSFXTSTL",
        "value": "Ipswich. Sea Transport Limited"
    },
    {
        "key": "IPSFXTPTE",
        "value": "Ipswich. Greencarrier Freight Services (UK) Limited"
    },
    {
        "key": "IPSFXTHFE",
        "value": "Ipswich. Hemisphere Freight Services Limited"
    },
    {
        "key": "IPSFXTBIE",
        "value": "Ipswich. Brinor International Shipping & Forwarding Ltd"
    },
    {
        "key": "IPSFXTCHI",
        "value": "Ipswich. Cavalier Haulage (Anglia) Limited"
    },
    {
        "key": "IPSFXTTVE",
        "value": "Ipswich. Transvalair (UK) Limited T/A Transvalocean"
    },
    {
        "key": "IPSFXTGMA",
        "value": "Ipswich. GMA Warehousing & Transport Ltd"
    },
    {
        "key": "IPSFXTWER",
        "value": "Ipswich. World Transport Agency Limited"
    },
    {
        "key": "IPSFXTSPE",
        "value": "Ipswich. Seaport Freight Services Ltd"
    },
    {
        "key": "IPSFXTKKE",
        "value": "Ipswich. Karl King Transport Limited"
    },
    {
        "key": "IPSFXTEFE",
        "value": "Ipswich. Eagle Freight Terminal Limited"
    },
    {
        "key": "IPSFXTCFE",
        "value": "Ipswich. Charterlink Worldwide Freight & Logistics Limited"
    },
    {
        "key": "IPSFXTAME",
        "value": "Ipswich. Action International (Manchester) Limited"
    },
    {
        "key": "IPSFXTGSE",
        "value": "Ipswich. GAC Shipping (UK) Limited"
    },
    {
        "key": "IPSFXTLSE",
        "value": "Ipswich. Lombard Shipping Limited"
    },
    {
        "key": "IRLMANEGX",
        "value": "Irlam. CEVA Freight (UK) Limited"
    },
    {
        "key": "IVELHRITX",
        "value": "Iver. Immediate Transport Co Limited"
    },
    {
        "key": "KRYBHXUXI1",
        "value": "Keresley. Barsan Global Logistics Limited"
    },
    {
        "key": "KNWLIVLSK",
        "value": "Knowsley. Lombard Shipping PLC"
    },
    {
        "key": "LAILONRED2",
        "value": "Laindon. Redhead Freight Limited"
    },
    {
        "key": "LAILONPXQ1",
        "value": "Laindon. Vanguard Logistics Services Limited"
    },
    {
        "key": "LALLHRRMGWDC",
        "value": "Langley. Royal Mail Group Limited"
    },
    {
        "key": "LANLHRRMGILC",
        "value": "Langley. Royal Mail Group Limited"
    },
    {
        "key": "LALLHRPMX",
        "value": "Langley. DHL Global Match (UK) Limited"
    },
    {
        "key": "LYOLCYMNX",
        "value": "Leyton. Momart Limited"
    },
    {
        "key": "LIOGLAGEX",
        "value": "Linwood. Agility Logistics Limited"
    },
    {
        "key": "LIVLIVMFE",
        "value": "Liverpool. Mersey Forwarding Shipping Services Limited"
    },
    {
        "key": "LIVLIVTTE",
        "value": "Liverpool. 3TL Limited"
    },
    {
        "key": "COOBFSDXX",
        "value": "Londonderry. DHL International UK Limited"
    },
    {
        "key": "LWMLBARED1",
        "value": "Low Moor. Redhead Freight Limited"
    },
    {
        "key": "LWMLBARUX",
        "value": "Low Moor. Rhenus Logistics Ltd"
    },
    {
        "key": "LUTLTNLPX",
        "value": "Luton Airport. Agility Logistics Limited"
    },
    {
        "key": "LUTLTNQAX",
        "value": "Luton Airport. Aircargo & Container Services Ltd"
    },
    {
        "key": "LUTLTNKDX",
        "value": "Luton Airport. GBA Services Limited"
    },
    {
        "key": "LYNDOGLAS",
        "value": "Lympne. Laser Transport International Ltd"
    },
    {
        "key": "MLKBFSEXX",
        "value": "Mallusk / Newtownabbey. Expeditors International (UK) Limited"
    },
    {
        "key": "MNCMANFZX",
        "value": "Manchester Airport. UPS SCS (UK) Limited"
    },
    {
        "key": "MNCMANDHX",
        "value": "Manchester Airport. DHL International (UK) Limited"
    },
    {
        "key": "MNCMANHSX",
        "value": "Manchester Airport. Goldstar Heathrow Ltd"
    },
    {
        "key": "MNCMANBXX",
        "value": "Manchester Airport. Schenker Limited"
    },
    {
        "key": "MNCMANUMX",
        "value": "Manchester Airport. UCH Logistics (Manchester) Limited"
    },
    {
        "key": "MNCMANKNX",
        "value": "Manchester Airport. Kuehne + Nagel Limited"
    },
    {
        "key": "MNCMANDTX",
        "value": "Manchester Airport. Davies Turner Air Cargo Ltd"
    },
    {
        "key": "MNCMANWIX",
        "value": "Manchester Airport. Woodland Global Limited"
    },
    {
        "key": "MNCMANMFTX",
        "value": "Manchester Airport. Fedex Trade Networks Transport & Brokerage (UK) Ltd"
    },
    {
        "key": "MIDMIDLVE",
        "value": "Middlesbrough. LV Shipping Limited"
    },
    {
        "key": "BLELTNKYX",
        "value": "Milton Keynes. CYUK Limited"
    },
    {
        "key": "MINBHXKNX",
        "value": "Minworth. Kuehne + Nagel Limited"
    },
    {
        "key": "NCTFXTKTE",
        "value": "Nacton. Karl King Transport Limited"
    },
    {
        "key": "NGRCWLDGX",
        "value": "Nantgarw. DHL Global Forwarding (UK) Limited.  "
    },
    {
        "key": "NWBEDIDHX",
        "value": "Newbridge. DHL International (UK) Limited"
    },
    {
        "key": "NWPLTNEXX",
        "value": "Newport Pagnell. Expeditors International UK Ltd"
    },
    {
        "key": "NSHTYNAQE",
        "value": "North Shields. Aquatran Limited"
    },
    {
        "key": "NTGEMAUPM1",
        "value": "Nottingham. Kuehne + Nagel Limited"
    },
    {
        "key": "NTNBHXBXX",
        "value": "Nuneaton. Schenker Limited"
    },
    {
        "key": "NURSTNPGM1",
        "value": "Nursling. Formation Freight Services Limited"
    },
    {
        "key": "OBYBHXPDE",
        "value": "Oldbury. DPD Group UK Limited"
    },
    {
        "key": "OBYBHXPFE",
        "value": "Oldbury. DPD Group UK Limited "
    },
    {
        "key": "OBYBHXHKH",
        "value": "Oldbury. Hinks Haulage HH Logistics Limited"
    },
    {
        "key": "PAYGLAQGX",
        "value": "Paisley. Air Sea Scotland Limited"
    },
    {
        "key": "PAYGLADHX",
        "value": "Paisley. DHL International (UK) Limited"
    },
    {
        "key": "PYALHRNFX",
        "value": "Park Royal. Santa Fe Relocation Services (UK) Ltd"
    },
    {
        "key": "PNLNCLKNX",
        "value": "Newcastle International Airport - Ponteland. Kuehne + Nagel Limited"
    },
    {
        "key": "POOPOOBXX",
        "value": "Poole. Schenker Limited"
    },
    {
        "key": "POTABZJSX",
        "value": "Portlethen. Bollore Logistics UK Limited"
    },
    {
        "key": "CBRLHRNEX",
        "value": "Poyle. Nemcast International Ltd"
    },
    {
        "key": "PLELHRFIX",
        "value": "Poyle. UPS SCS (UK) Limited"
    },
    {
        "key": "PLELHRCFX2",
        "value": "Poyle. Federal Express Europe Inc"
    },
    {
        "key": "CBRLHRRWX",
        "value": "Poyle. Sovereign Speed UK Ltd"
    },
    {
        "key": "PFTLONPXZ1",
        "value": "Purfleet. Trans Euro Group Limited"
    },
    {
        "key": "PFTLONPZT1",
        "value": "Purfleet. DSV Road Limited"
    },
    {
        "key": "PFTLONFPY2",
        "value": "Purfleet. FP Young Holdings Limited"
    },
    {
        "key": "PFTLONDHE",
        "value": "Purfleet. DHL Global Forwarding (UK) Limited"
    },
    {
        "key": "PFTLONPHX1",
        "value": "Purfleet. G.C. Distribution Limited"
    },
    {
        "key": "PFTLONPXI1",
        "value": "Purfleet. F P Young Holdings Limited"
    },
    {
        "key": "RAHLONPZI1",
        "value": "Rainham. International Cargo Centre Limited"
    },
    {
        "key": "RAHLONPZQ1",
        "value": "Rainham. Forest Freight Limited"
    },
    {
        "key": "RDNLHRDXX",
        "value": "Reading. DHL International (UK) Limited"
    },
    {
        "key": "RENGLAKWX",
        "value": "Renfrew. Kintetsu World Express (UK) Limited"
    },
    {
        "key": "HOORCSKZX",
        "value": "Rochester. Amazon UK Services Ltd"
    },
    {
        "key": "SLPLHRNJX",
        "value": "Ruislip. NG Terminal Ltd"
    },
    {
        "key": "TBLLTNDHX",
        "value": "Saint Albans. DHL International (UK) Limited"
    },
    {
        "key": "SEDHULRXE",
        "value": "Salt End (Hull). RIX Shipping Company Limited"
    },
    {
        "key": "SH2MANIHX",
        "value": "Sharston. Cardinal Maritime Limited"
    },
    {
        "key": "SH2MANJHX",
        "value": "Sharston. Cardinal Maritime Limited"
    },
    {
        "key": "SPPLHRVIX",
        "value": "Shepperton. Loomis International (UK) Limited"
    },
    {
        "key": "SLOLHRPTX",
        "value": "Slough. P2P Mailing Ltd"
    },
    {
        "key": "SLOLHRTTX",
        "value": "Slough. RST Direct Ltd"
    },
    {
        "key": "SLOLHRKAX",
        "value": "Slough. Tigers Global Logistics Limited"
    },
    {
        "key": "PLELHRTGX ",
        "value": "Slough. Global Trade Point Ltd"
    },
    {
        "key": "SMEBHXIDX",
        "value": "Smethwick. ICS Distribution Limited"
    },
    {
        "key": "SOLBHXROX",
        "value": "Solihull. Rohlig UK Limited"
    },
    {
        "key": "KSUIMMDSI",
        "value": "South Killingholme. DSV Road Limited"
    },
    {
        "key": "SLLLHRVTX",
        "value": "Southall. Veteran Logistics Limited"
    },
    {
        "key": "SLLLHRAPX",
        "value": "Southall. Allport Cargo Services Limited"
    },
    {
        "key": "SLLLHRQIX",
        "value": "Southall. St Mark Freight Services Limited"
    },
    {
        "key": "SLLLHRMDX",
        "value": "Southall. M.D. Freight Services Limited"
    },
    {
        "key": "SLLLHRFGX",
        "value": "Southall. Fruity Fresh (Western) Limited"
    },
    {
        "key": "SLLLHRBGX",
        "value": "Southall. Shuttle Bridge Logistics limited"
    },
    {
        "key": "PBKEMAPDX",
        "value": "Spalding. Perishable Movements Limited"
    },
    {
        "key": "SLGIMMPTS",
        "value": "Stallingborough. Greencarrier Freight Services (UK) Limited"
    },
    {
        "key": "SLGIMMBCE",
        "value": "Stallingborough. Bring Cargo Limited"
    },
    {
        "key": "BSTLSADHX",
        "value": "Stansted. DHL International (UK) Limited"
    },
    {
        "key": "SWLLHRJPX",
        "value": "Stanwell. Heathrow Import Clearance Services Ltd"
    },
    {
        "key": "SWLLHREPX",
        "value": "Stanwell. Pentagon Freight Services PLC"
    },
    {
        "key": "SWLLHRUVX",
        "value": "Stanwell. Uniserve (Air Freight) Limited"
    },
    {
        "key": "SWLLHRUKX",
        "value": "Stanwell. UCH Logistics Limited"
    },
    {
        "key": "SWLLHRJAX",
        "value": "Stanwell. JAS Forwarding UK Limited"
    },
    {
        "key": "SFDMANCKXCUK",
        "value": "Stretford. Charles Kendall Freight Limited"
    },
    {
        "key": "STDTHPPYO1",
        "value": "Strood. MIQ Logistics Limited"
    },
    {
        "key": "SBYLHRMZX",
        "value": "Sunbury. Maurice Ward & Co Ltd"
    },
    {
        "key": "SBYLHRTRX",
        "value": "Sunbury. Trans Global Freight Management Limited"
    },
    {
        "key": "TAWBHXTNT2",
        "value": "Tamworth. TNT UK Limited"
    },
    {
        "key": "TAWBHXDST",
        "value": "Tamworth. DSV Road Limited"
    },
    {
        "key": "TFDDOGUFS",
        "value": "Thetford. UFS Limited"
    },
    {
        "key": "TILLONEGK1",
        "value": "Tilbury. Allport Cargo Services Limited"
    },
    {
        "key": "TILLONPYD2",
        "value": "Tilbury. Uniserve (UK) Limited"
    },
    {
        "key": "TLQSTNSOF1",
        "value": "Totton. Southampton Freight Services Limited"
    },
    {
        "key": "TLQSTNACC1",
        "value": "Totton. Acclaim Logistics Limited"
    },
    {
        "key": "TLQSTNEFT1",
        "value": "Totton. Eagle Freight Terminal Limited"
    },
    {
        "key": "TLQSTNSSL1",
        "value": "Totton. Southampton Logistics Ltd"
    },
    {
        "key": "TLQSTNPDC1",
        "value": "Totton. Toll Global Forwarding Group (UK) Limited"
    },
    {
        "key": "TLQSTNYOU1",
        "value": "Totton. F P Young Holdings Limited"
    },
    {
        "key": "TFKMANEXX",
        "value": "Trafford Park. Expeditors International (UK) Limited"
    },
    {
        "key": "TFKMANMFM",
        "value": "Trafford Park. Davies Turner & Co. Limited"
    },
    {
        "key": "TFKMANGEX",
        "value": "Trafford Park. Agility Logistics Limited"
    },
    {
        "key": "TFKMANMLI1",
        "value": "Trafford Park. Agility Logistics Limited"
    },
    {
        "key": "TFKMANMFT2",
        "value": "Trafford Park. Vanguard Logistics Services Limited"
    },
    {
        "key": "TFKMANMCS1CNS",
        "value": "Trafford Park. C S Brunt (Freight Services) Limited"
    },
    {
        "key": "TFKMANMTI1",
        "value": "Trafford Park. Kerry Logistics (UK) Ltd"
    },
    {
        "key": "TFKMANMCX",
        "value": "Trafford Park. DHL Global Forwarding (UK) Limited"
    },
    {
        "key": "WDRLHRNIX",
        "value": "West Drayton. Nissin (UK) Ltd"
    },
    {
        "key": "WENSOURSL1",
        "value": "West End. Riva South Limited "
    },
    {
        "key": "WHOLONLIF1",
        "value": "West Horndon. Codognotto UK Limited T/A London International Freight Terminal"
    },
    {
        "key": "LWTLONEGA1",
        "value": "West Thurrock. Agility Logistics Limited"
    },
    {
        "key": "LWTLONJLR1",
        "value": "West Thurrock. Dornack International Limited"
    },
    {
        "key": "WPHSTNEPQ1",
        "value": "Whiteparish. BKG Transport Ltd"
    },
    {
        "key": "WBDLHRGCX",
        "value": "Wimbledon. Cadogan Tate London Limited"
    },
    {
        "key": "WIHFXTPQX",
        "value": "Witham. PFE Express Limited"
    },
    {
        "key": "WIHFXTPFE",
        "value": "Witham. PFE Express Limited"
    },
    {
        "key": "WIHLONUFE",
        "value": "Witham. Unisystems Freight (UK) Limited"
    },
    {
        "key": "WMHLTNUPX",
        "value": "Worminghall. UK Postings Ltd T/A UKP Worldwide"
    },
    {
        "key": "WT9LBAELX",
        "value": "Wortley. Torque Logistics Limited"
    },
    {
        "key": "WT9LBAELF1",
        "value": "Wortley. Torque Logistics Limited"
    },
    {
        "key": "WYHMANCNX",
        "value": "Wythenshawe. Concordia International Forwarding Ltd"
    },
    {
        "key": "WYHMANDSE",
        "value": "Wythenshawe. DSV Road Limited"
    },
    {
        "key": "WYHMANQJX",
        "value": "Wythenshawe. Regal Crown Logistics Limited"
    },
    {
        "key": "WYHMANIMX",
        "value": "Wythenshawe. Uniserve (UK) Ltd"
    },
    {
        "key": "WYHMANWLX",
        "value": "Wythenshawe. Geodis Wilson UK Ltd"
    },
    {
        "key": "WYHMANDNX",
        "value": "Wythenshawe. DSV Air & Sea Limited"
    },
    {
        "key": "WYHMANTDX",
        "value": "Wythenshawe. Kerry Logistics (UK) Ltd"
    },
    {
        "key": "WYHMANNPX",
        "value": "Wythenshawe. Nippon Express (UK) Limited"
    },
    {
        "key": "WYHMANJEX",
        "value": "Wythenshawe. Bollore Logistics UK Limited"
    },
    {
        "key": "WYHMANPQX",
        "value": "Wythenshawe. PFE Express Limited"
    },
    {
        "key": "WYHMANNTX",
        "value": "Wythenshawe. NG Transport Limited"
    },
    {
        "key": "BELBFSAEX",
        "value": "Belfast Airport. DHL Global Fowarding (UK) Limited"
    },
    {
        "key": "BELBFSHWS",
        "value": "Belfast Airport. Heavyweight Air Express Limited"
    },
    {
        "key": "EMAEMAUPS4",
        "value": "East Midlands Airport. UPS Limited"
    },
    {
        "key": "EDIEDIEDS",
        "value": "Edinburgh Airport. Extrordinair Limited"
    },
    {
        "key": "FXTFXTSEE",
        "value": "Felixstowe. Seafast Cold Chain Logistics PLC"
    },
    {
        "key": "GLWGLAFDS",
        "value": "Glasgow. Federal Express Europe Inc"
    },
    {
        "key": "LHRLHRPVS",
        "value": "London Heathrow Airport. Dnata Limited"
    },
    {
        "key": "LHRLHRNAS",
        "value": "London Heathrow Airport. Dnata Limited"
    },
    {
        "key": "LHRLHRNBS",
        "value": "London Heathrow Airport. Dnata Limited"
    },
    {
        "key": "LHRLHRNCS",
        "value": "London Heathrow Airport. Dnata Limited"
    },
    {
        "key": "LHRLHRANS",
        "value": "London Heathrow Airport. Airworld Airlines Limited"
    },
    {
        "key": "LHRLHRJLS",
        "value": "London Heathrow Airport. Japan Airlines Co. Ltd"
    },
    {
        "key": "LHRLHRCPS",
        "value": "London Heathrow Airport. Dnata Limited"
    },
    {
        "key": "UURBOHBVEE",
        "value": "Bournemouth International Airport - Hurn. Charles Kendall Freight Ltd"
    },
    {
        "key": "ABDABDSEI",
        "value": "Aberdeen Harbour. Sea Cargo Aberdeen Limited"
    },
    {
        "key": "ABDABDABM",
        "value": "Aberdeen - Matthews Quay. Sea Cargo Aberdeen Limited"
    },
    {
        "key": "BELBFSFDS",
        "value": "Belfast Airport. Federal Express Europe Inc"
    },
    {
        "key": "BELBFSHWS",
        "value": "Belfast Airport. Heavyweight Air Express Ltd"
    },
    {
        "key": "BELBFSHYS",
        "value": "Belfast Airport. PING Handing Ltd"
    },
    {
        "key": "BELBFSSLS",
        "value": "Belfast Airport. Swissport GB Limited"
    },
    {
        "key": "BELBFSFXT",
        "value": "Belfast Airport. FedEx Express UK Transportation Limited "
    },
    {
        "key": "BELBELLCC",
        "value": "Belfast. Lissan Coal Company"
    },
    {
        "key": "BELBELVLQ1",
        "value": "Belfast. TCB (NI) Limited"
    },
    {
        "key": "BELBELBTM2",
        "value": "Belfast. Coastal Container Line Ltd"
    },
    {
        "key": "BELBELBTC1",
        "value": "Belfast. Belfast Container Terminal (BCT) Limited"
    },
    {
        "key": "BELBELUML",
        "value": "Belfast. United Molasses (Ireland) Limited"
    },
    {
        "key": "BHMBHXPLS",
        "value": "Birmingham Airport. Dnata Limited"
    },
    {
        "key": "BHMBHXFDS",
        "value": "Birmingham Airport. Federal Express Europe Inc"
    },
    {
        "key": "BHMBHXSLS",
        "value": "Birmingham Airport. Swissport GB Limited"
    },
    {
        "key": "CMGCBGCMG",
        "value": "Cambridge Airport. Marshalls of Cambridge Aerospace Limited"
    },
    {
        "key": "DONDSARQS",
        "value": "Robin Hood Doncaster Sheffield Airport - Doncaster. Anglo World Cargo Limited"
    },
    {
        "key": "DVRDOGFSA",
        "value": "Dover Western Docks. Motis Ireland Limited"
    },
    {
        "key": "DYCABZSLS",
        "value": "Dyce - Aberdeen Airport. Swissport GB Limited"
    },
    {
        "key": "EMAEMAHWS",
        "value": "East Midlands Airport. Heavyweight Air Express Ltd"
    },
    {
        "key": "EMAEMASLS",
        "value": "East Midlands Airport. Swissport GB Limited"
    },
    {
        "key": "EMAEMADHS",
        "value": "East Midlands Airport. DHL Aviation UK Limited"
    },
    {
        "key": "EMAEMATNT",
        "value": "East Midlands Airport. TNT UK Limited"
    },
    {
        "key": "EMAEMAUZS",
        "value": "East Midlands Airport. UPS Limited"
    },
    {
        "key": "EDIEDIBAC",
        "value": "Edinburgh Airport. Dunwoody Airline Services Limited T/A Worldwide Flight Services"
    },
    {
        "key": "EDIEDIBWS",
        "value": "Edinburgh Airport. Dunwoody Airline Services Limited T/A Worldwide Flight Services"
    },
    {
        "key": "EDIEDITNT",
        "value": "Edinburgh Airport. TNT UK Limited"
    },
    {
        "key": "ELLELLEPT",
        "value": "Ellesmere Port. Quality Freight (UK) Limited"
    },
    {
        "key": "LGWLGWPZS",
        "value": "Gatwick Apt/London. Dnata Limited"
    },
    {
        "key": "LGWLGWGWK1",
        "value": "Gatwick Apt/London. Worldwide Flight Services Limited"
    },
    {
        "key": "LGWLGWVWS",
        "value": "Gatwick Apt/London. Worldwide Flight Services Limited"
    },
    {
        "key": "LGWLGWBIP",
        "value": "Gatwick Apt/London. Gatwick Airport Limited"
    },
    {
        "key": "BHDBHDMWS",
        "value": "George Best Belfast City Apt/Belfast. Menzies World Cargo Limited"
    },
    {
        "key": "GLWGLAPZS",
        "value": "Glasgow. Dnata Limited"
    },
    {
        "key": "GLWGLASLS",
        "value": "Glasgow. Swissport GB Limited"
    },
    {
        "key": "GLWGLABWS",
        "value": "Glasgow. Dunwoody Airline Services Limited t/a Worldwide Flight Services"
    },
    {
        "key": "GLWGLAGPS",
        "value": "Glasgow. Glasgow Prestwick Airport Limited"
    },
    {
        "key": "GLWGLABIP",
        "value": "Glasgow. Glasgow Prestwick Airport Limited"
    },
    {
        "key": "GRKGRKSFG",
        "value": "Glasgow. Greenock Ocean Terminal"
    },
    {
        "key": "GTYGTYGGT",
        "value": "Great Yarmouth. Gleadell Agriculture Limited"
    },
    {
        "key": "LHRLHRACS",
        "value": "London Heathrow Airport. Air Canada"
    },
    {
        "key": "LHRLHRAPH",
        "value": "London Heathrow Airport. Airport Perishable Handling Lt"
    },
    {
        "key": "LHRLHRJZS",
        "value": "London Heathrow Airport. Aviation Servicing Company Limited T/A ASC Cargo Handlin"
    },
    {
        "key": "LHRLHRCFL1",
        "value": "London Heathrow Airport. Courier Facilities Limited"
    },
    {
        "key": "LHRLHRDHS",
        "value": "London Heathrow Airport. DHL Aviation (UK) Limited"
    },
    {
        "key": "LHRLHRNDS",
        "value": "London Heathrow Airport. Dnata Limited"
    },
    {
        "key": "SWLLHRPHS",
        "value": "London Heathrow Airport. Dnata Ltd (Dnata City East)"
    },
    {
        "key": "LHRLHREUB",
        "value": "London Heathrow Airport. EUROBIP Limited"
    },
    {
        "key": "LHRLHRARC",
        "value": "London Heathrow Airport. Heathrow Animal Reception Centre"
    },
    {
        "key": "LHRLHRAFS",
        "value": "London Heathrow Airport. Heathrow Cargo Handling Limited"
    },
    {
        "key": "LHRLHRRMG",
        "value": "London Heathrow Airport. Royal Mail Group Limited"
    },
    {
        "key": "LHRLHRSLS",
        "value": "London Heathrow Airport. Swissport GB Limited"
    },
    {
        "key": "LHRLHRUAS",
        "value": "London Heathrow Airport. United Airlines Inc."
    },
    {
        "key": "LHRLHRWXS",
        "value": "London Heathrow Airport. Worldwide Flight Services Limited"
    },
    {
        "key": "LHRLHRWAS",
        "value": "London Heathrow Airport. Worldwide Flight Services Limited"
    },
    {
        "key": "LHRLHRCIS",
        "value": "London Heathrow Airport. Menzies World Cargo Limited"
    },
    {
        "key": "LHRLHRNHS",
        "value": "London Heathrow Airport. American Airlines Inc"
    },
    {
        "key": "LHRLHRAAS",
        "value": "London Heathrow Airport. American Airlines Inc"
    },
    {
        "key": "LHRLHRBAC",
        "value": "London Heathrow Airport. British Airways PLC"
    },
    {
        "key": "KLNKLNBNT",
        "value": "King's Lynn. Agrilynk Ltd"
    },
    {
        "key": "KMGHUYNGS",
        "value": "Humberside Airport - Kirmington. Anglia Cargo International Ltd"
    },
    {
        "key": "LIVLIVLWF",
        "value": "Liverpool - Alexandra Dock. Freight Logistics Warehousing Limited"
    },
    {
        "key": "LIVLIVJKE",
        "value": "Liverpool. Jenkins Shipping Company Limited"
    },
    {
        "key": "LCTLCYEAS",
        "value": "London City Airport. Execair Cargo Services Limited"
    },
    {
        "key": "LUTLTNDHS",
        "value": "Luton Airport. DHL Aviation UK Limited"
    },
    {
        "key": "LUTLTNLCS",
        "value": "Luton Airport. London Luton Airport Operations Limited"
    },
    {
        "key": "MNCMANPHS",
        "value": "Manchester Airport. Dnata Limited"
    },
    {
        "key": "MNCMANNAS",
        "value": "Manchester Airport. Dnata Limited"
    },
    {
        "key": "MNCMANFDS",
        "value": "Manchester Airport. Federal Express Europe Inc"
    },
    {
        "key": "MNCMANSLS",
        "value": "Manchester Airport. Swissport GB Limited"
    },
    {
        "key": "MNCMANBIP",
        "value": "Manchester Airport. Swissport GB Ltd (BIP)"
    },
    {
        "key": "MNCMANNBS",
        "value": "Manchester Airport. Dnata Ltd (3rd shed)"
    },
    {
        "key": "MNCMANBWS",
        "value": "Manchester Airport. Dunwoody Airline Services Limited t/a Worldwide Flight Services"
    },
    {
        "key": "NRWNWIRFS",
        "value": "Norwich Airport. Regional Freight Services Limited"
    },
    {
        "key": "PNLNCLPZS",
        "value": "Newcastle International Airport - Ponteland. Dnata Limited"
    },
    {
        "key": "PNLNCLBAC",
        "value": "Newcastle International Airport - Ponteland. Dunwoody Airline Services Limited T/A Worldwide Flight Services"
    },
    {
        "key": "PNLNCLFDS",
        "value": "Newcastle International Airport - Ponteland. Federal Express Europe Inc"
    },
    {
        "key": "PMEPTMPOR3",
        "value": "Portsmouth Continental Ferry Port - Condor Ferries. Condor Ferries Limited"
    },
    {
        "key": "PMEPTMPOR2",
        "value": "Portsmouth Continental Ferry Port - Flathouse Quay & Albert Johnson Quay. Geest Line Limited"
    },
    {
        "key": "RS8CWLRVS",
        "value": "Cardiff International Airport - Rhoose. John Stevenson t/a Raven Express Logistics"
    },
    {
        "key": "SSHTYNNSA",
        "value": "Hoegh Northern Terminal - South Shields. NSA UK Limited"
    },
    {
        "key": "SOUSTNJAM1",
        "value": "Southampton Docks - 209 Berth. J&A Marshall Limited"
    },
    {
        "key": "SOUSTNSCT1",
        "value": "Southampton Docks - 204 - 207 Berth. Southampton Container Terminals Limited T/A DP World Southampton"
    },
    {
        "key": "SOUSTNPNT1",
        "value": "Southampton Docks - Dock Gate 10 Compound. Pentalver Transport Limited"
    },
    {
        "key": "SPKLPLWNS",
        "value": "Liverpool John Lennon Airport - Speke. Wynne Aviation Services Limited"
    },
    {
        "key": "SPKLPLTNT",
        "value": "Liverpool John Lennon Airport - Speke. TNT UK Limited"
    },
    {
        "key": "STNLSAFHS",
        "value": "London Stansted Airport. Federal Express Europe Inc"
    },
    {
        "key": "STNLSASLS",
        "value": "London Stansted Airport. Swissport GB Limited"
    },
    {
        "key": "STNLSATNT",
        "value": "London Stansted Airport. TNT UK Limited"
    },
    {
        "key": "STNLSAUPS",
        "value": "London Stansted Airport. UPS Limited"
    },
    {
        "key": "STNLSAFDS",
        "value": "London Stansted Airport. Federal Express Europe Inc"
    },
    {
        "key": "STNLSABAC",
        "value": "London Stansted Airport. British Airways PLC"
    },
    {
        "key": "WRTWARBAE",
        "value": "Warton Aerodrome. BAE Systems (Operations) Limited"
    },
    {
        "key": "YDNLBABWS",
        "value": "Leeds Bradford Airport Northside - Yeadon. Dunwoody Airline Services Limited T/A Worldwide Flight Services"
    },
    {
        "key": "ABDABDABD",
        "value": "Aberdeen Docks"
    },
    {
        "key": "AULINVEWE",
        "value": "Aultbea - Loch Ewe Pier,  NATO POL Depot"
    },
    {
        "key": "AVOAVOAVO",
        "value": "Avonmouth Docks"
    },
    {
        "key": "AVOAVOCSX",
        "value": "Avonmouth - Royal Edward Dock X Berth"
    },
    {
        "key": "AYRAYRAYR",
        "value": "Ayr Harbour"
    },
    {
        "key": "BKGLONSVW",
        "value": "Barking [London] - Sivyer Wharf"
    },
    {
        "key": "BKGLONBDW",
        "value": "Barking [London] - Docklands Wharf"
    },
    {
        "key": "BKGLONPIN",
        "value": "Barking [London] - Pinns Wharf"
    },
    {
        "key": "BRVHULBRV",
        "value": "Barrow Haven - Old Ferry Wharf"
    },
    {
        "key": "BIFBARBIF",
        "value": "Barrow in Furness"
    },
    {
        "key": "BIFBARBDD",
        "value": "Barrow in Furness - Buccleuch & Devonshire Dock"
    },
    {
        "key": "BADCDFVPK",
        "value": "Barry Dock - Vopak Wharf,  Berths 8 & 9"
    },
    {
        "key": "BADCDFBAD",
        "value": "Barry Dock"
    },
    {
        "key": "BELBELBEL",
        "value": "Belfast Port"
    },
    {
        "key": "BELBELOB4",
        "value": "Belfast - Oil Berth 4"
    },
    {
        "key": "BWKBLYBWK",
        "value": "Berwick-upon-Tweed"
    },
    {
        "key": "BIDWATBID",
        "value": "Bideford - The Town Quay"
    },
    {
        "key": "BHWMIDBRO",
        "value": "Billingham Reach & Oil Jetties"
    },
    {
        "key": "BRKLIVBRK",
        "value": "Birkenhead - Base Harbour Port Facility"
    },
    {
        "key": "BLYBLYBLY",
        "value": "Blyth Harbour"
    },
    {
        "key": "BLYBLYRIO",
        "value": "Blyth - Rio Tinto Alan Shipping Unloading Facility"
    },
    {
        "key": "BOSBOSBOS",
        "value": "Boston"
    },
    {
        "key": "BFBGRGP40",
        "value": "Braefoot Bay - Exxon Ethylene Jetty (P40)"
    },
    {
        "key": "BFBGRGSHL",
        "value": "Braefoot Bay - Shell Jetty"
    },
    {
        "key": "BLSCOLBOW",
        "value": "Brightlingsea - Olivers Wharf"
    },
    {
        "key": "BFYSWABFY",
        "value": "Briton Ferry - Giants Wharf"
    },
    {
        "key": "BHKBHKBHK",
        "value": "Bromborough - Mersey Wharf"
    },
    {
        "key": "BHVGRGWYQ",
        "value": "Buckhaven - Wellesley Yard Quay"
    },
    {
        "key": "BUCFRBBUC",
        "value": "Buckie [Cluny] Harbour"
    },
    {
        "key": "BTLGRGBTL",
        "value": "Burntisland Docks"
    },
    {
        "key": "CBTARGONQ",
        "value": "Campbeltown - Old & New Quay"
    },
    {
        "key": "CBTARGNPJ",
        "value": "Campbeltown - NATO POL Jetty"
    },
    {
        "key": "CANLONHHW",
        "value": "Canvey Island - Hole Haven Wharf"
    },
    {
        "key": "CANLONCAL",
        "value": "Canvey Island - Calor Gas Jetty"
    },
    {
        "key": "CDFCDFCDF",
        "value": "Cardiff Docks"
    },
    {
        "key": "CTMMEDNO1",
        "value": "Chatham Dock - No.1 Berth"
    },
    {
        "key": "CTMMEDNO2",
        "value": "Chatham Dock - No.2 Berth"
    },
    {
        "key": "CTMMEDTSS",
        "value": "Chatham Dock - Berths 3, 4,  6 & 7 [Total Ship Services]"
    },
    {
        "key": "CGPBELCGP",
        "value": "Cloghan Point Oil Jetty"
    },
    {
        "key": "CLYGLWRTH",
        "value": "Rothesay Dock - Clydebank"
    },
    {
        "key": "CLRCLRCLR",
        "value": "Coleraine"
    },
    {
        "key": "CYYHLDCYY",
        "value": "Colwyn Bay (Bae Colwyn)"
    },
    {
        "key": "CORINVCOR",
        "value": "Corpach - Annat Point"
    },
    {
        "key": "COUGRKCOU",
        "value": "Coulport - RNAD Jetties"
    },
    {
        "key": "COWCOWCOW",
        "value": "Cowes - Medina Wharf"
    },
    {
        "key": "CNUARGCNU",
        "value": "Craignure [Isle of Mull]"
    },
    {
        "key": "DAGLONDTJ",
        "value": "Dagenham - Thunderer Jetty"
    },
    {
        "key": "DAGLONCMX",
        "value": "Dagenham - Jetty No. 8,  CEMEX"
    },
    {
        "key": "DAGLONEJ9",
        "value": "Dagenham - East Jetty No. 9"
    },
    {
        "key": "DAGLONFRD",
        "value": "Dagenham - Fords Jetty & Estate"
    },
    {
        "key": "DAGLONARC",
        "value": "Dagenham - ARC Jetty [Hanson]"
    },
    {
        "key": "DFDLONDFD",
        "value": "Dartford Freight Terminal"
    },
    {
        "key": "DGSDGSDGS",
        "value": "Douglas (IOM)"
    },
    {
        "key": "DVRDOVAPC",
        "value": "Dover - Admiralty Pier & Cruise Terminal"
    },
    {
        "key": "DVRDOVDVR",
        "value": "Dover Eastern Docks"
    },
    {
        "key": "DUNDUNDUN",
        "value": "Dundee"
    },
    {
        "key": "DNUARGHLM",
        "value": "Holy Loch Marina - Dunoon"
    },
    {
        "key": "ELLELLELL",
        "value": "Ellesmere Port - Innospec Wharf"
    },
    {
        "key": "ERIMEDEOW",
        "value": "Erith Oil Works - Jetties 1,  2 & 3; Albion Wharf"
    },
    {
        "key": "EVTINVEVT",
        "value": "Evanton Wharf - Highland Deephaven"
    },
    {
        "key": "FALFALFAL",
        "value": "Falmouth"
    },
    {
        "key": "FASGRKFAS",
        "value": "Faslane HM Naval Base"
    },
    {
        "key": "FAWSTNFAW",
        "value": "Fawley Wharf [Esso Refinary]"
    },
    {
        "key": "FXTFXTFXT",
        "value": "Felixstowe"
    },
    {
        "key": "FISFISFIS",
        "value": "Fishguard"
    },
    {
        "key": "FLEFLEFLE",
        "value": "Fleetwood"
    },
    {
        "key": "FLWTRTFLW",
        "value": "Flixborough Wharf"
    },
    {
        "key": "FLHKWLFLH",
        "value": "Flotta Oil Terminal"
    },
    {
        "key": "FOYFOYFOY",
        "value": "Fowey Harbour"
    },
    {
        "key": "FRBFRBFRB",
        "value": "Fraserburgh Harbour"
    },
    {
        "key": "GARINVGAR",
        "value": "Gairloch Wharf"
    },
    {
        "key": "GADGRGFOT",
        "value": "Garelochhead - Finnart Ocean Terminal"
    },
    {
        "key": "GADGRGOFD",
        "value": "Garelochhead - Oil Fuel Depot"
    },
    {
        "key": "GTNLIVGTN",
        "value": "Garston Docks"
    },
    {
        "key": "GLWGLWGLW",
        "value": "Glasgow King George V Dock"
    },
    {
        "key": "GLWGLWGRB",
        "value": "Glasgow King George V Dock - Riverside Berth"
    },
    {
        "key": "GLWGLWBCW",
        "value": "Glasgow - Barclay Curle Engine Wharf"
    },
    {
        "key": "GLWGLWIGD",
        "value": "Glasgow - Inchgreen Dock"
    },
    {
        "key": "GLDGLDGLD",
        "value": "Glasson Dock"
    },
    {
        "key": "GSAOBAGSA",
        "value": "Glensanda Jetty"
    },
    {
        "key": "GOOGOOGOO",
        "value": "Goole"
    },
    {
        "key": "GOSPTMOFD",
        "value": "Gosport - Oil Fuel Depot"
    },
    {
        "key": "GRGGRGLPG",
        "value": "Grangemouth - RLPG (BP) Jetty"
    },
    {
        "key": "GRGHPTHPT",
        "value": "Grangemouth - Hound Point Tanker Berths 1 & 2"
    },
    {
        "key": "GRGGRGE12",
        "value": "Grangemouth - Petroineos Jetty Heads E1 & E2"
    },
    {
        "key": "GRGGRGDMC",
        "value": "Grangemouth - D M Crombie New Jetty"
    },
    {
        "key": "GRGGRGINR",
        "value": "Grangemouth - Ineos Refinery,  Jetties J1 J2 & J3"
    },
    {
        "key": "GRGGRGGRG",
        "value": "Grangemouth Docks"
    },
    {
        "key": "GVSMEDCMT",
        "value": "Gravesend - Clubbs Maritime Terminal"
    },
    {
        "key": "GYSLONNST",
        "value": "Grays - NuStar Terminal Jetties 1 & 2"
    },
    {
        "key": "GYSLONGOJ",
        "value": "Grays - Oil Jetty,  West Thurrock"
    },
    {
        "key": "GYSLONGTW",
        "value": "Grays - Titan Works,  West Thurrock Jetty"
    },
    {
        "key": "GOAHRHGOA",
        "value": "Great Oakley Works - Bramble Island"
    },
    {
        "key": "GTYGTYGTY",
        "value": "Great Yarmouth"
    },
    {
        "key": "GTYGTYKGF",
        "value": "Great Yarmouth - Kings Wharf"
    },
    {
        "key": "GRKGRKGRK",
        "value": "Greenock - Ocean Terminal"
    },
    {
        "key": "GNWLONFCT",
        "value": "Greenwich - Floating Cruise Terminal"
    },
    {
        "key": "GNWLONVIC",
        "value": "Greenwich - Victoria Deep Water Terminal"
    },
    {
        "key": "GSYGRIGSY",
        "value": "Grimsby"
    },
    {
        "key": "GUWTRTGUW",
        "value": "Gunness Wharf"
    },
    {
        "key": "GUSTRTGRO",
        "value": "Gunness - Grove Wharf"
    },
    {
        "key": "GUSTRTNHW",
        "value": "Gunness - Neap House Wharf"
    },
    {
        "key": "HAMSTNHAM",
        "value": "Hamble Jetty [BP]"
    },
    {
        "key": "HTPHTPHTP",
        "value": "Hartlepool"
    },
    {
        "key": "HTPHTPSPQ",
        "value": "Hartlepool - Seaton Port Quays 1,  10 & 11 (TERRC)"
    },
    {
        "key": "HRWHRHHRW",
        "value": "Harwich - Parkeston Quay"
    },
    {
        "key": "HRWHRHNYW",
        "value": "Harwich - Navyard Wharf"
    },
    {
        "key": "HYMHEYHYM",
        "value": "Heysham"
    },
    {
        "key": "HLYHLDHLY",
        "value": "Holyhead"
    },
    {
        "key": "HDDGOOHDD",
        "value": "Howden Dyke - Wharves 1, 2, 3 & 4"
    },
    {
        "key": "HULHULHUL",
        "value": "Hull"
    },
    {
        "key": "IMMIMMIMM",
        "value": "Immingham Docks"
    },
    {
        "key": "IVGIVGAMP",
        "value": "Invergordon - Admiralty Pier"
    },
    {
        "key": "IVGIVGIVG",
        "value": "Invergordon - Saltburn Pier & Service Base"
    },
    {
        "key": "INKGRGINK",
        "value": "Inverkeithing Harbour"
    },
    {
        "key": "INKGRGENT",
        "value": "Inverkeithing - East Ness Terminal"
    },
    {
        "key": "INVINVINV",
        "value": "Inverness"
    },
    {
        "key": "IPSIPSIPS",
        "value": "Ipswich Dock"
    },
    {
        "key": "IPSIPSOTE",
        "value": "Ipswich - Orwell Terminal,  Eagle Wharf"
    },
    {
        "key": "IOGMEDNSJ",
        "value": "Isle of Grain - No. 6 Jetty"
    },
    {
        "key": "IOGMEDBPO",
        "value": "Isle of Grain - BP Oils Wharf"
    },
    {
        "key": "IOGMEDLNG",
        "value": "Isle of Grain - GRAIN LNG"
    },
    {
        "key": "KEATRTKEA",
        "value": "Keadby Wharf"
    },
    {
        "key": "KPSBELKPS",
        "value": "Kilroot Power Station Jetty"
    },
    {
        "key": "KLNKLNKLN",
        "value": "King's Lynn"
    },
    {
        "key": "KBEKLBKBE",
        "value": "Kinlochbervie"
    },
    {
        "key": "KKDGRGKKD",
        "value": "Kirkcaldy Dock - East Quay"
    },
    {
        "key": "KWLKWLKWL",
        "value": "Kirkwall"
    },
    {
        "key": "LGSGRKLGS",
        "value": "Largs - Hunterstown Terminal"
    },
    {
        "key": "LARBELLAR",
        "value": "Larne Harbour"
    },
    {
        "key": "LEILEILEI",
        "value": "Leith"
    },
    {
        "key": "LERLERLER",
        "value": "Lerwick"
    },
    {
        "key": "LITSHOLIT",
        "value": "Littlehampton Quayside"
    },
    {
        "key": "LIVLIVLIV",
        "value": "Liverpool Docks"
    },
    {
        "key": "LIVLIVCRU",
        "value": "Liverpool - Cruise Terminal,  Princess Dock"
    },
    {
        "key": "LCGARGLCG",
        "value": "Loch Long - Glen Mallen Jetty MOD DSDA"
    },
    {
        "key": "LHSGRKLHS",
        "value": "Loch Striven - NATO POL Jetty"
    },
    {
        "key": "LOVLCVLOV",
        "value": "Lochinver"
    },
    {
        "key": "LGPLGPLGP",
        "value": "London Gateway Port"
    },
    {
        "key": "LTPTHPLTP",
        "value": "London Thamesport - Isle of Grain"
    },
    {
        "key": "LONLONTMP",
        "value": "London - Tower Millenium Pier Cruise Terminal"
    },
    {
        "key": "LDYLDYLDY",
        "value": "Londonderry"
    },
    {
        "key": "LOWLOWLOW",
        "value": "Lowestoft"
    },
    {
        "key": "LOWLOWLNQ",
        "value": "Lowestoft - North Quay,  Grain Silo"
    },
    {
        "key": "MSCMNCMSC",
        "value": "Manchester Ship Canal"
    },
    {
        "key": "MAWSTNSMC",
        "value": "Marchwood Port - Sea Mounting Centre"
    },
    {
        "key": "MYNLDYMYN",
        "value": "Maydown - Oil Terminal, Coolkeeragh"
    },
    {
        "key": "MTHGRGMTH",
        "value": "Methil"
    },
    {
        "key": "MIDMIDDWW",
        "value": "Middlesbrough - Dawsons Wharf & North Sea Supply Base (NSSB)"
    },
    {
        "key": "MIDMIDNMB",
        "value": "Middlesbrough - Normanby Wharf"
    },
    {
        "key": "MIDMIDCCW",
        "value": "Middlesbrough - Cochranes Wharf"
    },
    {
        "key": "MLFMILSOH",
        "value": "Milford Haven - South Hook LNG Berths 1 & 2"
    },
    {
        "key": "MLFMILSEM",
        "value": "Milford Haven - Semlogistics Berths 2 & 3"
    },
    {
        "key": "MLFMILRTB",
        "value": "Milford Haven - Refinery Terminal Berths 1 - 3"
    },
    {
        "key": "MLFMILDRA",
        "value": "Milford Haven - Dragon LNG Wharf"
    },
    {
        "key": "MLFMILPEM",
        "value": "Milford Haven - Pembroke Refinery"
    },
    {
        "key": "MISHRHMIS",
        "value": "Mistley Wharf"
    },
    {
        "key": "MONMONMON",
        "value": "Montrose"
    },
    {
        "key": "CHEMOSCHE",
        "value": "Mostyn"
    },
    {
        "key": "NHOIMMNHP",
        "value": "New Holland Pier"
    },
    {
        "key": "NHOIMMNHD",
        "value": "New Holland Dock"
    },
    {
        "key": "NHVNHVNHV",
        "value": "Newhaven"
    },
    {
        "key": "NYLPENNYL",
        "value": "Newlyn - North, South & Mary Williams Piers; Fish Market & Old Quays"
    },
    {
        "key": "NPTNPTBPW",
        "value": "Newport - Bird Port Wharf"
    },
    {
        "key": "NPTNPTALX",
        "value": "Newport - Alexandra Dock"
    },
    {
        "key": "NPTNPTLSW",
        "value": "Newport - Liberty Steel Wharf"
    },
    {
        "key": "NPTNPTNPT",
        "value": "Newport - Alexander Dock"
    },
    {
        "key": "NGGABDNGG",
        "value": "Nigg Oil Terminal [Jacky Oil Platform]"
    },
    {
        "key": "NKHIMMNKH",
        "value": "North Killingholme - Humber Sea Terminal"
    },
    {
        "key": "NSHTYNNSH",
        "value": "North Shields"
    },
    {
        "key": "NFTLONTER",
        "value": "Northfleet - Terminal Wharf"
    },
    {
        "key": "NFTMED42W",
        "value": "Northfleet - 42 Wharf [Cement Works]"
    },
    {
        "key": "NFTMEDRLW",
        "value": "Northfleet - Red Lion Wharf"
    },
    {
        "key": "NFTLONTOW",
        "value": "Northfleet - Tower Wharf"
    },
    {
        "key": "PELPELPEL",
        "value": "Peel (Isle of Man)"
    },
    {
        "key": "PEDMILPED",
        "value": "Pembroke Dock"
    },
    {
        "key": "PERDUNPER",
        "value": "Perth Harbour"
    },
    {
        "key": "PHDPHDPHD",
        "value": "Peterhead"
    },
    {
        "key": "PLYPLYPLY",
        "value": "Plymouth - Millbay Docks"
    },
    {
        "key": "PLYPLYVCW",
        "value": "Plymouth - Victoria Wharf"
    },
    {
        "key": "PLYPLYCDW",
        "value": "Plymouth - Cattledown Wharf"
    },
    {
        "key": "PLYPLYPPJ",
        "value": "Plymouth - Pomphlett Jetty"
    },
    {
        "key": "PLYPLYCOR",
        "value": "Plymouth - Corporation Wharf"
    },
    {
        "key": "POOPOOPOO",
        "value": "Poole"
    },
    {
        "key": "POOPOOHWW",
        "value": "Poole - Hamworthy Wharf"
    },
    {
        "key": "POCMIDNTS",
        "value": "Port Clarence - North Tees Site,  Jetties 1A,  2 & 3"
    },
    {
        "key": "POCMIDNAV",
        "value": "Port Clarence - North Tees Site No. 4 Jetty"
    },
    {
        "key": "PTBPTBPTB",
        "value": "Port Talbot"
    },
    {
        "key": "PTLPTLPTL",
        "value": "Portland"
    },
    {
        "key": "PRHWEYPRH",
        "value": "Portland Harbour"
    },
    {
        "key": "PRHWEYIBB",
        "value": "Portland Harbour - Inner Breakwater Berth"
    },
    {
        "key": "PRTABDPRT",
        "value": "Portree,  Isle of Skye"
    },
    {
        "key": "PTSSHOPTS",
        "value": "Portslade - Texaco Wharf"
    },
    {
        "key": "PMEPTMPME",
        "value": "Portsmouth Continental Ferry Port"
    },
    {
        "key": "PMEPTMHMN",
        "value": "Portsmouth - HM Naval Base, Oil Fuel Jetty & Bedenham Pier/UHAF"
    },
    {
        "key": "PMEPTMAJQ",
        "value": "Portsmouth - Albert Johnson Quay"
    },
    {
        "key": "PMEPTMFHQ",
        "value": "Portsmouth - Flathouse Quay"
    },
    {
        "key": "PFTLONPFT",
        "value": "Purfleet - C-Ro"
    },
    {
        "key": "PFTLONESS",
        "value": "Purfleet - Esso No 1 Jetty"
    },
    {
        "key": "PFTLONJUJ",
        "value": "Purfleet - Jurgens Jetty"
    },
    {
        "key": "PFTLONCMJ",
        "value": "Purfleet - Civil & Marine Jetty"
    },
    {
        "key": "RAMRSYRAM",
        "value": "Ramsey (Isle of Man)"
    },
    {
        "key": "RMGRMGRMG",
        "value": "Ramsgate"
    },
    {
        "key": "RERMIDRED",
        "value": "Redcar"
    },
    {
        "key": "RENGLWREN",
        "value": "Renfrew - Lobnitz Dock"
    },
    {
        "key": "RIDMEDRST",
        "value": "Ridham Dock - West Side [Ridham Sea Terminals]"
    },
    {
        "key": "RIDMEDRBS",
        "value": "Ridham Dock - East Side [Robert Brett & Sons]"
    },
    {
        "key": "RIDMEDGHJ",
        "value": "Ridham Dock - Grovehurst Jetty,  Iwade"
    },
    {
        "key": "RCSMEDRCW",
        "value": "Rochester - Crown Wharf"
    },
    {
        "key": "ROYROSROY",
        "value": "Rosyth - North Wall"
    },
    {
        "key": "ROYROSRND",
        "value": "Rosyth Dockyard"
    },
    {
        "key": "RUNRUNRUN",
        "value": "Runcorn"
    },
    {
        "key": "RYEDOGRYE",
        "value": "Rye Wharf"
    },
    {
        "key": "SWYLERSWY",
        "value": "Scalloway Harbour"
    },
    {
        "key": "SCFKWLSCF",
        "value": "Scapa"
    },
    {
        "key": "SCRINVSCR",
        "value": "Scrabster"
    },
    {
        "key": "SEASUNSEA",
        "value": "Seaham Harbour"
    },
    {
        "key": "XSSMIDITT",
        "value": "Seal Sands - No 1 and 2 Jetties,  Seal Sands - [Inter Terminals]"
    },
    {
        "key": "TEEMIDINE",
        "value": "Seal Sands - No 1 and 2 Jetties,  Seal Sands - [INEOS]"
    },
    {
        "key": "XSSMIDEKO",
        "value": "Seal Sands - Ekofisk Nos 1-6 & 8 Jetties"
    },
    {
        "key": "XSSMIDNAV",
        "value": "Seal Sands - 1, 2,  & 3 Jetties, Navigator Terminals"
    },
    {
        "key": "SSSSSSSSS",
        "value": "Sharpness Docks"
    },
    {
        "key": "SHSMEDSHS",
        "value": "Sheerness Docks"
    },
    {
        "key": "ESHSHOESH",
        "value": "Shoreham-by-Sea"
    },
    {
        "key": "ESHSHOEGY",
        "value": "Shoreham-by-Sea - Egypt Wharf"
    },
    {
        "key": "ESHSHOKWW",
        "value": "Shoreham-by-Sea - Kingston Wharf West"
    },
    {
        "key": "SILSILSIL",
        "value": "Silloth Dock"
    },
    {
        "key": "SVTLONTLS",
        "value": "Silvertown - Thames Refinery [Silvertown & Cairns Mill Wharf]"
    },
    {
        "key": "KSUIMMAPT",
        "value": "South Killingholme - APT Jetty"
    },
    {
        "key": "SSHTYNSSH",
        "value": "South Shields [Tyne Dock]"
    },
    {
        "key": "SOUSTNSOU",
        "value": "Southampton Docks"
    },
    {
        "key": "SOUSTNPWS",
        "value": "Southampton - Princes Wharf"
    },
    {
        "key": "SHKSHOSHK",
        "value": "Southwick - Penney's & Turberville Wharves"
    },
    {
        "key": "SLHLONSHJ",
        "value": "Stanford-le-Hope - Shell Haven Jetty"
    },
    {
        "key": "SLHLONCRT",
        "value": "Stanford-le-Hope - Coryton Jetty"
    },
    {
        "key": "STOSTOSTO",
        "value": "Stornoway"
    },
    {
        "key": "STOSTOARN",
        "value": "Stornoway - Arnish Quay"
    },
    {
        "key": "SFDMNCCSR",
        "value": "Stretford - Cerestar Wharf, MSC"
    },
    {
        "key": "SFDMNCDD1",
        "value": "Stretford - Dry Dock 1, MSC"
    },
    {
        "key": "SNSKWLSNS",
        "value": "Stromness (including North and South Piers and Coplands Dock)"
    },
    {
        "key": "STDMEDSLT",
        "value": "Strood - Scotline Terminal"
    },
    {
        "key": "SULLERSUL",
        "value": "Sullom Voe"
    },
    {
        "key": "SUNSUNSUN",
        "value": "Sunderland"
    },
    {
        "key": "PSBKLNPSB",
        "value": "Sutton Bridge Port"
    },
    {
        "key": "SWASWAKDE",
        "value": "Swansea - E Berth,  Kings Dock"
    },
    {
        "key": "SWASWASWA",
        "value": "Swansea"
    },
    {
        "key": "TEEMIDTEE",
        "value": "Teesport"
    },
    {
        "key": "TEEMIDQPT",
        "value": "Teesport - Quay 1,  Potash Terminal"
    },
    {
        "key": "TNMTNMTNM",
        "value": "Teignmouth - Old,  Eastern & Western Quays"
    },
    {
        "key": "TTLHULTTL",
        "value": "Tetney Terminal - Mono Mooring Buoy"
    },
    {
        "key": "DFDTEPDFD",
        "value": "Thames Europort"
    },
    {
        "key": "TILLONTIL",
        "value": "Tilbury Docks"
    },
    {
        "key": "TOBARGTOB",
        "value": "Tobermory - Ledaig Pontoons,  Isle of Mull"
    },
    {
        "key": "UTORTNMTOR",
        "value": "Torquay - Haldon Pier, South Pier & Beacon Quay"
    },
    {
        "key": "TRNGLWTRN",
        "value": "Troon"
    },
    {
        "key": "TRUFALTRU",
        "value": "Truro - Lighterage Quay"
    },
    {
        "key": "ULLINVULL",
        "value": "Ullapool"
    },
    {
        "key": "WLKTYNNEP",
        "value": "Walker - Neptune Energy Park"
    },
    {
        "key": "WLKTYNOTP",
        "value": "Walker - Offshore Technology Park"
    },
    {
        "key": "WLALONBTW",
        "value": "Wallasea Island - Baltic Wharf"
    },
    {
        "key": "WPTWPTWPT",
        "value": "Warrenpoint Harbour"
    },
    {
        "key": "WEYWEYWEY",
        "value": "Weymouth"
    },
    {
        "key": "WTSWTSWTS",
        "value": "Whitstable"
    },
    {
        "key": "WICINVWIC",
        "value": "Wick"
    },
    {
        "key": "WISKLNWIS",
        "value": "Wisbech [Port of]"
    },
    {
        "key": "WORWORWOR",
        "value": "Workington"
    },
    {
        "key": "BIFBIFBGTGAS",
        "value": "Barrow Gas Terminal - Barrow in Furness"
    },
    {
        "key": "GTYGTYPGTGAS",
        "value": "Perenco Gas Terminal - Great Yarmouth"
    },
    {
        "key": "HWLHWLPGTGAS",
        "value": "Point of Ayr Gas Terminal - Holywell"
    },
    {
        "key": "HULHULEGTGAS",
        "value": "Easington Gas Terminal - Hull"
    },
    {
        "key": "MTPMTPTGTGAS",
        "value": "Theddlethorpe Gas Terminal - Mablethorpe"
    },
    {
        "key": "PHDPHDSGTGAS",
        "value": "St. Fergus Gas Terminal - Peterhead"
    },
    {
        "key": "XSSXSSTGTGAS",
        "value": "Teeside Gas Terminal - Seal Sands"
    },
    {
        "key": "SULSULSGTGAS",
        "value": "Shetland Gas Terminal - Sullom Voe"
    },
    { "key": "GBAUCYNAYRCYNGVM", value: "GBAUCYNAYRCYNGVM" },
    { "key": "GBAUDEUDEUDEUGVM", value: "GBAUDEUDEUDEUGVM" },
    { "key": "GBAUDVRDOVDVRGVM", value: "GBAUDVRDOVDVRGVM" },
    { "key": "GBAUEUTEUTEUTGVM", value: "GBAUEUTEUTEUTGVM" },
    { "key": "GBAUFISFISFISGVM", value: "GBAUFISFISFISGVM" },
    { "key": "GBAUHRHHRHHRHGVM", value: "GBAUHRHHRHHRHGVM" },
    { "key": "GBAUHYMHEYHYMGVM", value: "GBAUHYMHEYHYMGVM" },
    { "key": "GBAUHLYHLDHLYGVM", value: "GBAUHLYHLDHLYGVM" },
    { "key": "GBAUHULHULHULGVM", value: "GBAUHULHULHULGVM" },
    { "key": "GBAUIMMIMMIMMGVM", value: "GBAUIMMIMMIMMGVM" },
    { "key": "GBAUKLHKILKLHGVM", value: "GBAUKLHKILKLHGVM" },
    { "key": "GBAULIVLIVLIVGVM", value: "GBAULIVLIVLIVGVM" },
    { "key": "GBAUNHVNHVNHVGVM", value: "GBAUNHVNHVNHVGVM" },
    { "key": "GBAUPEDMILPEDGVM", value: "GBAUPEDMILPEDGVM" },
    { "key": "GBAUPLYPLYPLYGVM", value: "GBAUPLYPLYPLYGVM" },
    { "key": "GBAUPOOPOOPOOGVM", value: "GBAUPOOPOOPOOGVM" },
    { "key": "GBAUNCLTYNNCLGVM", value: "GBAUNCLTYNNCLGVM" },
    { "key": "GBAUPMEPTMPMEGVM", value: "GBAUPMEPTMPMEGVM" },
    { "key": "GBAUPUFPUFPUFGVM", value: "GBAUPUFPUFPUFGVM" },
    { "key": "GBAURMGRMGRMGGVM", value: "GBAURMGRMGRMGGVM" },
    { "key": "GBAUMEDMEDMEDGVM", value: "GBAUMEDMEDMEDGVM" },
    { "key": "GBAUTEEMIDTEEGVM", value: "GBAUTEEMIDTEEGVM" },
    { "key": "GBAUTILLONTILGVM", value: "GBAUTILLONTILGVM" },
    { "key": "CBRLHRKBX", value: "CBRLHRKBX" },
    { "key": "GBAUADGBHXEJX", value: "GBAUADGBHXEJX" },
    { "key": "GBAUHOULHRYRX", value: "GBAUHOULHRYRX" },
    { "key": "GBAUABDABDABDGVM", value: "GBAUABDABDABDGVM" },
    { "key": "GBAULRPAYRLRPGVM", value: "GBAULRPAYRLRPGVM" },
    { "key": "GBAUQDLBRSPTE", value: "GBAUQDLBRSPTE" },
    { "key": "GBAUPLELHRTGX", value: "GBAUPLELHRTGX" },
    { "key": "GBAUCBRLHRWVX", value: "GBAUCBRLHRWVX" },
    { "key": "GBAUCOAEMAOIE", value: "GBAUCOAEMAOIE" },
    { "key": "GBAUBELBELGVM", value: "GBAUBELBELGVM" },
    { "key": "GBAULARBELGVM", value: "GBAULARBELGVM" },
    { "key": "GBAUWPTWPTGVM", value: "GBAUWPTWPTGVM" },
    { "key": "GBAURSDDONMUE", value: "GBAURSDDONMUE" },
    { "key": "GBAUFXTFXTFXTGVM", value: "GBAUFXTFXTFXTGVM" },
    { "key": "GBAUASFLHROWD", value: "GBAUASFLHROWD" },
    { "key": "GBAUCCVBELDPB", value: "GBAUCCVBELDPB" },
    { "key": "GBAUANTBELDPK", value: "GBAUANTBELDPK" },
    { "key": "GBAUMLKBELDPM", value: "GBAUMLKBELDPM" },
    { "key": "GBAUXLUBELDPL", value: "GBAUXLUBELDPL" },
    { "key": "GBAUCRGBELDPC", value: "GBAUCRGBELDPC" },
    { "key": "GBAUTOOBELDPT", value: "GBAUTOOBELDPT" },
    { "key": "GBAUURLBELHAN", value: "GBAUURLBELHAN" },
    { "key": "GBAUBBGBELEOS", value: "GBAUBBGBELEOS" },
    { "key": "GBAUDFDDOVEPX", value: "GBAUDFDDOVEPX" },
    { "key": "GBAULWTLONTLE", value: "GBAULWTLONTLE" },
    { "key": "GBAUFEMLHRFQX", value: "GBAUFEMLHRFQX" },
    { "key": "GBAUCBRLHRKBX", value: "GBAUCBRLHRKBX" },
    { "key": "GBAULUTLTNKDX", value: "GBAULUTLTNKDX" },
    { "key": "GBAUFEMLHRTKX", value: "GBAUFEMLHRTKX" },
    { "key": "GBAUFEMLHRYSX", value: "GBAUFEMLHRYSX" },
    { "key": "GBAUBLELTNKYX", value: "GBAUBLELTNKYX" },
    { "key": "GBAUHOORCSKZX", value: "GBAUHOORCSKZX" },
    { "key": "GBAUBHIEMAKZX", value: "GBAUBHIEMAKZX" },
    { "key": "GBAUEDIEDIEDX", value: "GBAUEDIEDIEDX" },
    { "key": "GBAUIPSFXTEWB", value: "GBAUIPSFXTEWB" },
    { "key": "GBAUFEMLHROXE", value: "GBAUFEMLHROXE" },
    { "key": "GBAUASFDOGTCE", value: "GBAUASFDOGTCE" },
    { "key": "GBAUIPSFXTNVE", value: "GBAUIPSFXTNVE" },
    { "key": "GBAULBKLONMCE", value: "GBAULBKLONMCE" },
    { "key": "GBAUBILLONNSE", value: "GBAUBILLONNSE" },
    { "key": "GBAUPFTLONMKE", value: "GBAUPFTLONMKE" },
    { "key": "GBAUQWHEMADVE", value: "GBAUQWHEMADVE" },
    { "key": "GBAUCFDLSADCE", value: "GBAUCFDLSADCE" },
    { "key": "GBAUKTRBHXCVE", value: "GBAUKTRBHXCVE" },
    { "key": "GBAUTFDFXTADE", value: "GBAUTFDFXTADE" },
    { "key": "GBAUFEMLHRWTX", value: "GBAUFEMLHRWTX" },
    { "key": "GBAUWIHLSAUGE", value: "GBAUWIHLSAUGE" },
    { "key": "GBAUHHELTNETE", value: "GBAUHHELTNETE" },
    { "key": "GBAULIVLIVAWD", value: "GBAULIVLIVAWD" },
    { "key": "GBAUBRAFXTARE", value: "GBAUBRAFXTARE" },
    { "key": "GBAURDDBHXRRE", value: "GBAURDDBHXRRE" },
    { "key": "GBAUWBYLHRPME", value: "GBAUWBYLHRPME" },
    { "key": "GBAUMLLLSACPE", value: "GBAUMLLLSACPE" },
    { "key": "GBAUGYSLONDIE", value: "GBAUGYSLONDIE" },
    { "key": "GBAUHYNLIVMLE", value: "GBAUHYNLIVMLE" },
    { "key": "GBAUHHELTNMLE", value: "GBAUHHELTNMLE" },
    { "key": "GBAURMFTILCOE", value: "GBAURMFTILCOE" },
    { "key": "GBAUTLQSTNFSE", value: "GBAUTLQSTNFSE" },
    { "key": "GBAUDFDTHPERE", value: "GBAUDFDTHPERE" },
    { "key": "GBAURUILHRCRE", value: "GBAURUILHRCRE" },
    { "key": "GBAUBXETILCTE", value: "GBAUBXETILCTE" },
    { "key": "GBAUBRALSAABE", value: "GBAUBRALSAABE" },
    { "key": "GBAUCBYEMAHIE", value: "GBAUCBYEMAHIE" },
    { "key": "GBAUMMLLBANDE", value: "GBAUMMLLBANDE" },
    { "key": "GBAUCNKBHXCNE", value: "GBAUCNKBHXCNE" },
    { "key": "GBAULWMLBAKLE", value: "GBAULWMLBAKLE" },
    { "key": "GBAUBWOBHXLPE", value: "GBAUBWOBHXLPE" },
    { "key": "GBAUERIDOVRLE", value: "GBAUERIDOVRLE" },
    { "key": "GBAUBLVMEDATE", value: "GBAUBLVMEDATE" },
    { "key": "GBAUTILLONASE", value: "GBAUTILLONASE" },
    { "key": "GBAUBASLONCSE", value: "GBAUBASLONCSE" },
    { "key": "GBAUBASTILTFE", value: "GBAUBASTILTFE" },
    { "key": "GBAUBMBLIVBLE", value: "GBAUBMBLIVBLE" },
    { "key": "GBDUNRMFXTBLC", value: "GBDUNRMFXTBLC" },
    { "key": "GBDUSDWRMGNMO", value: "GBDUSDWRMGNMO" },
    { "key": "GBDURCSMEDBND", value: "GBDURCSMEDBND" },
    { "key": "GBDUWSSPTMIFA", value: "GBDUWSSPTMIFA" },
    { "key": "GBDUASFDOVIFA", value: "GBDUASFDOVIFA" },
    { "key": "GBDUCOQMOSEIR", value: "GBDUCOQMOSEIR" },
    { "key": "GBDUNRMGTYITC", value: "GBDUNRMGTYITC" },
    { "key": "GBDUKBTABDGNI", value: "GBDUKBTABDGNI" },
    { "key": "GBAULGXLONEYX", value: "GBAULGXLONEYX" },
    { "key": "GBAUHALLSAGFE", value: "GBAUHALLSAGFE" },
    { "key": "GBAUSTSLHRJBX", value: "GBAUSTSLHRJBX" },
    { "key": "GBAUSLLLHRGWE", value: "GBAUSLLLHRGWE" },
    { "key": "GBAUSLOLHRENX", value: "GBAUSLOLHRENX" },
    { "key": "GBAUWFEDOGMBE", value: "GBAUWFEDOGMBE" },
    { "key": "GBAUCBRLHRAUE", value: "GBAUCBRLHRAUE" },
    { "key": "GBAUWEDBHXEHE", value: "GBAUWEDBHXEHE" },
    { "key": "GBAUIPSIPSDME", value: "GBAUIPSIPSDME" },
    { "key": "GBAUNRYBELTSC", value: "GBAUNRYBELTSC" },
    { "key": "GBAUENKBELTSB", value: "GBAUENKBELTSB" },
    { "key": "GBAUGRBFXTCKE", value: "GBAUGRBFXTCKE" },
    { "key": "GBAULTPRCSCKX", value: "GBAULTPRCSCKX" },
    { "key": "GBAUPLELHRHNX", value: "GBAUPLELHRHNX" },
    { "key": "GBAUFXTFXTHEE", value: "GBAUFXTFXTHEE" },
    { "key": "GBAUABDABDGPE", value: "GBAUABDABDGPE" },
    { "key": "GBAUIPSFXTGME", value: "GBAUIPSFXTGME" },
    { "key": "GBAUBASLONCGE", value: "GBAUBASLONCGE" },
    { "key": "GBAUSNNBRSLEE", value: "GBAUSNNBRSLEE" },
    { "key": "GBAUEVCBRSPRE", value: "GBAUEVCBRSPRE" },
    { "key": "GBAUSTWFXTSRE", value: "GBAUSTWFXTSRE" },
    { "key": "GBAUGRBFXTRME", value: "GBAUGRBFXTRME" },
    { "key": "GBAUMHTFXTEWE", value: "GBAUMHTFXTEWE" },
    { "key": "GBAUGRBFXTCHE", value: "GBAUGRBFXTCHE" },
    { "key": "GBAUIPSFXTBSE", value: "GBAUIPSFXTBSE" },
    { "key": "GBAUIPSFXTSXE", value: "GBAUIPSFXTSXE" },
    { "key": "GBAUFXTFXTMAE", value: "GBAUFXTFXTMAE" },
    { "key": "GBAUFXTFXTKEE", value: "GBAUFXTFXTKEE" },
    { "key": "GBAUGTYGTYLLE", value: "GBAUGTYGTYLLE" },
    { "key": "GBAUHULHULMRE", value: "GBAUHULHULMRE" },
    { "key": "GBAUSLGIMMHTE", value: "GBAUSLGIMMHTE" },
    { "key": "GBAUKSUIMMITE", value: "GBAUKSUIMMITE" },
    { "key": "GBAUAPTMNCLRE", value: "GBAUAPTMNCLRE" },
    { "key": "GBAUAPTMNCYTE", value: "GBAUAPTMNCYTE" },
    { "key": "GBAUNHPLTNTFM", value: "GBAUNHPLTNTFM" },
    { "key": "GBAUCMTTYNLBE", value: "GBAUCMTTYNLBE" },
    { "key": "GBAUWIHLSASWE", value: "GBAUWIHLSASWE" },
    { "key": "GBAUWIHLSASGE", value: "GBAUWIHLSASGE" },
    { "key": "GBAUSOTEMASTE", value: "GBAUSOTEMASTE" },
    { "key": "GBAUROYLSACBE", value: "GBAUROYLSACBE" },
    { "key": "GBAUNHPLTNTGE", value: "GBAUNHPLTNTGE" },
    { "key": "GBAUTFKMANCWE", value: "GBAUTFKMANCWE" },
    { "key": "GBAUECSMANHSE", value: "GBAUECSMANHSE" },
    { "key": "GBAUHRDLHRHDE", value: "GBAUHRDLHRHDE" },
    { "key": "GBAUFDYBHXTSE", value: "GBAUFDYBHXTSE" },
    { "key": "GBAUHUDLTNOIE", value: "GBAUHUDLTNOIE" },
    { "key": "GBAURUHBHXBDE", value: "GBAURUHBHXBDE" },
    { "key": "GBAUCVTBHXNTE", value: "GBAUCVTBHXNTE" },
    { "key": "GBAUAYLLTNMGE", value: "GBAUAYLLTNMGE" },
    { "key": "GBAUIVSLSAMEE", value: "GBAUIVSLSAMEE" },
    { "key": "GBAUDWYLBAWHE", value: "GBAUDWYLBAWHE" },
    { "key": "GBAUCVTBHXWGE", value: "GBAUCVTBHXWGE" },
    { "key": "GBAULYNDOVPDX", value: "GBAULYNDOVPDX" },
    { "key": "GBAUHMWLHRWPX", value: "GBAUHMWLHRWPX" },
    { "key": "GBAUHEYLHRHOX", value: "GBAUHEYLHRHOX" },
    { "key": "GBAUCTFLBAVIE", value: "GBAUCTFLBAVIE" },
    { "key": "GBAUHROLHREHX", value: "GBAUHROLHREHX" },
    { "key": "GBAUCBRLHRKJX", value: "GBAUCBRLHRKJX" },
    { "key": "GBAUSLOLHRTTX", value: "GBAUSLOLHRTTX" },
    { "key": "GBAUWMHLTNUPX", value: "GBAUWMHLTNUPX" },
    { "key": "GBAUBHMBHXKEX", value: "GBAUBHMBHXKEX" },
    { "key": "GBAUIVELHRTJX", value: "GBAUIVELHRTJX" },
    { "key": "GBAUCBRLHRNOX", value: "GBAUCBRLHRNOX" },
    { "key": "GBAULRPAYRLRP", value: "GBAULRPAYRLRP" },
    { "key": "GBAUHESLHRNLX", value: "GBAUHESLHRNLX" },
    { "key": "GBAUDVRDOGWNL", value: "GBAUDVRDOGWNL" },
    { "key": "GBAUCVTBHXPCE", value: "GBAUCVTBHXPCE" },
    { "key": "GBAUHATLTNKSX", value: "GBAUHATLTNKSX" },
    { "key": "GBAUBHDBHDBWS", value: "GBAUBHDBHDBWS" },
]

export default identificationOfLocation