import SearchInput from "../../../common/SearchInput";
import { useCallback } from "react";
import { debounce } from "lodash";
import FilterButton from "../../../common/FilterButton/FilterButton";
import { getDeclarationLabel } from "../../../../helpers";
import { DECLARATION_STATUSES } from "../../../../constants/GlobalConstants";

const BasicSearch = (props) => {
	const { declarations, setFilteredDeclarations, filters, searchValue, setFilters, setSearchValue } = props;

	const searchDeclarations = (searchValue, filters) => {
		let filteredDeclarations = [];
		const lowerCaseSearchValue = searchValue.toLowerCase();

		if (!lowerCaseSearchValue && !Object.values(filters).length) {
			return setFilteredDeclarations([...declarations]);
		}

		// Recursively go through the declaration object and find if the search value equals one of the declaration properties
		const searchObject = (object) => {
			let matches = false;

			Object.keys(object).forEach((key) => {
				const value = object[key];

				if (typeof value === "object" && !Array.isArray(value)) {
					if (searchObject(value)) {
						matches = true;
					}
				} else if (
					typeof value === "string" &&
					value.toLowerCase().includes(lowerCaseSearchValue)
				) {
					matches = true;
				}
			});

			return matches;
		};

		const matchesFilter = (declaration) => {
			const service = declaration.data.service;
			const status = declaration.status;

			return (
				(filters.byDirection?.includes(service) || !filters.byDirection) &&
				(filters.byStatus?.includes(status) || !filters.byStatus)
			);
		};

		const matchesSearch = (declaration) => {
			const label = getDeclarationLabel(declaration);

			return (
				(lowerCaseSearchValue &&
					(label.toLowerCase().includes(lowerCaseSearchValue) ||
						searchObject(declaration))) ||
				!lowerCaseSearchValue
			);
		};

		declarations.forEach((declaration) => {
			if (matchesSearch(declaration) && matchesFilter(declaration)) {
				filteredDeclarations.push({ ...declaration });
			}
		});

		setFilteredDeclarations([...filteredDeclarations]);
	};

	const debouncedSearch = useCallback(
		debounce((searchValue, filters) => {
			searchDeclarations(searchValue || "", filters);
		}, 200),
		[declarations]
	);

	const handleSearchChange = (e) => {
		const { value } = e.target;
		setSearchValue(value);
		debouncedSearch(value, filters);
	};

	const handleFiltersChange = (filters) => {
		setFilters(filters);
		searchDeclarations(searchValue || "", filters);
	};

	return (
		<div className="d-flex align-items-center" style={{ paddingRight: 20, paddingLeft: 20 }}>
			<SearchInput
				containerStyle={{ width: 236, marginRight: 8 }}
				onChange={handleSearchChange}
				value={searchValue}
			/>

			<FilterButton value={filters} filterInputs={filterInputs} onChange={handleFiltersChange} />
		</div>
	);
};

const filterInputs = [
	{
		name: "byDirection",
		options: ["import", "export"],
		type: "radio",
	},
	{
		name: "byStatus",
		options: Object.values(DECLARATION_STATUSES),
		type: "radio",
	},
];

export default BasicSearch;
