import { useContext } from "react";
import Button from "react-bootstrap/Button";
import BootstrapButtonGroup from "react-bootstrap/ButtonGroup";
import ThemeContext from "../../../context/theme/ThemeContext";

const ButtonGroup = ({ buttons, selectedIndex }) => {
	const { withTheme } = useContext(ThemeContext);

	return (
		<BootstrapButtonGroup aria-label="Basic example">
			{buttons.map((button, index) => (
				<Button
					className={`${withTheme("button-group-button")} ${
						selectedIndex === index ? "button-group-button-selected" : ""
					}`}
					key={index}
					onClick={() => button.onClick()}
					variant="secondary"
				>
					{button.label}
				</Button>
			))}
		</BootstrapButtonGroup>
	);
};

export default ButtonGroup;
