import { LS_THEME_KEY } from '../../constants/GlobalConstants';
import { SET_THEME } from '../types';
import { initialThemeState } from './ThemeState';

export default (state, action) => {
    switch (action.type) {
        case SET_THEME: {
            const updatedState = {
                ...state,
                name: action.payload
            };
            
            localStorage.setItem(LS_THEME_KEY, JSON.stringify(updatedState));

            return {
                ...updatedState
            }
        }

        default:
            return state
    }
}