import { useContext } from "react";
import { useTranslation } from "react-i18next";
import DeclarationsContext from "../../../../context/declarations/DeclarationsContext";
import { handleValidationErrorsView } from "../../../../context/declarations/validations";
import ThemeContext from "../../../../context/theme/ThemeContext";
import ReactMarkdown from "react-markdown";
import Button from "../../../common/Button";

const ValidationErrors = () => {
	const { t } = useTranslation();
	const { withTheme } = useContext(ThemeContext);
	const { state } = useContext(DeclarationsContext);

	if (!state.validationErrors?.length) {
		return null;
	}

	return (
		<div className={withTheme("validation-errors-container")}>
			<div className={withTheme("validation-errors-title")}>
				{t("validationErrors")} ({state.validationErrors.length})
			</div>

			{state.validationErrors.map((error, index) => (
				<ErrorListItem error={error} key={index} />
			))}
		</div>
	);
};

const ErrorListItem = ({ error }) => {
	const { withTheme } = useContext(ThemeContext);
	const { t } = useTranslation();

	return (
		<div className={withTheme("error-list-item")}>
			<div style={{ fontSize: 14, width: "calc(100% - 100px)" }}>
				{/* {error.payload.level === "item" ? `[Item ${error.payload.itemIndex + 1}]` : ""}{" "} */}
				<ReactMarkdown children={error.message} />
			</div>

			<div style={{ width: "fit-content", marginLeft: "auto" }}>
				<Button onClick={() => handleValidationErrorsView(error)} type="secondary">
					{t("view")}
				</Button>
			</div>
		</div>
	);
};

export default ValidationErrors;
