import { useContext, useMemo, useRef } from "react";
import ThemeContext from "../../../../context/theme/ThemeContext";
import Button from "../../../common/Button";
import { PiPaperclip } from "react-icons/pi";
import { humanFileSize } from "../helpers";
import { FILE_SIZE_LIMIT_IN_MB } from "../../../../constants/GlobalConstants";
import { useTranslation } from "react-i18next";
import ErrorText from "../../../common/ErrorText";

const SelectFileInput = ({ selectedFile, error, onFileChange }) => {
	const { t } = useTranslation();
	const { withTheme } = useContext(ThemeContext);
	const fileInputRef = useRef(null);

	const sizeExceedsLimit = useMemo(
		() => selectedFile && selectedFile.size / 1000000 > FILE_SIZE_LIMIT_IN_MB,
		[selectedFile]
	);

	const handleFileInputClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.value = "";
		}
	};

	return (
		<div style={{ marginTop: 32 }}>
			<div style={{ display: "flex", alignItems: "center" }}>
				<div style={{ fontWeight: 500 }} className={withTheme("secondary-text")}>
					<span className={withTheme(sizeExceedsLimit ? "error-red" : "accent")}>
						{selectedFile ? humanFileSize(selectedFile.size, true, 0) : `0 MB`} /{" "}
					</span>
					{FILE_SIZE_LIMIT_IN_MB} MB
				</div>

				<div style={{ width: "fit-content", marginLeft: "auto" }}>
					<Button
						type="secondary"
						LeftIcon={PiPaperclip}
						onClick={() => {
							fileInputRef.current.click();
						}}
					>
						{t("selectFile")}
					</Button>

					<input
						ref={fileInputRef}
						type="file"
						multiple={false}
						style={{ display: "none" }}
						onChange={onFileChange}
						onClick={handleFileInputClick}
					/>
				</div>
			</div>
			{error && <ErrorText style={{ marginTop: 12 }}>{error}</ErrorText>}
		</div>
	);
};

export default SelectFileInput;
