import { useTranslation } from "react-i18next";
import Input from "../../../common/Input";
import { useContext, useEffect, useState } from "react";
import Button from "../../../common/Button";
import FilesContext from "../../../../context/files/FilesContext";

const FileListItemEditMode = ({ file, onCancel, onSave }) => {
	const [label, setLabel] = useState(false);
	const [error, setError] = useState(null);
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const { updateFile } = useContext(FilesContext);

	const { t } = useTranslation();

	const handleSaveClick = async () => {
		if (!label) {
			setError(t("required"));
			return;
		}

		if (label !== file.label) {
			setIsSaveLoading(true);

			await updateFile(file, { label });

			setIsSaveLoading(false);
		}

		onSave();
	};

	const handleInputChange = (e) => {
		setLabel(e.target.value);
		setError(null);
	};

	useEffect(() => {
		setLabel(file.label);
		setError(null);
	}, [file]);

	return (
		<div style={{ display: "flex", width: "100%" }}>
			<Input
				value={label}
				error={error}
				label={t("fileName")}
				placeholder={t("name")}
				containerStyle={{ width: "calc(100% - 230px)" }}
				style={{ width: "100%", maxWidth: 350 }}
				onChange={handleInputChange}
			/>

			<div
				style={{
					marginTop: 24,
					display: "flex",
					width: "fit-content",
					paddingLeft: 32,
					marginLeft: "auto",
					gap: 8,
				}}
			>
				<Button type="secondary" onClick={onCancel}>
					{t("cancel")}
				</Button>

				<Button onClick={handleSaveClick} isLoading={isSaveLoading}>
					{t("saveName")}
				</Button>
			</div>
		</div>
	);
};

export default FileListItemEditMode;
