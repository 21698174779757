import { useContext, useMemo } from "react";
import ThemeContext from "../../../context/theme/ThemeContext";
import { useTranslation } from "react-i18next";
import FilterOption from "./Option";
import validationElements from "../../../constants/validation";

const FilterInput = ({ input, value, onChange }) => {
	const { t } = useTranslation();
	const { withTheme } = useContext(ThemeContext);

	const inputElement = useMemo(() => {
		if (input.type === "element" && input.elementName) {
			return validationElements.find((tmpElement) => tmpElement.elementName === input.elementName);
		}
	}, [input]);

	let Input = null;

	if (input.type === "radio") {
		Input = <RadioInput input={input} value={value} onChange={(value) => onChange(input.name, value)} />;
	} else if (input.type === "element" && inputElement) {
		return <></>;
	}

	return (
		<div style={{ marginBottom: 12 }}>
			<div style={{ marginBottom: 12 }} className={withTheme("overlay-label")}>
				{t(input.name)}
			</div>
			{Input}
		</div>
	);
};

const RadioInput = ({ input, value, onChange }) => {
	const handleFilterClick = (filter) => {
		if (value?.includes(filter)) {
			value.splice(value.indexOf(filter), 1);
			onChange([...value]);
		} else {
			onChange([...(value || []), filter]);
		}
	};

	return (
		<div
			style={{
				paddingLeft: 4,
				paddingRight: 4,
				display: "flex",
				flexDirection: "column",
				gap: 8,
				marginTop: 8,
			}}
		>
			{input.options.map((option, index) => (
				<FilterOption
					option={option}
					isSelected={value?.includes(option)}
					onFilterClick={handleFilterClick}
					key={index}
				/>
			))}
		</div>
	);
};

export default FilterInput;
