import { useContext, useEffect, useMemo, useState } from "react";
import FilesContext from "../../../../context/files/FilesContext";
import LoadingSpinner from "../../../common/LoadingSpinner";
import SearchInput from "../../../common/SearchInput";
import { useTranslation } from "react-i18next";
import FileListItem from "../FileListItem/FileListItem";
import EmptyPageMessage from "../../../common/EmptyPageMessage";
import DeclarationsContext from "../../../../context/declarations/DeclarationsContext";

const UploadedFilesList = ({ selectedFileIds, onFileClick }) => {
	const { t } = useTranslation();
	const [searchValue, setSearchValue] = useState("");
	const { state } = useContext(DeclarationsContext);
	const { files, loadFiles, isFilesLoading } = useContext(FilesContext);

	const filteredFiles = useMemo(() => {
		if (!searchValue) {
			return files || [];
		}

		const lowerCaseSearchValue = searchValue.toLowerCase();

		return files?.filter(
			(file) =>
				file.label.toLowerCase().includes(lowerCaseSearchValue) ||
				file.description.toLowerCase().includes(lowerCaseSearchValue)
		) || [];
	}, [searchValue, files]);

	useEffect(() => {
		setSearchValue("");

		if (!files?.length) {
			loadFiles();
		}
	}, []);

	if (!files?.length && !isFilesLoading) {
		return null;
	}

	return (
		<div>
			<>
				<div style={{ fontSize: 16, fontWeight: 500, marginTop: 40, marginBottom: 12 }}>
					{t("selectFromPreviouslyUploaded")}
				</div>

				<SearchInput
					value={searchValue}
					placeholder={t("searchByKeywords")}
					containerStyle={{ width: "100%" }}
					onChange={(e) => setSearchValue(e.target.value)}
				/>
			</>

			{isFilesLoading ? (
				<div
					style={{
						width: "fit-content",
						marginLeft: "auto",
						marginRight: "auto",
						marginTop: 32,
					}}
				>
					<LoadingSpinner />
				</div>
			) : (
				<>
					<div
						style={{
							display: "flex",
							flexWrap: "wrap",
							gap: 12,
							marginTop: 12,
							maxHeight: "290px",
							overflowY: "auto",
						}}
					>
						{filteredFiles.map((file, index) => {
							const isAssociated = state.declaration.files.find(
								({ _id }) => _id === file._id
							);

							return (
								<FileListItem
									key={index}
									file={file}
									style={{ width: "calc(50% - 6px)" }}
									isDisabled={isAssociated}
									isSelected={isAssociated || selectedFileIds.includes(file._id)}
									onClick={onFileClick}
								/>
							);
						})}
					</div>

					{!filteredFiles.length && (
						<EmptyPageMessage style={{ paddingTop: 32 }} text={t("noFilesFound")} />
					)}
				</>
			)}
		</div>
	);
};

export default UploadedFilesList;
