import { axiosInstance } from "../index";
import config from "../config";
import { getHeaders } from "./helpers";

const { apiUrl } = config;

export const getSettings = async () => {
	const result = await axiosInstance.get(`${apiUrl}/settings`, { headers: getHeaders() });

	return result.data;
};

export const createSettings = async (payload) => {
	const result = await axiosInstance.post(
		`${apiUrl}/settings`,
		{ meta: { ...payload } },
		{ headers: getHeaders() }
	);

	return result.data;
};

export const updateSettings = async (payload) => {
	const result = await axiosInstance.put(`${apiUrl}/settings`, { settings: payload }, { headers: getHeaders() });

	return result.data;
};

export const savePushSubscription = async (subscription) => {
	const result = await axiosInstance.post(`${apiUrl}/settings/push-subscriptions`, subscription, {
		headers: getHeaders(),
	});

	return result.data;
};

export const deletePushSubscription = async (endpoint) => {
	const result = await axiosInstance.delete(`${apiUrl}/settings/push-subscriptions`, {
		headers: getHeaders(),
		params: { endpoint },
	});

	return result.data;
};

export const changeUserPassword = async (oldPassword, newPassword) => {
	const result = await axiosInstance.post(
		`${apiUrl}/settings/security`,
		{ oldPassword, newPassword },
		{ headers: getHeaders(), params: { operation: "change-password" } }
	);

	return result.data
};
