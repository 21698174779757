import { useContext } from "react";
import ThemeContext from "../../context/theme/ThemeContext";
import ErrorText from "./ErrorText";

const Textarea = ({ label, error, ...rest }) => {
	const { withTheme } = useContext(ThemeContext);

	return (
		<div style={{ textAlign: "left" }}>
			{label && (
				<div style={{ marginBottom: 4 }} className={withTheme("input-label")}>
					{label}
				</div>
			)}
			<textarea
				{...rest}
				className={`${withTheme("text-area")} ${error ? withTheme("text-area-error") : ""}`}
			/>

			{error && <ErrorText style={{ marginTop: 0 }}>{error}</ErrorText>}
		</div>
	);
};

export default Textarea;
