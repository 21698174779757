// Redux for moving attributes around with state
import {
	SET_DISABLED_DECLARATION_ELEMENTS,
	SET_ELEMENT_VALUE,
	SET_ITEM_ELEMENT_VALUE,
	SET_MANDATORY_DECLARATION_ELEMENTS,
	UPDATE_ITEM,
	UPDATE_STATE,
	DELETE_ITEM,
	CREATE_ITEM,
	SET_ITEM_ELEMENT_VALUE_IN_SECTION,
	SET_ELEMENT_VALUE_IN_SETCION,
	SET_VALIDATION_ERRORS,
	SET_UNSAVED_CHANGES,
	SET_REQUIRED_PULLDOWN_OPTIONS,
	LIST_DECLARATIONS,
	LIST_DECLARATIONS_LOADING,
	CLEAR_SELECTED_DECLARATION_STATE,
	SET_LOADING_DECLARATION_ID,
} from "../types";

const declarationsReducer = (state, action) => {
	switch (action.type) {
		case LIST_DECLARATIONS_LOADING:
			return { ...state, declarationsLoading: action.payload };
		case LIST_DECLARATIONS:
			return { ...state, declarations: action.payload, declarationsLoading: false };
		case UPDATE_STATE:
			const result = {
				...state,
				declaration: { ...action.payload },
			};
			return result;
		case CLEAR_SELECTED_DECLARATION_STATE: {
			return {
				...state,
				declaration: null,
				notifications: [],
				validationErrors: [],
				hasUnsavedChanges: null,
			};
		}

		case SET_ELEMENT_VALUE: {
			const { name, value } = action.payload;
			return {
				...state,
				declaration: {
					...state.declaration,
					data: {
						...state.declaration.data,
						[name]: value,
					},
				},
			};
		}

		case SET_ELEMENT_VALUE_IN_SETCION: {
			const { section, name, value } = action.payload;

			return {
				...state,
				declaration: {
					...state.declaration,
					data: {
						...state.declaration.data,
						[section]: {
							...state.declaration.data[section],
							[name]: value,
						},
					},
				},
			};
		}

		case SET_ITEM_ELEMENT_VALUE_IN_SECTION: {
			const { section, name, value, itemIndex } = action.payload;

			state.declaration.data.declarationItems[itemIndex] = {
				...state.declaration.data.declarationItems[itemIndex],
				updateTimeStamp: new Date().toISOString(),
				[section]: {
					...state.declaration.data.declarationItems[itemIndex][section],
					[name]: value,
				},
			};

			return {
				...state,
				declaration: {
					...state.declaration,
					data: {
						...state.declaration.data,
						declarationItems: [...state.declaration.data.declarationItems],
					},
				},
			};
		}

		case SET_ITEM_ELEMENT_VALUE: {
			const { name, value, itemIndex } = action.payload;
			state.declaration.data.declarationItems[itemIndex] = {
				...state.declaration.data.declarationItems[itemIndex],
				updateTimeStamp: new Date().toISOString(),
				[name]: value,
			};

			return {
				...state,
				declaration: {
					...state.declaration,
					data: {
						...state.declaration.data,
						declarationItems: [...state.declaration.data.declarationItems],
					},
				},
			};
		}

		case CREATE_ITEM: {
			return {
				...state,
				declaration: {
					...state.declaration,
					data: {
						...state.declaration.data,
						declarationItems: [
							...(state.declaration.data.declarationItems || []),
							{ ...action.payload },
						],
					},
				},
			};
		}

		case DELETE_ITEM: {
			const { itemIndex } = action.payload;
			state.declaration.data.declarationItems.splice(itemIndex, 1);
			return {
				...state,
				declaration: {
					...state.declaration,
					data: {
						...state.declaration.data,
						declarationItems: [...state.declaration.data.declarationItems],
					},
				},
			};
		}

		case UPDATE_ITEM: {
			const { updates, itemIndex } = action.payload;
			state.declaration.data.declarationItems[itemIndex] = {
				...state.declaration.data.declarationItems[itemIndex],
				...updates,
			};

			return {
				...state,
				declaration: {
					...state.declaration,
					data: {
						...state.declaration.data,
						declarationItems: [...state.declaration.data.declarationItems],
					},
				},
			};
		}

		case SET_MANDATORY_DECLARATION_ELEMENTS:
			return {
				...state,
				mandatoryElements: action.payload,
			};

		case SET_DISABLED_DECLARATION_ELEMENTS:
			return {
				...state,
				disabledElements: action.payload,
			};

		case SET_VALIDATION_ERRORS:
			return {
				...state,
				validationErrors: action.payload,
			};

		case SET_UNSAVED_CHANGES:
			return {
				...state,
				hasUnsavedChanges: action.payload,
			};

		case SET_REQUIRED_PULLDOWN_OPTIONS:
			return {
				...state,
				requiredPulldownOptions: action.payload,
			};

		case SET_LOADING_DECLARATION_ID:
			return {
				...state,
				loadingDeclarationId: action.payload,
			};

		default:
			return state;
	}
};

export default declarationsReducer;
