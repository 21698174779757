import { useContext, useEffect, useState } from "react";
import { listContacts } from "../../../../../api/contacts";
import { listLocations } from "../../../../../api/goodsLocations";
import NewSectionDataEntryModal from "./components/NewSectionDataEntryModal";
import { listCommodityCodes } from "../../../../../api/commodity-codes";
import CustomSectionDataPageDrawer from "./components/Drawer/CustomSectionDataPageDrawer";
import CustomSectionPageSelectedItem from "./components/SelectedItem/CustomSectionPageSelectedItem";
import ErrorModal from "../../../../modals/ErrorModal";
import EmptyCustomSectionPageMessage from "./components/EmptyCustomSectionPageMessage";
import { SEARCH_TYPES } from "../../../../../constants/GlobalConstants";
import WorkspacesContext from "../../../../../context/workspaces/WorkspacesContext";

/**
 * SearchPage component is used in both of the following scenarios:
 *   - In the Contacts, Goods Locations and Commodity Codes pages for creating, updating and deleting entries
 *   - In the corresponding declaration sections for each in order to search and select a saved entry
 *  */

const CustomSectionDataPage = (props) => {
	const [items, setItems] = useState([]);
	const [isDrawerExpanded, setIsDrawerExpanded] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedItem, setSelectedItem] = useState();
	const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
	const [isNewItemModalOpen, setIsNewItemModalOpen] = useState(false);
	const { selectedWorkspaceId } = useContext(WorkspacesContext);
	const { sectionElements, onSelectedItemChange, searchType, style, isInModal } = props;

	const fetchData = async () => {
		setIsLoading(true);
		try {
			if (searchType === SEARCH_TYPES.CONTACTS) {
				const contacts = await listContacts(selectedWorkspaceId);
				setItems(contacts);
			} else if (searchType === SEARCH_TYPES.GOODS_LOCATIONS) {
				const locations = await listLocations(selectedWorkspaceId);
				setItems(locations);
			} else if (searchType === SEARCH_TYPES.COMMODITY_CODES) {
				const codes = await listCommodityCodes(selectedWorkspaceId);
				setItems(codes);
			}
		} catch (e) {
			console.log(e);
			setIsErrorModalOpen(true);
		}
		setIsLoading(false);
	};

	const handleItemDelete = async () => {
		const deletedItemIndex = items.findIndex((item) => item._id === selectedItem._id);

		if (deletedItemIndex > -1) {
			items.splice(deletedItemIndex, 1);

			setItems([...items]);
		}

		setSelectedItem(undefined);
	};

	const handleNewItemSubmit = (newItem) => {
		setItems([...items, newItem]);
		setSelectedItem(newItem);
	};

	const handleItemUpdate = (updatedItem) => {
		let itemIndex = items.findIndex((item) => item._id === updatedItem._id);

		items[itemIndex] = updatedItem;

		setItems([...items]);
	};

	useEffect(() => {
		fetchData();
	}, []);

	// useEffect(() => {
	// 	if (selectedItem) {
	// 		const updatedSelectedItem = items.find((item) => item._id === selectedItem._id);

	// 		if (updatedSelectedItem) {
	// 			setSelectedItem({ ...updatedSelectedItem });
	// 		}
	// 	}
	// }, [items]);

	useEffect(() => {
		onSelectedItemChange?.(selectedItem);
	}, [selectedItem]);

	return (
		<div style={{ width: "100%", height: "100%", ...style }}>
			<ErrorModal isOpen={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)} />

			<NewSectionDataEntryModal
				isOpen={isNewItemModalOpen}
				onClose={() => setIsNewItemModalOpen(false)}
				sectionType={searchType}
				sectionElements={sectionElements}
				onSubmit={handleNewItemSubmit}
			/>

			<div style={{ display: "flex", width: "100%", height: "100%" }}>
				<CustomSectionDataPageDrawer
					items={items}
					isInModal={isInModal}
					searchType={searchType}
					setIsNewItemModalOpen={setIsNewItemModalOpen}
					isLoading={isLoading}
					isExpanded={isDrawerExpanded}
					selectedItem={selectedItem}
					setSelectedItem={setSelectedItem}
					setIsExpanded={setIsDrawerExpanded}
				/>

				<div
					style={{
						transition: "0.3s",
						width: `calc(100% - ${isDrawerExpanded ? 320 : 64}px)`,
						paddingTop: isInModal ? 40 : 0,
						paddingBottom: isInModal ? 40 : 0,
						paddingLeft: 40,
						paddingRight: 40,
						borderTopRightRadius: 6,
						borderBottomRightRadius: 6,
						backgroundColor: isInModal ? "#F7FCF9" : "",
						overflowY: "auto",
					}}
				>
					{!isLoading && (
						<>
							{!items.length ? (
								<EmptyCustomSectionPageMessage
									searchType={searchType}
									onActionButtonClick={() =>
										setIsNewItemModalOpen(true)
									}
								/>
							) : (
								<CustomSectionPageSelectedItem
									isInModal={isInModal}
									searchType={searchType}
									item={selectedItem}
									elements={sectionElements}
									onBackClick={() => setSelectedItem(null)}
									onDelete={handleItemDelete}
									onUpdate={handleItemUpdate}
								/>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default CustomSectionDataPage;
