import moment from "moment/moment";
import { useContext } from "react";
import ThemeContext from "../../../../context/theme/ThemeContext";
import { useTranslation } from "react-i18next";
import EmptyPageMessage from "../../../common/EmptyPageMessage";
import FileAssociatedDeclaration from "./AssociatedDeclaration";

const SelectedFileData = ({ file }) => {
	const { t } = useTranslation();
	const { withTheme } = useContext(ThemeContext);

	return (
		<>
			<div style={{ marginTop: 24 }} className={withTheme("item-element-container")}>
				<div className={withTheme("item-element-name")}>{t("added")}</div>
				<div className={withTheme("item-element-value")}>
					{moment(file.createdAt).format("DD MMM YYYY")}
				</div>
			</div>

			<div style={{ marginTop: 24 }} className={withTheme("item-element-container")}>
				<div className={withTheme("item-element-name")}>{t("description")}</div>
				<div className={withTheme("item-element-value")}>{file.description}</div>
			</div>

			<div style={{ marginTop: 40 }}>
				<div className={withTheme("file-associated-dec-title")}>
					{t("associatedDeclarations")} ({file.declarations?.length || 0})
				</div>

				{!file.declarations?.length && (
					<EmptyPageMessage
						text={t("noAssociatedDeclarations")}
						style={{ alignItems: "flex-start", paddingTop: 18 }}
						textStyle={{ fontSize: 14 }}
					/>
				)}

				<div style={{ marginTop: 8 }}>
					{file.declarations.map((declaration, index) => (
						<FileAssociatedDeclaration declaration={declaration} key={index} />
					))}
				</div>
			</div>
		</>
	);
};

export default SelectedFileData;
