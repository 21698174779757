import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ThemeContext from "../../../../context/theme/ThemeContext";
import WorkspacesContext from "../../../../context/workspaces/WorkspacesContext";
import Track from "../../../../components/layouts/Track";
import { fetchDeclarations } from "../../../../api/declarations";
import LoadingSpinner from "../../../../components/common/LoadingSpinner";

const SubscriptionUsage = () => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const { withTheme } = useContext(ThemeContext);
	const { workspaces, selectedWorkspaceId } = useContext(WorkspacesContext);
	const [usage, setUsage] = useState(null);

	const currentWorkspace = useMemo(
		() => workspaces.find((workspace) => workspace._id === selectedWorkspaceId),
		[workspaces, selectedWorkspaceId]
	);

	const getSubscriptionUsage = async () => {
		setIsLoading(true);
		const result = await fetchDeclarations(selectedWorkspaceId, { count: true });

		setUsage({
			declarations: result.count,
		});

		setIsLoading(false);
	};

	useEffect(() => {
		if (!usage) {
			getSubscriptionUsage();
		}
	}, []);

	return (
		<div className={withTheme("settings-section")}>
			<div className={withTheme("settings-section-label")}>{t("subscriptionUsage")}</div>

			{isLoading ? (
				<LoadingSpinner />
			) : (
				<div style={{ maxWidth: "50%" }}>
					<Track
						label={t("totalDeclarationsSubmitted")}
						value={usage?.declarations}
						total={currentWorkspace?.subscription.limits.declarationsLimit}
					/>
				</div>
			)}
		</div>
	);
};

export default SubscriptionUsage;
