import { SET_FILES, SET_FILES_LOADING } from "../types";

const filesReducer = (state, action) => {
	switch (action.type) {
		case SET_FILES_LOADING: {
			return {
				...state,
				isFilesLoading: action.payload,
			};
		}
		case SET_FILES:
			return {
				...state,
				isFilesLoading: false,
				files: action.payload,
			};

		default:
			return state;
	}
};

export default filesReducer;
