import React, { useContext, useEffect, useMemo } from "react";
import Declaration from "./Declaration";
import Tabs from "../../../components/common/Tabs/Tabs";
import Items from "./Items/Items";
import { useTranslation } from "react-i18next";
import { getQueryParam, removeQueryParam, updateQueryParam } from "../../../router/utils";
import { ELEMENT_QUERY_PARAM, PAGE_QUERY_PARAM, TAB_QUERY_PARAM } from "../../../constants/GlobalConstants";
import DeclarationsContext from "../../../context/declarations/DeclarationsContext";
import Notifications from "./Notifications/Notifications";
import AuditLogs from "./AuditLogs/AuditLogs";
import Files from "./Files/Files";

const TabsContainer = (props) => {
	const { state } = useContext(DeclarationsContext);
	const { t } = useTranslation();
	const { selectedTabIndex, setSelectedTabIndex } = props;

	const tabs = useMemo(
		() => [
			t("declarationForm"),
			`${t("items")} (${state.declaration?.data.declarationItems?.length || 0})`,
			`${t("files")} (${state.declaration?.files?.length || 0})`,
			`${t("notifications")} ${`(${state.declaration.notifications.length})`}`,
			t("audit"),
		],
		[state.declaration, state.notifications]
	);

	const getStateFromQuery = () => {
		const tab = getQueryParam(TAB_QUERY_PARAM);
		setSelectedTabIndex(parseInt(tab) || 0);
	};

	useEffect(() => {
		getStateFromQuery();
		const eventHandler = () => {
			getStateFromQuery();
		};

		window.addEventListener("popstate", eventHandler);

		return function cleanup() {
			window.removeEventListener("popstate", eventHandler);
		};
	}, []);

	return (
		<div style={{ paddingTop: 24 }}>
			<Tabs
				tabs={tabs}
				selectedTabIndex={selectedTabIndex}
				onChange={(index) => {
					updateQueryParam(PAGE_QUERY_PARAM, 0);
					removeQueryParam(ELEMENT_QUERY_PARAM);
					updateQueryParam(TAB_QUERY_PARAM, index);
				}}
			/>

			{selectedTabIndex === 0 && <Declaration />}
			{selectedTabIndex === 1 && <Items />}
			{selectedTabIndex === 2 && <Files />}
			{selectedTabIndex === 3 && <Notifications />}
			{selectedTabIndex === 4 && <AuditLogs />}
		</div>
	);
};

export default TabsContainer;
