import i18n from "./i18n";
import ObjectId from "bson-objectid";
import { v4 as uuidv4 } from "uuid";

export const formatTime = (duration) => {
	// Need to add more comments

	// Hours, minutes and seconds
	var hrs = ~~(duration / 3600);
	var mins = ~~((duration % 3600) / 60);
	var secs = ~~duration % 60;

	// Output like "1:01" or "4:03:59" or "123:03:59"
	var ret = "";

	if (hrs > 0) {
		ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
	}

	ret += "" + mins + ":" + (secs < 10 ? "0" : "");
	ret += "" + secs;
	return ret;
};

export const deepEqual = (obj1, obj2) => {
	if (obj1 === obj2) {
		return true;
	}

	if (typeof obj1 !== "object" || obj1 === null || typeof obj2 !== "object" || obj2 === null) {
		return false;
	}

	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (let key of keys1) {
		if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
			return false;
		}
	}

	return true;
};

export const validatePassword = (pwd) => {
	var regex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,100}$/;
	var valid = regex.test(pwd);
	return valid;
};

export const validateEmail = (email) => {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

export const getDeclarationLabel = (declaration) => {
	const { importType, service } = declaration.data;

	return `#${declaration.seq} ${i18n.t(`common:${importType === "finalSupplementary" ? importType : service}`)}`;
};

export const generateObjectId = () => {
	return ObjectId().toHexString();
};

export const generateFileKey = (file) => {
	const splitType = file.type.split("/");
	const type = splitType[1];
	const key = `${uuidv4()}.${type}`;

	return key;
};

export const urlB64ToUint8Array = (base64String) => {
	const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
	const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
};

export const seo = (data) => {
	if (data.metaDescription) {
		document.querySelector('meta[name="description"]').setAttribute("content", data.metaDescription);
	}

	document.title = `Tax2Cargo${data.title ? ` - ${data.title}` : ""}`;
};
