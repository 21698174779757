import { useContext } from "react";
import Button from "./Button";
import { PiPlus } from "react-icons/pi";
import ThemeContext from "../../context/theme/ThemeContext";

const EmptyPageMessage = ({ image, text, actionButtonLabel, style, textStyle, onActionButtonClick, actionButtonProps }) => {
	const { withTheme } = useContext(ThemeContext);

	return (
		<div style={style} className={withTheme("empty-page-message-container")}>
			{image && <img style={{ width: 180, marginBottom: 32 }} src={image} alt="empty-page-img" />}

			<div className={withTheme("empty-page-text")} style={textStyle}>{text}</div>

			{onActionButtonClick && actionButtonLabel && (
				<Button
					LeftIcon={PiPlus}
					style={{ marginTop: 32 }}
					width="200px"
					onClick={onActionButtonClick}
					{...(actionButtonProps || {})}
				>
					{actionButtonLabel}
				</Button>
			)}
		</div>
	);
};

export default EmptyPageMessage;
